import React, { useState } from 'react';
import { ToggleButtonGroup, ToggleButton as MuiToggleButton } from '@mui/material';

interface ItemProps {
    label: string | number;
    value: string | number;
}

interface ToggleButtonProps {
    items: ItemProps[];
    defaultValue: string | number;
    ariaLabel: string;
    size?: 'small' | 'medium' | 'large';
    exclusive?: boolean;
    onChange?: (_: React.ChangeEvent<EventTarget>, newValue: number | string) => void;
    enforce?: boolean;
}

export const ToggleButton = (props: ToggleButtonProps) => {
    const { items, defaultValue, ariaLabel, size = 'large', exclusive = true, onChange } = props;
    const [value, setValue] = useState(defaultValue);

    const handleChange = (event: React.ChangeEvent<EventTarget>, newValue: number | string) => {
        if (newValue !== null) {
            setValue(newValue);
            onChange && onChange(event, newValue);
        }
    };

    const control = {
        value: value,
        onChange: handleChange,
        exclusive: exclusive,
    };

    React.useEffect(() => {
        defaultValue && setValue(defaultValue);
    }, [defaultValue]);

    return (
        <ToggleButtonGroup size={size} {...control} aria-label={ariaLabel}>
            {items.map((item) => (
                <MuiToggleButton key={item.value} value={item.value}>
                    {item.label}
                </MuiToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};
