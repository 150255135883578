import React from 'react';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { Pagination } from '@mui/material';
import { CardContent, CardHeader, CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Paths } from '@/common/const';
import { Button } from '@/components/common/button/button';
import { Card } from './styles';

interface StatsCardProps {
    title: string | number;
    subHeader: string;
    children?: JSX.Element | JSX.Element[] | string;
    handlePagination: (_: React.ChangeEvent<unknown>, p: number) => void;
    count?: number;
    page?: number;
    headerAction?: { to: string; label: string };
}

export const StatsCard = (props: StatsCardProps) => {
    const { title, subHeader, children, handlePagination, count, page, headerAction } = props;
    const navigate = useNavigate();

    const handleViewPatientsClick = () => {
        navigate(headerAction && headerAction.to ? headerAction.to : Paths.patients);
    };

    return (
        <Card variant="table" elevation={4} sx={{ width: '100%' }}>
            <CardHeader
                title={title}
                subheader={subHeader}
                action={
                    <Button
                        size="xs"
                        variant="text"
                        onClick={handleViewPatientsClick}
                        endIcon={<ArrowForwardRoundedIcon fontSize="large" />}
                    >
                        {headerAction && headerAction.label ? headerAction.label : 'Patient list'}
                    </Button>
                }
            />
            <CardContent>{children}</CardContent>
            <CardActions>
                {!count || count <= 1 ? null : (
                    <Pagination
                        count={count}
                        page={page}
                        color="primary"
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePagination}
                    />
                )}
            </CardActions>
        </Card>
    );
};
