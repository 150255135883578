import React from 'react';
import { SvgIcon } from '@mui/material';

export const WeightIcon = (props) => {
    return (
        <>
            <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
                <path
                    d="M10 7H7C6.44772 7 6 7.44772 6 8V24C6 24.5523 6.44772 25 7 25H10C10.5523 25 11 24.5523 11 24V8C11 7.44772 10.5523 7 10 7Z"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                />
                <path
                    d="M25 7H22C21.4477 7 21 7.44772 21 8V24C21 24.5523 21.4477 25 22 25H25C25.5523 25 26 24.5523 26 24V8C26 7.44772 25.5523 7 25 7Z"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                />
                <path
                    d="M26 10H29C29.2652 10 29.5196 10.1054 29.7071 10.2929C29.8946 10.4804 30 10.7348 30 11V21C30 21.2652 29.8946 21.5196 29.7071 21.7071C29.5196 21.8946 29.2652 22 29 22H26"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                />
                <path
                    d="M6 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V11C2 10.7348 2.10536 10.4804 2.29289 10.2929C2.48043 10.1054 2.73478 10 3 10H6"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                />
                <path
                    d="M11 16H21"
                    stroke="currentcolor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                />
            </SvgIcon>
        </>
    );
};
