import React, { useState } from 'react';
import { Box } from '@mui/material';
import { DefaultThumbnailUrl } from '@/common/const';
import { Exercise } from '@/common/types';
import { FavoriteExerciseAction } from '@/components/common/FavoriteExercise/FavoriteExerciseAction';
import {
    MalePersonFrontFacingIcon,
    MalePersonRearFacingIcon,
    MalePersonSideFacingIcon,
} from '@/components/common/Icons/MalePersonIcon';
import { PhoneLandscapeIcon } from '@/components/common/Icons/PhoneLandscapeIcon';
import { PhonePortraitIcon } from '@/components/common/Icons/PhonePortraitIcon';
import { CapitalizedTooltip } from '@/components/common/Tooltip/Tooltip';
import { AddToProgramAction } from './AddToProgram/AddtoProgramAction';
import { ExerciseAnimationLazy as ExerciseAnimation } from './ExerciseAnimationLazy';
import { HEPBuilderExerciseCardExerciseMetrics } from './HEPBuilderExeriseCardExerciseMetrics';
import { ViewExerciseAction } from './ViewExercise/ViewExerciseAction';
import {
    HEPBuilderExerciseCardWrapper,
    HEPBuilderExerciseCardActions,
    HEPBuilderExerciseCardMedia,
    ExercisePositionIconWrapper,
    PatientOrientationIconWrapper,
    ExerciseCardIconContainer,
    PhoneIconWrapper,
} from './styles';

interface Props {
    readonly exercise: Exercise;
    readonly addToHepCtas: boolean;
}

export const HEPBuilderExerciseCard = (props: Props) => {
    const { exercise, addToHepCtas } = props;

    const [drawerIsActive, setDrawerIsActive] = useState(false);

    const openDrawer = () => {
        setDrawerIsActive(true);
    };

    const closeDrawer = () => {
        setDrawerIsActive(false);
    };

    return (
        <>
            <HEPBuilderExerciseCardWrapper>
                {exercise.animationUrl ? (
                    <ExerciseAnimation
                        animationUrl={exercise.animationUrl!}
                        onClick={openDrawer}
                        exerciseMetricId={exercise.exerciseMetrics[0].id}
                        activeFrame={exercise.animationThumbnailFrame}
                    />
                ) : (
                    <HEPBuilderExerciseCardMedia
                        onClick={openDrawer}
                        component="img"
                        image={exercise.thumbnailUrl || DefaultThumbnailUrl}
                        alt={exercise.name}
                    />
                )}
                <FavoriteExerciseAction exercise={exercise} />

                <h4 onClick={openDrawer}>{exercise.name}</h4>
                <ExerciseCardIconContainer onClick={openDrawer}>
                    <ExercisePositionIconWrapper>
                        <PhoneIconWrapper
                            title={exercise.cameraOrientation === 'PORTRAIT' ? 'Portrait' : 'Landscape'}
                            $orientation={exercise.cameraOrientation}
                            arrow
                        >
                            <Box display={'flex'}>
                                {exercise.cameraOrientation === 'PORTRAIT' ? (
                                    <PhonePortraitIcon />
                                ) : (
                                    <PhoneLandscapeIcon />
                                )}
                            </Box>
                        </PhoneIconWrapper>
                    </ExercisePositionIconWrapper>
                    <CapitalizedTooltip title={`${exercise.subjectOrientation.toLowerCase()} Facing`} arrow>
                        <PatientOrientationIconWrapper>
                            {exercise.subjectOrientation === 'FRONT' ? (
                                <MalePersonFrontFacingIcon />
                            ) : exercise.subjectOrientation === 'SIDE' ? (
                                <MalePersonSideFacingIcon />
                            ) : (
                                <MalePersonRearFacingIcon />
                            )}
                        </PatientOrientationIconWrapper>
                    </CapitalizedTooltip>
                    <div style={{ flexGrow: 1 }}></div>
                    <HEPBuilderExerciseCardExerciseMetrics exerciseMetrics={exercise.exerciseMetrics} />
                </ExerciseCardIconContainer>
                {addToHepCtas && (
                    <HEPBuilderExerciseCardActions>
                        <AddToProgramAction exercise={exercise} isInCard />
                    </HEPBuilderExerciseCardActions>
                )}
            </HEPBuilderExerciseCardWrapper>
            <ViewExerciseAction
                exercise={exercise}
                drawerIsOpen={drawerIsActive}
                openDrawer={openDrawer}
                closeDrawer={closeDrawer}
                addToHepCtas={addToHepCtas}
            />
        </>
    );
};
