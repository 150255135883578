import React from 'react';
import { SvgIcon } from '@mui/material';

export const CreateIcon = (props) => {
    return (
        <>
            <SvgIcon width="24" height="24" viewBox="0 0 2.88 2.88" fill="none" {...props}>
                <title />
                <g>
                    <path
                        fill={props.color || 'currentColor'}
                        d="M1.98 1.26H1.62V0.9a0.18 0.18 0 0 0 -0.36 0v0.36H0.9a0.18 0.18 0 0 0 0 0.36h0.36v0.36a0.18 0.18 0 0 0 0.36 0V1.62h0.36a0.18 0.18 0 0 0 0 -0.36"
                    />
                    <path
                        fill={props.color || 'currentColor'}
                        d="M1.44 0a1.44 1.44 0 1 0 1.44 1.44A1.441 1.441 0 0 0 1.44 0m0 2.52a1.08 1.08 0 1 1 1.08 -1.08 1.081 1.081 0 0 1 -1.08 1.08"
                    />
                </g>
            </SvgIcon>
        </>
    );
};
