import { useEffect, useState } from 'react';
import { useExerUser } from './ExerUser';

export const useExerFeatureFlags = (): {
    flags: {
        paths: boolean;
        modules: boolean;
        language: boolean;
    };
} => {
    const { user } = useExerUser();
    const [flags, setFlags] = useState({
        paths: false,
        modules: false,
        language: false,
    });
    const adminUsers = ['bjorn+practitioner1@exerai.com', 'bjorn@exerai.com'];
    const primaryUsers = [...adminUsers, 'zaw@exerai.com'];
    const secondaryUsers = [...primaryUsers, 'sarah@exerai.com', 'clint@exerai.com'];

    useEffect(() => {
        const updateFeatureFlags = () => {
            const email = user.email;
            const isPrimaryUser = primaryUsers.includes(email);
            const isSecondaryUser = secondaryUsers.includes(email);

            setFlags({
                paths: isPrimaryUser,
                modules: isSecondaryUser,
                language: isPrimaryUser,
            });
        };

        updateFeatureFlags();
    }, [user]);

    return {
        flags,
    };
};
