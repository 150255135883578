import React from 'react';
import { ClientVersionsContainer } from '@/components/ClientVersions/ClientVersionsContainer';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { RouteContentWrapper } from '../../styles';

export const ClientVersionsRoute = () => {
    return (
        <RouteContentWrapper>
            <PageTitle title="Scan Client Versions" />
            <ClientVersionsContainer />
        </RouteContentWrapper>
    );
};
