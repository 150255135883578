import { Accordion, AccordionSummary, AccordionDetails, styled, Typography, Chip, Grid, Tooltip } from '@mui/material';

export const SessionTitle = styled('h3')(({ theme }) => ({
    fontWeight: 'bold',
}));

export const SessionTime = styled('div')(({ theme }) => ({
    whiteSpace: 'nowrap',
}));

export const SessionExpansionPanelSummaryHeader = styled('div')(({ theme }) => ({
    width: '100%',
}));

export const SessionExpansionPanelSummaryHeaderTop = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        flexDirection: 'row',
    },
}));

export const SessionHeaderTopRight = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'center',
        flexBasis: '270px',
        marginBottom: '0',
    },
}));

export const SessionHeaderTopRightTotal = styled(Chip)(({ theme }) => ({
    display: 'flex',
    textTransform: 'uppercase',
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    height: 'auto',
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    minWidth: '150px',
    [theme.breakpoints.up('md')]: {
        marginTop: '0',
    },
}));

export const SessionGridContainer = styled(Grid)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    margin: '0',
    maxWidth: '100%',
    '&:last-of-type': {
        borderBottom: '0',
    },
}));

export const ExerciseContent = styled('div')(({ theme }) => ({
    flexBasis: '55%',
}));

export const SessionExpansionPanel = styled(Accordion)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    '&:before': {
        display: 'none',
    },
    '&.Mui-expanded': {
        margin: '0',
    },
    '&:last-of-type .MuiAccordionSummary-root': {
        borderBottom: 0,
    },
}));

export const SessionExpansionPanelSummary = styled(AccordionSummary)(({ theme }) => ({
    flexDirection: 'row-reverse',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    padding: '0',
    transition: '.5s ease background',
    '&:last-of-kind': {
        borderBottom: '0',
    },
    '&:hover': {
        backgroundColor: theme.palette.grey[50],
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        fontSize: theme.typography.h5.fontSize,
        marginRight: theme.spacing(1.5),
    },
    '& .MuiAccordionSummary-content': {
        margin: '0',
    },
    '&.Mui-expanded': {
        margin: '0',
        minHeight: 'unset',
    },
}));

export const SessionExpansionPanelDetails = styled(AccordionDetails)(({ theme }) => ({
    display: 'block',
    padding: `${theme.spacing(2)} 0`,

    '& hr': {
        outline: 'none',
        border: 0,
        borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
}));

export const PatientSessionSectionWrapper = styled('div')<{ $inModal }>(({ theme, $inModal }) => ({
    padding: `${theme.spacing(1)}`,
    [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(3)} ${theme.spacing($inModal ? 0 : 5)}`,
    },
    '& h3': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
}));

export const PatientSessionDateTitle = styled(Typography)(({ theme }) => ({
    display: 'block',
}));

export const SelfReportedTooltipWrapper = styled(Tooltip)(({ theme }) => ({
    display: 'inline',
}));
