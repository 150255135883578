import React from 'react';
import { SvgIcon } from '@mui/material';

export const SwapIcon = (props) => {
    return (
        <>
            <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.0157 3.94836C21.5128 3.94836 21.9157 4.35131 21.9157 4.84836V9.34836C21.9157 9.84542 21.5128 10.2484 21.0157 10.2484H16.5157C16.0187 10.2484 15.6157 9.84542 15.6157 9.34836C15.6157 8.85131 16.0187 8.44836 16.5157 8.44836H20.1157V4.84836C20.1157 4.35131 20.5187 3.94836 21.0157 3.94836Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 4.64998C11.0348 4.64998 10.079 4.84009 9.18727 5.20946C8.29552 5.57883 7.48526 6.12023 6.80275 6.80274C6.45128 7.15421 5.88143 7.15421 5.52996 6.80274C5.17849 6.45127 5.17849 5.88142 5.52996 5.52995C6.37962 4.68029 7.38831 4.00631 8.49843 3.54648C9.60856 3.08665 10.7984 2.84998 12 2.84998C13.2016 2.84998 14.3914 3.08665 15.5015 3.54648C16.6117 4.00631 17.6204 4.68029 18.47 5.52995L21.652 8.71193C22.0035 9.0634 22.0035 9.63325 21.652 9.98472C21.3005 10.3362 20.7307 10.3362 20.3792 9.98472L17.1972 6.80274C16.5147 6.12023 15.7045 5.57883 14.8127 5.20946C13.921 4.84009 12.9652 4.64998 12 4.64998Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.08435 14.6516C2.08435 14.1545 2.48729 13.7516 2.98435 13.7516H7.48435C7.98141 13.7516 8.38435 14.1545 8.38435 14.6516C8.38435 15.1486 7.98141 15.5516 7.48435 15.5516H3.88435V19.1516C3.88435 19.6486 3.48141 20.0516 2.98435 20.0516C2.48729 20.0516 2.08435 19.6486 2.08435 19.1516V14.6516Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.34795 14.0152C2.69943 13.6637 3.26928 13.6637 3.62075 14.0152L6.80273 17.1972C7.48524 17.8797 8.2955 18.4211 9.18724 18.7905C10.079 19.1598 11.0347 19.3499 12 19.3499C12.9652 19.3499 13.9209 19.1598 14.8127 18.7905C15.7044 18.4211 16.5147 17.8797 17.1972 17.1972C17.5487 16.8457 18.1185 16.8457 18.47 17.1972C18.8215 17.5486 18.8215 18.1185 18.47 18.47C17.6203 19.3196 16.6116 19.9936 15.5015 20.4534C14.3914 20.9133 13.2016 21.1499 12 21.1499C10.7984 21.1499 9.60854 20.9133 8.49841 20.4534C7.38828 19.9936 6.37959 19.3196 5.52993 18.47L2.34795 15.288C1.99648 14.9365 1.99648 14.3667 2.34795 14.0152Z"
                    fill="currentColor"
                />
            </SvgIcon>
        </>
    );
};
