import React, { ReactNode } from 'react';
import { Products } from '@/common/const';
import { getStringEnumKeyByValue } from '@/common/utils';
import { GaitPatientSessionsWrapper } from '@/components/Gait/GaitPatient/GaitPatient/GaitPatientSessionsWrapper';
import { HealthPatient } from '@/components/Health/HealthPatient/HealthPatient';
import { usePatientContext } from './context/hooks/PatientContext';

interface PatientDetailsProps {
    openBuilder: () => void;
}

export const PatientDetails = (props: PatientDetailsProps) => {
    const { patientRecord, product } = usePatientContext();

    let component: ReactNode = null;
    switch (product) {
        case getStringEnumKeyByValue(Products, Products.HEALTH):
            component = <HealthPatient {...props} />;
            break;
        case getStringEnumKeyByValue(Products, Products.GAIT):
            component = <GaitPatientSessionsWrapper patientRecordId={patientRecord.id} />;
            break;
    }
    return component;
};
