import React from 'react';
import { CircularProgress } from '@mui/material';
import { LoaderContainer } from './styles';

export const LoadingSpinner = () => {
    return (
        <LoaderContainer>
            <CircularProgress />
        </LoaderContainer>
    );
};
