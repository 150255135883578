import { styled, Typography, Card } from '@mui/material';

export const StepSummaryWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),

    [theme.breakpoints.up('lg')]: {
        marginLeft: '20%',
        marginRight: '20%',
    },

    '& hr': {
        margin: 0,
        border: 'none',
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        width: '100%',
    },
}));

export const StepSummaryContentWrapper = styled(Card)(({ theme }) => ({
    boxShadow: '0px 2px 5px rgba(16, 5, 51, 0.04)',
    padding: '0',
}));

export const StepSummaryContentHeader = styled('h3')(({ theme }) => ({
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    margin: 0,
}));

export const StepSummaryContentBody = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.fontSize,
    color: theme.palette.grey[600],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
}));

export const StepSummaryContentHeaderActions = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
}));

export const StepSummaryTitleWrapper = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[900],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

export const StepSummaryNotesWrapper = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

export const StepSummaryCaptionTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.5),
}));

export const StepSummaryNotes = styled('p')(({ theme }) => ({
    '&&': {
        whiteSpace: 'pre-wrap',
        marginBottom: theme.spacing(4),
    },
}));
