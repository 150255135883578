import { Comparator, ComparatorSymbolByString } from '@/common/const';
import { Maybe, Nullable, ScanSessionResult } from '@/common/types';
import { wordToTitleCase } from '@/common/utils';
import { CriteriaCategory } from '../types/CriteriaCategory.enum';
import { Sex } from '../types/Sex.enum';
import {
    FactorCriteria,
    FactorCriteriaValue,
    MaybeSex,
    OutcomeFactor,
    OutcomeFactorTableData,
    ParticipantDemographics,
    UNSPECIFIED,
} from '../types/types';
import { getFactorColor, getResultCriteria, valueMeetsCriteria } from '../utils';

const getAgeRangeString = (ageCriteria: FactorCriteria): string => {
    const conditions = ageCriteria.conditions;
    const minAge = conditions.find((c) => c.comparator === Comparator.gte || c.comparator === Comparator.gt)?.value;
    const maxAge = conditions.find((c) => c.comparator === Comparator.lte || c.comparator === Comparator.lt)?.value;
    return `${minAge}${maxAge ? `-${maxAge}` : `+`}`;
};

const getResultCriterionNode = (criterion: Maybe<FactorCriteria>): { value: string; unit: Nullable<string> } => {
    const criterionCondition = criterion?.conditions[0];

    return {
        value: criterionCondition
            ? `${ComparatorSymbolByString[criterionCondition.comparator]} ${criterionCondition?.value}`
            : '',
        unit: criterion?.units || null,
    };
};

export const meetsCriteriaByType = (
    result: ScanSessionResult,
    criteria: Maybe<FactorCriteria>,
    demographics: Maybe<ParticipantDemographics>,
) => {
    if (!criteria) {
        return true;
    }
    let value: Maybe<FactorCriteriaValue>;
    switch (criteria.type) {
        case CriteriaCategory.SEX:
            value = demographics?.sex?.value;
            break;
        case CriteriaCategory.AGE:
            value = demographics?.age?.value;
            break;
        default:
            value = result.value;
            break;
    }
    if (!value) {
        return false;
    }
    const meetsDefined = valueMeetsCriteria([criteria], value);
    return meetsDefined;
};

export const getOutcomeFactorTableData = (
    result: ScanSessionResult,
    factors: Maybe<OutcomeFactor[]>,
    demographics: Maybe<ParticipantDemographics>,
): { sexMap: OutcomeFactorTableData; resultCriterion: Maybe<FactorCriteria> } => {
    // TODO THIS WILL ONLY WORK FOR SINGLE FACTOR CATEGORIES (e.g. HIGH) and a single Criterion (e.g.Time)
    const sexMap: OutcomeFactorTableData = new Map();
    let resultCriterion: Maybe<FactorCriteria>;
    factors?.forEach((factor: OutcomeFactor) => {
        const sexCriterion = factor.criteria.find((c) => c.type === CriteriaCategory.SEX);
        let sex: MaybeSex = sexCriterion?.conditions[0].value as Sex;

        const ageCriterion = factor.criteria.find((c) => c.type === CriteriaCategory.AGE);
        const ageRange = getAgeRangeString(ageCriterion!);

        resultCriterion = getResultCriteria(factor.criteria)?.[0];
        const resultCriterionString = getResultCriterionNode(resultCriterion);

        if (!sex) {
            sex = UNSPECIFIED;
        }
        if (!sexMap.get(sex)) {
            sexMap.set(sex, {
                // This is why it's limited to single factor categories
                rows: [
                    {
                        ageRange: 'Age Range',
                        values: [{ value: `${wordToTitleCase(factor.category)} Risk`, unit: null }],
                        isResultRow: false,
                    },
                ],
            });
        }
        const meetsAllCriteria = [sexCriterion, ageCriterion, resultCriterion].every((criteria) => {
            return meetsCriteriaByType(result, criteria, demographics);
        });

        let row = sexMap.get(sex)?.rows.find((r) => r.ageRange === ageRange);
        if (!row) {
            row = {
                ageRange,
                values: [],
                isResultRow: meetsAllCriteria,
                factorColor: getFactorColor(factor.category),
            };
            sexMap.get(sex)?.rows.push(row);
        }

        row.values.push(resultCriterionString);
    });
    return { sexMap, resultCriterion };
};
