import React, { createContext, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { MetricCode } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { ExercisesDict, isROMExerciseMetric } from '@/common/types';
import { getStringEnumKeyByValue } from '@/common/utils';
import { resultIsError } from '@/services/HttpService';

interface IExerciseContext {
    exercisesDict?: ExercisesDict;
    saturateWithAnimation: (id: number, animationJson: any) => void;
}
export const ExercisesContext = createContext<IExerciseContext>({
    exercisesDict: undefined,
    saturateWithAnimation: (id: number, animationJson: any) => null,
});

export const ExercisesProvider = (props) => {
    const [exercisesDict, setExercisesDict] = useState<ExercisesDict>();
    const { user } = useExerUser();
    const { httpService } = useHttpContext();
    const edRef = useRef(exercisesDict);
    edRef.current = exercisesDict;

    const saturateWithAnimation = (id: number, animationJson) => {
        if (!exercisesDict) return;

        setExercisesDict((prev) => {
            if (!prev) return;
            const newExercise = prev[id];
            if (prev[id].id === id) {
                newExercise.animationJson = animationJson;
            }
            return { ...prev, [id]: { ...newExercise } };
        });
    };

    const fetchExercises = async () => {
        const result = await httpService.getExerciseMetrics();
        if (resultIsError(result)) {
            Sentry.captureException(`fetch exercises ${result.message}`);
        } else {
            const exerciseDict: ExercisesDict = {};
            result.map((em) => {
                if (em.id) {
                    exerciseDict[em.id] = {
                        id: em.id,
                        metric: em.metric,
                        name:
                            em.metric === getStringEnumKeyByValue(MetricCode, MetricCode.ROM)
                                ? em.exercise.name + ' (ROM)'
                                : em.exercise.name,
                        thumbnailUrl: em.exercise.thumbnailUrl,
                        animationUrl: em.exercise.animationUrl,
                        exerciseType: em.exercise.exerciseType,
                        animationJson: null,
                        animationThumbnailFrame: em.exercise.animationThumbnailFrame,
                        position: em.exercise.position,
                        action: em.exercise.action,
                    };
                    if (isROMExerciseMetric(em)) {
                        // TODO rename to settings?
                        exerciseDict[em.id].romProperties = em.settings?.ROM;
                    }
                }
            });
            setExercisesDict(exerciseDict);
        }
    };

    useEffect(() => {
        // if we haven't gotten the exerciseDict after a second, attempt again and set debug info so we can find why
        // TODO remove if we haven't seen it for x time
        setTimeout(async () => {
            if (!edRef && user.isAuthenticated) {
                await fetchExercises();
                Sentry.captureException('ExercisesContext fallback reached');
            }
        }, 1000);
    }, []);

    useEffect(() => {
        if (!exercisesDict && user.isAuthenticated) {
            (async () => {
                await fetchExercises();
            })();
        }
    }, [user.isAuthenticated]);

    const modalContext = { exercisesDict, saturateWithAnimation };
    return <ExercisesContext.Provider value={modalContext}>{props.children}</ExercisesContext.Provider>;
};
