import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from '@/common/const';
import { NewPasswordForm } from '@/components/Auth/ForgotPassword/ResetPasswordForm';

type LocationState = { state: { username: string } };

export const ResetPassword = () => {
    const { state } = useLocation() as LocationState;
    const navigate = useNavigate();

    useEffect(() => {
        if (!state) {
            navigate(Paths.userLogin, { replace: true });
        }
    }, []);

    return (
        <>
            <Typography variant={'h5'} component="h1" fontWeight={600}>
                Reset Password
            </Typography>
            <p>
                We have sent a verification code to <b>{state?.username}</b>.
                <br />
                Please check your inbox and enter the code below.
            </p>
            <NewPasswordForm />
        </>
    );
};
