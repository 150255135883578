import React from 'react';
import { PatientsContainer } from '@/components/Patients/Patients/PatientsContainer';
import { PaginationProvider } from '@/components/context/PaginationContext';
import { RouteContentWrapper } from '../styles';

export const PatientsRoute = () => {
    return (
        <RouteContentWrapper>
            <PaginationProvider>
                <PatientsContainer />
            </PaginationProvider>
        </RouteContentWrapper>
    );
};
