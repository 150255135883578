import React from 'react';
import { SvgIcon } from '@mui/material';

export const PatientsIconOutline = (props) => {
    return (
        <>
            <SvgIcon width="22" height="20" viewBox="0 0 22 20" fill="none" {...props}>
                <path
                    d="M15.0203 8.9134C16.8413 8.9134 18.3173 7.4374 18.3173 5.6164C18.3173 3.7964 16.8413 2.3194 15.0203 2.3194"
                    stroke="currentcolor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="transparent"
                />
                <path
                    d="M16.5364 12.4964C17.0804 12.5334 17.6204 12.6114 18.1534 12.7294C18.8924 12.8764 19.7824 13.1794 20.0984 13.8424C20.3004 14.2674 20.3004 14.7624 20.0984 15.1874C19.7834 15.8504 18.8924 16.1534 18.1534 16.3054"
                    stroke="currentcolor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="transparent"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.59149 13.2063C12.2815 13.2063 15.4335 13.7653 15.4335 15.9983C15.4335 18.2323 12.3015 18.8103 8.59149 18.8103C4.90149 18.8103 1.75049 18.2523 1.75049 16.0183C1.75049 13.7843 4.88149 13.2063 8.59149 13.2063Z"
                    stroke="currentcolor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="transparent"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.59152 10.0188C6.15752 10.0188 4.20752 8.06779 4.20752 5.63379C4.20752 3.20079 6.15752 1.24979 8.59152 1.24979C11.0255 1.24979 12.9765 3.20079 12.9765 5.63379C12.9765 8.06779 11.0255 10.0188 8.59152 10.0188Z"
                    stroke="currentcolor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="transparent"
                />
            </SvgIcon>
        </>
    );
};
