import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { DialogTitleContainer } from '@/components/Demo/Paths/styles';
import { Button } from '@/components/common/button/button';
import { Center } from '@/components/common/styles';
import { assessmentReportChartImageState } from '@/recoil/atoms/assessmentReportAtom';
import { ScanReportChart } from '../ScanReportChart';
import { ScanReportPDFProps, ScanReportResultData } from '../types';
import { ScanAssessmentReportPDFAction } from './ScanAssessmentReportPDFAction';

interface ScanAssessmentReportPDFProps {
    assessmentEntries: [string, Map<string, ScanReportResultData>][];
    scanReportPDFProps: ScanReportPDFProps;
}
export const ScanAssessmentReportPDF: FC<ScanAssessmentReportPDFProps> = (props) => {
    const { assessmentEntries, scanReportPDFProps } = props;
    const { title } = scanReportPDFProps;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const chartImageMap = useRecoilValue(assessmentReportChartImageState);

    const handleClick = () => {
        setDialogOpen(true);
    };

    const handleCloseModal = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        let imageCount = 0;
        assessmentEntries.forEach((ae) => {
            imageCount += [...ae[1].entries()].length;
        });
        setIsLoading(chartImageMap.size !== imageCount);
    }, [assessmentEntries, chartImageMap, dialogOpen]);

    return (
        <>
            <Button onClick={handleClick}>Event PDF</Button>
            {dialogOpen && (
                <Dialog open={true} keepMounted onClose={handleCloseModal} fullWidth maxWidth={'lg'}>
                    <DialogTitleContainer>
                        <DialogTitle>{title}</DialogTitle>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </DialogTitleContainer>
                    <DialogContent>
                        <div style={{ position: 'absolute', zIndex: '-100', width: '1280px', top: 0, left: -10000 }}>
                            {assessmentEntries.map((assessmentEntry) => {
                                const assessmentId = assessmentEntry[0];
                                const resultMap = assessmentEntry[1];
                                return (
                                    <>
                                        {[...resultMap.entries()].map((resultEntry) => {
                                            const resultData = resultEntry[1];
                                            return (
                                                <>
                                                    <ScanReportChart
                                                        assessmentId={assessmentId}
                                                        resultData={resultData}
                                                        setChartImages={true}
                                                    />
                                                </>
                                            );
                                        })}
                                    </>
                                );
                            })}
                        </div>
                        <Center>
                            {isLoading ? (
                                <Button
                                    onClick={() => {
                                        return;
                                    }}
                                    variant="primary"
                                    disabled={true}
                                >
                                    {'Rendering Images...'}
                                </Button>
                            ) : (
                                <ScanAssessmentReportPDFAction chartImageMap={chartImageMap} {...props} />
                            )}
                        </Center>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};
