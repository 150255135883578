import React, { useState } from 'react';
import { Close, Print } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Products } from '@/common/const';
import { DialogTitleContainer } from '@/components/Demo/Paths/styles';
import { Button } from '@/components/common/button/button';

interface SessionResultsActionProps {
    product: Products;
    children: JSX.Element | JSX.Element[];
}

export const SessionResultsAction = (props: SessionResultsActionProps) => {
    const { product, children } = props;
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClick = () => {
        setDialogOpen(true);
    };

    const handleCloseModal = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <Button onClick={handleClick} startIcon={<Print />} variant="secondary" size="small">
                PDF
            </Button>
            {dialogOpen && (
                <Dialog open={true} keepMounted onClose={handleCloseModal} fullWidth maxWidth={'lg'}>
                    <DialogTitleContainer>
                        <DialogTitle>{product} Session Results</DialogTitle>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </DialogTitleContainer>
                    <DialogContent>{children}</DialogContent>
                </Dialog>
            )}
        </>
    );
};
