import ReactPDF from '@react-pdf/renderer';
import { convertPxToPt } from '@/components/Health/PatientSessions/SessionsPDF/SessionsPDFUtils';

const baseStyles: ReactPDF.Styles = {
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexCol: {
        display: 'flex',
        flexDirection: 'column',
    },
};

export const assessmentReportPDFStyles: ReactPDF.Styles = {
    page: {
        ...baseStyles.flexCol,
        fontFamily: 'DM Sans',
        backgroundColor: '#fff',
        flex: 1,
        flexBasis: 'auto',
        height: '90%',
        padding: `${convertPxToPt(24)} ${convertPxToPt(27)} ${convertPxToPt(20)}`,
    },
    flexCol: {
        ...baseStyles.flexCol,
    },
    flexRow: {
        ...baseStyles.flexRow,
    },
    pageTitle: {
        fontSize: convertPxToPt(20),
        marginBottom: convertPxToPt(4),
    },
    pageSubTitle: {
        fontSize: convertPxToPt(16),
        marginBottom: convertPxToPt(16),
    },
    assessmentHeader: {
        fontSize: convertPxToPt(16),
        marginTop: convertPxToPt(18),
        paddingBottom: convertPxToPt(4),
        borderBottom: `1px solid #999999`,
        fontWeight: 500,
    },
    resultHeader: {
        fontSize: convertPxToPt(14),
        marginTop: convertPxToPt(10),
        fontWeight: 500,
    },
};
