import React, { useState, useEffect, ReactNode } from 'react';
import { ArrowForward, ArrowBack, Close } from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Grid, Checkbox, FormControlLabel } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { TemplateOrHEP, AssignOrCancelEnum } from '@/common/types';
import { ExerciseListProvider } from '@/components/Health/HEPBuilder/ExerciseList/state/ExerciseListContext';
import { HEPBuilderCloseButton } from '@/components/Health/HEPBuilder/HepBuilderModal/styles';
import { HepOrTemplateScreen } from '@/components/Health/HEPBuilder/steps/HepOrTemplateScreen/HepOrTemplateScreen';
import { Button } from '@/components/common/button/button';
import { selectedBuilderTemplateState } from '@/recoil/atoms/hepTemplatesAtom';
import { useHEPBuilderContext } from './state/hooks/HEPBuilderContext';
import { StepExercises } from './steps/StepExercises/StepExercises';
import { StepInformation } from './steps/StepInformation/StepInformation';
import { StepSummary } from './steps/StepSummary/StepSummary';
import {
    HEPBuilderHeader,
    HEPBuilderContent,
    HEPBuilderNav,
    HEPBuilderNavItem,
    HEPBuilderWrapper,
    HEPBuilderHeaderRight,
    HEPBuilderNavItemBullet,
    HEPBuilderFooter,
    HEPBuilderStepIndicator,
} from './styles';

interface HEPBuilderProps {
    closeModal?: () => void;
}

export const HEPBuilder = (props: HEPBuilderProps) => {
    const { closeModal } = props;
    const {
        activeStep,
        handleNext,
        handleBack,
        patientRecord,
        isEditing,
        isComplete,
        isTemplateOrHEP,
        handleAssignTemplate,
        handleAssignAndSaveAsTemplate,
        hepErrors,
        hepDetails,
    } = useHEPBuilderContext();

    const selectedTemplate = useRecoilValue(selectedBuilderTemplateState);
    const [templateName, setTemplateName] = useState(hepDetails.name);
    const [templateNotes, setTemplateNotes] = useState(hepDetails.notes ? hepDetails.notes : '');
    const [assignAndSave, setAssignAndSave] = useState(false);

    let currentStep: ReactNode;

    switch (activeStep) {
        case 0:
            currentStep = <HepOrTemplateScreen />;
            break;
        case 1:
            currentStep = <StepInformation />;
            break;
        case 2:
            currentStep = <StepExercises />;
            break;
        case 3:
            currentStep = <StepSummary closeModal={closeModal} />;
            break;
    }

    const handleAssignTemplateClick = () => {
        handleAssignTemplate(selectedTemplate);
    };

    const handleAssignAndSaveAsTemplateClick = ({
        templateName,
        templateNotes,
    }: {
        templateName?: string;
        templateNotes?: string;
    }) => {
        if (assignAndSave) {
            handleAssignAndSaveAsTemplate({ AssignOrCancel: AssignOrCancelEnum.ASSIGN, templateName, templateNotes });
        } else {
            handleAssignAndSaveAsTemplate({
                AssignOrCancel: AssignOrCancelEnum.CANCEL,
                templateName: '',
                templateNotes: '',
            });
        }
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTemplateName(e.target.value);
    };

    const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTemplateNotes(e.target.value);
    };

    const handleAssignAndSaveCheckboxClick = () => {
        setAssignAndSave((prev) => !prev);
    };

    useEffect(() => {
        setTemplateName(hepDetails.name);
        setTemplateNotes(hepDetails.notes || '');
    }, [hepDetails]);

    return (
        <HEPBuilderWrapper>
            <HEPBuilderHeader>
                {activeStep === 0 ? (
                    <h2>Assign Program</h2>
                ) : (
                    <h2>{isTemplateOrHEP === TemplateOrHEP.Program ? 'Program' : 'Program Template'} Builder</h2>
                )}

                {!isComplete && activeStep !== 0 && (
                    <HEPBuilderNav>
                        <HEPBuilderNavItem $isActive={activeStep === 1} variant="body1">
                            {activeStep === 1 ? (
                                <HEPBuilderStepIndicator variant="square">1</HEPBuilderStepIndicator>
                            ) : (
                                <HEPBuilderNavItemBullet>•</HEPBuilderNavItemBullet>
                            )}{' '}
                            Information
                        </HEPBuilderNavItem>
                        <HEPBuilderNavItem $isActive={activeStep === 2} variant="body1">
                            {activeStep === 2 ? (
                                <HEPBuilderStepIndicator variant="square">2</HEPBuilderStepIndicator>
                            ) : (
                                <HEPBuilderNavItemBullet>•</HEPBuilderNavItemBullet>
                            )}{' '}
                            Exercises
                        </HEPBuilderNavItem>
                        <HEPBuilderNavItem $isActive={activeStep === 3} variant="body1">
                            {activeStep === 3 ? (
                                <HEPBuilderStepIndicator variant="square">3</HEPBuilderStepIndicator>
                            ) : (
                                <HEPBuilderNavItemBullet>•</HEPBuilderNavItemBullet>
                            )}{' '}
                            Summary
                        </HEPBuilderNavItem>
                    </HEPBuilderNav>
                )}
                <HEPBuilderHeaderRight>
                    {closeModal ? (
                        <HEPBuilderCloseButton onClick={closeModal}>
                            <Close />
                        </HEPBuilderCloseButton>
                    ) : (
                        `\u00a0`
                    )}
                </HEPBuilderHeaderRight>
            </HEPBuilderHeader>
            <ExerciseListProvider>
                <HEPBuilderContent data-cy="program-builder-content" $fullScreen={activeStep === 0}>
                    {currentStep}
                </HEPBuilderContent>
            </ExerciseListProvider>
            {!isComplete && (
                <HEPBuilderFooter container>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={7} alignItems="center">
                        <>
                            {activeStep > 1 ? (
                                <Button variant="tertiary" onClick={handleBack} size="medium" startIcon={<ArrowBack />}>
                                    Back
                                </Button>
                            ) : null}
                        </>
                    </Grid>
                    {activeStep !== 0 ? (
                        <Grid
                            item
                            container
                            columnSpacing={4}
                            sm={6}
                            md={6}
                            lg={5}
                            display="flex"
                            justifyContent="flex-end"
                        >
                            <Grid item display="flex" alignItems="center" justifyContent="flex-end">
                                {isTemplateOrHEP === TemplateOrHEP.Program && activeStep === 3 && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value={assignAndSave}
                                                checked={assignAndSave}
                                                name="Save1"
                                                onChange={handleAssignAndSaveCheckboxClick}
                                            />
                                        }
                                        label="Save this Program as template"
                                    />
                                )}
                            </Grid>
                            <Grid item display="flex" alignItems="center" justifyContent="flex-end">
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        if (isTemplateOrHEP === TemplateOrHEP.Program && activeStep === 3) {
                                            handleAssignAndSaveAsTemplateClick({
                                                templateName,
                                                templateNotes,
                                            });
                                        } else {
                                            handleNext();
                                        }
                                    }}
                                    endIcon={activeStep === 3 ? undefined : <ArrowForward />}
                                >
                                    {activeStep === 3 ? (
                                        <>
                                            {isEditing
                                                ? 'Save'
                                                : patientRecord
                                                ? `Assign to Patient`
                                                : `Publish Template`}
                                        </>
                                    ) : (
                                        <>Next</>
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item sm={6} md={6} lg={5} display="flex" alignItems="center" justifyContent="flex-end">
                            <Button variant="primary" onClick={handleAssignTemplateClick} endIcon={<AddRoundedIcon />}>
                                Assign to Patient
                            </Button>
                        </Grid>
                    )}
                </HEPBuilderFooter>
            )}
        </HEPBuilderWrapper>
    );
};
