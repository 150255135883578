import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { MuiSearchIcon } from './styles';

interface HEPTemplateSearchProps {
    setSearchTerm: (term: string) => void;
}

export const HEPTemplateSearch = (props: HEPTemplateSearchProps) => {
    const { setSearchTerm } = props;

    return (
        <TextField
            data-cy="program-template-search-bar"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchTerm(e.target.value.toLocaleLowerCase());
            }}
            label="Search for template"
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <MuiSearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};
