import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { PROTypeformResponse } from '@/common/types';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { resultIsError } from '@/services/HttpService';
import { PROResponsesTable } from './PROResponsesTable';

export const PROResponsesTableContainer = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<PROTypeformResponse[]>();
    const { httpService } = useHttpContext();

    const getResponses = async () => {
        setIsLoading(true);
        const res = await httpService.adminGetPROTypeformUnprocessedResponses();
        if (resultIsError(res)) {
        } else {
            setRows(res);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await getResponses();
        })();
    }, []);

    const columnStructure = [
        {
            Header: 'Form Title',
            accessor: 'form.title',
            Cell: ({ value }) => <strong>{value}</strong>,
        },
        {
            Header: 'User',
            accessor: 'userId',
            Cell: ({ value }) => <strong>{value}</strong>,
        },
        {
            Header: 'Location',
            accessor: 'location',
            Cell: ({ value }) => <strong>{value}</strong>,
        },
        {
            Header: 'Date Time',
            accessor: 'dateTime',
            Cell: ({ value }) => (
                <>{value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS) : ''}</>
            ),
        },
    ];

    return (
        <div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                rows && <PROResponsesTable columnStructure={columnStructure} data={rows} />
            )}
        </div>
    );
};
