import React, { lazy, Suspense, useState } from 'react';
import { Button, Skeleton } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { LoadingStatus } from '@/common/types';
import { isHealthOrPROPatient } from '@/common/utils';
import { usePatientContext } from '@/components/Patients/Patient/context/hooks/PatientContext';
import { DownloadIcon } from '@/components/common/Icons/DownloadIcon';
import {
    healthChartsLoadingState,
    healthSessionImagesState,
    proChartImageState,
} from '@/recoil/atoms/healthSessionsAtom';
import { SessionsReportModal } from './styles';

const SessionsReportForm = lazy(() => import(/* webpackPrefetch: true */ './SessionsReportForm'));

export const SessionsReportAction = () => {
    const { healthSessions, patientRecord, proAnswersByQuestionsDict } = usePatientContext();
    const [dialogOpen, setDialogOpen] = useState(false);
    const setHealthSessionImages = useSetRecoilState(healthSessionImagesState);
    const setProChartImages = useSetRecoilState(proChartImageState);
    const setLoadingCharts = useSetRecoilState(healthChartsLoadingState);

    const handleClick = () => {
        setDialogOpen(true);
        setLoadingCharts(LoadingStatus.IDLE);
    };

    const closeModal = () => {
        setDialogOpen(false);
        setHealthSessionImages(new Map());
        setProChartImages(new Map());
    };

    return isHealthOrPROPatient(patientRecord) ? (
        <>
            <Button
                onClick={handleClick}
                variant="tertiary"
                aria-label="Download Report"
                size="small"
                disabled={
                    (!healthSessions || healthSessions?.length < 1) &&
                    (!proAnswersByQuestionsDict || Object.keys(proAnswersByQuestionsDict)?.length < 1)
                }
                startIcon={<DownloadIcon />}
            >
                Download Report
            </Button>
            <SessionsReportModal
                open={dialogOpen}
                onClose={closeModal}
                title={'Download Patient Report'}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <Suspense fallback={<Skeleton variant="rectangular" width="100%" height={200} />}>
                    <SessionsReportForm
                        sessions={healthSessions}
                        onCancelOrSubmit={closeModal}
                        patientRecord={patientRecord}
                    />
                </Suspense>
            </SessionsReportModal>
        </>
    ) : null;
};
