import React, { useState } from 'react';
import { FC } from 'react';
import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';
import { DialogTitleContainer } from '@/components/Demo/Paths/styles';
import { Center } from '@/components/common/styles';
import { ScanParticipantReportPDFActionButton } from '../styles';
import { ScanReportParticipantData, ScanReportPDFProps } from '../types';
import { ScanParticipantReportPDFAction } from './ScanParticipantReportPDFAction';

interface ScanParticipantReportPDFProps {
    participantEntry: [string, Map<string, ScanReportParticipantData>];
    scanReportPDFProps: ScanReportPDFProps;
}
export const ScanParticipantReportPDF: FC<ScanParticipantReportPDFProps> = (props) => {
    const { participantEntry, scanReportPDFProps } = props;
    const participantUid = participantEntry[0];
    const assessmentMap = participantEntry[1];
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClick = () => {
        setDialogOpen(true);
    };

    const handleCloseModal = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <ScanParticipantReportPDFActionButton variant="primary" onClick={handleClick}>
                <>Participant PDF ({participantUid})</>
            </ScanParticipantReportPDFActionButton>
            {dialogOpen && (
                <Dialog open={true} keepMounted onClose={handleCloseModal} fullWidth maxWidth={'lg'}>
                    <DialogTitleContainer>
                        <DialogTitle>Participant PDF ({participantUid})</DialogTitle>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </DialogTitleContainer>
                    <DialogContent>
                        <Center>
                            <ScanParticipantReportPDFAction
                                scanReportPDFProps={scanReportPDFProps}
                                assessmentMap={assessmentMap}
                            />
                        </Center>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};
