import React from 'react';
import { Program, PatientRecord } from '@/common/types';
import { SlideUpTransition } from '@/components/common/Transitions/SlideTransition';
import { PatientHEPPrintView } from './HEPPrintView';
import { HEPBuilderModalWrapper } from './styles';

interface Props {
    open: boolean;
    onClose: (program: Program | null) => void;
    patientRecord?: PatientRecord;
    program: Program;
}

export const HEPPrintModal = (props: Props) => {
    const { onClose, patientRecord, open, program } = props;
    const handleCloseClick = () => {
        onClose(null);
    };

    return (
        <HEPBuilderModalWrapper
            onClose={() => {
                onClose(null);
            }}
            open={open}
            TransitionComponent={SlideUpTransition}
        >
            <PatientHEPPrintView program={program} patientRecord={patientRecord} handleClose={handleCloseClick} />
        </HEPBuilderModalWrapper>
    );
};
