import { styled } from '@mui/material';

export const LoginThemeContainer = styled('div')(({ theme }) => ({
    '--amplify-primary-color': theme.palette.primary[500],
    '--amplify-primary-tint': theme.palette.primary[400],
    '--amplify-primary-shade': theme.palette.primary[700],
    '--amplify-font-family': theme.typography.fontFamily,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));
