import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { CreateHEPDto } from '@/common/types';
import { HEPBlockWrapper } from '../../HEPs/HEPBlock/styles';
import { useHEPBuilderContext } from '../state/hooks/HEPBuilderContext';
import { BuiltHEPBlockContent } from './BuiltHEPBlockContent';

const reorder = (list: CreateHEPDto, startIndex: number, endIndex: number) => {
    const result = Array.from(list.blocks);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return { ...list, blocks: result };
};

export const DraggableBuiltHEPBlocks = () => {
    const { hepDetails, hepBuilderActions, patientRecord } = useHEPBuilderContext();
    const [state, setState] = useState({ heps: hepDetails });

    useEffect(() => {
        setState({ heps: hepDetails });
    }, [hepDetails]);

    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const heps = reorder(state.heps, result.source.index, result.destination.index);
        const dragBlock = hepDetails.blocks[result.source.index];

        hepBuilderActions?.setBlocks(
            update(hepDetails.blocks, {
                $splice: [
                    [result.source.index, 1],
                    [result.destination.index, 0, dragBlock],
                ],
            }),
        );

        setState({ heps });
    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {state.heps.blocks.map((block, index) => {
                                return (
                                    <Draggable
                                        key={block.uuid || block.id}
                                        draggableId={`${block.uuid || block.id}`}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <HEPBlockWrapper
                                                $snapshot={snapshot.isDragging}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                data-cy="draggable-built-program-block"
                                            >
                                                <BuiltHEPBlockContent
                                                    block={block}
                                                    index={index}
                                                    isDraggable={true}
                                                    patientRecord={patientRecord}
                                                />
                                            </HEPBlockWrapper>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};
