import React from 'react';
import { Tooltip, styled, TooltipProps, tooltipClasses } from '@mui/material';

export const CapitalizedTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        textTransform: 'capitalize',
    },
});
