import { styled } from '@mui/material';

export const SettingsInputWrapper = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        maxWidth: '33%',
    },
}));

export const ResetOverrideButtonWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
}));
