import React, { useEffect, useState } from 'react';
import { ExerVisionProvider } from '@exerai/vision-engine-web';
import { Box, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useRecoilValue } from 'recoil';
import { Products } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useMuiUtils } from '@/common/hooks/MuiUtils';
import { toasts } from '@/common/toasts';
import { ScanSessionAdminRow } from '@/common/types';
import { getAssessmentIdDashName, getUrlSafeAssessmentName } from '@/common/utils';
import { SessionReplay } from '@/components/Scan/Replay/SessionReplay';
import { DateRangeActions } from '@/components/common/DateRangeActions/DateRangeActions';
import { CalendarIcon } from '@/components/common/Icons/CalendarIcon';
import { SessionReplayAction } from '@/components/common/Pose/SessionReplayAction';
import { TableActionsWrapper } from '@/components/common/Table/styles';
import { useAssessmentsContext } from '@/components/context/hooks/AssessmentsContext';
import { DateRangeState } from '@/recoil/atoms/dateRangeAtom';
import { resultIsError } from '@/services/HttpService';
import { AdminOutcomesActionContainer } from './AdminOutcomesActionContainer';
import { AdminScanSearch } from './AdminScanSearch';
import { AdminScanTable } from './AdminScanTable';
import { ScanDetailsAction } from './ScanDetailsAction';
import { ScanSessionPoseLogAction } from './ScanSessionPoseLogAction';
import { ScanUUIDDisplay } from './ScanUUIDDisplay';
import { AdminDateRangeWrapper } from './styles';

export const AdminScanTableContainer = () => {
    const { httpService } = useHttpContext();
    const { errorToast } = toasts;
    const { palette } = useMuiUtils();
    const [rows, setRows] = useState<ScanSessionAdminRow[]>([]);
    const [originalRows, setOriginalRows] = useState<ScanSessionAdminRow[]>([]);
    const [searchTerm, setSearchTerm] = useState<string | null>();
    const [isLoading, setIsLoading] = useState(false);
    const dateRange = useRecoilValue(DateRangeState);
    const { assessmentsDict } = useAssessmentsContext();

    const getSessions = async () => {
        setIsLoading(true);
        const start = dateRange.startDate.toISOString();
        const end = dateRange.endDate.toISOString();
        const res = await httpService.adminGetScanSessions(start, end);

        if (!res || resultIsError(res)) {
            errorToast(`Could not load scan sessions. ${res?.message || ''}`);
        } else {
            const resultRows = res.map(
                ({
                    id,
                    createdDate,
                    updatedDate,
                    sessionUUID,
                    session,
                    location,
                    practitioner,
                    organization,
                    artifacts,
                    participant,
                }) => {
                    const assessmentName = getAssessmentIdDashName(session?.data.assessmentId, assessmentsDict);
                    return {
                        id,
                        createdDate,
                        updatedDate,
                        sessionUUID,
                        session,
                        locationName: location?.name || '',
                        practitionerName: practitioner ? `${practitioner.firstName} ${practitioner.lastName}` : '',
                        assessmentName,
                        organizationName: organization?.name || '',
                        organizationId: organization?.id,
                        action: {
                            sessionUUID,
                            artifacts,
                            sessionIdentifier: `${DateTime.fromISO(createdDate).toFormat(
                                'MM-dd-yyyy',
                                // eslint-disable-next-line prettier/prettier
                            )}${getUrlSafeAssessmentName(session?.data.assessmentId, assessmentsDict)}${(participant && `_${participant.uid}`) || ''
                                // eslint-disable-next-line prettier/prettier
                                }`,
                        },
                        participantDetails: {
                            uid: (participant && participant.uid) || '',
                            archivedDate: (participant && participant.isArchived && participant.archivedDate) || null,
                        },
                    };
                },
            );
            setOriginalRows(resultRows);
            setRows(resultRows);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await getSessions();
        })();
    }, [dateRange, assessmentsDict]);

    useEffect(() => {
        if (!searchTerm) {
            setRows(originalRows);
            return;
        }
        setRows(
            originalRows.filter((row) => {
                const sst = searchTerm.toLowerCase();
                const fullString = (
                    (row.sessionUUID || '') +
                    (row.organizationName || '') +
                    (row.practitionerName || '') +
                    (row.locationName || '') +
                    (row.assessmentName || '') +
                    (row.session?.data?.assessmentId || '') +
                    (row?.participantDetails?.uid || '') +
                    (row?.participantDetails?.archivedDate || '')
                ).toLowerCase();
                return fullString.includes(sst);
            }),
        );
    }, [searchTerm, originalRows]);

    const columnStructure = [
        {
            Header: 'Organization',
            accessor: 'organizationName',
            width: '15%',
            Cell: ({ value }) => <>{value}</>,
        },
        {
            Header: 'Assessment',
            accessor: 'assessmentName',
            width: '15%',
            Cell: ({ value }) => <>{value}</>,
        },
        {
            Header: 'Created',
            accessor: 'createdDate',
            width: '15%',
            Cell: ({ value }) => (
                <>
                    {value ? (
                        <>
                            {DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED)}{' '}
                            {DateTime.fromISO(value).toLocaleString(DateTime.TIME_WITH_SECONDS)}
                        </>
                    ) : (
                        ''
                    )}
                </>
            ),
        },
        {
            Header: 'UUID',
            accessor: 'sessionUUID',
            width: '10%',
            Cell: ({ value }) => <ScanUUIDDisplay uuid={value} />,
        },
        {
            Header: 'UID',
            accessor: 'participantDetails',
            width: '10%',
            Cell: ({ value }) => (
                <>
                    <Typography>{value.uid}</Typography>
                    {value.archivedDate && (
                        <Typography variant="subtitle2">
                            <Tooltip
                                title={`Archived on: ${DateTime.fromISO(value.archivedDate).toLocaleString(
                                    DateTime.DATETIME_SHORT_WITH_SECONDS,
                                )}`}
                            >
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    Archived
                                    <Box style={{ color: palette.info[500], display: 'flex', marginLeft: 4 }}>
                                        <CalendarIcon />
                                    </Box>
                                </Box>
                            </Tooltip>
                        </Typography>
                    )}
                </>
            ),
        },
        {
            Header: 'Action',
            accessor: 'action',
            width: '35%',
            Cell: ({ row, value: { sessionUUID, artifacts, sessionIdentifier } }) => {
                return artifacts.includes('pose-log') ? (
                    <TableActionsWrapper style={{ justifyContent: 'flex-start' }}>
                        <SessionReplayAction product={Products.SCAN}>
                            <ExerVisionProvider>
                                <SessionReplay client="scan" sessionUUID={sessionUUID} />
                            </ExerVisionProvider>
                        </SessionReplayAction>
                        <AdminOutcomesActionContainer
                            org={{ id: row.original.organizationId, name: row.original.organizationName }}
                            scanSessionOutcomeData={row.original}
                            sessionIdentifier={sessionIdentifier}
                        />
                        <ScanSessionPoseLogAction sessionUUID={sessionUUID} />
                        <ScanDetailsAction row={row.original as ScanSessionAdminRow} />
                    </TableActionsWrapper>
                ) : (
                    'No pose logs available'
                );
            },
        },
    ];

    return (
        <>
            <AdminDateRangeWrapper>
                <DateRangeActions />
            </AdminDateRangeWrapper>
            <AdminScanSearch setSearchTerm={setSearchTerm} />
            <AdminScanTable columnStructure={columnStructure} data={rows} isLoading={isLoading} />
        </>
    );
};
