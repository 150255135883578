import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

interface GlobalFilterState {
    locationId: number | null;
    products: string[] | [];
}

export const globalFilterState = atom<GlobalFilterState>({
    key: 'globalFilterState',
    default: {
        locationId: null,
        products: [],
    },
    effects: [persistAtom],
});
