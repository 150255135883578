import { styled, ListItem as MuiListItem, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

export const ListItem = styled(MuiListItem)(({ theme }) => ({
    width: '100%',
    padding: 0,

    '&:last-of-type a': {
        borderBottom: 'none',
    },
}));

export const DashboardListLink = styled(Link)(({ theme }) => ({
    width: '100%',
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    color: theme.palette.grey[900],

    '&:hover': {
        color: theme.palette.primary[500],
    },

    '.MuiGrid-item': {
        display: 'flex',
        alignItems: 'center',
    },
}));

export const DashboardListGridTitle = styled(Grid)(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: 'inherit',
}));
