import React, { useEffect, useState } from 'react';
import { ProviderRoles } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { Location } from '@/common/types';
import { resultIsError } from '@/services/HttpService';
import { LocationsList } from './LocationsList';

export const LocationsListContainer = () => {
    const { httpService } = useHttpContext();
    const { getRole, user } = useExerUser();
    const [locations, setLocations] = useState<Location[]>();
    const [userLocationIds, setUserLocationIds] = useState<number[]>([]);
    const { errorToast } = toasts;

    useEffect(() => {
        (async () => {
            const res = await httpService.getLocations();
            if (resultIsError(res)) {
                errorToast('Unable to get locations');
                return;
            }
            setLocations(res);
        })();
    }, []);

    useEffect(() => {
        setUserLocationIds(user.locations?.map((location) => location.id) || []);
    }, [user.locations]);

    const userLocations = locations?.filter((location) => {
        return userLocationIds?.includes(location.id!);
    });

    return (
        <LocationsList
            locations={locations}
            userLocations={userLocations}
            showTabs={getRole() === ProviderRoles.PORTAL_PRACTITIONER || userLocationIds.length > 0}
        />
    );
};
