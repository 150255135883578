import React from 'react';
import { DateTime } from 'luxon';
import { useRecoilValue } from 'recoil';
import { DateRangeSelector } from '@/recoil/selectors/dateRangeSelector';
import { PROAnswerDate, PROAnswerWrapper, ResponseChip, PROQuestionWrapper, PROQuestionTitle } from './styles';
import { PROAnswersByQuestion } from './types';

interface PROQuestionProps {
    question: PROAnswersByQuestion;
}

export const PROQuestion = (props: PROQuestionProps) => {
    const { question } = props;
    const { startDateMillis, endDateMillis } = useRecoilValue(DateRangeSelector);

    const answers = question.answers
        .map((answer) => {
            const processedDate = DateTime.fromISO(answer.dateTime).startOf('day').toMillis();
            return processedDate >= startDateMillis && processedDate <= endDateMillis ? (
                <PROAnswerWrapper key={answer.dateTime}>
                    <PROAnswerDate>{DateTime.fromISO(answer.dateTime).toFormat('LLLL dd yyyy')}:</PROAnswerDate>{' '}
                    {<ResponseChip label={`${answer.value}`} size="small" color="info" />}
                </PROAnswerWrapper>
            ) : null;
        })
        .filter((a) => !!a);

    return !question.isChartable && answers.length ? (
        <PROQuestionWrapper>
            <PROQuestionTitle variant="h5">{question.text}</PROQuestionTitle>
            <div>{answers}</div>
        </PROQuestionWrapper>
    ) : null;
};
