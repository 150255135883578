import React from 'react';
import { Box, Tooltip, styled, TooltipProps, tooltipClasses } from '@mui/material';

export const FABTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.info[500],
        boxShadow: theme.shadows[4],
        fontSize: 16,
        fontWeight: '500',
        borderRadius: 14,
    },
    [`& .MuiTooltip-arrow::before`]: {
        backgroundColor: theme.palette.info[500],
    },
}));

export const ButtonTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary[500],
        boxShadow: theme.shadows[4],
        fontWeight: '500',
    },
    [`& .MuiTooltip-arrow::before`]: {
        backgroundColor: theme.palette.primary[500],
    },
}));

export const ControlsWrapper = styled(Box)(({ theme }) => ({
    position: 'sticky',
    bottom: '14px',
    right: '14px',
    top: 'calc(100% - 44px)',
    left: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 999,
}));

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
    borderRadius: '34px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    backgroundColor: theme.palette.primary[500],
    // marginRight: '14px',
}));
