import { styled } from '@mui/material';

export const OrganizationTitleWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
});

export const OrganizationTitleTimezone = styled('div')(({ theme }) => ({
    color: theme.palette.grey[700],
    marginLeft: theme.spacing(2),
}));

export const OrganizationTitleLeft = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

export const OrganizationTitleRight = styled('div')({});
