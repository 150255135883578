import { styled, Grid, Typography } from '@mui/material';

export const HEPTemplatesCardListWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

export const TemplateActionsWrapper = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
}));

export const TemplateCardListTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

export const ListControlsContainer = styled(Grid)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    display: 'flex',
    position: 'relative',

    [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));
