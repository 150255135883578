import React, { useState } from 'react';
import { Grid, TextField, Box } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { Button } from '@/components/common/button/button';
import { PathsState } from '@/recoil/atoms/pathsAtom';
import { Path } from './Path/Path';

export const PathsContainer = () => {
    // const [HEPTemplates, setHEPTemplates] = useRecoilState(hepTemplatesState);
    const paths = useRecoilValue(PathsState);
    const [searchValue, setSearchValue] = useState('');
    // const [hasFetched, setHasFetched] = useState(false);
    // const { httpService } = useHttpContext();

    // const getHEPTemplates = async () => {
    //     const response = await httpService.getHEPTemplates();
    //     if (resultIsError(response)) {
    //         return;
    //     }
    //     setHEPTemplates(response);
    // };

    // useEffect(() => {
    //     (async () => {
    //         if (!HEPTemplates) {
    //             await getHEPTemplates();
    //         }
    //     })();
    // }, []);

    return (
        <>
            <PageTitle title="Care Paths" />
            <Grid container spacing={1}>
                <Grid item sm={8} xs={12}>
                    <Box
                        component="form"
                        sx={{
                            width: '60%',
                            '& > :not(style)': { mb: 3, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="outlined-basic"
                            label="Search"
                            variant="outlined"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </Box>
                </Grid>
                <Grid
                    item
                    sm={4}
                    xs={12}
                    style={{ alignItems: 'flex-start', display: 'flex', justifyContent: 'flex-end' }}
                >
                    <Button size="medium" onClick={() => null}>
                        Add Path
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                {paths &&
                    paths.map((path, i) => {
                        if (i === 0) console.log('path1', path);
                        return (
                            <Grid key={path.name} item sm={6} xs={12}>
                                <Path title={path.name} phases={path.phases} id={path.id} />
                            </Grid>
                        );
                    })}
            </Grid>
        </>
    );
};
