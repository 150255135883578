import React from 'react';
import { Grid, ListItemButton } from '@mui/material';
import { Paths } from '@/common/const';
import { DashboardDataProps } from '@/components/Dashboard/DashboardList/DashboardList';
import { DashboardListLink, ListItem, DashboardListGridTitle } from './styles';

interface DashboardListItemProps {
    row: DashboardDataProps;
}

export const DashboardListItem = (props: DashboardListItemProps) => {
    const { row } = props;

    return (
        <ListItem disableGutters>
            <ListItemButton component={DashboardListLink} to={`${Paths.patients}${row?.id}`}>
                <Grid container key={row.id} columnSpacing={4}>
                    <DashboardListGridTitle item xs={8}>
                        {row.name}
                    </DashboardListGridTitle>
                    <Grid item xs={4} display="flex" justifyContent="flex-end">
                        {row.details}
                    </Grid>
                </Grid>
            </ListItemButton>
        </ListItem>
    );
};
