import React from 'react';
import { ScanSessionAdminRow } from '@/common/types';
import { getAssessmentNameDashID, preventParentLinkClick } from '@/common/utils';
import { Button } from '@/components/common/button/button';
import { useAssessmentsContext } from '@/components/context/hooks/AssessmentsContext';
import { useModalContext } from '@/components/context/hooks/ModalContext';
import { ScanSessionDetailedDetails, ScanSessionDetailsRow, ScanSessionResultsRow } from './styles';

interface ScanDetailsActionProps {
    row: ScanSessionAdminRow;
}
export const ScanDetailsAction = (props: ScanDetailsActionProps) => {
    const { row } = props;
    const { setDialog, setDialogOpen } = useModalContext();
    const { assessmentsDict } = useAssessmentsContext();

    const handleClick = (e: React.MouseEvent) => {
        preventParentLinkClick(e);
        setDialog(
            'Session Details',
            <>
                <ScanSessionDetailsRow>
                    <strong>Organization: </strong>
                    {row.organizationName}
                </ScanSessionDetailsRow>
                <ScanSessionDetailsRow>
                    <strong>Location: </strong>
                    {row.locationName}
                </ScanSessionDetailsRow>
                <ScanSessionDetailsRow>
                    <strong>Provider Name: </strong>
                    {row.practitionerName}
                </ScanSessionDetailsRow>
                <ScanSessionDetailsRow>
                    <strong>Session UUID: </strong>
                    {row.sessionUUID}
                </ScanSessionDetailsRow>
                <ScanSessionDetailsRow>
                    <strong>Created: </strong>
                    {row.createdDate}
                </ScanSessionDetailsRow>
                <ScanSessionDetailsRow>
                    <strong>Updated: </strong>
                    {row.updatedDate}
                </ScanSessionDetailsRow>
                {row.session && (
                    <ScanSessionDetailsRow>
                        <strong>Detailed Details: </strong>
                        <ScanSessionDetailedDetails>
                            {row.session.data.assessmentId && (
                                <ScanSessionDetailsRow>
                                    <strong>Assessment: </strong>
                                    {getAssessmentNameDashID(row.session.data.assessmentId, assessmentsDict)}
                                </ScanSessionDetailsRow>
                            )}
                            {row.session.data.results && (
                                <ScanSessionDetailsRow>
                                    <strong>Results: </strong>
                                    {row.session.data.results.map((result) => {
                                        return (
                                            <ScanSessionResultsRow key={result.id}>
                                                {result.name}:{' '}
                                                <strong>
                                                    {result.value}
                                                    {`${result.units === '°' ? '' : ' '}${result.units}`}
                                                </strong>
                                            </ScanSessionResultsRow>
                                        );
                                    })}
                                </ScanSessionDetailsRow>
                            )}
                            {row.session.meta && (
                                <ScanSessionDetailsRow>
                                    <strong>Meta: </strong>
                                    {Object.entries(row.session.meta).map(([key, value]) => {
                                        return (
                                            <ScanSessionResultsRow key={key}>
                                                {key}: <strong>{value}</strong>
                                            </ScanSessionResultsRow>
                                        );
                                    })}
                                </ScanSessionDetailsRow>
                            )}
                        </ScanSessionDetailedDetails>
                    </ScanSessionDetailsRow>
                )}
            </>,
        );
        setDialogOpen(true);
    };

    return (
        <Button variant="secondary" onClick={handleClick} size="small">
            Details
        </Button>
    );
};
