import React, { memo, useState, useCallback, useEffect, forwardRef } from 'react';
import { Pagination } from '@mui/material';
import usePagination from '@/common/hooks/Pagination';
import { Exercise } from '@/common/types';
import { ExerLoader } from '@/components/common/ExerLoader/ExerLoader';
import { useExerciseListContext } from '../ExerciseList/state/hooks/ExerciseListContext';
import { HEPBuilderExerciseCard } from './HEPBuilderExerciseCard';
import {
    HEPBuilderExercisesWrapper,
    HEPBuilderExercisesItemWrapper,
    HEPBuilderExercisesLoader,
    HEPBuilderExercisePagination,
} from './styles';

interface GridSizeProps {
    readonly xs?: number;
    readonly sm?: number;
    readonly md?: number;
    readonly lg?: number;
    readonly xl?: number;
    readonly xxl?: number;
    readonly chromebook?: number;
}
interface ExerciseLibraryProps {
    readonly gridSize?: GridSizeProps;
    readonly addToHepCtas?: boolean;
}

const SEARCH_PAGE_ITEM_LIMIT = 36;

export const HEPBuilderExercisesContainer = forwardRef((props: ExerciseLibraryProps, ref: any) => {
    const { loading, exercises, filtered } = useExerciseListContext();
    const { jump, setPaginatedData, currentData, jumpToBeginning, currentPage } = usePagination(SEARCH_PAGE_ITEM_LIMIT);
    const shownExercises = filtered || exercises;
    const [recordsPerPage] = useState(SEARCH_PAGE_ITEM_LIMIT);

    const scrollToTop = useCallback(() => {
        if (ref) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [ref]);

    const handlePaginationChange = (_: React.ChangeEvent<unknown>, p: number) => {
        scrollToTop();
        jump(p);
    };

    useEffect(() => {
        setPaginatedData(shownExercises ?? []);
        jumpToBeginning();
    }, [shownExercises]);

    const nPages = shownExercises ? Math.ceil(shownExercises.length / recordsPerPage) : 0;

    return (
        <HEPBuilderExercisesWrapper container spacing={{ xs: 2, md: 3 }}>
            {loading ? (
                <HEPBuilderExercisesLoader>
                    <ExerLoader />
                </HEPBuilderExercisesLoader>
            ) : currentData ? (
                <>
                    <HEPBuilderExercises shownExercises={currentData as Exercise[]} {...props} />
                    {!nPages || nPages <= 1 ? null : (
                        <HEPBuilderExercisePagination>
                            <Pagination
                                count={nPages}
                                page={currentPage}
                                color="primary"
                                variant="outlined"
                                shape="rounded"
                                onChange={handlePaginationChange}
                            />
                        </HEPBuilderExercisePagination>
                    )}
                </>
            ) : null}
        </HEPBuilderExercisesWrapper>
    );
});

HEPBuilderExercisesContainer.displayName = 'HEPBuilderExercisesContainer';

interface HEPBuilderExercisesProps {
    shownExercises: Exercise[];
    readonly gridSize?: GridSizeProps;
    readonly addToHepCtas?: boolean;
}

const HEPBuilderExercisesMemo = memo((props: HEPBuilderExercisesProps) => {
    const { gridSize, addToHepCtas = true, shownExercises } = props;

    return (
        <>
            {shownExercises
                ? shownExercises.map((exercise, key) => {
                      return (
                          <HEPBuilderExercisesItemWrapper
                              key={exercise.id}
                              item
                              xs={gridSize ? gridSize.sm : 12}
                              sm={gridSize ? gridSize.sm : 6}
                              md={gridSize ? gridSize.md : 6}
                              lg={gridSize ? gridSize.lg : 6}
                              chromebook={gridSize ? gridSize.chromebook : 6}
                              xl={gridSize ? gridSize.xl : 4}
                              xxl={gridSize ? gridSize.xxl : 3}
                          >
                              <HEPBuilderExerciseCard exercise={exercise} addToHepCtas={addToHepCtas} />
                          </HEPBuilderExercisesItemWrapper>
                      );
                  })
                : null}
        </>
    );
});

HEPBuilderExercisesMemo.displayName = 'HEPBuilderExercises';

export const HEPBuilderExercises = HEPBuilderExercisesMemo;
