import {
    styled,
    Autocomplete,
    Badge,
    Box,
    Menu,
    Typography,
    FormControlLabel as MuiFormControlLabel,
} from '@mui/material';

export const LocationAutocomplete = styled(Autocomplete)(({ theme }) => ({
    width: '100%',
    background: 'white',
    borderRadius: theme.shape.borderRadius,
    minWidth: 200,

    '& .MuiInputBase-input': {
        fontWeight: 500,
    },

    [theme.breakpoints.up('md')]: {
        width: '300px',
        marginTop: theme.spacing(0),
    },

    '& .MuiAutocomplete-endAdornment': {
        top: 'calc(50% - 12px)',
    },
}));

export const LocationFilterWrapper = styled('div')(({ theme }) => ({
    '&& .MuiInputLabel-root': {
        transform: `translate(14px,8px) scale(1)`,
    },
    '&& .MuiInputLabel-shrink': {
        transform: 'translate(14px,  -9px) scale(.75)',
    },
    '&& .MuiSvgIcon-fontSizeSmall': {
        width: '.6em',
        height: '.6em',
    },
    '&& .MuiAutocomplete-input': {
        padding: 0,
    },
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,

    '& .MuiButton-root': {
        background: 'white',
        paddingTop: theme.spacing(0.7),
        paddingBottom: theme.spacing(0.7),
        borderColor: theme.palette.grey[400],
    },
}));

export const MenuWrapper = styled(Menu)(({ theme }) => ({
    '.MuiList-root': {
        padding: '0',
    },
}));

export const MenuContent = styled(Box)(({ theme }) => ({
    minWidth: theme.spacing(37.5),
    borderRadius: theme.shape.borderRadius,
    background: 'white',

    '.MuiListItemIcon-root': {
        paddingTop: '9px',
        paddingBottom: '9px',
        minWidth: '28px',
        fontSize: theme.typography.h5.fontSize,
        marginLeft: '-1px',
    },
    '.MuiFormControl-root': {
        width: '100%',
    },
}));

export const MenuHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 2),
}));

export const MenuSelectedBadge = styled(Badge)(({ theme }) => ({
    '.MuiBadge-badge': {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary[600],
        width: '15px',
        height: '15px',
        minWidth: 'auto',
        fontWeight: '500',
        fontSize: theme.typography.caption.fontSize,
    },
}));

export const MenuSelectedAllText = styled('span')<{ $isActive }>(({ theme, $isActive }) => ({
    transform: $isActive ? 'scale(1)' : 'scale(0)',
    transformOrigin: '0% 50%',
    transition: 'transform 0.3s ease-in-out',
    paddingRight: theme.spacing(0.5),
}));

export const MenuItemText = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
}));

export const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
    width: '100%',
}));
