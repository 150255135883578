import { FormControl, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const AuthForm = styled('form')(({ theme }) => ({
    maxWidth: '340px',
    width: '100%',
    marginTop: theme.spacing(4),
}));

export const AuthPasswordControl = styled(FormControl)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

export const AuthLink = styled(Link)(({ theme }) => ({
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'none',
    '&:hover': {
        color: theme.palette.primary[500],
    },
}));
