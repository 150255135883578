import React from 'react';
import { useExerUser } from '@/common/hooks/ExerUser';
import { isAdmin } from '@/common/utils';
import { PractitionersProvider } from '../Practitioners/context/PractitionersContext';
import { OrganizationWrapper, LocationsAddLocation } from '../styles';
import { AddLocation } from './AddLocation/AddLocation';
import { LocationsListContainer } from './LocationsListContainer';

export const Locations = () => {
    const { user } = useExerUser();

    return (
        <OrganizationWrapper>
            {isAdmin(user) && (
                <LocationsAddLocation>
                    <AddLocation />
                </LocationsAddLocation>
            )}
            <PractitionersProvider>
                <LocationsListContainer />
            </PractitionersProvider>
        </OrganizationWrapper>
    );
};
