import React from 'react';
import { Grid } from '@mui/material';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { AssessmentList } from './Assessments/AssessmentList';
import { HandWristCard } from './Modules/HandWristCard';
import { HipSpineInstabilityCard } from './Modules/HipSpineInstabilityCard';
import { MobilityCard } from './Modules/MobilityCard';

export const ProductsContainer = () => {
    return (
        <>
            <PageTitle title="Modules" />
            <Grid container spacing={1}>
                {/*  */}
                <Grid container item spacing={3}>
                    <Grid item xs={12}>
                        <h3 style={{ margin: 0 }}>Current Modules</h3>
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                        <MobilityCard />
                    </Grid>
                    {/*  */}
                    <Grid item lg={4} md={6} xs={12}>
                        <HipSpineInstabilityCard />
                    </Grid>
                    <Grid item lg={4} md={6} xs={12}>
                        <HandWristCard />
                    </Grid>
                </Grid>
                {/*  */}
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} style={{ marginTop: 22 }}>
                    <h3 style={{ marginBottom: 0, marginTop: 22 }}>Assessments</h3>
                </Grid>
                <AssessmentList />
            </Grid>
        </>
    );
};
