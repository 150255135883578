import { styled } from '@mui/material';
import { Maybe } from '@/common/types';
import { FactorCategory } from '../types/FactorCategory.enum';
import { getFactorColor } from '../utils';

export const OutcomeBoxWrapper = styled('div')<{ $factorCategory: Maybe<FactorCategory> }>(
    ({ theme, $factorCategory }) => {
        const mainColor = $factorCategory ? getFactorColor($factorCategory) : 'inherit';
        return {
            display: 'flex',
            maxWidth: 382,
            height: 217,
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            gap: 8,
            margin: '0 auto',
            textAlign: 'center',
            padding: theme.spacing(4),
            border: `4px solid ${mainColor}`,
            color: `${mainColor}`,
            borderRadius: 6,
        };
    },
);

export const OutcomeResultName = styled('div')(({ theme }) => ({
    fontSize: 18,
}));

export const OutcomeResultValue = styled('div')(({ theme }) => ({
    fontSize: 48,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'stretch',
    display: 'flex',
}));

export const OutcomeResultUnitWrapper = styled('div')<{ $unit: string }>(({ theme, $unit }) => ({
    paddingLeft: $unit === '°' ? 0 : 8,
    display: 'flex',
    alignItems: $unit === '°' ? 'flex-start' : 'flex-end',
}));

export const OutcomeResultUnit = styled('div')(({ theme }) => ({
    fontSize: 24,
    paddingBottom: theme.spacing(0.5),
}));

export const OutcomeFactorLabel = styled('div')(({ theme }) => ({
    textAlign: 'right',
    alignSelf: 'bottom',
    width: '100%',
}));
