import React, { FC } from 'react';
import { Maybe, ScanSessionResult } from '@/common/types';
import { FactorCategory } from '../types/FactorCategory.enum';
import { getOutcomeBoxData } from '../utils';
import { OutcomeBox } from './OutcomeBox';

interface OutcomeBoxContainerProps {
    result: ScanSessionResult;
    factorResultCategory: Maybe<FactorCategory>;
}

export const OutcomeBoxContainer: FC<OutcomeBoxContainerProps> = ({ result, factorResultCategory }) => {
    const outcomeBoxData = getOutcomeBoxData(result, factorResultCategory);
    return <OutcomeBox outcomeBoxData={outcomeBoxData} />;
};
