import { Clear, Search } from '@mui/icons-material';
import { styled, TextField } from '@mui/material';

export const ExerciseSearchWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
}));

export const SearchInputWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '80%',
}));

export const SearchInput = styled(TextField)(({ theme }) => ({
    width: '100%',
}));

export const ClearFilter = styled(Clear)(({ theme }) => ({
    color: theme.palette.grey[500],
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    right: '20px',
    transform: 'translateY(-50%)',

    '&&&': {
        fontSize: 20,
    },
}));

export const SearchFilter = styled(Search)(({ theme }) => ({
    color: theme.palette.grey[500],
    position: 'absolute',
    top: '50%',
    right: '20px',
    transform: 'translateY(-50%)',

    '&&&': {
        fontSize: 20,
    },
}));
