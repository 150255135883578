import React from 'react';
import { actions } from '@exerai/react-core';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import { useExerUser } from '@/common/hooks/ExerUser';
import { mixpanel } from '@/common/mixpanel';
import { Button } from '@/components/common/button/button';
import { NotFoundWrapper } from './styles';

export const NotFoundProviderRoute = () => {
    const { dispatchUser } = useExerUser();

    const handleLogout = async () => {
        try {
            const h = await Auth.signOut();
            dispatchUser({ type: actions.LOGOUT });
            mixpanel.track('Signed Out');
            mixpanel.reset();
        } catch (e) {
            Sentry.captureException(e);
        }
    };

    return (
        <NotFoundWrapper>
            <h3>We didn&apos;t find a provider account for this login.</h3>
            <p>
                If you believe this is a mistake, please contact us at{' '}
                <a href="mailto:customers@exerai.com">customers@exerai.com</a>.
            </p>
            <Button onClick={handleLogout}>Log Out</Button>
        </NotFoundWrapper>
    );
};
