import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { TemplateCardCheckmarkIcon, TemplateCardCheckmarkOutlineIcon } from './styles';

interface TemplateCardCheckboxProps {
    onClick: () => void;
}

export const TemplateCardCheckbox = (props: TemplateCardCheckboxProps) => {
    const { onClick } = props;
    const [isChecked, setIsChecked] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsChecked(!isChecked);
        onClick();
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                {!isChecked ? <TemplateCardCheckmarkOutlineIcon /> : <TemplateCardCheckmarkIcon />}
            </IconButton>
        </>
    );
};
