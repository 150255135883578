import React from 'react';
import { Close } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { Program, PatientRecord } from '@/common/types';
import { HEPDetails } from '@/components/Health/HEPs/HEPDetails/HEPDetails';
import {
    DrawerWrapper,
    DrawerContentWrapper,
    DrawerTitle,
    DrawerTitleDivider,
    DrawerTitleWrapper,
    DrawerCloseButton,
    DrawerFooterActionsWrapper,
} from '@/components/common/Drawer/styles';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { patientHEPDrawerState } from '@/recoil/atoms/patientHEPDrawer';

interface PatientHEPDrawerProps {
    program: Program;
    patientRecord: PatientRecord;
    handleSubmit: () => void;
    closeDrawer: () => void;
}

export const PatientHEPDrawer = (props: PatientHEPDrawerProps) => {
    const { program, handleSubmit, closeDrawer } = props;
    const patientHEPDrawer = useRecoilValue(patientHEPDrawerState);

    return (
        <DrawerWrapper
            anchor="right"
            open={patientHEPDrawer.drawerIsActive}
            onClose={closeDrawer}
            data-cy="patient-program-drawer-wrapper"
        >
            <DrawerTitleWrapper>
                <DrawerTitle variant="h5">View Program</DrawerTitle>
                <DrawerCloseButton onClick={closeDrawer} size="small">
                    <Close />
                </DrawerCloseButton>
            </DrawerTitleWrapper>
            <DrawerTitleDivider />
            <DrawerContentWrapper>
                <HEPDetails program={program} />
            </DrawerContentWrapper>
            <DrawerFooterActionsWrapper>
                <SubmitCancelButtons
                    submitFunction={handleSubmit}
                    cancelFunction={closeDrawer}
                    submitText="Edit"
                    cancelText="Close"
                />
            </DrawerFooterActionsWrapper>
        </DrawerWrapper>
    );
};
