import React from 'react';
import xOff from '@/images/x-off.svg';
import { ErrorWrapper } from './styles';

export const UnhandledError = () => {
    return (
        <ErrorWrapper>
            <img src={xOff} />
            <h3>There was an issue we were not able to recover from.</h3>
        </ErrorWrapper>
    );
};
