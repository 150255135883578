import React, { useEffect, useState } from 'react';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { Location } from '@/common/types';
import { isAdmin } from '@/common/utils';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { resultIsError } from '@/services/HttpService';
import { OrganizationWrapper, LocationsAddLocation } from '../styles';
import { AddPractitioner } from './AddPractitioner/AddPractitioner';
import { PractitionersListContainer } from './PractitionersListContainer';
import { PractitionersProvider } from './context/PractitionersContext';

export const Practitioners = () => {
    const { user } = useExerUser();
    const { httpService } = useHttpContext();
    const [locations, setLocations] = useState<Location[]>();

    useEffect(() => {
        (async () => {
            const res = await httpService.getLocations();
            if (resultIsError(res)) {
                return;
            }
            setLocations(res);
        })();
    }, []);

    return (
        <OrganizationWrapper>
            <PageTitle title="Providers" />
            <PractitionersProvider>
                <LocationsAddLocation>
                    {isAdmin(user) && <AddPractitioner isAbsolute={true} locations={locations} />}
                </LocationsAddLocation>
                <PractitionersListContainer locations={locations} />
            </PractitionersProvider>
        </OrganizationWrapper>
    );
};
