import { useTheme, useMediaQuery, Palette } from '@mui/material';

export const useMuiUtils = (): {
    palette: Palette;
    xsmallBreakpoint: boolean;
    smallBreakpoint: boolean;
    mediumBreakpoint: boolean;
    largeBreakpoint: boolean;
} => {
    const theme = useTheme();

    const palette = theme.palette;

    const xsmallBreakpoint = useMediaQuery(theme.breakpoints.down('xs'));
    const smallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
    const mediumBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
    const largeBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));

    return { palette, xsmallBreakpoint, smallBreakpoint, mediumBreakpoint, largeBreakpoint };
};
