import React from 'react';
import { SvgIcon } from '@mui/material';

export const CheckMarkIcon = (props) => {
    return (
        <>
            <SvgIcon width="14" height="10" viewBox="0 0 14 10" fill="none" {...props}>
                <path
                    d="M1.29521 4.25541C1.17943 4.14359 1.02436 4.08171 0.863406 4.08311C0.70245 4.08451 0.548482 4.14907 0.434665 4.26289C0.320848 4.3767 0.256288 4.53067 0.254889 4.69163C0.25349 4.85258 0.315365 5.00765 0.427187 5.12343L4.72431 9.42055C4.83943 9.53563 4.99554 9.60028 5.15832 9.60028C5.32109 9.60028 5.47721 9.53563 5.59232 9.42055L13.5727 1.44018C13.6845 1.3244 13.7464 1.16934 13.745 1.00838C13.7436 0.847425 13.679 0.693458 13.5652 0.579641C13.4514 0.465824 13.2974 0.401263 13.1365 0.399864C12.9755 0.398465 12.8204 0.460341 12.7047 0.572163L5.15832 8.11852L1.29521 4.25541Z"
                    fill="currentcolor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.47553 4.07513L5.15838 7.75798L12.5276 0.388753C12.6915 0.230494 12.911 0.142951 13.1387 0.14493C13.3665 0.14691 13.5845 0.238281 13.7455 0.399364C13.9066 0.560447 13.998 0.778353 14 1.00615C14.0019 1.23395 13.9144 1.45341 13.7561 1.61727L13.753 1.62045L5.77265 9.60079C5.60972 9.76367 5.38875 9.85519 5.15838 9.85519C4.928 9.85519 4.70706 9.76369 4.54413 9.60082L0.243855 5.30054C0.0855961 5.13668 -0.00194666 4.91719 3.28537e-05 4.6894C0.00201233 4.4616 0.0933832 4.24369 0.254466 4.08261C0.415549 3.92153 0.633456 3.83015 0.861253 3.82818C1.08905 3.8262 1.30851 3.91377 1.47237 4.07203L1.47553 4.07513ZM5.15832 8.11852L1.29521 4.25541C1.17943 4.14359 1.02436 4.08171 0.863406 4.08311C0.70245 4.08451 0.548482 4.14907 0.434665 4.26289C0.320848 4.3767 0.256288 4.53067 0.254889 4.69163C0.25349 4.85258 0.315365 5.00765 0.427187 5.12343L4.72431 9.42055C4.83943 9.53563 4.99554 9.60028 5.15832 9.60028C5.32109 9.60028 5.47721 9.53563 5.59232 9.42055L13.5727 1.44018C13.6845 1.3244 13.7464 1.16934 13.745 1.00838C13.7436 0.847425 13.679 0.693458 13.5652 0.579641C13.4514 0.465824 13.2974 0.401263 13.1365 0.399864C12.9755 0.398465 12.8204 0.460341 12.7047 0.572163L5.15832 8.11852Z"
                    fill="currentcolor"
                />
            </SvgIcon>
        </>
    );
};
