import React from 'react';
import { Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { orgLogoAtomState } from '@/recoil/atoms/orgLogoAtom';
import { PageTitleWrapper } from './styles';

export interface PageTitleProps {
    readonly title: string;
}

export const PageTitle = (props: PageTitleProps) => {
    const { title } = props;
    const orgLogoState = useRecoilValue(orgLogoAtomState);

    return (
        <PageTitleWrapper>
            <Typography variant="h5" component="h1" fontWeight="500">
                {title}
            </Typography>
            {orgLogoState ? <img src={orgLogoState} key={orgLogoState} /> : null}
        </PageTitleWrapper>
    );
};
