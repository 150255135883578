import React, { useState, useEffect } from 'react';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { Typography, Tooltip } from '@mui/material';
import { DateTime, Interval } from 'luxon';
import { useRecoilState, useRecoilValue } from 'recoil';
import { monthsIndexDict } from '@/common/const';
import { RTMFilterMonths, RTMFilterMonthsList } from '@/common/types';
import { CalendarIcon } from '@/components/common/Icons/CalendarIcon';
import { Button } from '@/components/common/button/button';
import { usePaginationContext } from '@/components/context/hooks/PaginationContext';
import { RTMLoadingStatus, RTMState } from '@/recoil/atoms/rtmAtom';
import { RTMSelector } from '@/recoil/selectors/rtmSelector';
import {
    MenuWrapper,
    MenuContent,
    GridContainer,
    GridItemMonth,
    ButtonWrapper,
    MenuHeader,
    MenuSelectedBadge,
    MonthIconButton,
} from './styles';
import { createMonthList } from './utils';

interface DateFilterActionsProps {
    dateType: 'start' | 'end' | 'month';
}

enum YearDirection {
    NEXT = 'Next',
    PREVIOUS = 'Back',
    CURRENT = 'Current',
}

// The maximum Date range allowed is a combination of the following:
// previous 12 months + the current month + the next month = 14 months
export const DateFilterActions = (props: DateFilterActionsProps) => {
    const { dateType } = props;
    const [rtmState, setRtmState] = useRecoilState(RTMState);
    const { startDateSelected, endDateSelected, selectedRTMMonth } = useRecoilValue(RTMSelector);
    const [selectedYear, setSelectedYear] = useState(DateTime.now().year);
    const [selectedMonth, setSelectedMonth] = useState(
        dateType === 'month' ? DateTime.fromISO(selectedRTMMonth).monthShort.toLowerCase() : '',
    );
    const [validMonthList, setValidMonthList] = useState<Map<number, string>>();
    const [validYears, setValidYears] = useState<any[]>([]);
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [disabledPrevYear, setDisabledPrevYear] = useState(false);
    const [disabledNextYear, setDisabledNextYear] = useState(false);
    const { page, setPage } = usePaginationContext();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuIsOpen(true);
        setMenuAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchor(null);
        setMenuIsOpen(false);
    };

    const handleMonthSelection = (month: RTMFilterMonthsList) => {
        month !== selectedMonth ? setSelectedMonth(month) : setSelectedMonth('');
    };

    const handleYearChange = ({ direction }: { direction: YearDirection }) => {
        direction === YearDirection.NEXT && setSelectedYear((prev) => prev + 1);
        direction === YearDirection.PREVIOUS && setSelectedYear((prev) => prev - 1);
    };

    useEffect(() => {
        if (selectedMonth && selectedYear && validMonthList?.get(selectedYear)?.includes(selectedMonth)) {
            const lux = DateTime.fromObject({ year: selectedYear, month: monthsIndexDict[selectedMonth] });
            const iso = lux.toFormat('yyyy-MM');
            if (page !== 1) {
                setPage(1);
            }
            dateType === 'start'
                ? setRtmState({
                      ...rtmState,
                      filters: { ...rtmState.filters, cycleStart: iso },
                      loadCycles: RTMLoadingStatus.RELOAD,
                  })
                : dateType === 'end'
                ? setRtmState({
                      ...rtmState,
                      filters: { ...rtmState.filters, cycleEnd: iso },
                      loadCycles: RTMLoadingStatus.RELOAD,
                  })
                : setRtmState({
                      ...rtmState,
                      filters: { ...rtmState.filters, selectedMonth: iso },
                      loadCycles: RTMLoadingStatus.RELOAD,
                  });
        } else {
            dateType === 'start'
                ? setRtmState({
                      ...rtmState,
                      filters: { ...rtmState.filters, cycleStart: null },
                      loadCycles: RTMLoadingStatus.RELOAD,
                  })
                : dateType === 'end'
                ? setRtmState({
                      ...rtmState,
                      filters: { ...rtmState.filters, cycleEnd: null },
                      loadCycles: RTMLoadingStatus.RELOAD,
                  })
                : setRtmState({
                      ...rtmState,
                      filters: { ...rtmState.filters, selectedMonth: selectedRTMMonth },
                      loadCycles: RTMLoadingStatus.RELOAD,
                  });
        }
    }, [selectedMonth, selectedYear]);

    useEffect(() => {
        const minPastYear = Math.min.apply(null, validYears);
        const maxFutureYear = Math.max.apply(null, validYears);

        selectedYear === minPastYear ? setDisabledPrevYear(true) : setDisabledPrevYear(false);
        selectedYear === maxFutureYear ? setDisabledNextYear(true) : setDisabledNextYear(false);
    }, [selectedYear, validMonthList, validYears]);

    useEffect(() => {
        const maxFutureDate = DateTime.local().plus({ months: 2 });
        const maxPastDate = DateTime.local().plus({ months: -12 });
        const timeBetween = Interval.fromDateTimes(maxPastDate, maxFutureDate);

        const allowedMonths = createMonthList({
            timeBetween: timeBetween.count('months'),
            maxPastDate: maxPastDate,
        });
        setValidYears(Array.from(allowedMonths.keys()));
        setValidMonthList(allowedMonths);
    }, []);

    useEffect(() => {
        const currentYearIndex = validYears.indexOf(DateTime.now().year);
        setSelectedYear(validYears[currentYearIndex]);
    }, [validYears]);

    const MonthGridButton = ({ selectedYear }: { selectedYear: number }) => {
        return (
            <>
                {Object.keys(RTMFilterMonths).map((month) => {
                    let disabled;
                    if (validMonthList?.has(selectedYear)) {
                        disabled = validMonthList?.get(selectedYear)?.includes(month) ? false : true;
                    } else {
                        disabled = true;
                    }

                    return (
                        <GridItemMonth item xs={4} key={month} data-cy="month-buttons">
                            <Button
                                variant={!disabled && selectedMonth === month ? 'primary' : 'tertiary'}
                                size="small"
                                onClick={() => handleMonthSelection(month as RTMFilterMonthsList)}
                                disabled={disabled}
                                dataCy={`month-${month}`}
                            >
                                {month}
                            </Button>
                        </GridItemMonth>
                    );
                })}
            </>
        );
    };

    return (
        <React.Fragment>
            <ButtonWrapper>
                <Tooltip
                    title={`${
                        dateType === 'month'
                            ? `Select billing month`
                            : `Cycle ${dateType === 'start' ? 'Start' : 'End'} menu`
                    }`}
                >
                    <Button
                        variant="tertiary"
                        size="small"
                        onClick={handleClick}
                        startIcon={
                            <MenuSelectedBadge
                                color="secondary"
                                badgeContent=""
                                invisible={
                                    (dateType === 'start' && !startDateSelected) ||
                                    (dateType === 'end' && !endDateSelected) ||
                                    (dateType === 'month' && !selectedRTMMonth)
                                }
                                variant="dot"
                            >
                                <CalendarIcon />
                            </MenuSelectedBadge>
                        }
                        endIcon={menuIsOpen ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                        dataCy={`cycle-${dateType}-button`}
                    >
                        {dateType === 'month' ? 'Month' : <>Cycle {dateType === 'start' ? <>Start</> : <>End</>}</>}
                    </Button>
                </Tooltip>
            </ButtonWrapper>
            <MenuWrapper
                anchorEl={menuAnchor}
                id="date-menu"
                open={menuIsOpen}
                onClose={handleClose}
                elevation={0}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        mt: '20px',
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                data-cy={`cycle-${dateType}-menu`}
            >
                <MenuContent>
                    <>
                        <MenuHeader>
                            <MonthIconButton
                                aria-label="go back to previous year"
                                onClick={() => handleYearChange({ direction: YearDirection.PREVIOUS })}
                                size="small"
                                disabled={disabledPrevYear}
                                data-cy="prev-year-button"
                            >
                                <ArrowBackIosNewRoundedIcon />
                            </MonthIconButton>
                            <Typography variant="body2">{selectedYear}</Typography>
                            <MonthIconButton
                                aria-label="go to next year"
                                onClick={() => handleYearChange({ direction: YearDirection.NEXT })}
                                size="small"
                                disabled={disabledNextYear}
                                data-cy="next-year-button"
                            >
                                <ArrowForwardIosRoundedIcon />
                            </MonthIconButton>
                        </MenuHeader>
                        <div>
                            <GridContainer container rowSpacing={2} columnSpacing={1} data-cy="month-buttons-container">
                                <MonthGridButton selectedYear={selectedYear} />
                            </GridContainer>
                        </div>
                    </>
                </MenuContent>
            </MenuWrapper>
        </React.Fragment>
    );
};
