import React from 'react';
import { SvgIcon } from '@mui/material';

interface RadioCheckBoxProps {
    checked: boolean;
}

export const RadioCheckBox = (props: RadioCheckBoxProps) => {
    const { checked } = props;
    return (
        <>
            <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
                {checked ? (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3ZM1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9Z"
                            fill="currentColor"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.7803 6.96967C12.0732 7.26256 12.0732 7.73745 11.7803 8.03033L8.78033 11.0303C8.48745 11.3232 8.01255 11.3232 7.71968 11.0303L6.21967 9.53033C5.92678 9.23745 5.92678 8.76255 6.21967 8.46968C6.51257 8.1768 6.98744 8.1768 7.28033 8.46968L8.25 9.43935L10.7197 6.96967C11.0126 6.67678 11.4875 6.67678 11.7803 6.96967Z"
                            fill="currentColor"
                        />
                    </>
                ) : (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3ZM1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9Z"
                            fill="currentColor"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.21967 6.21967C6.51257 5.92678 6.98744 5.92678 7.28033 6.21967L9 7.93935L10.7197 6.21967C11.0126 5.92678 11.4875 5.92678 11.7803 6.21967C12.0732 6.51257 12.0732 6.98744 11.7803 7.28033L10.0607 9L11.7803 10.7197C12.0732 11.0126 12.0732 11.4875 11.7803 11.7803C11.4875 12.0732 11.0126 12.0732 10.7197 11.7803L9 10.0607L7.28033 11.7803C6.98744 12.0732 6.51257 12.0732 6.21967 11.7803C5.92678 11.4875 5.92678 11.0126 6.21967 10.7197L7.93935 9L6.21967 7.28033C5.92678 6.98744 5.92678 6.51257 6.21967 6.21967Z"
                            fill="currentColor"
                        />
                    </>
                )}
            </SvgIcon>
        </>
    );
};
