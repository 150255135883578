import React, { useState } from 'react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import {
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    MenuItem,
    MenuList,
    Typography,
} from '@mui/material';
import { useRecoilState } from 'recoil';
import { ButtonWrapper, MenuContent, MenuItemText, MenuWrapper } from '@/components/common/DateRangeActions/styles';
import { MenuHeader } from '@/components/common/Table/Filters/styles';
import { selfReportedFilterState } from '@/recoil/atoms/healthSessionsAtom';

export const SelfReportedFilter = (props) => {
    const [selfReportingFilter, setSelfReportingFilter] = useRecoilState(selfReportedFilterState);
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuIsOpen(true);
        setMenuAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchor(null);
        setMenuIsOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelfReportingFilter(event.target.checked);
    };

    return (
        <React.Fragment>
            <div style={{ marginLeft: 14 }}>
                <ButtonWrapper>
                    <Button
                        variant="tertiary"
                        size="small"
                        onClick={handleClick}
                        endIcon={menuIsOpen ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                    >
                        <>
                            <span>Reporting Type</span>
                        </>
                    </Button>
                </ButtonWrapper>
            </div>

            <MenuWrapper
                anchorEl={menuAnchor}
                open={menuIsOpen}
                onClose={handleClose}
                elevation={0}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        mt: '20px',
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuContent>
                    <>
                        <MenuHeader>
                            <Typography variant="body2">Reporting Type</Typography>
                        </MenuHeader>
                        <Divider />
                        <MenuList sx={{ maxHeight: 500, overflowY: 'scroll' }}>
                            <FormControl component="fieldset" variant="standard">
                                <FormGroup>
                                    <MenuItem>
                                        <FormControlLabel
                                            control={<Checkbox checked={selfReportingFilter} onChange={handleChange} />}
                                            label={<MenuItemText>Include Self-Reported</MenuItemText>}
                                        />
                                    </MenuItem>
                                </FormGroup>
                            </FormControl>
                        </MenuList>
                    </>
                </MenuContent>
            </MenuWrapper>
        </React.Fragment>
    );
};
