import React from 'react';
import { CssBaseline } from '@mui/material';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { useExerUser } from '@/common/hooks/ExerUser';
import { GlobalModal } from '@/components/common/GlobalModal/GlobalModal';
import { ToastContainer } from '@/components/common/Toast/styles';
import { Routes } from '../routing/Routes';
import { Initialization } from './common/Initialization/Initialization';

const App = () => {
    injectStyle();
    const { user, getRole } = useExerUser();

    return (
        <>
            <CssBaseline />
            <ToastContainer />
            <Routes />
            <GlobalModal />
            <Initialization />
        </>
    );
};

export default App;
