import React from 'react';
import { PROResponsesTableContainer } from '@/components/PROs/PROResponses/PROResponsesTableContainer';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { RouteContentWrapper } from '../../styles';

export const AdminPROResponsesRoute = () => {
    return (
        <RouteContentWrapper>
            <PageTitle title="Unprocessed PRO Responses" />
            <PROResponsesTableContainer />
        </RouteContentWrapper>
    );
};
