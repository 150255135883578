import React from 'react';
import { SvgIcon } from '@mui/material';

export const AlertCircleIcon = (props) => {
    return (
        <>
            <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.99991 2.04184C5.15746 2.04184 2.04158 5.15772 2.04158 9.00017C2.04158 12.8426 5.15746 15.9585 8.99991 15.9585C12.8431 15.9585 15.9582 12.8427 15.9582 9.00017C15.9582 5.15767 12.8431 2.04184 8.99991 2.04184ZM0.54158 9.00017C0.54158 4.32929 4.32903 0.54184 8.99991 0.54184C13.6717 0.54184 17.4582 4.32935 17.4582 9.00017C17.4582 13.671 13.6717 17.4585 8.99991 17.4585C4.32903 17.4585 0.54158 13.6711 0.54158 9.00017Z"
                    fill="currentcolor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.99583 5.08691C9.41005 5.08691 9.74583 5.4227 9.74583 5.83691V9.51941C9.74583 9.93363 9.41005 10.2694 8.99583 10.2694C8.58162 10.2694 8.24583 9.93363 8.24583 9.51941V5.83691C8.24583 5.4227 8.58162 5.08691 8.99583 5.08691Z"
                    fill="currentcolor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.24583 12.1634C8.24583 11.7492 8.58162 11.4134 8.99583 11.4134H9.00417C9.41838 11.4134 9.75417 11.7492 9.75417 12.1634C9.75417 12.5776 9.41838 12.9134 9.00417 12.9134H8.99583C8.58162 12.9134 8.24583 12.5776 8.24583 12.1634Z"
                    fill="currentcolor"
                />
            </SvgIcon>
        </>
    );
};
