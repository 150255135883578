import React from 'react';
import { SvgIcon } from '@mui/material';

export const DischargeIcon = (props) => {
    return (
        <>
            <SvgIcon viewBox="200 87.5 799.99 1025" {...props}>
                <path
                    d="m966.67 154.17h-251.88c-23.125-39.582-65.625-66.668-114.79-66.668s-91.668 27.082-114.79 66.668h-251.88c-18.332 0-33.332 15-33.332 33.332v891.67c0 18.332 15 33.332 33.332 33.332h733.33c18.332 0 33.332-15 33.332-33.332v-891.67c0-18.332-15-33.332-33.332-33.332zm-33.336 891.66h-666.67v-825h200v33.332c0 18.332 15 33.332 33.332 33.332h200c18.332 0 33.332-15 33.332-33.332v-33.332h200v825z"
                    fill="currentColor"
                />
                <path
                    d="m533.33 847.08-190.21-190.21 47.082-47.082 143.12 143.12 276.46-276.46 47.082 47.082-323.54 323.54z"
                    fill="currentColor"
                />
            </SvgIcon>
        </>
    );
};

export const NewPocIcon = (props) => {
    return (
        <>
            <SvgIcon viewBox="200 87.5 799.99 1025" fill="none" {...props}>
                <path
                    d="m966.67 154.17h-251.88c-23.125-39.582-65.625-66.668-114.79-66.668s-91.668 27.082-114.79 66.668h-251.88c-18.332 0-33.332 15-33.332 33.332v891.67c0 18.332 15 33.332 33.332 33.332h733.33c18.332 0 33.332-15 33.332-33.332v-891.67c0-18.332-15-33.332-33.332-33.332zm-33.336 891.66h-666.67v-825h200v33.332c0 18.332 15 33.332 33.332 33.332h200c18.332 0 33.332-15 33.332-33.332v-33.332h200v825z"
                    fill="currentColor"
                />
                <path
                    d="m855.96 572.52h-230.52v-230.52c0-13.199-10.801-24-24-24-13.199 0-24 10.801-24 24v230.52h-230.52c-13.199 0-24 10.801-24 24s10.801 24 24 24h230.52v230.52c0 13.199 10.801 24 24 24 13.199 0 24-10.801 24-24v-230.52h230.52c13.199 0 24-10.801 24-24s-10.68-24-24-24z"
                    fill="currentColor"
                />
            </SvgIcon>
        </>
    );
};
