import React from 'react';
import { Session } from '@/common/types';
import { SessionExpandingRowContainer } from './SessionExpandingRowContainer';

interface Props {
    sessions: Session[];
}

export const PatientSessionsList = (props: Props) => {
    const { sessions } = props;

    const sessionExpandingRows = sessions.map((session) => {
        return <SessionExpandingRowContainer session={session} key={session.sessionUUID} />;
    });

    return <>{sessionExpandingRows}</>;
};
