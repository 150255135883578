import React from 'react';
import { Products } from '@/common/const';
import { EmptyDataProps, EmptyPatientsData } from '@/components/common/EmptyData/EmptyData';
import { AddPatientContainer } from '../Patients/AddPatient/AddPatientContainer';
import { EmptyPatientsWrapper } from '../Patients/styles';

export const PatientBillingEmpty = ({ title, body }: EmptyDataProps) => {
    return (
        <EmptyPatientsWrapper>
            <EmptyPatientsData title={title} body={body} />
            <AddPatientContainer product={Products.HEALTH} />
        </EmptyPatientsWrapper>
    );
};
