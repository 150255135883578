import { styled } from '@mui/material';

export const LoaderContainer = styled('div')(({ theme }) => ({
    width: '100%',
    height: theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const InlineTextLoadingSpinnerWrapper = styled('div')<{ $size: number }>(({ theme, $size }) => ({
    width: $size ?? 20,
    height: $size ?? 20,
    margin: `0 ${$size / 2 ?? 10}px`,
}));
