import { ExerciseMetric } from '../../common/types';

export const exerciseMetrics: ExerciseMetric[] = [
    {
        id: 86,
        metric: 'REPS',
        exercise: {
            id: 'S-0003',
            name: ' Shoulder Abduction with Cane (Active Assisted)',
            simpleName: ' Shoulder Abduction with Cane (Active Assisted)',
            exerciseType: 'ACTIVE_DYNAMIC',
            bodyTargets: ['SHOULDER'],
            action: '• The goal is to use the non-operative arm to actively facilitate the upward and slightly away from the body motion for the involved side. \n• Grab a wooden dowel or broomstick with both hands, with the involved side at the top of the dowel.\n• Do your best to relax the involved side and move slowly and smoothly. \n• Move upwards till there is minor tension but no pain. \n• Return to the starting position and repeat the prescribed number of reps.',
            position: '• Stand facing the camera.',
            cameraHeight: 'TABLE',
            cameraOrientation: 'PORTRAIT',
            subjectOrientation: 'FRONT',
            subjectPosition: 'STANDING',
            thumbnailUrl: 'https://exer-exercise-media-staging.s3.amazonaws.com/thumbnails/S-AM-010.jpg',
            demoUrl: 'https://exer-exercise-media-staging.s3.amazonaws.com/demo-videos/S-AM-010.mp4',
            animationUrl: 'https://exer-exercise-media-staging.s3.amazonaws.com/animations/S-0082.json',
            demoAudioUrl:
                'https://exer-exercise-media-staging.s3.amazonaws.com/demo-audios/02b577d0-268b-402a-b49c-5be0389173e8.mp3',
            animationThumbnailFrame: 8,
            equipment: 'CANE',
            sequence: 'SINGLE',
            withAnchorPoint: false,
            withHold: false,
            activeAssisted: true,
            settings: {
                ROM: {
                    max: {
                        label: 'End',
                        limit: 180,
                    },
                    min: {
                        label: 'Start',
                        limit: 0,
                    },
                },
            },
        },
        settings: {
            ROM: {
                max: {
                    label: 'End',
                    limit: 180,
                },
                min: {
                    label: 'Start',
                    limit: 0,
                },
            },
        },
    },
    {
        id: 86,
        metric: 'ROM',
        exercise: {
            id: 'S-0003',
            name: ' Shoulder Abduction with Cane (Active Assisted)',
            simpleName: ' Shoulder Abduction with Cane (Active Assisted)',
            exerciseType: 'ACTIVE_DYNAMIC',
            bodyTargets: ['SHOULDER'],
            action: '• The goal is to use the non-operative arm to actively facilitate the upward and slightly away from the body motion for the involved side. \n• Grab a wooden dowel or broomstick with both hands, with the involved side at the top of the dowel.\n• Do your best to relax the involved side and move slowly and smoothly. \n• Move upwards till there is minor tension but no pain. \n• Return to the starting position and repeat the prescribed number of reps.',
            position: '• Stand facing the camera.',
            cameraHeight: 'TABLE',
            cameraOrientation: 'PORTRAIT',
            subjectOrientation: 'FRONT',
            subjectPosition: 'STANDING',
            thumbnailUrl: 'https://exer-exercise-media-staging.s3.amazonaws.com/thumbnails/S-AM-010.jpg',
            demoUrl: 'https://exer-exercise-media-staging.s3.amazonaws.com/demo-videos/S-AM-010.mp4',
            animationUrl: 'https://exer-exercise-media-staging.s3.amazonaws.com/animations/S-0082.json',
            demoAudioUrl:
                'https://exer-exercise-media-staging.s3.amazonaws.com/demo-audios/02b577d0-268b-402a-b49c-5be0389173e8.mp3',
            animationThumbnailFrame: 8,
            equipment: 'CANE',
            sequence: 'SINGLE',
            withAnchorPoint: false,
            withHold: false,
            activeAssisted: true,
            settings: {
                ROM: {
                    max: {
                        label: 'End',
                        limit: 180,
                    },
                    min: {
                        label: 'Start',
                        limit: 0,
                    },
                },
            },
        },
        settings: {
            ROM: {
                max: {
                    label: 'End',
                    limit: 180,
                },
                min: {
                    label: 'Start',
                    limit: 0,
                },
            },
        },
    },
];
