import React, { useState } from 'react';
import { Practitioner, Location } from '@/common/types';
import { Tabs, Tab } from '@/components/common/Tabs/styles';
import { OrganizationListWrapper } from '../styles';
import { EmptyPractitioners } from './EmptyPractitioners';
import { PractitionerRow } from './PractitionerRow';
import { PractitionersListHeader } from './PractitionersListHeader';

enum PractitionerTabs {
    CURRENT,
    INVITED,
}

interface Props {
    practitioners?: Practitioner[];
    locations?: Location[];
}

export const PractitionersList = (props: Props) => {
    const { practitioners, locations } = props;
    const [activeTab, setActiveTab] = useState(PractitionerTabs.CURRENT);

    const handleTabChange = (_: React.ChangeEvent, newValue: PractitionerTabs) => {
        setActiveTab(newValue);
    };

    let activePractitioners: Practitioner[] = [];
    const currentUsers =
        (practitioners &&
            practitioners.filter((practitioner) => {
                return !!practitioner.userId;
            })) ||
        [];

    const invitedUsers =
        (practitioners &&
            practitioners.filter((practitioner) => {
                return !practitioner.userId;
            })) ||
        [];

    const currentUserCount = currentUsers.length;
    const invitedUserCount = invitedUsers.length;

    switch (activeTab) {
        case PractitionerTabs.CURRENT:
            activePractitioners = currentUsers;
            break;
        case PractitionerTabs.INVITED:
            activePractitioners = invitedUsers;
            break;
        default:
    }

    const locationRows = activePractitioners.map((practitioner) => {
        return <PractitionerRow key={practitioner.id} practitioner={practitioner} locations={locations} />;
    });

    return (
        <OrganizationListWrapper data-cy="practitioner-list">
            <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label={`Current Users (${currentUserCount})`} value={PractitionerTabs.CURRENT} />
                <Tab label={`Invited Users (${invitedUserCount})`} value={PractitionerTabs.INVITED} />
            </Tabs>
            <PractitionersListHeader />
            {practitioners ? practitioners.length > 0 ? locationRows : <EmptyPractitioners /> : null}
        </OrganizationListWrapper>
    );
};
