import React from 'react';
import { usePaginationContext } from '@/components/context/hooks/PaginationContext';
import { ColumnSortedIcon, TableHeaderCell } from './styles';

interface Props {
    column: any;
    nonHeadered: string[];
    nonSorted: string[];
}

export const SortableTableHeaderCell = (props: Props) => {
    const { column, nonHeadered, nonSorted } = props;
    const { sort, handleSetSort } = usePaginationContext();
    const headerProps = { ...column.getHeaderProps() };

    let nextState: 'ASC' | 'DESC' | null;
    switch (sort?.dir) {
        case 'DESC':
            nextState = null;
            break;
        case 'ASC':
            nextState = 'DESC';
            break;
        default:
            nextState = 'ASC';
    }

    return (
        <TableHeaderCell
            {...headerProps}
            $isSorted={sort?.sortKey === column.id}
            onClick={() => {
                if (!nonSorted.includes(column.id)) {
                    handleSetSort(column.id, nextState);
                }
            }}
            style={{ width: column.width, maxWidth: column.width, minWidth: column.width, whiteSpace: 'nowrap' }}
        >
            <div>
                <span style={{ display: 'inline-block' }}>
                    {!nonHeadered.includes(column.id) && column.render('Header')}
                </span>
                {!nonSorted.includes(column.id) &&
                    (sort?.sortKey === column.id ? (
                        sort?.dir === 'DESC' ? (
                            <ColumnSortedIcon dir="DESC" />
                        ) : (
                            <ColumnSortedIcon dir="ASC" />
                        )
                    ) : (
                        <ColumnSortedIcon />
                    ))}
            </div>
        </TableHeaderCell>
    );
};
