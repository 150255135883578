import { useContext } from 'react';
import { HEPBuilderContext } from '../HEPBuilderContext';

export const useHEPBuilderContext = () => {
    const hepBuilderContext = useContext(HEPBuilderContext);

    if (!hepBuilderContext) {
        throw new Error('HEPBuilderContext not initialized');
    }

    return hepBuilderContext;
};
