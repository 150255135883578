import { atom } from 'recoil';

interface FocusModalStateProps {
    isModalOpen: boolean;
}

export const FocusModalState = atom<FocusModalStateProps>({
    key: 'focusModal',
    default: {
        isModalOpen: false,
    },
});
