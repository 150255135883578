import { useContext } from 'react';
import { ModalContext } from '../ModalContext';

export const useModalContext = () => {
    const modalContext = useContext(ModalContext);

    if (!modalContext) {
        throw new Error('ModalContext not initialized');
    }

    return modalContext;
};
