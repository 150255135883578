import { styled, Badge, Box, Menu, Typography, FormControlLabel as MuiFormControlLabel } from '@mui/material';
import { Button } from '@/components/common/button/button';

export const ButtonWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,

    '& .MuiButton-root': {
        background: 'white',
        paddingTop: theme.spacing(0.7),
        paddingBottom: theme.spacing(0.7),
        borderColor: theme.palette.grey[400],

        '& svg': {
            fontSize: 24,
            color: '#737580',
        },
    },
}));

export const MenuWrapper = styled(Menu)(({ theme }) => ({
    '.MuiList-root': {
        padding: '0',
    },
}));

export const MenuContent = styled(Box)(({ theme }) => ({
    minWidth: theme.spacing(37.5),
    borderRadius: theme.shape.borderRadius,
    background: 'white',

    '.MuiListItemIcon-root': {
        paddingTop: '9px',
        paddingBottom: '9px',
        minWidth: '28px',
        fontSize: theme.typography.h5.fontSize,
        marginLeft: '-1px',
    },
    '.MuiFormControl-root': {
        width: '100%',
    },
}));

export const MenuHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 2),
}));

export const MenuSelectedBadge = styled(Badge)(({ theme }) => ({
    '.MuiBadge-badge': {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary[600],
        width: '15px',
        height: '15px',
        minWidth: 'auto',
        fontWeight: '500',
        fontSize: theme.typography.caption.fontSize,
        marginRight: -10,
        paddingLeft: 6,
    },
}));

export const MenuItemText = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
}));

export const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
    width: '100%',
}));

export const MultiSelectButton = styled(Button)(({ theme }) => ({
    color: theme.palette.grey[800],
    width: '100%',
    '&.MuiButton-root': {
        padding: `${theme.spacing(1.6)} ${theme.spacing(1)} ${theme.spacing(1.6)} ${theme.spacing(1.5)}`,
        borderColor: theme.palette.grey[300],
    },
}));

export const MultiSelectBadgeWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
}));
