import { Card, CardActions, CardMedia, Grid, Box, Tooltip, styled } from '@mui/material';

export const HEPBuilderExercisesLoader = styled('div')(({ theme }) => ({
    width: '100%',
    paddingTop: theme.spacing(4),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const HEPBuilderExercisesItemWrapper = styled(Grid)(({}) => ({}));

export const HEPBuilderExercisesWrapper = styled(Grid)(({ theme }) => ({
    paddingBottom: theme.spacing(4),
}));

export const HEPBuilderExerciseCardWrapper = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    position: 'relative',
    zIndex: '10',
    padding: '0',
    cursor: 'pointer',
    boxShadow: `0px 2px 5px rgba(16, 5, 51, 0.04)`,
    transition: '.4s ease box-shadow',

    '&:hover': {
        boxShadow: `0 0 0 4px ${theme.palette.primary[300]}`,
    },

    '& > h4': {
        fontSize: theme.typography.htmlFontSize,
        width: '100%',
        height: '100%',
        padding: theme.spacing(0),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        margin: 0,
        textAlign: 'left',
    },
}));

export const HEPBuilderExerciseCardMedia = styled(CardMedia)<{ component: string; alt: string }>(({ theme }) => ({
    maxHeight: theme.spacing(31),
}));

export const HEPBuilderExerciseCardActions = styled(CardActions)(({ theme }) => ({
    padding: 0,
    alignItems: 'space-between',
    flexWrap: 'wrap',
    width: '100%',

    '& > .MuiButton-root': {
        margin: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
        padding: theme.spacing(1),
        flex: '1 1 auto',
    },
}));

export const ExerciseCardIconContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
}));

export const ExercisePositionIconWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.primary[500],
    svg: {
        fill: theme.palette.primary[300],
        stroke: theme.palette.primary[500],
        strokeWidth: '0.5pt',
    },
}));

export const PatientOrientationIconWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    svg: {
        fontSize: theme.typography.h4.fontSize,
        color: '#d8cdfe',
        stroke: '#6a41f1',
        strokeWidth: '40px',
        paintOrder: 'stroke',
        strokeLinejoin: 'round',
    },
}));

export const PhoneIconWrapper = styled(Tooltip)<{ $orientation }>(({ $orientation }) => ({
    width: $orientation === 'PORTRAIT' ? '19px' : '30px',
}));

export const ExerciseMetricsText = styled('div')(({ theme }) => ({
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
}));

export const HEPBuilderExercisePagination = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: theme.spacing(5),
}));

export const AnimationControlsWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const AnimationControlsContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[300],
    borderRadius: 8,
    paddingLeft: 8,
    paddingRight: 8,
}));
