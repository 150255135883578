import { BodyPart, HealthPatientStatus, SideOfBody } from '@/common/const';
import { PatientRecord, HealthPatientRecord } from '@/common/types';
import { getStringEnumKeyByValue } from '@/common/utils';

export const organization11 = {
    id: 1,
    name: 'Organization 1',
    products: ['GAIT', 'HEALTH'],
    timezone: 'MST',
    logoUrl: 'https://placehold.co/400',
    webLogoUrl: 'https://placehold.co/400',
    disabled: false,
};

export const organization11New = {
    id: 1,
    name: 'Organization 1 New Name',
    products: ['GAIT', 'HEALTH'],
    timezone: 'MST',
    logoUrl: 'https://placehold.co/400',
    webLogoUrl: 'https://placehold.co/400',
    disabled: false,
};

export const organization11Admin = {
    id: 1,
    name: 'Organization 1 New Name',
    products: ['GAIT', 'HEALTH'],
    timezone: 'MST',
    logoUrl: 'https://placehold.co/400',
    webLogoUrl: 'https://placehold.co/400',
    scanModules: null,
    disabled: false,
};

export const assessment1 = {
    id: 'AST-0001',
    name: 'Bilateral Functional Reach',
    versions: [],
    enabledVersions: [],
    purpose:
        'To measure the distance between the length of an outstretched arm(s) in a maximal forward reach, while maintaining a fixed base of support.\n',
    setupDescription:
        "• Tripod, iPad (camera 36’ from floor) \n• 7ft of distance between participant and iPad.\n• Area is open and free from distracting background elements (mirrors, \n  portraits, TV's, etc.)",
    subjectOrientation: 'SIDE',
    supportsLeftRight: true,
    thumbnailUrl:
        'https://exer-exercise-media-staging.s3.amazonaws.com/assessment-images/910ae032-2e52-44bb-b5f5-c07eda694aae.jpeg',
    environmentAnimationUrl:
        'https://exer-exercise-media-staging.s3.amazonaws.com/assessment-animations/6ad98560-5e91-481b-843c-a15b339a3e22.json',
    participantImageUrl:
        'https://exer-exercise-media-staging.s3.amazonaws.com/assessment-images/dd1d2603-486b-4034-a1db-206d1c8b4dda.jpeg',
    participantImageProviderAssistUrl:
        'https://exer-exercise-media-staging.s3.amazonaws.com/assessment-images/83ccbf4c-597e-4df0-88e1-ef0fabc0f7e5.jpeg',
    leftParticipantImageUrl:
        'https://exer-exercise-media-staging.s3.amazonaws.com/assessment-images/e59e562b-eedf-481e-907e-c6c9c78bd6a4.jpeg',
    leftParticipantImageProviderAssistUrl:
        'https://exer-exercise-media-staging.s3.amazonaws.com/assessment-images/d449946c-b69e-4bbe-8953-b54135073113.jpeg',
    rightParticipantImageUrl:
        'https://exer-exercise-media-staging.s3.amazonaws.com/assessment-images/1ba74b5a-2c48-4634-aa71-877b892184c1.jpeg',
    rightParticipantImageProviderAssistUrl:
        'https://exer-exercise-media-staging.s3.amazonaws.com/assessment-images/a7e66887-c00a-4e35-b63a-2ae69df1029b.jpeg',
    exerciseProperties: null,
    updated: '2024-07-17T00:12:36.464Z',
};

export const practitioner1 = {
    id: 1,
    firstName: 'Doctor',
    lastName: 'Strange',
    locations: [],
    email: 'thing@one.com',
};

export const practitioner2 = {
    id: 1,
    firstName: 'Doctor',
    lastName: 'Normal',
    locations: [],
};

export const location1 = {
    id: 1,
    name: 'Nowhereville',
    timezone: 'America/Denver',
};

export const hep1 = {
    id: 1,
    name: 'Knee Program',
};

export const patientRecord1: PatientRecord = {
    id: 4,
    patient: {
        firstName: 'Calvin',
        lastName: 'Fischoeder',
        email: 'fr@patient',
        phoneNumber: '3034045555',
    },
    practitioner: practitioner1,
    location: location1,
    products: ['GAIT', 'HEALTH'],
    mostRecentSession: '2022-09-05T14:48:00.000Z',
    mostRecentActivity: '2022-09-05T14:48:00.000Z',
    discharged: false,
    productData: {
        health: {
            injuredSideOfBody: SideOfBody.RIGHT,
            injuredBodyPart: BodyPart.SHOULDER,
            status: getStringEnumKeyByValue(HealthPatientStatus, HealthPatientStatus.HAS_ACCOUNT),
            heps: [hep1],
            mostRecentSession: '2022-09-05T14:48:00.000Z',
            mostRecentActivity: '2022-09-05T14:48:00.000Z',
        },
        gait: {
            mostRecentSession: '2022-09-05T14:48:00.000Z',
            uid: null,
        },
    },
    plansOfCare: [
        {
            start: '2022-09-21',
            end: '2024-02-21',
        },
    ],
};

export const patientRecord2: PatientRecord = {
    id: 4,
    patient: {
        firstName: 'Sergeant',
        lastName: 'Bosco',
        email: 'fr@patient',
        phoneNumber: '3034045555',
    },
    practitioner: practitioner1,
    location: location1,
    products: ['GAIT', 'HEALTH'],
    mostRecentSession: '2022-09-05T14:48:00.000Z',
    mostRecentActivity: '2022-09-05T14:48:00.000Z',
    discharged: false,
    productData: {
        health: {
            injuredSideOfBody: SideOfBody.RIGHT,
            injuredBodyPart: BodyPart.SHOULDER,
            status: getStringEnumKeyByValue(HealthPatientStatus, HealthPatientStatus.NO_ACCOUNT),
            heps: [hep1],
            mostRecentSession: '2022-09-05T14:48:00.000Z',
            mostRecentActivity: '2022-09-05T14:48:00.000Z',
        },
        gait: {
            mostRecentSession: '2022-09-05T14:48:00.000Z',
            uid: null,
        },
    },
    plansOfCare: [
        {
            start: '2022-09-21',
            end: '2024-02-21',
        },
    ],
};

export const patientRecord3: PatientRecord = {
    id: 4,
    patient: {
        firstName: 'Jimmy',
        lastName: 'Pesto',
        email: 'fr@patient',
        phoneNumber: '3034045555',
    },
    practitioner: practitioner2,
    location: location1,
    products: ['GAIT', 'HEALTH'],
    mostRecentSession: '2022-09-05T14:48:00.000Z',
    mostRecentActivity: '2022-09-05T14:48:00.000Z',
    discharged: false,
    productData: {
        health: {
            injuredSideOfBody: SideOfBody.RIGHT,
            injuredBodyPart: BodyPart.SHOULDER,
            status: getStringEnumKeyByValue(HealthPatientStatus, HealthPatientStatus.NO_ASSIGNED_HEP),
            heps: [hep1],
            mostRecentSession: '2022-09-05T14:48:00.000Z',
            mostRecentActivity: '2022-09-05T14:48:00.000Z',
        },
        gait: {
            mostRecentSession: '2022-09-05T14:48:00.000Z',
            uid: null,
        },
    },
    plansOfCare: [
        {
            start: '2022-09-21',
            end: '2024-02-21',
        },
    ],
};

export const healthPatientRecord1: HealthPatientRecord = {
    id: 4,
    patient: {
        firstName: 'Calvin',
        lastName: 'Fischoeder',
        email: 'fr@patient',
        phoneNumber: '3034045555',
    },
    location: location1,
    organization: organization11,
    mostRecentSession: '2022-09-05T14:48:00.000Z',
    discharged: false,
    injuredSideOfBody: SideOfBody.RIGHT,
    injuredBodyPart: BodyPart.SHOULDER,
    heps: [hep1],
};

export const healthPatientRecord2: HealthPatientRecord = {
    id: 4,
    patient: {
        firstName: 'Sergeant',
        lastName: 'Bosco',
        email: 'sb@patient',
        phoneNumber: '3034045555',
    },
    location: location1,
    organization: organization11,
    mostRecentSession: '2022-09-05T14:48:00.000Z',
    discharged: false,
    injuredSideOfBody: SideOfBody.RIGHT,
    injuredBodyPart: BodyPart.SHOULDER,
    heps: [hep1],
};

export const healthPatientRecord3: HealthPatientRecord = {
    id: 4,
    patient: {
        firstName: 'Jimmy',
        lastName: 'Pesto',
        email: 'jp@patient',
        phoneNumber: '3034045555',
    },
    location: location1,
    organization: organization11,
    mostRecentSession: '2022-09-05T14:48:00.000Z',
    discharged: false,
    injuredSideOfBody: SideOfBody.RIGHT,
    injuredBodyPart: BodyPart.SHOULDER,
    heps: [hep1],
};
