import { styled, TextField, IconButton } from '@mui/material';
import { EditIcon } from '../Icons/EditIcon';
import { TrashIcon } from '../Icons/TrashIcon';

export const SessionCommentWrapper = styled(IconButton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.grey[600],
    fontSize: 'inherit',
    '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
        fontSize: theme.typography.h5.fontSize,
    },
}));

export const CommentActions = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.grey[500],
}));

export const EditCommentIcon = styled(EditIcon)(({ theme }) => ({
    cursor: 'pointer',
}));

export const DeleteCommentIcon = styled(TrashIcon)(({ theme }) => ({
    position: 'relative',
}));

export const CommentTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    marginBottom: theme.spacing(2),
}));

export const CommentErrors = styled('p')(({ theme }) => ({
    color: theme.palette.error.main,
}));

export const CommentContent = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}));

export const CommentWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    columnGap: theme.spacing(3),
    p: {
        margin: 0,
    },
}));
