import {
    Accordion,
    AccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    styled,
    Typography,
    Alert,
} from '@mui/material';

export const PROChartTitle = styled(Typography)(({ theme }) => ({
    paddingBottom: theme.spacing(5),
    fontWeight: 'bold',
}));

export const ChartEmptyIcon = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.common.white,
    width: theme.spacing(9.5),
    height: theme.spacing(9.5),
    borderRadius: '50%',
    boxShadow: '0 0 0 6px rgba(177, 154, 254, 0.2)',
    marginBottom: '40px',
    '&::after': {
        content: `''`,
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'linear-gradient(160.2deg, rgba(134, 118, 251, 0.5) 47.57%, rgb(242,79,78, .4) 110.77%)',
        animation: `emptyRotate infinite 3s linear`,
        transformBox: 'fill-box',
        borderRadius: '50%',
    },
    '& .MuiSvgIcon-root': {
        fill: 'none',
        zIndex: '10',
    },
    '@keyframes emptyRotate': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
}));

export const ChartEmptyWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: theme.spacing(5),
    textAlign: 'center',
    '& h4': {
        marginBottom: theme.spacing(1),
    },
    '& h6': {
        color: theme.palette.grey[600],
    },
}));

export const Panel = styled(Accordion)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[200]}`,
    marginTop: theme.spacing(4),
    '&:before': {
        display: 'none',
    },
    '&.Mui-expanded:first-of-type': {
        marginTop: theme.spacing(4),
    },
}));

export const PanelDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: `0 ${theme.spacing(4)} ${theme.spacing(2)}`,
}));

export const PanelSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    flexDirection: 'row-reverse',
    transition: '.5s ease background',
    padding: `0 ${theme.spacing(4)}`,
    '&:last-of-kind': {
        borderBottom: '0',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        fontSize: theme.typography.h5.fontSize,
        marginRight: theme.spacing(1.5),
    },
}));

export const PanelText = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
    padding: `${theme.spacing(1)} 0`,
}));

export const ChartAlert = styled(Alert)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2.5),
}));
