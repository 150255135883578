import React from 'react';
import { TableCell } from '@mui/material';
import { useSortBy, useTable } from 'react-table';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { PaginationContainer } from '@/components/common/Table/PaginationContainer';
import { TableWrapper, FullWidthTable, TableHeaderCell, TableHeader } from '@/components/common/Table/styles';

interface ScanTableProps {
    columnStructure: any[];
    data: any[];
    isLoading: boolean;
}

export const ScanSessionsTable = (props: ScanTableProps) => {
    const { columnStructure, data, isLoading } = props;
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns: columnStructure,
            data,
        },
        useSortBy,
    );

    return (
        <TableWrapper>
            <FullWidthTable {...getTableProps()}>
                <TableHeader style={{ width: '100%' }}>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={headerGroup.id + '-' + headerGroup.Cell?.toString}
                        >
                            {headerGroup.headers.map((column) => {
                                return (
                                    <TableHeaderCell
                                        key={column.id}
                                        $isSorted={true}
                                        style={{
                                            width: column.width,
                                            maxWidth: column.width,
                                            minWidth: column.width,
                                            whiteSpace: 'nowrap',
                                            textAlign: ['action', 'details'].includes(column.id) ? 'center' : 'left',
                                        }}
                                    >
                                        {column.render('Header')}
                                    </TableHeaderCell>
                                );
                            })}
                        </tr>
                    ))}
                </TableHeader>

                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={row.original.id}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <TableCell
                                                {...cell.getCellProps()}
                                                key={cell.column.id}
                                                style={{
                                                    width: cell.column.width,
                                                    maxWidth: cell.column.width,
                                                    minWidth: cell.column.width,
                                                }}
                                                size="small"
                                            >
                                                {cell.render('Cell')}
                                            </TableCell>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                )}
            </FullWidthTable>
            <PaginationContainer />
        </TableWrapper>
    );
};
