import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { HEPTemplatesCardList } from '@/components/Health/HEPs/HEPTemplatesCardList/HEPTemplatesCardList';
import { hepTemplatesState, deletedHEPTemplatesState } from '@/recoil/atoms/hepTemplatesAtom';
import { resultIsError } from '@/services/HttpService';

export const HEPTemplatesCardListContainer = () => {
    const [templateCards, setTemplateCards] = useRecoilState(hepTemplatesState);
    const deletedHEPTempletes = useRecoilValue(deletedHEPTemplatesState);
    const [hasFetched, setHasFetched] = useState(false);
    const { httpService } = useHttpContext();

    const getHEPTemplates = async () => {
        const response = await httpService.getHEPTemplates();
        if (resultIsError(response)) {
            return;
        }
        setTemplateCards(response);
    };

    useEffect(() => {
        (async () => {
            await getHEPTemplates();
            setHasFetched(true);
        })();
    }, [deletedHEPTempletes]);

    return (
        <>
            <div>{hasFetched ? <HEPTemplatesCardList data={templateCards} /> : null}</div>
        </>
    );
};
