import React, { useState } from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { useExerFeatureFlags } from '@/common/hooks/ExerFeatureFlags';
import { FormFieldContainer } from '../common/Form/styles';

export const LanguageSelector = () => {
    const [value, setValue] = useState('english');
    const { flags } = useExerFeatureFlags();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    if (!flags.language) return null;

    return (
        <FormFieldContainer>
            <FormControl>
                <FormLabel component="legend">Language</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                >
                    <FormControlLabel value="english" control={<Radio />} label="English" />
                    <FormControlLabel value="spanish" control={<Radio />} label="Spanish" />
                </RadioGroup>
            </FormControl>
        </FormFieldContainer>
    );
};
