import React from 'react';
import { useTable } from 'react-table';
import { Paths } from '@/common/const';
import { PatientTableRow } from '@/components/Patients/AllPatientsTable/styles';
import { NoPatients } from '@/components/Patients/NoPatients';
import { PatientsTableWrapper } from '@/components/common/Table/PatientsTable/styles';
import {
    TableWrapper,
    TableHeader,
    TableCell,
    FullWidthTable,
    TableHeaderCell,
} from '@/components/common/Table/styles';

interface AllPatientsTableProps {
    columnStructure: any[];
    data: any[];
}

export const GaitEventsPatientsTable = (props: AllPatientsTableProps) => {
    const { columnStructure, data } = props;
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns: columnStructure,
        data,
    });

    return (
        <PatientsTableWrapper>
            <TableWrapper>
                <FullWidthTable {...getTableProps()}>
                    <TableHeader>
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                key={headerGroup.id + '-' + headerGroup.Cell?.toString}
                            >
                                {headerGroup.headers.map((column) => {
                                    const headerProps = { ...column.getHeaderProps() };
                                    return (
                                        <TableHeaderCell
                                            {...headerProps}
                                            $isSorted={false}
                                            key={column.id}
                                            style={{
                                                width: column.width,
                                                maxWidth: column.width,
                                                minWidth: column.width,
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {column.render('Header')}
                                        </TableHeaderCell>
                                    );
                                })}
                            </tr>
                        ))}
                    </TableHeader>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <PatientTableRow
                                    {...row.getRowProps()}
                                    key={row.original.patientRecord?.id}
                                    to={`${Paths.patients}${row.original.patientRecord?.id}`}
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <TableCell
                                                {...cell.getCellProps()}
                                                key={cell.column.id}
                                                style={{
                                                    width: cell.column.width,
                                                    maxWidth: cell.column.width,
                                                    minWidth: cell.column.width,
                                                }}
                                                size="small"
                                            >
                                                {cell.render('Cell')}
                                            </TableCell>
                                        );
                                    })}
                                </PatientTableRow>
                            );
                        })}
                    </tbody>
                </FullWidthTable>
            </TableWrapper>
            {rows.length === 0 && <NoPatients />}
        </PatientsTableWrapper>
    );
};
