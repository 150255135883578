import { Box, styled, Divider, Dialog } from '@mui/material';

export const PrintViewContainer = styled(Box)(({ theme }) => ({
    width: '100vw',
}));

export const PageHeaderWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${theme.spacing(2.5)} 0 ${theme.spacing(1)}`,
}));

export const PageHeaderContainer = styled('div')(({ theme }) => ({
    maxWidth: 400,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

export const PageContentWrapper = styled('div')(({ theme }) => ({
    flex: '1',

    [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

export const PageTitleDivider = styled(Divider)(({ theme }) => ({
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(4),
}));

export const HEPBuilderModalWrapper = styled(Dialog)(({}) => ({
    width: '100%',
    height: '100%',

    '& > .MuiDialog-container > .MuiPaper-root': {
        width: '100%',
        height: '100%',
        maxWidth: 'none',
        maxHeight: 'none',
        margin: 0,
        borderRadius: 0,
    },
}));
