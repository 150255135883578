import mixpanelBrowser from 'mixpanel-browser';

export const mixpanel = {
    init: (appId: string) => {
        try {
            mixpanelBrowser.init(appId);
        } catch {}
    },
    identify: (userId: string) => {
        try {
            mixpanelBrowser.identify(userId);
        } catch {}
    },
    people: {
        set: (user: any) => {
            try {
                mixpanelBrowser.people.set(user);
            } catch {}
        },
    },
    track: (event: string, payload: any = undefined) => {
        if (!!payload) {
            payload.source = 'browser';
        } else {
            payload = { source: 'browser' };
        }
        try {
            mixpanelBrowser.track(event, payload);
        } catch {}
    },
    reset: () => {
        try {
            mixpanelBrowser.reset();
        } catch {}
    },
};
