import { DateTime } from 'luxon';
import { RtmEvent } from '@/common/const';
import { RTMCycleEvent } from '@/common/types';

export const summarize98977Events = (
    events: RTMCycleEvent[],
): { totalDays: number; proCnt: number; sessionCnt: number } => {
    const sessionDays = new Set(
        events
            .filter((e) => [RtmEvent.HEP_SESSION, RtmEvent.SELF_REPORTED_HEP_SESSION].includes(e.type))
            .map((e) => DateTime.fromJSDate(new Date(e.dateTime)).toFormat('LL/dd/yyyy')),
    );

    const proDays = new Set(
        events
            .filter((e) => e.type === RtmEvent.PRO_RESPONSE)
            .map((e) => DateTime.fromJSDate(new Date(e.dateTime)).toFormat('LL/dd/yyyy')),
    );

    const allDays = new Set([...sessionDays, ...proDays]);
    return { totalDays: allDays.size, proCnt: proDays.size, sessionCnt: sessionDays.size };
};
