import React, { useEffect, useState } from 'react';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { PROTypeformForm } from '@/common/types';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { resultIsError } from '@/services/HttpService';
import { PROFormsTable } from './PROFormsTable';

export const PROFormsTableContainer = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<PROTypeformForm[]>();
    const { httpService } = useHttpContext();

    const getForms = async () => {
        setIsLoading(true);
        const res = await httpService.adminGetPROTypeformForms();
        if (resultIsError(res)) {
        } else {
            setRows(res);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await getForms();
        })();
    }, []);

    const columnStructure = [
        {
            Header: 'ID',
            accessor: 'id',
            Cell: ({ value }) => <strong>{value}</strong>,
        },
        {
            Header: 'Has Exer Webhook Enabled',
            accessor: 'hasExerWebhookEnabled',
            Cell: ({ value }) => {
                return <strong>{value ? 'Confirmed' : 'Missing'}</strong>;
            },
        },
    ];

    return (
        <div>
            {isLoading ? <LoadingSpinner /> : rows && <PROFormsTable columnStructure={columnStructure} data={rows} />}
        </div>
    );
};
