import React, { Dispatch, SetStateAction, useState } from 'react';
import { ArrowBackIosNew } from '@mui/icons-material';
import { Collapse, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { SideOfBody } from '@/common/const';
import { PatientRecord } from '@/common/types';
import { getStringEnumKeyByValue } from '@/common/utils';
import { BlockPropSelectionWrapper, BlockPropSelectionLabel, SideOfBodyExpandButton } from './styles';

interface EditBlockSideProps {
    patientRecord: PatientRecord | null | undefined;
    sideOfBody: keyof typeof SideOfBody | undefined;
    setSideOfBody: Dispatch<SetStateAction<keyof typeof SideOfBody | undefined>>;
    exerciseNeedsSide: boolean;
    showToggle: boolean;
}

export const EditBlockSide = (props: EditBlockSideProps) => {
    const { patientRecord, sideOfBody, setSideOfBody, exerciseNeedsSide, showToggle } = props;

    const [sideSelectionOpen, setSideSelectionOpen] = useState(showToggle ? false : true);

    const handleSideOfBodyClick = (side: keyof typeof SideOfBody) => {
        setSideOfBody(side);
    };

    const handleSideOfBodyToggle = () => {
        setSideSelectionOpen(!sideSelectionOpen);
    };

    return exerciseNeedsSide || sideOfBody ? (
        <BlockPropSelectionWrapper>
            <BlockPropSelectionLabel id="program-block-side" $isActive={sideSelectionOpen}>
                Side Of Body
                {showToggle && (
                    <SideOfBodyExpandButton onClick={handleSideOfBodyToggle} $isActive={sideSelectionOpen}>
                        <ArrowBackIosNew />
                    </SideOfBodyExpandButton>
                )}
            </BlockPropSelectionLabel>
            <Collapse in={sideSelectionOpen}>
                <RadioGroup row aria-labelledby="program-block-side" name="program-block-side-group" value={sideOfBody}>
                    <FormControlLabel
                        value={getStringEnumKeyByValue(SideOfBody, SideOfBody.LEFT)}
                        control={<Radio />}
                        label={`${SideOfBody.LEFT}${
                            patientRecord?.productData?.health?.injuredSideOfBody &&
                            getStringEnumKeyByValue(SideOfBody, SideOfBody.LEFT) ===
                                patientRecord.productData.health.injuredSideOfBody
                                ? ' (Patient involved side)'
                                : ''
                        }`}
                        onChange={() => handleSideOfBodyClick(getStringEnumKeyByValue(SideOfBody, SideOfBody.LEFT))}
                    />
                    <FormControlLabel
                        value={getStringEnumKeyByValue(SideOfBody, SideOfBody.RIGHT)}
                        control={<Radio />}
                        label={`${SideOfBody.RIGHT}${
                            patientRecord?.productData?.health?.injuredSideOfBody &&
                            getStringEnumKeyByValue(SideOfBody, SideOfBody.RIGHT) ===
                                patientRecord.productData.health.injuredSideOfBody
                                ? ' (Patient involved side)'
                                : ''
                        }`}
                        onChange={() => handleSideOfBodyClick(getStringEnumKeyByValue(SideOfBody, SideOfBody.RIGHT))}
                    />
                </RadioGroup>
            </Collapse>
        </BlockPropSelectionWrapper>
    ) : null;
};
