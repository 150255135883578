import React from 'react';
import { AdminScanTableContainer } from '@/components/Scan/Admin/AdminScanTableContainer';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { PaginationProvider } from '@/components/context/PaginationContext';
import { RouteContentWrapper } from '../../styles';

export const AdminScanRoute = () => {
    return (
        <RouteContentWrapper>
            <PageTitle title="Scan Sessions" />
            <PaginationProvider>
                <AdminScanTableContainer />
            </PaginationProvider>
        </RouteContentWrapper>
    );
};
