import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { mixpanel } from '@/common/mixpanel';
import { PatientContainer } from '@/components/Patients/Patient/PatientContainer';
import { Breadcrumbs } from '@/components/layout/Breadcrumbs/Breadrumbs';
import { orgLogoAtomState } from '@/recoil/atoms/orgLogoAtom';
import { RouteContentWrapper } from '../styles';

export const PatientRoute = () => {
    const { patientRecordId } = useParams<{ patientRecordId: string }>();
    const orgLogoState = useRecoilValue(orgLogoAtomState);

    useEffect(() => {
        mixpanel.track('Viewed Patient Activity', { $current_url: '' });
    }, []);

    return (
        <RouteContentWrapper>
            <Breadcrumbs />
            <PatientContainer patientRecordId={parseInt(patientRecordId!)} />
            {orgLogoState ? <img src={orgLogoState} key={orgLogoState} /> : null}
        </RouteContentWrapper>
    );
};
