import { Action, CreateHEPDto, HEPBlock, HEPTemplate } from '@/common/types';

export const HepBuilderActions = {
    SET_NAME: 'SET_NAME',
    SET_NOTES: 'SET_NOTES',
    SET_FREQ_DAILY: 'SET_FREQ_DAILY',
    SET_FREQ_WEEKLY: 'SET_FREQ_WEEKLY',
    SET_BLOCKS: 'SET_BLOCKS',
    ADD_BLOCK: 'ADD_BLOCK',
    DELETE_BLOCK: 'DELETE_BLOCK',
    EDIT_BLOCK: 'EDIT_BLOCK',
};

interface HEPBuilderEditBlockPayload {
    index: number;
    hepBlock: HEPBlock;
}
type HEPBuilderActionPayload = string | number | HEPBlock | HEPBlock[] | HEPBuilderEditBlockPayload;

export interface HepBuilderAction extends Action {
    type: string;
    payload?: HEPBuilderActionPayload;
}

export const hepBuilderReducer = (
    state: CreateHEPDto | HEPTemplate,
    action: HepBuilderAction,
): CreateHEPDto | HEPTemplate => {
    const stateCopy = structuredClone(state);
    let payload: HEPBuilderActionPayload;
    switch (action.type) {
        case HepBuilderActions.SET_NAME:
            payload = action.payload as string;
            return { ...stateCopy, name: payload };
        case HepBuilderActions.SET_NOTES:
            payload = action.payload as string;
            return { ...stateCopy, notes: payload };
        case HepBuilderActions.SET_FREQ_DAILY:
            payload = action.payload as number;
            return { ...stateCopy, dailyFrequency: payload };
        case HepBuilderActions.SET_FREQ_WEEKLY:
            payload = action.payload as number;
            return { ...stateCopy, weeklyFrequency: payload };
        case HepBuilderActions.SET_BLOCKS:
            payload = action.payload as HEPBlock[];
            return { ...stateCopy, blocks: payload };
        case HepBuilderActions.DELETE_BLOCK:
            payload = action.payload as number;
            stateCopy.blocks = stateCopy.blocks.filter((_, i) => {
                return i !== payload;
            });
            return stateCopy;
        case HepBuilderActions.EDIT_BLOCK:
            payload = action.payload as HEPBuilderEditBlockPayload;
            stateCopy.blocks[payload.index] = payload.hepBlock;
            return stateCopy;
        default:
            return stateCopy;
    }
};
