import React from 'react';
import { TableCell } from '@mui/material';
import { useSortBy, useTable } from 'react-table';
import { TableWrapper, FullWidthTable, TableHeaderCell, TableHeader } from '@/components/common/Table/styles';

interface AdminGaitTableProps {
    columnStructure: any[];
    data: any[];
}

export const AdminGaitTable = (props: AdminGaitTableProps) => {
    const { columnStructure, data } = props;
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns: columnStructure,
            data,
            initialState: {
                sortBy: [
                    {
                        id: 'id',
                        desc: true,
                    },
                ],
            },
        },
        useSortBy,
    );

    return (
        <TableWrapper>
            <FullWidthTable {...getTableProps()}>
                <TableHeader style={{ width: '100%' }}>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={headerGroup.id + '-' + headerGroup.Cell?.toString}
                        >
                            {headerGroup.headers.map((column) => {
                                return (
                                    <TableHeaderCell
                                        key={column.id}
                                        $isSorted={true}
                                        style={{
                                            width: column.width,
                                            maxWidth: column.width,
                                            minWidth: column.width,
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {column.render('Header')}
                                    </TableHeaderCell>
                                );
                            })}
                        </tr>
                    ))}
                </TableHeader>

                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={row.original.id}>
                                {row.cells.map((cell) => {
                                    return (
                                        <TableCell
                                            {...cell.getCellProps()}
                                            key={cell.column.id}
                                            style={{
                                                width: cell.column.width,
                                                maxWidth: cell.column.width,
                                                minWidth: cell.column.width,
                                            }}
                                            size="small"
                                        >
                                            {cell.render('Cell')}
                                        </TableCell>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </FullWidthTable>
        </TableWrapper>
    );
};
