import { selector } from 'recoil';
import { StatusList } from '@/common/types';
import { RTMState } from '@/recoil/atoms/rtmAtom';
import { globalFilterState } from '../atoms/globalSearchAtom';

export const RTMSelector = selector({
    key: 'RTMSelector',
    get: ({ get }) => {
        const state = get(RTMState);
        const { filters } = state;
        const globalFilters = get(globalFilterState);

        const filteredStatusList = Object.keys(filters.statusList).filter(
            (status) => filters.statusList[status] === true,
        ) as StatusList[];

        const startDateSelected = filters.cycleStart;
        const endDateSelected = filters.cycleEnd;
        const selectedRTMMonth = filters.selectedMonth;
        const locationId = globalFilters.locationId;
        const reloadCyclesByMonth = state.loadCycles;

        return {
            filteredStatusList,
            startDateSelected,
            endDateSelected,
            selectedRTMMonth,
            locationId,
            reloadCyclesByMonth,
        };
    },
});
