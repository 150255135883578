import React, { lazy, Suspense, useEffect, useState } from 'react';
import star from 'react-useanimations/lib/star';
import { useRecoilState } from 'recoil';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { mixpanel } from '@/common/mixpanel';
import { Exercise } from '@/common/types';
import { favoriteExercisesState } from '@/recoil/atoms/favoriteExercisesAtom';
import { resultIsError } from '@/services/HttpService';
import { FavoriteExerciseActionWrapper } from './styles';

const UseAnimations = lazy(
    () =>
        import(
            /* webpackChunkName: "react-useanimations" */
            /* webpackPrefetch: true */ 'react-useanimations'
        ),
);

interface FavoriteExerciseActionProps {
    readonly exercise: Exercise;
}

export const FavoriteExerciseAction = (props: FavoriteExerciseActionProps) => {
    const { exercise } = props;
    const { id, name } = exercise;
    const { httpService } = useHttpContext();
    const [isFavorited, setIsFavorited] = useState(false);
    const [favoriteExercises, setFavoriteExercises] = useRecoilState(favoriteExercisesState);
    const [isBusy, setIsBusy] = useState(false);
    const { errorToast } = toasts;

    const handleSetFavorite = async () => {
        if (isBusy) {
            return;
        }
        if (!id) {
            errorToast('Unable to remove favorite exercise.');
            return;
        }
        setIsBusy(true);
        const res = await httpService.addFavoriteExercise(id);
        if (resultIsError(res)) {
            errorToast('Unable to add favorite exercise.');
        } else {
            setFavoriteExercises(res);
            mixpanel.track(`User favorited an exercise`, { 'Exercise Name': name });
        }
        setIsBusy(false);
    };

    const handleRemoveFavorite = async () => {
        if (isBusy) {
            return;
        }
        if (!id) {
            errorToast('Unable to remove favorite exercise.');
            return;
        }
        setIsBusy(true);
        const res = await httpService.removeFavoriteExercise(id);
        if (resultIsError(res)) {
            errorToast('Unable to remove favorite exercise.');
        } else {
            setFavoriteExercises(favoriteExercises.filter((favoriteExerciseId) => favoriteExerciseId !== id));
            mixpanel.track(`User unfavorited an exercise`, { 'Exercise Name': name });
        }
        setIsBusy(false);
    };

    useEffect(() => {
        if (id) {
            setIsFavorited(favoriteExercises?.includes(id));
        }
    }, [id, favoriteExercises]);

    const handleClick = () => {
        if (isFavorited) {
            handleRemoveFavorite();
        } else {
            handleSetFavorite();
        }
    };

    return (
        <FavoriteExerciseActionWrapper $isFavorited={isFavorited}>
            <Suspense>
                <UseAnimations
                    reverse={isFavorited}
                    onClick={() => {
                        handleClick();
                    }}
                    animation={star}
                    strokeColor="currentColor"
                    fillColor="currentColor"
                    size={30}
                />
            </Suspense>
        </FavoriteExerciseActionWrapper>
    );
};
