import React, { FC, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useExerUser } from '@/common/hooks/ExerUser';
import { Maybe, ScanSessionAdmin } from '@/common/types';
import { getAssessmentName } from '@/common/utils';
import { useAssessmentsContext } from '@/components/context/hooks/AssessmentsContext';
import { ScanAssessmentReportPDF } from './ScanAssesmentReportPDF/ScanAssessmentReportPDF';
import { ScanReportChart } from './ScanReportChart';
import { ScanAssessmentReportH2, ScanAssessmentReportFilters, ScanAssessmentReportSectionWrapper } from './styles';
import { ScanReportResultData } from './types';

interface ScanAssessmentReportProps {
    sessions: ScanSessionAdmin[];
    startEnd: Date;
    title: string;
    locationName?: Maybe<string>;
}

const ALL = 'all';

export const ScanAssessmentReport: FC<ScanAssessmentReportProps> = ({ sessions, startEnd, title, locationName }) => {
    const [selectedAssessmentId, setSelectedAssessmentId] = useState<string>(ALL);
    const { assessmentsDict } = useAssessmentsContext();
    const { user } = useExerUser();

    const assessmentMap = new Map<string, Map<string, ScanReportResultData>>();
    sessions.forEach((session) => {
        const assessmentId = session.session?.data?.assessmentId;
        if (!assessmentId) {
            return;
        }
        const resultMap = assessmentMap.get(assessmentId) || new Map<string, ScanReportResultData>();
        session.session?.data.results.forEach((result) => {
            const resultData: ScanReportResultData = resultMap.get(result.name) || {
                result: { name: result.name, units: result.units },
                data: [],
            };
            resultData.data.push({
                sessionId: session.id,
                participantUid: session.participant?.uid || 'Unknown',
                value: result.value,
            });
            resultMap.set(result.name, resultData);
        });
        assessmentMap.set(assessmentId, resultMap);
    });

    const assessmentEntries = [...assessmentMap.entries()];

    const filteredAssessmentEntries = assessmentEntries.filter((assessmentEntry) => {
        if (selectedAssessmentId === ALL) {
            return true;
        }
        if (assessmentEntry[0] !== selectedAssessmentId) {
            return false;
        }
        return true;
    });
    const handleChange = (e: SelectChangeEvent<string>) => {
        setSelectedAssessmentId(e.target.value);
    };

    return (
        <>
            <ScanAssessmentReportFilters>
                <div style={{ width: '25%' }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Assessment Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Assessment Filter"
                            onChange={handleChange}
                            value={selectedAssessmentId}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value={ALL}>All</MenuItem>
                            {assessmentEntries.map((assessmentEntry) => {
                                const assessmentId = assessmentEntry[0];
                                return (
                                    <MenuItem key={assessmentId} value={assessmentId}>
                                        {getAssessmentName(assessmentId, assessmentsDict)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <ScanAssessmentReportPDF
                    assessmentEntries={filteredAssessmentEntries}
                    scanReportPDFProps={{
                        title,
                        locationName,
                        organizationLogoPrintUrl: user.organizationLogoPrintUrl,
                    }}
                />
            </ScanAssessmentReportFilters>

            {filteredAssessmentEntries.map((assessmentEntry) => {
                const assessmentId = assessmentEntry[0];
                const resultMap = assessmentEntry[1];
                return (
                    <ScanAssessmentReportSectionWrapper key={assessmentId}>
                        <ScanAssessmentReportH2>
                            {getAssessmentName(assessmentId, assessmentsDict)}
                        </ScanAssessmentReportH2>
                        {[...resultMap.entries()].map((resultEntry) => {
                            const resultName = resultEntry[0];
                            const resultData = resultEntry[1];
                            return (
                                <>
                                    <h3>{resultName}</h3>
                                    <ScanReportChart
                                        assessmentId={assessmentId}
                                        resultData={resultData}
                                        startEnd={startEnd}
                                    />
                                </>
                            );
                        })}
                    </ScanAssessmentReportSectionWrapper>
                );
            })}
        </>
    );
};
