import { useContext } from 'react';
import { ExercisesContext } from '../ExercisesContext';

export const useExercisesContext = () => {
    const exercisesContext = useContext(ExercisesContext);

    if (!exercisesContext) {
        throw new Error('ExercisesContext not initialized');
    }

    return exercisesContext;
};
