import { styled } from '@mui/material';
import { Modal } from '@/components/common/Modal/Modal';

export const AddToProgramModal = styled(Modal)(({ theme }) => ({
    zIndex: 2000,
    padding: '0',
    '& .MuiDialogContent-root': {
        padding: '0',
    },
    '& .MuiDialogTitle-root': {
        color: theme.palette.grey[900],
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        fontSize: theme.typography.h5.fontSize,
    },
}));
