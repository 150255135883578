import { styled, Typography, Card, Grid, Paper, Avatar } from '@mui/material';

export const StepInformationWrapper = styled(Grid)(({}) => ({}));

export const StepContentWrapper = styled(Card)(({ theme }) => ({
    boxShadow: '0px 2px 5px rgba(16, 5, 51, 0.04)',
    padding: `${theme.spacing(5)} ${theme.spacing(8)}`,
    width: '100%',

    '.MuiFormControl-root': {
        display: 'flex',
    },
}));

export const ExerciseDetailsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.grey[600],
    paddingRight: theme.spacing(2),
    fontSize: theme.typography.fontSize,
}));

export const ExerciseDetailsText = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[900],
}));

export const RadioCardGroupWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

export const CardButtonWrapper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
}));

export const CardButton = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const CardButtonAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.primary[200],
    color: theme.palette.primary[600],
    width: theme.spacing(4),
    height: theme.spacing(4),
}));

export const CardButtonText = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(2),
    lineHeight: 1,
}));

export const CardButtonDescriptionText = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(2),
    lineHeight: 1,
    textTransform: 'none',
}));
