import { styled, Grid, Typography } from '@mui/material';

export const DashboardInnerContent = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(4)}`,
    },
}));

export const AuthContainer = styled(Grid)(({ theme }) => ({
    textAlign: 'center',
    marginTop: '0',
    minHeight: '100vh',
}));

export const AuthFormWrapper = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingRight: '0',
    backgroundColor: theme.palette.common.white,
    '& .MuiSvgIcon-root': {
        fill: 'none',
    },
}));

export const AuthLogo = styled('object')(({ theme }) => ({
    pointerEvents: 'none',
}));

export const AuthLogoWrapper = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f9fafc',
    padding: theme.spacing(4),
    paddingRight: '0',
}));

export const AuthLogoSubTitle = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(6),
    maxWidth: '400px',
}));
