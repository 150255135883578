import React from 'react';
import { Navigate } from 'react-router-dom';
import { Paths } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { isExerSupport } from '@/common/utils';

export const AdminRoute = () => {
    const { user } = useExerUser();
    const isSupport = isExerSupport(user);
    return <Navigate to={isSupport ? Paths.adminSettings : Paths.adminOrg} />;
};
