import React, { createContext, Dispatch, SetStateAction, useState } from 'react';

interface IModalContext {
    dialogOpen: boolean;
    setDialogOpen: Dispatch<SetStateAction<boolean>>;
    title: string;
    setTitle: Dispatch<SetStateAction<string>>;
    content?: JSX.Element;
    setContent: Dispatch<SetStateAction<JSX.Element | undefined>>;
    setDialog: SetDialog;
}
export const ModalContext = createContext<IModalContext | null>(null);

export type SetDialog = (title: string, content: JSX.Element) => void;

export const ModalProvider = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState<JSX.Element>();

    const setDialog: SetDialog = (title: string, content: JSX.Element) => {
        setTitle(title);
        setContent(content);
    };
    const modalContext = { dialogOpen, setDialogOpen, title, setTitle, content, setContent, setDialog };
    return <ModalContext.Provider value={modalContext}>{props.children}</ModalContext.Provider>;
};
