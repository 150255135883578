import React from 'react';
import { Typography } from '@mui/material';
import { AlertIcon } from '../Icons/AlertIcon';
import { ChartEmptyIcon, ChartEmptyWrapper } from './styles';

interface ChartEmptyProps {
    title: string;
    body: string;
}

export const ChartEmpty = (props: ChartEmptyProps) => {
    const { title, body } = props;
    return (
        <ChartEmptyWrapper>
            <ChartEmptyIcon>
                <AlertIcon />
            </ChartEmptyIcon>
            <Typography variant="h4">{title}</Typography>
            <Typography variant="subtitle1" fontWeight={500}>
                {body}
            </Typography>
        </ChartEmptyWrapper>
    );
};
