import React, { useEffect, useState } from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { IconButton, useTheme, Zoom } from '@mui/material';
import { ControlsWrapper, ButtonsWrapper, ButtonTooltip, FABTooltip } from './styles';

interface ZoomControlsProps {
    target: HTMLElement;
    fontSize: number;
    setFontSize: (fontSize) => void;
    open: boolean;
}

const FONT_SIZE_INCREMENT = 10;

export const ZoomControls = (props: ZoomControlsProps) => {
    const { target, fontSize, setFontSize, open } = props;
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        target.style.fontSize = `${fontSize}%`;

        open && setTooltipIsOpen(true);

        const tooltipTimer = setTimeout(() => {
            setTooltipIsOpen(false);
        }, 800);

        return () => clearTimeout(tooltipTimer);
    }, [fontSize, open]);

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    return (
        <ControlsWrapper>
            <Zoom
                in
                timeout={transitionDuration}
                style={{
                    transitionDelay: `${transitionDuration.exit}ms`,
                }}
                unmountOnExit
            >
                <ButtonsWrapper sx={{ boxShadow: 4 }}>
                    <FABTooltip title={`${fontSize}%`} placement="left" open={tooltipIsOpen} TransitionComponent={Zoom}>
                        <div>
                            <ButtonTooltip title={`Zoom out`} placement="top" arrow>
                                <IconButton
                                    aria-label="delete"
                                    size="medium"
                                    onClick={() => setFontSize(fontSize - FONT_SIZE_INCREMENT)}
                                >
                                    <ZoomOutIcon fontSize="large" style={{ color: '#fff' }} />
                                </IconButton>
                            </ButtonTooltip>
                            <ButtonTooltip title={`Zoom in`} placement="top" arrow>
                                <IconButton
                                    aria-label="delete"
                                    size="medium"
                                    onClick={() => setFontSize(fontSize + FONT_SIZE_INCREMENT)}
                                >
                                    <ZoomInIcon fontSize="large" style={{ color: '#fff' }} />
                                </IconButton>
                            </ButtonTooltip>
                        </div>
                    </FABTooltip>
                </ButtonsWrapper>
            </Zoom>
        </ControlsWrapper>
    );
};
