import React from 'react';
import { AdminLocationsListContainer } from '@/components/Organization/Locations/Admin/AdminLocationsListContainer';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { RouteContentWrapper } from '../../styles';

export const AdminLocationsRoute = () => {
    return (
        <RouteContentWrapper>
            <PageTitle title="Locations" />
            <AdminLocationsListContainer />
        </RouteContentWrapper>
    );
};
