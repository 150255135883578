import React from 'react';
import { NoPatientsWrapper } from './styles';

export const NoPatients = () => {
    return (
        <NoPatientsWrapper>
            <h3>No patients meet that criteria.</h3>
        </NoPatientsWrapper>
    );
};
