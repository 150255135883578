import React from 'react';
import { SvgIcon } from '@mui/material';

export const IconFilter = () => {
    return (
        <>
            <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path
                    d="M13.875 16.6244L3.75 16.6244"
                    stroke="currentColor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.25 16.6244L17.625 16.6244"
                    stroke="currentColor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.75 18.4994C16.7855 18.4994 17.625 17.6599 17.625 16.6244C17.625 15.5889 16.7855 14.7494 15.75 14.7494C14.7145 14.7494 13.875 15.5889 13.875 16.6244C13.875 17.6599 14.7145 18.4994 15.75 18.4994Z"
                    stroke="currentColor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.875 8.3744L3.75 8.37433"
                    stroke="currentColor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.25 8.37433L11.625 8.3744"
                    stroke="currentColor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.75 10.2494C10.7855 10.2494 11.625 9.40992 11.625 8.37439C11.625 7.33886 10.7855 6.49939 9.75 6.49939C8.71447 6.49939 7.875 7.33886 7.875 8.37439C7.875 9.40992 8.71447 10.2494 9.75 10.2494Z"
                    stroke="currentColor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </SvgIcon>
        </>
    );
};
