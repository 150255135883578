import React, { useState, ChangeEvent } from 'react';
import { Close } from '@mui/icons-material';
import { CreateIntegerArray } from '@/common/utils';
import { EditActionButton } from '@/components/Health/HEPBuilder/steps/EditActions/styles';
import { HEPInputWrapper } from '@/components/Health/HEPBuilder/steps/StepInformation/styles';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { Modal } from '@/components/common/Modal/Modal';
import { ModalCloseButton } from '@/components/common/Modal/styles';
import { ToggleButton } from '@/components/common/ToggleButton/ToggleButton';
import { useHEPBuilderContext } from '../../state/hooks/HEPBuilderContext';

interface FrequencyEditorProps {
    isInModal: boolean;
}

export const FrequencyEditor = (props: FrequencyEditorProps) => {
    const { isInModal } = props;
    const { hepDetails, hepBuilderActions } = useHEPBuilderContext();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [dailyFrequency, setDailyFrequency] = useState(hepDetails.dailyFrequency);
    const [weeklyFrequency, setWeeklyFrequency] = useState(hepDetails.weeklyFrequency);

    const handleDailyChange = (_: ChangeEvent, newValue: number) => {
        isInModal ? setDailyFrequency(newValue) : hepBuilderActions?.setDailyFrequency(newValue);
    };

    const handleWeeklyChange = (_: ChangeEvent, newValue: number) => {
        isInModal ? setWeeklyFrequency(newValue) : hepBuilderActions?.setWeeklyFrequency(newValue);
    };

    const handleCloseModal = () => {
        setDailyFrequency(hepDetails.dailyFrequency);
        setWeeklyFrequency(hepDetails.weeklyFrequency);
        setModalIsOpen(!modalIsOpen);
    };

    const handleSubmit = () => {
        setModalIsOpen(!modalIsOpen);
        hepBuilderActions?.setDailyFrequency(dailyFrequency);
        hepBuilderActions?.setWeeklyFrequency(weeklyFrequency);
    };

    return (
        <>
            {isInModal ? (
                <>
                    <EditActionButton size="small" onClick={() => setModalIsOpen(true)} startIcon={<EditIcon />}>
                        Edit
                    </EditActionButton>
                    <Modal
                        open={modalIsOpen}
                        onClose={handleCloseModal}
                        title="Edit Program Frequency"
                        fullWidth={true}
                        maxWidth={'lg'}
                    >
                        <HEPInputWrapper>
                            <h4>Daily Frequency</h4>
                            <p>How many times a day the patient should complete the exercise.</p>
                            <ToggleButton
                                items={CreateIntegerArray(5)}
                                ariaLabel="daily frequency toggle button group"
                                defaultValue={hepDetails.dailyFrequency}
                                onChange={handleDailyChange}
                                enforce
                            />
                        </HEPInputWrapper>
                        <HEPInputWrapper>
                            <h4>Weekly Frequency</h4>
                            <p>How many times a week the patient should complete the exercise.</p>
                            <ToggleButton
                                items={CreateIntegerArray(7)}
                                ariaLabel="weekly frequency toggle button group"
                                defaultValue={hepDetails.weeklyFrequency}
                                onChange={handleWeeklyChange}
                                enforce
                            />
                        </HEPInputWrapper>
                        <SubmitCancelButtons
                            submitFunction={handleSubmit}
                            cancelFunction={handleCloseModal}
                            submitText="Save"
                        />
                        <ModalCloseButton onClick={handleCloseModal}>
                            <Close />
                        </ModalCloseButton>
                    </Modal>
                </>
            ) : (
                <>
                    <HEPInputWrapper>
                        <h4>Daily Frequency</h4>
                        <p>How many times a day the patient should complete the exercise.</p>
                        <ToggleButton
                            items={CreateIntegerArray(5)}
                            ariaLabel="daily frequency toggle button group"
                            defaultValue={hepDetails.dailyFrequency}
                            onChange={handleDailyChange}
                            enforce
                        />
                    </HEPInputWrapper>
                    <HEPInputWrapper>
                        <h4>Weekly Frequency</h4>
                        <p>How many times a week the patient should complete the exercise.</p>
                        <ToggleButton
                            items={CreateIntegerArray(7)}
                            ariaLabel="weekly frequency toggle button group"
                            defaultValue={hepDetails.weeklyFrequency}
                            onChange={handleWeeklyChange}
                            enforce
                        />
                    </HEPInputWrapper>
                </>
            )}
        </>
    );
};
