import React from 'react';
import ExerLoaderSVG from '@/images/exer-loader.svg';
import { LoaderOuterWrapper, LoaderWrapper } from './styles';

export const ExerLoader = () => {
    return (
        <LoaderOuterWrapper>
            <LoaderWrapper>
                <object type="image/svg+xml" data={ExerLoaderSVG}></object>
            </LoaderWrapper>
        </LoaderOuterWrapper>
    );
};
