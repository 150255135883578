import { styled } from '@mui/material';
import { Button } from '@/components/common/button/button';

export const ExerciseFiltersWrapper = styled('div')(({ theme }) => ({}));

export const ExerciseFilterWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(2),

    '& h5': {
        fontSize: 12,
        textTransform: 'uppercase',
        margin: 0,
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
    },

    '& .MuiTypography-root': {
        color: theme.palette.grey[600],
        fontSize: 12,
    },

    '& .MuiRadio-root': {
        padding: theme.spacing(1),
    },
}));

export const ExerciseBooleanFilterWrapper = styled(ExerciseFilterWrapper)(({ theme }) => ({
    position: 'relative',

    '& .MuiSwitch-root': {
        position: 'absolute',
        top: -12,
        right: 0,
    },
}));

export const ExerciseActiveFilterWrapper = styled(Button)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(0.6),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    borderRadius: 20,
    fontSize: 11,
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
}));

export const ExerciseActiveFilters = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
    minHeight: 80,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& .MuiButton-root': {
        whiteSpace: 'unset',
    },
}));
