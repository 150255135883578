import React from 'react';
import xOff from '@/images/x-off.svg';
import { NotFoundLink, NotFoundWrapper } from './styles';

export const NotFoundRoute = () => {
    return (
        <NotFoundWrapper>
            <img src={xOff} />
            <h3>Looks like our form is a little off. We had trouble finding that URL.</h3>
            <NotFoundLink href="/" variant="secondary">
                Try the homepage
            </NotFoundLink>
        </NotFoundWrapper>
    );
};
