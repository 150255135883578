import { Box } from '@mui/material';
import styled from '@mui/material/styles/styled';

export const ScanSessionDetailsRow = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

export const ScanSessionDetailedDetails = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
}));

export const ScanSessionResultsRow = styled('li')(({ theme }) => ({
    listStyleType: 'none',
    paddingLeft: theme.spacing(2),
}));

export const AdminDateRangeWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
}));
