import React from 'react';
import SwitchUnstyled from '@mui/base/SwitchUnstyled';
import { Switch } from '@/components/common/Switch/styles';
import { FilterDict } from '../ExerciseList/state/ExerciseListReducer';
import { IExerciseFilter } from './ExerciseFilters';
import { ExerciseBooleanFilterWrapper } from './styles';

interface FilterProps {
    filter: IExerciseFilter;
    updateFilters: (type: string, value: string | string[], include: boolean) => void;
    activeFilters: FilterDict;
}

export const ExerciseBooleanFilter = (props: FilterProps) => {
    const {
        filter: { id, name, options },
        updateFilters,
        activeFilters,
    } = props;

    const checked = !!activeFilters[id]?.value;
    const [booleanOptionValue] = options;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFilters(id, booleanOptionValue, e.target.checked);
    };
    const label = { componentsProps: { input: { 'aria-label': 'Time-Based Exercise Filter' } } };

    return (
        <ExerciseBooleanFilterWrapper>
            <h5>{name}: </h5>
            <SwitchUnstyled component={Switch} {...label} onChange={handleChange} checked={checked} />
        </ExerciseBooleanFilterWrapper>
    );
};
