import { atom } from 'recoil';
import { LoadingStatus } from '@/common/types';

export const healthSessionImagesState = atom({
    key: 'healthSessionImagesState',
    default: new Map(),
});

export const healthChartsLoadingState = atom({
    key: 'healthChartsLoadingState',
    default: LoadingStatus.IDLE,
});

export const proChartImageState = atom({
    key: 'proChartImageState',
    default: new Map(),
});

export const selfReportedFilterState = atom({
    key: 'selfReportedFilter',
    default: true,
});
