import React from 'react';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { PaginationProvider } from '@/components/context/PaginationContext';
import { ScanReports } from './ScanReports';

export const ScanReportsContainer = () => {
    return (
        <>
            <PageTitle title="Scan Reports" />
            <PaginationProvider>
                <ScanReports />
            </PaginationProvider>
        </>
    );
};
