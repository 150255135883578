import React from 'react';
import { DialogContent } from '@mui/material';
import { RecordType } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { resendInvite } from '@/common/resendInvite';
import { Practitioner } from '@/common/types';
import { DialogContentWrapper } from '@/components/common/Dialog/styles';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { useModalContext } from '@/components/context/hooks/ModalContext';

interface Props {
    practitioner: Practitioner;
    handleCancelClick: (_: React.MouseEvent) => void;
}
export const ResendInviteDialogContent = (props: Props) => {
    const { practitioner, handleCancelClick } = props;
    const { httpService } = useHttpContext();
    const { setDialogOpen } = useModalContext();
    const { successToast, errorToast } = toasts;

    const handleSubmit = async () => {
        const success = await resendInvite(httpService, RecordType.PRACTITIONER, practitioner.id);

        if (success) {
            successToast('Invite successfully sent.');
            setDialogOpen(false);
        } else {
            errorToast('Invite could not be sent.');
        }
    };

    return (
        <DialogContent>
            <DialogContentWrapper>
                <h3>
                    Send another invitation to {practitioner.firstName} {practitioner.lastName}?
                </h3>
                <SubmitCancelButtons
                    submitFunction={handleSubmit}
                    cancelFunction={handleCancelClick}
                    submitText="Send"
                />
            </DialogContentWrapper>
        </DialogContent>
    );
};
