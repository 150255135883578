import React from 'react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Paths } from '@/common/const';
import { HEPTemplate } from '@/common/types';
import { TemplateCardActionsMenu } from '@/components/Health/HEPs/HEPTemplatesCardList/HEPTemplateCard/TemplateCardActionsMenu';
import { WeightIcon } from '@/components/common/Icons/WeightIcon';
import { deleteTemplatesListState } from '@/recoil/atoms/hepTemplatesAtom';
import { TemplateCardCheckbox } from './TemplateCardCheckbox';
import {
    TemplateCardHeader,
    TemplateCardWrapper,
    TemplateCardTitle,
    TemplateCardContent,
    TemplateCardNotes,
    TemplateCardExerciseWrapper,
    TemplateCardCalIcon,
    TemplateCardHeaderActions,
    TemplateCardActionArea,
} from './styles';

interface TemplateCardProps {
    hepTemplete: HEPTemplate;
}

export const TemplateCard = (props: TemplateCardProps) => {
    const { hepTemplete } = props;
    const navigate = useNavigate();
    const [deleteTemplatesState, setDeleteTemplatesState] = useRecoilState(deleteTemplatesListState);
    const { ids, selectIsActive } = deleteTemplatesState;

    const handleCardClick = () => {
        navigate(`${Paths.programsHEPTemplates}${hepTemplete?.id}`);
    };

    const handleCheckboxClick = () => {
        if (!ids.includes(hepTemplete.id)) {
            setDeleteTemplatesState({ ...deleteTemplatesState, ids: [...ids, hepTemplete.id] });
        } else {
            const filtered = ids.filter((val) => val !== hepTemplete.id);
            setDeleteTemplatesState({ ...deleteTemplatesState, ids: filtered });
        }
    };

    return (
        <TemplateCardWrapper>
            <TemplateCardHeaderActions>
                <TemplateCardHeader
                    avatar={<TemplateCardCalIcon />}
                    action={
                        selectIsActive ? (
                            <TemplateCardCheckbox onClick={handleCheckboxClick} />
                        ) : (
                            <TemplateCardActionsMenu hepTemplete={hepTemplete} handleViewHep={handleCardClick} />
                        )
                    }
                    subheader={DateTime.fromISO(hepTemplete.dateUpdated).toFormat('LL/dd/yyyy').toLocaleString()}
                />
            </TemplateCardHeaderActions>
            <TemplateCardActionArea onClick={handleCardClick} disabled={selectIsActive}>
                <TemplateCardContent>
                    <TemplateCardTitle noWrap>{hepTemplete.name}</TemplateCardTitle>

                    <TemplateCardExerciseWrapper>
                        <WeightIcon />
                        {hepTemplete.blocks.length} exercise{`${hepTemplete.blocks.length > 1 ? 's' : ''}`}
                    </TemplateCardExerciseWrapper>
                    <TemplateCardNotes>{hepTemplete.notes}</TemplateCardNotes>
                </TemplateCardContent>
            </TemplateCardActionArea>
        </TemplateCardWrapper>
    );
};
