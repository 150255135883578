import { styled } from '@mui/material';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { TrashIcon } from '@/components/common/Icons/TrashIcon';

export const TooltipDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
}));

export const HEPBlockEditIcon = styled(EditIcon)({});

export const HEPBlockDeleteIcon = styled(TrashIcon)({});
