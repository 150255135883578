import React, { useState } from 'react';
import { HEPTemplate, TemplateOrHEP } from '@/common/types';
import { BuilderActions } from '@/components/Health/HEPBuilder/state/HEPBuilderContext';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { Button } from '@/components/common/button/button';
import { HEPBuilderModal } from '../../../HEPBuilder/HepBuilderModal/HEPBuilderModal';

interface EditHEPTemplateActionProps {
    hepTemplate: HEPTemplate;
}

export const EditHEPTemplateAction = (props: EditHEPTemplateActionProps) => {
    const { hepTemplate } = props;
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClick = () => {
        setDialogOpen(true);
    };

    return (
        <>
            <Button
                onClick={handleClick}
                color="primary"
                aria-label="Edit Program"
                size="small"
                startIcon={<EditIcon color="currentcolor" />}
            >
                Edit Template
            </Button>
            <HEPBuilderModal
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                {...props}
                existingTemplateOrHEP={hepTemplate}
                actionType={BuilderActions.EDIT_TEMPLATE}
                isTemplateOrHEP={TemplateOrHEP.TEMPLATE}
            />
        </>
    );
};
