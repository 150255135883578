import { DateTime } from 'luxon';

export const createMonthList = ({ timeBetween, maxPastDate }: { timeBetween: number; maxPastDate: DateTime }) => {
    const monthList = new Map();
    for (let i = 0; i < timeBetween; i++) {
        if (monthList.has(maxPastDate.plus({ months: i }).year)) {
            monthList.set(maxPastDate.plus({ months: i }).year, [
                ...monthList.get(maxPastDate.plus({ months: i }).year),
                maxPastDate.plus({ months: i }).monthShort.toLowerCase(),
            ]);
        } else {
            monthList.set(maxPastDate.plus({ months: i }).year, [
                maxPastDate.plus({ months: i }).monthShort.toLowerCase(),
            ]);
        }
    }

    return monthList;
};
