import React from 'react';
import { FC } from 'react';
import { Maybe } from '@/common/types';
import { wordToTitleCase } from '@/common/utils';
import { FactorCriteria, OutcomeFactorTableData } from '../types/types';
import {
    OFTColumn,
    OutcomeFactorSexTableWrapper,
    OutcomeFactorTableHeader,
    OutcomeFactorTableRow,
    OutcomeFactorTableWrapper,
} from './styles';

interface OutcomeFactorTableProps {
    outcomeFactorTableData: {
        sexMap: OutcomeFactorTableData;
        resultCriterion: Maybe<FactorCriteria>;
    };
}

export const OutcomeFactorTable: FC<OutcomeFactorTableProps> = ({ outcomeFactorTableData }) => {
    const { sexMap, resultCriterion } = outcomeFactorTableData;

    return (
        <OutcomeFactorTableWrapper>
            {Array.from(sexMap).map((sex) => {
                const [sexName, sexData] = sex;
                return (
                    <OutcomeFactorSexTableWrapper key={sexName}>
                        <OutcomeFactorTableHeader>
                            {resultCriterion && `${wordToTitleCase(resultCriterion.type)}`} (
                            {sexName && wordToTitleCase(sexName)})
                        </OutcomeFactorTableHeader>
                        {sexData.rows.map((row, i) => {
                            return (
                                <OutcomeFactorTableRow
                                    key={row.ageRange}
                                    $isHeaderRow={i === 0}
                                    $isResultRow={row.isResultRow}
                                    $factorColor={row.factorColor}
                                    $isLastRow={i === sexData.rows.length - 1}
                                >
                                    <OFTColumn>{row.ageRange}</OFTColumn>
                                    {row.values.map((value, j) => {
                                        return (
                                            <OFTColumn
                                                key={row.ageRange + value}
                                                $isLastColumn={j === row.values.length - 1}
                                            >
                                                {value.value}
                                                {value.unit === '°' ? (
                                                    <sup>{value.unit}</sup>
                                                ) : (
                                                    <span>{value.unit}</span>
                                                )}
                                            </OFTColumn>
                                        );
                                    })}
                                </OutcomeFactorTableRow>
                            );
                        })}
                    </OutcomeFactorSexTableWrapper>
                );
            })}
        </OutcomeFactorTableWrapper>
    );
};
