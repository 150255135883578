import React from 'react';
import { OrganizationEmptyEntitiesWrapper } from '../styles';

export const EmptyUserLocations = () => {
    return (
        <OrganizationEmptyEntitiesWrapper>
            <h3>
                You do not have any locations assigned. If this is incorrect, please contact an admin at your
                organization.
            </h3>
        </OrganizationEmptyEntitiesWrapper>
    );
};
