import { Angles, Edges, RenderKeypoints } from '@exerai/vision-engine-web';
import { Maybe } from '@/common/types';

export enum SubjectOrientation {
    Front = 'FRONT',
    Side = 'SIDE',
    Rear = 'REAR',
}

export enum SideOfBody {
    Right = 'RIGHT',
    Left = 'LEFT',
}

export enum AngleType {
    EDGES = 'EDGES', // EDGES do not necessarily have a common vertex
    JOINT_EDGES = 'JOINT_EDGES', // JOINT_EDGES are edges that have a common vertex
}

export type EdgeAngleTuple = [[number, number], [number, number]];

export type JointEdgeAngleTuple = [number, number, number];

export interface EdgeAngleType {
    type: AngleType.EDGES;
    edges: EdgeAngleTuple;
}

export interface JointEdgeAngleType {
    type: AngleType.JOINT_EDGES;
    joints: JointEdgeAngleTuple;
}

export interface AngleThreshold {
    angle: EdgeAngleType | JointEdgeAngleType;
    name: string;
    min: number;
    max: number;
}

interface GIPConditions {
    sideToCamera: SideOfBody;
    checkpoints: AngleThreshold[];
}

interface TimeCondition {
    type: ConditionType.TIME;
    time: number;
}

interface CheckpointsCondition {
    type: ConditionType.CHECKPOINTS;
    checkpoints: AngleThreshold[];
}

export interface EndingConditions {
    gate: GateLogic;
    conditions: (TimeCondition | CheckpointsCondition)[];
}

interface NoneCondition {
    type: ConditionType.NONE;
}

export interface StartingConditions extends Omit<EndingConditions, 'conditions'> {
    conditions: (TimeCondition | CheckpointsCondition | NoneCondition)[];
}

export interface ROMAssessmentResult {
    type: AssessmentResultType.ROM;
    extremum: Extremum;
    angle: EdgeAngleType | JointEdgeAngleType;
    angleAsComplimentary?: Maybe<boolean>;
    name: string;
}

export type AssessmentResult = ROMAssessmentResult;

export interface Assessment {
    purpose: string;
    setupDescription: string;
    subjectOrientation: SubjectOrientation;
    supportsLeftRight: boolean;
    thumbnailUrl: string;
    environmentAnimationUrl: string;
    participantImageUrl: string;
    participantImageProviderAssistUrl: string;
    leftParticipantImageUrl: string;
    leftParticipantImageProviderAssistUrl: string;
    rightParticipantImageUrl: string;
    rightParticipantImageProviderAssistUrl: string;
    exerciseProperties: null;
    id: string;
    name: string;
    updated: string;
    versions: [];
    enabledVersions: [];
    startingConditions: StartingConditions;
    endingConditions: EndingConditions;
    gipConditions: GIPConditions;
    resultDefinitions: AssessmentResult[];
    tick: (kp: RenderKeypoints, e: Edges, a: Angles) => void;
}

export enum ConditionType {
    TIME = 'TIME',
    CHECKPOINTS = 'CHECKPOINTS',
    NONE = 'NONE',
}
export enum GateLogic {
    AND = 'AND',
    OR = 'OR',
}
export enum AssessmentResultType {
    'ROM' = 'ROM',
    'REP' = 'REP',
    'TIME' = 'TIME',
}
export enum Extremum {
    'MIN' = 'MIN',
    'MAX' = 'MAX',
}
const gipConditions = {
    sideToCamera: SideOfBody.Right,
    checkpoints: [],
};

const startingConditions: StartingConditions = {
    gate: GateLogic.AND,
    conditions: [{ type: ConditionType.NONE }],
};

const endingConditions: EndingConditions = {
    gate: GateLogic.AND,
    conditions: [
        {
            type: ConditionType.TIME,
            time: 300,
        },
    ],
};

const resultDefinitions: AssessmentResult[] = [
    {
        type: AssessmentResultType.ROM,
        extremum: Extremum.MAX,
        angle: {
            type: AngleType.EDGES,
            edges: [
                [2, 1],
                [1, 0],
            ],
        },
        angleAsComplimentary: true,
        name: 'Max Right Knee Flexion',
    },
    {
        type: AssessmentResultType.ROM,
        extremum: Extremum.MAX,
        angle: {
            type: AngleType.EDGES,
            edges: [
                [3, 4],
                [4, 5],
            ],
        },
        angleAsComplimentary: true,
        name: 'Max Left Knee Flexion',
    },
    {
        type: AssessmentResultType.ROM,
        extremum: Extremum.MAX,
        angle: {
            type: AngleType.EDGES,
            edges: [
                [19, 2],
                [2, 1],
            ],
        },
        angleAsComplimentary: true,
        name: 'Max Right Hip Flexion',
    },
    {
        type: AssessmentResultType.ROM,
        extremum: Extremum.MAX,
        angle: {
            type: AngleType.EDGES,
            edges: [
                [19, 3],
                [3, 4],
            ],
        },
        angleAsComplimentary: true,
        name: 'Max Left Hip Flexion',
    },
];

export const astGait: Assessment = {
    purpose:
        'A gait assessment focusing on balance and stability that involves evaluating the patient’s ability to maintain equilibrium while walking. This includes observing the patient’s posture, control of body movements, and the ability to walk in a straight line without swaying or stumbling. These evaluations help identify issues related to balance and stability, which are crucial for preventing falls and ensuring safe, independent mobility.',
    setupDescription: '',
    subjectOrientation: SubjectOrientation.Side,
    supportsLeftRight: false,
    thumbnailUrl: '',
    environmentAnimationUrl: '',
    participantImageUrl: '',
    participantImageProviderAssistUrl: '',
    leftParticipantImageUrl: '',
    leftParticipantImageProviderAssistUrl: '',
    rightParticipantImageUrl: '',
    rightParticipantImageProviderAssistUrl: '',
    exerciseProperties: null,
    id: 'AST-GAIT',
    name: 'Gait (Side-Facing)',
    updated: '2024-07-17T00:16:06.642Z',
    versions: [],
    enabledVersions: [],
    gipConditions,
    startingConditions,
    endingConditions,
    resultDefinitions,
    // TODO: This is Assessment specific logic run after(?) session.tick
    tick: (kp, e, a) => {},
};
