import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Chart as ChartJS } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { DateTime } from 'luxon';
import { useSetRecoilState } from 'recoil';
import { Maybe } from '@/common/types';
import { assessmentReportChartImageState } from '@/recoil/atoms/assessmentReportAtom';
import { getResultPrecision } from '../Outcomes/utils';
import { ScanReportDownloadButtonWrapper, ScanReportDownloadButton, ScanReportChartWrapper } from './styles';
import { ScanReportResultData } from './types';

interface ScanReportChartProps {
    resultData: ScanReportResultData;
    startEnd?: Date;
    setChartImages?: Maybe<boolean>;
    assessmentId: string;
}

export const ScanReportChart = (props: ScanReportChartProps) => {
    const { resultData, startEnd, assessmentId, setChartImages } = props;
    const [chartInstance, setChartInstance] = useState<ChartJS<'bar', string[], string> | null>(null);
    const scanEventChartRef = useRef<HTMLCanvasElement>(null);
    const imgDownloadRef = useRef<HTMLAnchorElement>(null);
    const setChartImageMap = useSetRecoilState(assessmentReportChartImageState);

    const customCanvasBackgroundColor = {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart, args, options) => {
            const { ctx } = chart;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = options.color || '#99ffff';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        },
    };

    const generateImage = useCallback(() => {
        if (chartInstance && imgDownloadRef.current) {
            const url = chartInstance.toBase64Image();
            imgDownloadRef.current.href = url;
            setChartImages &&
                setChartImageMap((prevMap) => {
                    const imageMap = prevMap || new Map();
                    return new Map(imageMap.set(`${assessmentId}-${resultData.result.name}`, url));
                });
        }
    }, [assessmentId, chartInstance, resultData.result.name, setChartImageMap, setChartImages]);

    useEffect(() => {
        ChartJS.register(annotationPlugin);
        if (!scanEventChartRef?.current) return;
        const chartDict: { [key: string]: string } = { go: '30' };
        const ctx = scanEventChartRef.current.getContext('2d');
        if (ctx) {
            const newChartInstance = new ChartJS(ctx, {
                type: 'bar',
                plugins: [customCanvasBackgroundColor],
                data: {
                    labels: Object.keys(chartDict),
                    datasets: [
                        {
                            label: resultData.result.name,
                            data: Object.values(chartDict),
                        },
                    ],
                },
                options: {
                    responsive: true,
                    layout: {
                        padding: 20,
                    },
                    plugins: {
                        customCanvasBackgroundColor: {
                            color: '#ffffff',
                        },
                    },
                },
            });
            setChartInstance(newChartInstance);
        }
    }, []);

    useEffect(() => {
        if (chartInstance) {
            let highestValue = 0;
            const chartDict: { [key: string]: string } = {};
            resultData.data.forEach((sessionData) => {
                const decimalPrecision = getResultPrecision(resultData.result.units);
                const value = sessionData.value.toFixed(decimalPrecision);
                if (+value > highestValue) {
                    highestValue = +value;
                }
                chartDict[sessionData.participantUid] = value;
            });

            chartInstance.data.datasets = [
                {
                    label: `${resultData.result.name} (${resultData.result.units})`,
                    data: Object.values(chartDict),
                    backgroundColor: 'rgba(29, 132, 233, 0.6)',
                },
            ];
            chartInstance.data.labels = Object.keys(chartDict);
            chartInstance.options.animation = {
                onComplete: generateImage,
            };
            chartInstance.update();
        }
    }, [chartInstance, generateImage, resultData.data, resultData.result.name, resultData.result.units]);

    return (
        <ScanReportChartWrapper>
            <ScanReportDownloadButtonWrapper>
                <ScanReportDownloadButton onClick={generateImage}>
                    <a
                        ref={imgDownloadRef}
                        download={`Scan_Event${DateTime.fromJSDate(startEnd ? new Date(startEnd) : new Date()).toFormat(
                            'MM-dd-yyyy',
                        )}.png`}
                    >
                        Download Chart Image
                    </a>
                </ScanReportDownloadButton>
            </ScanReportDownloadButtonWrapper>

            <canvas ref={scanEventChartRef} id={`${assessmentId}-${resultData.result.name}`} />
        </ScanReportChartWrapper>
    );
};
