import { Grid, Select, styled, IconButton, TextField, Badge, BadgeProps } from '@mui/material';

export const PractitionerRowName = styled(Grid)(({ theme }) => ({
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: 'center',
    display: 'flex',

    [theme.breakpoints.up('lg')]: {
        height: 52,
    },
}));

export const PractitionerRowEmail = styled(Grid)(() => ({
    paddingTop: 10,

    alignItems: 'center',
    display: 'flex',
}));

export const PractitionerRowRole = styled(Grid)(() => ({
    paddingTop: 10,
    alignItems: 'center',
    display: 'flex',
}));

export const PractitionerRowButtonWrapper = styled(Grid)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
}));

export const PractitionerRowButton = styled(IconButton)(({ theme }) => ({
    display: 'flex',
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[500],
}));

export const PractitionerRowDeleteButton = styled(PractitionerRowButton)(({ theme }) => ({
    position: 'relative',
    top: -2,
}));

export const PractitionerRowEdit = styled(Grid)(({ theme }) => ({
    textAlign: 'right',
    paddingRight: theme.spacing(0),
    position: 'relative',
}));

export const PractitionerRoleSelect = styled(Select)(({ theme }) => ({
    '& .MuiSelect-select dd': {
        display: 'none',
    },

    '& .MuiSelect-icon': {
        fontSize: theme.typography.h4.fontSize,
    },
}));

export const PractitionerEditRoleSelect = styled(PractitionerRoleSelect)(({ theme }) => ({
    '& .MuiSelect-select': {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
}));

export const PractitionerEmailText = styled(TextField)(({ theme }) => ({
    width: '90%',
    '& .MuiInputBase-input': {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
}));

export const PractitionerRoleActionsGrid = styled(Grid)(({ theme }) => ({
    textAlign: 'right',

    '& button': {
        marginLeft: theme.spacing(1),
        padding: '0',
    },
}));

export const PractitionersListHeaderWrapper = styled(Grid)(({ theme }) => ({
    marginLeft: '0',
    marginTop: '0',
    width: 'auto',
    [theme.breakpoints.down('lg')]: {
        display: 'none',
    },
}));

export const PractitionerForm = styled('form')(({ theme }) => ({
    paddingTop: theme.spacing(2),
}));

export const LocationBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -6,
        top: -1,
    },
}));
