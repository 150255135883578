import { useContext } from 'react';
import { HEPContext } from '../HEPContext';

export const useHEPContext = () => {
    const hepContext = useContext(HEPContext);

    if (!hepContext) {
        throw new Error('HEPContext not initialized');
    }

    return hepContext;
};
