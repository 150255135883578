import { Grid, styled, Typography } from '@mui/material';

export const HEPBlockDetails = styled('div')(({ theme }) => ({
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(1),
    color: theme.palette.grey[500],
}));

export const HEPBlockWrapper = styled(Grid)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    padding: `${theme.spacing(2)} 0`,
    '&:last-of-type': {
        borderBottom: '0',
    },
}));

export const HEPBlockImgWrapper = styled(Grid)(({}) => ({}));

export const HEPBlockTitle = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightMedium,
}));

export const HEPBlockInfo = styled(Grid)(({}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}));

export const HEPBlockCaption = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    display: 'block',
}));
