import * as React from 'react';
import { SvgIcon } from '@mui/material';

export const DemoModuleIcon = (props) => (
    <SvgIcon viewBox="56.22 74.97 1087.51 1050.04" {...props}>
        <path
            d="m1142.4 649.69s-0.5625-1.125-0.75-1.6875c-0.75-1.5-1.6875-2.8125-2.8125-3.9375-0.375-0.5625-0.75-0.9375-1.125-1.5-1.5-1.3125-3.375-2.625-5.4375-3.5625l-251.06-107.62v-325.12c0-2.25-0.5625-4.5-1.3125-6.5625 0-0.5625-0.5625-1.125-0.75-1.6875-0.75-1.5-1.6875-2.8125-2.8125-3.9375-0.375-0.5625-0.75-0.9375-1.125-1.5-1.5-1.3125-3.375-2.625-5.4375-3.5625l-262.5-112.5c-4.6875-2.0625-9.9375-2.0625-14.625 0l-262.5 112.5c-2.0625 0.9375-3.9375 2.25-5.4375 3.5625-0.375 0.5625-0.75 0.9375-1.125 1.5-1.125 1.125-2.0625 2.4375-2.8125 3.9375 0 0.5625-0.5625 1.125-0.75 1.6875-0.75 2.0625-1.3125 4.3125-1.3125 6.5625v325.12l-251.06 107.62c-2.0625 0.9375-3.9375 2.25-5.4375 3.5625-0.375 0.5625-0.75 0.9375-1.125 1.5-1.125 1.125-2.0625 2.4375-2.8125 3.9375 0 0.5625-0.5625 1.125-0.75 1.6875-0.75 2.0625-1.3125 4.3125-1.3125 6.5625v337.5c0 7.5 4.5 14.25 11.438 17.25l262.5 112.5c2.25 0.9375 4.6875 1.5 7.3125 1.5s5.0625-0.5625 7.3125-1.5l255.19-109.31 255.19 109.31c2.25 0.9375 4.6875 1.5 7.3125 1.5s5.0625-0.5625 7.3125-1.5l262.5-112.5c6.9375-3 11.438-9.75 11.438-17.25v-337.5c0-2.25-0.5625-4.5-1.3125-6.5625zm-823.69 428.06-225-96.375v-296.62l225 96.375zm18.75-329.44-214.88-92.062 214.88-92.062 214.88 92.062zm243.75-120.56-103.12-44.062-121.88-52.312v-296.62l225 96.375zm-196.12-421.5 214.88-92.062 214.88 92.062-214.88 92.062zm458.62 871.5-225-96.375v-296.62l225 96.375zm18.75-329.44-214.88-92.062 214.88-92.062 214.88 92.062z"
            fill="currentcolor"
        />
    </SvgIcon>
);

export const DemoPathsIcon = (props) => (
    <SvgIcon viewBox="0 86.72 1200.01 1026.54" {...props}>
        <path
            d="m164.3 86.727c-90.625 0-164.3 74.051-164.3 164.88s73.672 164.99 164.3 164.99c84.27 0 154.02-64.141 163.38-146.17h426.86c86.07 0 154.77 68.965 154.77 155.43s-68.707 155.32-154.77 155.32h-309.07c-106.27 0-192.28 86.445-192.28 192.97 0 106.53 86.012 192.94 192.28 192.94l426.86 0.003907c9.2969 82.082 79.062 146.17 163.38 146.17 90.625 0 164.3-74.016 164.3-164.85s-73.672-164.99-164.3-164.99c-84.27 0-154.02 64.141-163.38 146.17h-426.86c-86.07 0-154.77-68.965-154.77-155.43s68.707-155.47 154.77-155.47h309.07c106.27 0 192.28-86.297 192.28-192.83s-86.012-192.94-192.28-192.94h-426.86c-9.2969-82.082-79.062-146.2-163.38-146.2zm0 37.539c70.285 0 126.94 56.711 126.94 127.34s-56.656 127.49-126.94 127.49-126.79-56.855-126.79-127.49 56.508-127.34 126.79-127.34zm871.41 696.67c70.285 0 126.9 56.855 126.9 127.49 0 70.633-56.617 127.34-126.9 127.34s-126.94-56.711-126.94-127.34c0-70.633 56.656-127.49 126.94-127.49z"
            fill="currentcolor"
        />
    </SvgIcon>
);
