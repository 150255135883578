import { styled, ListItemIcon, ListItemText } from '@mui/material';

export const CardIconWrapper = styled(ListItemIcon)(({ theme }) => ({
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.grey[500],

    '&.MuiListItemIcon-root': {
        minWidth: '27px',
    },

    '.MuiSvgIcon-root': {
        fill: 'none',
    },
}));

export const CardItemText = styled(ListItemText)(({ theme }) => ({
    color: theme.palette.grey[700],
}));
