import React from 'react';
import { PatientRecord, Session } from '@/common/types';
import { PatientSessionsListContainer } from '@/components/Health/PatientSessions/PatientSessionListContainer';
import { ChartSkeletonLoader } from '@/components/common/SkeletonLoaders/ChartSkeletonLoader';
import { DateRangeAtomState } from '@/recoil/atoms/dateRangeAtom';
import { TabPanel } from './TabPanel';

interface ActivityTabProps {
    currentTab: number;
    patientRecord: PatientRecord;
    dateRange: DateRangeAtomState;
    hasFetched: boolean;
    sessions: Session[] | undefined;
}

export const ActivityTab = (props: ActivityTabProps) => {
    const { currentTab, patientRecord, dateRange, hasFetched, sessions } = props;

    return (
        <TabPanel currentTab={currentTab} index={0} ariaLabel="activity">
            {hasFetched ? (
                <>
                    <PatientSessionsListContainer
                        sessions={sessions}
                        timeSpan={dateRange}
                        patientRecord={patientRecord}
                    />
                </>
            ) : (
                <ChartSkeletonLoader />
            )}
        </TabPanel>
    );
};
