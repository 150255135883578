import React, { useState } from 'react';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { GaitEventsAddPatientForm } from './GaitEventsAddPatientForm';
import { GaitEventsPatientsTableContainer } from './GaitEventsPatientsTableContainer';

export const GaitEventPatientsContainer = () => {
    const [selectedLocationId, setSelectedLocationId] = useState<number>();
    const [addedPatientCount, setAddedPatientCount] = useState<number>(0);

    return (
        <>
            <PageTitle title="Gait Events - Add Patient" />
            <GaitEventsAddPatientForm
                setSelectedLocationId={setSelectedLocationId}
                addedPatientCount={addedPatientCount}
                setAddedPatientCount={setAddedPatientCount}
            />
            {selectedLocationId && (
                <GaitEventsPatientsTableContainer
                    locationId={selectedLocationId}
                    addedPatientCount={addedPatientCount}
                />
            )}
        </>
    );
};
