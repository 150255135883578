import React from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
    Accordion as MuiAccordion,
    AccordionProps,
    AccordionSummary as MuiAccordionSummary,
    AccordionSummaryProps,
    styled,
} from '@mui/material';

export const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    // border: `1px solid ${theme.palette.divider}`,
    paddingLeft: `${theme.spacing(2)}`,
    width: '100%',
    '&:not(:last-child)': {
        // borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    paddingLeft: 0,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        margin: 0,
        marginLeft: theme.spacing(1),
    },
}));

// export const AccordionSum = styled(AccordionSummary)(({ theme }) => ({
//     '& .MuiAccordionSummary-content': {
//         marginLeft: theme.spacing(1),
//         width: '100%',
//         backgroundColor: 'tomato',
//     },
// }));

// export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//     padding: theme.spacing(2),
//     paddingLeft: theme.spacing(2.75),
//     // borderTop: '1px solid rgba(0, 0, 0, .125)',
// }));
