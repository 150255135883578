import React, { ReactNode } from 'react';
import { Grid, Radio } from '@mui/material';
import { CalendarIcon } from '@/components/common/Icons/CalendarIcon';
import {
    CardDetails,
    CardTitle,
    CardTitleContainer,
    CardRightContainer,
    CardSupTitle,
    RadioCardPaper,
    CardLabel,
} from './styles';

interface RadioCardProps {
    supTitle?: string;
    title: string;
    cardDetails?: ReactNode;
}

export interface RadioCardControlProps extends RadioCardProps {
    value: string | number;
    selected?: boolean;
}

export const RadioCard = (props: RadioCardProps) => {
    const { supTitle, title, cardDetails } = props;

    return (
        <Grid container>
            <Grid container item direction="column" xs={8} rowSpacing={1}>
                <Grid item>
                    {supTitle && (
                        <CardSupTitle variant="supheader">
                            <CalendarIcon />
                            {supTitle}
                        </CardSupTitle>
                    )}
                </Grid>
                <CardTitleContainer item>
                    <CardTitle noWrap>{title}</CardTitle>
                </CardTitleContainer>
            </Grid>
            <CardRightContainer container item direction="column" xs={4} rowSpacing={1}>
                <Grid item />
                <CardDetails item xs={4}>
                    {cardDetails}
                </CardDetails>
            </CardRightContainer>
        </Grid>
    );
};

export const RadioCardControl = (props: RadioCardControlProps) => {
    const { title, cardDetails, value, supTitle, selected } = props;

    return (
        <RadioCardPaper variant="radio" selected={selected}>
            <CardLabel
                value={value}
                control={<Radio />}
                label={<RadioCard title={title} cardDetails={cardDetails} supTitle={supTitle} />}
            />
        </RadioCardPaper>
    );
};
