import React, { useCallback, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Tab } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Products } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { canNotSelectMultiple, getStringEnumKeyByValue, onlyAvailableProductToAddUsers } from '@/common/utils';
import { Button } from '@/components/common/button/button';
import { AddNewPatientModal, AddPatientTabs, AddPatientModalContentWrapper } from '../styles';
import { AddPatientForm } from './AddPatientForm';
import { AddPatientProductSelection } from './AddPatientProductSelection';
import { BulkAddPatient } from './BulkAddPatient';

enum ModalTabs {
    SINGLE,
    BULK,
}

interface Props {
    product?: Products;
}

export const AddPatientContainer = (props: Props) => {
    const { product } = props;
    const [searchParams] = useSearchParams();
    const [dialogOpen, setDialogOpen] = useState(searchParams.get('add') === 'true' ? true : false);
    const [activeTab, setActiveTab] = useState(ModalTabs.SINGLE);
    const { user } = useExerUser();
    const [isProductsConfirmed, setIsProductsConfirmed] = useState(!!product);
    const [confirmedProduct, setConfirmedProduct] = useState(product);
    const [productsSelected, setProductsSelected] = useState<string[]>(
        product ? [getStringEnumKeyByValue(Products, product)] : [],
    );
    const [productsError, setProductsError] = useState<string | null>();

    const handleCreateButtonClick = (_: React.MouseEvent) => {
        setDialogOpen(true);
    };

    const handleTabChange = (_, newValue: ModalTabs) => {
        setActiveTab(newValue);
    };

    const handleProductSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProductsError(null);
        const products = productsSelected?.includes(e.target.value)
            ? productsSelected?.filter((existing) => existing !== e.target.value)
            : [...(productsSelected ?? []), e.target.value];
        setProductsSelected(products);
    };

    const confirmProductChoices = () => {
        let refined;
        if (productsSelected.length === 2) {
            refined = null;
        } else if (productsSelected.length === 1) {
            refined = productsSelected[0];
        } else {
            setProductsError('You must select at least one product.');
            return;
        }
        setConfirmedProduct(refined);
        setIsProductsConfirmed(true);
    };

    const handleCancel = () => {
        setProductsError(undefined);
        setDialogOpen(false);
        setProductsSelected([]);
        setConfirmed();
    };

    const setConfirmed = useCallback(() => {
        const userCanNotSelectMultiple = canNotSelectMultiple(user);
        if (userCanNotSelectMultiple) {
            setIsProductsConfirmed(true);
            setConfirmedProduct(onlyAvailableProductToAddUsers(user.products));
        } else if (!product) {
            setConfirmedProduct(undefined);
            setIsProductsConfirmed(false);
        }
    }, [user, product]);

    useEffect(() => {
        setConfirmed();
    }, [user, setConfirmed]);

    return (
        <>
            <Button onClick={handleCreateButtonClick} variant="primary" startIcon={<AddIcon />} size="small">
                Add New Patient
            </Button>
            <AddNewPatientModal
                open={dialogOpen}
                onClose={handleCancel}
                title={`Add ${product || 'New'} Patient`}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <AddPatientModalContentWrapper data-cy="add-patient-modal-content">
                    {!isProductsConfirmed ? (
                        <AddPatientProductSelection
                            productsSelected={productsSelected}
                            handleProductSelect={handleProductSelect}
                            productsError={productsError}
                            confirmProductChoices={confirmProductChoices}
                            handleCancel={handleCancel}
                        />
                    ) : (
                        <>
                            <AddPatientTabs value={activeTab} onChange={handleTabChange}>
                                <Tab label={`Add ${product || ''} Patient`} value={ModalTabs.SINGLE} />
                                {confirmedProduct && <Tab label="Bulk Add" value={ModalTabs.BULK} />}
                            </AddPatientTabs>

                            {activeTab == ModalTabs.BULK && confirmedProduct && (
                                <BulkAddPatient product={confirmedProduct} />
                            )}
                            {activeTab == ModalTabs.SINGLE && (
                                <AddPatientForm cancel={handleCancel} product={confirmedProduct} />
                            )}
                        </>
                    )}
                </AddPatientModalContentWrapper>
            </AddNewPatientModal>
        </>
    );
};
