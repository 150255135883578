import React, { Dispatch, SetStateAction, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { ClickAwayListener, MenuList, MenuItem, Menu, ListItemIcon, ListItemText, Button } from '@mui/material';
import { SetterOrUpdater } from 'recoil';
import { ComputerGraphIcon } from '@/components/common/Icons/ComputerGraphIcon';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { PrintIcon } from '@/components/common/Icons/PrintIcon';
import { SwapIcon } from '@/components/common/Icons/SwapIcon';
import { patientHEPDrawerHEPDict } from '@/recoil/atoms/patientHEPDrawer';
import { usePatientContext } from '../context/hooks/PatientContext';

interface PatientHEPMenuProps {
    handleEditHEP: () => void;
    setDrawerActive: SetterOrUpdater<patientHEPDrawerHEPDict>;
    setUnassignDialogOpen: Dispatch<SetStateAction<boolean>>;
    handlePrint: () => void;
}

export const PatientHEPMenu = (props: PatientHEPMenuProps) => {
    const { handleEditHEP, setDrawerActive, setUnassignDialogOpen, handlePrint } = props;
    const { setHEPBuilderDialogOpen } = usePatientContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuIsOpen = Boolean(anchorEl);

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const openMenu = (target: EventTarget & HTMLElement) => {
        setAnchorEl(target);
    };

    const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        openMenu(e.currentTarget);
    };

    const handleCloseMenu = (e: React.MouseEvent | TouchEvent | MouseEvent) => {
        e.preventDefault();
        closeMenu();
    };

    const openDrawer = () => {
        closeMenu();
        setDrawerActive({ drawerIsActive: true });
    };

    const handleReplaceClick = () => {
        closeMenu();
        setHEPBuilderDialogOpen(true);
    };

    const handleUnassignClick = () => {
        closeMenu();
        setUnassignDialogOpen(true);
    };

    return (
        <>
            <Button variant="tertiary" size="small" startIcon={<ComputerGraphIcon />} onClick={handleOpenMenu}>
                Manage Program
            </Button>
            <Menu
                onClick={(e) => e.stopPropagation()}
                id="edit-patient-actions-menu"
                aria-labelledby="edit-patient-actions-button"
                anchorEl={anchorEl}
                open={menuIsOpen}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <ClickAwayListener onClickAway={handleCloseMenu}>
                    <MenuList>
                        <MenuItem onClick={openDrawer}>
                            <ListItemIcon>
                                <ComputerGraphIcon />
                            </ListItemIcon>
                            <ListItemText>View Program</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handlePrint}>
                            <ListItemIcon>
                                <PrintIcon />
                            </ListItemIcon>
                            <ListItemText>Print Program</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleEditHEP}>
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText>Edit Program</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleReplaceClick}>
                            <ListItemIcon>
                                <SwapIcon />
                            </ListItemIcon>
                            <ListItemText>Replace Program</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleUnassignClick}>
                            <ListItemIcon>
                                <Delete />
                            </ListItemIcon>
                            <ListItemText>Remove Program</ListItemText>
                        </MenuItem>
                    </MenuList>
                </ClickAwayListener>
            </Menu>
        </>
    );
};
