import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { HEPTemplate } from '@/common/types';
import { Result } from './HttpService';

export const transformHEPTemplateResponse = (result: AxiosResponse<HEPTemplate>): Result<HEPTemplate> => {
    const template = result.data;
    template.blocks = template.blocks.map((block) => {
        // patch to compensate for hep templates not having a block entity, but a block prototype
        block.id = uuidv4();
        return block;
    });
    return template;
};
