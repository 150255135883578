import { styled, Grid, Typography, Paper, FormControlLabel } from '@mui/material';

export const RadioCardPaper = styled(Paper)<{ selected }>(({ theme, selected }) => ({
    transition: '.4s ease box-shadow',
    boxShadow: selected ? `0 0 0 3px ${theme.palette.primary[300]}` : `0px 2px 5px rgba(16, 5, 51, 0.04)`,
    minHeight: theme.spacing(7.75),

    '&:hover': {
        boxShadow: `0 0 0 3px ${theme.palette.primary[300]}`,
    },
}));

export const CardTitleContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(1.5),
    marginTop: theme.spacing(0.5),
}));

export const CardTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[900],
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    maxWidth: '235px',
}));

export const CardSupTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1.5),
    '.MuiSvgIcon-root': {
        marginRight: theme.spacing(0.9375),
    },
}));

export const CardLabel = styled(FormControlLabel)(({ theme }) => ({
    minHeight: theme.spacing(7.75),
}));

export const CardDetails = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '4px',
    paddingBottom: theme.spacing(1.5),
}));

export const CardRightContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
}));
