import { styled, Paper, Menu, Box, Typography, FormControlLabel as MuiFormControlLabel, MenuItem } from '@mui/material';

export const ButtonWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const MenuWrapper = styled(Menu)(({ theme }) => ({
    '.MuiList-root': {
        padding: '0',
    },
}));

export const MenuContent = styled(Box)(({ theme }) => ({
    minWidth: theme.spacing(37.5),
    '.MuiListItemIcon-root': {
        paddingTop: '9px',
        paddingBottom: '9px',
        minWidth: '28px',
        fontSize: theme.typography.h5.fontSize,
        marginLeft: '-1px',
    },
    '.MuiFormControl-root': {
        width: '100%',
    },
}));

export const MenuItemText = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
}));

export const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
    width: '100%',
}));

export const CalendarActions = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
}));

export const MenuItemCustomDate = styled(MenuItem)<{ $isActive }>(({ theme, $isActive }) => ({
    'svg, p': {
        color: $isActive ? theme.palette.primary[500] : theme.palette.grey[600],
    },
}));

export const DateRangeWrapper = styled('div')<{ $inModal?: boolean }>(({ theme, $inModal }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(3.75),
    paddingRight: $inModal ? 0 : theme.spacing(4),
    paddingLeft: $inModal ? 0 : theme.spacing(4),
}));

export const CalendarWrapper = styled(Paper)(({ theme }) => ({
    '.rdrWeekDays': {
        display: `flex`,
    },
    '.rdrWeekDay': {
        fontWeight: `400`,
        lineHeight: `2.667em`,
        color: `${theme.palette.grey[600]}`,
        flexBasis: `calc(100% / 7)`,
        boxSizing: `inherit`,
        textAlign: `center`,
        fontSize: '16px',
    },
    '.rdrDays': {
        display: `flex`,
        flexWrap: `wrap`,
    },
    '.rdrDay': {
        boxSizing: `inherit`,
        width: `calc(100% / 7)`,
        position: `relative`,
        font: `inherit`,
        cursor: `pointer`,
        background: `transparent`,
        userSelect: `none`,
        border: `0`,
        padding: `0`,
        lineHeight: `3.000em`,
        height: `48px`,
        textAlign: `center`,
        color: `#1d2429`,
    },
    '.rdrDayDisabled': {
        cursor: `not-allowed`,
        '.rdrDayNumber': {
            color: `${theme.palette.grey[400]}`,
        },
    },
    '.rdrDayNumber': {
        outline: `0`,
        fontWeight: `300`,
        position: `absolute`,
        left: `0`,
        right: `0`,
        top: `5px`,
        bottom: `5px`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        color: `${theme.palette.grey[900]}`,
        fontSize: '16px',
    },
    '.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge': {
        background: `currentColor`,
        position: `absolute`,
        top: `5px`,
        left: `0`,
        right: `0`,
        bottom: `5px`,
    },
    '.rdrStartEdge': {
        borderTopLeftRadius: `4px`,
        borderBottomLeftRadius: `4px`,
        left: `2px`,
    },
    '.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span': {
        color: `${theme.palette.grey[900]}`,
    },
    '.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span':
        {
            color: `${theme.palette.common.white}`,
        },
    '.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after': {
        content: `''`,
        border: `1px solid currentColor`,
        borderRadius: `4px`,
        position: `absolute`,
        top: `-2px`,
        bottom: `-2px`,
        left: `0px`,
        right: `0px`,
        background: `transparent`,
    },

    '.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview': {
        background: `rgba(255, 255, 255, 0.09)`,
        position: `absolute`,
        top: `3px`,
        left: `0px`,
        right: `0px`,
        bottom: `3px`,
        pointerEvents: `none`,
        border: `0px solid currentColor`,
        zIndex: `1`,
    },
    '.rdrDayEndOfMonth, .rdrDayEndOfWeek': {
        '.rdrInRange,  .rdrStartEdge': {
            right: `2px`,
        },
        '.rdrDayInPreview, .rdrDayStartPreview': {
            borderRightWidth: `1px`,
            right: `0px`,
        },
    },
    '.rdrDayStartOfMonth, .rdrDayStartOfWeek': {
        '.rdrDayInPreview, .rdrDayEndPreview': {
            borderLeftWidth: `1px`,
            left: `0px`,
        },
    },
    '.rdrDayStartPreview': {
        borderTopWidth: `1px`,
        borderLeftWidth: `1px`,
        borderBottomWidth: `1px`,
        borderTopLeftRadius: `4px`,
        borderBottomLeftRadius: `4px`,
        left: `0px`,
    },
    '.rdrDayInPreview': {
        borderTopWidth: `1px`,
        borderBottomWidth: `1px`,
    },
    '.rdrDayEndPreview': {
        borderTopWidth: `1px`,
        borderRightWidth: `1px`,
        borderBottomWidth: `1px`,
        borderTopRightRadius: `4px`,
        borderBottomRightRadius: `4px`,
        right: `0px`,
    },
    '.rdrDayPassive': {
        pointerEvents: `none`,
    },
    '.rdrDayPassive .rdrDayNumber span': {
        color: `${theme.palette.grey[300]}`,
    },
    '.rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview':
        {
            display: `none`,
        },
    '.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge':
        {
            left: `2px`,
        },
    '.rdrEndEdge': {
        borderTopRightRadius: `4px`,
        borderBottomRightRadius: `4px`,
        right: `2px`,
    },
    '.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge':
        {
            right: `2px`,
        },
    'span.rdrInRange': {
        color: `${theme.palette.primary[100]}!important`,
    },
    '.rdrDayToday .rdrDayNumber span:after': {
        content: `''`,
        position: `absolute`,
        bottom: `4px`,
        left: `50%`,
        transform: `translate(-50%,0)`,
        width: `4px`,
        height: `4px`,
        borderRadius: `50%`,
        background: `${theme.palette.primary[500]}`,
    },
    '.rdrMonthName': {
        display: 'flex',
        justifyContent: 'center',
    },
    '.rdrMonthAndYearWrapper': {
        display: 'flex',
        justifyContent: 'space-between',
        zIndex: '999',
        position: 'relative',
        height: '0',
        button: {
            background: 'transparent',
            outline: 'none',
            border: 'none',
        },
        '.rdrMonthAndYearPickers': {
            display: 'none',
        },
        '.rdrPprevButton i': {
            display: 'block',
            width: '12px',
            height: '12px',
            borderBottom: '2px solid',
            borderLeft: '2px solid',
            transform: 'rotate(45deg)',
            borderRadius: '0px',
            marginTop: '20px',
            marginLeft: '20px',
            cursor: 'pointer',
        },
        '.rdrNextButton i': {
            display: 'block',
            width: '12px',
            height: '12px',
            borderTop: '2px solid',
            borderRight: '2px solid',
            transform: 'rotate(45deg)',
            borderRadius: '0px',
            marginTop: '20px',
            marginRight: '20px',
            cursor: 'pointer',
        },
    },
    '.rdrMonths': {
        display: 'flex',
        justifyContent: 'space-between',
        '.rdrMonth': {
            width: '45%',
            minWidth: '300px',
            padding: '20px',
        },
    },
    '.rdrDefinedRangesWrapper, .rdrDateDisplayWrapper': {
        display: 'none',
    },
}));

export const SingleCalendarDateWrapper = styled('div')(({ theme }) => ({
    '& .rdrSelected': {
        borderRadius: 4,
    },

    '&& .rdrMonthAndYearPickers': {
        display: 'block',
        marginTop: theme.spacing(2),
        fontWeight: 'bold',
    },
    '&& .rdrMonthAndYearWrapper': {
        height: 'inherit',
    },
    '&& .rdrMonth': {
        width: '100%',
    },
}));
