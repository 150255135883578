import React, { useEffect, useState } from 'react';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { OrganizationAdmin, ScanModule } from '@/common/types';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { resultIsError } from '@/services/HttpService';
import { AdminOrganizationScanModules } from './AdminOrganizationScanModules';

interface AdminScanOrganizationModulesProps {
    organizationId: number;
}

export const AdminScanOrganizationModulesContainer = (props: AdminScanOrganizationModulesProps) => {
    const { organizationId } = props;
    const [organization, setOrganization] = useState<OrganizationAdmin>();
    const { httpService } = useHttpContext();
    const { errorToast, successToast } = toasts;

    const getSetOrganization = async () => {
        const res = await httpService.getOrganizationAdmin(organizationId);
        if (resultIsError(res)) {
            errorToast('Could not get organization details.');
            return;
        }
        setOrganization(res);
    };

    useEffect(() => {
        (async () => {
            await getSetOrganization();
        })();
    }, []);

    const submitScanModules = async (scanModules: ScanModule[]) => {
        if (!organization) {
            errorToast('No organization set.');
            return;
        }

        const res = await httpService.updateOrganizationAdmin(organization.id, { scanModules: scanModules });
        if (resultIsError(res)) {
            errorToast(res.message);
            return;
        }
        setOrganization(res);
        successToast(`${organization.name} Modules Updated`);
    };

    return organization ? (
        <>
            <PageTitle title={organization.name} />
            <AdminOrganizationScanModules organization={organization} submitScanModules={submitScanModules} />
        </>
    ) : null;
};
