import React, { forwardRef } from 'react';
import { Button as MuiButton } from '@mui/material';

export interface ButtonProps {
    readonly color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    readonly variant?: 'primary' | 'secondary' | 'tertiary' | 'filter' | 'text' | 'textPrimary' | 'card';
    readonly onClick: React.MouseEventHandler<HTMLButtonElement>;
    readonly type?: 'button' | 'submit' | 'reset';
    readonly size?: 'xs' | 'small' | 'medium' | 'large';
    readonly children: JSX.Element | JSX.Element[] | string;
    readonly dataCy?: string;
    readonly startIcon?: JSX.Element;
    readonly endIcon?: JSX.Element;
    readonly ariaLabel?: string;
    readonly disabled?: boolean;
    readonly style?: any;
}

export const Button = forwardRef((props: ButtonProps, ref) => {
    const {
        children,
        color,
        type = 'button',
        variant = 'primary',
        onClick,
        dataCy,
        startIcon,
        endIcon,
        size,
        ariaLabel,
        ...rest
    } = props;
    return (
        <MuiButton
            size={size}
            color={color}
            type={type}
            variant={variant}
            onClick={onClick}
            data-cy={dataCy}
            startIcon={startIcon}
            endIcon={endIcon}
            aria-label={ariaLabel}
            {...rest}
        >
            {children}
        </MuiButton>
    );
});

Button.displayName = 'Button';
