import { Box, styled, Typography } from '@mui/material';

export const HEPDetailsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
}));

export const HEPDetailsContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    maxWidth: 800,
}));

export const HEPDetailsTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

export const HEPDetailsHeader = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

export const HEPDetailsNotes = styled('p')(({ theme }) => ({
    whiteSpace: 'pre-wrap',

    marginBottom: theme.spacing(4),
}));

export const HEPPrintLogoWrapper = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
}));
