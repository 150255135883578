import React, { useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { Button } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { ReconcileResponse } from '@/common/types';
import { DragAndDropWrapper } from '@/components/Patients/AddPatient/styles';
import {
    UploadProgressWrapper,
    UploadProgress,
    CsvTemplateContainer,
    BulkAddError,
    BulkAddSuccess,
} from '@/components/Patients/styles';
import { resultIsError } from '@/services/HttpService';

export const GaitReconcile = () => {
    const [processing, setProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [uploadResult, setUploadResult] = useState<ReconcileResponse | null>();
    const [template, setTemplate] = useState<any>();
    const { httpService } = useHttpContext();

    useEffect(() => {
        (async () => {
            const res = await httpService.getGaitReconcileTemplateCsv();
            setTemplate(res);
        })();
    }, []);

    const onFileInput = async (e) => {
        setProcessing(true);
        const file = e[0];
        setErrorMessage('');
        setUploadResult(null);
        const res = await httpService.uploadPatientReconciliationCsv(file);
        if (resultIsError(res)) {
            setErrorMessage(res.message);
        } else {
            setUploadResult(res);
        }
        setProcessing(false);
    };

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: { 'text/csv': [] },
        onDrop: onFileInput,
    });

    return (
        <>
            {processing ? (
                <UploadProgressWrapper>
                    <UploadProgress />
                </UploadProgressWrapper>
            ) : (
                <>
                    <div {...getRootProps()} style={{ outline: 'none' }}>
                        <DragAndDropWrapper
                            $isFocused={isFocused}
                            $isDragAccept={isDragAccept}
                            $isDragReject={isDragReject}
                        >
                            <input {...getInputProps()} />
                            <Button variant="tertiary" onClick={(e) => e.preventDefault}>
                                Upload from a CSV file
                            </Button>
                        </DragAndDropWrapper>
                    </div>
                </>
            )}
            <CsvTemplateContainer>
                Please follow the format of our{' '}
                <Link
                    target="_blank"
                    download={'exer_gait_reconcile_template.csv'}
                    href={URL.createObjectURL(new Blob([template], { type: 'text/csv' }))}
                >
                    CSV Template
                </Link>
            </CsvTemplateContainer>
            {errorMessage && <BulkAddError> {errorMessage} </BulkAddError>}
            {uploadResult && (
                <BulkAddSuccess>
                    {`Upload was successful. ${uploadResult.reconciledCount} patient record${
                        !uploadResult.reconciledCount || uploadResult.reconciledCount > 1 ? 's were' : ' was'
                    } updated.`}
                </BulkAddSuccess>
            )}
        </>
    );
};
