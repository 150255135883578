import React from 'react';
import { DashboardInnerContent } from './styles';

interface Props {
    children: JSX.Element | JSX.Element[];
}

export const DashboardUnAuthLayout = (props: Props) => {
    const { children } = props;

    return <DashboardInnerContent className="dashboard-inner-content">{children}</DashboardInnerContent>;
};
