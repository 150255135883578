import { styled } from '@mui/material';

export const SetInfoTableWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
    },

    '& small ': {
        color: theme.palette.primary[500],
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: '4px',
    },

    '& h5 ': {
        fontSize: theme.typography.body2.fontSize,
        marginBottom: 0,
    },
}));

export const SetInfoTableRow = styled('div')(({ theme }) => ({
    display: 'flex',

    '& div:first-child': {
        marginRight: theme.spacing(1),
    },
}));

export const SetInfoTableItemLight = styled('span')(({ theme }) => ({
    color: theme.palette.grey[600],
}));

export const SetInfoTableItemDark = styled('span')(({ theme }) => ({
    color: theme.palette.grey[900],
}));
