import React, { useState } from 'react';
import { useAsyncDebounce } from '@/common/react-table-utils';
import { SearchFilter, ClearFilter } from '@/components/Patients/styles';
import { NameTextFieldContainer, NameTextField } from '@/components/common/Table/PatientsTable/styles';
import { usePaginationContext } from '@/components/context/hooks/PaginationContext';

export const TableSearchFilter = ({ globalFilter, setGlobalFilter, searchLabel }) => {
    const [value, setValue] = useState(globalFilter);
    const { page, setPage } = usePaginationContext();
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (page !== 1) {
            setPage(1);
        }
        setValue(e.target.value);
        onChange(e.target.value);
    };

    const handleClear = (_: React.MouseEvent) => {
        if (page !== 1) {
            setPage(1);
        }
        setValue(null);
        setGlobalFilter(undefined);
    };

    return (
        <NameTextFieldContainer>
            <NameTextField
                value={value || ''}
                onChange={handleChange}
                id="name-filter"
                label={searchLabel}
                variant="outlined"
                size="small"
            />
            {!value ? (
                <SearchFilter color={!value ? 'disabled' : 'inherit'} onClick={handleClear} />
            ) : (
                <ClearFilter color={!value ? 'disabled' : 'inherit'} onClick={handleClear} />
            )}
        </NameTextFieldContainer>
    );
};
