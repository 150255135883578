import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, TextField } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { toasts } from '@/common/toasts';
import { AuthForm } from '@/components/Auth/styles';

const schema = yup.object().shape({
    username: yup.string().email('Email must be valid.').required('Email is required.'),
});

type Data = {
    username: string;
};

export const ForgotPasswordForm = () => {
    const { successToast } = toasts;
    const { register, handleSubmit, formState, setError } = useForm({ resolver: yupResolver(schema) });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const forgotPassword = async (data: Data) => {
        setLoading(true);
        try {
            await Auth.forgotPassword(data.username);
            navigate('/user/reset-password', { state: { username: data.username } });
            setLoading(false);
            successToast('A password verification code has been sent to your email.');
        } catch (e) {
            setLoading(false);
            setError('email', {
                type: 'manual',
                message: `${e.message}`,
            });
            return;
        }
    };

    return (
        <>
            <AuthForm data-cy="login-form" onSubmit={handleSubmit(forgotPassword)} noValidate>
                <TextField
                    variant="outlined"
                    {...register('username')}
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Email"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    error={!!formState.errors.username}
                    helperText={formState.errors.username && (formState.errors.username.message as string)}
                />
                <Box my={3}>
                    <LoadingButton loading={loading} variant="primary" fullWidth type="submit">
                        Continue
                    </LoadingButton>
                </Box>
            </AuthForm>
        </>
    );
};
