import { useContext } from 'react';
import { ExerciseListContext } from '../ExerciseListContext';

export const useExerciseListContext = () => {
    const exerciseListContext = useContext(ExerciseListContext);

    if (!exerciseListContext) {
        throw new Error('ExerciseListContext not initialized');
    }

    return exerciseListContext;
};
