import { Box, CircularProgress, FormHelperText as MUIFormHelperText, styled } from '@mui/material';
import { SortedIcon } from '../Icons/SortedIcon';

export const FormFieldContainer = styled(Box)(({ theme }) => ({
    textAlign: 'left',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(1),

    '& .MuiFormControl-root': {
        width: '100%',
        marginTop: 0,
        marginBottom: 0,
    },

    '& legend.MuiFormLabel-root': {
        textTransform: 'uppercase',
        color: theme.palette.primary[500],
    },
}));

export const SaveSaveAnotherCancelButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),

    '& div:first-child': {
        display: 'flex',
        justifyContent: 'flex-start',
    },

    '& div:last-child': {
        display: 'flex',
        justifyContent: 'flex-end',

        '& button': {
            marginLeft: '10px',
        },
    },
}));

export const UpdateButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
}));

export const FormButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: theme.spacing(2),

    '& div:first-child': {
        display: 'flex',

        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start',
        },
    },

    '& div:last-child': {
        display: 'flex',
        justifyContent: 'flex-end',

        [theme.breakpoints.up('sm')]: {
            '& button': {
                marginLeft: '10px',
            },
        },
    },

    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        rowGap: 0,
    },
}));

export const ServerError = styled('p')(({ theme }) => ({
    fontSize: theme.typography.caption.fontSize,
    textAlign: 'left',
    '&&': {
        color: theme.palette.error.main,
    },
}));

export const SuccessMessage = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',

    '& div': {
        visibility: 'hidden',
        marginBottom: theme.spacing(2),
        width: '30%',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        fontSize: 16,
        textAlign: 'center',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary[400],
    },

    '&.success div': {
        visibility: 'visible',
    },
}));

export const FormHelperText = styled(MUIFormHelperText)(({ theme }) => ({
    color: theme.palette.error.main,
}));

export const SubmitProgressWrapper = styled('div')({
    textAlign: 'center',
});

export const SubmitProgress = styled(CircularProgress)({});

export const DropdownInputIcon = styled(SortedIcon)(({ theme }) => ({
    fontSize: 14,
}));

export const SelectStyledButton = styled('div')<{ $isErrored: boolean }>(({ theme, $isErrored }) => ({
    border: `1px solid ${$isErrored ? theme.palette.error.main : theme.palette.grey[400]}`,
    background: $isErrored ? theme.palette.error.light : 'none',
    borderRadius: 4,
    padding: theme.spacing(1),
    color: theme.palette.grey[600],
    display: 'flex',
    position: 'relative',
    cursor: 'pointer',

    '.MuiSvgIcon-root:first-child': {
        marginRight: theme.spacing(1),
        position: 'relative',
        top: 1,
    },

    '[data-testid="ArrowDropDownIcon"]': {
        fontSize: 20,
        position: 'absolute',
        right: theme.spacing(1),
    },
}));

export const FormHr = styled('hr')(({ theme }) => ({
    margin: theme.spacing(3),
}));
