import * as Sentry from '@sentry/react';
import { Comparator } from '@/common/const';
import { Maybe, ScanSessionResult } from '@/common/types';
import { wordToTitleCase } from '@/common/utils';
import { RISK_HIGH_COLOR, RISK_MEDIUM_COLOR, RISK_LOW_COLOR } from './const';
import { CriteriaCategory } from './types/CriteriaCategory.enum';
import { FactorCategory } from './types/FactorCategory.enum';
import {
    AssessmentOutcome,
    FactorCriteria,
    FactorCriteriaValue,
    OutcomeBoxData,
    OutcomeFactor,
    ParticipantDemographics,
} from './types/types';

export const outcomeJSONToMap = (json: AssessmentOutcome[]): Map<string, AssessmentOutcome> => {
    const map = new Map<string, AssessmentOutcome>();
    json.forEach((assessmentOutcome: AssessmentOutcome) => {
        map.set(assessmentOutcome.assessmentId, assessmentOutcome);
    });
    return map;
};

const compareUsingComparator = (comparator: Comparator, v1: FactorCriteriaValue, v2: FactorCriteriaValue) => {
    const comparatorDict = {
        [Comparator.gt]: (a: FactorCriteriaValue, b: FactorCriteriaValue) => a > b,
        [Comparator.lt]: (a: FactorCriteriaValue, b: FactorCriteriaValue) => a < b,
        [Comparator.gte]: (a: FactorCriteriaValue, b: FactorCriteriaValue) => a >= b,
        [Comparator.lte]: (a: FactorCriteriaValue, b: FactorCriteriaValue) => a <= b,
        [Comparator.e]: (a: FactorCriteriaValue, b: FactorCriteriaValue) => a == b,
    };

    return comparatorDict[comparator]?.(v1, v2) ?? false;
};

export const getResultCriteria = (criteria: FactorCriteria[]): Maybe<FactorCriteria[]> => {
    const resultCriteria = criteria.filter((criterion) => {
        return criterion.type !== CriteriaCategory.AGE && criterion.type !== CriteriaCategory.SEX;
    });
    if (resultCriteria.length === 0) {
        Sentry.captureException(`Assessment Outcomes: No result criteria specified.`);
        return undefined;
    }
    return resultCriteria;
};

const getDemographicCriteria = (
    criteria: FactorCriteria[],
    criteriaCategory: CriteriaCategory,
): Maybe<FactorCriteria[]> => {
    const filtered = criteria.filter((criterion) => {
        return criterion.type === criteriaCategory;
    });
    if (filtered.length === 0) {
        return undefined;
    }
    if (filtered.length !== 1) {
        // Unexpected.  No scenario where > 1 of same demographic criteria added.
        Sentry.captureException(`Assessment Outcomes: More than 1 ${criteriaCategory} criteria specified.`);
    }
    return filtered;
};

export const valueMeetsCriteria = (criteria: FactorCriteria[], value: FactorCriteriaValue) => {
    const metCriteria = criteria.map((criterion) => {
        const metConditions = criterion.conditions.map((condition) => {
            return compareUsingComparator(condition.comparator, value, condition.value);
        });
        const metAllConditions = metConditions.every((metCondition) => {
            return metCondition === true;
        });
        return metAllConditions;
    });
    // NB: This will need to be updated if we want to introduce other logic than "Meets all conditions/criteria"
    return metCriteria.every((metCriterium) => {
        return metCriterium === true;
    });
};

export const getQualifyingFactor = (
    factors: OutcomeFactor[],
    sessionResultsValue: number,
    demographics: Maybe<ParticipantDemographics> = undefined,
): Maybe<OutcomeFactor> => {
    let metCount = 0;
    let qualifiedFactor: Maybe<OutcomeFactor> = undefined;

    factors.forEach((factor) => {
        let metCriteria: Maybe<boolean> = undefined;
        const resultCriteria = getResultCriteria(factor.criteria);
        if (!resultCriteria) {
            return undefined;
        }
        metCriteria = valueMeetsCriteria(resultCriteria, sessionResultsValue);
        const ageCriteria = getDemographicCriteria(factor.criteria, CriteriaCategory.AGE);
        if (ageCriteria) {
            if (!demographics?.age?.value) {
                metCriteria = false;
            } else {
                metCriteria = metCriteria && valueMeetsCriteria(ageCriteria, demographics.age.value);
            }
        }
        const sexCriteria = getDemographicCriteria(factor.criteria, CriteriaCategory.SEX);
        if (sexCriteria) {
            if (!demographics?.sex?.value) {
                metCriteria = false;
            } else {
                metCriteria = metCriteria && valueMeetsCriteria(sexCriteria, demographics.sex.value);
            }
        }
        if (metCriteria) {
            metCount++;
            qualifiedFactor = factor;
        }
    });
    if (metCount > 1) {
        // This shouldn't happen, factors are misconfigured.
        Sentry.captureException(`Assessment Outcomes: More than 1 factor qualified.`);
    }
    return qualifiedFactor;
};

export const getFactorColor = (factorCategory: FactorCategory): string => {
    switch (factorCategory) {
        case FactorCategory.HIGH:
            return RISK_HIGH_COLOR;
        case FactorCategory.MEDIUM:
            return RISK_MEDIUM_COLOR;
        case FactorCategory.LOW:
            return RISK_LOW_COLOR;
        default:
            return RISK_LOW_COLOR;
    }
};

export const getResultPrecision = (units: string): number => {
    let decimalPrecision = 2;
    switch (units) {
        case '°':
            decimalPrecision = 0;
            break;
    }
    return decimalPrecision;
};

export const getOutcomeBoxData = (
    result: ScanSessionResult,
    factorResultCategory: Maybe<FactorCategory>,
): OutcomeBoxData => {
    const { name, units, value } = result;
    const decimalPrecision = getResultPrecision(result.units);

    return {
        factorResultCategory,
        name,
        value: value.toFixed(decimalPrecision),
        units,
        label: `${factorResultCategory ? wordToTitleCase(factorResultCategory) : ''} Risk`,
    };
};
