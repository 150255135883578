import React, { useEffect } from 'react';
import { mixpanel } from '@/common/mixpanel';
import { IconFilter } from '@/components/common/Icons/IconFilter';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { Button } from '@/components/common/button/button';
import {
    ExerciseFilters,
    ActiveExerciseChips,
    ClearExerciseFilters,
} from '../../../Health/HEPBuilder/ExerciseFilters/ExerciseFilters';
import { ExerciseListProvider } from '../../../Health/HEPBuilder/ExerciseList/state/ExerciseListContext';
import { ExerciseSearch } from '../../../Health/HEPBuilder/ExerciseSearch/ExerciseSearch';
import { HEPBuilderExercisesContainer } from '../../../Health/HEPBuilder/HEPBuilderExercises/HEPBuilderExercises';
import {
    HEPBuilderExercisesFilterWrapper,
    HEPBuilderExercisesSidebar,
    HEPBuilderSearchWrapper,
} from '../../../Health/HEPBuilder/steps/StepExercises/styles';
import {
    ExercisesWrapper,
    ViewExerciseDrawerWrapper,
    ExercisesFiltersWrapper,
    ActiveFiltersWrapper,
    HEPBuilderExercisesContent,
} from './styles';

export const ExerciseLibraryRoute = () => {
    const [drawerIsOpen, setDrawerIsOpen] = React.useState<boolean>(false);

    const toggleDrawer = (open: boolean) => () => {
        setDrawerIsOpen(open);
    };

    useEffect(() => {
        mixpanel.track('Viewed Motion Library');
    }, []);

    return (
        <>
            <PageTitle title="Motion Library" />
            <ExerciseListProvider>
                <ActiveFiltersWrapper>
                    <HEPBuilderExercisesFilterWrapper>
                        <Button onClick={toggleDrawer(true)} variant="text" startIcon={<IconFilter />}>
                            Filters
                        </Button>
                        <ClearExerciseFilters />
                    </HEPBuilderExercisesFilterWrapper>
                    <ActiveExerciseChips />
                </ActiveFiltersWrapper>
                <ExercisesWrapper container spacing={4}>
                    <ExercisesFiltersWrapper item md={3} lg={3} xl={2}>
                        <HEPBuilderExercisesSidebar>
                            <HEPBuilderExercisesFilterWrapper>
                                <h3>Filters</h3>
                                <ClearExerciseFilters />
                            </HEPBuilderExercisesFilterWrapper>
                            <ExerciseFilters />
                        </HEPBuilderExercisesSidebar>
                    </ExercisesFiltersWrapper>
                    <HEPBuilderExercisesContent item md={12} lg={9} xl={10}>
                        <HEPBuilderSearchWrapper>
                            <ExerciseSearch />
                        </HEPBuilderSearchWrapper>
                        <HEPBuilderExercisesContainer
                            gridSize={{ xs: 6, sm: 6, md: 4, lg: 3, xl: 3, xxl: 2 }}
                            addToHepCtas={false}
                        />
                    </HEPBuilderExercisesContent>
                    <ViewExerciseDrawerWrapper anchor="left" open={drawerIsOpen} onClose={toggleDrawer(false)}>
                        <ExerciseFilters />
                    </ViewExerciseDrawerWrapper>
                </ExercisesWrapper>
            </ExerciseListProvider>
        </>
    );
};
