import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Radio } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { HEPTemplate } from '@/common/types';
import { WeightIcon } from '@/components/common/Icons/WeightIcon';
import { hepTemplatesState } from '@/recoil/atoms/hepTemplatesAtom';
import { useHEPBuilderContext } from '../../../state/hooks/HEPBuilderContext';
import {
    TemplateAccordionDetails,
    TemplateAccordion,
    TemplateAccordionSummary,
    TemplateMenuItem,
    TemplateMenuItemTitle,
    TemplateMenuItemExercises,
} from './styles';

const MenuItem = ({
    template,
    handleTemplateClick,
    selectedTemplate,
}: {
    template: HEPTemplate;
    handleTemplateClick: (template) => void;
    selectedTemplate?: HEPTemplate | null;
}) => {
    return (
        <TemplateMenuItem onClick={() => handleTemplateClick(template)}>
            <Grid container>
                <Grid item xs={1} display="flex" alignItems="center">
                    <Radio checked={selectedTemplate?.id === template.id} />
                </Grid>
                <Grid item xs={8} display="flex" alignItems="center">
                    <TemplateMenuItemTitle noWrap>{template.name}</TemplateMenuItemTitle>
                </Grid>
                <Grid item xs={3} display="flex" alignItems="center" justifyContent="flex-end">
                    <TemplateMenuItemExercises variant="caption">
                        <WeightIcon />
                        {template.blocks.length} Exercises
                    </TemplateMenuItemExercises>
                </Grid>
            </Grid>
        </TemplateMenuItem>
    );
};

export const TemplatesAccordion = () => {
    const templateDict = useRecoilValue(hepTemplatesState);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const { hepBuilderActions } = useHEPBuilderContext();
    const [selectedTemplate, setSelectedTemplate] = useState<HEPTemplate | null>();

    useEffect(() => {
        hepBuilderActions?.setName(selectedTemplate ? selectedTemplate.name : '');
        hepBuilderActions?.setNotes((selectedTemplate && selectedTemplate?.notes) || '');
        hepBuilderActions?.setBlocks(selectedTemplate ? selectedTemplate.blocks : []);
        hepBuilderActions?.setDailyFrequency(selectedTemplate ? selectedTemplate.dailyFrequency : 3);
        hepBuilderActions?.setWeeklyFrequency(selectedTemplate ? selectedTemplate.weeklyFrequency : 5);
    }, [selectedTemplate]);

    const handleAccordionClick = () => {
        setExpanded((prev) => !prev);
    };
    const handleTemplateClick = (template: HEPTemplate | null) => {
        handleAccordionClick();
        setSelectedTemplate(template);
    };

    return templateDict ? (
        <TemplateAccordion disableGutters onChange={handleAccordionClick} expanded={expanded}>
            <TemplateAccordionSummary expandIcon={<ExpandMoreIcon />}>
                {selectedTemplate ? selectedTemplate.name : 'Choose existing template as a starter'}
            </TemplateAccordionSummary>
            <TemplateAccordionDetails>
                <TemplateMenuItem onClick={() => handleTemplateClick(null)}>
                    <Grid container>
                        <Grid item xs={1} display="flex" alignItems="center">
                            <Radio checked={!selectedTemplate} />
                        </Grid>
                        <Grid item xs={11} display="flex" alignItems="center">
                            <TemplateMenuItemTitle>None</TemplateMenuItemTitle>
                        </Grid>
                    </Grid>
                </TemplateMenuItem>
                {templateDict.map((template) => (
                    <MenuItem
                        key={template.id}
                        template={template}
                        handleTemplateClick={handleTemplateClick}
                        selectedTemplate={selectedTemplate}
                    />
                ))}
            </TemplateAccordionDetails>
        </TemplateAccordion>
    ) : null;
};
