import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Paths } from '@/common/const';
import { EmptyDataIcon } from '@/components/common/EmptyData/styles';
import { FormButtonContainer } from '@/components/common/Form/styles';
import { Button } from '@/components/common/button/button';
import { HEPReadyWrapper, HEPReadyActions } from './styles';

interface TemplateReadyProps {
    closeModal?: () => void;
}

export const TemplateReady = (props: TemplateReadyProps) => {
    const { closeModal } = props;
    const navigate = useNavigate();

    const handleSecondaryClick = () => {
        closeModal && closeModal();
    };

    const handlePrimaryClick = () => {
        navigate(Paths.patients);
    };

    return (
        <HEPReadyWrapper>
            <EmptyDataIcon>
                <CheckIcon />
            </EmptyDataIcon>
            <h2>Your Program Template is ready!</h2>
            <p>You can use this template as a starter for future programs</p>
            <HEPReadyActions>
                <FormButtonContainer>
                    <Box>
                        <Button onClick={handleSecondaryClick} variant="tertiary" size="small">
                            Back to Template List
                        </Button>
                    </Box>
                    <Box>
                        <Button variant="primary" onClick={handlePrimaryClick} size="small">
                            Assign Programs Now
                        </Button>
                    </Box>
                </FormButtonContainer>
            </HEPReadyActions>
        </HEPReadyWrapper>
    );
};
