import React, { useEffect, useState } from 'react';
import { Row } from 'react-table';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { Organization } from '@/common/types';
import { PatientProductBadges } from '@/components/Patients/ProductBadges/ProductBadges';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { Button } from '@/components/common/button/button';
import { Center } from '@/components/common/styles';
import { useModalContext } from '@/components/context/hooks/ModalContext';
import { resultIsError } from '@/services/HttpService';
import { OrganizationsTable } from './OrganizationsTable';

export const OrganizationsTableContainer = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<Organization[]>();
    const { httpService } = useHttpContext();
    const { setDialog, setDialogOpen } = useModalContext();
    const { infoToast, successToast, errorToast } = toasts;

    const getOrganizations = async () => {
        setIsLoading(true);
        const res = await httpService.getOrganizations();
        if (resultIsError(res)) {
        } else {
            const orderedByName = res.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            setRows(orderedByName);
        }
        setIsLoading(false);
    };

    const handleDisable = async (organizationId: number) => {
        setDialogOpen(false);
        infoToast('Attempting to disable organization. This may take a few minutes.');
        setIsLoading(true);
        const res = await httpService.disableOrganizationAdmin(organizationId);
        if (resultIsError(res)) {
            errorToast(`Failed to disable organization`);
        } else {
            successToast(`Successfully disabled organization`);
            await getOrganizations();
        }
    };

    const handleEnable = async (organizationId: number) => {
        setDialogOpen(false);
        infoToast('Attempting to enable organization. This may take a few minutes.');
        setIsLoading(true);
        const res = await httpService.enableOrganizationAdmin(organizationId);
        if (resultIsError(res)) {
            errorToast(`Failed to enable organization`);
        } else {
            successToast(`Successfully enabled organization`);
            await getOrganizations();
        }
    };

    useEffect(() => {
        (async () => {
            await getOrganizations();
        })();
    }, []);

    const handleEnableClick = (organizationId: number) => {
        setDialog(
            'Enable Organization?',
            <Center>
                <p>
                    Are you sure you want to enable this organization? This will restore any previous access to Exer
                    applications for patients and providers.
                </p>
                <SubmitCancelButtons
                    submitText="Enable"
                    submitFunction={() => handleEnable(organizationId)}
                    cancelFunction={() => setDialogOpen(false)}
                />
            </Center>,
        );
        setDialogOpen(true);
    };
    const handleDisableClick = (organizationId: number) => {
        setDialog(
            'Disable Organization?',
            <Center>
                <p>
                    Are you sure you want to disable this organization? This will revoke access to all Exer applications
                    and discharge all patients.
                </p>
                <SubmitCancelButtons
                    submitText="Disable"
                    submitFunction={() => handleDisable(organizationId)}
                    cancelFunction={() => setDialogOpen(false)}
                />
            </Center>,
        );
        setDialogOpen(true);
    };

    const columnStructure = [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ value }) => <strong>{value}</strong>,
        },
        {
            Header: 'TimeZone',
            accessor: 'timezone',
            Cell: ({ value }) => <strong>{value}</strong>,
        },
        {
            Header: 'Products',
            accessor: 'products',
            Cell: ({ value }) => {
                return <PatientProductBadges products={value} />;
            },
        },
        {
            Header: 'Enable/Disable',
            accessor: 'disabled',
            Cell: ({ value, row }: { value: boolean; row: Row }) => {
                return value ? (
                    <Button onClick={() => handleEnableClick(row.values.id)} size="small" variant="primary">
                        Enable
                    </Button>
                ) : (
                    <Button onClick={() => handleDisableClick(row.values.id)} size="small" variant="tertiary">
                        Disable
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                rows && <OrganizationsTable allowAddOrgs columnStructure={columnStructure} data={rows} />
            )}
        </div>
    );
};
