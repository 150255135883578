import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Radio } from '@mui/material';
import { ROMDataDict, ROMData } from '@/components/Health/PatientSessions/Charts/types';
import { AccordionDetails, Accordion, AccordionSummary, RadioMenuItem, MenuItemTitle } from './styles';

interface PatientChartROMAccordionProps {
    readonly options: ROMDataDict;
    readonly handleChange: (options: { optionId: string; optionName: string }) => void;
}

interface MenuItemProps {
    item: ROMData;
    handleClick: (item: ROMData) => void;
    selectedItem?: ROMData;
}

const MenuItem = (props: MenuItemProps) => {
    const { item, handleClick, selectedItem } = props;
    return (
        <RadioMenuItem onClick={() => handleClick(item)}>
            <Grid container>
                <Grid item xs={1} display="flex" alignItems="center">
                    <Radio checked={selectedItem?.exerciseId === item.exerciseId} />
                </Grid>
                <Grid item xs={11} display="flex" alignItems="center">
                    <MenuItemTitle noWrap>{item.exerciseName}</MenuItemTitle>
                </Grid>
            </Grid>
        </RadioMenuItem>
    );
};

export const ROMAccordion = ({ handleChange, options }: PatientChartROMAccordionProps) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<ROMData>();

    useEffect(() => {
        setSelectedItem(options[Object.keys(options)[0]]);
    }, []);

    const handleAccordionClick = () => {
        setExpanded((prev) => !prev);
    };

    const handleClick = (ROM: ROMData) => {
        handleAccordionClick();
        setSelectedItem(ROM);
        handleChange({ optionId: ROM.exerciseId, optionName: ROM.exerciseName });
    };

    return options ? (
        <Accordion disableGutters onChange={handleAccordionClick} expanded={expanded}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {selectedItem && selectedItem.exerciseName}
            </AccordionSummary>
            <AccordionDetails>
                {Object.values(options).map((template) => (
                    <MenuItem
                        key={template.exerciseId}
                        item={template}
                        handleClick={handleClick}
                        selectedItem={selectedItem}
                    />
                ))}
            </AccordionDetails>
        </Accordion>
    ) : null;
};
