import React from 'react';
import { Pagination, useMediaQuery, useTheme } from '@mui/material';
import { usePaginationContext } from '@/components/context/hooks/PaginationContext';
import { PaginationWrapper } from './styles';

export const PaginationContainer = () => {
    const { page, setPage, paginationMeta } = usePaginationContext();
    const theme = useTheme();
    const smallBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));

    const handlePaginationChange = (_: React.ChangeEvent<unknown>, page: number) => {
        setPage(page);
    };

    return (
        <PaginationWrapper>
            <Pagination
                count={paginationMeta?.totalPages}
                defaultPage={page}
                page={page}
                color="primary"
                variant="outlined"
                shape="rounded"
                onChange={handlePaginationChange}
                boundaryCount={!smallBreakpoint ? 1 : undefined}
                siblingCount={!smallBreakpoint ? 0 : undefined}
            />
        </PaginationWrapper>
    );
};
