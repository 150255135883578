import { styled, RadioGroup, FormLabel } from '@mui/material';

export const ListForm = styled('form')(({ theme }) => ({
    width: '100%',
}));

export const RadioCardList = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    '.MuiPaper-radio': {
        marginBottom: theme.spacing(3),
    },
}));

export const RadioCardListLabel = styled(FormLabel)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    color: theme.palette.grey[900],
}));
