import { atom } from 'recoil';
import { HEPTemplate } from '@/common/types';

interface deleteTemplatesListStateProps {
    ids: number[];
    selectIsActive: boolean;
}

export const hepTemplatesState = atom<HEPTemplate[]>({
    key: 'hepTemplatesDict',
    default: undefined,
});

export const deletedHEPTemplatesState = atom<number[]>({
    key: 'deletedHEPTemplates',
    default: [],
});

export const hepTemplateState = atom<HEPTemplate>({
    key: 'HEPTemplateDetailState',
    default: undefined,
});

export const selectedBuilderTemplateState = atom<HEPTemplate>({
    key: 'selectedBuilderTemplateState',
    default: undefined,
});

export const deleteTemplatesListState = atom<deleteTemplatesListStateProps>({
    key: 'selectedBuilderTemplateState',
    default: { ids: [], selectIsActive: false },
});
