import { styled } from '@mui/material';

export const SessionOutcomesOrgSelect = styled('div')(({ theme }) => ({
    padding: theme.spacing(4),
}));

export const SessionOutcomesContentWrapper = styled('div')(({ theme }) => ({
    minHeight: 320,
    margin: theme.spacing(4),
    marginTop: 0,
    marginBottom: 0,
    borderTop: '1px solid #333333',
}));
