import React from 'react';
import { Typography } from '@mui/material';
import { ThresholdFunction } from '@/common/types';
import { AlertIcon } from '../Icons/AlertIcon';
import { AlertCellDiv } from './style';

interface Props {
    value: number;
    thresholdFunction: ThresholdFunction;
    unit: string;
}

export const ThresholdValue = (props: Props) => {
    const { value, thresholdFunction, unit } = props;

    const thresholdWarning = !!thresholdFunction(value);

    return (
        <AlertCellDiv $thresholdWarning={thresholdWarning}>
            <Typography variant="body1">
                {value}
                {unit && <>{unit}</>}
            </Typography>
            {thresholdFunction(value) && <AlertIcon sx={{ fontSize: '18px', marginLeft: '4px' }} />}
        </AlertCellDiv>
    );
};
