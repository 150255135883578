import React, { FC } from 'react';
import { Svg as SvgOriginal } from '@react-pdf/renderer';

interface SVGProps {
    width?: string | number;
    height?: string | number;
    viewBox?: string;
    children?: React.ReactNode;
    style?: any;
}

const Svg: FC<SVGProps> = (props) => {
    return <SvgOriginal {...props} />;
};

export default Svg;
