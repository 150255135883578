import { styled, Tabs as MuiTabs, Tab as MuiTab, Grid } from '@mui/material';

export const Tabs = styled(MuiTabs)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    marginBottom: '0',
    boxShadow: `inset 0px -2px 0px 0px ${theme.palette.grey[200]}`,
    paddingLeft: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(4),
    },
}));

export const Tab = styled(MuiTab)(({ theme }) => ({
    padding: `${theme.spacing(1.5)} 0 ${theme.spacing(0.5)}`,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: '700',
    marginRight: theme.spacing(4),
    minWidth: 'min-content',
    marginLeft: '0',
}));

export const TabContent = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
}));

export const TabContentGrid = styled(Grid)<{ $inModal?: boolean }>(({ theme, $inModal }) => ({
    backgroundColor: theme.palette.common.white,
    maxWidth: '100%',
    marginTop: '0',
    marginLeft: '0',
    padding: `0 ${theme.spacing($inModal ? 0 : 6)} ${theme.spacing(4)} 0`,
}));
