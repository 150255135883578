import { useContext } from 'react';
import { AssessmentsContext } from '../AssessmentsContext';

export const useAssessmentsContext = () => {
    const assessmentsContext = useContext(AssessmentsContext);

    if (!assessmentsContext) {
        throw new Error('AssessmentContext not initialized');
    }

    return assessmentsContext;
};
