import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { PatientPagedRequestParams, PatientRecord } from '@/common/types';
import { patientNameFromRecord } from '@/common/utils';
import { EmptyPatients } from '@/components/Patients/EmptyPatients';
import { UnhandledError } from '@/components/common/Error/UnhandledError';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { resultIsError } from '@/services/HttpService';
import { GaitEventsPatientsTable } from './GaitEventsPatientsTable';

interface GaitEventsPatientsTableContainerProps {
    locationId: number;
    addedPatientCount: number;
}

export const GaitEventsPatientsTableContainer = (props: GaitEventsPatientsTableContainerProps) => {
    const { locationId, addedPatientCount } = props;
    const { httpService } = useHttpContext();
    const [rows, setRows] = useState<
        {
            id: number;
            NAME: string;
            GAIT_UID: string | null | undefined;
            LAST_ACTIVITY: string;
            LOCATION: string | undefined;
            patientRecord: PatientRecord;
        }[]
    >([]);
    const [hasFetched, setHasFetched] = useState(false);
    const [patientsExist, setPatientsExist] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isErrored, setIsErrored] = useState(false);

    const getPatients = async () => {
        const res = await httpService.getPatientRecords({
            locationId,
            limit: 999,
        } as unknown as PatientPagedRequestParams);
        if (!res || resultIsError(res)) {
            setIsErrored(true);
        } else {
            const converted = res.items.map((patientRecord: PatientRecord) => {
                return {
                    id: patientRecord.id,
                    NAME: patientNameFromRecord(patientRecord),
                    GAIT_UID: patientRecord.productData.gait?.uid,
                    LAST_ACTIVITY:
                        patientRecord.mostRecentActivity &&
                        DateTime.fromISO(patientRecord.mostRecentActivity).toFormat('LL/dd/yyyy').toLocaleString(),
                    LOCATION: patientRecord.location?.name,
                    patientRecord,
                };
            });
            setRows(converted);
            setPatientsExist(Object.keys(res.items).length > 0);
        }
    };

    useEffect(() => {
        setIsLoading(true);
    }, []);

    useEffect(() => {
        (async () => {
            await getPatients();

            setHasFetched(true);
            setIsLoading(false);
        })();
    }, [locationId, addedPatientCount]);

    const columnStructure = [
        {
            Header: 'Name',
            accessor: 'NAME',
            Cell: ({ value, row }) => <div>{value}</div>,
        },
        {
            Header: 'Location',
            accessor: 'LOCATION',
            Cell: ({ value, row }) => <div>{value}</div>,
        },
        {
            Header: 'Gait UID',
            accessor: 'GAIT_UID',
            Cell: ({ value, row }) => <div>{value}</div>,
        },
        {
            Header: 'Last Activity',
            accessor: 'LAST_ACTIVITY',
            Cell: ({ value, row }) => <div>{value}</div>,
        },
    ];

    return (
        <>
            {' '}
            <div>
                {isErrored ? (
                    <UnhandledError />
                ) : isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {hasFetched ? (
                            <>
                                {!patientsExist ? (
                                    <EmptyPatients title="No gait patients with that location" body="" />
                                ) : (
                                    <>
                                        <GaitEventsPatientsTable columnStructure={columnStructure} data={rows} />
                                    </>
                                )}
                            </>
                        ) : null}
                    </>
                )}
            </div>
        </>
    );
};
