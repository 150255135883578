import { useGenericHttpContext } from '@exerai/react-core';
import { HttpService } from '@/services/HttpService';

export const useHttpContext = () => {
    const { httpClient, httpService } = useGenericHttpContext<HttpService>();

    return {
        httpClient,
        httpService,
    };
};
