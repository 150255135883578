import { atom } from 'recoil';

interface SidebarNavStateProps {
    isOpen: boolean;
}

export const sidebarNavState = atom<SidebarNavStateProps>({
    key: 'SidebarNavAtom',
    default: {
        isOpen: true,
    },
});
