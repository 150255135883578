import React from 'react';
import { TabContent } from '@/components/common/Tabs/styles';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    ariaLabel?: string;
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ariaLabel, ...other } = props;

    return (
        <TabContent
            role="tabpanel"
            hidden={value !== index}
            id={`${ariaLabel?.replace(/\s/g, '-').toLowerCase() || index}-tabpanel`}
            aria-labelledby={`${ariaLabel?.replace(/\s/g, '-').toLowerCase() || index}-tab`}
            {...other}
        >
            {value === index && <>{children}</>}
        </TabContent>
    );
};
