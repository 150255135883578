import React, { useState, useEffect } from 'react';
import { Box, Divider, useTheme } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Paths } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { isExerAdmin } from '@/common/utils';
import { sidebarNavState } from '@/recoil/atoms/sidebarNavAtom';
import { DashboardHeader } from '../Header/DashboardHeader';
import { AdminSidebarContent } from './AdminSidebarContent';
import { DynamicLogo } from './DynamicLogo';
import { MiniSidebarContent } from './MiniSidebarContent';
import { DrawerHeader, Drawer } from './styles';
import { MobileDrawer, MobileDrawerPaper } from './styles';

export const MiniDrawer = (props) => {
    const { children } = props;
    const theme = useTheme();
    const sidebarNav = useRecoilValue(sidebarNavState);
    const { isOpen } = sidebarNav;
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    const location = useLocation();
    const { user } = useExerUser();

    useEffect(() => {
        if (isMobileNavOpen) {
            handleMobileClose();
        }
    }, [location.pathname]);

    const handleMobileClose = () => {
        setMobileNavOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DashboardHeader isMobileNavOpen={isMobileNavOpen} setMobileNavOpen={setMobileNavOpen} />
            <MobileDrawer
                anchor="left"
                onClose={handleMobileClose}
                open={isMobileNavOpen}
                variant="temporary"
                ModalProps={{ onBackdropClick: handleMobileClose }}
                $isAdmin={isExerAdmin(user) && !!matchPath(`${Paths.admin}*`, location.pathname)}
                // @ts-ignore
                PaperProps={{ component: MobileDrawerPaper }}
                sx={{ display: { md: 'none', xs: 'block' } }}
            >
                {!!matchPath(`${Paths.admin}*`, location.pathname) ? <AdminSidebarContent /> : <MiniSidebarContent />}
            </MobileDrawer>
            <Drawer
                variant="permanent"
                open={isOpen}
                sx={{ backgroundColor: 'rgb(10, 10, 13)', display: { md: 'block', xs: 'none' } }}
            >
                <DrawerHeader>
                    <Box sx={{ display: 'flex' }}>
                        <DynamicLogo />
                    </Box>
                </DrawerHeader>
                <Divider style={{ borderColor: theme.palette.grey[600], marginBottom: 24 }} />
                {!!matchPath(`${Paths.admin}*`, location.pathname) ? <AdminSidebarContent /> : <MiniSidebarContent />}
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    minWidth: {
                        xs: '100vw',
                        md: 'auto',
                    },
                    padding: {
                        xs: '0 16px',
                        md: '0',
                    },
                }}
            >
                {children}
            </Box>
        </Box>
    );
};
