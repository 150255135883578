import React from 'react';
import { StyleSheet, Text, View, Document, Page } from '@react-pdf/renderer';
import { ExerPDFHeader } from '@/common/pdf/ExerPDFHeader';
import { getResultPrecision } from '../../Outcomes/utils';
import { ScanReportParticipantData, ScanReportPDFProps } from '../types';
import { participantReportPDFStyles } from './styles';

interface ScanAssessmentReportPDFDocumentProps {
    assessmentMap: Map<string, ScanReportParticipantData>;
    scanReportPDFProps: ScanReportPDFProps;
}

export const ScanParticipantReportPDFDocument = (props: ScanAssessmentReportPDFDocumentProps) => {
    const { assessmentMap, scanReportPDFProps } = props;
    const { title, locationName, organizationLogoPrintUrl } = scanReportPDFProps;

    const pdfStyles = StyleSheet.create(participantReportPDFStyles);

    return (
        <Document>
            <Page size="A4" style={pdfStyles.page}>
                <ExerPDFHeader organizationLogoPrintUrl={organizationLogoPrintUrl} />
                <View style={pdfStyles.pageTitle}>
                    <Text>{title}</Text>
                </View>
                <View style={pdfStyles.pageSubTitle}>
                    <Text>
                        <Text style={{ fontWeight: 600 }}>Location: </Text>
                        {locationName}
                    </Text>
                </View>
                {[...assessmentMap.entries()].map((assessmentEntry) => {
                    const assessmentData = assessmentEntry[1];
                    return (
                        <View key={assessmentData.assessment.id} style={pdfStyles.assessmentCard}>
                            <Text style={pdfStyles.assessmentHeader}>{assessmentData.assessment.name}</Text>
                            {[...assessmentData.sessions.entries()].map((sessionEntry) => {
                                const sessionId = sessionEntry[0];
                                const sessionData = sessionEntry[1];
                                return (
                                    <View key={sessionId} style={pdfStyles.resultsList}>
                                        {sessionData.results.map((result) => {
                                            const decimalPrecision = getResultPrecision(result.units);
                                            return (
                                                <View key={result.name} style={pdfStyles.result}>
                                                    <Text style={pdfStyles.resultText}>
                                                        <Text style={pdfStyles.resultName}>{result.name}: </Text>
                                                        {result.value.toFixed(decimalPrecision)}
                                                        {result.units === '°' ? result.units : ` ${result.units}`}
                                                    </Text>
                                                </View>
                                            );
                                        })}
                                    </View>
                                );
                            })}
                        </View>
                    );
                })}
            </Page>
        </Document>
    );
};
