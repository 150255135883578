import React, { useState } from 'react';
import { useExerUser } from '@/common/hooks/ExerUser';
import { Location, Practitioner } from '@/common/types';
import { isAdmin } from '@/common/utils';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { Modal } from '@/components/common/Modal/Modal';
import { MobileLabel } from '@/components/common/styles';
import { OrganizationRowWrapper } from '../styles';
import { LocationForm } from './LocationForm/LocationForm';
import { LocationRowEdit, LocationRowAddress, LocationRowName, LocationRowEditButton } from './styles';

interface Props {
    location: Location;
    practitioners?: Practitioner[];
}
export const LocationRow = (props: Props) => {
    const { location, practitioners } = props;
    const { user } = useExerUser();
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleEditClick = (_: React.MouseEvent) => {
        setDialogOpen(true);
    };

    const handleCancelClick = (_: React.MouseEvent) => {
        setDialogOpen(false);
    };

    const cityStateSeparator = location.city && location.stateCode ? ', ' : ' ';
    const address1Separator = !location.address2 ? ', ' : ' ';

    return (
        <OrganizationRowWrapper container spacing={2}>
            <LocationRowName item xs={12} md={2}>
                <MobileLabel sx={{ display: { md: 'none', xs: 'inline' } }}>Name: </MobileLabel>
                {location.name}
            </LocationRowName>
            <LocationRowAddress item xs={12} md={4}>
                <MobileLabel sx={{ display: { md: 'none', xs: 'inline' } }}>
                    Location: <br />
                </MobileLabel>
                {location.address1 && location.address1 + address1Separator}
                {location.address2 && location.address2 + ', '}
                {location.city && location.city + cityStateSeparator}
                {location.stateCode && location.stateCode + ' '}
                {location.zipCode && location.zipCode + ' '}
            </LocationRowAddress>
            <LocationRowName item xs={12} md={2}>
                <MobileLabel sx={{ display: { md: 'none', xs: 'inline' } }}>Default Provider: </MobileLabel>
                {location.defaultProvider &&
                    `${location.defaultProvider.firstName} ${location.defaultProvider.lastName}` + ' '}
            </LocationRowName>
            <LocationRowName item xs={12} md={2}>
                <MobileLabel sx={{ display: { md: 'none', xs: 'inline' } }}> Timezone: </MobileLabel>
                {location.timezone}
            </LocationRowName>
            <LocationRowEdit item xs={12} md={2}>
                {isAdmin(user) && (
                    <LocationRowEditButton size="small" onClick={handleEditClick}>
                        <EditIcon />
                    </LocationRowEditButton>
                )}
            </LocationRowEdit>
            <Modal
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                fullWidth={true}
                maxWidth={'lg'}
                title="Edit Location"
            >
                <LocationForm
                    action="update"
                    location={location}
                    handleCancel={handleCancelClick}
                    practitioners={practitioners}
                />
            </Modal>
        </OrganizationRowWrapper>
    );
};
