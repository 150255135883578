export const moduleImages = {
    1: {
        name: 'Activities of Daily Living (ADL)',
        imageUrl:
            'https://exer-exercise-media.s3.us-east-1.amazonaws.com/module-icons/Activities+of+Daily+Living+(ADL)/noun-stairs-6829522.png',
    },
    2: {
        name: 'Balance & Stability',
        imageUrl:
            'https://exer-exercise-media.s3.us-east-1.amazonaws.com/module-icons/Balance+%26+Stability/noun-slippery-floor-4566935+.png',
    },
    3: {
        name: 'Bone Health & Osteoporosis Risk',
        imageUrl:
            'https://exer-exercise-media.s3.us-east-1.amazonaws.com/module-icons/Bone+Health+%26+Osteoporosis+Risk/noun-healthy-bone-5199975.png',
    },
    4: {
        name: 'Cardiovascular Health',
        imageUrl:
            'https://exer-exercise-media.s3.us-east-1.amazonaws.com/module-icons/Cardiovascular+Health/noun-heartbeat-4646648.png',
    },
    5: {
        name: 'Gait Event Screening',
        imageUrl:
            'https://exer-exercise-media.s3.us-east-1.amazonaws.com/module-icons/Gait+_Event_+Screening/noun-walking-5026477.png',
    },
    6: {
        name: 'General Health & Functional Fitness',
        imageUrl:
            'https://exer-exercise-media.s3.us-east-1.amazonaws.com/module-icons/General+Health+%26+Functional+Fitness/noun-posture-4592827.png',
    },
    7: {
        name: 'Lower Extremity',
        imageUrl:
            'https://exer-exercise-media.s3.us-east-1.amazonaws.com/module-icons/Lower+Extremity/noun-leg-3097526.png',
    },
    8: {
        name: 'Mobility & Joint Health',
        imageUrl:
            'https://exer-exercise-media.s3.us-east-1.amazonaws.com/module-icons/Mobility+%26+Joint+Health/noun-exercise-642298.png',
    },
    9: {
        name: 'Neurological & Cognitive Health',
        imageUrl:
            'https://exer-exercise-media.s3.us-east-1.amazonaws.com/module-icons/Neurological+%26+Cognitive+Health/noun-brain-2937636.png',
    },
    10: {
        name: 'Posture & Alignment',
        imageUrl:
            'https://exer-exercise-media.s3.us-east-1.amazonaws.com/module-icons/Posture+%26+Alignment/noun-straight-posture-6283590.png',
    },
    11: {
        name: 'Rehabilitation & Recovery',
        imageUrl:
            'https://exer-exercise-media.s3.us-east-1.amazonaws.com/module-icons/Rehabilitation+%26+Recovery/noun-race-1870932.png',
    },
    12: {
        name: 'Research and Development',
        imageUrl:
            'https://exer-exercise-media.s3.us-east-1.amazonaws.com/module-icons/Research+and+Development/noun-research-and-development-5596420.png',
    },
    13: {
        name: 'Sport Specific & Performance',
        imageUrl:
            'https://exer-exercise-media.s3.us-east-1.amazonaws.com/module-icons/Sport+Specific+%26+Performance/noun-sports-6523804.png',
    },
    14: {
        name: 'Strength & Endurance',
        imageUrl:
            'https://exer-exercise-media.s3.us-east-1.amazonaws.com/module-icons/Strength+%26+Endurance/noun-dumbbell-7301086.png',
    },
    15: {
        name: 'Upper Extremity',
        imageUrl:
            'https://exer-exercise-media.s3.us-east-1.amazonaws.com/module-icons/Upper+Extremity/noun-arm-5132425.png',
    },
};
