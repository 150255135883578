import React from 'react';
import Close from '@mui/icons-material/Close';
import { Button } from '@/components/common/button/button';
import useExerciseFilters from '../Hooks/UseExerciseFilters';
import { ExerciseActiveBooleanFilter } from './ExerciseActiveBooleanFilter';
import { ExerciseActiveFilter } from './ExerciseActiveFilter';
import { ExerciseBooleanFilter } from './ExerciseBooleanFilter';
import { ExerciseFilter } from './ExerciseFilter';
import { ExerciseFiltersWrapper, ExerciseActiveFilters } from './styles';

export const SEARCH_FILTER = 'SEARCH';
export const EXERCISE_TYPE_FILTER = 'exerciseType';
export const BODY_TARGET_FILTER = 'bodyTargets';
export const METRICS_FILTER = 'metricsSupported';
export const EQUIPMENT_FILTER = 'equipment';
export const SUBJECT_POSITION_FILTER = 'subjectPosition';
export const CAMERA_ORIENTATION_FILTER = 'cameraOrientation';
export const FAVORITE_FILTER = 'isFavorite';
export const ASSESSMENT_FILTER = 'isAssessment';
export const PRIMITIVE_FILTERS = [SUBJECT_POSITION_FILTER, CAMERA_ORIENTATION_FILTER, EQUIPMENT_FILTER];
export const STRING_FILTERS = [SEARCH_FILTER];
export const BOOLEAN_FILTERS = [FAVORITE_FILTER];

export interface IExerciseFilter {
    id: string;
    name: string;
    options: Set<string>;
}

export interface ExerciseFilterDict {
    exerciseType: IExerciseFilter;
    metricsSupported: IExerciseFilter;
    bodyTargets: IExerciseFilter;
    equipment: IExerciseFilter;
    subjectPosition: IExerciseFilter;
    cameraOrientation: IExerciseFilter;
    isFavorite: IExerciseFilter;
    isAssessment: IExerciseFilter;
}

export const exerciseFilters: ExerciseFilterDict = {
    exerciseType: { id: EXERCISE_TYPE_FILTER, name: 'Exercise Type', options: new Set() },
    metricsSupported: { id: METRICS_FILTER, name: 'Metrics Supported', options: new Set(['ROM', 'REPS', 'TIME']) },
    bodyTargets: { id: BODY_TARGET_FILTER, name: 'Body Target', options: new Set() },
    equipment: { id: EQUIPMENT_FILTER, name: 'Equipment', options: new Set() },
    subjectPosition: { id: SUBJECT_POSITION_FILTER, name: 'Subject Position', options: new Set() },
    cameraOrientation: { id: CAMERA_ORIENTATION_FILTER, name: 'Camera Orientation', options: new Set() },
    isAssessment: { id: ASSESSMENT_FILTER, name: 'ASSESSMENTS', options: new Set(['isAssessment']) },
    isFavorite: { id: FAVORITE_FILTER, name: 'SHOW FAVORITES', options: new Set(['isFavorite']) },
};

export const ActiveExerciseChips = () => {
    const { activeFilters, updateFilters } = useExerciseFilters();

    return (
        <>
            <ExerciseActiveFilters>
                {Object.values(activeFilters).map((filter) => {
                    if (!filter) {
                        return null;
                    }
                    if (Array.isArray(filter.value)) {
                        return filter.value.map((value) => {
                            return (
                                <ExerciseActiveFilter
                                    key={`${filter.type}-${value}`}
                                    type={filter.type}
                                    value={value}
                                    updateFilters={updateFilters}
                                />
                            );
                        });
                    } else if (BOOLEAN_FILTERS.includes(filter.type)) {
                        return (
                            <ExerciseActiveBooleanFilter
                                key={`${filter.type}-${filter.value}`}
                                type={filter.type}
                                value={filter.value || ''}
                                updateFilters={updateFilters}
                            />
                        );
                    } else {
                        return (
                            <ExerciseActiveFilter
                                key={`${filter.type}-${filter.value}`}
                                type={filter.type}
                                value={filter.value || ''}
                                updateFilters={updateFilters}
                            />
                        );
                    }
                })}
            </ExerciseActiveFilters>
        </>
    );
};

export const ExerciseFilters = () => {
    const { filters, activeFilters, updateFilters, exercisesIncludeAssessments, filtersDetermined } =
        useExerciseFilters();
    return (
        <ExerciseFiltersWrapper>
            <ExerciseActiveFilters>
                <ActiveExerciseChips />
            </ExerciseActiveFilters>
            {filters && filtersDetermined && (
                <>
                    <ExerciseBooleanFilter
                        filter={filters.isFavorite}
                        updateFilters={updateFilters}
                        activeFilters={activeFilters}
                    />
                    {exercisesIncludeAssessments && (
                        <ExerciseBooleanFilter
                            filter={filters.isAssessment}
                            updateFilters={updateFilters}
                            activeFilters={activeFilters}
                        />
                    )}
                    <ExerciseFilter
                        filter={filters.metricsSupported}
                        updateFilters={updateFilters}
                        activeFilters={activeFilters}
                    />
                    <ExerciseFilter
                        filter={filters.bodyTargets}
                        updateFilters={updateFilters}
                        activeFilters={activeFilters}
                    />
                    <ExerciseFilter
                        filter={filters.exerciseType}
                        updateFilters={updateFilters}
                        activeFilters={activeFilters}
                    />
                    <ExerciseFilter
                        filter={filters.equipment}
                        updateFilters={updateFilters}
                        activeFilters={activeFilters}
                    />
                    <ExerciseFilter
                        filter={filters.subjectPosition}
                        updateFilters={updateFilters}
                        activeFilters={activeFilters}
                    />
                    <ExerciseFilter
                        filter={filters.cameraOrientation}
                        updateFilters={updateFilters}
                        activeFilters={activeFilters}
                    />
                </>
            )}
        </ExerciseFiltersWrapper>
    );
};

export const ClearExerciseFilters = () => {
    const { activeFilters, clearFilters } = useExerciseFilters();
    const handleClick = (_: React.MouseEvent) => {
        clearFilters();
    };

    return (
        <>
            {Object.keys(activeFilters).length >= 1 ? (
                <Button onClick={handleClick} variant="text" endIcon={<Close fontSize="small" />}>
                    Clear all
                </Button>
            ) : null}
        </>
    );
};
