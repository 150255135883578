import { Box, Drawer, Grid } from '@mui/material';
import { styled } from '@mui/material';

export const ExercisesWrapper = styled(Grid)(({ theme }) => ({
    display: 'flex',
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(4),
        paddingLeft: '0',
    },
}));

export const ExercisesFiltersWrapper = styled(Grid)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('lg')]: {
        display: 'flex',
        paddingLeft: theme.spacing(4),
    },
}));

export const HEPBuilderExercisesContent = styled(Grid)(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(4),
    },
}));

export const ActiveFiltersWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
        display: 'none',
    },
}));

export const ViewExerciseDrawerWrapper = styled(Drawer)(({ theme }) => ({
    zIndex: 1500,

    '& .MuiDrawer-paper': {
        width: '80vw',
        maxWidth: '320px',
        padding: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));
