import React, { useRef, useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, Collapse, IconButton, Grid, Checkbox } from '@mui/material';
import { Chart as ChartJS, registerables } from 'chart.js';
import { DateTime } from 'luxon';
import { useRecoilValue } from 'recoil';
import {
    LegendGridPaper,
    LegendGridItem,
    LegendGridIcon,
    LegendWrapper,
    ChartContainer,
} from '@/components/Gait/GaitPatient/Charts/styles';
import { ChartEmpty } from '@/components/common/Chart/ChartEmpty';
import { Panel, PanelDetails, PanelSummary, PanelText, ChartAlert } from '@/components/common/Chart/styles';
import { GaitSessionsSelector } from '@/recoil/selectors/gaitSessionsSelector';
import { GaitData } from './types';

ChartJS.register(...registerables);

const options = {
    responsive: true,
    redraw: true,
    scales: {
        y: {
            beginAtZero: true,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
            text: '',
        },
    },
};

export const GaitChart = ({ currentChart }: { currentChart: string; timeSpan: any }) => {
    const gaitChartContainer = useRef<HTMLCanvasElement>(null);
    const { version1Exists, gaitChartsData } = useRecoilValue(GaitSessionsSelector);
    const [chartInstance, setChartInstance] = useState<ChartJS>();
    const [isEmpty, setIsEmpty] = useState(false);
    const [alertIsOpen, setAlertIsOpen] = React.useState(true);
    const [legendItems, setLegendItems] = useState<any>();
    const [legendDisabled, setLegendDisabled] = useState<string[]>([]);

    const getData = (gaitFeature: GaitData) => {
        const dateLabels: string[] = [];
        const dataSet: { data: any[] } = { data: [] };

        Object.values(gaitFeature.chart.labels).map((set, i) => {
            const date = DateTime.fromMillis(set).toFormat('dd LLL');
            dateLabels.unshift(date);
        });
        Object.values(gaitFeature.chart.datasets).map((set, i) => {
            dataSet.data.unshift(gaitFeature.chart.datasets[i]);
        });

        setIsEmpty(dataSet.data.length < 1);

        return {
            dataSet,
            dateLabels,
        };
    };

    const updateChartData = () => {
        if (gaitChartsData && Object.keys(gaitChartsData).length && chartInstance && chartInstance.data) {
            Object.values(gaitChartsData).map((gaitFeature, key) => {
                if (gaitFeature.id === currentChart) {
                    const { dataSet, dateLabels } = getData(gaitFeature);
                    chartInstance.data.datasets = dataSet.data;
                    chartInstance.data.labels = dateLabels;
                    chartInstance.update();
                    const items = chartInstance.options.plugins?.legend?.labels?.generateLabels?.(chartInstance);
                    setLegendItems(items);
                    setLegendDisabled([]);
                }
            });
        }
    };

    useEffect(() => {
        if (!gaitChartContainer?.current) return;
        const ctx = gaitChartContainer.current.getContext('2d');
        if (ctx) {
            const newChartInstance = new ChartJS(ctx, {
                type: 'line',
                data: {
                    datasets: [],
                    labels: [] as string[],
                },
                options: options,
            });

            Object.values(gaitChartsData).map((gaitFeature) => {
                if (gaitFeature.id === currentChart) {
                    setChartInstance(newChartInstance);
                    const { dataSet, dateLabels } = getData(gaitFeature);
                    newChartInstance.data.datasets = dataSet.data;
                    newChartInstance.data.labels = dateLabels;
                    newChartInstance.update();
                    const items = newChartInstance.options.plugins?.legend?.labels?.generateLabels?.(newChartInstance);
                    setLegendItems(items);
                }
            });
        }

        updateChartData();
    }, []);

    const ChartLegend = () => {
        const leftItems = legendItems.filter((item) => /Flexion/.test(item.text));
        const rightItems = legendItems.filter((item) => /Extension|Dorsiflexion/.test(item.text));
        const singleItems = legendItems.filter((item) => /Trunk|Head|Step|Stride|Speed/.test(item.text)).reverse();
        const handleClick = ({ index, name }) => {
            chartInstance?.setDatasetVisibility(index, !chartInstance?.isDatasetVisible(index));
            chartInstance?.update();
            legendDisabled.includes(name)
                ? setLegendDisabled((prev) => prev.filter((fruit) => fruit !== name))
                : setLegendDisabled((prev) => [...prev, name]);
        };
        // TODO create LegendGridItem component and extract chartInstance Items logic to rely on item.hidden
        // TODO instead of using legendDisabled array.
        return (
            <div>
                {leftItems.length > 0 && rightItems.length > 0 ? (
                    <Grid container spacing={5}>
                        <Grid item xs={6}>
                            <LegendGridItem>
                                {leftItems.map((item, i) => {
                                    return (
                                        <LegendGridPaper
                                            key={item.text}
                                            onClick={() => handleClick({ index: item.datasetIndex, name: item.text })}
                                            elevation={0}
                                        >
                                            <div>
                                                <LegendGridIcon
                                                    $isDashed={item.lineDash.length > 0}
                                                    $color={item.fillStyle}
                                                />
                                                <div>{item.text}</div>
                                            </div>
                                            <Checkbox checked={!legendDisabled.includes(item.text)} />
                                        </LegendGridPaper>
                                    );
                                })}
                            </LegendGridItem>
                        </Grid>
                        <Grid item xs={6}>
                            <LegendGridItem>
                                {rightItems.map((item, i) => {
                                    return (
                                        <LegendGridPaper
                                            key={item.text}
                                            onClick={() => handleClick({ index: item.datasetIndex, name: item.text })}
                                            elevation={0}
                                        >
                                            <div>
                                                <LegendGridIcon
                                                    $isDashed={item.lineDash.length > 0}
                                                    $color={item.fillStyle}
                                                />
                                                <div>{item.text}</div>
                                            </div>
                                            <Checkbox checked={!legendDisabled.includes(item.text)} />
                                        </LegendGridPaper>
                                    );
                                })}
                            </LegendGridItem>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container>
                        <Grid item xs={6}>
                            <LegendGridItem>
                                {singleItems.map((item, i) => {
                                    return (
                                        <LegendGridPaper
                                            key={item.text}
                                            onClick={() => handleClick({ index: item.datasetIndex, name: item.text })}
                                            elevation={0}
                                        >
                                            <div>
                                                <LegendGridIcon
                                                    $isDashed={item.lineDash.length > 0}
                                                    $color={item.fillStyle}
                                                />
                                                <div>{item.text}</div>
                                            </div>
                                            <Checkbox checked={!legendDisabled.includes(item.text)} />
                                        </LegendGridPaper>
                                    );
                                })}
                            </LegendGridItem>
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    };

    useEffect(() => {
        updateChartData();
    }, [currentChart, gaitChartsData]);

    return (
        <ChartContainer>
            {version1Exists && (
                <Collapse in={alertIsOpen}>
                    <ChartAlert
                        severity="info"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlertIsOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        <Typography>
                            Heads up! Some data may not show up in the charts because it&apos;s from an older version.
                            You can still access it in the &quot;Assessment&quot; tab.
                        </Typography>
                    </ChartAlert>
                </Collapse>
            )}
            <canvas ref={gaitChartContainer} id="patientChart" style={{ display: isEmpty ? 'none' : 'block' }} />
            {chartInstance !== null && legendItems && (
                <LegendWrapper>
                    <ChartLegend />
                </LegendWrapper>
            )}
            {isEmpty && (
                <ChartEmpty
                    title="No patient data available in this date range"
                    body="Your patient has not done any gait assessments in this date range. Change the date range to view your patient's progress."
                />
            )}

            <Panel elevation={0}>
                <PanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="graph-info" id="graph-info-header">
                    <Typography>What does this graph show?</Typography>
                </PanelSummary>
                <PanelDetails>
                    <PanelText variant="body1">
                        This graph reflects the highest measurement for the specified day.
                    </PanelText>
                    <PanelText variant="body1">
                        If the resident does multiple measurements in a day, you can see all their daily session data
                        under the &quot;Assessment&quot; tab.
                    </PanelText>
                </PanelDetails>
            </Panel>
        </ChartContainer>
    );
};
