import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { DateTime } from 'luxon';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { Maybe, ScanSessionAdmin } from '@/common/types';
import { getAssessmentName, getUrlSafeAssessmentName } from '@/common/utils';
import { useAssessmentsContext } from '@/components/context/hooks/AssessmentsContext';
import { resultIsError } from '@/services/HttpService';
import { AssessmentOutcome } from '../Outcomes/types/types';
import { getResultPrecision, outcomeJSONToMap } from '../Outcomes/utils';
import { SessionOutcomesActionContainer } from '../Sessions/SessionOutcomesActionContainer';
import { ScanParticipantReportPDF } from './ScanParticipantReport/ScanParticipantReportPDF';
import {
    ScanAssessmentReportH2,
    ScanAssessmentReportFilters,
    ScanAssessmentReportCard,
    ScanAssessmentReportCardResults,
    ScanAssessmentReportSectionWrapper,
} from './styles';
import { ScanReportSessionData, ScanReportParticipantData } from './types';

interface ScanParticipantReportProps {
    sessions: ScanSessionAdmin[];
    startEnd: Date;
    locationName?: Maybe<string>;
}

const ALL = 'all';

export const ScanParticipantReport: FC<ScanParticipantReportProps> = ({ sessions, startEnd, locationName }) => {
    const [selectedParticipantId, setSelectedParticipantId] = useState<string>(ALL);
    const { httpService } = useHttpContext();
    const { errorToast } = toasts;
    const { assessmentsDict } = useAssessmentsContext();
    const { user } = useExerUser();
    const [outcomes, setOutcomes] = useState<Maybe<Map<string, AssessmentOutcome>>>();

    const participantMap = new Map<string, Map<string, ScanReportParticipantData>>();
    sessions.forEach((session) => {
        const participantUid = session.participant?.uid || 'Unknown';
        const assessmentMap = participantMap.get(participantUid) || new Map<string, ScanReportParticipantData>();
        if (!session.session) {
            return;
        }

        const sesh = session.session;
        const assessmentName = getAssessmentName(sesh.data.assessmentId, assessmentsDict);
        sesh.data.results.forEach((result) => {
            const participantData: ScanReportParticipantData = assessmentMap.get(sesh.data.assessmentId) || {
                assessment: {
                    name: (assessmentsDict && assessmentsDict[sesh.data.assessmentId].name) || 'Unknown Assessment',
                    id: sesh.data.assessmentId,
                },
                sessions: new Map<number, ScanReportSessionData>(),
            };
            const s = participantData.sessions.get(session.id) || {
                scanSessionOutcomeData: {
                    createdDate: session.createdDate,
                    session: sesh,
                    practitionerName: session.practitioner
                        ? `${session.practitioner.firstName} ${session.practitioner.lastName}`
                        : '',
                    assessmentName,
                    participantDetails: {
                        uid: (session.participant && session.participant.uid) || '',
                        archivedDate:
                            (session.participant &&
                                session.participant.isArchived &&
                                session.participant.archivedDate) ||
                            null,
                    },
                },
                results: [],
            };
            s.results.push({
                value: result.value,
                units: result.units,
                name: result.name,
            });
            participantData.sessions.set(session.id, s);
            assessmentMap.set(sesh.data.assessmentId, participantData);
        });
        participantMap.set(participantUid, assessmentMap);
    });

    const participantEntries = [...participantMap.entries()];

    const handleChange = (e: SelectChangeEvent<string>) => {
        setSelectedParticipantId(e.target.value);
    };

    const getOutcomes = useCallback(async () => {
        const res = await httpService.getScanRiskFactorOutcomes();
        if (!res || resultIsError(res)) {
            errorToast(`Could not load outcome data. ${res?.message || ''}`);
            return;
        }
        const o = outcomeJSONToMap(res);
        setOutcomes(o);
    }, [httpService]);

    useEffect(() => {
        (async () => {
            await getOutcomes();
        })();
    }, [getOutcomes, user]);

    return (
        <>
            <ScanAssessmentReportFilters>
                <div style={{ width: '25%' }}>
                    <FormControl fullWidth>
                        <InputLabel id="participant-filter-label">Participant Filter</InputLabel>
                        <Select
                            labelId="participant-filter-label"
                            id="participant-filter"
                            label="Participant Filter"
                            onChange={handleChange}
                            value={selectedParticipantId}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value={ALL}>All</MenuItem>
                            {participantEntries.map((assessmentEntry) => {
                                const participantUid = assessmentEntry[0];
                                return (
                                    <MenuItem key={participantUid} value={participantUid}>
                                        {participantUid}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            </ScanAssessmentReportFilters>

            {participantEntries
                .filter((participantEntry) => {
                    if (selectedParticipantId === ALL) {
                        return true;
                    }
                    if (participantEntry[0] !== selectedParticipantId) {
                        return false;
                    }
                    return true;
                })
                .map((participantEntry) => {
                    const participantUid = participantEntry[0];
                    const assessmentMap = participantEntry[1];
                    const pdfTitle = `${DateTime.fromJSDate(new Date(startEnd)).toFormat(
                        'MM/dd/yyyy',
                    )}: Participant ${participantUid}`;
                    return (
                        <ScanAssessmentReportSectionWrapper key={participantUid}>
                            <ScanAssessmentReportH2>
                                Participant: {participantUid}
                                <ScanParticipantReportPDF
                                    scanReportPDFProps={{
                                        title: pdfTitle,
                                        locationName,
                                        organizationLogoPrintUrl: user.organizationLogoPrintUrl,
                                    }}
                                    participantEntry={participantEntry}
                                />
                            </ScanAssessmentReportH2>
                            {[...assessmentMap.entries()].map((assessmentEntry) => {
                                const assessmentId = assessmentEntry[0];
                                const assessmentData = assessmentEntry[1];
                                return (
                                    <ScanAssessmentReportCard key={assessmentData.assessment.id}>
                                        <h3>{assessmentData.assessment.name}</h3>
                                        {[...assessmentData.sessions.entries()].map((sessionEntry) => {
                                            const sessionId = sessionEntry[0];
                                            const sessionData = sessionEntry[1];
                                            return (
                                                <ScanAssessmentReportCardResults key={sessionId}>
                                                    <div>
                                                        {sessionData.results.map((result) => {
                                                            const decimalPrecision = getResultPrecision(result.units);
                                                            return (
                                                                <div key={result.name}>
                                                                    <strong>{result.name}: </strong>
                                                                    {result.value.toFixed(decimalPrecision)}
                                                                    {result.units === '°'
                                                                        ? result.units
                                                                        : ` ${result.units}`}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    <div>
                                                        {outcomes && (
                                                            <SessionOutcomesActionContainer
                                                                scanSessionOutcomeData={
                                                                    sessionData.scanSessionOutcomeData
                                                                }
                                                                sessionIdentifier={`${DateTime.fromISO(
                                                                    sessionData.scanSessionOutcomeData.createdDate,
                                                                ).toFormat('MM-dd-yyyy')}_${getUrlSafeAssessmentName(
                                                                    assessmentId,
                                                                    assessmentsDict,
                                                                )}${`_${participantUid}` || ''}`}
                                                                outcomes={outcomes}
                                                            />
                                                        )}
                                                    </div>
                                                </ScanAssessmentReportCardResults>
                                            );
                                        })}
                                    </ScanAssessmentReportCard>
                                );
                            })}
                        </ScanAssessmentReportSectionWrapper>
                    );
                })}
        </>
    );
};
