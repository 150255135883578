import { Typography } from '@mui/material';
import { styled } from '@mui/material';

export const DashboardSubTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
    marginBottom: theme.spacing(4),
}));

export const DashboardTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[900],
    marginBottom: theme.spacing(4),
    fontWeight: theme.typography.fontWeightMedium,
}));
