import React from 'react';
import { ProductBadgeWrapper } from './styles';

interface Props {
    productName: string;
}
export const ProductBadge = (props: Props) => {
    const { productName } = props;
    return <ProductBadgeWrapper label={productName} size="small" />;
};
