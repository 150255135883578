import { PortalEvent } from './const';

const eventService = {
    on(event: PortalEvent, callback: (_: any) => void) {
        document.addEventListener(event.toString(), (e: CustomEvent) => callback(e.detail));
    },
    dispatch(event: PortalEvent, data: any) {
        document.dispatchEvent(new CustomEvent(event.toString(), { detail: data }));
    },
    // @ts-expect-error  TODO
    remove(event: PortalEvent, callback: () => void = undefined) {
        document.removeEventListener(event.toString(), callback);
    },
};

export default eventService;
