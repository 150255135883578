import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Products } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { Maybe } from '@/common/types';
import { SessionOutcomes } from '@/components/Scan/Outcomes/SessionOutcomes';
import { AssessmentOutcome, ScanSessionOutcomeData } from '@/components/Scan/Outcomes/types/types';
import { SessionOutcomesAction } from '@/components/common/Pose/SessionOutcomesAction';
import { resultIsError } from '@/services/HttpService';
import { SessionOutcomesOrgSelect } from '../Outcomes/styles';
import { outcomeJSONToMap } from '../Outcomes/utils';

interface AdminOutcomesActionContainerProps {
    scanSessionOutcomeData: Maybe<ScanSessionOutcomeData>;
    sessionIdentifier: string;
    org: { id: number; name: string };
}

export const AdminOutcomesActionContainer: FC<AdminOutcomesActionContainerProps> = ({
    scanSessionOutcomeData,
    sessionIdentifier,
    org,
}) => {
    const { httpService } = useHttpContext();
    const { errorToast } = toasts;
    const [dialogOpen, setDialogOpen] = useState(false);
    const { user } = useExerUser();
    const [selectedOrgId, setSelectedOrgId] = useState(org.id);
    const [outcomes, setOutcomes] = useState<Map<string, AssessmentOutcome>>();
    const sessionData = scanSessionOutcomeData?.session?.data;

    const getOutcomes = useCallback(async () => {
        const res = await httpService.adminGetScanRiskFactorOutcomesByOrg(selectedOrgId);
        if (!res || resultIsError(res)) {
            errorToast(`Could not load outcome data. ${res?.message || ''}`);
            return;
        }

        const o = outcomeJSONToMap(res);
        setOutcomes(o);
    }, [httpService, selectedOrgId]);

    const handleOrgChange = (e: SelectChangeEvent<string>) => {
        setSelectedOrgId(+e.target.value);
    };

    useEffect(() => {
        if (dialogOpen) {
            (async () => {
                await getOutcomes();
            })();
        }
    }, [dialogOpen, getOutcomes]);

    return (
        <SessionOutcomesAction product={Products.SCAN} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen}>
            <>
                <div>
                    <SessionOutcomesOrgSelect>
                        <FormControl fullWidth>
                            <InputLabel id="org-select-label">Organization for Outcome Data</InputLabel>
                            <Select
                                labelId="org-select-label"
                                id="org-select"
                                type="number"
                                value={selectedOrgId.toFixed(0)}
                                label="Organization For Outcome Data"
                                onChange={handleOrgChange}
                            >
                                <MenuItem value={org.id}>{org.name}</MenuItem>
                                {user.organizationId && user.organizationId !== org.id ? (
                                    <MenuItem value={user.organizationId}>{user.organizationName}</MenuItem>
                                ) : null}
                            </Select>
                        </FormControl>
                    </SessionOutcomesOrgSelect>
                </div>
                {outcomes && sessionData?.assessmentId && outcomes?.get(sessionData.assessmentId) ? (
                    outcomes.size < 1 ? (
                        'No Outcome Data For That Org.'
                    ) : (
                        <SessionOutcomes
                            scanSessionOutcomeData={scanSessionOutcomeData!}
                            sessionIdentifier={sessionIdentifier}
                            outcomes={outcomes}
                        />
                    )
                ) : (
                    'No Outcome Data For That Assessment.'
                )}
            </>
        </SessionOutcomesAction>
    );
};
