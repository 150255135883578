import { Drawer, Chip } from '@mui/material';
import styled from 'styled-components';

export const ViewExerciseDrawerWrapper = styled(Drawer)(({ theme }) => ({
    zIndex: 1500,

    '& .MuiDrawer-paper': {
        padding: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        maxWidth: '100vw',

        [theme.breakpoints.up('sm')]: {
            width: 640,
            maxWidth: '90vw',
        },
    },
    '& h3, h4, h5, p': {
        margin: 0,
    },
    '& h3': {
        marginBottom: theme.spacing(2),
    },
    '& h4': {
        marginBottom: theme.spacing(1),
        fontSize: 16,
    },
    '& h5': {
        marginBottom: theme.spacing(0.4),
        fontSize: 14,
    },
    '& p': {
        marginBottom: theme.spacing(1),
        fontSize: 14,
        color: theme.palette.grey[500],
    },
    '& hr': {
        margin: 0,
        border: 'none',
        width: '100%',
    },
}));

export const ViewExerciseDrawerContent = styled('div')(({ theme }) => ({
    position: 'relative',

    p: {
        whiteSpace: 'pre-wrap',
    },
}));

export const ViewExerciseTags = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
}));

export const ViewExerciseTag = styled(Chip)(({ theme }) => ({
    marginRight: theme.spacing(0.5),
    position: 'relative',
}));

export const ViewExerciseVideoWrapper = styled('div')(({ theme }) => ({
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    position: 'relative',

    '& video': {
        margin: '0 auto',
        maxWidth: '100%',
    },
}));

export const ViewExerciseDrawerActions = styled('div')(({ theme }) => ({
    position: 'sticky',
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    bottom: 0,
    boxShadow: '0 -2px 4px rgba(16,24,64,.05)',
    padding: theme.spacing(2),
}));
