import { styled } from '@mui/material';

export const PatientHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
    },
    '& h1': {
        margin: '0',
    },
}));

export const PatientHeaderRight = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '22px',
    justifyContent: 'flex-end',

    '& h6': {
        marginBottom: '4px',
        marginLeft: '8px',
    },
    '& .MuiButton-root + .MuiButton-root': {
        marginLeft: theme.spacing(2),
    },
}));

export const PatientTitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    columnGap: theme.spacing(1.25),
}));

export const PatientDetailsCalloutWrapper = styled('div')(({ theme }) => ({
    background: 'white',
    marginBottom: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    boxShadow: '0px 2px 5px rgba(16, 5, 51, 0.04)',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'flex-start',

    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
    },

    '> div > h3': {
        marginTop: 0,
        fontSize: 18,
        fontWeight: 500,
        color: theme.palette.grey[900],
    },
}));
