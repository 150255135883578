import React, { useEffect, useState } from 'react';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { UserProfile } from '@/common/types';
import { SignUp } from './SignUp';

interface Props {
    code: string;
}

export const SignUpContainer = (props: Props) => {
    const { code } = props;
    const [userProfile, setUserProfile] = useState<UserProfile>();
    const { httpService } = useHttpContext();

    useEffect(() => {
        (async () => {
            const res = await httpService.getUserProfileByCode(code);
            setUserProfile(res);
        })();
    }, []);

    return !!userProfile ? <SignUp userProfile={userProfile} /> : null;
};
