import React, { Suspense } from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { MixPanel } from '@/common/Mixpanel/Mixpanel';
import { useExerUser } from '@/common/hooks/ExerUser';
import { DashboardLayout } from '@/components/layout/layouts/DashboardLayout';
import { NotFoundRoute } from '@/components/routes/NotFound/NotFound';
import { ProtectedRoute } from './ProtectedRoute';
import { routes } from './definedRoutes';

export const Routes = () => {
    const { user, getRole } = useExerUser();

    return (
        <BrowserRouter>
            <Suspense>
                <ReactRoutes>
                    {routes.map((route) => {
                        const rest =
                            user.isAuthenticated == route.protected || !route.redirect
                                ? {
                                      element: (
                                          <route.layout>
                                              <route.component />
                                              <MixPanel />
                                          </route.layout>
                                      ),
                                  }
                                : {
                                      element: <ProtectedRoute route={route} />,
                                  };
                        return <Route key={route.path} path={route.path} {...rest} />;
                    })}
                    <Route
                        path="*"
                        element={
                            user.isAuthenticated ? (
                                <DashboardLayout>
                                    <NotFoundRoute />
                                </DashboardLayout>
                            ) : (
                                <NotFoundRoute />
                            )
                        }
                    />
                </ReactRoutes>
            </Suspense>
        </BrowserRouter>
    );
};
