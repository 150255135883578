import { styled, TextField, Theme, Typography, Card } from '@mui/material';
import { ServerError } from '@/components/common/Form/styles';

export const StepInformationWrapper = styled('div')(({}) => ({}));

export const StepInformationBody = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.fontSize,
    color: theme.palette.grey[600],
    marginBottom: theme.spacing(5),
}));

export const HEPInputWrapper = styled('div')(({ theme }) => ({
    margin: theme.spacing(4),
    marginLeft: 0,
    marginRight: 0,

    '& h4': {
        margin: 0,
    },
    '& p': {
        color: theme.palette.grey[600],
    },
}));

export const HEPTextFields = styled('div')(({}) => ({}));

export const HEPTextField = styled(TextField)({
    width: '100%',
});

export const HEPError = styled(ServerError)(({ theme }) => ({
    textAlign: 'left',
    fontSize: theme.typography.caption.fontSize,
    marginTop: '3px',
    marginLeft: theme.spacing(1.75),
}));

export const StepInfoContentWrapper = styled(Card)(({ theme }) => ({
    boxShadow: '0px 2px 5px rgba(16, 5, 51, 0.04)',
    padding: theme.spacing(5),
}));

export const HEPNotesLength = styled('span')<{ theme: Theme; $isMax?: boolean }>(({ theme, $isMax }) => ({
    color: $isMax ? 'inherit' : theme.palette.grey[500],
    float: 'right',
    margin: 0,
}));

export const TemplateMenuItemDescription = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.fontSize,
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(3),
}));
