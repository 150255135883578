import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Paths } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { UserProfile } from '@/common/types';
import { LoginThemeContainer } from '../Login/styles';
import { SignUpForm } from './SignUpForm';

interface Props {
    userProfile: UserProfile;
}

export const SignUp = (props: Props) => {
    const { user } = useExerUser();
    const navigate = useNavigate();

    useEffect(() => {
        user.isAuthenticated && navigate(`${Paths.dashboard}`, { replace: true });
    }, []);

    return (
        <LoginThemeContainer>
            <Typography variant={'h5'} component="h1" fontWeight={600}>
                Sign Up for Exer
            </Typography>
            <SignUpForm {...props} />
            <Typography variant={'body1'} component="p" fontWeight={600}>
                Already have an account? <Link to={Paths.userLogin}>Sign In</Link>
            </Typography>
        </LoginThemeContainer>
    );
};
