import { styled, IconButton, Drawer, Typography, Divider } from '@mui/material';

export const DrawerWrapper = styled(Drawer)(({ theme }) => ({
    zIndex: 1500,
    '& .MuiDrawer-paper': {
        paddingTop: theme.spacing(2),
        maxWidth: '100vw',

        [theme.breakpoints.up('sm')]: {
            maxWidth: '90vw',
            width: 640,
        },
    },
}));

export const DrawerTitle = styled(Typography)(({}) => ({}));

export const DrawerTitleWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

export const DrawerContentWrapper = styled('div')(({ theme }) => ({
    flex: '1',

    [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

export const DrawerTitleDivider = styled(Divider)(({ theme }) => ({
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(4),
}));

export const DrawerCloseButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.grey[900],
}));

export const DrawerFooterActionsWrapper = styled('div')(({ theme }) => ({
    padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.palette.common.white,
}));
