import React, { useState } from 'react';
import { Program, PatientRecord, TemplateOrHEP } from '@/common/types';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { Modal } from '@/components/common/Modal/Modal';
import { SlideUpTransition } from '@/components/common/Transitions/SlideTransition';
import { HEPBuilder } from '../HEPBuilder';
import { HEPBuilderProvider, BuilderActions } from '../state/HEPBuilderContext';
import { HEPBuilderModalWrapper, HEPBuilderCloseModalBody } from './styles';

interface Props {
    open: boolean;
    onClose: (program: Program | null) => void;
    patientRecord?: PatientRecord;
    existingTemplateOrHEP?: Program;
    isTemplateOrHEP: TemplateOrHEP;
    actionType: BuilderActions;
}

export const HEPBuilderModal = (props: Props) => {
    const { onClose, patientRecord, existingTemplateOrHEP, open, isTemplateOrHEP, actionType } = props;
    const [openModal, setOpenModal] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const handleOpenCloseModal = () => {
        setOpenModal((prev) => !prev);
    };
    const handleCloseClick = () => {
        if (isDirty) {
            handleOpenCloseModal();
        } else {
            onClose(null);
        }
    };

    const handleSubmit = () => {
        handleOpenCloseModal();
        onClose(null);
    };

    const onCancel = () => {
        handleOpenCloseModal();
    };

    return (
        <HEPBuilderModalWrapper
            onClose={() => {
                onClose(null);
            }}
            open={open}
            TransitionComponent={SlideUpTransition}
        >
            <HEPBuilderProvider
                onClose={onClose}
                initialPatientRecord={patientRecord}
                existingTemplateOrHEP={existingTemplateOrHEP}
                handleIsDirty={setIsDirty}
                isTemplateOrHEP={isTemplateOrHEP}
                actionType={actionType}
            >
                <HEPBuilder closeModal={handleCloseClick} />
                <Modal
                    open={openModal}
                    onClose={handleOpenCloseModal}
                    title="Discard Changes?"
                    fullWidth={true}
                    maxWidth={'lg'}
                >
                    <HEPBuilderCloseModalBody>
                        Changes you made to this Program will not be saved.
                    </HEPBuilderCloseModalBody>
                    <SubmitCancelButtons submitFunction={handleSubmit} cancelFunction={onCancel} submitText="Discard" />
                </Modal>
            </HEPBuilderProvider>
        </HEPBuilderModalWrapper>
    );
};
