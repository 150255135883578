import { DateTime } from 'luxon';
import { PatientRecord, PatientsTableRow } from '@/common/types';
import { getProductsWeight, isPROOnlyPatient, patientNameFromRecord } from '@/common/utils';

export const convertToRows = (patientRecords: PatientRecord[]): PatientsTableRow[] => {
    return patientRecords.map((patientRecord: PatientRecord) => {
        return {
            id: patientRecord.id,
            NAME: patientNameFromRecord(patientRecord),
            LAST_ACTIVITY:
                patientRecord.mostRecentActivity &&
                DateTime.fromISO(patientRecord.mostRecentActivity).toFormat('LL/dd/yyyy').toLocaleString(),
            LOCATION: patientRecord.location?.name,
            product: { products: patientRecord.products, weight: getProductsWeight(patientRecord.products) },
            EMAIL: patientRecord.patient.email,
            program: patientRecord.productData.health?.heps?.[0].name,
            STATUS: isPROOnlyPatient(patientRecord) ? null : patientRecord.productData.health?.status,
            patientRecord,
        };
    });
};
