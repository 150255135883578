import React from 'react';
import { useModalContext } from '@/components/context/hooks/ModalContext';
import { Modal } from '../Modal/Modal';

export const GlobalModal = () => {
    const { dialogOpen, setDialogOpen, title, content } = useModalContext();

    return (
        <Modal open={dialogOpen} onClose={() => setDialogOpen(false)} title={title} fullWidth={true} maxWidth={'lg'}>
            {content}
        </Modal>
    );
};
