import { useState, useEffect } from 'react';
import React from 'react';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { OrganizationAdmin } from '@/common/types';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { resultIsError } from '@/services/HttpService';
import { AdminOrganizationScanOutcomes } from './AdminOrganizationScanOutcomes';

interface AdminScanOrganizationOutcomesProps {
    organizationId: number;
}

export const AdminScanOrganizationOutcomesContainer = (props: AdminScanOrganizationOutcomesProps) => {
    const { organizationId } = props;

    const [organization, setOrganization] = useState<OrganizationAdmin>();
    const { httpService } = useHttpContext();
    const { errorToast } = toasts;

    const getSetOrganization = async () => {
        const res = await httpService.getOrganizationAdmin(organizationId);
        if (resultIsError(res)) {
            errorToast('Could not get organization details.');
            return;
        }
        setOrganization(res);
    };

    useEffect(() => {
        (async () => {
            await getSetOrganization();
        })();
    }, []);

    return organization ? (
        <>
            <PageTitle title={organization.name} />
            <AdminOrganizationScanOutcomes organization={organization} />
        </>
    ) : null;
};
