import { styled, Chip } from '@mui/material';
import { HealthPatientStatus } from '@/common/const';

export const PatientStatusBadgeWrapper = styled(Chip)<{ label: string }>(({ theme, label }) => ({
    background:
        label === HealthPatientStatus.HAS_ACCOUNT
            ? theme.palette.success[100]
            : label === HealthPatientStatus.NO_ACCOUNT
            ? theme.palette.warning[100]
            : theme.palette.error[100],
    color:
        label === HealthPatientStatus.HAS_ACCOUNT
            ? theme.palette.success[700]
            : label === HealthPatientStatus.NO_ACCOUNT
            ? theme.palette.warning[700]
            : theme.palette.error[700],
    marginRight: theme.spacing(1),
}));
