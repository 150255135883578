import {
    Dialog as MUIDialog,
    DialogContent as MUIDialogContent,
    DialogTitle as MUIDialogTitle,
    styled,
    Box,
} from '@mui/material';
import { FormFieldContainer } from '@/components/common/Form/styles';
import { LockIcon } from '@/components/common/Icons/LockIcon';

export const ModuleCardContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    background: theme.palette.grey[200],
    borderRadius: '4px',
    width: '100%',
    flex: 1,
    '.Mui-expanded': {
        margin: '0!important',
    },
    '& .MuiAccordion-root': {
        background: 'inherit',
        boxShadow: 'none!important',
        width: '100%',
        '&::before': {},
    },
    '&: .MuiFormControl-root': {
        marginTop: '14px',
    },
    '.MuiCollapse-vertical': {
        width: 'auto',
    },
}));

export const SortableModuleCard = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: theme.palette.grey[200],
    borderRadius: '4px',
    width: '100%',
}));

export const SortableModuleHandle = styled(Box)(({ theme }) => ({
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontSize: 40,
    paddingLeft: '12px',
}));

export const Dialog = styled(MUIDialog)(({ theme }) => ({
    minWidth: '500px',
    '.MuiDialogContent-root': {
        paddingTop: `${theme.spacing(3)}!important`,
    },
}));

export const DialogTitle = styled(MUIDialogTitle)(({ theme }) => ({
    fontSize: theme.typography.h3.fontSize,
}));

export const DialogContent = styled(MUIDialogContent)(({ theme }) => ({
    minWidth: '600px',
}));

export const DialogTitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
}));

export const DialogContentWrapper = styled('div')(({ theme }) => ({
    position: 'relative',

    '& h2': {
        textAlign: 'center',
        margin: `0 0 24px`,
    },
    '& h3': {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 'normal',
        textAlign: 'center',
        margin: `0 0 24px`,
    },
}));

export const DialogFooter = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(6),
}));

export const TitleLockIcon = styled(LockIcon)(({ theme }) => ({
    color: theme.palette.primary[500],
    fontSize: '24px',
    marginLeft: '16px',
}));

export const AssessmentCardContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    background: theme.palette.common.white,
    borderRadius: 8,
    width: '100%',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    marginBottom: theme.spacing(1),
}));

export const ModuleFormFieldContainer = styled(FormFieldContainer)({
    position: 'relative',
});

export const ModuleImageImg = styled('img')(({ theme }) => ({
    position: 'relative',
    top: 2,
    marginRight: theme.spacing(1),
}));
