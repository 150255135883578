import { styled, Typography, Chip } from '@mui/material';
import { DashboardActionStatus } from '@/common/const';

export const DateTypography = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.grey[600],
    lineHeight: 1.7,
}));

export const PatientStatusBadgeWrapper = styled(Chip)<{ label: string }>(({ theme, label }) => ({
    background:
        label === DashboardActionStatus.READY_TO_BILL
            ? theme.palette.success[100]
            : label === DashboardActionStatus.INVITE_SENT
            ? theme.palette.warning[100]
            : theme.palette.error[100],
    color:
        label === DashboardActionStatus.READY_TO_BILL
            ? theme.palette.success[700]
            : label === DashboardActionStatus.INVITE_SENT
            ? theme.palette.warning[700]
            : theme.palette.error[700],
}));
