import { styled, Dialog, DialogTitle, DialogContent } from '@mui/material';

export const Modal = styled(Dialog)(() => ({
    margin: 'auto',

    '@media (min-width: 600px)': {
        width: '620px',
    },
}));

export const ModalTitle = styled(DialogTitle)(({}) => ({
    textAlign: 'center',
    '&&& h2': {
        lineHeight: '26px',
    },
}));

export const ModalContent = styled(DialogContent)(() => ({
    padding: '24px',
}));

export const ModalCloseButton = styled('a')(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer',
    color: theme.palette.grey[500],

    '& .MuiSvgIcon-root': {
        fontSize: 28,
    },
}));
