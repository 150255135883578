import React from 'react';
import { Grid } from '@mui/material';
import { HEPTemplate } from '@/common/types';
import { EditHEPTemplateAction } from './EditHEPTemplateAction/EditHEPTemplateAction';
import { HEPBlocks } from './HEPBlocks/HEPBlocks';
import {
    HEPDetailsWrapper,
    HEPDetailsLeft,
    HEPDetailsRight,
    HEPDetailsActionsWrapper,
    HEPDetailsNotes,
    HEPFrequencyDetails,
    HEPDetailsTitle,
    HEPDetailsCaption,
    HEPDetailsNotesCard,
} from './styles';

interface Props {
    hepTemplate: HEPTemplate;
}

export const HEPTemplateDetails = (props: Props) => {
    const { hepTemplate } = props;

    return (
        <div>
            <HEPDetailsWrapper>
                <Grid
                    container
                    columnSpacing={8}
                    rowSpacing={{ xs: 4, sm: 0 }}
                    flexDirection={{ xs: 'column-reverse', sm: 'row' }}
                >
                    <Grid item xs={12} lg={8}>
                        <HEPDetailsLeft item container direction="column">
                            <HEPDetailsActionsWrapper>
                                <HEPDetailsTitle variant="h4">{hepTemplate.name}</HEPDetailsTitle>
                                <EditHEPTemplateAction hepTemplate={hepTemplate} />
                            </HEPDetailsActionsWrapper>

                            <HEPDetailsCaption variant="caption">Program Frequency</HEPDetailsCaption>
                            <HEPFrequencyDetails variant="body1">
                                Daily Frequency: {hepTemplate.dailyFrequency} {`\u00a0`} Weekly Frequency:{'  '}
                                {hepTemplate.weeklyFrequency}
                            </HEPFrequencyDetails>
                            <HEPDetailsCaption variant="caption">Exercises</HEPDetailsCaption>
                            <Grid lg={12} xl={9}>
                                {hepTemplate.blocks && <HEPBlocks blocks={hepTemplate.blocks} />}
                            </Grid>
                        </HEPDetailsLeft>
                    </Grid>
                    <HEPDetailsRight item xs={12} lg={4}>
                        <HEPDetailsNotesCard variant="outlined">
                            <HEPDetailsCaption variant="caption">Program Notes</HEPDetailsCaption>
                            <HEPDetailsNotes>{hepTemplate.notes || '-'}</HEPDetailsNotes>
                        </HEPDetailsNotesCard>
                    </HEPDetailsRight>
                </Grid>
            </HEPDetailsWrapper>
        </div>
    );
};
