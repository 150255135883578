import React from 'react';
import { useParams } from 'react-router-dom';
import { AccountPath } from '@/common/const';
import { AccountInfoContainer } from '@/components/Account/AccountInfoContainer';
import { RouteContentWrapper } from '../styles';

export const AccountInfoRoute = () => {
    const { accountPath } = useParams<{ accountPath: AccountPath }>();

    return (
        <RouteContentWrapper>
            <AccountInfoContainer accountPath={accountPath!} />
        </RouteContentWrapper>
    );
};
