import React from 'react';
import { Link } from 'react-router-dom';
import MobileLogo from '@/images/x-white.svg';
import { HeaderLogoImg } from './styles';

export const HeaderLogo = () => {
    return (
        <Link to="/">
            <HeaderLogoImg src={MobileLogo} />
        </Link>
    );
};
