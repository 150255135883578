import React, { useEffect, useState } from 'react';
import { SEARCH_FILTER } from '../ExerciseFilters/ExerciseFilters';
import { useExerciseListContext } from '../ExerciseList/state/hooks/ExerciseListContext';
import { ExerciseSearchWrapper, ClearFilter, SearchFilter, SearchInputWrapper, SearchInput } from './styles';

export const ExerciseSearch = () => {
    const { activeFilters, filterExercises, setActiveFilters } = useExerciseListContext();
    const [searchString, setSearchString] = useState('');

    useEffect(() => {
        !activeFilters?.[SEARCH_FILTER] && setSearchString('');
    }, [activeFilters]);

    const updateSearch = (value: string | null) => {
        const active = activeFilters;
        if (!value) {
            if (active[SEARCH_FILTER]) {
                delete active[SEARCH_FILTER];
            }
        } else {
            active[SEARCH_FILTER] = { type: SEARCH_FILTER, value };
        }
        filterExercises({ active });
        setActiveFilters(active);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateSearch(e.target.value);
        setSearchString(e.target.value);
    };

    const clearSearch = () => {
        updateSearch(null);
        setSearchString('');
    };

    return (
        <ExerciseSearchWrapper>
            <SearchInputWrapper>
                <SearchInput
                    placeholder="Exercise Name"
                    label="Exercise Name"
                    variant="outlined"
                    value={searchString}
                    onChange={onChange}
                />
                {!searchString ? (
                    <SearchFilter color={!searchString ? 'disabled' : 'inherit'} />
                ) : (
                    <ClearFilter color={!searchString ? 'disabled' : 'inherit'} onClick={clearSearch} />
                )}
            </SearchInputWrapper>
        </ExerciseSearchWrapper>
    );
};
