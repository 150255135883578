import React from 'react';
import { MenuItem } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { mixpanel } from '@/common/mixpanel';
import { HEPTemplate } from '@/common/types';
import { sleep } from '@/common/utils';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { TrashIcon } from '@/components/common/Icons/TrashIcon';
import { Center } from '@/components/common/styles';
import { useModalContext } from '@/components/context/hooks/ModalContext';
import { deletedHEPTemplatesState } from '@/recoil/atoms/hepTemplatesAtom';
import { resultIsError } from '@/services/HttpService';
import { CardIconWrapper, CardItemText } from './styles';

interface DeleteTemplateActionProps {
    hepTemplate: HEPTemplate;
    closeMenu: () => void;
}

export const DeleteTemplateAction = (props: DeleteTemplateActionProps) => {
    const { hepTemplate, closeMenu } = props;
    const [deletedHEPTemplates, setDeletedHEPTemplates] = useRecoilState(deletedHEPTemplatesState);

    const { setDialog, setDialogOpen } = useModalContext();
    const { httpService } = useHttpContext();
    const { successToast, errorToast } = toasts;

    const handleSubmit = async () => {
        const success = await httpService.deleteHEPTemplate(hepTemplate.id);

        if (!success || resultIsError(success)) {
            errorToast('The Program template could not be deleted.');
            return;
        } else {
            successToast(`${hepTemplate.name} has been deleted.`);
            await sleep(1000);

            setDialogOpen(false);
            setDeletedHEPTemplates([...deletedHEPTemplates, hepTemplate.id]);
            await sleep(200);

            mixpanel.track('Deleted Program Template');
        }
    };

    const handleClick = () => {
        closeMenu();
        setDialog(
            'Delete Program Template?',
            <Center>
                <p>Are you sure you want to delete {hepTemplate.name}?</p>
                <SubmitCancelButtons
                    submitText="Delete"
                    submitFunction={handleSubmit}
                    cancelFunction={() => setDialogOpen(false)}
                />
            </Center>,
        );
        setDialogOpen(true);
    };

    return (
        <>
            <MenuItem onClick={handleClick}>
                <CardIconWrapper>
                    <TrashIcon />
                </CardIconWrapper>
                <CardItemText>Delete</CardItemText>
            </MenuItem>
        </>
    );
};
