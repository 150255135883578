import React, { useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Location } from '@/common/types';
import { Modal } from '@/components/common/Modal/Modal';
import { OrganizationAddEntityButton } from '../../styles';
import { AddPractitionerForm } from '../AddPractitionerForm/AddPractitionerForm';

interface Props {
    isAbsolute: boolean;
    locations: Location[] | undefined;
}

export const AddPractitioner = (props: Props) => {
    const { isAbsolute, locations } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const dialogRef = useRef(null);

    const handleAddClick = (e: React.MouseEvent) => {
        e.preventDefault();
        setDialogOpen(true);
    };

    const handleCancelClick = (_: React.MouseEvent) => {
        setDialogOpen(false);
    };

    return (
        <>
            <OrganizationAddEntityButton onClick={handleAddClick} startIcon={<AddIcon />} size="small">
                Invite Users
            </OrganizationAddEntityButton>
            <Modal
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                fullWidth={true}
                maxWidth={'lg'}
                title="Add Provider"
            >
                <AddPractitionerForm handleCancel={handleCancelClick} scrollTarget={dialogRef} locations={locations} />
            </Modal>
        </>
    );
};
