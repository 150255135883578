import React, { Dispatch, SetStateAction, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { Box } from '@mui/material';
import { useMuiUtils } from '@/common/hooks/MuiUtils';
import { Nullable, SortableScanAssessment, SortableScanModule } from '@/common/types';
import { DragIcon } from '@/components/common/Icons/DragIcon';
import { EditAssessmentModal } from '../Assessments/EditAssessmentModal';
import { ModuleCard } from './ModuleCard';
import { SortableModuleCard, SortableModuleHandle } from './styles';

export const SortableItem = ({
    module,
    handleSaveModule,
    handleAddAssessment,
    setModules,
    handleSaveAssessment,
    handleDeleteAssessment,
    handleDeleteModule,
    setHasUnsavedChanges,
    isGrabbing,
}: {
    module: SortableScanModule;
    handleSaveModule: (updatedModule: SortableScanModule, oldModuleName: string) => void;
    handleAddAssessment: (module: SortableScanModule) => void;
    setModules: Dispatch<SetStateAction<SortableScanModule[]>>;
    handleSaveAssessment: (updatedModule: SortableScanModule) => void;
    handleDeleteAssessment: (module: SortableScanModule, assessmentId: string) => void;
    handleDeleteModule: (moduleId: string) => void;
    setHasUnsavedChanges: Dispatch<SetStateAction<boolean>>;
    isGrabbing: boolean;
}) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging, active } = useSortable({
        id: module.id,
    });
    const { palette } = useMuiUtils();

    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition,
        boxShadow: isDragging ? `0px 0px 0px 2px ${palette.primary[500]}` : `0px 0px 0px 1px ${palette.grey[300]}`,
        zIndex: isDragging ? 999999 : 999,
    };

    const [openEditAssessment, setOpenEditAssessment] = useState(false);
    const [assessment, setAssessment] = useState<Nullable<SortableScanAssessment>>(null);

    const handleCloseAssessmentEdit = () => {
        setOpenEditAssessment(false);
    };

    const handleEditAssessment = (assessment: SortableScanAssessment) => {
        setAssessment(assessment);
        setOpenEditAssessment(true);
    };

    return (
        <SortableModuleCard ref={setNodeRef} style={style}>
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <SortableModuleHandle
                    style={{
                        cursor: active ? 'grabbing' : 'grab',
                    }}
                    {...attributes}
                    {...listeners}
                >
                    <DragIcon />
                </SortableModuleHandle>
                <ModuleCard
                    module={module}
                    handleSaveModule={handleSaveModule}
                    handleAddAssessment={handleAddAssessment}
                    setModules={setModules}
                    handleEditAssessment={handleEditAssessment}
                    handleDeleteAssessment={handleDeleteAssessment}
                    handleDeleteModule={handleDeleteModule}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                    isGrabbing={isGrabbing}
                />
            </Box>
            {assessment && (
                <EditAssessmentModal
                    open={openEditAssessment}
                    onClose={handleCloseAssessmentEdit}
                    onSave={handleSaveAssessment}
                    module={module}
                    assessment={assessment}
                    key={assessment.name}
                />
            )}
        </SortableModuleCard>
    );
};
