import React, { useState, useEffect } from 'react';
import { Products } from '@/common/const';
import { ProductBadge } from './ProductBadge';
import { PatientProductBadgesWrapper } from './styles';

interface Props {
    products: string[];
}

export const PatientProductBadges = (props: Props) => {
    const { products } = props;
    const [allProducts, setAllProducts] = useState(products);

    const productBadges =
        allProducts &&
        allProducts.map((product) => {
            return <ProductBadge key={Products[product]} productName={Products[product]} />;
        });

    useEffect(() => {
        setAllProducts(products);
    }, [products]);

    return <PatientProductBadgesWrapper>{productBadges}</PatientProductBadgesWrapper>;
};
