import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Modal } from '@/components/common/Modal/Modal';
import { Button } from '@/components/common/button/button';
import { AddPROTypeformFormForm } from './AddPROTypeformForm';

export const AddPROTypeformFormContainer = () => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleCreateButtonClick = (_: React.MouseEvent) => {
        setDialogOpen(true);
    };

    const handleCancel = () => {
        setDialogOpen(false);
    };

    return (
        <div>
            <Button onClick={handleCreateButtonClick} variant="primary" startIcon={<AddIcon />} size="small">
                Add New Typeform Form
            </Button>
            <Modal
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                title={'Add New Typeform Form'}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <AddPROTypeformFormForm cancel={handleCancel} onSuccess={() => window.location.reload()} />
            </Modal>
        </div>
    );
};
