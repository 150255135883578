import React, { FC, useState } from 'react';
import { Products } from '@/common/const';
import { Maybe } from '@/common/types';
import { SessionOutcomes } from '@/components/Scan/Outcomes/SessionOutcomes';
import { AssessmentOutcome, ScanSessionOutcomeData } from '@/components/Scan/Outcomes/types/types';
import { SessionOutcomesAction } from '@/components/common/Pose/SessionOutcomesAction';

interface SessionOutcomesActionContainerProps {
    scanSessionOutcomeData: Maybe<ScanSessionOutcomeData>;
    sessionIdentifier: string;
    outcomes: Maybe<Map<string, AssessmentOutcome>>;
}

export const SessionOutcomesActionContainer: FC<SessionOutcomesActionContainerProps> = ({
    scanSessionOutcomeData,
    sessionIdentifier,
    outcomes,
}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const sessionData = scanSessionOutcomeData?.session?.data;

    return sessionData?.assessmentId && outcomes?.get(sessionData.assessmentId) ? (
        <SessionOutcomesAction product={Products.SCAN} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen}>
            <SessionOutcomes
                scanSessionOutcomeData={scanSessionOutcomeData!}
                sessionIdentifier={sessionIdentifier}
                outcomes={outcomes}
            />
        </SessionOutcomesAction>
    ) : null;
};
