import { styled, Chip, Box, IconButton, Typography } from '@mui/material';

export const PROAnswerWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1),
}));

export const PROQuestionWrapper = styled('div')(({ theme }) => ({
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
}));

export const PROQuestionTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    fontWeight: 'bold',
}));

export const PROAnswerDate = styled('div')(({ theme }) => ({
    minWidth: '20%',
    marginRight: theme.spacing(2),
}));

export const ResponseChip = styled(Chip)(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightLight,
}));

export const MessagesList = styled('div')(({ theme }) => ({
    maxHeight: theme.spacing(87.5),
    overflow: 'scroll',
    paddingBottom: theme.spacing(5),
}));

export const ScrollUpButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary[500],
    borderRadius: theme.shape.borderRadius,
    '& .MuiSvgIcon-root': {
        fill: 'none',
    },
    '&:hover': {
        backgroundColor: theme.palette.primary[400],
    },
}));

export const ScrollButtonWrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: '30px',
    right: '45px',
    overflow: 'hidden',
}));

export const MessagesWrapper = styled('div')(({ theme }) => ({
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    paddingLeft: theme.spacing(5),
    minHeight: theme.spacing(87.5),
}));
