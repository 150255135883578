import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { BlobProvider, Font } from '@react-pdf/renderer';
import dmSansBold from '@/fonts/DMSans-Bold.ttf';
import dmSansMedium from '@/fonts/DMSans-Medium.ttf';
import { ScanReportParticipantData, ScanReportPDFProps } from '../types';
import { ScanParticipantReportPDFDocument } from './ScanParticipantReportPDFDocument';

interface ScanParticipantReportPDFActionProps {
    assessmentMap: Map<string, ScanReportParticipantData>;
    scanReportPDFProps: ScanReportPDFProps;
}

export const ScanParticipantReportPDFAction = (props: ScanParticipantReportPDFActionProps) => {
    const { scanReportPDFProps } = props;
    const { title } = scanReportPDFProps;

    useEffect(() => {
        Font.register({
            family: 'DM Sans',
            fonts: [{ src: dmSansMedium }, { src: dmSansBold, fontWeight: 600 }],
        });
    }, []);

    return (
        <BlobProvider document={<ScanParticipantReportPDFDocument {...props} />}>
            {({ url, loading }) => {
                return (
                    <a href={url || undefined} download={title}>
                        <Button onClick={undefined} variant="primary" disabled={loading || !url}>
                            {loading || !url ? 'Generating PDF...' : 'Download PDF'}
                        </Button>
                    </a>
                );
            }}
        </BlobProvider>
    );
};
