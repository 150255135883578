import React from 'react';
import { SvgIcon } from '@mui/material';

export const EyeOpenIcon = (props) => {
    return (
        <>
            <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" {...props} style={{ fill: 'none' }}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.1614 12.5531C15.1614 14.2991 13.7454 15.7141 11.9994 15.7141C10.2534 15.7141 8.83838 14.2991 8.83838 12.5531C8.83838 10.8061 10.2534 9.39111 11.9994 9.39111C13.7454 9.39111 15.1614 10.8061 15.1614 12.5531Z"
                    stroke="currentcolor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.998 19.8549C15.806 19.8549 19.289 17.1169 21.25 12.5529C19.289 7.98892 15.806 5.25092 11.998 5.25092H12.002C8.194 5.25092 4.711 7.98892 2.75 12.5529C4.711 17.1169 8.194 19.8549 12.002 19.8549H11.998Z"
                    stroke="currentcolor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </SvgIcon>
        </>
    );
};
