import { styled } from '@mui/material';

export const OutcomeDemographicFormWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    border: '1px solid #333333',
    borderRadius: theme.shape.borderRadius,
    maxWidth: 640,
    margin: '0 auto',
}));

export const OutcomeDemographicFormHeader = styled('h4')(({ theme }) => ({
    textAlign: 'center',
}));

export const OutcomeDemographicInputWrapper = styled('div')(({ theme }) => ({}));

export const OutcomeDemographicButtonWrapper = styled('div')(({ theme }) => ({
    textAlign: 'right',
    marginTop: theme.spacing(2),

    '& .MuiButton-root': {
        backgroundColor: theme.palette.primary.main,
        color: '#ffffff',
    },
}));
