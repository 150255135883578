import React from 'react';
import { GaitSession } from '@/common/types';
import { SessionExpandingRowContainer } from './SessionExpandingRowContainer';

interface Props {
    sessions: GaitSession[];
}

export const GaitPatientSessionsList = (props: Props) => {
    const { sessions } = props;

    const SessionExpandingRows = () => {
        return (
            <>
                {sessions
                    ? sessions.map((session) => {
                          return <SessionExpandingRowContainer session={session} key={session.sessionUUID} />;
                      })
                    : null}
            </>
        );
    };

    return (
        <>
            <SessionExpandingRows />
        </>
    );
};
