import { styled, Badge, Menu, Box, Grid, IconButton } from '@mui/material';

export const ButtonWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiButton-root': {
        background: 'white',
        paddingTop: theme.spacing(0.7),
        paddingBottom: theme.spacing(0.7),
        borderColor: theme.palette.grey[400],
    },
}));

export const MenuWrapper = styled(Menu)(({ theme }) => ({
    '.MuiList-root': {
        padding: '0',
    },
}));

export const MenuContent = styled(Box)(({ theme }) => ({
    minWidth: theme.spacing(37.5),
    borderRadius: theme.shape.borderRadius,
    '.MuiListItemIcon-root': {
        paddingTop: '9px',
        paddingBottom: '9px',
        minWidth: '28px',
        fontSize: theme.typography.h5.fontSize,
        marginLeft: '-1px',
    },
    '.MuiFormControl-root': {
        width: '100%',
    },
}));

export const MenuHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 2),
}));

export const MenuSelectedBadge = styled(Badge)(({ theme }) => ({
    '.MuiBadge-badge': {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary[600],
    },
}));

export const MonthIconButton = styled(IconButton)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
    maxWidth: theme.spacing(43),
    padding: theme.spacing(3),
}));

export const GridItemMonth = styled(Grid)(({ theme }) => ({
    width: theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
    '.MuiButton-root': {
        width: '100%',
        lineHeight: '1',
        borderRadius: '8px',
        borderColor: theme.palette.grey[400],
    },
}));
