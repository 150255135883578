import { FormGroup, Box } from '@mui/material';
import styled from 'styled-components';

export const ProductSelectionWrapper = styled('div')(({ theme }) => ({
    textAlign: 'center',
    margin: `0 ${theme.spacing(6)}`,
}));

export const ProductSelectionContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

export const ProductsCheckboxesFieldGroup = styled(FormGroup)(({ theme }) => ({
    justifyContent: 'space-around',
    minWidth: '300px',
}));

export const AddPatientFormWarningText = styled('p')(({ theme }) => ({
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    textAlign: 'center',
}));

export const DragAndDropWrapper = styled(FormGroup)<{
    $isFocused: boolean;
    $isDragAccept: boolean;
    $isDragReject: boolean;
}>(({ theme, $isFocused, $isDragAccept, $isDragReject }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    padding: theme.spacing(4),
    border: `2px dashed ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    outline: 'none',
    borderColor: $isFocused
        ? theme.palette.primary[400]
        : $isDragAccept
        ? theme.palette.success[400]
        : $isDragReject
        ? theme.palette.error[400]
        : theme.palette.grey[400],
    transition: 'border .24s ease-in-out',
}));
