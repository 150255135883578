import { styled } from '@mui/material';
import { Button } from '@/components/common/button/button';

export const GaitReportDownloadButton = styled(Button)(({ theme }) => ({
    '> a': {
        color: 'white',
        textDecoration: 'none',
    },
}));

export const GaitReportDownloadButtonWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(4),
}));

export const GaitReportTabsWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
}));
