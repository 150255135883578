import { styled } from '@mui/material';

export const HEPReadyWrapper = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(6),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '.MuiSvgIcon-root': {
        fill: theme.palette.common.white,
    },

    h1: {
        marginBottom: 0,
    },
}));

export const HEPReadyActions = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(4),
}));
