import React, { useRef, forwardRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Program, PatientRecord } from '@/common/types';
import { PrintableHEPDetails } from '@/components/Health/HEPs/HEPDetails/PrintableHEPDetails';
import { Button } from '@/components/common/button/button';
import {
    PrintViewContainer,
    PageContentWrapper,
    PageTitleDivider,
    PageHeaderWrapper,
    PageHeaderContainer,
} from './styles';

interface PatientHEPPrintViewProps {
    program: Program;
    patientRecord?: PatientRecord;
    handleClose: () => void;
}

export const PatientHEPPrintView = forwardRef(function MyInput(props: PatientHEPPrintViewProps, ref) {
    const { program, patientRecord, handleClose } = props;
    const printHEPRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printHEPRef.current,
        documentTitle: `${patientRecord?.patient.firstName} - ${program.name}`,
    });

    return (
        <PrintViewContainer>
            <PageHeaderWrapper>
                <PageHeaderContainer>
                    <Button variant="tertiary" onClick={handleClose} size="small">
                        Cancel
                    </Button>
                    <Button onClick={handlePrint} size="small">
                        Print Program
                    </Button>
                </PageHeaderContainer>
            </PageHeaderWrapper>
            <PageTitleDivider />
            <PageContentWrapper ref={printHEPRef}>
                <PrintableHEPDetails program={program} />
            </PageContentWrapper>
        </PrintViewContainer>
    );
});
