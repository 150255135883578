import { Box } from '@mui/material';
import { styled } from '@mui/material';

export const RouteContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    transition: 'all .5s ease-in-out',
    height: '100%',
}));

export const RouteContentWrapper = styled(Box)(({ theme }) => ({
    minHeight: 600,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[100],

    '> img': {
        position: 'absolute',
        top: '8px',
        right: '32px',
        height: '46px',
    },
}));
