import { styled, Tab, Tabs } from '@mui/material';

export const PatientProductSelectionTabsWrapper = styled(Tabs)(({ theme }) => ({
    background: 'none',
    boxShadow: 'none',
    padding: 0,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),

    '.MuiTabs-indicator': {
        display: 'none',
    },

    [theme.breakpoints.up('md')]: {
        marginTop: 0,
    },
}));

export const PatientProductSelectionTab = styled(Tab)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[500]}`,
    margin: 0,
    borderRadius: theme.shape.borderRadius,
    zIndex: 10,
    minHeight: 0,
    padding: theme.spacing(1.25),
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.grey[500],
    textTransform: 'capitalize',

    '&.Mui-selected': {
        border: `1px solid ${theme.palette.primary.main}`,
        background: theme.palette.primary.main,
        color: 'white',
    },
    '&:first-child': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    '&:last-child': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        position: 'relative',
        zIndex: 0,

        '&.Mui-selected': {
            zIndex: 20,
        },
    },
}));
