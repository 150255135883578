import { Box, styled } from '@mui/material';

export const AccountInfoFormContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
        width: '50%',
    },
}));
