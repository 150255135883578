import React, { useEffect, useState } from 'react';
import { Maybe, Nullish } from '@/common/types';
import { getAssessmentName, wordToTitleCase } from '@/common/utils';
import { useAssessmentsContext } from '@/components/context/hooks/AssessmentsContext';
import { OutcomeBoxContainer } from './OutcomeBox/OutcomeBoxContainer';
import { OutcomeDemographicForm } from './OutcomeDemographicForm/OutcomeDemographicForm';
import { OutcomeFactorTableContainer } from './OutcomeFactorTable/OutcomeFactorTableContainer';
import { OutcomeSliderContainer } from './OutcomeSlider/OutcomeSliderContainer';
import { SessionOutcomePrintAction } from './PrintOutcome/SessionOutcomePrintAction';
import { SessionOutcomesContentWrapper } from './styles';
import { CriteriaCategory } from './types/CriteriaCategory.enum';
import { FactorCategory } from './types/FactorCategory.enum';
import {
    AssessmentOutcome,
    FactorResult,
    OutcomeFactor,
    ParticipantDemographics,
    ScanSessionOutcomeData,
} from './types/types';
import { getQualifyingFactor } from './utils';

interface SessionOutcomesProps {
    scanSessionOutcomeData: ScanSessionOutcomeData;
    sessionIdentifier: string;
    outcomes?: Maybe<Map<string, AssessmentOutcome>>;
}

export const SessionOutcomes = (props: SessionOutcomesProps) => {
    const { assessmentsDict } = useAssessmentsContext();
    const { scanSessionOutcomeData, outcomes, sessionIdentifier } = props;
    const { assessmentId, results: sessionResults } = scanSessionOutcomeData.session?.data || {};
    const [factors, setFactors] = useState<Maybe<OutcomeFactor[]>>();
    const [factorResult, setFactorResult] = useState<Maybe<FactorResult>>();
    const [demographicsRequired, setDemographicsRequired] = useState<Nullish<CriteriaCategory[]>>();
    const [demographics, setDemographics] = useState<Maybe<ParticipantDemographics>>();

    useEffect(() => {
        setDemographicsRequired(null);
        if (assessmentId) {
            if (outcomes) {
                setDemographicsRequired(outcomes.get(assessmentId)?.demographicsRequired);
                setFactors(outcomes.get(assessmentId)?.factors);
            }
        }
    }, [assessmentId, outcomes]);

    useEffect(() => {
        if (demographicsRequired === undefined || (demographicsRequired && !demographics)) {
            return;
        }
        if (assessmentId && sessionResults?.[0]?.value) {
            if (outcomes) {
                if (factors) {
                    // The org specifies an outcome, so if not all conditions are met, default to Low Risk
                    setFactorResult({ category: FactorCategory.LOW, description: 'Low Risk' });
                    const factor = getQualifyingFactor(factors, sessionResults?.[0]?.value, demographics);
                    if (factor) {
                        setFactorResult(factor);
                    }
                }
            }
        }
    }, [assessmentId, demographics, demographicsRequired, factors, outcomes, sessionResults]);

    const assessmentName = getAssessmentName(assessmentId, assessmentsDict);

    const title = assessmentId ? `${assessmentName}: ${wordToTitleCase(outcomes?.get(assessmentId)?.type)} Risk` : '';

    return (
        <>
            <SessionOutcomesContentWrapper>
                <h2>{title}</h2>
                {demographicsRequired !== undefined && (
                    <>
                        {demographicsRequired && !demographics && (
                            <OutcomeDemographicForm
                                setDemographics={setDemographics}
                                demographicsRequired={demographicsRequired}
                            />
                        )}
                    </>
                )}

                {sessionResults?.[0] ? (
                    <>
                        {factorResult && (
                            <>
                                <SessionOutcomePrintAction
                                    scanSessionOutcomeData={scanSessionOutcomeData}
                                    factorResultCategory={factorResult.category}
                                    sessionIdentifier={sessionIdentifier}
                                    factors={factors}
                                    demographics={demographics}
                                    assessment={assessmentId ? { id: assessmentId, name: assessmentName } : undefined}
                                />
                                <OutcomeBoxContainer
                                    result={sessionResults[0]}
                                    factorResultCategory={factorResult.category}
                                />
                                {demographicsRequired && demographics ? (
                                    <OutcomeFactorTableContainer
                                        result={sessionResults[0]}
                                        factors={factors}
                                        demographics={demographics}
                                    />
                                ) : (
                                    <OutcomeSliderContainer
                                        factors={factors}
                                        factorResultCategory={factorResult.category}
                                    />
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <>No result data found for session.</>
                )}
            </SessionOutcomesContentWrapper>
        </>
    );
};
