import { styled, Box } from '@mui/material';

export const LocationStateSelectWrapper = styled('div')(({ theme }) => ({
    paddingBottom: theme.spacing(0),

    '& .MuiInputLabel-outlined': {
        background: 'white',
        padding: '0 8px 4px',
    },
    '& .MuiSelect-select.MuiSelect-select:focus': {
        background: 'white',
    },
    '& .MuiSelect-icon': {
        fontSize: theme.typography.h4.fontSize,
    },
}));

export const LocationsFormWrapper = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));
