import React from 'react';
import { GaitSessionTableSubRow } from '../GaitPatient/styles';

interface SubRowProps {
    row: React.ReactNode[];
}

export const SessionSubRow = (props: SubRowProps) => {
    const { row } = props;
    return (
        <>
            {row.map((t, i) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <GaitSessionTableSubRow key={i} $borderTop={i > 0}>
                        {t}
                    </GaitSessionTableSubRow>
                );
            })}
        </>
    );
};
