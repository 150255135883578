import React from 'react';
import { SvgIcon } from '@mui/material';

export const AddIcon = (props) => {
    return (
        <>
            <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.40039 12.0004C2.40039 11.2548 3.00481 10.6504 3.75039 10.6504H20.2504C20.996 10.6504 21.6004 11.2548 21.6004 12.0004C21.6004 12.746 20.996 13.3504 20.2504 13.3504H3.75039C3.00481 13.3504 2.40039 12.746 2.40039 12.0004Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.0004 2.40039C12.746 2.40039 13.3504 3.00481 13.3504 3.75039V20.2504C13.3504 20.996 12.746 21.6004 12.0004 21.6004C11.2548 21.6004 10.6504 20.996 10.6504 20.2504V3.75039C10.6504 3.00481 11.2548 2.40039 12.0004 2.40039Z"
                    fill="currentColor"
                />
            </SvgIcon>
        </>
    );
};
