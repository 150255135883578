import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { BlobProvider, Font } from '@react-pdf/renderer';
import { useAssessmentsContext } from '@/components/context/hooks/AssessmentsContext';
import dmSansBold from '@/fonts/DMSans-Bold.ttf';
import dmSansMedium from '@/fonts/DMSans-Medium.ttf';
import { ScanReportPDFProps, ScanReportResultData } from '../types';
import { ScanAssessmentReportPDFDocument } from './ScanAssessmentReportPDFDocument';

interface ScanAssessmentReportPDFActionProps {
    chartImageMap: Map<string, string>;
    assessmentEntries: [string, Map<string, ScanReportResultData>][];
    scanReportPDFProps: ScanReportPDFProps;
}

export const ScanAssessmentReportPDFAction = (props: ScanAssessmentReportPDFActionProps) => {
    const { scanReportPDFProps } = props;
    const { title } = scanReportPDFProps;
    const { assessmentsDict } = useAssessmentsContext();

    useEffect(() => {
        Font.register({
            family: 'DM Sans',
            fonts: [{ src: dmSansMedium }, { src: dmSansBold, fontWeight: 600 }],
        });
    }, []);

    return (
        <BlobProvider document={<ScanAssessmentReportPDFDocument assessmentsDict={assessmentsDict} {...props} />}>
            {({ url, loading }) => {
                return (
                    <a href={url || undefined} download={title}>
                        <Button onClick={undefined} variant="primary" disabled={loading || !url}>
                            {loading || !url ? 'Generating PDF...' : 'Download PDF'}
                        </Button>
                    </a>
                );
            }}
        </BlobProvider>
    );
};
