import { styled, Typography, AccordionDetails as MuiAccordionDetails, Grid } from '@mui/material';
import { AccordionSummary } from './Accordion';

export const RTMCodeTitle = styled(Typography)(({ theme }) => ({
    // marginRight: theme.spacing(1.5),
}));

export const AccordionName = styled(AccordionSummary)(({ theme }) => ({
    '& .MuiAccordionSummary-content': {
        margin: 0,
        marginLeft: theme.spacing(1),
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'hidden',
    },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    '.MuiAccordionDetails-root': {
        paddingTop: theme.spacing(1.75),
        paddingBottom: theme.spacing(1.75),
    },
}));

export const CycleDetailText = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
}));

export const NotesGridWrapper = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
}));

export const StatusGridWrapper = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
}));

export const StatusAnimationInnerWrapper = styled('div')(({ theme }) => ({
    paddingLeft: 8,
}));

export const ActionGridWrapper = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
}));
