import React from 'react';
import { EmptyDataProps, EmptyPatientsData } from '@/components/common/EmptyData/EmptyData';
import { AddPatientContainer } from './AddPatient/AddPatientContainer';
import { EmptyPatientsWrapper } from './styles';

export const EmptyPatients = ({ title, body }: EmptyDataProps) => {
    return (
        <EmptyPatientsWrapper>
            <EmptyPatientsData title={title} body={body} />
            <AddPatientContainer />
        </EmptyPatientsWrapper>
    );
};
