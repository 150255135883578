import React from 'react';
import { Grow } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import { SideOfBody } from '@/common/const';
import { HEPBlock, isRepsGoal, isTimeGoal } from '@/common/types';
import { toHHMMSS } from '@/common/utils';
import { ExerciseAnimationLazy as ExerciseAnimation } from '@/components/Health/HEPBuilder/HEPBuilderExercises/ExerciseAnimationLazy';
import { useExercisesContext } from '@/components/context/hooks/ExercisesContext';
import {
    HEPBlockDetails,
    HEPBlockWrapper,
    HEPBlockImgWrapper,
    HEPBlockInfo,
    HEPBlockTitle,
    HEPBlockCaption,
} from './styles';

interface Props {
    blocks: HEPBlock[];
}
export const HEPBlocks = (props: Props) => {
    const { blocks } = props;
    const { exercisesDict } = useExercisesContext();

    return exercisesDict && blocks ? (
        <>
            <TransitionGroup>
                {blocks.map((block: HEPBlock, index: number) => {
                    return (
                        <Grow
                            key={block.id}
                            {...{ timeout: { appear: index * 250 + 250, enter: index * 250 + 250, exit: 250 } }}
                        >
                            <HEPBlockWrapper container>
                                <HEPBlockImgWrapper item xs={4} sm={2}>
                                    {exercisesDict?.[block.exerciseId]?.animationUrl ? (
                                        <ExerciseAnimation
                                            animationUrl={exercisesDict[block.exerciseId].animationUrl!}
                                            exerciseMetricId={block.exerciseId}
                                            activeFrame={exercisesDict[block.exerciseId].animationThumbnailFrame}
                                            style={{
                                                minWidth: 90,
                                                width: 90,
                                                padding: 0.5,
                                            }}
                                        />
                                    ) : (
                                        <img
                                            src={exercisesDict?.[block.exerciseId]?.thumbnailUrl}
                                            alt=""
                                            width="60px"
                                        />
                                    )}
                                </HEPBlockImgWrapper>
                                <HEPBlockInfo item xs={8} sm={10}>
                                    <HEPBlockCaption variant="caption">
                                        Exercise{' '}
                                        {(index + 1).toLocaleString('en-US', {
                                            minimumIntegerDigits: 2,
                                            useGrouping: false,
                                        })}
                                    </HEPBlockCaption>
                                    <HEPBlockTitle>
                                        {block.sideOfBody
                                            ? `${exercisesDict?.[block.exerciseId]?.name} - ${
                                                  SideOfBody[block.sideOfBody]
                                              }`
                                            : `${exercisesDict?.[block.exerciseId]?.name}`}
                                    </HEPBlockTitle>
                                    <HEPBlockDetails>
                                        {block.setGoal && (
                                            <>
                                                {isRepsGoal(block.setGoal)
                                                    ? block.setGoal.reps &&
                                                      `Repetitions: ${block.setGoal.reps} \u00a0 \u00a0`
                                                    : null}
                                                {isTimeGoal(block.setGoal)
                                                    ? `Time Goal: ${toHHMMSS(block.setGoal.time)} \u00a0 \u00a0`
                                                    : null}
                                            </>
                                        )}
                                        {block.numSets && <>Sets: {block.numSets}</>}
                                    </HEPBlockDetails>
                                </HEPBlockInfo>
                            </HEPBlockWrapper>
                        </Grow>
                    );
                })}
            </TransitionGroup>
        </>
    ) : null;
};
