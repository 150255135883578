import {
    styled,
    Pagination as MUIPagination,
    Table as MUITable,
    TableHead,
    TableCell as MUITableCell,
} from '@mui/material';
import { SortIcon } from '../Icons/SortIcon';
import { SortedIcon } from '../Icons/SortedIcon';

export const TableWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    overflow: 'auto',
}));

export const Table = styled(MUITable)(({ theme }) => ({
    width: '100%',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    color: theme.palette.grey[600],
}));

export const FullWidthTable = styled(Table)({
    minWidth: 800,
    tableLayout: 'fixed',
    width: '100%',
});

export const TableHeader = styled(TableHead)(({ theme }) => ({
    '& th': {
        fontWeight: theme.typography.fontWeightMedium,
    },
}));

export const TableHeaderCell = styled('th')<{ $isSorted: boolean; $width?: number | string }>(
    ({ theme, $isSorted, $width }) => ({
        position: 'relative',
        fontSize: 12,
        fontWeight: 'normal',
        textTransform: 'uppercase',
        color: $isSorted ? theme.palette.primary[500] : theme.palette.grey[600],
        padding: theme.spacing(2),
        textAlign: 'left',
        cursor: $isSorted ? 'pointer' : 'default',
        width: $width,
    }),
);

export const TableCell = styled(MUITableCell)(({ theme }) => ({}));

export const ColumnSortIcon = styled(SortIcon)({
    fontSize: 14,
    top: 4,
    position: 'relative',
    marginLeft: 8,
    width: '20px',
    height: '8px',
    display: 'block',
});

export const ColumnSortedIcon = styled(SortedIcon)(({ theme }) => ({
    fontSize: 14,
    top: 2,
    position: 'relative',
    marginLeft: 8,
}));

export const PaginationWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
}));

export const Pagination = styled(MUIPagination)(({ theme }) => ({}));

export const TableActionsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
}));
