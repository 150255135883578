import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Paths } from '@/common/const';
import { AccountPath } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { UserProfile } from '@/common/types';
import { isUserProfile } from '@/common/utils';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { Result } from '@/services/HttpService';
import { AccountInfo } from './AccountInfo';
import { ChangePassword } from './ChangePassword';

interface Props {
    accountPath: AccountPath;
}

export const AccountInfoContainer = (props: Props) => {
    const { accountPath } = props;
    const { httpService } = useHttpContext();
    const [userProfile, setUserProfile] = useState<UserProfile | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const res: Result<UserProfile> = await httpService.getCurrentUser();
            if (isUserProfile(res)) {
                setUserProfile(res);
            }
        })();
    }, []);

    const handleChange = (_, newValue: AccountPath) => {
        navigate(`${Paths.userAccountInfo}${newValue}`);
    };

    return !!userProfile ? (
        <>
            <PageTitle title="Your Account" />
            <Tabs value={accountPath} onChange={handleChange}>
                <Tab label="Account Info" value={AccountPath.Info} />
                <Tab label="Password" value={AccountPath.Password} />
            </Tabs>
            {accountPath == AccountPath.Info && <AccountInfo userProfile={userProfile} />}
            {accountPath == AccountPath.Password && <ChangePassword />}
        </>
    ) : null;
};
