import React, { useState } from 'react';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Grid,
    TextField,
    Box,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
} from '@mui/material';
import { useRecoilState } from 'recoil';
import { EditingPathsState } from '@/recoil/atoms/pathsAtom';
import { EditActionsProps } from '../types';
import { FormItemContainer, FormLabelContainer } from './styles';

const updateTempPaths = (paths, type, id, value) => {
    const newArr = paths.map((path) => {
        const updatedPath = path.phases.map((phase) => {
            if (phase.id === id) {
                const updatedPhase = { ...phase, [type]: value };
                return updatedPhase;
            }
            return phase;
        });

        return { ...path, phases: updatedPath };
    });

    return newArr;
};

export const TrackAction = ({ track, trackOptions, id }: { track: string; trackOptions: string[]; id: number }) => {
    const [value, setValue] = useState(track);
    const [paths, setPaths] = useRecoilState(EditingPathsState);

    const handleChange = (event) => {
        setValue(event.target.value);
        const updatedPaths = updateTempPaths(paths, 'selectedTrack', id, event.target.value);
        setPaths(updatedPaths);
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="Track-selection-label">Track</InputLabel>
                <Select
                    labelId="Track-select-label"
                    id="Track-select"
                    value={value}
                    label="Track"
                    onChange={handleChange}
                >
                    {trackOptions &&
                        trackOptions.map((t) => (
                            <MenuItem key={t} value={t}>
                                {t}
                            </MenuItem>
                        ))}

                    {/* TODO Nick what do about "Total Score" */}
                </Select>
            </FormControl>
        </Box>
    );
};

export const TypeAction = ({ type, typeOptions, id }: { type: string; typeOptions: string[]; id: number }) => {
    const [value, setValue] = useState(type);
    const [paths, setPaths] = useRecoilState(EditingPathsState);

    const handleChange = (event) => {
        setValue(event.target.value);
        const updatedPaths = updateTempPaths(paths, 'selectedType', id, event.target.value);
        setPaths(updatedPaths);
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="Type-selection-label">Type</InputLabel>
                <Select labelId="Type-select-label" id="Type-select" value={value} label="Type" onChange={handleChange}>
                    {typeOptions &&
                        typeOptions.map((t) => (
                            <MenuItem key={t} value={t}>
                                {t}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export const ValueAction = ({ number, id }: { number: string; id: number }) => {
    const [value, setValue] = useState(number);
    const [paths, setPaths] = useRecoilState(EditingPathsState);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(e.target.value) >= 0 && Number(e.target.value) <= 999) {
            setValue(e.target.value);
            const updatedPaths = updateTempPaths(paths, 'selectedNumber', id, e.target.value);
            setPaths(updatedPaths);
        }
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <TextField
                id="outlined-controlled"
                label="Number"
                value={value}
                onChange={handleChange}
                type="number"
                style={{ width: '100%' }}
            />
        </Box>
    );
};

export const LogicAction = ({ logic, logicOptions, id }: { logic: string; logicOptions: string[]; id: number }) => {
    const [value, setValue] = useState(logic);
    const [paths, setPaths] = useRecoilState(EditingPathsState);

    const handleChange = (event) => {
        setValue(event.target.value);
        const updatedPaths = updateTempPaths(paths, 'selectedLogic', id, event.target.value);
        setPaths(updatedPaths);
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="option-comparison-select-label">Logic</InputLabel>
                <Select labelId="Logic-label" id="Logic-select" value={value} label="Logic" onChange={handleChange}>
                    {logicOptions &&
                        logicOptions.map((t) => (
                            <MenuItem key={t} value={t}>
                                {t}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </Box>
    );
};
export const MessageProviderAction = ({ messageProvider, id }: { messageProvider: boolean; id: number }) => {
    const [value, setValue] = useState(messageProvider ? 'yes' : 'no');
    const [paths, setPaths] = useRecoilState(EditingPathsState);

    const handleChange = (event) => {
        setValue((event.target as HTMLInputElement).value);
        const updatedPaths = updateTempPaths(paths, 'messageProvider', id, event.target.value === 'yes' ? true : false);
        setPaths(updatedPaths);
    };

    return (
        <Box>
            <FormItemContainer>
                <FormControl>
                    <FormLabelContainer>
                        <NotificationsActiveIcon style={{ color: '#6a41f1' }} />
                        <FormLabel id="demo-row-radio-buttons-group-label">Notify Provider</FormLabel>
                    </FormLabelContainer>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>
            </FormItemContainer>
        </Box>
    );
};

export const EditActions = (props: EditActionsProps) => {
    const { track, type, number, logic, trackOptions, logicOptions, typeOptions, id, messageProvider } = props;

    return (
        <div style={{ margin: '16px 0' }}>
            <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                    <TrackAction track={track} trackOptions={trackOptions} id={id} />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <LogicAction logic={logic} logicOptions={logicOptions} id={id} />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <ValueAction number={number} id={id} />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TypeAction type={type} typeOptions={typeOptions} id={id} />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <MessageProviderAction messageProvider={messageProvider} id={id} />
                </Grid>
            </Grid>
        </div>
    );
};
