import React from 'react';
import { Products } from '@/common/const';
import { getStringEnumKeyByValue, isPROOnlyPatient } from '@/common/utils';
import { AddtoProductAction } from '../../AllPatientsTable/PatientActions/AddToProductAction/AddToProductAction';
import { usePatientContext } from '../context/hooks/PatientContext';
import { PatientDetailsCalloutWrapper } from '../styles';

export const PatientPromoteToHealth = () => {
    const { patientRecord, product } = usePatientContext();

    return patientRecord &&
        isPROOnlyPatient(patientRecord) &&
        product !== getStringEnumKeyByValue(Products, Products.GAIT) ? (
        <PatientDetailsCalloutWrapper>
            <div>
                <h3>Program</h3>
                <p>Adding patient to Exer Health will allow Programs to be assigned.</p>
            </div>
            <AddtoProductAction patientRecord={patientRecord} toAdd={Products.HEALTH} isCTA={true} />
        </PatientDetailsCalloutWrapper>
    ) : null;
};
