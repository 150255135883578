import React from 'react';
import { GaitReportsContainer } from '@/components/Gait/Reports/GaitReportsContainer';
import { RouteContentWrapper } from '../styles';

export const GaitReportsRoute = () => {
    return (
        <>
            <RouteContentWrapper>
                <GaitReportsContainer />
            </RouteContentWrapper>
        </>
    );
};
