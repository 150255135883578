import React from 'react';
import { useRecoilValue } from 'recoil';
import { orgLogoAtomState } from '@/recoil/atoms/orgLogoAtom';
import { Locations } from '../../Organization/Locations/Locations';
import { OrganizationHeader } from '../../Organization/OrganizationHeader/OrganizationHeader';
import { RouteContentWrapper } from '../styles';

export const OrganizationRoute = () => {
    const orgLogoState = useRecoilValue(orgLogoAtomState);

    return (
        <RouteContentWrapper>
            <OrganizationHeader />
            <Locations />
            {orgLogoState ? <img src={orgLogoState} key={orgLogoState} /> : null}
        </RouteContentWrapper>
    );
};
