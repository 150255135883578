import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Paths } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { UserProfileWithInviteCode } from '@/common/types';
import { FormTextField } from '@/components/common/Form/Form';
import { FormFieldContainer } from '@/components/common/Form/styles';
import { CopyIcon } from '@/components/common/Icons/CopyIcon';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { resultIsError } from '@/services/HttpService';
import { RouteContentWrapper } from '../../styles';

const shape = {
    email: yup.string().required('Email is required'),
};

export const UserSignUpLink = () => {
    const schema = yup.object().shape(shape);
    const { httpService } = useHttpContext();
    const [serverError, setServerError] = useState<object | null>();
    const [userProfile, setUserProfile] = useState<UserProfileWithInviteCode | null>();
    const { host } = window.location;

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
    });

    const resetAll = () => {
        setUserProfile(null);
        reset();
    };

    const findByUUID = async (data) => {
        setServerError(null);
        const result = await httpService.adminGetUserWithInviteCodeByEmail(data.email);
        if (resultIsError(result)) {
            setServerError({ email: { message: 'No patient found, please check the email.' } });
        } else {
            setUserProfile(result);
            return result;
        }
    };

    return (
        <RouteContentWrapper>
            <PageTitle title="Get Provider Signup Link" />
            {userProfile ? (
                <>
                    <p>
                        <strong>
                            {userProfile.firstName} {userProfile.lastName}
                        </strong>
                    </p>
                    <p>
                        <strong>Email: </strong>
                        {userProfile.email}
                    </p>
                    {userProfile.hasAccount ? (
                        <h4>This provider already has an account. The signup link below will not do anything.</h4>
                    ) : null}
                    <p>
                        <strong>Signup Link: </strong>
                        <CopyToClipboard text={`${host}${Paths.userCreateAccount}${userProfile.inviteCode}`}>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                {userProfile.hasAccount ? (
                                    <s>{`${host}${Paths.userCreateAccount}${userProfile.inviteCode}`}</s>
                                ) : (
                                    <>{`${host}${Paths.userCreateAccount}${userProfile.inviteCode}`}</>
                                )}
                            </a>
                        </CopyToClipboard>
                        {`\u00a0`}
                        {`\u00a0`}
                        <CopyToClipboard text={`${host}${Paths.userCreateAccount}${userProfile.inviteCode}`}>
                            <CopyIcon sx={{ cursor: 'pointer' }} />
                        </CopyToClipboard>
                    </p>
                    <Button onClick={resetAll} variant="primary">
                        Find Another
                    </Button>
                </>
            ) : (
                <form onSubmit={handleSubmit(findByUUID)}>
                    <FormTextField control={control} errors={serverError || errors} name="email" label="User Email" />
                    <FormFieldContainer>
                        <Button type="submit" variant="primary">
                            Find
                        </Button>
                    </FormFieldContainer>
                </form>
            )}
        </RouteContentWrapper>
    );
};
