import React from 'react';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Avatar } from '@mui/material';
import { EditActions } from './Actions/EditActions';
import {
    HEPExerciseTotalContainer,
    HEPName,
    HEPExerciseTotal,
    Dumbbell,
    PhaseContainer,
    PhaseIconContainer,
    BodyTextContainer,
    PhaseBodyContainer,
    BodyText,
    TooltipProgress,
    TargetMetric,
} from './styles';
import { PathPhase } from './types';

interface PhaseProps {
    title: string;
    exerciseTotal: string | null;
    messageTotal: string | null;
    phaseNumber: number;
    isEditing?: boolean;
    phase: PathPhase;
    percent: string;
    total: number;
    isLast?: boolean;
}

export const Phase = (props: PhaseProps) => {
    const { title, exerciseTotal, phaseNumber, isEditing, phase, percent, messageTotal } = props;

    return (
        <PhaseContainer>
            <PhaseIconContainer>
                {/* <Tooltip title={`Phase ${phaseNumber}/${total}`}> */}
                <TooltipProgress $percent={percent}>
                    <Avatar style={{ backgroundColor: '#dadce5', color: '#55575e' }}>
                        <ArrowDownwardRoundedIcon />
                    </Avatar>
                </TooltipProgress>
                {/* <Avatar style={{ backgroundColor: '#d8cdfe', color: '#55575e' }}>P{phaseNumber}</Avatar> */}
                {/* </Tooltip> */}
            </PhaseIconContainer>
            <div>
                {/* <PhaseNumber>Phase {phaseNumber}</PhaseNumber> */}
                <HEPName>{title}</HEPName>
                {exerciseTotal && (
                    <HEPExerciseTotalContainer>
                        <Dumbbell style={{ color: '#6a41f1' }} />
                        <HEPExerciseTotal color="primary">{exerciseTotal} ASSESSMENTS</HEPExerciseTotal>
                    </HEPExerciseTotalContainer>
                )}
                {messageTotal ? (
                    <HEPExerciseTotalContainer>
                        <QuestionAnswerIcon style={{ color: '#6a41f1' }} />
                        <HEPExerciseTotal color="primary">{messageTotal}</HEPExerciseTotal>
                    </HEPExerciseTotalContainer>
                ) : null}
                {phase.messageProvider && !isEditing ? (
                    <HEPExerciseTotalContainer>
                        <NotificationsActiveIcon style={{ color: '#6a41f1' }} />
                        <HEPExerciseTotal color="primary">Notify Provider</HEPExerciseTotal>
                    </HEPExerciseTotalContainer>
                ) : null}
                {!isEditing ? (
                    <PhaseBodyContainer>
                        <BodyTextContainer>
                            {/* {phaseValues.type === TypeValuesEnum.DEGREES ? (

                            )} */}
                            <BodyText>
                                {phase.selectedTrack}{' '}
                                <TargetMetric>
                                    {phase.selectedLogic} {phase.selectedNumber} {phase.selectedType}
                                </TargetMetric>
                            </BodyText>
                        </BodyTextContainer>
                        {/* {type === 'DURATION' ? (
                            <BodyTextContainer>
                                <CalendarIcon color="primary" />
                                <BodyText>
                                    Duration {currentDuration}{' '}
                                    {durationType && durationType === 'DAY'
                                        ? 'Days'
                                        : durationType === 'WEEK'
                                        ? 'Weeks'
                                        : null}
                                </BodyText>
                            </BodyTextContainer>
                        ) : null}

                        {type === 'TIME' ? (
                            <BodyTextContainer>
                                <AccessTimeRoundedIcon color="primary" />
                                <BodyText>Complete Time {currentTime} min</BodyText>
                            </BodyTextContainer>
                        ) : null}
                        {type === 'DURATION_&_TIME' ? (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <BodyTextContainer>
                                    <CalendarIcon color="primary" />
                                    <BodyText>
                                        Duration {currentDuration}{' '}
                                        {durationType && durationType === 'DAY'
                                            ? 'Days'
                                            : durationType === 'WEEK'
                                            ? 'Weeks'
                                            : null}
                                    </BodyText>
                                </BodyTextContainer>
                                <BodyOrText>or</BodyOrText>
                                <BodyTextContainer>
                                    <AccessTimeRoundedIcon color="primary" />
                                    <BodyText>Complete Time {currentTime} min</BodyText>
                                </BodyTextContainer>
                            </div>
                        ) : null} */}
                    </PhaseBodyContainer>
                ) : (
                    <EditActions
                        id={phase.id}
                        track={phase.selectedTrack}
                        number={phase.selectedNumber}
                        logic={phase.selectedLogic}
                        type={phase.selectedType}
                        trackOptions={phase.trackOptions}
                        logicOptions={phase.logicOptions}
                        typeOptions={phase.typeOptions}
                        messageProvider={phase.messageProvider}
                    />
                )}
            </div>
        </PhaseContainer>
    );
};

export const EndPhase = (props: { phaseNumber: string; showText: boolean }) => {
    const { showText } = props;

    return (
        <PhaseContainer>
            <PhaseIconContainer>
                {/* <Tooltip title={`Complete`}> */}
                <TooltipProgress $percent="100">
                    <Avatar style={{ backgroundColor: '#e6e8f0', color: '#55575e' }}>
                        <VerifiedIcon />
                    </Avatar>
                </TooltipProgress>
                {/* </Tooltip> */}
            </PhaseIconContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <PhaseNumber>Phase {phaseNumber}</PhaseNumber> */}
                {showText && <HEPName>Care Path End - Notify Provider</HEPName>}
            </div>
        </PhaseContainer>
    );
};
