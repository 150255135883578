import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { SideOfBody, BodyPart, Products } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { mixpanel } from '@/common/mixpanel';
import { CreateUpdatePatientDto, PatientRecord, PractitionerOption } from '@/common/types';
import { Location } from '@/common/types';
import { isGaitPatient, isHealthPatient, productsIncludesGait, productsIncludesPRO } from '@/common/utils';
import {
    formatUSPhoneNumber,
    getPractitionerOptions,
    getSideOfBodyChoices,
    getStringEnumKeyByValue,
    phoneNumberTest,
    productsIncludesHealth,
} from '@/common/utils';
import { DialogContentWrapper } from '@/components/common/Dialog/styles';
import {
    FormAutoCompleteField,
    FormDateField,
    FormPhoneNumberField,
    FormRadioField,
    FormTextField,
    SubmitCancelButtons,
} from '@/components/common/Form/Form';
import { ServerError } from '@/components/common/Form/styles';
import { resultIsError } from '@/services/HttpService';
import { PortalEvent } from '@/services/events/const';
import eventService from '@/services/events/eventService';
import { SubmitCancelButtonsWrapper } from './styles';

interface Props {
    patientRecord: PatientRecord;
    handleCancel: () => void;
    onSuccess?: () => void;
}

type EditPatientDto = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    location: Location;
    dob: DateTime | null | undefined;
    injuredSideOfBody: string;
    injuredBodyPart: string;
    primaryProvider: PractitionerOption | null;
    gaitUid?: string | null;
};

const healthShape = {
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Email must be valid.').required('Email is required'),
    phoneNumber: yup
        .string()
        .transform((_, val) => (val === '' ? null : val))
        .test('len', 'Phone number must be 10 digits', phoneNumberTest)
        .nullable(),
    dob: yup.date().nullable().default(undefined),
    location: yup.object().required('Location is required'),
    primaryProvider: yup.object().nullable(),
    injuredSideOfBody: yup.string().required(),
    injuredBodyPart: yup.string().required(),
    gaitUid: yup.string().nullable(),
};

const gaitShape = {
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Email must be valid.').nullable(),
    phoneNumber: yup
        .string()
        .transform((_, val) => (val === '' ? null : val))
        .test('len', 'Phone number must be 10 digits', phoneNumberTest)
        .nullable(),
    dob: yup.date().nullable().default(undefined),
    location: yup.object().required('Location is required'),
    primaryProvider: yup.object().nullable(),
    injuredSideOfBody: yup.string().nullable(),
    injuredBodyPart: yup.string().nullable(),
    gaitUid: yup.string().nullable(),
};

const proShape = {
    firstName: yup.string().nullable(),
    lastName: yup.string().nullable(),
    email: yup.string().email('Email must be valid.').nullable(),
    phoneNumber: yup
        .string()
        .test('len', 'Phone number must be 10 digits', phoneNumberTest)
        .required('Phone number is required'),
    dob: yup.date().nullable().default(undefined),
    location: yup.object().required('Location is required'),
    primaryProvider: yup.object().nullable(),
    injuredSideOfBody: yup.string().nullable(),
    injuredBodyPart: yup.string().nullable(),
    gaitUid: yup.string().nullable(),
};

export const PatientEditDialogContent = (props: Props) => {
    const { patientRecord, handleCancel, onSuccess } = props;
    const {
        id,
        patient: { firstName, lastName, email, phoneNumber, dob, hasAccount },
        location,
        products,
        productData,
        practitioner,
    } = patientRecord;
    const [locations, setLocations] = useState<Location[]>();
    const [practitioners, setPractitioners] = useState<PractitionerOption[]>();
    const [currentPractitioner, setCurrentPractitioner] = useState<PractitionerOption | undefined>(
        practitioner
            ? {
                id: practitioner.id,
                name: `${practitioner.firstName} ${practitioner.lastName}`,
            }
            : undefined,
    );
    const [serverError, setServerError] = useState<string | null>();
    const { errorToast, successToast } = toasts;
    const { httpService } = useHttpContext();

    let shape;
    switch (true) {
        case products.includes(getStringEnumKeyByValue(Products, Products.HEALTH)):
            shape = healthShape;
            break;
        case products.includes(getStringEnumKeyByValue(Products, Products.GAIT)):
            shape = gaitShape;
            break;
        case products.includes(getStringEnumKeyByValue(Products, Products.PRO)):
            shape = proShape;
            break;
        default:
            shape = healthShape;
    }
    const schema = yup.object().shape(shape);

    const { handleSubmit, control, watch } = useForm<EditPatientDto>({
        defaultValues: {
            firstName: firstName || '',
            lastName: lastName || '',
            email: email || '',
            phoneNumber: phoneNumber || '',
            dob: dob ? DateTime.fromISO(dob) : null,
            location,
            injuredSideOfBody:
                productData?.health?.injuredSideOfBody === null
                    ? SideOfBody.N_A
                    : productData?.health?.injuredSideOfBody,
            injuredBodyPart: productData?.health?.injuredBodyPart,
            primaryProvider: practitioner
                ? {
                    id: practitioner.id,
                    name: `${practitioner.firstName} ${practitioner.lastName}`,
                }
                : null,
            gaitUid: productData.gait?.uid?.toUpperCase() || null,
        },
        resolver: yupResolver(schema),
    });

    const getSetPractitioners = async () => {
        const res = await httpService.getPractitioners();
        if (!res || resultIsError(res)) {
            errorToast('Could not get providers.');
            return;
        }
        const uniquePractitioners = getPractitionerOptions(res, setCurrentPractitioner, currentPractitioner);
        setPractitioners(uniquePractitioners);
    };

    const getSetLocations = async () => {
        const res = await httpService.getLocations();
        if (resultIsError(res)) {
            return;
        }
        setLocations(res);
    };

    useEffect(() => {
        (async () => {
            await getSetLocations();
            await getSetPractitioners();
        })();
    }, []);

    const { errors } = useFormState({
        control,
    });

    const onSubmit = async (data: EditPatientDto) => {
        setServerError(null);
        const intlPhoneNumber = data.phoneNumber ? formatUSPhoneNumber(data.phoneNumber) : data.phoneNumber;
        const injuredSideOfBody =
            !data.injuredSideOfBody || data.injuredSideOfBody === SideOfBody.N_A ? null : data.injuredSideOfBody;

        const transformed: Partial<CreateUpdatePatientDto> = {
            ...data,
            dob: data.dob as string | null | undefined,
            injuredSideOfBody,
            locationId: data.location?.id,
            products,
            phoneNumber: intlPhoneNumber,
            primaryPractitionerId: data.primaryProvider?.id,
            gaitUid: data.gaitUid || null,
        };
        delete transformed.location;
        if (!transformed.email) {
            delete transformed.email;
        }
        const result = await httpService.updatePatientRecord(id, transformed);
        if (resultIsError(result)) {
            setServerError(result.message);
            return;
        }
        mixpanel.track('Edited Patient Details');
        successToast('Successfully updated patient details.');
        eventService.dispatch(PortalEvent.UPDATE_PATIENT, result);
        if (onSuccess) {
            onSuccess();
        }
    };

    const validateAndSubmit = () => {
        const requiredHealthFields = watch(['injuredSideOfBody', 'injuredBodyPart']);
        if (productsIncludesHealth(products)) {
            if (
                requiredHealthFields.some((field) => {
                    return !field;
                })
            ) {
                setServerError('You must set Side of Body and Involved Body Part for Health patients.');
                return;
            }
        }
        handleSubmit(onSubmit)();
    };
    return (
        <DialogContentWrapper>
            <form>
                <FormTextField
                    name="firstName"
                    label="First Name"
                    control={control}
                    errors={errors}
                    required={productsIncludesHealth(products) || productsIncludesGait(products)}
                />
                <FormTextField
                    name="lastName"
                    label="Last Name"
                    control={control}
                    errors={errors}
                    required={productsIncludesHealth(products) || productsIncludesGait(products)}
                />
                <FormTextField
                    name="email"
                    label="Email"
                    control={control}
                    errors={errors}
                    required={productsIncludesHealth(products)}
                    disabled={!!hasAccount}
                />
                <FormPhoneNumberField
                    control={control}
                    errors={errors}
                    name="phoneNumber"
                    label="Phone Number"
                    required={productsIncludesPRO(products)}
                />
                <FormDateField
                    control={control}
                    errors={errors}
                    name="dob"
                    label={'Date of Birth'}
                    required={false}
                    noFuture={true}
                />
                {locations && (
                    <FormAutoCompleteField
                        control={control}
                        errors={errors}
                        name="location"
                        label="Location"
                        options={locations}
                        optionLabel={(location) => location.name}
                    />
                )}
                <FormAutoCompleteField
                    control={control}
                    errors={errors}
                    name="primaryProvider"
                    label="Primary Provider"
                    options={practitioners || []}
                    optionLabel={(practitioner) => practitioner?.name}
                    clearIcon={null}
                />

                {isHealthPatient(patientRecord) ? (
                    <>
                        <FormRadioField
                            control={control}
                            errors={errors}
                            name="injuredSideOfBody"
                            label="Involved Side"
                            required={productsIncludesHealth(products)}
                            choices={getSideOfBodyChoices()}
                        />
                        <FormRadioField
                            control={control}
                            errors={errors}
                            name="injuredBodyPart"
                            label="Involved Body Part"
                            required={productsIncludesHealth(products)}
                            choices={[
                                {
                                    value: getStringEnumKeyByValue(BodyPart, BodyPart.SHOULDER),
                                    label: BodyPart.SHOULDER,
                                },
                                { value: getStringEnumKeyByValue(BodyPart, BodyPart.HIP), label: BodyPart.HIP },
                                { value: getStringEnumKeyByValue(BodyPart, BodyPart.KNEE), label: BodyPart.KNEE },
                                { value: getStringEnumKeyByValue(BodyPart, BodyPart.SPINE), label: BodyPart.SPINE },
                                {
                                    value: getStringEnumKeyByValue(BodyPart, BodyPart.MULTIPLE),
                                    label: BodyPart.MULTIPLE,
                                },
                            ]}
                        />
                    </>
                ) : null}
                {isGaitPatient(patientRecord) ? (
                    <>
                        <FormTextField
                            name="gaitUid"
                            label="Unique Gait ID"
                            control={control}
                            errors={errors}
                            required={false}
                        />
                    </>
                ) : null}
                <ServerError>{serverError || '\u00a0'}</ServerError>
                <SubmitCancelButtonsWrapper>
                    <SubmitCancelButtons submitFunction={validateAndSubmit} cancelFunction={handleCancel} />
                </SubmitCancelButtonsWrapper>
            </form>
        </DialogContentWrapper>
    );
};
