import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { Products } from '@/common/const';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { ServerError } from '@/components/common/Form/styles';
import { ProductSelectionWrapper, ProductsCheckboxesFieldGroup, ProductSelectionContent } from './styles';

interface Props {
    productsSelected: string[];
    handleProductSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
    productsError?: string | null;
    confirmProductChoices: () => void;
    handleCancel: () => void;
}
export const AddPatientProductSelection = (props: Props) => {
    const { productsSelected, handleProductSelect, productsError, confirmProductChoices, handleCancel } = props;

    return (
        <ProductSelectionWrapper>
            <ProductSelectionContent>
                <p>Select the products to which you are adding the patient.</p>
                <ProductsCheckboxesFieldGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={Products.HEALTH}
                                checked={productsSelected && productsSelected.includes(Products.HEALTH)}
                                onChange={handleProductSelect}
                            />
                        }
                        label={`Exer ${Products.HEALTH}`}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={Products.GAIT}
                                checked={productsSelected && productsSelected.includes(Products.GAIT)}
                                onChange={handleProductSelect}
                            />
                        }
                        label={`Exer ${Products.GAIT}`}
                    />
                </ProductsCheckboxesFieldGroup>
                <ServerError>{productsError || '\u00a0 '}</ServerError>
            </ProductSelectionContent>
            <SubmitCancelButtons
                submitFunction={confirmProductChoices}
                cancelFunction={handleCancel}
                submitText="Next"
            />
        </ProductSelectionWrapper>
    );
};
