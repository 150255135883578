import React, { useEffect, useState, ChangeEvent } from 'react';
import { DateTime } from 'luxon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { HealthPatientPath, healthPatientPaths } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { mixpanel } from '@/common/mixpanel';
import { PatientInteractionList, Session } from '@/common/types';
import { isPROOnlyPatient, saturateSessionWithExerciseNames, sortBySessionDate } from '@/common/utils';
import { ROMDataDict } from '@/components/Health/PatientSessions/Charts/types';
import { AGGREGATION, getROMChartData, filterROMDict } from '@/components/Health/PatientSessions/Charts/utils';
import { getPROChartData } from '@/components/Health/PatientSessions/PROs/utils';
import { usePatientContext } from '@/components/Patients/Patient/context/hooks/PatientContext';
import { PatientWrapper } from '@/components/Patients/styles';
import { EmptyData } from '@/components/common/EmptyData/EmptyData';
import { useExercisesContext } from '@/components/context/hooks/ExercisesContext';
import { useHEPContext } from '@/components/context/hooks/HEPContext';
import { DateRangeState } from '@/recoil/atoms/dateRangeAtom';
import { selfReportedFilterState } from '@/recoil/atoms/healthSessionsAtom';
import { interactionsState } from '@/recoil/atoms/interactionsAtom';
import { DateRangeSelector } from '@/recoil/selectors/dateRangeSelector';
import { resultIsError } from '@/services/HttpService';
import { AssignHepAction } from '../HealthPatientActions/AssignHEPAction/AssignHepAction';
import { PROChartDataDict } from '../PatientSessions/PROs/types';
import { FocusedModal } from './FocusedModal';
import { PatientTabs } from './Tabs/PatientTabs';
import { EmptyPatientHr, PatientWithNoHEPWrapper } from './styles';

interface HealthPatientProps {
    openBuilder: () => void;
}

export const HealthPatient = (props: HealthPatientProps) => {
    const { openBuilder } = props;
    const { exercisesDict } = useExercisesContext();
    const {
        healthSessions: sessions,
        setHealthSessions,
        patientRecord,
        proAnswersByQuestionsDict,
        setPROAnswersByQuestionsDict,
    } = usePatientContext();
    const { program } = useHEPContext();
    const [dateRange] = useRecoilState(DateRangeState);
    const { startDateMillis, endDateMillis } = useRecoilValue(DateRangeSelector);
    const setPatientInteractions = useSetRecoilState(interactionsState);
    const [selfReportingFilter, setSelfReportingFilter] = useRecoilState(selfReportedFilterState);
    const { httpService } = useHttpContext();
    const [hasFetched, setHasFetched] = useState<boolean>(false);
    const [romDictData, setRomDictData] = useState<ROMDataDict>();
    const [romDictDataFiltered, setRomDictDataFiltered] = useState<ROMDataDict>();
    const [proChartDataDict, setPROChartDataDict] = useState<PROChartDataDict>();
    const { patientSection } = useParams<{ patientSection: string }>();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [currentChart, setCurrentChart] = useState<string>('');
    const [isLoadingPROMessages, setIsLoadingPROMessages] = useState(true);

    const getInitTab = () => {
        switch (true) {
            case !patientSection:
                navigate(`${pathname}/${HealthPatientPath.activity}`, { replace: true });
                return 0;
            case patientSection === HealthPatientPath.activity:
                return 0;
            case patientSection === HealthPatientPath.rom:
                return 1;
            case patientSection === HealthPatientPath.pro:
                return 2;
            case patientSection === HealthPatientPath.care:
                return 3;
            default:
                return 0;
        }
    };
    const [currentTab, setCurrentTab] = useState(getInitTab());

    const handleTabChange = (event: ChangeEvent<HTMLButtonElement>, newValue: number) => {
        mixpanel.track(`User viewed ${event.target?.id}`, { $current_url: '' });
        setCurrentTab(newValue);
        navigate(`${pathname}/../${healthPatientPaths[newValue]}`, { replace: true });
    };

    const handleChartSelection = ({ optionName, optionId }: { optionName: string; optionId: string }) => {
        setCurrentChart(optionId);
    };

    useEffect(() => {
        (async () => {
            const res = (await httpService.getHealthPatientSessions(
                patientRecord.id,
                selfReportingFilter,
            )) as Session[];
            setHasFetched(true);
            if (!res || resultIsError(res)) {
                return;
            }
            if (exercisesDict) {
                const sortedSessions = sortBySessionDate(res);
                const sessionsWithExerciseNames = saturateSessionWithExerciseNames(sortedSessions, exercisesDict);
                setHealthSessions(sessionsWithExerciseNames);
                const romDict = getROMChartData(sessionsWithExerciseNames, AGGREGATION.SINGLE_MAX_RECORD_OF_DAY);
                setRomDictData(romDict);
                if (Object.keys(romDict).length) {
                    setCurrentChart(romDict[Object.keys(romDict)[0]].exerciseId);
                }
            }
        })();
    }, [exercisesDict, selfReportingFilter]);

    useEffect(() => {
        setPatientInteractions([]);

        return () => {
            setSelfReportingFilter(true);
        };
    }, []);

    useEffect(() => {
        (async () => {
            const res = (await httpService.getAllPatientInteractions(patientRecord.id)) as PatientInteractionList;
            if (res && !resultIsError(res)) {
                setPatientInteractions(res);
            }
        })();

        return () => setPatientInteractions([]);
    }, []);

    useEffect(() => {
        if (romDictData) {
            const filteredROMDict = filterROMDict(romDictData, startDateMillis, endDateMillis);

            setRomDictDataFiltered(filteredROMDict);

            if (
                Object.keys(filteredROMDict).length &&
                !Object.entries(filteredROMDict).some((e) => e[1].exerciseId === currentChart)
            ) {
                setCurrentChart(filteredROMDict[Object.keys(filteredROMDict)[0]].exerciseId);
            }
        }
    }, [dateRange, romDictData]);

    useEffect(() => {
        (async () => {
            if (patientRecord.patient.phoneNumber) {
                const res = await httpService.getPROPatientMessages(patientRecord.id);
                setIsLoadingPROMessages(false);
                if (res && !resultIsError(res)) {
                    const proDict = getPROChartData(res);
                    setPROChartDataDict(proDict);
                    setPROAnswersByQuestionsDict(res);
                }
            }
        })();
    }, [patientRecord.patient.phoneNumber]);

    const proExistsInRange = () => {
        return (
            proAnswersByQuestionsDict &&
            Object.values(proAnswersByQuestionsDict).some((q) => {
                const found = q.answers.find((a) => {
                    const processedDate = DateTime.fromISO(a.dateTime).startOf('day').toMillis();
                    return processedDate >= startDateMillis && processedDate <= endDateMillis;
                });
                return !!found;
            })
        );
    };

    const hasSessions = (): boolean => {
        return !!sessions && sessions.length > 0;
    };

    const hasPROs = (): boolean => {
        return proChartDataDict && Object.keys(proChartDataDict).length > 0 ? true : false;
    };

    return (
        <PatientWrapper>
            {!program && !hasSessions() && !isPROOnlyPatient(patientRecord) && !hasPROs() ? (
                <PatientWithNoHEPWrapper>
                    <EmptyPatientHr />
                    <EmptyData
                        title="Assign your patient’s first Program (Program)"
                        body={`${patientRecord?.patient?.firstName} will receive an email invite to create an Exer Health account when they are assigned a Program.`}
                    />
                    <AssignHepAction openBuilder={openBuilder} text="Assign Program" />
                </PatientWithNoHEPWrapper>
            ) : (
                hasFetched && (
                    <>
                        <PatientTabs
                            currentTab={currentTab}
                            handleTabChange={handleTabChange}
                            patientRecord={patientRecord}
                            isLoadingPROMessages={isLoadingPROMessages}
                            inModal={false}
                            dateRange={dateRange}
                            hasFetched={hasFetched}
                            sessions={sessions}
                            hasSessions={hasSessions}
                            romDictDataFiltered={romDictDataFiltered}
                            handleChartSelection={handleChartSelection}
                            currentChart={currentChart}
                            proChartDataDict={proChartDataDict}
                            proExistsInRange={proExistsInRange}
                        />
                    </>
                )
            )}

            {hasFetched && (
                <FocusedModal title={`${patientRecord?.patient?.firstName} ${patientRecord?.patient?.lastName}`}>
                    <PatientTabs
                        currentTab={currentTab}
                        handleTabChange={handleTabChange}
                        patientRecord={patientRecord}
                        isLoadingPROMessages={isLoadingPROMessages}
                        inModal
                        dateRange={dateRange}
                        hasFetched={hasFetched}
                        sessions={sessions}
                        hasSessions={hasSessions}
                        romDictDataFiltered={romDictDataFiltered}
                        handleChartSelection={handleChartSelection}
                        currentChart={currentChart}
                        proChartDataDict={proChartDataDict}
                        proExistsInRange={proExistsInRange}
                    />
                </FocusedModal>
            )}
        </PatientWrapper>
    );
};
