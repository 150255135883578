import {
    Dialog as MUIDialog,
    DialogContent as MUIDialogContent,
    DialogTitle as MUIDialogTitle,
    styled,
} from '@mui/material';

export const Dialog = styled(MUIDialog)(({ theme }) => ({
    minWidth: '1000px',
    '.MuiDialogContent-root': {
        paddingTop: `${theme.spacing(3)}!important`,
    },
}));

export const DialogTitle = styled(MUIDialogTitle)(({ theme }) => ({
    fontSize: theme.typography.h3.fontSize,
}));

export const DialogContent = styled(MUIDialogContent)(({ theme }) => ({
    minWidth: '1100px',
}));

export const JSONCode = styled('pre')(({ theme }) => ({
    margin: 0,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    height: '96%',
    background: '#28282b',
    color: '#9197ff',
}));
