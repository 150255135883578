import { DateTime } from 'luxon';
import { GaitSessionVersion } from '@/common/const';
import { GaitSession, GaitSessionV1DataItem, GaitSessionV2DataName } from '@/common/types';
import { precisionRound } from '@/common/utils';

type GaitChart = {
    [name in GaitSessionV1DataItem]: {
        name: string;
        sessionSchema: string;
        id: string;
        chart: {
            labels: number[];
            datasets: [
                {
                    label: string;
                    data: number[]; // in milliseconds
                    borderColor: string;
                    backgroundColor: string;
                },
            ];
        };
    };
};

const checkIfMeasureShouldBeIncluded = (measureName: GaitSessionV2DataName) => {
    return /(%)|(avgTime)|(lapOneTime)|(lapTwoTime)|(distance)|(stepDifference)|(strideDifference)|(flexionDifference)|(extensionDifference)/g.test(
        measureName,
    );
};

const getChartLabelColor = (measureName: string) => {
    if (/Left/.test(measureName) && /Step|Stride|Flexion/.test(measureName)) {
        return 'legendLeftMeasureLeft';
    }
    if (/Right/.test(measureName) && /Step|Stride|Flexion/.test(measureName)) {
        return 'legendLeftMeasureRight';
    }
    if (/Left/.test(measureName) && /Extension|Dorsiflexion/.test(measureName)) {
        return 'legendRightMeasureLeft';
    }
    if (/Right/.test(measureName) && /Extension|Dorsiflexion/.test(measureName)) {
        return 'legendRightMeasureRight';
    }
};

const setChartColor = (measureName: string) => {
    switch (measureName) {
        case 'legendLeftMeasureLeft':
            return {
                borderColor: '#f441cd',
                pointHoverBackgroundColor: '#f441cd',
                backgroundColor: '#f441cd',
            };
        case 'legendLeftMeasureRight':
            return {
                borderDash: [6, 10],
                borderColor: '#f441cd',
                pointHoverBackgroundColor: '#f441cd',
                backgroundColor: '#f441cd',
            };
        case 'legendRightMeasureLeft':
            return {
                borderColor: '#07df7f',
                pointHoverBackgroundColor: '#07df7f',
                backgroundColor: '#07df7f',
            };
        case 'legendRightMeasureRight':
            return {
                borderDash: [6, 10],
                borderColor: '#07df7f',
                pointHoverBackgroundColor: '#07df7f',
                backgroundColor: '#07df7f',
            };
        default:
            return {
                borderColor: '#f441cd',
                pointHoverBackgroundColor: '#f441cd',
                backgroundColor: '#f441cd',
            };
    }
};

export const getGaitChartData = (sessions: GaitSession[]): GaitChart => {
    const gaitChartData: GaitChart = {} as GaitChart;

    const labels: number[] = [];
    const dataSets: {
        label: string;
        data: number[];
        pointBackgroundColor: string;
        borderWidth: number;
        pointBorderWidth: number;
        pointRadius: number;
        pointHoverRadius: number;
    }[] = [];
    sessions.map((session: GaitSession) => {
        const endDateMillis = DateTime.fromJSDate(new Date(session.session.meta.capturedDate))
            .startOf('day')
            .toMillis();
        if (!labels.includes(endDateMillis)) {
            labels.push(endDateMillis);
        }
        Object.keys(session.session.data).map((dataIndex) => {
            if (session.sessionSchema !== GaitSessionVersion.GAIT_SA_2) return;
            gaitChartData[session.session.data[dataIndex].item] = {
                name: session.session.data[dataIndex].item,
                id: session.session.data[dataIndex].item,
                chart: {
                    labels: [...labels],
                    datasets: [],
                },
            };

            Object.keys(session.session.data[dataIndex].measures).map((measuresIndex) => {
                const sessionMeasurement = session.session.data[dataIndex].measures[measuresIndex];
                const hiddenMeasurements = checkIfMeasureShouldBeIncluded(sessionMeasurement.name);
                if (hiddenMeasurements) return;
                const chartLabelColor = getChartLabelColor(sessionMeasurement.displayName);
                const chartColor = chartLabelColor ? setChartColor(chartLabelColor) : {};
                const dataSet = {
                    label: sessionMeasurement.displayName,
                    data: [] as number[],
                    pointBackgroundColor: '#fff',
                    borderWidth: 1.5,
                    pointBorderWidth: 2,
                    pointRadius: 4,
                    pointHoverRadius: 6,
                    ...chartColor,
                };
                dataSets.push(dataSet);
                gaitChartData[session.session.data[dataIndex].item].chart.datasets.push(dataSet);
            });
        });
    });

    sessions.forEach((s: GaitSession) => {
        const date = s.session.meta.capturedDate && new Date(s.session.meta.capturedDate);
        if (date && s.sessionSchema === GaitSessionVersion.GAIT_SA_2) {
            Object.keys(s.session.data).map((dataIndex) => {
                Object.keys(s.session.data[dataIndex].measures).map((m) => {
                    const hiddenMeasurements = checkIfMeasureShouldBeIncluded(
                        s.session.data[dataIndex].measures[m].name,
                    );
                    if (hiddenMeasurements) return;

                    gaitChartData[s.session.data[dataIndex].item].chart.datasets.some((dataset) => {
                        if (s.session.data[dataIndex].measures[m].displayName === dataset.label) {
                            const decimals = dataset.label?.toLowerCase().includes('speed') ? 2 : 1;
                            dataset.data.unshift(
                                precisionRound(Number(s.session.data[dataIndex].measures[m].value), decimals),
                            );
                        }
                    });
                });
            });
        }
    });
    return gaitChartData;
};
