import React, { useState, useEffect } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Slide, Dialog, IconButton } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import { useRecoilState } from 'recoil';
import { ZoomControls } from '@/components/common/ZoomControls/ZoomControls';
import { FocusModalState } from '@/recoil/atoms/focusModalAtom';

interface FocusedModalProps {
    title: string;
    children: JSX.Element;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const FocusedModal = (props: FocusedModalProps) => {
    const { title, children } = props;
    const htmlTag = document.querySelector('html');
    const [fontSize, setFontSize] = useState(100);
    const [modal, setModal] = useRecoilState(FocusModalState);
    const { isModalOpen } = modal;

    const handleClose = () => {
        setModal({ isModalOpen: false });
        if (htmlTag) {
            htmlTag.style.fontSize = `100%`;
            setFontSize(100);
        }
    };

    useEffect(() => {
        if (htmlTag && isModalOpen) {
            setFontSize(110);
        }
    }, [isModalOpen]);

    return (
        <>
            <Dialog
                open={isModalOpen}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
                keepMounted
                fullScreen
            >
                <DialogTitle
                    sx={{
                        boxShadow: 3,
                        position: 'relative',
                        zIndex: 9,
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <span>{title}</span>
                    <IconButton onClick={handleClose}>
                        <CloseRoundedIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {htmlTag && (
                        <ZoomControls
                            target={htmlTag}
                            fontSize={fontSize}
                            setFontSize={setFontSize}
                            open={isModalOpen}
                        />
                    )}
                    <>{children}</>
                </DialogContent>
            </Dialog>
        </>
    );
};
