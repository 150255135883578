import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { RTMStatusEnum } from '@/common/types';
import { MultiSelectFilter } from '@/components/common/Table/Filters/MultiSelectFilter';
import { usePaginationContext } from '@/components/context/hooks/PaginationContext';
import { RTMLoadingStatus, RTMState } from '@/recoil/atoms/rtmAtom';
import { RTMSelector } from '@/recoil/selectors/rtmSelector';

export const StatusFilterActions = () => {
    const [rtmState, setRtmState] = useRecoilState(RTMState);
    const { filteredStatusList } = useRecoilValue(RTMSelector);
    const { page, setPage } = usePaginationContext();

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (page !== 1) {
            setPage(1);
        }
        const selectedItems = {
            ...rtmState,
            filters: {
                ...rtmState.filters,
                statusList: { ...rtmState.filters.statusList, [event.target.name]: event.target.checked },
            },
            loadCycles: RTMLoadingStatus.RELOAD,
        };
        setRtmState(selectedItems);
    };

    const handleClearSelection = () => {
        if (page !== 1) {
            setPage(1);
        }
        const selectedItems = {
            ...rtmState,
            filters: {
                ...rtmState.filters,
                statusList: {
                    IN_PROGRESS: false,
                    READY_TO_BILL: false,
                    BILLED: false,
                    ACTIVE_DAYS_INCOMPLETE: false,
                },
            },
            loadCycles: RTMLoadingStatus.RELOAD,
        };
        setRtmState(selectedItems);
    };

    return (
        <MultiSelectFilter
            selected={filteredStatusList}
            options={Object.keys(RTMStatusEnum).map((status) => {
                return { id: status, name: RTMStatusEnum[status] };
            })}
            handleRadioChange={handleRadioChange}
            handleClearSelection={handleClearSelection}
            label="Statuses"
            prefix="date-range-"
        />
    );
};
