import ReactPDF from '@react-pdf/renderer';
import { convertPxToPt } from '@/components/Health/PatientSessions/SessionsPDF/SessionsPDFUtils';

const baseStyles: ReactPDF.Styles = {
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexCol: {
        display: 'flex',
        flexDirection: 'column',
    },
};

export const participantReportPDFStyles: ReactPDF.Styles = {
    page: {
        ...baseStyles.flexCol,
        fontFamily: 'DM Sans',
        backgroundColor: '#fff',
        flex: 1,
        flexBasis: 'auto',
        height: '90%',
        padding: `${convertPxToPt(24)} ${convertPxToPt(27)} ${convertPxToPt(20)}`,
    },
    flexCol: {
        ...baseStyles.flexCol,
    },
    flexRow: {
        ...baseStyles.flexRow,
    },
    pageTitle: {
        fontSize: convertPxToPt(20),
        marginBottom: convertPxToPt(4),
    },
    pageSubTitle: {
        fontSize: convertPxToPt(16),
        marginBottom: convertPxToPt(16),
    },
    assessmentCard: {
        padding: convertPxToPt(16),
        marginBottom: convertPxToPt(16),
        paddingBottom: convertPxToPt(16),
        border: `1px solid #aaaaaa`,
        borderRadius: convertPxToPt(4),
    },
    assessmentHeader: {
        fontSize: convertPxToPt(16),
        marginBottom: convertPxToPt(12),
        fontWeight: 600,
    },
    resultList: {
        marginTop: convertPxToPt(10),
    },
    result: {},
    resultText: {
        fontSize: convertPxToPt(14),
    },
    resultName: {
        fontWeight: 600,
    },
};
