import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useExerUser } from '@/common/hooks/ExerUser';
import { Organization } from '@/common/types';
import { Modal } from '@/components/common/Modal/Modal';
import { Button } from '@/components/common/button/button';
import { AddOrganizationForm } from './AddOrganizationForm';

export const AddOrganizationContainer = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const { user } = useExerUser();
    const [currentStep, setCurrentStep] = useState(1);
    const [org, setOrg] = useState<Organization>();

    const handleCreateButtonClick = (_: React.MouseEvent) => {
        setDialogOpen(true);
    };

    const handleCancel = () => {
        setDialogOpen(false);
    };

    return (
        <div>
            <Button onClick={handleCreateButtonClick} variant="primary" startIcon={<AddIcon />} size="small">
                Add New Organization
            </Button>
            <Modal
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                title={currentStep === 1 ? 'Add New Organization' : `Upload image for ${org?.name}`}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <AddOrganizationForm
                    cancel={handleCancel}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    org={org}
                    setOrg={setOrg}
                />
            </Modal>
        </div>
    );
};
