import { Box, styled } from '@mui/material';

export const Center = styled('div')({
    textAlign: 'center',
});

export const SemiStrong = styled('strong')({
    fontWeight: 500,
});

export const MobileLabel = styled(Box)({
    fontWeight: 700,
});
