import { styled, Card, Avatar, CardHeader } from '@mui/material';

export const EmptyDashboardCard = styled(Card)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    flexDirection: 'column',

    '.MuiCardActions-root': {
        justifyContent: 'flex-end',
    },

    '.MuiCardContent-root': {
        color: theme.palette.grey[600],
        paddingTop: '0',
    },
}));

export const EmptyDashboardCardHeader = styled(CardHeader)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    '.MuiCardHeader-title': {
        fontSize: theme.typography.h5.fontSize,
        color: theme.palette.grey[900],
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: theme.spacing(3),
    },
}));

export const EmptyDashboardAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary[500],
    fontSize: theme.spacing(3.5),
    width: theme.spacing(8),
    height: theme.spacing(8),
}));
