import React from 'react';
import { Button } from '@mui/material';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { useModalContext } from '@/components/context/hooks/ModalContext';
import { PatientEditDialogContent } from '../../AllPatientsTable/PatientActions/PatientActionsDialogContent/PatientEditDialogContent';
import { usePatientContext } from '../context/hooks/PatientContext';

export const PatientDetailsEditAction = () => {
    const { patientRecord } = usePatientContext();
    const { setDialogOpen, setDialog } = useModalContext();

    const handleEdit = (e: React.MouseEvent) => {
        e.preventDefault();
        setDialog(
            'Edit Patient Details',
            <PatientEditDialogContent
                patientRecord={patientRecord}
                handleCancel={() => {
                    setDialogOpen(false);
                }}
                onSuccess={() => {
                    setDialogOpen(false);
                }}
            />,
        );
        setDialogOpen(true);
    };

    return (
        <Button variant="tertiary" size="small" startIcon={<EditIcon />} onClick={handleEdit}>
            Edit
        </Button>
    );
};
