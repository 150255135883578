import { ToastContainer as ToastifyContainer } from 'react-toastify';
import styled from 'styled-components';

export const ToastContainer = styled(ToastifyContainer)(({ theme }) => ({
    '&&&.Toastify__toast-container--top-right': {
        top: theme.spacing(4),
    },

    '&&& .Toastify__toast--success': {
        color: theme.palette.success[800],
        backgroundColor: theme.palette.success[100],
        border: `1px solid ${theme.palette.success[600]}`,
        boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.08)',
        minHeight: theme.spacing(7.25),

        '.Toastify__toast-icon': {
            color: theme.palette.success[500],
        },
    },

    '&&& .Toastify__toast--error': {
        color: theme.palette.error[900],
        backgroundColor: theme.palette.error[100],
        border: `1px solid ${theme.palette.error[600]}`,
        boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.08)',
        minHeight: theme.spacing(7.25),

        '.Toastify__toast-icon': {
            color: theme.palette.error[600],
        },
    },

    '&&& .Toastify__toast--warning': {
        color: theme.palette.warning[800],
        backgroundColor: theme.palette.warning[100],
        border: `1px solid ${theme.palette.warning[600]}`,
        boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.08)',
        minHeight: theme.spacing(7.25),

        '.Toastify__toast-icon': {
            color: theme.palette.warning[600],

            '.MuiSvgIcon-root': {
                fontSize: '18px',
            },
        },
    },

    '&&& .Toastify__toast--info': {
        color: theme.palette.info[700],
        backgroundColor: theme.palette.info[100],
        border: `1px solid ${theme.palette.info[600]}`,
        boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.08)',
        minHeight: theme.spacing(7.25),

        '.Toastify__toast-icon': {
            color: theme.palette.info[500],
        },
    },

    '.Toastify__toast-body': {
        color: 'currentColor',
        fontSize: '14px',
        fontFamily: 'DM Sans',
        fontWeight: '500',
    },

    '.Toastify__toast-icon': {
        color: 'currentColor',

        '.MuiSvgIcon-root': {
            fontSize: '16px',
        },
    },

    '.Toastify__close-button': {
        color: theme.palette.grey[600],
        opacity: 1,
        alignSelf: 'center',
    },
}));
