import { Accordion, AccordionDetails, AccordionSummary, IconButton, styled, Grid } from '@mui/material';
import { TableCell } from '@/components/common/Table/styles';

export const PatientGaitSessionTableWrapper = styled('div')(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,

    'th:nth-child(1n + 2), td:nth-child(1n + 2)': {
        // borderLeft: `1px solid ${theme.palette.grey[300]}`,
        boxShadow: `-1px 0px 0px 0 ${theme.palette.grey[300]}`,
        textAlign: 'center',
    },
    th: {
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        color: theme.palette.grey[600],
        fontWeight: theme.typography.fontWeightMedium,
    },
    td: {
        padding: '0',
    },
    'td:nth-child(1n + 2) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export const GaitSessionTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightRegular,
}));

export const GaitSessionTableContent = styled('div')<{ $percentDifferenceOfAverage?: boolean }>(
    ({ theme, $percentDifferenceOfAverage }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: $percentDifferenceOfAverage ? '100px' : '50px',
    }),
);

export const GaitSessionTableAssessmentLabel = styled('div')(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    height: '50px',
    display: 'flex',
    alignItems: 'center',
}));

export const GaitSessionTableSubRow = styled('div')<{ $borderTop: boolean }>(({ theme, $borderTop }) => ({
    boxShadow: $borderTop ? `0px -1px 0px 0 ${theme.palette.grey[300]}` : '0',
}));

export const PatientGaitSessionTableRefreshWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: '0',
    padding: '8px',
}));

export const PatientGaitSessionTableRefreshButton = styled(IconButton)(({ theme }) => ({}));

export const SessionTime = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(2),
    color: theme.palette.grey[600],
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
}));

export const SessionTitle = styled('h3')(({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[900],
}));

export const SessionExpansionPanelSummaryHeader = styled('div')(({ theme }) => ({
    width: '100%',
}));

export const SessionExpansionPanelSummaryHeaderTop = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        flexDirection: 'row',
    },
}));

export const SessionHeaderTopRight = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'center',
        flexBasis: '270px',
        marginBottom: '0',
    },
}));

export const SessionExpansionPanel = styled(Accordion)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    '&:before': {
        display: 'none',
    },
    '&.Mui-expanded': {
        margin: '0',
    },
}));

export const SessionExpansionPanelSummary = styled(AccordionSummary)(({ theme }) => ({
    flexDirection: 'row-reverse',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: '0',
    transition: '.5s ease background',
    '&:last-of-kind': {
        borderBottom: '0',
    },
    '&:hover': {
        backgroundColor: theme.palette.grey[50],
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        fontSize: theme.typography.h5.fontSize,
        marginRight: theme.spacing(1.5),
    },
    '& .MuiAccordionSummary-content': {
        margin: '0',
    },
    '&.Mui-expanded': {
        margin: '0',
        minHeight: 'unset',
        borderBottom: `1px solid ${theme.palette.grey[500]}`,
    },
}));

export const SessionExpansionPanelDetails = styled(AccordionDetails)(({ theme }) => ({
    display: 'block',
    padding: `${theme.spacing(2)} 0`,

    '& hr': {
        outline: 'none',
        border: 0,
        borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
}));

export const GaitTableFooterContainer = styled(Grid)(({ theme }) => ({
    padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
}));

export const GaitTableFooterLeft = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
}));

export const GaitTableFooterRight = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    marginLeft: '-1px',
    boxShadow: `1px 0px 0px 0px ${theme.palette.grey[50]}`,
}));

export const GaitTableFooterItem = styled(Grid)(({ theme }) => ({
    color: theme.palette.grey[600],
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    span: {
        display: 'inline-block',
        lineHeight: 1.4,
    },
    p: {
        color: theme.palette.grey[900],
        marginLeft: theme.spacing(0.5),
        lineHeight: 1.4,
    },
}));

export const GaitTableWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0`,
    overflow: 'auto',
}));
