import React, { SyntheticEvent, useEffect, useState } from 'react';
import { isFuture, format } from 'date-fns';
import { DischargeOrPocEnum } from '@/common/const';
import { Program, TemplateOrHEP } from '@/common/types';
import {
    formatPhoneNumber,
    isPROOnlyPatient,
    patientNameFromRecord,
    productsIncludesHealth,
    productsIncludesPRO,
    dischargeOrPoc,
    getEndDate,
} from '@/common/utils';
import { HEPBuilderModal } from '@/components/Health/HEPBuilder/HepBuilderModal/HEPBuilderModal';
import { BuilderActions } from '@/components/Health/HEPBuilder/state/HEPBuilderContext';
import { SessionsReportAction } from '@/components/Health/PatientSessions/SessionsReport/SessionsReportAction';
import { DischargeAction } from '@/components/Patients/AllPatientsTable/PatientActions/DischargeAction/DischargeAction';
import { useHEPContext } from '@/components/context/hooks/HEPContext';
import { EditPlanOfCareAction } from '../AllPatientsTable/PatientActions/PlanOfCareActions/EditPlanOfCareAction';
import { NewPlanOfCareAction } from '../AllPatientsTable/PatientActions/PlanOfCareActions/NewPlanOfCareAction';
import {
    PatientHeaderLeft,
    PatientInfoTitle,
    PatientSubHeaderLeft,
    PatientWrapper,
    PatientInfoTitleContainer,
    PatientInvolvedTitleContainer,
} from '../styles';
import { PatientDetails } from './PatientDetails';
import { PatientDetailsEditAction } from './PatientDetailsActions/PatientDetailsEditAction';
import { PatientHEP } from './PatientHEP/PatientHEP';
import { PatientProductSelectionTabs } from './PatientProductSelectionTabs/PatientProductSelectionTabs';
import { PatientPromoteToHealth } from './PatientPromoteToHealth/PatientPromoteToHealth';
import { usePatientContext } from './context/hooks/PatientContext';
import { PatientHeader, PatientHeaderRight, PatientTitleContainer } from './styles';

export const Patient = () => {
    const { patientRecord, hepBuilderDialogOpen, setHEPBuilderDialogOpen, product } = usePatientContext();
    const { plansOfCare, products } = patientRecord;
    const { setHEP } = useHEPContext();
    const [endDate, setEndDate] = useState<Date | null>(getEndDate(plansOfCare));
    const [currentAction, setCurrentAction] = useState<`${DischargeOrPocEnum}` | undefined>(
        DischargeOrPocEnum.DISCHARGE,
    );

    const handleHEPBuilderOpen = () => {
        setHEPBuilderDialogOpen(true);
    };

    const handleHEPBuilderClose = (hepOrEvent: (Program & SyntheticEvent) | null) => {
        const program = !hepOrEvent || hepOrEvent?.currentTarget ? undefined : (hepOrEvent as Program);
        if (program) {
            setHEP(program);
        }
        setHEPBuilderDialogOpen(false);
    };

    const updateEndDate = (d: Date) => {
        setEndDate(d);
    };

    useEffect(() => {
        setEndDate(getEndDate(plansOfCare));
    }, [plansOfCare]);

    useEffect(() => {
        setCurrentAction(dischargeOrPoc(endDate));
    }, [endDate]);

    return (
        <PatientWrapper>
            <PatientProductSelectionTabs />
            <PatientHeader>
                <PatientHeaderLeft>
                    <PatientTitleContainer>
                        <h1>{patientNameFromRecord(patientRecord)}</h1>
                    </PatientTitleContainer>
                    <PatientSubHeaderLeft>
                        {patientRecord.patient?.email && (
                            <PatientInfoTitle variant="subtitle1" $isEmail={true}>
                                {patientRecord.patient?.email}
                            </PatientInfoTitle>
                        )}
                        {patientRecord.patient?.phoneNumber && (
                            <PatientInfoTitle variant="subtitle1">
                                Phone: {'\u00a0'}
                                <span>{formatPhoneNumber(patientRecord.patient.phoneNumber)}</span>
                            </PatientInfoTitle>
                        )}

                        {product !== 'GAIT' && (productsIncludesHealth(products) || productsIncludesPRO(products)) && (
                            <>
                                {currentAction === DischargeOrPocEnum.POC && endDate && (
                                    <PatientInfoTitle variant="subtitle1">
                                        Scheduled to be discharged: {'\u00a0'}
                                        {format(endDate, 'MM/dd/yy')}
                                    </PatientInfoTitle>
                                )}
                                {currentAction === DischargeOrPocEnum.DISCHARGE && endDate && (
                                    <PatientInfoTitle variant="subtitle1">
                                        Discharged: {'\u00a0'}
                                        {format(endDate, 'MM/dd/yy')}
                                    </PatientInfoTitle>
                                )}
                            </>
                        )}
                    </PatientSubHeaderLeft>
                    <PatientSubHeaderLeft>
                        <PatientInfoTitleContainer>
                            <PatientInfoTitle variant="subtitle1">
                                Primary Provider:{'\u00a0'}
                                <strong>{`${patientRecord.practitioner?.firstName} ${patientRecord.practitioner?.lastName}`}</strong>
                            </PatientInfoTitle>
                            {patientRecord.location && (
                                <PatientInfoTitle variant="subtitle1">
                                    Location:{'\u00a0'}
                                    <strong>{patientRecord.location?.name}</strong>
                                </PatientInfoTitle>
                            )}
                        </PatientInfoTitleContainer>
                        <PatientInvolvedTitleContainer>
                            {patientRecord.productData?.health?.injuredSideOfBody && (
                                <PatientInfoTitle variant="subtitle1">
                                    Involved Side:{'\u00a0'}
                                    <strong>
                                        {patientRecord.productData?.health?.injuredSideOfBody?.toLowerCase()}
                                    </strong>
                                </PatientInfoTitle>
                            )}
                            {patientRecord.productData?.health?.injuredBodyPart && (
                                <PatientInfoTitle variant="subtitle1">
                                    Involved Joint:{'\u00a0'}
                                    <strong>{patientRecord.productData?.health?.injuredBodyPart?.toLowerCase()}</strong>
                                </PatientInfoTitle>
                            )}
                            {patientRecord.productData?.gait?.uid && (
                                <PatientInfoTitle variant="subtitle1">
                                    Unique ID:{'\u00a0'}
                                    <strong>{patientRecord.productData.gait.uid.toUpperCase()}</strong>
                                </PatientInfoTitle>
                            )}
                        </PatientInvolvedTitleContainer>
                    </PatientSubHeaderLeft>
                </PatientHeaderLeft>
                <PatientHeaderRight>
                    <PatientDetailsEditAction />
                    <SessionsReportAction />
                    {product !== 'GAIT' && (productsIncludesHealth(products) || productsIncludesPRO(products)) && (
                        <>
                            {!endDate && plansOfCare.length > 0 && (
                                <DischargeAction patientRecord={patientRecord} setEndDate={updateEndDate} isCTA />
                            )}
                            {endDate && isFuture(endDate) && (
                                <EditPlanOfCareAction
                                    patientRecord={patientRecord}
                                    endDate={endDate}
                                    setEndDate={updateEndDate}
                                />
                            )}
                            {endDate && !isFuture(endDate) && (
                                <NewPlanOfCareAction patientRecord={patientRecord} lastEndDate={endDate} />
                            )}
                        </>
                    )}
                </PatientHeaderRight>
            </PatientHeader>
            {isPROOnlyPatient(patientRecord) ? <PatientPromoteToHealth /> : <PatientHEP />}

            <PatientDetails openBuilder={handleHEPBuilderOpen} />
            <HEPBuilderModal
                open={hepBuilderDialogOpen}
                onClose={handleHEPBuilderClose}
                actionType={BuilderActions.ASSIGN}
                patientRecord={patientRecord}
                isTemplateOrHEP={TemplateOrHEP.Program}
            />
        </PatientWrapper>
    );
};
