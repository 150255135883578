import React from 'react';
import { Assessment, SessionResult, SessionResultDict } from '@exerai/vision-engine-web';
import { StyleSheet, Text, View, Document, Page } from '@react-pdf/renderer';
import { DateTime } from 'luxon';
import { ExerPDFHeader } from '@/common/pdf/ExerPDFHeader';
import { Maybe } from '@/common/types';
import { pdfStyles } from './pdfStyles';
import { SessionParticipant } from './types';

interface SessionResultsPDFDocumentProps {
    results: SessionResultDict;
    assessment: Assessment;
    participant: SessionParticipant;
    organizationImageUrl: Maybe<string>;
    capturedDate: string;
}

export const SessionResultsPDFDocument = (props: SessionResultsPDFDocumentProps) => {
    const { results, assessment, participant, organizationImageUrl, capturedDate } = props;
    const sessionDate = DateTime.fromISO(capturedDate).toFormat('L/dd/yyyy');

    const styles = StyleSheet.create(pdfStyles);
    const resultRows: SessionResult[][] = [];
    Object.values(results as SessionResult[]).forEach((result, i) => {
        const topIndex = Math.floor(i / 2);
        const t = resultRows[topIndex] || [];
        t.push(result);
        resultRows[topIndex] = t;
    });
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <ExerPDFHeader organizationLogoPrintUrl={organizationImageUrl} />
                <View style={styles.subHeaderWrapper}>
                    <View style={styles.subHeaderRow}>
                        <View style={styles.subHeaderTextWrapper}>
                            <Text style={styles.bold}>Participant ID: </Text>
                            <Text>{participant.uid}</Text>
                        </View>
                        <View style={styles.subHeaderTextWrapper}>
                            <Text style={styles.bold}>Provider: </Text>
                            <Text>{participant.practitionerName}</Text>
                        </View>
                    </View>
                    <View style={[styles.subHeaderRow, {}]}>
                        <View style={styles.subHeaderTextWrapper}>
                            <Text style={styles.bold}>Note: </Text>
                            <Text style={{ color: '#ffffff', borderBottom: '1px solid #999999' }}>
                                {' '}
                                ________________________
                            </Text>
                        </View>
                        <View style={[styles.subHeaderTextWrapper]}>
                            <Text style={styles.bold}>Date: </Text>
                            <Text> {sessionDate}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.sectionHeader}>
                    <Text>{assessment.name}</Text>
                </View>
                <View style={styles.sectionText}>
                    <Text>{assessment.purpose}</Text>
                </View>
                {resultRows.map((resultRow, i) => {
                    return (
                        <View key={resultRow[0].id} style={styles.resultRow}>
                            {resultRow.map((result, i) => {
                                return (
                                    <View
                                        key={result.id}
                                        style={[
                                            styles.resultBox,
                                            { marginRight: i === 0 && resultRow.length > 1 ? 12 : 0 },
                                        ]}
                                    >
                                        <Text style={styles.resultHeader}>{result.name}</Text>
                                        <View style={styles.result}>
                                            <Text style={styles.resultValue}>{result.value?.toFixed(2)}</Text>
                                            <Text
                                                style={[
                                                    styles.resultUnit,
                                                    { fontSize: result.units === '°' ? 40 : 24 },
                                                ]}
                                            >
                                                {result.units}
                                            </Text>
                                        </View>
                                    </View>
                                );
                            })}
                        </View>
                    );
                })}
            </Page>
        </Document>
    );
};
