import React from 'react';
import { Grid } from '@mui/material';
import { ExerciseGroupedSet } from '@/common/types';
import { ExerciseContent } from '@/components/Health/PatientSessions/styles';
import { SetInfoTable } from '../SetInfoTable/SetInfoTable';
import { ExerciseSetWrapper, ExerciseSetTitle } from './styles';

interface Props {
    exerciseSet: ExerciseGroupedSet;
}
export const ExerciseRepsSet = (props: Props) => {
    const { exerciseSet } = props;

    let totalReps = 0;
    let completeReps = 0;
    let setsStarted = 0;
    const setInfoTables = exerciseSet.sets.map((set, i) => {
        totalReps += set.setInfo.repsGoal ? set.setInfo.repsGoal : 0;
        completeReps += set.setInfo.repsComplete ? set.setInfo.repsComplete : 0;
        setsStarted += (set.setInfo.repsComplete || 0) > 0 ? 1 : 0;
        // eslint-disable-next-line react/no-array-index-key
        return <SetInfoTable set={set} i={i} key={i} />;
    });

    return (
        <ExerciseSetWrapper>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <ExerciseSetTitle>Set</ExerciseSetTitle>
                </Grid>
                <Grid item xs={4}>
                    <ExerciseSetTitle>Reps</ExerciseSetTitle>
                </Grid>
                <Grid item xs={4}>
                    <ExerciseSetTitle>Errors</ExerciseSetTitle>
                </Grid>
            </Grid>
            <ExerciseContent>
                <>{setInfoTables}</>
            </ExerciseContent>
        </ExerciseSetWrapper>
    );
};
