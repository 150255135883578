import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, FormControl, MenuItem } from '@mui/material';
import { ProviderRoles } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { mixpanel } from '@/common/mixpanel';
import { Practitioner, ROLES, Location } from '@/common/types';
import { resultIsError } from '@/services/HttpService';
import { MultiSelectField } from './MultiSelectField/MultiSelectField';
import {
    PractitionerEditRoleSelect,
    PractitionerEmailText,
    PractitionerRoleActionsGrid,
    PractitionerRowEmail,
    PractitionerRowRole,
} from './styles';

interface Props {
    practitioner: Practitioner;
    handleCancelClick: (_: React.MouseEvent) => void;
    onSubmit: (email: string, locationIds: number[], role?: ROLES) => void;
    locations?: Location[];
    selectedLocations: number[];
    setSelectedLocations: Dispatch<SetStateAction<number[]>>;
}

export const PractitionerEditForm = (props: Props) => {
    const { practitioner, handleCancelClick, onSubmit, locations, selectedLocations, setSelectedLocations } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { errorToast } = toasts;
    const { httpService } = useHttpContext();
    const [role, setRole] = useState(practitioner.role || practitioner.addedAsRole);
    const [email, setEmail] = useState(practitioner.email);

    const handleRoleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRole(e.target.value as ROLES);
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentSelectedLocations = selectedLocations;
        if (event.target.checked) {
            setSelectedLocations([...currentSelectedLocations, Number(event.target.name)]);
        } else {
            const index = selectedLocations.indexOf(Number(event.target.name));
            if (index > -1) {
                currentSelectedLocations.splice(index, 1);
            }
            setSelectedLocations([...currentSelectedLocations]);
        }
    };

    const handleClearSelection = () => {
        setSelectedLocations([]);
    };

    const handleSelectAll = () => {
        if (!locations) return;
        const allLocations = locations.map((l) => {
            return l.id!;
        });
        setSelectedLocations([...allLocations]);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };
    const accountCreated = !!practitioner.userId;

    const handleSaveClick = async () => {
        setIsSubmitting(true);
        const res = await httpService.updatePractitioner(practitioner.id, {
            role,
            email,
            locationIds: selectedLocations,
        });
        setIsSubmitting(false);
        if (!res || resultIsError(res)) {
            errorToast('Could not update practitioner.');
        } else {
            const selectedLocationIds = res.locations
                ? res.locations.map((l) => {
                    return l.id;
                })
                : [];
            onSubmit(email, selectedLocationIds, role);
            mixpanel.track(`User updated a provider in the portal.`);
        }
    };

    return (
        <>
            <PractitionerRowEmail item xs={12} lg={4}>
                <PractitionerEmailText
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    disabled={accountCreated}
                />
            </PractitionerRowEmail>
            <PractitionerRowRole item xs={12} lg={1.5}>
                <FormControl variant="outlined" disabled={!accountCreated}>
                    <PractitionerEditRoleSelect onChange={handleRoleSelectChange} value={role}>
                        {Object.keys(ProviderRoles).map((key) => {
                            return (
                                <MenuItem key={key} value={key}>
                                    <div>
                                        <dt>{ProviderRoles[key]}</dt>
                                        <dd>
                                            {ProviderRoles[key] === ProviderRoles.PORTAL_PROVIDER_ADMIN
                                                ? `Access and administrative controls of the Organization. Can invite others and manage patients.`
                                                : `Ability to manage patients.`}
                                        </dd>
                                    </div>
                                </MenuItem>
                            );
                        })}
                    </PractitionerEditRoleSelect>
                </FormControl>
            </PractitionerRowRole>
            <PractitionerRowRole item xs={12} lg={3}>
                {locations && (
                    <MultiSelectField
                        label="locations"
                        prefix="locations-"
                        options={locations}
                        selected={selectedLocations}
                        handleRadioChange={handleRadioChange}
                        handleClearSelection={handleClearSelection}
                        handleSelectAll={handleSelectAll}
                    />
                )}
            </PractitionerRowRole>
            <PractitionerRoleActionsGrid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
            >
                <Button variant="secondary" disabled={isSubmitting} onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button disabled={isSubmitting} onClick={handleSaveClick}>
                    Save
                </Button>
            </PractitionerRoleActionsGrid>
        </>
    );
};
