import * as React from 'react';
import { SvgIcon } from '@mui/material';

export const ProgramTemplateLibraryIcon = (props) => (
    <SvgIcon viewBox="191.61 91.99 816.8 1016.02" {...props}>
        <g>
            <path
                d="m978.52 91.992h-757.03c-7.9258 0-15.527 3.1484-21.129 8.7539-5.6055 5.6016-8.7539 13.203-8.7539 21.129v956.25c0 7.9258 3.1484 15.527 8.7539 21.129 5.6016 5.6055 13.203 8.7539 21.129 8.7539h757.03c7.9258 0 15.527-3.1484 21.129-8.7539 5.6055-5.6016 8.7539-13.203 8.7539-21.129v-956.25c0-7.9258-3.1484-15.527-8.7539-21.129-5.6016-5.6055-13.203-8.7539-21.129-8.7539zm-29.883 956.25h-697.27v-896.48h697.27z"
                fill="currentcolor"
            />
            <path
                d="m829.1 888.87h-79.688c-10.676 0-20.543 5.6953-25.879 14.941-5.3398 9.2461-5.3398 20.637 0 29.883 5.3359 9.2461 15.203 14.941 25.879 14.941h79.688c10.676 0 20.543-5.6953 25.879-14.941 5.3398-9.2461 5.3398-20.637 0-29.883-5.3359-9.2461-15.203-14.941-25.879-14.941z"
                fill="currentcolor"
            />
            <path
                d="m370.9 948.63h239.06c10.676 0 20.543-5.6953 25.879-14.941 5.3398-9.2461 5.3398-20.637 0-29.883-5.3359-9.2461-15.203-14.941-25.879-14.941h-239.06c-10.676 0-20.543 5.6953-25.879 14.941-5.3398 9.2461-5.3398 20.637 0 29.883 5.3359 9.2461 15.203 14.941 25.879 14.941z"
                fill="currentcolor"
            />
            <path
                d="m829.1 769.34h-79.688c-10.676 0-20.543 5.6953-25.879 14.941-5.3398 9.2461-5.3398 20.637 0 29.883 5.3359 9.2461 15.203 14.941 25.879 14.941h79.688c10.676 0 20.543-5.6953 25.879-14.941 5.3398-9.2461 5.3398-20.637 0-29.883-5.3359-9.2461-15.203-14.941-25.879-14.941z"
                fill="currentcolor"
            />
            <path
                d="m370.9 829.1h239.06c10.676 0 20.543-5.6953 25.879-14.941 5.3398-9.2461 5.3398-20.637 0-29.883-5.3359-9.2461-15.203-14.941-25.879-14.941h-239.06c-10.676 0-20.543 5.6953-25.879 14.941-5.3398 9.2461-5.3398 20.637 0 29.883 5.3359 9.2461 15.203 14.941 25.879 14.941z"
                fill="currentcolor"
            />
            <path
                d="m829.1 649.8h-79.688c-10.676 0-20.543 5.6953-25.879 14.941-5.3398 9.2461-5.3398 20.637 0 29.883 5.3359 9.2461 15.203 14.941 25.879 14.941h79.688c10.676 0 20.543-5.6953 25.879-14.941 5.3398-9.2461 5.3398-20.637 0-29.883-5.3359-9.2461-15.203-14.941-25.879-14.941z"
                fill="currentcolor"
            />
            <path
                d="m370.9 709.57h239.06c10.676 0 20.543-5.6953 25.879-14.941 5.3398-9.2461 5.3398-20.637 0-29.883-5.3359-9.2461-15.203-14.941-25.879-14.941h-239.06c-10.676 0-20.543 5.6953-25.879 14.941-5.3398 9.2461-5.3398 20.637 0 29.883 5.3359 9.2461 15.203 14.941 25.879 14.941z"
                fill="currentcolor"
            />
            <path
                d="m370.9 550.2h119.53c7.9258 0 15.527-3.1484 21.129-8.7539 5.6055-5.6016 8.7539-13.203 8.7539-21.129v-89.648h159.38v89.648c0 7.9258 3.1484 15.527 8.7539 21.129 5.6016 5.6055 13.203 8.7539 21.129 8.7539h119.53c7.9258 0 15.527-3.1484 21.129-8.7539 5.6055-5.6016 8.7539-13.203 8.7539-21.129v-239.06c0-7.9258-3.1484-15.527-8.7539-21.129-5.6016-5.6055-13.203-8.7539-21.129-8.7539h-119.53c-7.9258 0-15.527 3.1484-21.129 8.7539-5.6055 5.6016-8.7539 13.203-8.7539 21.129v89.648h-159.38v-89.648c0-7.9258-3.1484-15.527-8.7539-21.129-5.6016-5.6055-13.203-8.7539-21.129-8.7539h-119.53c-7.9258 0-15.527 3.1484-21.129 8.7539-5.6055 5.6016-8.7539 13.203-8.7539 21.129v239.06c0 7.9258 3.1484 15.527 8.7539 21.129 5.6016 5.6055 13.203 8.7539 21.129 8.7539zm368.55-239.06h59.766v179.3h-59.766zm-338.67 0h59.766v179.3h-59.766z"
                fill="currentcolor"
            />
        </g>
    </SvgIcon>
);
