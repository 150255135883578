import React from 'react';
import { Grid } from '@mui/material';
import { SelfReportedEnum } from '@/common/const';
import { ExerciseSetInfo, ReportingMetric, SetResolution } from '@/common/types';
import { toHHMMSS } from '@/common/utils';
import { SelfReportedTooltip } from '../SelfReportedTooltip';
import { SetInfoTableWrapper, SetInfoTableRow, SetInfoTableItemLight, SetInfoTableItemDark } from './styles';

interface Props {
    set: ExerciseSetInfo;
    i: number;
    swapLeftRight?: boolean;
}
export const SetInfoTable = (props: Props) => {
    const { set, i, swapLeftRight } = props;
    const { setInfo } = set;
    const errorCount = setInfo.errors ? Object.keys(setInfo.errors).length : 0;
    const isNotROM = setInfo.reportingMetric !== ReportingMetric.ROM;
    const errors = setInfo.errors
        ? Object.keys(setInfo.errors).map((key) => {
              return (
                  <SetInfoTableRow key={key}>
                      <div>{setInfo.errors?.[key].message}</div>
                      {isNotROM && (
                          <div>
                              <SetInfoTableItemLight>
                                  ({setInfo.errors?.[key].count}/{setInfo.repsGoal} REPS)
                              </SetInfoTableItemLight>
                          </div>
                      )}
                  </SetInfoTableRow>
              );
          })
        : null;

    const leftValue = swapLeftRight ? setInfo.maxROM : setInfo.minROM;
    const rightValue = swapLeftRight ? setInfo.minROM : setInfo.maxROM;
    return (
        <SetInfoTableWrapper>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    {leftValue !== undefined ? (
                        <SetInfoTableRow>
                            <div>
                                {setInfo.resolution === 'SUCCESS' ||
                                (setInfo.resolution === 'SKIPPED_IN_SET' && leftValue && leftValue > 0) ||
                                setInfo.resolution === 'SELF_REPORTED' ? (
                                    `${leftValue !== undefined ? Math.floor(leftValue) : '-'}°`
                                ) : (
                                    <SetInfoTableItemLight>
                                        {setInfo.resolution ? SetResolution[setInfo.resolution] : ''}
                                    </SetInfoTableItemLight>
                                )}
                            </div>
                        </SetInfoTableRow>
                    ) : setInfo.repsGoal && setInfo.timeGoal ? (
                        <SetInfoTableItemDark>{setInfo.repsComplete}</SetInfoTableItemDark>
                    ) : (
                        <div>
                            {setInfo.reportingMetric === ReportingMetric.ROM && setInfo.resolution === 'NOT_STARTED'
                                ? SetResolution[setInfo.resolution]
                                : i + 1}
                            <SelfReportedTooltip
                                selfReportedStatus={
                                    setInfo.resolution === 'SELF_REPORTED'
                                        ? SelfReportedEnum.FULL
                                        : SelfReportedEnum.NONE
                                }
                            />
                        </div>
                    )}
                </Grid>
                <Grid item xs={4}>
                    {setInfo.resolution === 'SUCCESS' ||
                    (setInfo.resolution === 'SKIPPED_IN_SET' && rightValue && rightValue > 0) ||
                    setInfo.resolution === 'SELF_REPORTED' ? (
                        <>
                            {rightValue !== undefined && (
                                <SetInfoTableRow>
                                    <div>{rightValue !== undefined ? Math.floor(rightValue) : '-'}°</div>
                                </SetInfoTableRow>
                            )}
                            {setInfo.reportingMetric === ReportingMetric.REPS_OR_TIME && (
                                <>
                                    <SetInfoTableRow>
                                        <SetInfoTableItemDark>{toHHMMSS(setInfo.timeComplete)}</SetInfoTableItemDark>
                                    </SetInfoTableRow>
                                </>
                            )}
                            {setInfo.reportingMetric === ReportingMetric.REPS && (
                                <>
                                    <SetInfoTableRow>
                                        <SetInfoTableItemDark>{setInfo.repsComplete}</SetInfoTableItemDark>
                                        <SetInfoTableItemLight>/{setInfo.repsGoal}</SetInfoTableItemLight>
                                    </SetInfoTableRow>
                                </>
                            )}
                            {setInfo.reportingMetric === ReportingMetric.TIME && (
                                <>
                                    <SetInfoTableRow>
                                        <SetInfoTableItemDark>{toHHMMSS(setInfo.timeComplete)}</SetInfoTableItemDark>
                                        <SetInfoTableItemLight>/{toHHMMSS(setInfo.timeGoal)}</SetInfoTableItemLight>
                                    </SetInfoTableRow>
                                </>
                            )}
                        </>
                    ) : (
                        <SetInfoTableItemLight>
                            {setInfo.resolution ? SetResolution[setInfo.resolution] : ''}
                        </SetInfoTableItemLight>
                    )}
                </Grid>
                <Grid item xs={4}>
                    {errorCount > 0 ? <>{errors}</> : <SetInfoTableRow>-</SetInfoTableRow>}
                </Grid>
            </Grid>
        </SetInfoTableWrapper>
    );
};
