import React, { useState, MouseEvent, useEffect } from 'react';
import { Collapse, List, ListItemText, TooltipProps, styled, Tooltip, tooltipClasses, Zoom } from '@mui/material';
import { matchPath, useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useMuiUtils } from '@/common/hooks/MuiUtils';
import { sidebarNavState } from '@/recoil/atoms/sidebarNavAtom';
import {
    ChildSidebarNavItemWrapper,
    ActiveItemIcon,
    SidebarNavList,
    NavListItemButton,
    NavListItemIcon,
} from './styles';

const MenuTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.grey[900],
        boxShadow: theme.shadows[4],
        fontSize: 11,
    },
    [`& .MuiTooltip-arrow::before`]: {
        backgroundColor: theme.palette.grey[900],
    },
}));

interface SidebarNavItemProps {
    title: string;
    to: string | null;
    onClick?: (e: MouseEvent<HTMLDivElement>) => void;
    children?: JSX.Element | JSX.Element[];
    icon?: JSX.Element | JSX.Element[];
    isChild?: boolean;
}

export const SidebarNavItem = (props: SidebarNavItemProps) => {
    const { icon, title, to, children, onClick, isChild } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const { mediumBreakpoint } = useMuiUtils();
    const [sidebarNav, setSidebarNav] = useRecoilState(sidebarNavState);
    const { isOpen } = sidebarNav;

    const active = to
        ? !!matchPath(location.pathname, to) || (!!matchPath(`${to}*`, location.pathname) && !matchPath(`${to}*`, '/'))
        : false;
    const [open, setOpen] = useState(active);

    const go = () => {
        to && navigate(to);
    };

    const handleExpandClick = () => {
        setOpen(!open);
    };
    useEffect(() => {
        if (!isOpen) {
            setOpen(false);
        } else {
            if (active) setOpen(true);
        }
    }, [isOpen]);

    useEffect(() => {
        if (mediumBreakpoint) {
            setSidebarNav({ isOpen: true });
        }
    }, [mediumBreakpoint]);

    return (
        <SidebarNavList $isActive={active} $hasChildren={children}>
            {!isOpen && children && !mediumBreakpoint ? (
                <MenuTooltip
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 200 }}
                    title={
                        <>
                            {children ? (
                                <List component="div" disablePadding>
                                    <div>{children}</div>
                                </List>
                            ) : (
                                <p style={{ color: 'tomato' }}>{title}</p>
                            )}
                        </>
                    }
                    placement="right"
                    arrow
                >
                    <NavListItemButton
                        onClick={(e) => {
                            if (children) {
                                handleExpandClick();
                            } else {
                                if (onClick) {
                                    onClick(e);
                                } else {
                                    go();
                                }
                            }
                        }}
                        $isActive={active}
                        selected={active}
                    >
                        {active && !children && isOpen && <ActiveItemIcon />}
                        {active && !isOpen && !isChild && <ActiveItemIcon />}
                        {icon ? <NavListItemIcon>{icon}</NavListItemIcon> : null}
                        {isOpen ? <ListItemText primary={title} /> : null}
                    </NavListItemButton>
                </MenuTooltip>
            ) : (
                <MenuTooltip
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 200 }}
                    title={<p style={{ color: '#fff', fontSize: 14, padding: '0 1rem' }}>{title}</p>}
                    placement="right"
                    arrow
                    disableHoverListener={(!!children && isOpen) || isChild || isOpen || mediumBreakpoint}
                >
                    <NavListItemButton
                        onClick={(e) => {
                            if (children) {
                                handleExpandClick();
                            } else {
                                if (onClick) {
                                    onClick(e);
                                } else {
                                    go();
                                }
                            }
                        }}
                        $isActive={active}
                        selected={active}
                    >
                        {active && !children && isOpen && <ActiveItemIcon />}
                        {active && !isOpen && !isChild && <ActiveItemIcon />}
                        {icon ? <NavListItemIcon>{icon}</NavListItemIcon> : null}
                        {(isOpen || isChild || mediumBreakpoint) && <ListItemText primary={title} />}
                    </NavListItemButton>
                </MenuTooltip>
            )}
            {children && isOpen && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ChildSidebarNavItemWrapper>{children}</ChildSidebarNavItemWrapper>
                    </List>
                </Collapse>
            )}
        </SidebarNavList>
    );
};
