import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DndContext } from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, IconButton, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Assessment, SortableScanModule } from '@/common/types';
import { AddIcon } from '@/components/common/Icons/AddIcon';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { TrashIcon } from '@/components/common/Icons/TrashIcon';
import { Button } from '@/components/common/button/button';
import { SortableAssessmentCard } from '../Assessments/Assessment';
import { ModuleModal } from './ModuleModal';
import { ModuleCardContainer, TitleLockIcon } from './styles';

export const ModuleCard = ({
    module,
    handleSaveModule,
    handleAddAssessment,
    handleEditAssessment,
    setModules,
    handleDeleteAssessment,
    handleDeleteModule,
    setHasUnsavedChanges,
    isGrabbing,
}: {
    module: SortableScanModule;
    handleSaveModule: (updatedModule: SortableScanModule, oldModuleName: string) => void;
    handleAddAssessment: (module: SortableScanModule) => void;
    setModules: Dispatch<SetStateAction<SortableScanModule[]>>;
    handleEditAssessment: (assessment: Assessment) => void;
    handleDeleteAssessment: (module: SortableScanModule, assessmentId: string) => void;
    handleDeleteModule: (moduleId: string) => void;
    setHasUnsavedChanges: Dispatch<SetStateAction<boolean>>;
    isGrabbing: boolean;
}) => {
    const { name, assessments, imageUrl, subtitle, isLocked } = module;
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [wasOpenBeforeGrab, setWasOpenBeforeGrab] = useState(false);

    useEffect(() => {
        if (isGrabbing) {
            setWasOpenBeforeGrab(isOpen);
            setIsExpanded(false);
        } else {
            setIsExpanded(wasOpenBeforeGrab);
        }
    }, [isGrabbing]);

    const handleCloseModuleEdit = () => {
        setIsOpen(false);
    };

    const saveModule = (newModule: SortableScanModule, oldModuleName: string) => {
        handleSaveModule(newModule, oldModuleName);
        setIsOpen(false);
    };

    const handleEditClick = () => {
        setIsOpen(true);
    };

    const handleEditAssessmentClick = (assessment: Assessment) => {
        handleEditAssessment(assessment);
    };

    const handleDeleteClick = (assessmentId: string) => {
        handleDeleteAssessment(module, assessmentId);
    };

    const handleDragEnd = ({ active, over }) => {
        if (!over) return;
        if (active.id !== over.id) {
            const oldIndex = assessments.findIndex((assessment) => assessment.id === active.id);
            const newIndex = assessments.findIndex((assessment) => assessment.id === over.id);
            const newAssessments = arrayMove(assessments, oldIndex, newIndex);
            setModules((prevModules) =>
                prevModules.map((mod) => (mod.id === module.id ? { ...mod, assessments: newAssessments } : mod)),
            );
            setHasUnsavedChanges(true);
        }
    };

    return (
        <>
            <ModuleCardContainer key={name}>
                <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
                    <AccordionSummary
                        expandIcon={
                            <ExpandMoreIcon
                                style={{
                                    fontSize: 34,
                                }}
                            />
                        }
                        aria-controls={`${name}-content`}
                        id={`${name}-header`}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: 16,
                                }}
                            >
                                {imageUrl && (
                                    <Box
                                        style={{
                                            marginRight: 18,
                                        }}
                                    >
                                        <img src={imageUrl} alt="" width={50} height={50} />
                                    </Box>
                                )}
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography variant="h2">{name}</Typography>
                                        {isLocked && <TitleLockIcon />}
                                    </Box>
                                    <Typography variant="h5">{subtitle}</Typography>
                                </Box>
                            </Box>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton onClick={handleEditClick}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteModule(module.id)}>
                                    <TrashIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <DndContext onDragEnd={handleDragEnd} modifiers={[restrictToWindowEdges]}>
                                <SortableContext items={assessments.map((assessment) => assessment.id)}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            rowGap: '16px',
                                        }}
                                    >
                                        {assessments.length > 0 ? (
                                            assessments.map((assessment) => (
                                                <SortableAssessmentCard
                                                    key={assessment.id}
                                                    assessment={assessment}
                                                    handleEditClick={handleEditAssessmentClick}
                                                    handleDeleteClick={handleDeleteClick}
                                                />
                                            ))
                                        ) : (
                                            <Typography>No assessments have been added yet</Typography>
                                        )}
                                    </div>
                                </SortableContext>
                            </DndContext>
                            <Button
                                variant="tertiary"
                                onClick={() => handleAddAssessment(module)}
                                style={{ marginTop: 24, alignSelf: 'flex-end' }}
                                size="small"
                                startIcon={<AddIcon />}
                            >
                                Add Assessment
                            </Button>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </ModuleCardContainer>
            <Box>
                <ModuleModal
                    open={isOpen}
                    onClose={handleCloseModuleEdit}
                    onSave={saveModule}
                    type="EDIT"
                    module={module}
                />
            </Box>
        </>
    );
};
