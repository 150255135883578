import React, { useState, useEffect } from 'react';
import { DialogActions, TextField, Box, Typography } from '@mui/material';
import { Nullable, SortableScanAssessment, SortableScanModule } from '@/common/types';
import { Button } from '@/components/common/button/button';
import { Dialog, DialogContent, DialogTitle } from '../Modules/styles';

export const EditAssessmentModal = ({
    open,
    onClose,
    onSave,
    module,
    assessment,
}: {
    open: boolean;
    onClose: () => void;
    onSave: (updatedModule: SortableScanModule) => void;
    module: SortableScanModule;
    assessment: SortableScanAssessment;
}) => {
    const [assessmentNameOverride, setAssessmentNameOverride] = useState<Nullable<string>>(null);

    useEffect(() => {
        if (assessment.nameOverride) {
            setAssessmentNameOverride(assessment.nameOverride);
        }
    }, [assessment]);

    const handleAssessmentNameOverrideChange = (event) => {
        if (event.target.value.trim().length) {
            setAssessmentNameOverride(event.target.value);
        } else {
            setAssessmentNameOverride(null);
        }
    };

    const handleSave = () => {
        const updatedModule = {
            ...module,
            assessments: module.assessments.map((moduleAssessment) => {
                return moduleAssessment.id === assessment.id
                    ? { ...moduleAssessment, nameOverride: assessmentNameOverride }
                    : moduleAssessment;
            }),
        };
        onSave(updatedModule);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit Assessment</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    label="Assessment Name Override"
                    type="text"
                    fullWidth
                    value={assessmentNameOverride}
                    onChange={handleAssessmentNameOverrideChange}
                />
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexDirection: 'row',
                        background: '#fff',
                        borderRadius: '12px',
                        marginTop: '16px',
                    }}
                >
                    <img src={assessment.thumbnailUrl} width={50} height={50} alt={assessment.name} />
                    <Typography variant="h6">{assessment.name}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="tertiary">
                    Cancel
                </Button>
                <Button onClick={handleSave} variant="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
