import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { PatientFocusIcon } from '@/components/common/Icons/PatientFocusIcon';
import { FocusModalState } from '@/recoil/atoms/focusModalAtom';

export const PatientFocusAction = () => {
    const setIsOpen = useSetRecoilState(FocusModalState);
    const handleClick = () => setIsOpen({ isModalOpen: true });
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Tooltip title="Patient View">
            <IconButton
                size="medium"
                onClick={handleClick}
                color="primary"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <PatientFocusIcon hovered={isHovered ? isHovered : null} />
            </IconButton>
        </Tooltip>
    );
};
