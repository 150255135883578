import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { StatesRegions } from '@/common/StatesRegions';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { mixpanel } from '@/common/mixpanel';
import { CreateLocationDto, Location, Practitioner } from '@/common/types';
import {
    FormAutoCompleteField,
    FormTextField,
    SaveSaveAnotherCancelButtons,
    SubmitCancelButtons,
} from '@/components/common/Form/Form';
import { FormFieldContainer, ServerError, SuccessMessage } from '@/components/common/Form/styles';
import { resultIsError } from '@/services/HttpService';
import { newLocation } from '../AddLocation/AddLocation';
import { LocationStateSelectWrapper, LocationsFormWrapper } from './styles';

interface Props {
    action: 'create' | 'update';
    location: Location;
    handleCancel: (_: any) => void;
    scrollTarget?: any;
    practitioners?: Practitioner[];
}

const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    timezone: yup.string().required('Timezone is required'),
    address1: yup.string().nullable(),
    address2: yup.string().nullable(),
    city: yup.string().nullable(),
    stateCode: yup.string().nullable(),
    zipCode: yup.string().nullable(),
    defaultProviderId: yup.number().nullable(),
});

export const LocationForm = (props: Props) => {
    const {
        action,
        location: { id, name, address1, address2, city, stateCode, zipCode, defaultProvider, timezone },
        handleCancel,
        scrollTarget,
        practitioners,
    } = props;

    const [serverError, setServerError] = useState<string>();
    const [showMessage, setShowMessage] = useState(false);
    const { httpService } = useHttpContext();
    let addAnother = false;
    const { handleSubmit, control, reset } = useForm<CreateLocationDto>({
        defaultValues: {
            name,
            timezone,
            address1,
            address2,
            city,
            stateCode,
            zipCode,
        },
        resolver: yupResolver(schema),
    });

    const { errors } = useFormState({
        control,
    });

    const displaySuccessMessage = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 1000);
    };

    const saveAndAddAnother = async (data: CreateLocationDto) => {
        addAnother = true;
        const success = await onSubmit(data);
        if (success) {
            if (scrollTarget && scrollTarget.current) {
                scrollTarget.current.scrollTop = 0;
            }
            displaySuccessMessage();
            reset(newLocation);
            addAnother = false;
        }
    };

    const onSubmit = async (data: CreateLocationDto) => {
        const success =
            action === 'create'
                ? await httpService.createLocation(data)
                : id && (await httpService.updateLocation(id, data));
        if (!success || resultIsError(success)) {
            setServerError(`Could not ${action} location.`);
            return false;
        } else {
            mixpanel.track('Added New Location');
            if (addAnother) {
                return true;
            } else {
                window.location.reload();
            }
            return false;
        }
    };

    return (
        <>
            {showMessage && (
                <SuccessMessage className={showMessage ? 'success' : ''}>
                    <div>Location Added</div>
                </SuccessMessage>
            )}
            <LocationsFormWrapper>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormTextField
                        name="name"
                        label="Location Name"
                        control={control}
                        errors={errors}
                        required={true}
                    />
                    <FormAutoCompleteField
                        control={control}
                        errors={errors}
                        name="timezone"
                        label="Timezone"
                        options={Intl.supportedValuesOf('timeZone')}
                        optionLabel={(timezone: string) => timezone}
                        clearIcon={false}
                    />
                    <FormTextField
                        name="address1"
                        label="Address Line 1"
                        control={control}
                        errors={errors}
                        required={false}
                    />
                    <FormTextField name="address2" label="Address Line 2" control={control} errors={errors} />
                    <FormTextField name="city" label="City" control={control} errors={errors} />
                    <FormFieldContainer>
                        <LocationStateSelectWrapper>
                            <Controller
                                name="stateCode"
                                control={control}
                                defaultValue={stateCode}
                                render={({ field }) => {
                                    return (
                                        <FormControl variant="outlined">
                                            <InputLabel shrink htmlFor="stateCode">
                                                State
                                            </InputLabel>
                                            <Select label="State" {...field}>
                                                <MenuItem>None</MenuItem>
                                                {Object.keys(StatesRegions).map((key) => {
                                                    return (
                                                        <MenuItem key={key} value={key}>
                                                            {StatesRegions[key]}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            <p> </p>
                                        </FormControl>
                                    );
                                }}
                            />
                        </LocationStateSelectWrapper>
                    </FormFieldContainer>

                    <FormTextField name="zipCode" label="Zip Code" control={control} errors={errors} />

                    {practitioners && (
                        <FormFieldContainer>
                            <LocationStateSelectWrapper>
                                <Controller
                                    name="defaultProviderId"
                                    control={control}
                                    defaultValue={defaultProvider?.id}
                                    render={({ field }) => {
                                        return (
                                            <FormControl variant="outlined">
                                                <InputLabel shrink htmlFor="defaultProviderId">
                                                    Default Provider
                                                </InputLabel>
                                                <Select label="Default Provider" {...field}>
                                                    <MenuItem>None</MenuItem>
                                                    {practitioners.map((p) => (
                                                        <MenuItem key={p.id} value={p.id}>
                                                            {p.firstName} {p.lastName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <p> </p>
                                            </FormControl>
                                        );
                                    }}
                                />
                            </LocationStateSelectWrapper>
                        </FormFieldContainer>
                    )}
                    <ServerError>{serverError || '\u00a0'}</ServerError>
                    {action === 'create' ? (
                        <SaveSaveAnotherCancelButtons
                            saveFunction={handleSubmit(onSubmit)}
                            saveAndAddAnotherFunction={handleSubmit(saveAndAddAnother)}
                            cancelFunction={handleCancel}
                        />
                    ) : (
                        <SubmitCancelButtons submitFunction={handleSubmit(onSubmit)} cancelFunction={handleCancel} />
                    )}
                </form>
            </LocationsFormWrapper>
        </>
    );
};
