import React from 'react';
import { HttpClient as HttpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import * as ReactDOMClient from 'react-dom/client';
import AppProvider from '@/components/AppProvider';
import packageInfo from '../package.json';

declare global {
    interface Window {
        dataLayer: any;
        HubSpotConversations: any;
    }
}

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace Intl {
        const supportedValuesOf: (prop: string) => string[];
    }
}

window.dataLayer;

if (process.env.NODE_ENV !== 'production') {
    module.hot?.accept();
}

!!process.env.SENTRY_DSN &&
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        environment: process.env.ENVIRONMENT,
        release: packageInfo.version,
        integrations: [
            new HttpClientIntegration({
                failedRequestStatusCodes: [[500, 599]],
            }),
        ],
        sendDefaultPii: true,
    });

const appContainer = document.getElementById('root');

const root = ReactDOMClient.createRoot(appContainer!);
root.render(<AppProvider />);
