import { useEffect } from 'react';
import { mixpanel } from '@/common/mixpanel';
import { PortalEvent } from '@/services/events/const';
import eventService from '@/services/events/eventService';
import { useExerUser } from '../hooks/ExerUser';

export const MixPanel = () => {
    const { user, getRole } = useExerUser();

    useEffect(() => {
        if (['STAGING', 'PRODUCTION'].includes(process.env.ENVIRONMENT!)) {
            mixpanel.init(process.env.MIXPANEL_APP_ID!);
        }
    }, []);

    useEffect(() => {
        eventService.on(PortalEvent.SIGN_IN_OR_CREATE, (data) => {
            mixpanel.people.set({
                $first_name: user.firstName,
                $last_name: user.lastName,
                $email: user.email,
                Organization: user.organizationName,
                Role: getRole(),
            });
        });

        // set once every app load to capture existing users before Mixpanel implementation, could be removed after X time
        setTimeout(() => {
            mixpanel.people.set({
                $first_name: user.firstName,
                $last_name: user.lastName,
                $email: user.email,
                Organization: user.organizationName,
                Role: getRole(),
            });
        }, 1000);

        return () => {
            eventService.remove(PortalEvent.SIGN_IN_OR_CREATE);
        };
    }, []);

    return null;
};
