import React from 'react';
import { Close } from '@mui/icons-material';
import { BodyTarget, Equipment, ExerciseType, Sequence } from '@/common/const';
import { Exercise } from '@/common/types';
import {
    DrawerContentWrapper,
    DrawerTitle,
    DrawerTitleDivider,
    DrawerTitleWrapper,
    DrawerCloseButton,
} from '@/components/common/Drawer/styles';
import { Button } from '@/components/common/button/button';
import { AddToProgramAction } from '../AddToProgram/AddtoProgramAction';
import { ExerciseAnimationPlayer } from '../ExerciseAnimationPlayer';
import {
    ViewExerciseDrawerContent,
    ViewExerciseTag,
    ViewExerciseTags,
    ViewExerciseDrawerActions,
    ViewExerciseDrawerWrapper,
} from './styles';

interface Props {
    readonly exercise: Exercise;
    readonly drawerIsOpen: boolean;
    readonly addToHepCtas: boolean;
    readonly closeDrawer: any;
    readonly openDrawer: any;
}

export const ViewExerciseAction = (props: Props) => {
    const { exercise, drawerIsOpen, openDrawer, closeDrawer, addToHepCtas } = props;

    const handleOpenDrawer = () => {
        openDrawer();
    };

    const handleCloseDrawer = () => {
        closeDrawer();
    };

    return (
        <>
            <ViewExerciseDrawerWrapper anchor="right" open={drawerIsOpen} onClose={handleCloseDrawer}>
                <DrawerTitleWrapper>
                    <DrawerTitle variant="h5">{exercise.name}</DrawerTitle>
                    <DrawerCloseButton onClick={closeDrawer} size="small">
                        <Close />
                    </DrawerCloseButton>
                </DrawerTitleWrapper>
                <DrawerTitleDivider />
                <DrawerContentWrapper>
                    <ViewExerciseTags>
                        {exercise.exerciseType && (
                            <ViewExerciseTag
                                color="info"
                                label={ExerciseType[exercise.exerciseType] || exercise.exerciseType.toLowerCase()}
                            />
                        )}
                        {exercise.bodyTargets &&
                            exercise.bodyTargets.map((bodyTargets, key) => {
                                return (
                                    <ViewExerciseTag
                                        color="info"
                                        key={bodyTargets}
                                        label={BodyTarget[bodyTargets] || bodyTargets.toLowerCase()}
                                    />
                                );
                            })}
                        <>
                            {exercise.equipment && (
                                <ViewExerciseTag
                                    color="info"
                                    label={Equipment[exercise.equipment] || exercise.equipment.toLowerCase()}
                                />
                            )}
                            {exercise.sequence && (
                                <ViewExerciseTag
                                    color="info"
                                    label={Sequence[exercise.sequence] || exercise.sequence.toLowerCase()}
                                />
                            )}
                            {exercise.withAnchorPoint && <ViewExerciseTag color="info" label="Has Anchor" />}
                            {exercise.withHold && <ViewExerciseTag color="info" label="With Hold" />}
                        </>
                    </ViewExerciseTags>
                    <ViewExerciseDrawerContent>
                        <ExerciseAnimationPlayer exercise={exercise} />
                        <h4>Exercise Descriptions</h4>
                        <h5>Position</h5>
                        <p>{exercise.position}</p>
                        <h5>Action</h5>
                        <p>{exercise.action}</p>
                    </ViewExerciseDrawerContent>
                </DrawerContentWrapper>
                {addToHepCtas && (
                    <ViewExerciseDrawerActions>
                        <Button variant="tertiary" size="small" onClick={handleCloseDrawer}>
                            Cancel
                        </Button>
                        <AddToProgramAction exercise={exercise} onSubmit={handleCloseDrawer} />
                    </ViewExerciseDrawerActions>
                )}
            </ViewExerciseDrawerWrapper>
        </>
    );
};
