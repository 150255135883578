import React, { ChangeEvent, useState, useEffect } from 'react';
import { FormControl, Typography } from '@mui/material';
import { RadioCardControlProps } from '@/components/common/RadioCardGroup/RadioCard/RadioCard';
import { RadioCardControl } from '@/components/common/RadioCardGroup/RadioCard/RadioCard';
import { ListForm, RadioCardList, RadioCardListLabel } from './styles';

interface RadioCardGroupProps {
    data: RadioCardControlProps[];
    selectedItem?: (value: string | number) => void;
    formLabel?: string;
    defaultValue?: string | number;
}

export const RadioCardGroup = (props: RadioCardGroupProps) => {
    const { data, selectedItem, formLabel, defaultValue } = props;
    const [selectedValue, setSelectedValue] = useState('');
    const [activeValue, setActiveValue] = useState<string | number>();
    const [items, setItems] = useState(data);

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedValue((event.target as HTMLInputElement).value);
        setActiveValue(Number((event.target as HTMLInputElement).value));
        selectedItem && selectedItem((event.target as HTMLInputElement).value);
    };

    useEffect(() => {
        // If we need ever user filtering here we can add that with this useEfect
        setItems(data);
        setSelectedValue(data[0].value.toString());
    }, []);

    if (!data.length) {
        return null;
    }

    return (
        <ListForm>
            <FormControl variant="standard">
                {formLabel && (
                    <RadioCardListLabel id="radio-group">
                        <Typography variant="body2" component="h1" fontWeight={700}>
                            {formLabel}
                        </Typography>
                    </RadioCardListLabel>
                )}
                <RadioCardList
                    aria-labelledby="radio-group"
                    name="radio-card-list"
                    value={selectedValue}
                    onChange={handleRadioChange}
                    defaultValue={defaultValue}
                >
                    {items.map((item) => (
                        <RadioCardControl
                            key={item.value}
                            value={item.value}
                            title={item.title}
                            supTitle={item.supTitle}
                            cardDetails={item.cardDetails}
                            selected={item.value === activeValue}
                        />
                    ))}
                </RadioCardList>
            </FormControl>
        </ListForm>
    );
};
