import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary, AccordionDetails, Button } from '@mui/material';
import { handleEnterPress, characterCountWithLineBreaks } from '@/common/utils';
import { FrequencyEditor } from '@/components/Health/HEPBuilder/steps/FrequencyEditor/FrequencyEditor';
import { FrequencyActionsWrapper } from '@/components/Health/HEPBuilder/steps/FrequencyEditor/styles';
import { ServerError } from '@/components/common/Form/styles';
import { LockIcon } from '@/components/common/Icons/LockIcon';
import { DraggableBuiltHEPBlocks } from '../../BuiltHEPBlocks/DraggableBuiltHEPBlocks';
import { NOTES_MAX_LENGTH } from '../../state/HEPBuilderContext';
import { useHEPBuilderContext } from '../../state/hooks/HEPBuilderContext';
import { EditActions } from '../EditActions/EditActions';
import { HEPTextField, HEPError, HEPNotesLength } from '../StepInformation/styles';
import { StepSummaryTitleWrapper, StepSummaryNotesWrapper } from '../StepSummary/styles';
import {
    HEPBuilderExercisesMobile,
    BuiltHEPSidebarContent,
    HEPBuilderExercisesContentRight,
    BuiltHEPSidebar,
    BuiltHEPSidebarScroll,
    HEPNotesWrapper,
    EditActionsWrapper,
    HEPNotes,
} from './styles';

export const StepExercisesHEPDetails = () => {
    const {
        hepDetails,
        isEditingTitle,
        isEditingNotes,
        hepBuilderActions,
        hepErrors,
        handleEditingTitle,
        handleEditingNotes,
    } = useHEPBuilderContext();
    const [hepName, setHepName] = useState(hepDetails.name);
    const [hepNotes, setHepNotes] = useState(hepDetails.notes ?? '');

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHepName(e.target.value);
    };

    const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const notes =
            characterCountWithLineBreaks(e.target.value) > NOTES_MAX_LENGTH
                ? e.target.value.substring(0, NOTES_MAX_LENGTH)
                : e.target.value;
        hepBuilderActions?.setNotes(notes);
    };

    const handleNameEditClick = () => {
        hepBuilderActions?.setName(hepName);
        handleEditingTitle(!isEditingTitle);
    };

    const handleNameSubmit = () => {
        hepBuilderActions?.setName(hepName);
        handleEditingTitle(!isEditingTitle);
    };

    const handleNameEnterPress = (e: React.KeyboardEvent) => {
        handleEnterPress(e, handleNameSubmit);
    };

    const handleCancelNameEdit = () => {
        setHepName(hepDetails.name ? hepDetails.name : '');
        handleEditingTitle(!isEditingTitle);
    };

    const handleNotesEditClick = () => {
        handleEditingNotes(!isEditingNotes);
    };

    const handleNotesSubmit = () => {
        handleEditingNotes(!isEditingNotes);
    };

    const handleCancelNotesEdit = () => {
        handleEditingNotes(!isEditingNotes);
    };

    useEffect(() => {
        return () => {
            handleEditingNotes(false);
            handleEditingTitle(false);
        };
    }, []);

    return (
        <>
            <HEPBuilderExercisesMobile>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h3>{hepName}</h3>
                </AccordionSummary>
                <AccordionDetails>
                    {hepDetails && (
                        <BuiltHEPSidebarContent>
                            <h3>{hepName}</h3>
                            <h5>Program Frequency</h5>
                            <p>Daily Frequency: {hepDetails.dailyFrequency}</p>
                            <p>Weekly Frequency: {hepDetails.weeklyFrequency}</p>
                            <h5>Selected Exercises</h5>
                            <DraggableBuiltHEPBlocks />
                        </BuiltHEPSidebarContent>
                    )}
                    {hepErrors?.blocks && <ServerError>{hepErrors.blocks}</ServerError>}
                </AccordionDetails>
            </HEPBuilderExercisesMobile>
            <HEPBuilderExercisesContentRight>
                <BuiltHEPSidebar data-cy="built-program-sidebar">
                    <BuiltHEPSidebarScroll>
                        <BuiltHEPSidebarContent>
                            {isEditingTitle ? (
                                <>
                                    <div>
                                        <HEPTextField
                                            required={true}
                                            value={hepName}
                                            onChange={handleNameChange}
                                            placeholder="Name Your Program"
                                            id="program-name"
                                            label="Program Name"
                                            variant="outlined"
                                            error={!!hepErrors?.name}
                                            onKeyDown={handleNameEnterPress}
                                            autoFocus
                                        />
                                        <HEPError>{hepErrors?.name}</HEPError>
                                    </div>
                                    <EditActionsWrapper>
                                        <Button onClick={handleCancelNameEdit} size="small" variant="text">
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={handleNameSubmit}
                                            size="small"
                                            startIcon={<LockIcon />}
                                            variant="textPrimary"
                                        >
                                            Save
                                        </Button>
                                    </EditActionsWrapper>
                                </>
                            ) : (
                                <StepSummaryTitleWrapper>
                                    <span>{hepDetails?.name}</span>
                                    <EditActions handleClick={handleNameEditClick} />
                                </StepSummaryTitleWrapper>
                            )}
                            <FrequencyActionsWrapper>
                                <h5>Program Frequency</h5>
                                <FrequencyEditor isInModal={true} />
                            </FrequencyActionsWrapper>
                            <p>Daily Frequency: {hepDetails.dailyFrequency}</p>
                            <p>Weekly Frequency: {hepDetails.weeklyFrequency}</p>
                            <h5>Selected Exercises</h5>
                            <DraggableBuiltHEPBlocks />
                        </BuiltHEPSidebarContent>
                        <HEPNotesWrapper>
                            {!isEditingNotes && (
                                <StepSummaryNotesWrapper>
                                    <span>Program Notes</span>
                                    <EditActions handleClick={handleNotesEditClick} />
                                </StepSummaryNotesWrapper>
                            )}
                            {isEditingNotes ? (
                                <>
                                    <HEPTextField
                                        multiline={true}
                                        rows={4}
                                        inputProps={{
                                            maxLength: NOTES_MAX_LENGTH,
                                        }}
                                        value={hepDetails.notes}
                                        onChange={handleNotesChange}
                                        placeholder="Type something..."
                                        id="program-name"
                                        label="Program notes (optional)"
                                        variant="outlined"
                                        error={!!hepErrors?.notes}
                                    />
                                    <HEPError>
                                        {hepErrors?.notes || `\u00a0`}{' '}
                                        <HEPNotesLength
                                            $isMax={
                                                hepDetails.notes ? hepDetails.notes.length >= NOTES_MAX_LENGTH : false
                                            }
                                        >
                                            {hepDetails.notes?.length || 0}/{NOTES_MAX_LENGTH.toString()}
                                        </HEPNotesLength>
                                    </HEPError>
                                    <EditActionsWrapper>
                                        <Button onClick={handleNotesSubmit} size="small" variant="textPrimary">
                                            Close
                                        </Button>
                                    </EditActionsWrapper>
                                </>
                            ) : (
                                <HEPNotes>{hepDetails.notes || '-'}</HEPNotes>
                            )}
                        </HEPNotesWrapper>
                        {hepErrors?.blocks && <ServerError>{hepErrors.blocks}</ServerError>}
                    </BuiltHEPSidebarScroll>
                </BuiltHEPSidebar>
            </HEPBuilderExercisesContentRight>
        </>
    );
};
