import React from 'react';
import { SvgIcon } from '@mui/material';

export const AlertIcon = (props) => {
    return (
        <>
            <SvgIcon width="22" height="22" viewBox="1 1 22 22" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.7825 16.1072L13.7897 6.1039C13.0531 4.62814 10.9474 4.62812 10.2108 6.10389L5.21775 16.1071C4.55404 17.4369 5.52107 19.0003 7.00722 19.0003H16.9931C18.4792 19.0003 19.4462 17.4369 18.7825 16.1072ZM15.5792 5.21073C14.106 2.2592 9.89444 2.25918 8.42124 5.2107L3.42828 15.2139C2.10086 17.8734 4.0349 21.0003 7.00722 21.0003H16.9931C19.9654 21.0003 21.8994 17.8734 20.572 15.214L15.5792 5.21073Z"
                    fill="currentcolor"
                />
                <path
                    d="M13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12V8Z"
                    fill="currentcolor"
                />
                <path
                    d="M12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15Z"
                    fill="currentcolor"
                />
            </SvgIcon>
        </>
    );
};
