import { DateTime } from 'luxon';
import { selector } from 'recoil';
import { DateRangeState } from '@/recoil/atoms/dateRangeAtom';

export const DateRangeSelector = selector({
    key: 'dateRangeSelector',
    get: ({ get }) => {
        const dateRange = get(DateRangeState);

        const startDateMillis = DateTime.fromJSDate(dateRange.startDate).startOf('day').toMillis();
        const endDateMillis = DateTime.fromJSDate(dateRange.endDate).endOf('day').toMillis();

        return {
            startDateMillis,
            endDateMillis,
        };
    },
});
