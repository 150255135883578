import React, { ReactNode } from 'react';
import { Breadcrumbs as BreadcrumbsWrapper } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Paths } from '@/common/const';

export const Breadcrumbs = () => {
    const location = useLocation();
    const links: ReactNode[] = [];
    const adminPath = Paths.admin.replaceAll('/', '');

    const pathParts = location.pathname.split('/').filter((part) => {
        return !!part && part;
    });

    switch (pathParts[0]) {
        case 'programs':
            if (pathParts[1] === 'templates' && !!pathParts[2]) {
                links.push(
                    <div key={1}>
                        <Link to={Paths.programsHEPTemplates}>Program Templates</Link>
                        <span> / Template Details</span>
                    </div>,
                );
            }
            break;
        case 'patients':
            if (!!pathParts[1]) {
                links.push(
                    <div key={1}>
                        <Link to={Paths.patients}>Patient List</Link>
                        <span> / Patient Details</span>
                    </div>,
                );
            }
            break;
    }

    return <BreadcrumbsWrapper>{links}</BreadcrumbsWrapper>;
};
