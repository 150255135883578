import React from 'react';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { EditActionButton, EditActionWrapper } from './styles';

interface EditActionsProps {
    handleClick: () => void;
}

export const EditActions = (props: EditActionsProps) => {
    const { handleClick } = props;
    return (
        <EditActionWrapper>
            <EditActionButton size="small" onClick={handleClick} startIcon={<EditIcon />}>
                Edit
            </EditActionButton>
        </EditActionWrapper>
    );
};
