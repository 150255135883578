import React from 'react';
import { Location } from '@/common/types';
import { PractitionersList } from './PractitionersList';
import { usePractitionersContext } from './context/hooks/PractitionerContext';

interface PractitionersListContainerProps {
    locations: Location[] | undefined;
}

export const PractitionersListContainer = (props: PractitionersListContainerProps) => {
    const { locations } = props;
    const { practitioners } = usePractitionersContext();

    return <PractitionersList practitioners={practitioners} locations={locations} />;
};
