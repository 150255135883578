import React, { useEffect } from 'react';
import { useSetRecoilState, useResetRecoilState } from 'recoil';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { UserProfile } from '@/common/types';
import { isUserProfile } from '@/common/utils';
import { PatientsBillingTableContainer } from '@/components/PatientBilling/PatientsBillingTable/PatientsBillingTableContainer';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { PaginationProvider } from '@/components/context/PaginationContext';
import { RTMState } from '@/recoil/atoms/rtmAtom';
import { UserProfileState } from '@/recoil/atoms/userProfileAtom';
import { Result } from '@/services/HttpService';
import { RouteContentWrapper } from '../../styles';

export const BillingRoute = () => {
    const resetRTMFilters = useResetRecoilState(RTMState);
    const setUserProfile = useSetRecoilState(UserProfileState);
    const { httpService } = useHttpContext();

    useEffect(() => {
        resetRTMFilters();
        (async () => {
            const res: Result<UserProfile> = await httpService.getCurrentUser();
            if (isUserProfile(res)) {
                setUserProfile(res);
            }
        })();
    }, []);

    return (
        <>
            <RouteContentWrapper>
                <PaginationProvider>
                    <PageTitle title="RTM Billing" />
                    <PatientsBillingTableContainer />
                </PaginationProvider>
            </RouteContentWrapper>
        </>
    );
};
