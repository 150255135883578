import React from 'react';
import { SvgIcon } from '@mui/material';

export const EditIcon = (props) => {
    return (
        <>
            <SvgIcon width="24" height="24" viewBox="0 0 23 23" fill="none" {...props}>
                <path
                    d="M4 21C4 20.4477 4.44772 20 5 20H19C19.5523 20 20 20.4477 20 21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21Z"
                    fill={props.color || 'currentColor'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.4116 3.17449C13.9737 1.6124 16.5064 1.61241 18.0684 3.1745L18.8258 3.93186C20.3879 5.49395 20.3879 8.02662 18.8258 9.58872L11.9197 16.4948C11.6423 16.7722 11.2894 16.9619 10.9049 17.0403L6.40132 17.9582C4.99677 18.2445 3.75594 17.0037 4.04217 15.5991L4.95998 11.0954C5.03833 10.7109 5.22804 10.358 5.50549 10.0805L12.4116 3.17449ZM16.6542 4.58872C15.8732 3.80767 14.6069 3.80767 13.8258 4.58871L13.4142 5.00036L16.9999 8.58614L17.4116 8.1745C18.1926 7.39345 18.1926 6.12712 17.4116 5.34607L16.6542 4.58872ZM8.41413 10.0003L11.9999 6.41457L15.5857 10.0003L11.9999 13.5861L8.41413 10.0003ZM6.99991 11.4146L6.9197 11.4948L6.00189 15.9985L10.5055 15.0806L10.5857 15.0003L6.99991 11.4146Z"
                    fill={props.color || 'currentColor'}
                />
            </SvgIcon>
        </>
    );
};
