import { PROChartDataDict, PROChartData, PROAnswersByQuestionsDict } from './types';

const pushMessageToDataSet = (proData: PROChartData, date: Date, value: string) => {
    proData.chart.labels.push(date);
    proData.chart.datasets[0].data.push(value);
};

export const options = {
    clip: false,
    responsive: true,
    redraw: true,
    interaction: {
        intersect: false,
        mode: 'index',
    },
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        tooltip: {
            position: 'nearest',
        },
    },
};

export const getPROChartData = (proAnswersByQuestionsDict: PROAnswersByQuestionsDict): PROChartDataDict => {
    const proDict: PROChartDataDict = {};
    Object.values(proAnswersByQuestionsDict).forEach((q) => {
        if (q.isChartable) {
            const data = {
                title: q.text,
                chart: {
                    labels: [],
                    datasets: [
                        {
                            label: q.text,
                            data: [],
                            borderColor: '#f441cd',
                            pointHoverBackgroundColor: '#f441cd',
                            backgroundColor: '#fff',
                            borderWidth: 1.5,
                            pointBorderWidth: 2,
                            pointRadius: 4,
                            pointHoverRadius: 6,
                        },
                    ],
                    options: {
                        ...options,
                        scales: {
                            y: {
                                beginAtZero: !q.minValue || q.minValue === 0 ? true : false,
                                steps: q.maxValue || 10,
                                stepValue: 1,
                                min: q.minValue || 0,
                                max: q.maxValue || 10,
                                ticks: {
                                    stepSize: 1,
                                },
                            },
                            x: {
                                grid: {
                                    display: false,
                                },
                            },
                        },
                    },
                },
            };
            q.answers.forEach((a) => {
                pushMessageToDataSet(data, new Date(a.dateTime), a.value);
            });
            proDict[q.id] = data;
        }
    });

    return proDict;
};
