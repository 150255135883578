import React from 'react';
import { FC } from 'react';
import { Maybe } from '@exerai/vision-engine-web/dist/src/common/types';
import { ScanSessionResult } from '@/common/types';
import { OutcomeFactor, ParticipantDemographics } from '../types/types';
import { OutcomeFactorTable } from './OutcomeFactorTable';
import { getOutcomeFactorTableData } from './utils';

interface OutcomeFactorTableContainerProps {
    result: ScanSessionResult;
    factors: Maybe<OutcomeFactor[]>;
    demographics: Maybe<ParticipantDemographics>;
}

export const OutcomeFactorTableContainer: FC<OutcomeFactorTableContainerProps> = ({
    result,
    factors,
    demographics,
}) => {
    const { sexMap, resultCriterion } = getOutcomeFactorTableData(result, factors, demographics);

    return <OutcomeFactorTable outcomeFactorTableData={{ sexMap, resultCriterion }} />;
};
