import { Drawer as MuiDrawer, ListItemButton, List, styled, ListItemIcon, Theme, CSSObject } from '@mui/material';
import { ActiveNavItemIcon } from '@/components/common/Icons/ActiveNavItemIcon';

export const ActiveItemIcon = styled(ActiveNavItemIcon)(({ theme }) => ({
    width: '4px',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
}));

export const MobileDrawer = styled(MuiDrawer)<{ $isAdmin: boolean }>(({ $isAdmin, theme }) => ({
    '& > .MuiPaper-root': {
        background: $isAdmin ? theme.palette.info[900] : theme.palette.grey[900],
        fontWeight: '700',
    },
}));

export const MobileDrawerPaper = styled('div')(() => ({
    width: '256px',
}));

export const NavListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: '20px',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: '16px',
    color: 'inherit',
    fontSize: theme.typography.h5.fontSize,
    marginTop: 5,
    marginBottom: 5,
    '& .MuiSvgIcon-root': {
        fill: 'none',
    },
}));

export const SidebarContentWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',
    '& .MuiListItem-root': {
        paddingLeft: theme.spacing(4),
    },
    '& .MuiListItem-root:hover': {
        color: `${theme.palette.common.white}`,
    },
    '& .MuiListItem-root.Mui-selected:hover': {},
}));

export const SidebarNavList = styled(List)<{ $isActive: boolean; $hasChildren }>(
    ({ theme, $isActive, $hasChildren }) => ({
        padding: '0',
        '& > .MuiListItemButton-root': {
            paddingLeft: theme.spacing(2.75),
            backgroundColor: 'transparent',
            color: $isActive ? theme.palette.primary[400] : theme.palette.grey[600],
            '&:hover': {
                background: 'rgba(255, 255, 255, 0.1)',
            },
        },
    }),
);

export const NavListItemButton = styled(ListItemButton)<{ $isActive: boolean }>(({ theme, $isActive }) => ({
    color: $isActive ? `${theme.palette.common.white}` : `${theme.palette.grey[600]}`,
    '&:hover': {
        color: theme.palette.common.white,
    },
    '&.MuiListItemButton-root ~ .MuiCollapse-root .MuiListItemButton-root.Mui-selected': {
        background: 'rgba(255, 255, 255, 0.1)',
        color: $isActive ? `${theme.palette.common.white}` : `${theme.palette.error[500]}`,
    },
    '& > span': {
        fontWeight: 700,
    },
}));

export const ChildSidebarNavItemWrapper = styled('div')(({ theme }) => ({
    '& .MuiListItemButton-root': {
        paddingLeft: theme.spacing(7.25),
        whiteSpace: 'break-spaces',
    },
}));

export const openedMixin = (theme: Theme): CSSObject => ({
    width: 240,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    backgroundColor: 'rgb(10, 10, 13)',
});

export const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    backgroundColor: 'rgb(10, 10, 13)',
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(3, 4, 2.75, 1.25),
}));

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: 240,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: 'rgb(10, 10, 13)',
    '& > div.MuiDrawer-paper': {
        backgroundColor: 'rgb(10, 10, 13)',
    },
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));
