import { styled, Typography, Card } from '@mui/material';

export const ExerciseDetailsWrapper = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),

    [theme.breakpoints.up('lg')]: {
        paddingLeft: 0,
        paddingRight: theme.spacing(8),
    },
}));

export const HEPTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
}));

export const HEPCaption = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    display: 'flex',
}));

export const HEPExercisesCaption = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(4),
    display: 'flex',
}));

export const HEPFrequencyDetails = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[900],
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    fontSize: theme.typography.subtitle1.fontSize,
}));

export const HEPDetailsNotesCard = styled(Card)(({}) => ({
    backgroundColor: 'transparent',
}));

export const HEPDetailsNotes = styled(Typography)(({ theme }) => ({
    whiteSpace: 'pre-wrap',
    color: theme.palette.grey[600],
}));
