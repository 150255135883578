import React, { useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { useRecoilValue } from 'recoil';
import { sidebarNavState } from '@/recoil/atoms/sidebarNavAtom';

export const DynamicLogo = (props) => {
    const sidebarNav = useRecoilValue(sidebarNavState);
    const { isOpen } = sidebarNav;

    const [logoPiecesSprings, logoPiecesApi] = useSpring(() => ({
        from: { x: 0, opacity: 1 },
    }));
    const [logoXSprings, logoXApi] = useSpring(() => ({
        from: { x: 0, opacity: 1 },
    }));
    const [logoESpring, logoEApi] = useSpring(() => ({
        from: { x: 0, opacity: 1 },
    }));

    const logoEAnimation = () => {
        logoEApi.start({
            from: {
                x: !isOpen ? 0 : 60,
                opacity: !isOpen ? 1 : 0,
            },
            to: {
                x: !isOpen ? 60 : 0,
                opacity: !isOpen ? 0 : 1,
            },
        });
    };

    const logoPiecesAnimation = () => {
        logoPiecesApi.start({
            from: {
                x: isOpen ? -200 : 0,
                opacity: isOpen ? 0 : 1,
            },
            to: {
                x: isOpen ? 0 : -200,
                opacity: isOpen ? 1 : 0,
            },
        });
    };

    const logoXPosition = () => {
        logoXApi.start({
            from: {
                x: !isOpen ? 0 : -130,
            },
            to: {
                x: !isOpen ? -130 : 0,
            },
        });
    };

    useEffect(() => {
        // if (isOpen) {
        //     showLogoPieces();
        // } else {
        //     hideLogoPieces();
        // }

        logoPiecesAnimation();
        logoEAnimation();
        logoXPosition();
    }, [isOpen]);

    return (
        <animated.svg
            width={606}
            height={168}
            viewBox="0 0 606 168"
            fill="#ffffff"
            style={{ width: '90px', height: 16 }}
        >
            <animated.path
                style={{ ...logoPiecesSprings }}
                d="M480.427 58.5542L465.923 73.0577V152.827L480.427 167.33L494.93 152.827V73.0577L480.427 58.5542Z"
            />
            <animated.path
                style={{ ...logoPiecesSprings }}
                d="M597.85 139.161L593.108 134.698L559.36 100.671L547.645 88.9565H527.006V109.317L534.257 116.569L535.373 117.685L579.162 161.195L584.741 166.773H605.101V146.413L599.523 140.834L597.85 139.161Z"
            />
            <animated.path
                style={{ ...logoPiecesSprings }}
                d="M605.101 54.9293V38.1945L590.597 23.691L576.094 38.1945V48.2354V49.351V66.0858V83.0995L590.597 97.324L605.101 83.0995V72.7797V68.596V54.9293Z"
            />
            <animated.path
                style={{ ...logoPiecesSprings }}
                d="M488.801 28.99V29.2689H520.597H540.678L557.413 28.99H568.849L583.073 14.7654L568.849 0.261963H528.685H497.168H480.433L465.93 14.7654L480.433 29.2689L488.801 28.99Z"
            />
            <animated.path
                style={{ ...logoESpring }}
                d="M76.9799 20.3606V0H56.6193L0 56.3404V76.701H20.3606L76.9799 20.3606Z"
            />
            <animated.path
                style={{ ...logoESpring }}
                d="M0 89.2521V109.613L56.6193 166.232H76.9799V145.592L20.3606 89.2521H0Z"
            />
            <animated.path
                style={{ ...logoESpring }}
                d="M64.2023 68.3913L47.4675 68.3913L32.964 82.8947L47.4675 97.3982L57.5083 97.3982L58.624 97.3982L75.3588 97.3982L92.3724 97.3982L106.597 82.8947L92.3724 68.3913L82.0527 68.3913L77.869 68.3913L64.2023 68.3913Z"
            />
            <animated.path
                style={{ ...logoESpring }}
                d="M76.2886 68.3913L59.5538 68.3913L45.0503 82.8947L59.5538 97.3982L69.5947 97.3982L70.7103 97.3982L87.4451 97.3982L104.459 97.3982L118.683 82.8947L104.459 68.3913L94.139 68.3913L89.9553 68.3913L76.2886 68.3913Z"
            />
            <animated.path
                style={{ ...logoPiecesSprings }}
                d="M400.043 20.3606V0H379.682L323.063 56.3404V76.701H343.423L400.043 20.3606Z"
            />
            <animated.path
                style={{ ...logoPiecesSprings }}
                d="M323.062 89.2521V109.613L379.682 166.232H400.042V145.592L343.423 89.2521H323.062Z"
            />
            <animated.path
                style={{ ...logoPiecesSprings }}
                d="M387.272 68.3913L370.537 68.3913L356.034 82.8947L370.537 97.3982L380.578 97.3982L381.694 97.3982L398.428 97.3982L415.442 97.3982L429.667 82.8947L415.442 68.3913L405.122 68.3913L400.939 68.3913L387.272 68.3913Z"
            />
            <animated.path
                style={{ ...logoPiecesSprings }}
                d="M399.358 68.3913L382.624 68.3913L368.12 82.8947L382.624 97.3982L392.664 97.3982L393.78 97.3982L410.515 97.3982L427.528 97.3982L441.753 82.8947L427.528 68.3913L417.209 68.3913L413.025 68.3913L399.358 68.3913Z"
            />
            <animated.path
                style={{ ...logoXSprings }}
                d="M132.436 145.035V165.396H153.076L209.416 108.776V88.4158H189.055L132.436 145.035Z"
            />
            <animated.path
                style={{ ...logoXSprings }}
                d="M133.005 0V20.3606L189.345 76.701H209.706V56.3404L153.365 0H133.005Z"
            />
            <animated.path
                style={{ ...logoXSprings }}
                d="M221.98 56.3404V76.701H242.341L298.681 20.3606V0H278.321L221.98 56.3404Z"
            />
            <animated.path
                style={{ ...logoXSprings }}
                d="M221.98 88.9734V109.334L278.321 165.674H298.681V145.314L242.341 88.9734H221.98Z"
            />
        </animated.svg>
    );
};
