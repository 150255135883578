import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Paths, Products } from '@/common/const';
import { PatientRecord, ProductKey, Session } from '@/common/types';
import { getStringEnumKeyByValue, isGaitPatient, isHealthOrPROPatient } from '@/common/utils';
import { PROAnswersByQuestionsDict } from '@/components/Health/PatientSessions/PROs/types';

interface IPatientContext {
    product: ProductKey;
    setProduct: Dispatch<SetStateAction<ProductKey>>;
    patientRecord: PatientRecord;
    healthSessions?: Session[];
    setHealthSessions: Dispatch<SetStateAction<Session[]>>;
    hepBuilderDialogOpen: boolean;
    setHEPBuilderDialogOpen: Dispatch<SetStateAction<boolean>>;
    proAnswersByQuestionsDict?: PROAnswersByQuestionsDict;
    setPROAnswersByQuestionsDict: Dispatch<SetStateAction<PROAnswersByQuestionsDict>>;
}

export const PatientContext = createContext<IPatientContext | null>(null);

interface PatientProviderProps {
    children: JSX.Element | JSX.Element[];
    patientRecord: PatientRecord;
}
export const PatientProvider = (props: PatientProviderProps) => {
    const { children, patientRecord } = props;
    const initProduct =
        patientRecord.products?.length > 1 || isHealthOrPROPatient(patientRecord)
            ? getStringEnumKeyByValue(Products, Products.HEALTH)
            : isGaitPatient(patientRecord)
            ? getStringEnumKeyByValue(Products, Products.GAIT)
            : null;
    const [healthSessions, setHealthSessions] = useState<Session[]>();
    const [hepBuilderDialogOpen, setHEPBuilderDialogOpen] = useState(false);
    const [proAnswersByQuestionsDict, setPROAnswersByQuestionsDict] = useState<PROAnswersByQuestionsDict>();
    const { patientRecordId, patientProduct } = useParams<{ patientRecordId: string; patientProduct: string }>();
    const [product, setProduct] = useState<ProductKey>(
        patientProduct ? (patientProduct.toUpperCase() as ProductKey) : (initProduct as ProductKey),
    );
    const navigate = useNavigate();

    useEffect(() => {
        switch (patientProduct) {
            case 'health':
                setProduct('HEALTH');
                break;
            case 'gait':
                setProduct('GAIT');
                break;
        }
    }, [patientProduct]);

    useEffect(() => {
        if (patientProduct !== product.toLowerCase()) {
            navigate(`${Paths.patients}${patientRecordId}/${product.toLowerCase()}`, { replace: true });
        }
    }, [product]);

    const patientContext = {
        product,
        setProduct,
        patientRecord,
        healthSessions,
        setHealthSessions,
        hepBuilderDialogOpen,
        setHEPBuilderDialogOpen,
        proAnswersByQuestionsDict,
        setPROAnswersByQuestionsDict,
    };
    return <PatientContext.Provider value={patientContext}>{children}</PatientContext.Provider>;
};
