import React from 'react';
import { Button, CssBaseline } from '@mui/material';
import { ThemeProvider } from 'styled-components';
import { exerTheme } from '@/theme/exerTheme';
import { ErrorHeader } from '../../layout/Header/ErrorHeader';
import { ErrorContainer } from './styles';

export const ErrorHandler = () => {
    const reload = () => {
        window.location.reload();
    };

    const goHome = () => {
        window.location.href = '/';
    };

    return (
        <ThemeProvider theme={exerTheme}>
            <CssBaseline />
            <ErrorHeader />
            <ErrorContainer>
                <p>There was an issue we couldn&apos;t recover from. </p>
                <Button onClick={reload}>Go Home</Button>
                <p>Or</p>
                <Button onClick={reload}>Reload this page.</Button>
            </ErrorContainer>
        </ThemeProvider>
    );
};
