import React from 'react';
import { FormControlLabel, FormControlLabelProps, styled, useRadioGroup } from '@mui/material';

interface StyledFormControlLabelProps extends FormControlLabelProps {
    readonly checked: boolean;
}

export const RadioLabel = styled((props: StyledFormControlLabelProps) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
        color: theme.palette.grey[500],
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        margin: '0',
        padding: '10px 15px',
        fontWeight: theme.typography.fontWeightMedium,
        '.MuiFormControlLabel-label': checked && {
            color: theme.palette.grey[900],
        },
        '&:last-of-type': {
            borderBottom: '0',
        },
        '&:hover': {
            backgroundColor: theme.palette.grey[50],
        },
    }),
);

export const ControlLabel = (props: FormControlLabelProps) => {
    const { value } = props;
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === value;
    }

    return <RadioLabel checked={checked} {...props} />;
};
