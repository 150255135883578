import React from 'react';
import { CardMedia } from '@mui/material';
import { HEPBlock, isRepsGoal, isTimeGoal } from '@/common/types';
import { toHHMMSS } from '@/common/utils';
import { ExerciseAnimationLazy as ExerciseAnimation } from '@/components/Health/HEPBuilder/HEPBuilderExercises/ExerciseAnimationLazy';
import { SemiStrong } from '@/components/common/styles';
import { useExercisesContext } from '@/components/context/hooks/ExercisesContext';
import { HEPBlockDetails, HEPBlockWrapper, HEPBlockImgWrapper, HEPBlockImg, HEPBlockInfo } from './styles';

interface Props {
    blocks: HEPBlock[];
}
export const HEPBlocks = (props: Props) => {
    const { blocks } = props;
    const { exercisesDict } = useExercisesContext();

    return (
        <>
            {blocks.map((block: HEPBlock) => {
                return (
                    <HEPBlockWrapper key={block.id} container>
                        <HEPBlockImgWrapper item xs={4} sm={2}>
                            {exercisesDict?.[block.exerciseId]?.animationUrl ? (
                                <CardMedia
                                    alt={exercisesDict?.[block.exerciseId]?.name}
                                    component={(_) => (
                                        <ExerciseAnimation
                                            animationUrl={exercisesDict[block.exerciseId].animationUrl!}
                                            exerciseMetricId={block.exerciseId}
                                            activeFrame={exercisesDict[block.exerciseId].animationThumbnailFrame}
                                            style={{
                                                minWidth: 90,
                                                width: 90,
                                                padding: 0.5,
                                            }}
                                        />
                                    )}
                                />
                            ) : (
                                <HEPBlockImg src={exercisesDict?.[block.exerciseId]?.thumbnailUrl} />
                            )}
                        </HEPBlockImgWrapper>
                        <HEPBlockInfo item xs={8} sm={10}>
                            <SemiStrong>{exercisesDict?.[block.exerciseId]?.name}</SemiStrong>
                            <HEPBlockDetails>
                                {block.setGoal && (
                                    <>
                                        {isRepsGoal(block.setGoal)
                                            ? block.setGoal.reps && `Repetitions: ${block.setGoal.reps} \u00a0 \u00a0`
                                            : null}
                                        {isTimeGoal(block.setGoal)
                                            ? `Time Goal: ${toHHMMSS(block.setGoal.time)} \u00a0 \u00a0`
                                            : null}
                                    </>
                                )}
                                {block.numSets && <>Sets: {block.numSets}</>}
                            </HEPBlockDetails>
                        </HEPBlockInfo>
                    </HEPBlockWrapper>
                );
            })}
        </>
    );
};
