import React from 'react';
import { useTable } from 'react-table';
import { Paths } from '@/common/const';
import { LocationFilter } from '@/components/common/Table/Filters/LocationFilter';
import { PatientProductFilter } from '@/components/common/Table/Filters/PatientProductFilter';
import { PractitionersFilter } from '@/components/common/Table/Filters/PractitionersFilter';
import { PaginationContainer } from '@/components/common/Table/PaginationContainer';
import { ListControlsContainer, PatientsTableWrapper } from '@/components/common/Table/PatientsTable/styles';
import { SortableTableHeaderCell } from '@/components/common/Table/SortableTableHeaderCell';
import { TableSearchFilter } from '@/components/common/Table/TableSearchFilter/TableSearchFilter';
import { TableWrapper, TableHeader, TableCell, FullWidthTable } from '@/components/common/Table/styles';
import { usePaginationContext } from '@/components/context/hooks/PaginationContext';
import { NoPatients } from '../../NoPatients';
import { PatientTableRow } from '../styles';

interface AllPatientsTableProps {
    columnStructure: any[];
    data: any[];
    currentTab?: number;
}

export const AllPatientsTable = (props: AllPatientsTableProps) => {
    const { columnStructure, data, currentTab } = props;
    const { globalSearch, handleSetGlobalSearch } = usePaginationContext();
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns: columnStructure,
        data,
    });

    const nonSorted = ['patientRecord', 'hasAccount', 'product', 'program'];
    const nonHeadered = ['hasAccount', 'patientRecord'];

    return (
        <PatientsTableWrapper>
            <ListControlsContainer>
                <TableSearchFilter
                    globalFilter={globalSearch}
                    setGlobalFilter={handleSetGlobalSearch}
                    searchLabel="Search Patients"
                />
                <PatientProductFilter />
                <LocationFilter />
                <PractitionersFilter />
            </ListControlsContainer>
            <TableWrapper>
                <FullWidthTable {...getTableProps()}>
                    <TableHeader>
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                key={headerGroup.id + '-' + headerGroup.Cell?.toString}
                            >
                                {headerGroup.headers.map((column) => {
                                    return (
                                        <SortableTableHeaderCell
                                            key={column.id}
                                            column={column}
                                            nonHeadered={nonHeadered}
                                            nonSorted={nonSorted}
                                        />
                                    );
                                })}
                            </tr>
                        ))}
                    </TableHeader>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <PatientTableRow
                                    {...row.getRowProps()}
                                    key={row.original.patientRecord?.id}
                                    to={`${Paths.patients}${row.original.patientRecord?.id}`}
                                    $isDisabled={currentTab === 1}
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <TableCell
                                                {...cell.getCellProps()}
                                                key={cell.column.id}
                                                style={{
                                                    width: cell.column.width,
                                                    maxWidth: cell.column.width,
                                                    minWidth: cell.column.width,
                                                }}
                                                size="small"
                                            >
                                                {cell.render('Cell')}
                                            </TableCell>
                                        );
                                    })}
                                </PatientTableRow>
                            );
                        })}
                    </tbody>
                </FullWidthTable>
            </TableWrapper>
            {rows.length === 0 && <NoPatients />}
            <PaginationContainer />
        </PatientsTableWrapper>
    );
};
