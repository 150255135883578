import React, { useEffect, useRef, useState } from 'react';
import { Download } from '@mui/icons-material';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { Button } from '@/components/common/button/button';
import { Result, resultIsError } from '@/services/HttpService';
import { ScanPoseLog } from './types';

interface ScanSessionPoseLogActionProps {
    sessionUUID: string;
}

export const ScanSessionPoseLogAction = (props: ScanSessionPoseLogActionProps) => {
    const { sessionUUID } = props;
    const { httpService } = useHttpContext();
    const aRef = useRef<HTMLAnchorElement>(null);
    const [jsonString, setJSONString] = useState<string | null>();

    const handleClick = async () => {
        const res = await httpService.adminGetSessionPoseLogSignedUrl('scan', sessionUUID);
        if (resultIsError(res)) {
            alert(res.message);
            return;
        }
        const poseRes: Result<ScanPoseLog> = await httpService.getExternalUrl(res);
        if (resultIsError(poseRes)) {
            console.log('Could not get log.');
            return;
        }
        setJSONString('text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(poseRes)));
    };

    useEffect(() => {
        if (jsonString) {
            aRef.current?.click();
        }
        setJSONString(null);
    }, [jsonString]);

    return (
        <>
            <Button onClick={handleClick} startIcon={<Download />} variant="secondary" size="small">
                Log
            </Button>
            <a
                ref={aRef}
                style={{ display: 'none' }}
                href={`data:${jsonString}`}
                download={`Scan_session_pose_log_${sessionUUID}.json`}
            ></a>
        </>
    );
};
