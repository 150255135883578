import React from 'react';
import { styled, SvgIcon } from '@mui/material';

export const SortIconWrapper = styled('div')({
    display: 'inline-block',
    width: '10px',
});

export const SortIcon = (props) => {
    return (
        <SortIconWrapper>
            <SvgIcon {...props} sx={{ overflow: 'visible' }} width="10" height="6" viewBox="0 0 11 7">
                <path
                    d="M9.61362 4.03351L5.67656 0.709154C5.36557 0.446558 4.86268 0.446558 4.55499 0.709154L0.614624 4.03351C0.115048 4.45534 0.469052 5.17887 1.17706 5.17887H9.05118C9.75919 5.17887 10.1132 4.45534 9.61362 4.03351Z"
                    fill="current-color"
                />
            </SvgIcon>
            <SvgIcon {...props} sx={{ overflow: 'visible' }} width="10" height="5" viewBox="0 0 11 6">
                <path
                    d="M4.87958 4.12822L4.87741 4.12638L0.937199 0.802155C0.937179 0.802139 0.937159 0.802122 0.937139 0.802105C0.937106 0.802077 0.937074 0.80205 0.937041 0.802022C0.891135 0.763211 0.883765 0.735497 0.882044 0.727084C0.879814 0.716177 0.879682 0.69843 0.891929 0.673454C0.915602 0.625178 0.997682 0.538818 1.17706 0.538818H9.05118C9.23056 0.538818 9.31264 0.625178 9.33632 0.673454C9.34857 0.69843 9.34843 0.716177 9.3462 0.727084C9.34448 0.735507 9.33709 0.763274 9.29105 0.802155L5.35399 4.12651C5.22827 4.23266 4.9993 4.23039 4.87958 4.12822Z"
                    fill="current-color"
                    stroke="current-color"
                />
            </SvgIcon>
        </SortIconWrapper>
    );
};
