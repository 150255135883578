import { styled } from '@mui/material';

export const CalenderContainer = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),

    '& .rdrSelected': {
        borderRadius: 4,
    },

    '& > div': {
        maxWidth: 300,
    },
}));

export const InfoTextContainer = styled('div')(({ theme }) => ({
    margin: `0 ${theme.spacing(2.25)} ${theme.spacing(2.25)}`,
    textAlign: 'center',
}));
