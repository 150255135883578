import React from 'react';
import { SvgIcon } from '@mui/material';

export const UsersIcon = (props) => {
    return (
        <>
            <SvgIcon width="32" height="33" viewBox="0 0 32 33" fill="none" {...props}>
                <path
                    d="M23.4598 15.3258C25.5963 15.3258 27.3294 13.594 27.3294 11.4575C27.3294 9.32106 25.5963 7.58917 23.4598 7.58917"
                    stroke="currentColor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M25.2389 19.5297C25.8769 19.5737 26.5112 19.6641 27.1358 19.8047C28.0036 19.9746 29.0473 20.3302 29.4189 21.1088C29.656 21.6074 29.656 22.188 29.4189 22.6879C29.0486 23.4664 28.0036 23.8209 27.1358 23.9993"
                    stroke="currentColor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.38677 15.3258C6.25032 15.3258 4.51721 13.594 4.51721 11.4575C4.51721 9.32106 6.25032 7.58917 8.38677 7.58917"
                    stroke="currentColor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.60777 19.5297C5.96977 19.5737 5.33544 19.6641 4.71088 19.8047C3.84311 19.9746 2.79933 20.3302 2.42899 21.1088C2.19066 21.6074 2.19066 22.188 2.42899 22.6879C2.79811 23.4664 3.84311 23.8209 4.71088 23.9993"
                    stroke="currentColor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.9171 20.3627C20.2462 20.3627 23.9446 21.0178 23.9446 23.6395C23.9446 26.26 20.2706 26.9395 15.9171 26.9395C11.5867 26.9395 7.88953 26.2844 7.88953 23.6627C7.88953 21.0411 11.5635 20.3627 15.9171 20.3627Z"
                    stroke="currentColor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.917 16.6232C13.0619 16.6232 10.7727 14.334 10.7727 11.4777C10.7727 8.62256 13.0619 6.33334 15.917 6.33334C18.7721 6.33334 21.0614 8.62256 21.0614 11.4777C21.0614 14.334 18.7721 16.6232 15.917 16.6232Z"
                    stroke="currentColor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </SvgIcon>
        </>
    );
};
