import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import { Program, PatientRecord } from '@/common/types';

interface IHEPContext {
    program: Program | null;
    setHEP: Dispatch<SetStateAction<Program | null>>;
}

export const HEPContext = createContext<IHEPContext | null>(null);

interface HEPProviderProps {
    children: JSX.Element | JSX.Element[];
    patientRecord: PatientRecord;
}
export const HEPProvider = (props: HEPProviderProps) => {
    const { children, patientRecord } = props;
    const initHEP =
        patientRecord.productData?.health?.heps && patientRecord.productData?.health?.heps.length > 0
            ? patientRecord.productData?.health?.heps[0]
            : undefined;
    const [program, setHEP] = useState<Program | null>(initHEP as Program);

    const hepContext = {
        program,
        setHEP,
    };
    return <HEPContext.Provider value={hepContext}>{children}</HEPContext.Provider>;
};
