import React from 'react';
import { ExpandMore } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { ExerciseSessionType, SelfReportedEnum } from '@/common/const';
import { mixpanel } from '@/common/mixpanel';
import { ExerciseSetInfo, Program, ReportingMetric, Session } from '@/common/types';
import { calculatePercentComplete, groupByBlock, groupByExerciseAndSide } from '@/common/utils';
import { ExerciseROMSet } from './ExerciseSet/ExerciseROMSet';
import { ExerciseRepsOrTimeSet } from './ExerciseSet/ExerciseRepsOrTimeSet';
import { ExerciseRepsSet } from './ExerciseSet/ExerciseRepsSet';
import { ExerciseTimeSet } from './ExerciseSet/ExerciseTimeSet';
import { SelfReportedTooltip } from './SelfReportedTooltip';
import { SessionGridWrapper } from './SessionGridWrapper';
import {
    SessionTitle,
    SessionTime,
    SessionExpansionPanel,
    SessionExpansionPanelSummary,
    SessionExpansionPanelDetails,
    SessionExpansionPanelSummaryHeader,
    SessionExpansionPanelSummaryHeaderTop,
    SessionHeaderTopRight,
    SessionHeaderTopRightTotal,
} from './styles';

interface Props {
    session: Session;
    program?: Program | null;
}

export const SessionExpandingRow = (props: Props) => {
    const { session, program } = props;

    const getSelfReportedStatus = (sets: ExerciseSetInfo[]): SelfReportedEnum => {
        return sets.every((set) => {
            return set.setInfo.resolution === 'SELF_REPORTED';
        })
            ? SelfReportedEnum.FULL
            : sets.some((set) => {
                  return set.setInfo.resolution === 'SELF_REPORTED';
              })
            ? SelfReportedEnum.PARTIAL
            : SelfReportedEnum.NONE;
    };
    const createdTime = session.createdDate
        ? DateTime.fromISO(session.createdDate).toLocaleString(DateTime.TIME_SIMPLE)
        : null;
    const exerciseSets: JSX.Element[] = [];

    const blocks = session.session?.sets
        ? program
            ? groupByBlock(session.session.sets, program)
            : groupByExerciseAndSide(session.session.sets)
        : null;

    let i = 0;
    if (blocks) {
        for (const [_, block] of blocks) {
            switch (block.exercise.sets[0]?.setInfo.reportingMetric) {
                case ReportingMetric.ROM:
                    exerciseSets.push(
                        <SessionGridWrapper
                            exerciseName={block.exercise.name}
                            index={i + 1}
                            key={i + 1}
                            selfReportedStatus={SelfReportedEnum.NONE}
                        >
                            <ExerciseROMSet exerciseSet={block.exercise} key={i + 1} />
                        </SessionGridWrapper>,
                    );
                    break;
                case ReportingMetric.REPS_OR_TIME:
                    exerciseSets.push(
                        <SessionGridWrapper
                            exerciseName={block.exercise.name}
                            index={i + 1}
                            key={i + 1}
                            selfReportedStatus={getSelfReportedStatus(block.exercise.sets)}
                        >
                            <ExerciseRepsOrTimeSet exerciseSet={block.exercise} key={i + 1} />
                        </SessionGridWrapper>,
                    );
                    break;
                case ReportingMetric.REPS:
                    exerciseSets.push(
                        <SessionGridWrapper
                            exerciseName={block.exercise.name}
                            index={i + 1}
                            key={i + 1}
                            selfReportedStatus={getSelfReportedStatus(block.exercise.sets)}
                        >
                            <ExerciseRepsSet exerciseSet={block.exercise} key={i + 1} />
                        </SessionGridWrapper>,
                    );
                    break;
                case ReportingMetric.TIME:
                    exerciseSets.push(
                        <SessionGridWrapper
                            exerciseName={block.exercise.name}
                            index={i + 1}
                            key={i + 1}
                            selfReportedStatus={getSelfReportedStatus(block.exercise.sets)}
                        >
                            <ExerciseTimeSet exerciseSet={block.exercise} key={i + 1} />
                        </SessionGridWrapper>,
                    );
                    break;
                default:
                    return null;
            }
            i++;
        }
    }

    const percentComplete = blocks ? calculatePercentComplete(blocks) : 0;

    const handleAccordian = (_, expanded) => {
        if (expanded) {
            mixpanel.track('Viewed Health Session Data', { $current_url: '' });
        }
    };

    return (
        <SessionExpansionPanel elevation={0} onChange={handleAccordian}>
            <SessionExpansionPanelSummary expandIcon={<ExpandMore />}>
                <SessionExpansionPanelSummaryHeader>
                    <SessionExpansionPanelSummaryHeaderTop>
                        <SessionTitle data-cy="session-expanding-row-title">
                            {session.session?.type === ExerciseSessionType.HEPSession
                                ? session.session.hepName || 'Exercise Program'
                                : session.session?.sets[0]?.exercise?.name}
                            <SelfReportedTooltip selfReportedStatus={session.session.selfReported} />
                        </SessionTitle>
                        <SessionHeaderTopRight>
                            {percentComplete === 100 && (
                                <SessionHeaderTopRightTotal color="success" label={`Completion: ${percentComplete}%`} />
                            )}
                            {percentComplete === 0 && (
                                <SessionHeaderTopRightTotal color="warning" label={`Completion: ${percentComplete}%`} />
                            )}
                            {percentComplete < 100 && percentComplete > 0 && (
                                <SessionHeaderTopRightTotal color="info" label={`Completion: ${percentComplete}%`} />
                            )}
                            <SessionTime>{createdTime}</SessionTime>
                        </SessionHeaderTopRight>
                    </SessionExpansionPanelSummaryHeaderTop>
                </SessionExpansionPanelSummaryHeader>
            </SessionExpansionPanelSummary>
            <SessionExpansionPanelDetails>{exerciseSets}</SessionExpansionPanelDetails>
        </SessionExpansionPanel>
    );
};
