import { useEffect, useState } from 'react';
import { reorderBodyTargetSet } from '@/common/utils';
import { ExerciseFilterDict, exerciseFilters, STRING_FILTERS } from '../ExerciseFilters/ExerciseFilters';
import { useExerciseListContext } from '../ExerciseList/state/hooks/ExerciseListContext';

export default function useExerciseFilters() {
    const [filters, setFilters] = useState<ExerciseFilterDict>();
    const { exercises, activeFilters, filterExercises, setActiveFilters, clearExerciseFilter } =
        useExerciseListContext();
    const [exercisesIncludeAssessments, setExercisesIncludeAssessments] = useState(false);
    const [filtersDetermined, setFiltersDetermined] = useState(false);

    useEffect(() => {
        const exerciseTypes = new Set<string>();
        const bodyTargets = new Set<string>();
        const equipment = new Set<string>();
        const subjectPositions = new Set<string>();
        const cameraOrientations = new Set<string>();

        exercises &&
            exercises.forEach((exercise) => {
                exercise.subjectPosition && subjectPositions.add(exercise.subjectPosition);
                exercise.cameraOrientation && cameraOrientations.add(exercise.cameraOrientation);
                exercise.exerciseType && exerciseTypes.add(exercise.exerciseType);
                exercise.bodyTargets?.forEach((bodyTarget) => {
                    bodyTarget && bodyTargets.add(bodyTarget);
                });
                exercise.equipment && equipment.add(exercise.equipment);
                if (exercise.name.includes('Assessment')) {
                    setExercisesIncludeAssessments(true);
                }
                setFiltersDetermined(true);
            });

        const orderedBodyTargets = reorderBodyTargetSet(bodyTargets);
        const filters = {
            ...exerciseFilters,
            exerciseType: { ...exerciseFilters.exerciseType, options: exerciseTypes },
            bodyTargets: { ...exerciseFilters.bodyTargets, options: orderedBodyTargets },
            equipment: { ...exerciseFilters.equipment, options: equipment },
            subjectPosition: { ...exerciseFilters.subjectPosition, options: subjectPositions },
            cameraOrientation: { ...exerciseFilters.cameraOrientation, options: cameraOrientations },
        };

        setFilters(filters);
    }, [exercises]);

    const updateFilters = (type: string, newValue: string, include: boolean) => {
        const active = { ...activeFilters };
        let value: string | string[] | null | undefined;
        if (STRING_FILTERS.includes(type)) {
            value = active[type]?.value;
            if (include) {
                value = active && active[type]?.value;
            } else {
                value = null;
            }
        } else {
            value = (active[type]?.value as string[]) || [];
            value = include ? [...value].concat([newValue]) : value.filter((v) => v !== newValue);
        }
        active[type] = { type, value };
        if (!value || value.length === 0) {
            delete active[type];
        }
        filterExercises({ active });
        setActiveFilters(active);
    };

    const clearFilters = () => {
        clearExerciseFilter();
        setActiveFilters({});
    };

    return {
        filters,
        activeFilters,
        updateFilters,
        clearFilters,
        exercisesIncludeAssessments,
        filtersDetermined,
    };
}
