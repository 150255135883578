import React from 'react';
import { SvgIcon } from '@mui/material';

export const SortedIcon = (props) => {
    return (
        <SvgIcon
            {...props}
            // color="secondary"
            sx={{
                transform: `rotate(${props.dir === 'ASC' ? 0 : '180deg'})`,
                // left: `${props.dir === 'DESC' ? '6px' : 0}`,
                overflow: 'visible',
            }}
            width="10"
            height="6"
            viewBox="0 0 11 7"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M9.61362 4.03351L5.67656 0.709154C5.36557 0.446558 4.86268 0.446558 4.55499 0.709154L0.614624 4.03351C0.115048 4.45534 0.469052 5.17887 1.17706 5.17887H9.05118C9.75919 5.17887 10.1132 4.45534 9.61362 4.03351Z"
                fill="current-color"
            />
        </SvgIcon>
    );
};
