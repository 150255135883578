import {
    styled,
    Avatar,
    ListItem as MuiListItem,
    Grid,
    ListItemIcon,
    ListItemText,
    FormControlLabel,
} from '@mui/material';

export const TimeAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.primary[300],
    color: theme.palette.primary[900],
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(12),
}));

export const ListItem = styled(MuiListItem)(({ theme }) => ({
    maxWidth: '100%',
}));

export const ReviewContentGrid = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    maxWidth: '100%',
    marginTop: '0',
    marginLeft: '0',
    padding: `0 ${theme.spacing(4)} ${theme.spacing(4)} 0`,
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
    },
}));

export const CardIconWrapper = styled(ListItemIcon)(({ theme }) => ({
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.grey[500],

    '&.MuiListItemIcon-root': {
        minWidth: '27px',
    },

    '.MuiSvgIcon-root': {
        fill: 'none',
    },
}));

export const CardItemText = styled(ListItemText)(({ theme }) => ({
    color: theme.palette.grey[700],
}));

export const InteractionsGridAction = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
}));

export const CheckBoxWrapper = styled(FormControlLabel)(({ theme }) => ({
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
}));

export const InteractionType = styled('span')(({ theme }) => ({
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(12),
    margin: `0 ${theme.spacing(0.5)}`,
    fontWeight: '500',
}));
