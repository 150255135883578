import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { AdminLocation, AdminOrganization } from '@/common/types';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { resultIsError } from '@/services/HttpService';
import { AdminLocationsTable } from './AdminLocationsTable';
import { KeywordCell } from './KeywordCell';

export type CellKeyword = {
    id: number;
    keyword: string | null;
};

type LocationRow = Omit<AdminLocation, 'organization'> & {
    organization: string;
    locationMeta: CellKeyword;
};

export const AdminLocationsListContainer = () => {
    const { httpService } = useHttpContext();
    const { errorToast, successToast } = toasts;
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<LocationRow[]>();
    const [organizations, setOrganizations] = useState<AdminOrganization[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<string>('');

    const convertToRows = (locations: AdminLocation[]): LocationRow[] => {
        return locations.map((location: AdminLocation) => {
            return {
                ...location,
                organization: location.organization.name,
                locationMeta: {
                    id: location.id!,
                    keyword: location.keyword ?? null,
                },
            };
        });
    };

    const getLocations = async () => {
        setIsLoading(true);
        const res = await httpService.getAdminLocations();

        if (resultIsError(res)) {
            errorToast('Unable to get locations');
        } else {
            const orderedByName = res.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });

            const objectsMap = new Map();
            orderedByName.map((object) => {
                objectsMap.set(object.organization.id, object.organization);
            });
            const reducedOrganizations = Array.from(objectsMap, ([_, value]) => value).sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            setOrganizations(reducedOrganizations);

            setRows(convertToRows(orderedByName));
        }
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await getLocations();
        })();
    }, []);

    const handleUpdateKeyword = async (id: number, keyword: string | null) => {
        const res = await httpService.updateAdminLocationKeyword(id, keyword);

        if (!res || resultIsError(res)) {
            errorToast('Could not update keyword.');
            return false;
        } else {
            successToast(`Successfully updated keyword.`);
            getLocations();
            return true;
        }
    };

    const handleChange = (event) => {
        setSelectedOrganization(event.target.value);
    };

    const columnStructure = [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: (cell) => <strong>{cell.value}</strong>,
        },
        {
            Header: 'Organization',
            accessor: 'organization',
            Cell: (cell) => <strong>{cell.value}</strong>,
        },
        {
            Header: 'Keyword',
            accessor: 'locationMeta',
            Cell: (cell) => {
                return <KeywordCell cell={cell.value} handleUpdateKeyword={handleUpdateKeyword} />;
            },
        },
        {
            Header: 'Contact List',
            accessor: 'contactList',
            Cell: (cell) => {
                return <div>{cell.value}</div>;
            },
        },
        {
            Header: 'City',
            accessor: 'city',
            Cell: (cell) => {
                return <div>{cell.value}</div>;
            },
        },
        {
            Header: 'State',
            accessor: 'state',
            Cell: (cell) => {
                return <div>{cell.value}</div>;
            },
        },
        {
            Header: 'Country',
            accessor: 'country',
            Cell: (cell) => {
                return <div>{cell.value}</div>;
            },
        },
    ];

    return (
        <div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                rows && (
                    <>
                        <FormControl fullWidth>
                            <InputLabel id="org-select-label">Organizations</InputLabel>
                            <Select
                                labelId="org-select-label"
                                id="org-select"
                                value={selectedOrganization}
                                label="Organizations"
                                onChange={handleChange}
                            >
                                <MenuItem value="">All Organizations</MenuItem>
                                {organizations.map((org) => (
                                    <MenuItem value={org.name} key={org.id}>
                                        {org.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <AdminLocationsTable
                            columnStructure={columnStructure}
                            data={rows}
                            selectedOrg={selectedOrganization}
                        />
                    </>
                )
            )}
        </div>
    );
};
