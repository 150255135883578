import React from 'react';
import { Box } from '@mui/material';
import { DemoHandFlexionIcon, DemoHandSupinationIcon, DemoKapandjiIcon } from '@/components/common/Icons/DemoHandIcon';
import FistIcon from '@/images/Fist.png';
import STSIcon from '@/images/SitToStandRom.png';
import TimerIcon from '@/images/Timer.png';
import { AssessmentColumn } from '../styles';
import { AssessmentAccordionCard } from './AssessmentAccordionCard';

export const AssessmentList = () => {
    return (
        <>
            <AssessmentColumn item xs={12} md={6} lg={4}>
                <Box>
                    <AssessmentAccordionCard
                        title="Sit to Stand"
                        subTitle="Front-Facing"
                        body="The front-facing Sit-to-Stand functional assessment begins when an individual repeatedly stands up from a seated position and sits back down in a chair, facing forward, without using their hands for support, all while being timed until they complete five (5) repetitions."
                        icon={
                            <Box>
                                <img src={STSIcon} width={60} />
                            </Box>
                        }
                    />
                </Box>
                <Box>
                    <AssessmentAccordionCard
                        title="Sit to Stand"
                        subTitle="Side-Facing"
                        body="The side-facing Sit-to-Stand functional assessment begins when an individual repeatedly stands up from a seated position and then sits back down in a chair, without using their hands for support, all while being timed until they complete five (5) repetitions."
                        icon={
                            <Box>
                                <img src={STSIcon} width={60} />
                            </Box>
                        }
                    />
                </Box>
                <Box>
                    <AssessmentAccordionCard
                        title="Sit to Stand 30s"
                        subTitle="Front-Facing"
                        body="The Sit-to-Stand for 30 Seconds functional assessment begins when an individual stands up from a seated position and sits back down in a chair, facing forward, as many times as possible within 30 seconds, without using their hands for support."
                        icon={
                            <Box>
                                <img src={STSIcon} width={60} />
                            </Box>
                        }
                    />
                </Box>
                <Box>
                    <AssessmentAccordionCard
                        title="Sit to Stand"
                        subTitle="ROM"
                        body="The side-facing Sit-to-Stand Range-of-Motion assessment involves an individual standing up from a seated position and sitting back down, while their range of motion and technique is observed from a side view."
                        icon={
                            <Box>
                                <img src={STSIcon} width={60} />
                            </Box>
                        }
                    />
                </Box>
                <Box>
                    <AssessmentAccordionCard
                        title="Functional Reach"
                        subTitle="ROM"
                        body="The Functional Reach Test is a healthcare assessment where an individual stands, extends one arm forward at shoulder height, and reaches as far as possible without taking a step, to measure their stability and reach distance."
                        icon={
                            <Box>
                                <img src={FistIcon} width={60} />
                            </Box>
                        }
                    />
                </Box>
                <Box>
                    <AssessmentAccordionCard
                        title="Timed Up & Go"
                        subTitle="Side-Facing"
                        body="The side-facing Timed Up & Go (TUG) functional assessment involves timing an individual who begins seated, stands up, walks three meters to one side, turns, and walks back to the chair to sit down."
                        icon={
                            <Box>
                                <img src={TimerIcon} width={60} />
                            </Box>
                        }
                    />
                </Box>
            </AssessmentColumn>

            <AssessmentColumn item xs={12} md={6} lg={4}>
                <Box>
                    <AssessmentAccordionCard
                        title="Timed Up & Go"
                        subTitle="Side-Facing"
                        body="The side-facing Timed Up & Go (TUG) functional assessment involves timing an individual who begins seated, stands up, walks three meters to one side, turns, and walks back to the chair to sit down."
                        icon={
                            <Box>
                                <img src={TimerIcon} width={60} />
                            </Box>
                        }
                    />
                </Box>
                <Box>
                    <AssessmentAccordionCard
                        title="Posture"
                        subTitle="Side-Facing"
                        body="The side-facing posture assessment in healthcare involves observing and evaluating an individual's posture from a side view to identify any misalignments or deviations in the spine and overall body posture."
                        icon={
                            <Box>
                                <img src={TimerIcon} width={60} />
                            </Box>
                        }
                    />
                </Box>
                <Box>
                    <AssessmentAccordionCard
                        title="Trunk Flexion"
                        subTitle="Side-Facing"
                        body="The side-facing posture assessment in healthcare involves observing and evaluating an individual's posture from a side view to identify any misalignments or deviations in the spine and overall body posture."
                        icon={
                            <Box>
                                <img src={TimerIcon} width={60} />
                            </Box>
                        }
                    />
                </Box>
                <Box>
                    <AssessmentAccordionCard
                        title="Gait"
                        subTitle="Side-Facing"
                        body="The side-facing gait analysis assessment in healthcare involves observing and evaluating an individual's walking pattern from a side view to assess their biomechanics, stride, and any abnormalities in movement."
                        icon={
                            <Box>
                                <img src={TimerIcon} width={60} />
                            </Box>
                        }
                    />
                </Box>
                <Box>
                    <AssessmentAccordionCard
                        title="Gait"
                        subTitle="Front-Facing"
                        body="The front-facing gait analysis assessment involves observing and evaluating an individual's walking pattern from a front view to assess their biomechanics, symmetry, and any abnormalities in movement."
                        icon={
                            <Box>
                                <img src={TimerIcon} width={60} />
                            </Box>
                        }
                    />
                </Box>
                <Box>
                    <AssessmentAccordionCard
                        title="Wrist Flexion"
                        subTitle="ROM"
                        body="The wrist flexion assessment involves measuring the range of motion as an individual bends their wrist forward towards the forearm."
                        icon={
                            <Box
                                style={{
                                    width: 65,
                                    height: 65,
                                    background: '#508bf2',
                                    border: '5px solid #daebfe',
                                    borderRadius: 50,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#fff',
                                }}
                            >
                                <DemoHandFlexionIcon
                                    width={30}
                                    style={{
                                        color: '#fff',
                                        fontSize: '28px',
                                        stroke: '#fff',
                                        strokeWidth: '6',
                                    }}
                                />
                            </Box>
                        }
                    />
                </Box>
            </AssessmentColumn>

            <AssessmentColumn item xs={12} md={6} lg={4}>
                <Box>
                    <AssessmentAccordionCard
                        title="Wrist Extension"
                        subTitle="ROM"
                        body="The wrist extension assessment involves measuring the range of motion as an individual bends their wrist backward away from the forearm."
                        icon={
                            <Box
                                style={{
                                    width: 65,
                                    height: 65,
                                    background: '#508bf2',
                                    border: '5px solid #daebfe',
                                    borderRadius: 50,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#fff',
                                }}
                            >
                                <DemoHandFlexionIcon
                                    width={30}
                                    style={{
                                        color: '#fff',
                                        fontSize: '28px',
                                        stroke: '#fff',
                                        strokeWidth: '6',
                                    }}
                                />
                            </Box>
                        }
                    />
                </Box>
                <Box>
                    <AssessmentAccordionCard
                        title="Kapandji"
                        subTitle="ROM"
                        body="The Kapandji assessment measures the thumb's range of motion by having the individual touch each fingertip with their thumb, scoring each touch on a scale from 0 to 10 based on the thumb's ability to reach and oppose the fingers."
                        icon={
                            <Box
                                style={{
                                    width: 65,
                                    height: 65,
                                    background: '#508bf2',
                                    border: '5px solid #daebfe',
                                    borderRadius: 50,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#fff',
                                }}
                            >
                                <DemoKapandjiIcon
                                    width={30}
                                    style={{
                                        color: '#fff',
                                        fontSize: '28px',
                                        stroke: '#fff',
                                        strokeWidth: '6',
                                    }}
                                />
                            </Box>
                        }
                    />
                </Box>
                <Box>
                    <AssessmentAccordionCard
                        title="Wrist Pronation"
                        subTitle="ROM"
                        body="The hand/wrist pronation assessment involves measuring the range of motion as an individual rotates their forearm and palm downward."
                        icon={
                            <Box
                                style={{
                                    width: 65,
                                    height: 65,
                                    background: '#508bf2',
                                    border: '5px solid #daebfe',
                                    borderRadius: 50,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#fff',
                                }}
                            >
                                <DemoHandSupinationIcon
                                    width={30}
                                    style={{
                                        color: '#fff',
                                        fontSize: '28px',
                                        stroke: '#fff',
                                        strokeWidth: '6',
                                    }}
                                />
                            </Box>
                        }
                    />
                </Box>
                <Box>
                    <AssessmentAccordionCard
                        title="Wrist Supination"
                        subTitle="ROM"
                        body="The hand/wrist supination assessment involves measuring the range of motion as an individual rotates their forearm and palm upward."
                        icon={
                            <Box
                                style={{
                                    width: 65,
                                    height: 65,
                                    background: '#508bf2',
                                    border: '5px solid #daebfe',
                                    borderRadius: 50,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#fff',
                                }}
                            >
                                <DemoHandSupinationIcon
                                    width={30}
                                    style={{
                                        color: '#fff',
                                        fontSize: '28px',
                                        stroke: '#fff',
                                        strokeWidth: '6',
                                    }}
                                />
                            </Box>
                        }
                    />
                </Box>
                <Box>
                    <AssessmentAccordionCard
                        title="Total Active Motion"
                        subTitle="ROM"
                        body="The Total Active Motion (TAM) assessment for the hand/wrist measures the combined range of motion of all finger joints and the wrist, quantifying the hand's overall ability to perform tasks requiring flexion, extension, and other movements."
                        icon={
                            <Box
                                style={{
                                    width: 65,
                                    height: 65,
                                    background: '#508bf2',
                                    border: '5px solid #daebfe',
                                    borderRadius: 50,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#fff',
                                }}
                            >
                                <DemoHandSupinationIcon
                                    width={30}
                                    style={{
                                        color: '#fff',
                                        fontSize: '28px',
                                        stroke: '#fff',
                                        strokeWidth: '6',
                                    }}
                                />
                            </Box>
                        }
                    />
                </Box>
            </AssessmentColumn>
        </>
    );
};
