import { Grid, styled, Typography, Card } from '@mui/material';

export const HEPDetailsWrapper = styled('div')(({}) => ({
    position: 'relative',
}));

export const HEPDetailsLeft = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(4)} ${theme.spacing(4)} 0`,
    borderRadius: '4px',
    boxShadow: '0px 2px 5px rgba(16, 5, 51, 0.04)',
}));

export const HEPDetailsTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(4),
}));

export const HEPDetailsRight = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(2),

    [theme.breakpoints.up('lg')]: {
        marginTop: 0,
    },
}));

export const HEPDetailsActionsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    rowGap: theme.spacing(2),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
        rowGap: 0,
    },

    h4: {
        marginBottom: '0',
    },
}));

export const HEPDetailsNotes = styled(Typography)(({ theme }) => ({
    whiteSpace: 'pre-wrap',
    color: theme.palette.grey[600],
}));

export const HEPDetailsNotesCard = styled(Card)(({}) => ({
    backgroundColor: 'transparent',
}));

export const HEPDetailsCaption = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1.5),
    display: 'block',
    color: theme.palette.grey[700],
}));

export const HEPFrequencyDetails = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[900],
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    fontSize: theme.typography.subtitle1.fontSize,
}));
