import React from 'react';
import { GaitPatientSessionsListContainer } from '@/components/Gait/GaitPatient/Sessions/GaitPatientSessionListContainer';
import { EmptyData } from '@/components/common/EmptyData/EmptyData';
import { DateRangeAtomState } from '@/recoil/atoms/dateRangeAtom';
import { TabPanel } from './TabPanel';

interface AssessmentTabProps {
    currentTab: number;
    gaitSortedSessions: any;
    dateRange: DateRangeAtomState;
    gaitSessionsFilteredByDateRange: any;
}

export const AssessmentTab = (props: AssessmentTabProps) => {
    const { currentTab, gaitSortedSessions, dateRange, gaitSessionsFilteredByDateRange } = props;

    return (
        <TabPanel currentTab={currentTab} index={0} ariaLabel="Assesment tab">
            {gaitSortedSessions && gaitSortedSessions.length > 0 ? (
                <GaitPatientSessionsListContainer sessions={gaitSessionsFilteredByDateRange} timeSpan={dateRange} />
            ) : (
                <>
                    <EmptyData
                        title="No Gait sessions for this user"
                        body="Your patient’s results will show up here when they complete a gait assessment."
                    />
                </>
            )}
        </TabPanel>
    );
};
