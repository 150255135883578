import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useMuiUtils } from '@/common/hooks/MuiUtils';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export const AssessmentAccordionCard = (props: {
    title: string;
    subTitle: string;
    body: string;
    icon: JSX.Element;
}) => {
    const { title, subTitle, body, icon } = props;
    const theme = useMuiUtils();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <Card sx={{ marginBottom: 3, borderBottom: `1px solid ${theme.palette.grey[400]}`, minHeight: '126px' }}>
                <CardHeader
                    avatar={icon}
                    action={
                        <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    }
                    title={title}
                    subheader={subTitle}
                    titleTypographyProps={{ fontSize: 24, fontWeight: '600' }}
                    subheaderTypographyProps={{
                        fontSize: 14,
                        backgroundColor: theme.palette.success[500],
                        color: '#fff',
                        borderRadius: 1,
                        display: 'flex',
                        paddingLeft: 1,
                        paddingRight: 1,
                        paddingTop: 0.2,
                        paddingBottom: 0.2,
                        marginTop: 0.2,
                        width: 'max-content',
                    }}
                    sx={{ padding: 0 }}
                />

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography paragraph sx={{ m: 0 }}>
                            {body}
                        </Typography>
                    </CardContent>
                </Collapse>
            </Card>
        </>
    );
};
