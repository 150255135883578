import React from 'react';
import { Products } from '@/common/const';
import { a11yProps, getStringEnumKeyByValue, isGaitPatient, isHealthOrPROPatient } from '@/common/utils';
import { usePatientContext } from '../context/hooks/PatientContext';
import { PatientProductSelectionTab, PatientProductSelectionTabsWrapper } from './styles';

export const PatientProductSelectionTabs = () => {
    const { patientRecord, product, setProduct } = usePatientContext();

    const handleTabChange = (_, value) => {
        setProduct(value);
    };

    return isHealthOrPROPatient(patientRecord) && isGaitPatient(patientRecord) ? (
        <PatientProductSelectionTabsWrapper value={product} onChange={handleTabChange}>
            <PatientProductSelectionTab
                label={Products.HEALTH}
                value={getStringEnumKeyByValue(Products, Products.HEALTH)}
                data-cy={Products.HEALTH}
                {...a11yProps(Products.HEALTH)}
            />
            <PatientProductSelectionTab
                label={Products.GAIT}
                value={getStringEnumKeyByValue(Products, Products.GAIT)}
                data-cy={`patient-tab-${Products.GAIT}`}
                {...a11yProps(Products.GAIT)}
            />
        </PatientProductSelectionTabsWrapper>
    ) : null;
};
