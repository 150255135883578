import React, { useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Products } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { mixpanel } from '@/common/mixpanel';
import { getStringEnumKeyByValue } from '@/common/utils';
import {
    BulkAddError,
    BulkAddSuccess,
    CsvTemplateContainer,
    UploadCsvColumn,
    UploadCsvWrapper,
    UploadProgress,
    UploadProgressWrapper,
} from '@/components/Patients/styles';
import { Button } from '@/components/common/button/button';
import { resultIsError } from '@/services/HttpService';
import { AddPatientFormWarningText, DragAndDropWrapper } from './styles';

interface Props {
    product: Products;
}

export const BulkAddPatient = (props: Props) => {
    const { product } = props;
    const { httpService } = useHttpContext();
    const [processing, setProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [uploadSuccessful, setUploadSuccessful] = useState(false);
    const [template, setTemplate] = useState<any>();

    useEffect(() => {
        (async () => {
            const res =
                product === Products.HEALTH
                    ? await httpService.getHealthTemplateCsv()
                    : await httpService.getGaitTemplateCsv();
            setTemplate(res);
        })();
    }, []);

    const onFileInput = async (e) => {
        setProcessing(true);
        const file = e[0];
        setErrorMessage('');
        setUploadSuccessful(false);
        const res = await httpService.uploadPatientCsv(file, getStringEnumKeyByValue(Products, product));
        if (resultIsError(res)) {
            setErrorMessage(res.message);
            mixpanel.track('Bulk Add Patient Error');
        } else {
            setUploadSuccessful(true);
            mixpanel.track('Bulk Added Patients', {
                Product: product || 'Gait and Health',
                'Number of Patients': +res,
            });
        }
        setProcessing(false);
    };

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: { 'text/csv': [] },
        onDrop: onFileInput,
    });

    return (
        <>
            <AddPatientFormWarningText>
                {product === Products.HEALTH &&
                    `Each of your patients will receive an email to create an account to use Exer Health on their device`}
                .
            </AddPatientFormWarningText>
            <UploadCsvWrapper>
                <UploadCsvColumn>
                    {processing ? (
                        <UploadProgressWrapper>
                            <UploadProgress />
                        </UploadProgressWrapper>
                    ) : (
                        <>
                            <div {...getRootProps()} style={{ outline: 'none' }}>
                                <DragAndDropWrapper
                                    $isFocused={isFocused}
                                    $isDragAccept={isDragAccept}
                                    $isDragReject={isDragReject}
                                >
                                    <input {...getInputProps()} />
                                    <Button variant="tertiary" onClick={(e) => e.preventDefault}>
                                        Upload from a CSV file
                                    </Button>
                                </DragAndDropWrapper>
                            </div>
                        </>
                    )}
                    <CsvTemplateContainer>
                        Please follow the format of our{' '}
                        <Link
                            target="_blank"
                            download={
                                product === Products.HEALTH
                                    ? 'exer_bulk_add_template_health.csv'
                                    : 'exer_bulk_add_template_gait.csv'
                            }
                            href={URL.createObjectURL(new Blob([template], { type: 'text/csv' }))}
                        >
                            CSV Template
                        </Link>
                    </CsvTemplateContainer>
                    {errorMessage && <BulkAddError> {errorMessage} </BulkAddError>}
                    {uploadSuccessful && <BulkAddSuccess> Upload was successful </BulkAddSuccess>}
                </UploadCsvColumn>
            </UploadCsvWrapper>
        </>
    );
};
