import React, { useEffect, useState } from 'react';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { Location } from '@/common/types';
import { isAdmin } from '@/common/utils';
import { resultIsError } from '@/services/HttpService';
import { OrganizationEmptyEntitiesWrapper } from '../styles';
import { AddPractitioner } from './AddPractitioner/AddPractitioner';

export const EmptyPractitioners = () => {
    const { user } = useExerUser();
    const { httpService } = useHttpContext();
    const [locations, setLocations] = useState<Location[]>();

    useEffect(() => {
        (async () => {
            const res = await httpService.getLocations();
            if (resultIsError(res)) {
                return;
            }
            setLocations(res);
        })();
    }, []);

    return (
        <OrganizationEmptyEntitiesWrapper>
            <h3>No users added yet.</h3>
            {isAdmin(user) && <AddPractitioner isAbsolute={false} locations={locations} />}
        </OrganizationEmptyEntitiesWrapper>
    );
};
