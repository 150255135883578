import React from 'react';
import { Radio } from '@mui/material';
import { ControlLabel } from '@/components/common/ControlLabel/ControlLabel';
import { RadioGroup, AccordionTitle } from './styles';
import { ROMDataDict } from './types';

interface PatientChartRadioGroupProps {
    readonly title: string;
    readonly options: ROMDataDict;
    readonly handleChange: (options: { optionId: string; optionName: string }) => void;
    readonly selectedOption?: string;
}

export const PatientChartRadioGroup = ({
    title,
    options,
    handleChange,
    selectedOption,
}: PatientChartRadioGroupProps) => {
    const handleOptionChange = ({ optionName, optionId }: { optionName: string; optionId: string }) => {
        handleChange({ optionId: optionId, optionName: optionName });
    };

    const AccordionItems = () => {
        return (
            <>
                {Object.values(options).map((option, key) => {
                    return (
                        <ControlLabel
                            key={option.exerciseId}
                            value={option.exerciseId}
                            label={option.exerciseName}
                            onChange={() => {
                                handleOptionChange({ optionName: option.exerciseName, optionId: option.exerciseId });
                            }}
                            control={<Radio />}
                        />
                    );
                })}
            </>
        );
    };

    return (
        <>
            <AccordionTitle variant="caption">{title}</AccordionTitle>
            <RadioGroup name={`${title} options`} defaultValue={selectedOption} value={selectedOption}>
                <AccordionItems />
            </RadioGroup>
        </>
    );
};
