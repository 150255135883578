import { atom } from 'recoil';
import { Program } from '@/common/types';

interface HEPDict {
    [key: number]: Program;
}
export const hepState = atom<HEPDict>({
    key: 'hepDict',
    default: undefined,
});
