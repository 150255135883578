import { Button, styled } from '@mui/material';

export const NotFoundWrapper = styled('div')(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(6),

    '& img': {
        width: 240,
        marginBottom: theme.spacing(4),
    },
}));

export const NotFoundLink = styled(Button)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.primary.main,
}));
