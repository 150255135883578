import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { FormGroup, Checkbox, MenuItem, MenuList, FormControl, Divider } from '@mui/material';
import { Location } from '@/common/types';
import { ServerError } from '@/components/common/Form/styles';
import { TrashIcon } from '@/components/common/Icons/TrashIcon';
import { Button } from '@/components/common/button/button';
import {
    MenuWrapper,
    MenuContent,
    MenuItemText,
    FormControlLabel,
    ButtonWrapper,
    MenuHeader,
    MenuSelectedBadge,
    MultiSelectButton,
    MultiSelectBadgeWrapper,
} from './styles';

interface MultiSelectFilterProps {
    options: Location[];
    selected: number[];
    handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleClearSelection: () => void;
    handleSelectAll: () => void;
    label: string;
    prefix?: string;
    error?: string;
}

export const MultiSelectField = (props: MultiSelectFilterProps) => {
    const { selected, options, handleRadioChange, handleClearSelection, handleSelectAll, label, prefix, error } = props;
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuIsOpen(true);
        setMenuAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchor(null);
        setMenuIsOpen(false);
    };

    return (
        <>
            <div>
                <ButtonWrapper>
                    <MultiSelectButton
                        variant="tertiary"
                        size="small"
                        onClick={handleClick}
                        data-cy={`${prefix}button`}
                        endIcon={menuIsOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    >
                        <MultiSelectBadgeWrapper>
                            <span>{label}</span>
                            <MenuSelectedBadge color="secondary" badgeContent={selected?.length} />
                        </MultiSelectBadgeWrapper>
                    </MultiSelectButton>
                </ButtonWrapper>
                {error && (
                    <div style={{ marginLeft: 0 }}>
                        <ServerError style={{ margin: '3px 14px 0px' }}>{error || '\u00a0'}</ServerError>
                    </div>
                )}
            </div>

            <MenuWrapper
                anchorEl={menuAnchor}
                id={prefix}
                open={menuIsOpen}
                onClose={handleClose}
                elevation={0}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        mt: '20px',
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuContent>
                    <>
                        <MenuHeader>
                            <Button
                                aria-label="Select all"
                                onClick={handleSelectAll}
                                startIcon={<CheckBoxOutlinedIcon />}
                                size="small"
                                variant="text"
                            >
                                select all
                            </Button>
                            <Button
                                aria-label="Reset selection"
                                onClick={handleClearSelection}
                                startIcon={<TrashIcon />}
                                size="small"
                                variant="text"
                            >
                                clear
                            </Button>
                        </MenuHeader>
                        <Divider />
                        <MenuList sx={{ maxHeight: 500, overflowY: 'scroll' }}>
                            <FormControl component="fieldset" variant="standard">
                                <FormGroup>
                                    {options.map((option) => {
                                        return (
                                            <MenuItem key={option.id}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={selected?.includes(option.id!)}
                                                            onChange={handleRadioChange}
                                                            data-cy={prefix ? `${prefix}checkbox-${option}` : null}
                                                            name={option.id?.toString()}
                                                        />
                                                    }
                                                    label={<MenuItemText>{option.name}</MenuItemText>}
                                                />
                                            </MenuItem>
                                        );
                                    })}
                                </FormGroup>
                            </FormControl>
                        </MenuList>
                    </>
                </MenuContent>
            </MenuWrapper>
        </>
    );
};
