import React, { createContext, useEffect, useState } from 'react';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { AssessmentsDict } from '@/common/types';
import { resultIsError } from '@/services/HttpService';

interface IAssessmentsContext {
    assessmentsDict?: AssessmentsDict;
}

export const AssessmentsContext = createContext<IAssessmentsContext>({ assessmentsDict: undefined });

export const AssessmentsProvider = (props) => {
    const { httpService } = useHttpContext();
    const { user } = useExerUser();
    const { errorToast } = toasts;
    const [assessmentsDict, setAssessmentsDict] = useState<AssessmentsDict>();

    const getAssessments = async () => {
        const res = await httpService.adminGetAssessments();
        if (!res || resultIsError(res)) {
            errorToast("Couldn't fetch assessments :(");
        } else {
            const assDict = {};
            res.forEach((ass) => {
                assDict[ass.id] = ass;
            });
            setAssessmentsDict(assDict);
        }
    };

    useEffect(() => {
        if (!assessmentsDict && user.isAuthenticated) {
            (async () => {
                await getAssessments();
            })();
        }
    }, [user.isAuthenticated]);

    const ctx = { assessmentsDict };
    return <AssessmentsContext.Provider value={ctx}>{props.children}</AssessmentsContext.Provider>;
};
