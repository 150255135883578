import React, { lazy, Suspense } from 'react';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { ExerciseAnimationProps } from './ExerciseAnimation';

const ExerciseAnimation = lazy(
    () =>
        import(
            /* webpackChunkName: "ExerciseAnimation" */
            /* webpackPrefetch: true */ './ExerciseAnimation'
        ),
);

export const ExerciseAnimationLazy = (props: ExerciseAnimationProps) => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <ExerciseAnimation {...props} />
        </Suspense>
    );
};
