import React from 'react';
import { SvgIcon } from '@mui/material';

export const PatientFocusIcon = (props: { hovered: boolean | null }) => {
    const { hovered } = props;

    return (
        <SvgIcon viewBox="75 75 1050 1050" {...props}>
            <path
                d="m135 135h270v-60h-330v330h60z"
                fill="currentcolor"
                style={{
                    transform: hovered ? 'translateY(5%) translateX(10%)' : 'translateY(0) translateX(0)',
                    transition: '.6s ease transform',
                }}
            />
            <path
                d="m75 1125h330v-60h-270v-270h-60z"
                fill="currentcolor"
                style={{
                    transform: hovered ? 'translateY(-5%) translateX(10%)' : 'translateY(0) translateX(0)',
                    transition: '.6s ease transform',
                }}
            />
            <path
                d="m1065 1065h-270v60h330v-330h-60z"
                fill="currentcolor"
                style={{
                    transform: hovered ? 'translateY(-5%) translateX(-10%)' : 'translateY(0) translateX(0)',
                    transition: '.6s ease transform',
                }}
            />
            <path
                d="m795 135h270v270h60v-330h-330z"
                fill="currentcolor"
                style={{
                    transform: hovered ? 'translateY(5%) translateX(-10%)' : 'translateY(0) translateX(0)',
                    transition: '.6s ease transform',
                }}
            />
            <path
                d="m900 868.93v-73.141c0-108.54-85.637-196.86-190.9-196.86-8.1445 0-15.938 3.3086-21.59 9.168-23.133 23.984-54.203 37.18-87.512 37.18s-64.379-13.199-87.512-37.176c-5.6523-5.8594-13.449-9.168-21.59-9.168-105.26-0.003906-190.9 88.312-190.9 196.86v73.141c0 16.566 13.434 30 30 30h540c16.566-0.003906 30-13.438 30-30.004zm-60-30h-480v-43.141c0-71.41 52.574-130.22 119.39-136.33 33.234 29.648 75.555 45.82 120.61 45.82s87.379-16.172 120.61-45.82c66.812 6.1094 119.39 64.922 119.39 136.34z"
                fill="currentcolor"
                style={{
                    transform: hovered ? 'translateY(100%)' : 'translate(0, 0)',
                    transition: '.6s ease transform',
                }}
            />
            <path
                d="m600 271.07c-90.98 0-165 74.02-165 165 0 90.98 74.02 165 165 165s165-74.02 165-165c0-90.98-74.02-165-165-165zm0 270c-57.891 0-105-47.109-105-105 0-57.891 47.109-105 105-105s105 47.109 105 105c0 57.891-47.109 105-105 105z"
                fill="currentcolor"
                style={{
                    transform: hovered ? 'scale(1.5) translate(-19%, -3%)' : 'scale(1) translate(0, 0)',
                    transition: '.6s ease transform',
                }}
            />
        </SvgIcon>
    );
};

// const
