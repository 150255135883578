import React, { Dispatch, SetStateAction } from 'react';
import { Menu } from '@mui/icons-material';
import { AppBar, Box, IconButton } from '@mui/material';
import { HeaderLogo } from './HeaderLogo';
import { HeaderWrapper } from './styles';

interface Props {
    isMobileNavOpen: boolean;
    setMobileNavOpen: Dispatch<SetStateAction<boolean>>;
}
export const DashboardHeader = (props: Props) => {
    const { isMobileNavOpen, setMobileNavOpen } = props;

    const toggleMobileNav = () => {
        setMobileNavOpen(!isMobileNavOpen);
    };

    return (
        <AppBar sx={{ display: { md: 'none', xs: 'block' } }} elevation={0}>
            <HeaderWrapper>
                <HeaderLogo />
                <Box sx={{ flexGrow: 1 }} />
                <IconButton color="inherit" onClick={toggleMobileNav}>
                    <Menu color="inherit" />
                </IconButton>
            </HeaderWrapper>
        </AppBar>
    );
};
