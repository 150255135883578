import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { Box, IconButton, Typography } from '@mui/material';
import { useMuiUtils } from '@/common/hooks/MuiUtils';
import { SortableScanAssessment } from '@/common/types';
import { DragIcon } from '@/components/common/Icons/DragIcon';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { TrashIcon } from '@/components/common/Icons/TrashIcon';
import { AssessmentCardContainer, SortableAssessment, SortableAssessmentHandle } from './styles';

export const SortableAssessmentCard = ({
    assessment,
    handleEditClick,
    handleDeleteClick,
}: {
    assessment: SortableScanAssessment;
    handleEditClick: (assessment: SortableScanAssessment) => void;
    handleDeleteClick: (assessmentId: string) => void;
}) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging, active } = useSortable({
        id: assessment.id,
    });
    const { palette } = useMuiUtils();

    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition,
        opacity: isDragging ? 0.9 : 1,
        zIndex: isDragging ? 999999 : 999,
        borderRadius: 8,
        boxShadow: isDragging ? `0px 0px 0px 2px ${palette.primary[500]}` : `0px 0px 0px 1px ${palette.grey[300]}`,
    };

    return (
        <SortableAssessment ref={setNodeRef} style={style}>
            <AssessmentCardContainer>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: 8,
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <SortableAssessmentHandle
                            style={{
                                cursor: active ? 'grabbing' : 'grab',
                            }}
                            {...attributes}
                            {...listeners}
                        >
                            <DragIcon />
                        </SortableAssessmentHandle>
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {assessment.thumbnailUrl && (
                            <Box
                                style={{
                                    marginRight: 8,
                                }}
                            >
                                <img src={assessment.thumbnailUrl} width={50} height={50} />
                            </Box>
                        )}
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography variant="h5">{assessment.nameOverride || assessment.name}</Typography>
                            <Typography variant="caption">({assessment.subjectOrientation})</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: 16,
                    }}
                >
                    <IconButton onClick={() => handleEditClick(assessment)} size="small">
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteClick(assessment.id)} size="small">
                        <TrashIcon />
                    </IconButton>
                </Box>
            </AssessmentCardContainer>
        </SortableAssessment>
    );
};
