import React from 'react';
import { useParams } from 'react-router-dom';
import { AdminScanOrganizationOutcomesContainer } from '@/components/Organization/AdminOrganization/AdminOrganizationScanOutcomesContainer';
import { RouteContentWrapper } from '../../styles';

export const AdminScanOrganizationOutcomesRoute = () => {
    const { organizationId } = useParams<{ organizationId: string }>();

    return (
        <RouteContentWrapper>
            <AdminScanOrganizationOutcomesContainer organizationId={parseInt(organizationId!)} />
        </RouteContentWrapper>
    );
};
