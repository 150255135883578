import React, { FC } from 'react';
import { Maybe } from '@/common/types';
import { FactorCategory } from '../types/FactorCategory.enum';
import { OutcomeFactor } from '../types/types';
import { OutcomeSlider } from './OutcomeSlider';

interface OutcomeSliderContainerProps {
    factors: Maybe<OutcomeFactor[]>;
    factorResultCategory: Maybe<FactorCategory>;
}

export const OutcomeSliderContainer: FC<OutcomeSliderContainerProps> = ({ factors, factorResultCategory }) => {
    const outcomeSliderdata = { factors, factorResultCategory };

    return <OutcomeSlider outcomeSliderData={outcomeSliderdata} />;
};
