import React from 'react';
import { Grid } from '@mui/material';
import { PROQuestions } from '@/components/Health/PatientSessions/PROs/PROQuestions';
import { usePatientContext } from '@/components/Patients/Patient/context/hooks/PatientContext';
import { EmptyData } from '@/components/common/EmptyData/EmptyData';
import { TabContentGrid } from '@/components/common/Tabs/styles';
import { DateRangeAtomState } from '@/recoil/atoms/dateRangeAtom';
import { PROChart } from '../../PatientSessions/Charts/PROChart';
import { PROChartDataDict } from '../../PatientSessions/PROs/types';
import { TabPanel } from './TabPanel';

interface PROTabProps {
    currentTab: number;
    dateRange: DateRangeAtomState;
    proExistsInRange: () => boolean | undefined;
    proChartDataDict: PROChartDataDict | undefined;
}

export const PROTab = (props: PROTabProps) => {
    const { currentTab, proChartDataDict, proExistsInRange, dateRange } = props;
    const { proAnswersByQuestionsDict } = usePatientContext();

    return (
        <TabPanel currentTab={currentTab} index={2} ariaLabel="pro data">
            {proChartDataDict ? (
                !proExistsInRange() ? (
                    <EmptyData
                        title="No PRO data available in this date range"
                        body="Your patient’s responses will appear here when they respond to a PRO."
                    />
                ) : (
                    <TabContentGrid container spacing={4}>
                        <Grid item xs={12} lg={6}>
                            <div>
                                {Object.values(proChartDataDict).map((proChart) => {
                                    return (
                                        <PROChart
                                            key={proChart.title}
                                            proChart={proChart}
                                            timeSpan={dateRange}
                                            chartTitle={proChart.title}
                                        />
                                    );
                                })}
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <PROQuestions proAnswersByQuestionsDict={proAnswersByQuestionsDict} />
                        </Grid>
                    </TabContentGrid>
                )
            ) : (
                <EmptyData
                    title="No PRO data available"
                    body="Your patient's responses will show up when they respond to a PRO."
                />
            )}
        </TabPanel>
    );
};
