import React, { useEffect, useState, MouseEvent, SyntheticEvent } from 'react';
import { useRecoilState } from 'recoil';
import { PAGE_SIZE, PATIENT_STATUS, Products, SortBy } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { PatientPagedRequestParams, PatientsTableRow, TemplateOrHEP, PatientRecord } from '@/common/types';
import { a11yProps, preventParentLinkClick } from '@/common/utils';
import { HEPBuilderModal } from '@/components/Health/HEPBuilder/HepBuilderModal/HEPBuilderModal';
import { BuilderActions } from '@/components/Health/HEPBuilder/state/HEPBuilderContext';
import { PatientActions } from '@/components/Patients/AllPatientsTable/Actions/PatientActions';
import { AllPatientsTable } from '@/components/Patients/AllPatientsTable/Table/AllPatientsTable';
import { HealthColumns, GaitColumns, HealthAndGaitColumns } from '@/components/Patients/AllPatientsTable/Table/Columns';
import { UnhandledError } from '@/components/common/Error/UnhandledError';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { PATIENT_PRODUCT_FILTER_ID } from '@/components/common/Table/Filters/PatientProductFilter';
import { PRACTITIONER_FILTER_ID } from '@/components/common/Table/Filters/PractitionersFilter';
import { usePaginationContext } from '@/components/context/hooks/PaginationContext';
import { patientTableState } from '@/recoil/atoms/patientTableAtom';
import { resultIsError } from '@/services/HttpService';
import { PortalEvent } from '@/services/events/const';
import eventService from '@/services/events/eventService';
import { AddPatientContainer } from '../AddPatient/AddPatientContainer';
import { EmptyPatients } from '../EmptyPatients';
import { convertToRows } from './Table/Utils';
import {
    PatientName,
    PatientNameAndSelectionCell,
    PatientTableActionsWrapper,
    PatientTableHeaderTop,
    PatientTableTab,
    PatientTableTabs,
} from './styles';

export const AllPatientsTableContainer = () => {
    const { httpService } = useHttpContext();
    const [rows, setRows] = useState<PatientsTableRow[]>([]);
    const [hasFetched, setHasFetched] = useState(false);
    const [patientsExist, setPatientsExist] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isErrored, setIsErrored] = useState(false);
    const [product, setProduct] = useState<Products>();
    const { page, globalSearch, sort, setPaginationMeta, filters, noFilters, setPage } = usePaginationContext();
    const { user, isGaitUser, isHealthUser } = useExerUser();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [patientRecord, setPatientRecord] = useState<PatientRecord | null>();
    const [hasCheckedFilters, setHasCheckedFilters] = useState(false);
    const [tableState, setTableState] = useRecoilState(patientTableState);

    const handleHEPBuilderModalClose = () => {
        setDialogOpen(false);
        setPatientRecord(null);
    };

    const handleHEPBuilderModalOpen = (event) => {
        preventParentLinkClick(event);
        setDialogOpen(true);
    };

    const handleTabChange = (_: SyntheticEvent, newValue: number) => {
        setTableState({ currentTab: newValue });
    };

    const getRequestParams = (): PatientPagedRequestParams => {
        const params = {} as PatientPagedRequestParams;
        params.page = page;
        params.limit = PAGE_SIZE;
        if (globalSearch) {
            params.search = globalSearch;
        }
        if (sort && !!sort.sortKey) {
            params.sortBy = sort.sortKey;
            params.orderBy = sort.dir;
        } else {
            params.sortBy = SortBy.LAST_ACTIVITY;
            params.orderBy = 'DESC';
        }
        if (isDischargedView()) {
            params.status = PATIENT_STATUS.DISCHARGED;
        }

        filters.forEach((filter) => {
            if (filter.filterKey === PRACTITIONER_FILTER_ID || filter.filterKey === PATIENT_PRODUCT_FILTER_ID) {
                params[filter.filterKey] = JSON.parse(filter.filterValue).join(',');
            } else {
                params[filter.filterKey] = filter.filterValue;
            }
        });
        return params;
    };

    const getPatients = async () => {
        const res = await httpService.getPatientRecords(getRequestParams());
        if (!res || resultIsError(res)) {
            if (page !== 1) {
                window.location.reload();
            } else {
                setIsErrored(true);
            }
        } else {
            const converted = convertToRows(res.items);
            setRows(converted);
            setPaginationMeta(res.metadata);
            setPatientsExist(Object.keys(res.items).length > 0);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        eventService.on(PortalEvent.UPDATE_PATIENT, async (data) => {
            await getPatients();
        });
        return () => {
            eventService.remove(PortalEvent.UPDATE_PATIENT);
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (hasCheckedFilters) {
                await getPatients();

                setHasFetched(true);
                setIsLoading(false);
            }
        })();
    }, [tableState.currentTab, page, filters, globalSearch, sort, hasCheckedFilters]);

    useEffect(() => {
        if (filters) {
            setHasCheckedFilters(true);
        }
    }, [filters]);

    useEffect(() => {
        setPage(1);
    }, [tableState.currentTab]);

    useEffect(() => {
        if (user.products?.length === 1) {
            setProduct(Products[user.products[0]]);
        }
    }, [user]);

    const isDischargedView = (): boolean => {
        return tableState.currentTab === 1;
    };

    const patientActions = [
        {
            Header: 'Action',
            accessor: 'patientRecord',
            width: 10,
            Cell: ({ value }) => (
                <>
                    <PatientActions
                        patientRecord={value}
                        openHEPModal={(event: MouseEvent | TouchEvent | React.TouchEvent | React.MouseEvent) => {
                            handleHEPBuilderModalOpen(event);
                            setPatientRecord(value);
                        }}
                        getPatients={getPatients}
                    />
                </>
            ),
        },
    ];

    const columnStructure = [
        ...(!isDischargedView() ? [...patientActions] : []),
        {
            Header: 'Name',
            accessor: 'NAME',
            Cell: ({ value, row }) => (
                <PatientNameAndSelectionCell>
                    <PatientName>{value}</PatientName>
                </PatientNameAndSelectionCell>
            ),
        },
        ...(isHealthUser() && !isGaitUser() ? [...HealthColumns] : []),
        ...(isGaitUser() && !isHealthUser() ? [...GaitColumns] : []),
        ...(isHealthUser() && isGaitUser() ? [...HealthAndGaitColumns] : []),
    ];

    return (
        <>
            <PageTitle title="Patient List" />
            <div>
                {isErrored ? (
                    <UnhandledError />
                ) : isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {hasFetched ? (
                            <>
                                <PatientTableHeaderTop>
                                    <PatientTableTabs value={tableState.currentTab} onChange={handleTabChange}>
                                        <PatientTableTab label="Active" {...a11yProps(0)} />
                                        <PatientTableTab label="Discharged" {...a11yProps(1)} />
                                    </PatientTableTabs>
                                    {!isDischargedView() && rows?.length > 0 && (
                                        <PatientTableActionsWrapper>
                                            <AddPatientContainer product={product} />
                                        </PatientTableActionsWrapper>
                                    )}
                                </PatientTableHeaderTop>

                                {!patientsExist && noFilters() && (
                                    <EmptyPatients
                                        title="No patients added to your organization yet"
                                        body="Add your first patient and assign them a product."
                                    />
                                )}
                                {(patientsExist || !noFilters()) && (
                                    <>
                                        <AllPatientsTable columnStructure={columnStructure} data={rows} />
                                        <HEPBuilderModal
                                            open={dialogOpen}
                                            onClose={handleHEPBuilderModalClose}
                                            actionType={BuilderActions.ASSIGN}
                                            patientRecord={patientRecord ? patientRecord : undefined}
                                            isTemplateOrHEP={TemplateOrHEP.Program}
                                        />
                                    </>
                                )}
                            </>
                        ) : null}
                    </>
                )}
            </div>
        </>
    );
};
