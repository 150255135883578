import React from 'react';
import { Program } from '@/common/types';
import { SemiStrong } from '@/components/common/styles';
import Logo from '@/images/exer-black.svg';
import { PrintableHEPBlocks } from './HEPBlocks/PrintableHEPBlocks';
import {
    HEPDetailsWrapper,
    HEPDetailsHeader,
    HEPDetailsNotes,
    HEPDetailsTitle,
    HEPDetailsContainer,
    HEPPrintLogoWrapper,
} from './styles';

interface Props {
    program: Program;
}

export const PrintableHEPDetails = (props: Props) => {
    const { program } = props;

    return (
        <HEPDetailsWrapper>
            <HEPDetailsContainer>
                <HEPPrintLogoWrapper>
                    <img width="100" src={Logo} />
                </HEPPrintLogoWrapper>
                <HEPDetailsTitle variant="h4">{program.name}</HEPDetailsTitle>

                {program.notes && (
                    <>
                        <HEPDetailsHeader variant="caption">Program Notes</HEPDetailsHeader>
                        <HEPDetailsNotes>{program.notes}</HEPDetailsNotes>
                    </>
                )}

                <HEPDetailsHeader variant="caption">Program Frequency</HEPDetailsHeader>
                <p>
                    <SemiStrong>
                        Daily Frequency: {program.dailyFrequency} {`\u00a0`} Weekly Frequency: {program.weeklyFrequency}
                    </SemiStrong>
                </p>
                <PrintableHEPBlocks blocks={program.blocks} />
            </HEPDetailsContainer>
        </HEPDetailsWrapper>
    );
};
