import React from 'react';
import { IconButton } from '@mui/material';
import { Button } from '@mui/material';
import { RecordType } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { resendInvite } from '@/common/resendInvite';
import { preventParentLinkClick } from '@/common/utils';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { SendIcon } from '@/components/common/Icons/SendIcon';
import { useModalContext } from '@/components/context/hooks/ModalContext';
import { ResendInviteWrapper, ResendInviteText, ResendInviteModalContent } from './styles';

interface Props {
    recordId: number;
    name: string;
    recordType: RecordType;
    buttonType?: 'icon' | 'button';
}
export const ResendInvite = (props: Props) => {
    const { recordId, name, recordType, buttonType = 'icon' } = props;
    const { httpService } = useHttpContext();
    const { setDialog, setDialogOpen } = useModalContext();
    const { successToast, errorToast } = toasts;

    const handleClick = (e: React.MouseEvent) => {
        preventParentLinkClick(e);
        setDialog(
            'Resend Invite',
            <ResendInviteModalContent>
                <p>Send another invitation to {name}?</p>
                <SubmitCancelButtons
                    submitFunction={handleSubmit}
                    cancelFunction={() => {
                        setDialogOpen(false);
                    }}
                    submitText="Send"
                    size="small"
                />
            </ResendInviteModalContent>,
        );
        setDialogOpen(true);
    };

    const handleSubmit = async (e: React.MouseEvent) => {
        preventParentLinkClick(e);
        const success = await resendInvite(httpService, recordType, recordId);

        if (success) {
            successToast('Invite successfully sent.');
            setDialogOpen(false);
        } else {
            errorToast('Invite could not be sent.');
        }
    };

    return (
        <>
            {buttonType === 'icon' ? (
                <ResendInviteWrapper onClick={handleClick}>
                    <IconButton size="small">
                        <SendIcon />
                    </IconButton>
                    <ResendInviteText>Resend Invite</ResendInviteText>
                </ResendInviteWrapper>
            ) : (
                <Button variant="primary" size="medium" onClick={handleClick} startIcon={<SendIcon fontSize="16px" />}>
                    Resend Exer Health Invite
                </Button>
            )}
        </>
    );
};
