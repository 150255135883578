import { Grid, styled } from '@mui/material';

export const ScanSessionsTableWrapper = styled('div')(({}) => ({}));

export const ScanSessionsTableResultsWrapper = styled('div')(({ theme }) => ({
    fontSize: 14,
}));

export const ListControlsContainer = styled(Grid)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flexWrap: 'wrap',
    alignItems: 'bottom',
    marginTop: theme.spacing(2),
    gap: theme.spacing(1),

    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
    },

    [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
        justifyContent: 'flex-start',
        alignItems: 'center',
    },

    [theme.breakpoints.up('chromebook')]: {
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
    },
}));
