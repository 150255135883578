import React, { Fragment } from 'react';
import { Divider, Stack } from '@mui/material';
import { MetricCode } from '@/common/const';
import { ExerciseMetricOmitExercise } from '@/common/types';
import { ExerciseMetricsText } from './styles';

interface HEPBuilderExerciseCardExerciseMetricsProps {
    exerciseMetrics: ExerciseMetricOmitExercise[];
}

export const HEPBuilderExerciseCardExerciseMetrics = (props: HEPBuilderExerciseCardExerciseMetricsProps) => {
    const { exerciseMetrics } = props;

    return (
        <Stack direction="row" spacing={1}>
            {exerciseMetrics.map((em, i) => {
                return (
                    <Fragment key={`${em.id}-${em.metric}`}>
                        <ExerciseMetricsText key={`${em.id}-${em.metric}`}>{MetricCode[em.metric]}</ExerciseMetricsText>
                        {i === exerciseMetrics.length - 1 ? null : <Divider orientation="vertical" flexItem />}
                    </Fragment>
                );
            })}
        </Stack>
    );
};
