import { DateTime } from 'luxon';
import { atom } from 'recoil';
import { RTMItem } from '@/common/types';

export enum RTMLoadingStatus {
    IDLE = 'IDLE',
    RELOAD = 'RELOAD',
    DONE = 'DONE',
}

interface RtmStateProps {
    filters: {
        cycleStart: string | null;
        cycleEnd: string | null;
        selectedMonth: string;
        statusList: {
            IN_PROGRESS: boolean;
            READY_TO_BILL: boolean;
            BILLED: boolean;
            ACTIVE_DAYS_INCOMPLETE: boolean;
        };
        locationId: string | null;
    };
    loadCycles: RTMLoadingStatus;
}

export const RTMState = atom<RtmStateProps>({
    key: 'RTMState',
    default: {
        filters: {
            cycleStart: null,
            cycleEnd: null,
            selectedMonth: DateTime.now().toFormat('yyyy-LL'),
            statusList: { IN_PROGRESS: true, READY_TO_BILL: true, BILLED: false, ACTIVE_DAYS_INCOMPLETE: false },
            locationId: null,
        },
        loadCycles: RTMLoadingStatus.IDLE,
    },
});

export const RTMCyclesByMonthState = atom<RTMItem[]>({
    key: 'RTMCyclesByMonthState',
    default: [],
});
