import { Grid, styled, IconButton } from '@mui/material';

export const LocationRowName = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        height: 52,
    },
}));

export const LocationRowAddress = styled(Grid)(() => ({}));

export const LocationRowEditButton = styled(IconButton)(({ theme }) => ({}));

export const LocationRowEdit = styled(Grid)(({ theme }) => ({
    textAlign: 'right',
    paddingRight: theme.spacing(2),
}));

export const LocationsListHeaderWrapper = styled(Grid)(({ theme }) => ({
    marginTop: '0',
    marginLeft: '0',
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}));

export const LocationListTabsContainer = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));
