import React, { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { BodyPart, Paths, Products, SideOfBody } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { mixpanel } from '@/common/mixpanel';
import { CreateUpdatePatientDto, Location, PatientRecord, PractitionerOption } from '@/common/types';
import {
    formatUSPhoneNumber,
    getPractitionerOptions,
    getSideOfBodyChoices,
    getStringEnumKeyByValue,
    phoneNumberTest,
} from '@/common/utils';
import { LanguageSelector } from '@/components/Demo/LanguageSelector';
import {
    FormAutoCompleteField,
    FormDateField,
    FormPhoneNumberField,
    FormRadioField,
    FormTextField,
    SaveSaveAnotherCancelButtons,
} from '@/components/common/Form/Form';
import { ServerError } from '@/components/common/Form/styles';
import { resultIsError } from '@/services/HttpService';
import { PortalEvent } from '@/services/events/const';
import eventService from '@/services/events/eventService';
import { AddPatientFormWarningText } from './styles';

interface Props {
    cancel: () => void;
    product?: Products;
}

type CreatePatientDto = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    primaryProvider: PractitionerOption | null;
};

type CreateGaitPatientDto = CreatePatientDto & {
    location: Location;
    dob: Date;
};

type CreateHealthPatientDto = CreatePatientDto & {
    injuredSideOfBody: SideOfBody | null;
    injuredBodyPart: BodyPart | null;
    phoneNumber?: string;
};

export type CreateAnyPatientDto = CreateHealthPatientDto | CreateGaitPatientDto;

const HealthDefaultValues = { location: null, injuredSideOfBody: null, injuredBodyPart: null };
const GaitDefaultValues = { location: null, dob: null };

const healthShape = {
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Email must be valid.').required('Email is required'),
    phoneNumber: yup
        .string()
        .test('len', 'Phone number must be 10 digits', phoneNumberTest)
        .required('Phone number is required'),
    dob: yup.date().nullable().default(undefined),
    location: yup.object().required('Location is required'),
    primaryProvider: yup.object().nullable(),
    injuredSideOfBody: yup.string().required('Side of body is required').nullable(),
    injuredBodyPart: yup.string().required('Involved body part is required').nullable(),
};

const gaitShape = {
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Email must be valid').nullable(),
    phoneNumber: yup
        .string()
        .transform((_, val) => (val === '' ? null : val))
        .test('len', 'Phone number must be 10 digits', phoneNumberTest)
        .nullable(),
    dob: yup.date().nullable().default(undefined),
    location: yup.object().required('Location is required'),
    primaryProvider: yup.object().nullable(),
    injuredSideOfBody: yup.string().nullable(),
    injuredBodyPart: yup.string().nullable(),
};

export const AddPatientForm = (props: Props) => {
    const { cancel, product } = props;
    const { httpService } = useHttpContext();
    const [locations, setLocations] = useState<Location[]>([]);
    const [practitioners, setPractitioners] = useState<PractitionerOption[]>();
    const [currentPractitioner, setCurrentPractitioner] = useState<PractitionerOption>();
    const [serverError, setServerError] = useState<string | null>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { successToast, errorToast } = toasts;
    const navigate = useNavigate();

    const navigateToPatient = (patientRecordId: number) => {
        navigate(`${Paths.patients}${patientRecordId}/`);
    };

    const defaultValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        ...(product === Products.HEALTH && { ...HealthDefaultValues }),
        ...(product === Products.GAIT && { ...GaitDefaultValues }),
        ...(!product && { ...HealthDefaultValues, ...GaitDefaultValues }),
        primaryProvider: null,
    };
    const shape = !product || product === Products.HEALTH ? healthShape : gaitShape;
    const schema = yup.object().shape(shape);

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<CreateAnyPatientDto>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const getSetLocations = async () => {
        const res = await httpService.getLocations();
        if (!res || resultIsError(res)) {
            errorToast('Could not get locations.');
            return;
        }
        setLocations(res);
    };

    const getSetPractitioners = async () => {
        const res = await httpService.getPractitioners();
        if (!res || resultIsError(res)) {
            errorToast('Could not get providers.');
            return;
        }
        const uniquePractitioners = getPractitionerOptions(res, setCurrentPractitioner, currentPractitioner);
        setPractitioners(uniquePractitioners);
    };

    const getSetCurrentPractitioner = async () => {
        const res = await httpService.getCurrentPractitioner();
        if (resultIsError(res)) {
            return;
        }
        if (!res) {
            // ignore, we just won't have a default set
        }
        setCurrentPractitioner({
            id: res.id,
            name: `${res.firstName} ${res.lastName}`,
        });
    };

    useEffect(() => {
        (async () => {
            await getSetLocations();
            await getSetCurrentPractitioner();
        })();
    }, []);

    useEffect(() => {
        if (currentPractitioner) {
            (async () => {
                await getSetPractitioners();
            })();
        }
    }, [currentPractitioner]);

    useEffect(() => {
        if (currentPractitioner) {
            setValue('primaryProvider', currentPractitioner);
        }
    }, [practitioners, currentPractitioner, setValue]);

    const transformData = async (dto: CreateAnyPatientDto): Promise<CreateUpdatePatientDto> => {
        let data;
        let healthValues = {};

        if (!product || product === Products.HEALTH) {
            data = dto as CreateHealthPatientDto;
            const injuredSideOfBody =
                !data.injuredSideOfBody || data.injuredSideOfBody === SideOfBody.N_A ? null : data.injuredSideOfBody;
            healthValues = {
                injuredSideOfBody,
                injuredBodyPart: data.injuredBodyPart || null,
                locationId: data.location ? data.location.id : null,
            };
        }
        let gaitValues = {};
        if (!product || product === Products.GAIT) {
            data = dto as CreateGaitPatientDto;
            gaitValues = {
                dob: data.dob ? data.dob.toISOString().substring(0, 10) : null,
                locationId: data.location ? data.location.id : null,
            };
        }
        const intlPhoneNumber = data.phoneNumber ? formatUSPhoneNumber(data.phoneNumber) : data.phoneNumber;
        return {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email || null,
            phoneNumber: intlPhoneNumber,
            ...healthValues,
            ...gaitValues,
            products: !product
                ? [getStringEnumKeyByValue(Products, Products.HEALTH), getStringEnumKeyByValue(Products, Products.GAIT)]
                : [getStringEnumKeyByValue(Products, product)],
            primaryPractitionerId: data.primaryProvider?.id,
        };
    };

    const addPatient = async (data: CreateAnyPatientDto): Promise<PatientRecord | undefined> => {
        setServerError(null);
        setIsSubmitting(true);
        const patientDto = await transformData(data);
        const result = await httpService.addNewPatient(patientDto);
        setIsSubmitting(false);
        if (resultIsError(result)) {
            setServerError(result.message);
            return;
        } else {
            successToast(
                `Patient added${!product || product === Products.HEALTH
                    ? ` to Provider Portal. Please assign Program as a next step.`
                    : `.`
                }`,
            );
            eventService.dispatch(PortalEvent.ADD_PATIENT, result);
            mixpanel.track('Added New Patient', { Product: product || 'Gait and Health' });
            return result;
        }
    };

    const save = async (data: CreateAnyPatientDto) => {
        const result = await addPatient(data);
        if (result) {
            navigateToPatient(result.id);
        }
    };

    const saveAndAddAnother = async (data: CreateAnyPatientDto) => {
        const result = await addPatient(data);
        if (result) {
            reset();
        }
    };

    return (
        <>
            <AddPatientFormWarningText>
                {(!product || product === Products.HEALTH) &&
                    `This patient will receive an email to create an account to use Exer Health on their device.`}
            </AddPatientFormWarningText>
            <form noValidate data-cy="add-patient-form">
                <FormTextField control={control} errors={errors} name="firstName" label="First Name" required={true} />
                <FormTextField control={control} errors={errors} name="lastName" label="Last Name" required={true} />
                <FormTextField
                    control={control}
                    errors={errors}
                    name="email"
                    label="Email"
                    required={!product || product === Products.HEALTH}
                />
                <FormPhoneNumberField
                    control={control}
                    errors={errors}
                    name="phoneNumber"
                    label="Phone Number"
                    required={!product || product === Products.HEALTH}
                />
                {(!product || product === Products.GAIT) && (
                    <FormDateField control={control} errors={errors} name="dob" label="DOB" noFuture />
                )}
                <FormAutoCompleteField
                    control={control}
                    errors={errors}
                    name="location"
                    label="Location"
                    options={locations}
                    optionLabel={(location) => location.name}
                    required
                />
                <br />
                <FormAutoCompleteField
                    control={control}
                    errors={errors}
                    name="primaryProvider"
                    label="Primary Provider"
                    options={practitioners || []}
                    optionLabel={(practitioner) => practitioner?.name}
                    clearIcon={null}
                />
                {(!product || product === Products.HEALTH) && (
                    <>
                        <FormRadioField
                            control={control}
                            errors={errors}
                            name="injuredSideOfBody"
                            label="Involved Side"
                            required={!product || product === Products.HEALTH}
                            choices={getSideOfBodyChoices()}
                        />

                        <FormRadioField
                            control={control}
                            errors={errors}
                            name="injuredBodyPart"
                            label="Involved Body Part"
                            required={!product || product === Products.HEALTH}
                            choices={[
                                {
                                    value: getStringEnumKeyByValue(BodyPart, BodyPart.SHOULDER),
                                    label: BodyPart.SHOULDER,
                                },
                                { value: getStringEnumKeyByValue(BodyPart, BodyPart.HIP), label: BodyPart.HIP },
                                { value: getStringEnumKeyByValue(BodyPart, BodyPart.KNEE), label: BodyPart.KNEE },
                                { value: getStringEnumKeyByValue(BodyPart, BodyPart.SPINE), label: BodyPart.SPINE },
                                {
                                    value: getStringEnumKeyByValue(BodyPart, BodyPart.MULTIPLE),
                                    label: BodyPart.MULTIPLE,
                                },
                            ]}
                        />
                    </>
                )}
                <LanguageSelector />
                <ServerError>{serverError || '\u00a0'}</ServerError>
                <SaveSaveAnotherCancelButtons
                    saveFunction={handleSubmit(save)}
                    cancelFunction={cancel}
                    saveAndAddAnotherFunction={handleSubmit(saveAndAddAnother)}
                    disabled={isSubmitting}
                />
            </form>
        </>
    );
};
