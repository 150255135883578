import React, { useEffect } from 'react';
import { useFilters, useGlobalFilter, useSortBy, useTable, usePagination } from 'react-table';
import { PatientsTableWrapper } from '@/components/common/Table/PatientsTable/styles';
import {
    TableWrapper,
    TableHeader,
    TableCell,
    FullWidthTable,
    TableHeaderCell,
    ColumnSortIcon,
    ColumnSortedIcon,
    Pagination,
    PaginationWrapper,
} from '@/components/common/Table/styles';

interface Props {
    columnStructure: any[];
    data: any[];
    selectedOrg: string;
}
export const AdminLocationsTable = (props: Props) => {
    const { columnStructure, data, selectedOrg } = props;

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, pageCount, gotoPage, setFilter } =
        useTable(
            {
                columns: columnStructure,
                autoResetSortBy: false,
                data,
                initialState: {
                    sortBy: [
                        {
                            id: 'name',
                            desc: false,
                        },
                    ],
                },
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination,
        );

    const nonSorted: string[] = [];
    const nonHeadered: string[] = [];

    const handlePaginationChange = (_, value) => {
        gotoPage(value - 1);
    };

    useEffect(() => {
        setFilter('organization', selectedOrg);
    }, [selectedOrg]);

    return (
        <PatientsTableWrapper>
            <TableWrapper>
                <FullWidthTable {...getTableProps()}>
                    <TableHeader>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                {headerGroup.headers.map((column) => {
                                    const headerProps = !nonSorted.includes(column.id)
                                        ? { ...column.getHeaderProps(column.getSortByToggleProps()) }
                                        : { ...column.getHeaderProps() };
                                    return (
                                        <TableHeaderCell
                                            {...headerProps}
                                            key={headerProps.key}
                                            $isSorted={column.isSorted}
                                            $width={200}
                                        >
                                            {!nonHeadered.includes(column.id) && column.render('Header')}
                                            {!nonSorted.includes(column.id) &&
                                                (column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <ColumnSortedIcon dir="DESC" />
                                                    ) : (
                                                        <ColumnSortedIcon dir="ASC" />
                                                    )
                                                ) : (
                                                    <ColumnSortIcon />
                                                ))}
                                        </TableHeaderCell>
                                    );
                                })}
                            </tr>
                        ))}
                    </TableHeader>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={row.id}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <TableCell {...cell.getCellProps()} key={cell.column.id}>
                                                {cell.render('Cell')}
                                            </TableCell>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </FullWidthTable>
            </TableWrapper>
            <PaginationWrapper>
                <Pagination
                    count={pageCount}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    onChange={handlePaginationChange}
                />
            </PaginationWrapper>
        </PatientsTableWrapper>
    );
};
