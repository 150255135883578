import { styled } from '@mui/material';

export const ErrorWrapper = styled('div')(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.error[500],
    paddingTop: theme.spacing(6),

    '& img': {
        width: 240,
        marginBottom: theme.spacing(4),
    },
}));
