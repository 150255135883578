import React from 'react';
import { useExerUser } from '@/common/hooks/ExerUser';
import { isAdmin } from '@/common/utils';
import { OrganizationEmptyEntitiesWrapper } from '../styles';
import { AddLocation } from './AddLocation/AddLocation';

export const EmptyLocations = () => {
    const { user } = useExerUser();

    return (
        <OrganizationEmptyEntitiesWrapper>
            <h3>No locations added yet.</h3>
            {isAdmin(user) && <AddLocation />}
        </OrganizationEmptyEntitiesWrapper>
    );
};
