import { useContext } from 'react';
import { PatientContext } from '../PatientContext';

export const usePatientContext = () => {
    const patientContext = useContext(PatientContext);

    if (!patientContext) {
        throw new Error('PatientContext not initialized');
    }

    return patientContext;
};
