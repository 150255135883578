import { styled } from '@mui/material';

export const PageTitleWrapper = styled('div')(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    margin: '0 -32px 32px',
    padding: '4px 32px 14px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',

    img: {
        position: 'absolute',
        top: '-8px',
        right: '32px',
        height: '46px',
    },
}));
