import React from 'react';
import { Close } from '@mui/icons-material';
import { getOptionEnumById } from '@/common/utils';
import { ExerciseActiveFilterWrapper } from './styles';

interface Props {
    type: string;
    value: string;
    updateFilters: (type: string, value: string, include: boolean) => void;
}

export const ExerciseActiveFilter = (props: Props) => {
    const { type, value, updateFilters } = props;
    const typeEnum = getOptionEnumById(type);

    const handleClick = (_: React.MouseEvent) => {
        updateFilters(type, value, false);
    };

    return (
        <ExerciseActiveFilterWrapper onClick={handleClick} endIcon={<Close fontSize="inherit" />} variant="filter">
            {typeEnum?.[value] || value.toLowerCase()}
        </ExerciseActiveFilterWrapper>
    );
};
