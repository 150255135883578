import React from 'react';
import { SvgIcon } from '@mui/material';

export const CommentIcon = (props) => {
    return (
        <>
            <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.36247 4.41708C7.94074 4.32811 6.53542 4.76121 5.41042 5.63506C4.28542 6.50891 3.51811 7.76339 3.25259 9.16294C2.98786 10.5583 3.24007 12.0021 3.96184 13.225C4.0906 13.4353 4.1305 13.6947 4.05974 13.9424L3.56054 15.6896L5.30773 15.1904C5.55235 15.1205 5.81197 15.158 6.02429 15.2878C7.24731 16.0099 8.69151 16.2623 10.0872 15.9975C11.4867 15.732 12.7412 14.9647 13.6151 13.8397C14.4889 12.7147 14.922 11.3093 14.833 9.88762C14.7441 8.46588 14.1391 7.12553 13.1318 6.11825C12.1246 5.11097 10.7842 4.50604 9.36247 4.41708ZM5.44292 17.0238C6.96796 17.8244 8.72286 18.0885 10.4227 17.766C12.2531 17.4187 13.8938 16.4152 15.0366 14.9438C16.1795 13.4725 16.7459 11.6346 16.6295 9.77519C16.5132 7.91579 15.722 6.16282 14.4046 4.84545C13.0873 3.52809 11.3343 2.73695 9.47489 2.62059C7.61549 2.50423 5.77756 3.07066 4.30624 4.21352C2.83491 5.35637 1.8314 6.99704 1.48413 8.82743C1.16164 10.5273 1.42573 12.2822 2.22633 13.8072L1.6629 15.7792L2.52827 16.0265L1.6629 15.7792C1.59125 16.03 1.58796 16.2954 1.65339 16.5478C1.71881 16.8003 1.85056 17.0307 2.03498 17.2151C2.21941 17.3996 2.4498 17.5313 2.70227 17.5967C2.95474 17.6621 3.22012 17.6589 3.47091 17.5872L5.44292 17.0238Z"
                    fill="currentcolor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.4705 7.9605C14.4978 7.46419 14.9223 7.084 15.4186 7.11131C16.7316 7.18357 18.0039 7.59302 19.1127 8.30016C20.2214 9.0073 21.1292 9.98826 21.7485 11.1484C22.3677 12.3085 22.6775 13.6087 22.648 14.9234C22.6215 16.1054 22.3214 17.2634 21.7737 18.3071L22.3372 20.2792C22.4088 20.53 22.4121 20.7954 22.3467 21.0479C22.2812 21.3003 22.1495 21.5307 21.9651 21.7152C21.7806 21.8996 21.5503 22.0313 21.2978 22.0968C21.0453 22.1622 20.7799 22.1589 20.5291 22.0872L18.557 21.5238C17.6745 21.9868 16.7089 22.2736 15.7141 22.3667C14.5872 22.4722 13.4509 22.3264 12.3871 21.9399C11.3233 21.5534 10.3585 20.9358 9.56213 20.1316C8.76575 19.3273 8.15764 18.3565 7.7816 17.289C7.61645 16.8201 7.86263 16.3062 8.33145 16.1411C8.80027 15.9759 9.3142 16.2221 9.47934 16.6909C9.76695 17.5074 10.2321 18.2499 10.8411 18.865C11.4502 19.4801 12.1882 19.9525 13.0018 20.2481C13.8154 20.5437 14.6845 20.6552 15.5463 20.5746C16.4043 20.4943 17.2339 20.2255 17.976 19.7877C18.1883 19.658 18.4478 19.6206 18.6923 19.6904L20.4395 20.1896L19.9403 18.4424C19.8695 18.1945 19.9096 17.9353 20.038 17.7253C20.5469 16.8629 20.826 15.8842 20.8485 14.883C20.871 13.8775 20.6341 12.8832 20.1605 11.996C19.687 11.1088 18.9927 10.3586 18.1448 9.81778C17.2968 9.27699 16.3238 8.96385 15.3197 8.90859C14.8233 8.88128 14.4432 8.4568 14.4705 7.9605Z"
                    fill="currentcolor"
                />
            </SvgIcon>
        </>
    );
};
