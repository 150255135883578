import { DateTime } from 'luxon';
import { selector } from 'recoil';
import { PatientInteractionList } from '@/common/types';
import { interactionsState } from '@/recoil/atoms/interactionsAtom';
import { DateRangeSelector } from '@/recoil/selectors/dateRangeSelector';

export const InteractionsSelector = selector({
    key: 'filteredInteractions',
    get: ({ get }) => {
        const patientInteractions = get(interactionsState);
        const { startDateMillis, endDateMillis } = get(DateRangeSelector);
        const filterInteractionsByDateRange = () => {
            const sessionData: PatientInteractionList = [];

            patientInteractions.map((interaction) => {
                const currentDate = DateTime.fromISO(interaction.date);
                const start = currentDate.startOf('day').toMillis();

                // If my interaction date is in range between the start and end calendar dates, add it to the interactionData
                if (start >= startDateMillis && start <= endDateMillis) {
                    sessionData.push(interaction);
                }
            });

            return sessionData;
        };

        const patientInteractionsByDate = filterInteractionsByDateRange();
        const sortDateTimes = (a, b) => (a > b ? 1 : a < b ? -1 : 0);

        const sortedPatientInteractions = patientInteractionsByDate
            .sort((a, b) => {
                return sortDateTimes(DateTime.fromISO(a.date).toMillis(), DateTime.fromISO(b.date).toMillis());
            })
            .reverse();

        const interactionsExistsInRange = () => {
            return (
                sortedPatientInteractions &&
                sortedPatientInteractions.some((day) => {
                    const startDate = DateTime.fromISO(day.date).startOf('day').toMillis();
                    const endDate = DateTime.fromISO(day.date).endOf('day').toMillis();

                    return startDate >= startDateMillis && endDate <= endDateMillis;
                })
            );
        };

        return { patientInteractionsByDate, sortedPatientInteractions, interactionsExistsInRange };
    },
});
