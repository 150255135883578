import { Grid, styled, Box, Typography } from '@mui/material';

export const HEPBlockDetails = styled(Box)(({ theme }) => ({
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(0.5),
    color: theme.palette.grey[500],
    '@page': {
        size: 'auto',
        margin: '0mm',
    },
}));

export const HEPBlockWrapper = styled(Grid)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    padding: `${theme.spacing(2)} 0`,
    marginTop: theme.spacing(1),
    maxWidth: '800px',
    breakInside: 'avoid',

    '&:last-of-type': {
        borderBottom: '0',
    },
}));

export const HEPBlockTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1.3rem',
    fontWeight: 600,
}));

export const HEPBlockSubTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    textTransform: 'uppercase',
}));

export const HEPBlockDetailsTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: 600,
    margin: `${theme.spacing(1)} 0 0`,
}));

export const HEPBlockDetailsInfo = styled(Typography)(({ theme }) => ({
    whiteSpace: 'pre-wrap',
    maxWidth: '550px',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    pageBreakAfter: 'auto',
}));

export const HEPBlockImgWrapper = styled(Grid)(({ theme }) => ({}));

export const HEPBlockImg = styled('img')(({ theme }) => ({
    maxWidth: '80px',
    borderRadius: '6px',
}));

export const HEPBlockInfo = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
}));

export const PrintableHEPBlockDetails = styled(Box)(({ theme }) => ({
    display: 'flex',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(0.5),
    color: theme.palette.grey[900],
}));

export const BlockAnimationContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
}));

export const AnimationStepContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    p: {
        textTransform: 'uppercase',
    },
}));
