import React from 'react';
import { Grid } from '@mui/material';
import { DashboardListItem } from '@/components/Dashboard/DashboardList/DashboardListItem/DashboardListItem';
import {
    DashboardListContainer,
    DashboardGridContainer,
    DashboardGridHeaderContainer,
    DashboardGridTitle,
} from './styles';

export interface DashboardDataProps {
    name: string;
    details: JSX.Element | JSX.Element[] | string;
    id: number | string;
}

interface DashboardListProps {
    data: DashboardDataProps[];
    columnTitles: { left: string; right: string };
}

export const DashboardList = (props: DashboardListProps) => {
    const { data, columnTitles } = props;

    return (
        <DashboardGridContainer container>
            <DashboardGridHeaderContainer container item>
                <DashboardGridTitle item xs={8}>
                    {columnTitles?.left}
                </DashboardGridTitle>
                <DashboardGridTitle item xs={4} display="flex" justifyContent="flex-end">
                    {columnTitles?.right}
                </DashboardGridTitle>
            </DashboardGridHeaderContainer>
            <Grid item xs={12}>
                <DashboardListContainer>
                    {data && data.map((row) => <DashboardListItem row={row} key={row.id} />)}
                </DashboardListContainer>
            </Grid>
        </DashboardGridContainer>
    );
};
