import React from 'react';
import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { IconButton, Tooltip, Box } from '@mui/material';
import { useAudioController, ControlEnum } from '@/common/hooks/AudioController';
import { Exercise } from '@/common/types';
import { ExerciseAnimationLazy as ExerciseAnimation } from './ExerciseAnimationLazy';
import { ViewExerciseVideoWrapper } from './ViewExercise/styles';
import { AnimationControlsContainer, AnimationControlsWrapper } from './styles';

interface ExerciseAnimationPlayerProps {
    exercise: Exercise;
}

export const ExerciseAnimationPlayer = (props: ExerciseAnimationPlayerProps) => {
    const { exercise } = props;
    const { controlState, toggle, restart } = useAudioController(exercise.demoAudioUrl);

    return (
        <Box>
            <ViewExerciseVideoWrapper>
                {exercise.animationUrl ? (
                    <ExerciseAnimation
                        animationUrl={exercise.animationUrl}
                        exerciseMetricId={exercise.exerciseMetrics[0].id}
                        controlState={exercise.demoAudioUrl ? controlState : undefined}
                        activeFrame={exercise.animationThumbnailFrame}
                    />
                ) : (
                    <video key={exercise.demoUrl} height="240" controls>
                        <source src={exercise.demoUrl} />
                    </video>
                )}
            </ViewExerciseVideoWrapper>
            {!exercise.demoAudioUrl ? null : (
                <AnimationControlsWrapper>
                    <AnimationControlsContainer>
                        <IconButton onClick={toggle}>
                            {controlState === ControlEnum.STOPPED || controlState === ControlEnum.PAUSED ? (
                                <Tooltip title="Play">
                                    <PlayCircleOutlineRoundedIcon color="primary" />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Pause">
                                    <PauseCircleOutlineRoundedIcon color="primary" />
                                </Tooltip>
                            )}
                        </IconButton>
                        <IconButton onClick={restart}>
                            <Tooltip title="Restart">
                                <ReplayRoundedIcon color="primary" />
                            </Tooltip>
                        </IconButton>
                    </AnimationControlsContainer>
                </AnimationControlsWrapper>
            )}
        </Box>
    );
};
