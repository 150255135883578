import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { PaginationMeta } from '@/common/types';
import { globalFilterState } from '@/recoil/atoms/globalSearchAtom';
import { LOCATION_FILTER_ID } from '../common/Table/Filters/LocationFilter';
import { PATIENT_PRODUCT_FILTER_ID } from '../common/Table/Filters/PatientProductFilter';

export interface SortMeta {
    sortKey: string;
    dir: 'ASC' | 'DESC';
}

interface FilterMeta {
    filterKey: string;
    filterValue: string;
}

interface IPaginationContext {
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    globalSearch?: string;
    handleSetGlobalSearch: (_) => void;
    paginationMeta: PaginationMeta | null;
    setPaginationMeta: Dispatch<SetStateAction<PaginationMeta>>;
    noFilters: () => boolean;
    sort?: SortMeta | null;
    handleSetSort: (_: string, __: 'ASC' | 'DESC' | null) => void;
    filters: FilterMeta[];
    handleSetFilters: (key: string, value: string | null) => void;
}

export const PaginationContext = createContext<IPaginationContext | null>(null);

export const PaginationProvider = (props) => {
    const globalFilters = useRecoilValue(globalFilterState);
    const [page, setPage] = useState(1);
    const [globalSearch, setGlobalSearch] = useState<string>();
    const [sort, setSort] = useState<SortMeta | null>();
    const [filters, setFilters] = useState<FilterMeta[]>([]);
    const [paginationMeta, setPaginationMeta] = useState<PaginationMeta | null>(null);

    const noFilters = (): boolean => {
        return !globalSearch && filters.length === 0;
    };

    const handleSetSort = (sortKey: string, dir: 'ASC' | 'DESC' | null) => {
        if (!dir) {
            setSort(null);
        } else {
            setSort({ sortKey, dir });
        }
    };

    const handleSetGlobalSearch = (searchTerm: string) => {
        setPage(1);
        setGlobalSearch(searchTerm);
    };

    const handleSetFilters = (key: string, value: string) => {
        const otherFilters = filters?.filter((ac) => {
            return ac.filterKey !== key;
        });
        if (!value) {
            setFilters(otherFilters || []);
        } else {
            const addFilterArray = [{ filterKey: key, filterValue: value }];
            const filterArray = (otherFilters || []).concat(addFilterArray);
            setFilters(filterArray.length > 0 ? filterArray : []);
        }

        setPage(1);
    };

    useEffect(() => {
        const currentActiveProducts = [...globalFilters.products];

        const newFilters = [
            ...(currentActiveProducts.length > 0
                ? [
                      {
                          filterKey: PATIENT_PRODUCT_FILTER_ID,
                          filterValue: JSON.stringify(currentActiveProducts),
                      },
                  ]
                : []),
            ...(globalFilters.locationId
                ? [
                      {
                          filterKey: LOCATION_FILTER_ID,
                          filterValue: `${globalFilters.locationId}`,
                      },
                  ]
                : []),
        ];
        setFilters(newFilters);
    }, [globalFilters]);

    const modalContext = {
        page,
        setPage,
        globalSearch,
        handleSetGlobalSearch,
        paginationMeta,
        setPaginationMeta,
        noFilters,
        sort,
        handleSetSort,
        filters,
        handleSetFilters,
    };

    return <PaginationContext.Provider value={modalContext}>{props.children}</PaginationContext.Provider>;
};
