import React, { createContext, Dispatch, FC, SetStateAction, useEffect, useReducer, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { mixpanel } from '@/common/mixpanel';
import {
    Program,
    HEPBlock,
    PatientRecord,
    HEPTemplate,
    TemplateOrHEP,
    AssignOrCancelEnum,
    CreateHEPDto,
} from '@/common/types';
import { characterCountWithLineBreaks } from '@/common/utils';
import { useExercisesContext } from '@/components/context/hooks/ExercisesContext';
import { hepTemplatesState, hepTemplateState } from '@/recoil/atoms/hepTemplatesAtom';
import { resultIsError } from '@/services/HttpService';
import { PortalEvent } from '@/services/events/const';
import eventService from '@/services/events/eventService';
import { HepBuilderActions, hepBuilderReducer } from './HepBuilderReducer';

type HEPErrors = { name?: string; notes?: string; dailyFrequency?: string; weeklyFrequency?: string; blocks?: string };

export const NOTES_MAX_LENGTH = 500;

const initialHEPErrors = {
    name: undefined,
    notes: undefined,
    dailyFrequency: undefined,
    weeklyFrequency: undefined,
    blocks: undefined,
};

export enum BuilderActions {
    CREATE_HEP = 'CREATE_HEP',
    CREATE_TEMPLATE = 'CREATE_TEMPLATE',
    EDIT_HEP = 'EDIT_HEP',
    EDIT_TEMPLATE = 'EDIT_TEMPLATE',
    DUPLICATE_TEMPLATE = 'DUPLICATE_TEMPLATE',
    DELETE_TEMPLATE = 'DELETE_TEMPLATE',
    ASSIGN = 'ASSIGN',
}

interface HEPBuilderMethods {
    setName: (name: string) => void;
    setNotes: (notes: string) => void;
    setDailyFrequency: (frequency: number) => void;
    setWeeklyFrequency: (frequency: number) => void;
    setBlocks: (blocks: HEPBlock[]) => void;
    deleteBlock: (index: number) => void;
    editBlock: ({ index, hepBlock }: { index: number; hepBlock: HEPBlock }) => void;
}

interface IHEPBuilderContext {
    activeStep: number;
    setActiveStep: Dispatch<SetStateAction<1 | 2 | 3>>;
    isEditing: boolean;
    isComplete: boolean;
    replace: number | null | undefined;
    setReplace: Dispatch<SetStateAction<number | null>>;
    patientRecord: PatientRecord | null | undefined;
    setPatientRecord: Dispatch<SetStateAction<PatientRecord>>;
    hepDetails: CreateHEPDto | HEPTemplate;
    hepBuilderActions?: HEPBuilderMethods;
    hepErrors: HEPErrors;
    setHEPErrors: Dispatch<SetStateAction<HEPErrors>>;
    handleNext: () => void;
    handleBack: () => void;
    handleAssignTemplate: (_: HEPTemplate) => void;
    handleAssignAndSaveAsTemplate: (any) => void;
    createdHEP?: Program;
    onClose?: (_: Program) => void;
    isEditingTitle: boolean;
    isEditingNotes: boolean;
    handleEditingNotes: (isEditingNotes: boolean) => void;
    handleEditingTitle: (isEditingTitle: boolean) => void;
    isEditingHEP: boolean;
    isTemplateOrHEP: TemplateOrHEP;
    actionType: BuilderActions;
}
export const HEPBuilderContext = createContext<IHEPBuilderContext | null>(null);

interface Props {
    children: JSX.Element | JSX.Element[];
    onClose?: (program: Program) => void;
    initialPatientRecord?: PatientRecord;
    existingTemplateOrHEP?: Program;
    handleIsDirty: (isDirty: boolean) => void;
    isTemplateOrHEP: TemplateOrHEP;
    actionType: BuilderActions;
}

export const HEPBuilderProvider: FC<Props> = (props: Props) => {
    const {
        children,
        onClose,
        initialPatientRecord,
        existingTemplateOrHEP,
        handleIsDirty,
        isTemplateOrHEP,
        actionType,
    } = props;
    const isEditingHEP = !!existingTemplateOrHEP;
    const [activeStep, setActiveStep] = useState<0 | 1 | 2 | 3>(actionType === BuilderActions.ASSIGN ? 0 : 1);
    const [isEditing, setIsEditing] = useState(!!existingTemplateOrHEP);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isEditingNotes, setIsEditingNotes] = useState(false);
    const [replace, setReplace] = useState<number | null | undefined>(undefined);
    const [isComplete, setIsComplete] = useState(false);
    const { httpService } = useHttpContext();
    const { exercisesDict } = useExercisesContext();
    const setCurrentTemplate = useSetRecoilState(hepTemplateState);
    const [patientRecord, setPatientRecord] = useState<PatientRecord | null | undefined>(initialPatientRecord);
    const [hepDetails, dispatchHEPDetails] = useReducer(
        hepBuilderReducer,
        existingTemplateOrHEP || {
            name: '',
            notes: '',
            dailyFrequency: 3,
            weeklyFrequency: 5,
            blocks: [],
        },
    );
    const [hepErrors, setHEPErrors] = useState<HEPErrors>(initialHEPErrors);
    const existingHEPId = existingTemplateOrHEP?.id;
    const [createdHEP, setCreatedHEP] = useState<Program | undefined>(undefined);
    const [hepTemplates, setHEPTemplates] = useRecoilState(hepTemplatesState);
    const isTemplate = actionType.includes('TEMPLATE');
    const { successToast, errorToast } = toasts;

    useEffect(() => {
        let errors: HEPErrors = { ...initialHEPErrors };
        if (hepDetails?.notes && characterCountWithLineBreaks(hepDetails.notes) >= NOTES_MAX_LENGTH) {
            errors = { ...errors, notes: `The number of characters should be max ${NOTES_MAX_LENGTH.toString()}` };
        }
        setHEPErrors(errors);

        handleIsDirty(
            JSON.stringify(existingTemplateOrHEP) !== JSON.stringify(hepDetails) && hepDetails.name.length > 0,
        );
    }, [hepDetails]);

    const handleNext = () => {
        switch (activeStep) {
            case 0:
                setActiveStep(1);
                break;
            case 1:
                if (!hepDetails?.name) {
                    setHEPErrors((prevState) => ({
                        ...prevState,
                        name: 'Name is required',
                    }));
                } else {
                    setActiveStep(2);
                }
                break;
            case 2:
                if (!hepDetails?.blocks || hepDetails.blocks.length < 1) {
                    setHEPErrors((prevState) => ({
                        ...prevState,
                        blocks: `You must add exercises in order to create the ${!isTemplate ? 'Program' : 'Template'}`,
                    }));
                } else if (isEditingTitle) {
                    setHEPErrors((prevState) => ({
                        ...prevState,
                        name: "Don't forget to save or cancel your changes",
                    }));
                } else {
                    setActiveStep(3);
                }
                break;
            case 3:
                if (!hepDetails?.blocks || hepDetails.blocks.length < 1) {
                    errorToast(
                        `You must add exercises in order to create the ${!isTemplate ? 'Program' : 'Template'}.`,
                    );
                    return;
                }

                if (isEditingTitle) {
                    setHEPErrors((prevState) => ({
                        ...prevState,
                        name: "Don't forget to save or cancel your changes",
                    }));
                    break;
                }

                if (isTemplate) {
                    const hepTemplate = hepDetails as HEPTemplate;
                    (async () => {
                        if (
                            actionType === BuilderActions.CREATE_TEMPLATE ||
                            actionType === BuilderActions.DUPLICATE_TEMPLATE
                        ) {
                            const result = await httpService.createHEPTemplate(hepTemplate);
                            try {
                                const exerciseArray = hepTemplate.blocks.map((block) => {
                                    return exercisesDict?.[block.exerciseId].name || '';
                                });
                                mixpanel.track('Created Program Template', {
                                    'Number of Exercises': +hepTemplate.blocks.length,
                                    'Weekly Frequency': +hepTemplate.weeklyFrequency,
                                    'Daily Frequency': hepTemplate.dailyFrequency,
                                    'Program Exercises': exerciseArray.join(', '),
                                    $current_url: '',
                                });
                            } catch (e) {
                                console.log(e);
                            }
                            if (!result || resultIsError(result)) {
                                errorToast(result?.message || 'Something went wrong.');
                            } else {
                                setCreatedHEP(result);
                                setHEPTemplates([result, ...hepTemplates]);

                                setIsComplete(true);
                                handleIsDirty(false);
                                actionType === BuilderActions.CREATE_TEMPLATE
                                    ? successToast(`Successfully created ${result.name}`)
                                    : successToast(`Successfully duplicated ${result.name}`);
                            }
                        } else if (actionType === BuilderActions.EDIT_TEMPLATE) {
                            if (!hepTemplate.id) {
                                return;
                            }
                            const result = await httpService.updateHEPTemplate(hepTemplate.id, hepTemplate);
                            if (resultIsError(result)) {
                                errorToast(result.message);
                            } else {
                                setCreatedHEP(result);
                                setCurrentTemplate(result);
                                if (hepTemplates) {
                                    setHEPTemplates((prevTemplates) => {
                                        const index = prevTemplates.findIndex((template) => template.id === result.id);
                                        return index !== -1
                                            ? [
                                                ...prevTemplates.slice(0, index),
                                                result,
                                                ...prevTemplates.slice(index + 1),
                                            ]
                                            : [result, ...prevTemplates];
                                    });
                                }
                                setIsComplete(true);
                                handleIsDirty(false);
                            }
                        }
                    })();
                }

                if (!isTemplate) {
                    (async () => {
                        if (!patientRecord && existingHEPId) {
                            setReplace(existingHEPId);
                        } else {
                            const result = await httpService.createHealthHEP(hepDetails);

                            try {
                                const exerciseArray = hepDetails.blocks.map((block) => {
                                    return exercisesDict?.[block.exerciseId].name || '';
                                });
                                mixpanel.track('Published Program', {
                                    'Number of Exercises': +hepDetails.blocks.length,
                                    'Weekly Frequency': +hepDetails.weeklyFrequency,
                                    'Daily Frequency': hepDetails.dailyFrequency,
                                    'Program Exercises': exerciseArray.join(', '),
                                });
                            } catch (e) {
                                console.log(e);
                            }

                            if (resultIsError(result)) {
                                errorToast(result.message);
                            } else {
                                setCreatedHEP(result);
                                setIsComplete(true);
                                handleIsDirty(false);

                                // Patient Specific
                                if (patientRecord) {
                                    const updatedPatientRecord = await httpService.createHealthHEPAssignment(
                                        result.id,
                                        patientRecord.id,
                                        !!existingTemplateOrHEP,
                                    );

                                    if (updatedPatientRecord && !resultIsError(updatedPatientRecord)) {
                                        eventService.dispatch(PortalEvent.UPDATE_PATIENT, updatedPatientRecord);
                                        successToast(
                                            `Successfully ${actionType === BuilderActions.EDIT_HEP ? 'updated' : 'assigned'
                                            } Program. This patient will be notified about their updated Program.`,
                                        );
                                        onClose && onClose(result);

                                        mixpanel.track('Assigned Program', { $current_url: '' });
                                    } else {
                                        errorToast('There was a problem assigning the Program to the patient.');
                                    }
                                } else {
                                }
                            }
                        }
                    })();
                }
                break;
        }
    };

    const handleBack = () => {
        handleEditingTitle(false);
        handleEditingNotes(false);
        switch (activeStep) {
            case 1:
                setActiveStep(0);
                break;
            case 2:
                setActiveStep(1);
                break;
            case 3:
                setActiveStep(2);
                break;
        }
    };

    const handleAssignTemplate = async (template: HEPTemplate) => {
        try {
            // Create new Program from template
            const newHEP = await httpService.createHealthHEP(template);

            if (resultIsError(newHEP)) {
                errorToast(newHEP.message);
            } else if (!patientRecord?.id) {
                errorToast('Could not assign template');
            } else {
                // Assign newly created Program to patient
                const result = await httpService.createHealthHEPAssignment(
                    newHEP.id,
                    patientRecord.id,
                    !!existingTemplateOrHEP,
                );

                if (result && !resultIsError(result)) {
                    eventService.dispatch(PortalEvent.UPDATE_PATIENT, result);
                    successToast(
                        'Successfully assigned Program. This patient will be notified about their updated Program.',
                    );
                    onClose && onClose(newHEP);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleAssignAndSaveAsTemplate = async ({
        AssignOrCancel,
        templateName,
        templateNotes,
    }: {
        AssignOrCancel: AssignOrCancelEnum;
        templateName?: string;
        templateNotes?: string;
    }) => {
        try {
            // Create new Program from template
            const newHEP = await httpService.createHealthHEP(hepDetails);

            if (resultIsError(newHEP)) {
                errorToast(newHEP.message);
            } else if (!patientRecord?.id) {
                errorToast('Could not assign template');
            } else {
                // Assign newly created Program to patient
                const result = await httpService.createHealthHEPAssignment(
                    newHEP.id,
                    patientRecord.id,
                    !!existingTemplateOrHEP,
                );

                if (result && !resultIsError(result)) {
                    if (AssignOrCancel === AssignOrCancelEnum.ASSIGN) {
                        const hepTemplate = await httpService.createHEPTemplate({
                            ...hepDetails,
                            name: templateName,
                            notes: templateNotes,
                        } as HEPTemplate);

                        if (!resultIsError(hepTemplate)) {
                            eventService.dispatch(PortalEvent.UPDATE_PATIENT, result);
                            successToast('Program succesfully assigned & saved as template');
                            onClose && onClose(newHEP);
                        }
                    } else if (AssignOrCancel === AssignOrCancelEnum.CANCEL) {
                        eventService.dispatch(PortalEvent.UPDATE_PATIENT, result);
                        successToast('Program successfully assigned.');
                        onClose && onClose(newHEP);
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const hepBuilderActions = {
        setName: (payload: string) => {
            dispatchHEPDetails({ type: HepBuilderActions.SET_NAME, payload });
        },
        setNotes: (payload: string) => {
            dispatchHEPDetails({ type: HepBuilderActions.SET_NOTES, payload });
        },
        setDailyFrequency: (payload: number) => {
            dispatchHEPDetails({ type: HepBuilderActions.SET_FREQ_DAILY, payload });
        },
        setWeeklyFrequency: (payload: number) => {
            dispatchHEPDetails({ type: HepBuilderActions.SET_FREQ_WEEKLY, payload });
        },
        setBlocks: (payload: HEPBlock[]) => {
            dispatchHEPDetails({ type: HepBuilderActions.SET_BLOCKS, payload });
        },
        deleteBlock: (payload: number) => {
            dispatchHEPDetails({ type: HepBuilderActions.DELETE_BLOCK, payload });
        },
        editBlock: (payload: { index: number; hepBlock: HEPBlock }) => {
            dispatchHEPDetails({ type: HepBuilderActions.EDIT_BLOCK, payload });
        },
    };
    const handleEditingNotes = (payload: boolean) => {
        setIsEditingNotes(payload);
    };

    const handleEditingTitle = (payload: boolean) => {
        if (!hepDetails.name) {
            setHEPErrors((prevState) => ({
                ...prevState,
                name: 'Name is required',
            }));
        } else {
            setHEPErrors(initialHEPErrors);
            setIsEditingTitle(payload);
        }
    };

    const hepBuilderContext = {
        activeStep,
        setActiveStep,
        isEditing,
        isComplete,
        replace,
        setReplace,
        patientRecord,
        setPatientRecord,
        hepDetails,
        hepBuilderActions,
        hepErrors,
        setHEPErrors,
        handleNext,
        handleBack,
        createdHEP,
        onClose,
        isEditingNotes,
        isEditingTitle,
        handleEditingNotes,
        handleEditingTitle,
        isEditingHEP,
        isTemplateOrHEP,
        actionType,
        handleAssignTemplate,
        handleAssignAndSaveAsTemplate,
    };

    return <HEPBuilderContext.Provider value={hepBuilderContext}>{children}</HEPBuilderContext.Provider>;
};
