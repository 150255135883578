import React from 'react';
import { SvgIcon } from '@mui/material';

export const HomeIcon = (props) => {
    return (
        <>
            <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.0001 2.41367L18.3358 9.82313C18.7486 10.19 19.3806 10.1529 19.7475 9.74008C20.1145 9.3273 20.0773 8.69523 19.6645 8.32831L10.7974 0.446419C10.3427 0.0422736 9.65756 0.0422749 9.2029 0.446419L0.335769 8.32831C-0.0770139 8.69523 -0.114195 9.3273 0.252723 9.74008C0.619641 10.1529 1.25171 10.19 1.6645 9.82313L10.0001 2.41367ZM4.03137 9.95068C4.58366 9.95068 5.03137 10.3984 5.03137 10.9507V17.3569C5.03137 17.6331 5.25523 17.8569 5.53137 17.8569H14.4689C14.745 17.8569 14.9689 17.6331 14.9689 17.3569V10.9507C14.9689 10.3984 15.4166 9.95068 15.9689 9.95068C16.5212 9.95068 16.9689 10.3984 16.9689 10.9507V17.3569C16.9689 18.7376 15.8496 19.8569 14.4689 19.8569H5.53137C4.15066 19.8569 3.03137 18.7376 3.03137 17.3569V10.9507C3.03137 10.3984 3.47909 9.95068 4.03137 9.95068ZM10.0001 13.9113C11.1887 13.9113 12.1523 12.9477 12.1523 11.7591C12.1523 10.5705 11.1887 9.60693 10.0001 9.60693C8.81152 9.60693 7.84796 10.5705 7.84796 11.7591C7.84796 12.9477 8.81152 13.9113 10.0001 13.9113Z"
                    fill="currentColor"
                />
            </SvgIcon>
        </>
    );
};
