import React, { useState } from 'react';
import { IconButton, Button, Grid } from '@mui/material';
import { AddIcon } from '@/components/common/Icons/AddIcon';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { CellKeyword } from './AdminLocationsListContainer';
import { KeywordInput } from './styles';

export const KeywordCell = ({
    cell,
    handleUpdateKeyword,
}: {
    cell: CellKeyword;
    handleUpdateKeyword: (id: number, keyword: string | null) => Promise<boolean>;
}) => {
    const [value, setValue] = useState(cell.keyword);
    const [isEditing, setIsEditing] = useState(false);

    const handleSaveKeyword = () => {
        const updatedKeyword = handleUpdateKeyword(cell.id, value);

        if (!updatedKeyword) {
            setValue(cell.keyword);
        }
        setIsEditing(false);
    };

    const handleCancelKeyword = () => {
        setValue(cell.keyword);
        setIsEditing(false);
    };

    const handleAddEdit = () => {
        setIsEditing(true);
    };

    const handleKeywordChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <div>
            {!isEditing ? (
                <>
                    {value ? (
                        <>
                            {value}
                            <IconButton size="small" onClick={handleAddEdit}>
                                <EditIcon />
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <IconButton size="small" onClick={handleAddEdit}>
                                <AddIcon />
                            </IconButton>
                        </>
                    )}
                </>
            ) : (
                <Grid container>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <KeywordInput value={value} onChange={handleKeywordChange} />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button onClick={handleCancelKeyword}>Cancel</Button>
                        <Button variant="secondary" onClick={handleSaveKeyword} style={{ padding: '0 8px' }}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};
