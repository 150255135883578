import { styled, Chip } from '@mui/material';
import { Products } from '@/common/const';

export const ProductBadgeWrapper = styled(Chip)<{ label: string }>(({ theme, label }) => {
    let color = theme.palette.info[700];
    let background = theme.palette.info[200];
    switch (label) {
        case Products.HEALTH:
            color = theme.palette.success[800];
            background = theme.palette.success[200];
            break;
        case Products.PRO:
            color = theme.palette.primary[500];
            background = theme.palette.primary[100];
            break;
        case Products.GAIT:
            color = theme.palette.info[700];
            background = theme.palette.info[200];
            break;
    }
    return {
        color,
        background,
        marginRight: theme.spacing(1),
    };
});

export const PatientProductBadgesWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    [theme.breakpoints.down('chromebook')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& > div + div': {
            marginTop: 4,
        },
    },
}));
