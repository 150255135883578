import React from 'react';
import { SvgIcon } from '@mui/material';

export const ActiveNavItemIcon = (props) => {
    return (
        <SvgIcon width="4px" height="48px" viewBox="0 0 4 48" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 4C4 1.79086 2.20914 0 0 0V48C2.20914 48 4 46.2091 4 44V4Z"
                fill="url(#paint0_linear_2220_6228)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2220_6228"
                    x1="2"
                    y1="0"
                    x2="10.2163"
                    y2="57.3217"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.234375" stopColor="#5A43FF" />
                    <stop offset="0.895833" stopColor="#FC615D" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
};
