import { styled } from '@mui/material';

export const SessionReplayCanvasWrapper = styled('div')<{ width: number | string; height: number | string }>(
    ({ width, height }) => ({
        width: width,
        height: height,
        border: '1px solid #666666',
        borderRadius: 8,
        margin: '0 auto',
    }),
);
