import React, { useRef, useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { Chart as ChartJS, registerables } from 'chart.js';
import { DateTime } from 'luxon';
import { useRecoilValue } from 'recoil';
import { TimeSpan } from '@/common/types';
import { getStartAndEndOfDateInMillis } from '@/common/utils';
import { ChartEmpty } from '@/components/common/Chart/ChartEmpty';
import { Panel, PanelDetails, PanelSummary, PanelText } from '@/components/common/Chart/styles';
import { DateRangeSelector } from '@/recoil/selectors/dateRangeSelector';
import { ROMData, ROMDataDict } from './types';

ChartJS.register(...registerables);

export const options = {
    clip: false,
    responsive: true,
    redraw: true,
    scales: {
        y: {
            beginAtZero: true,
        },
    },
    plugins: {
        legend: {
            position: 'bottom' as const,
            align: 'start' as const,
        },
        title: {
            display: false,
            text: '',
        },
    },
    tooltip: {
        position: 'nearest',
    },
};

export const PatientChart = ({
    sessions,
    currentChart,
    timeSpan,
}: {
    sessions: ROMDataDict;
    currentChart: string;
    timeSpan: TimeSpan;
}) => {
    const chartContainer = useRef<HTMLCanvasElement>(null);
    const [chartInstance, setChartInstance] = useState<ChartJS<'line', number[], string> | null>(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const { startDateMillis, endDateMillis } = useRecoilValue(DateRangeSelector);

    const getData = (rom: ROMData) => {
        const dateLabels: string[] = [];
        const minDataSet = { ...rom.chart.datasets[0] };
        const maxDataSet = { ...rom.chart.datasets[1] };
        minDataSet.data = [];
        maxDataSet.data = [];

        Object.values(rom.chart.labels).map((setDate, i) => {
            const date = DateTime.fromJSDate(new Date(setDate)).toFormat('dd LLL');
            const processedDate = getStartAndEndOfDateInMillis(setDate);

            if (processedDate.startDate >= startDateMillis && processedDate.endDate <= endDateMillis) {
                date && dateLabels.unshift(date);
                minDataSet.data.unshift(rom.chart.datasets[0].data[i]);
                maxDataSet.data.unshift(rom.chart.datasets[1].data[i]);
            }
        });

        setIsEmpty(minDataSet.data.length < 1);

        return {
            minDataSet,
            maxDataSet,
            dateLabels,
        };
    };

    function updateChartData() {
        if (sessions && Object.keys(sessions).length && chartInstance && chartInstance.data) {
            Object.values(sessions).map((rom) => {
                if (rom.exerciseId === currentChart) {
                    const { minDataSet, maxDataSet, dateLabels } = getData(rom);
                    chartInstance.data.datasets = [minDataSet, maxDataSet];
                    chartInstance.data.labels = dateLabels;
                    chartInstance.update();
                }
            });
        }
    }

    useEffect(() => {
        if (!!chartContainer?.current) {
            const currentChartContainer = chartContainer.current;
            Object.values(sessions).map((rom) => {
                if (rom.exerciseId === currentChart && chartInstance === null) {
                    const { minDataSet, maxDataSet, dateLabels } = getData(rom);
                    const newChartInstance = new ChartJS(currentChartContainer, {
                        type: 'line',
                        data: {
                            datasets: [minDataSet, maxDataSet],
                            labels: dateLabels,
                        },
                        options: options as any,
                    });
                    setChartInstance(newChartInstance);
                }
            });
        }
    }, []);

    useEffect(() => {
        updateChartData();
    }, [currentChart, timeSpan]);

    return (
        <div>
            <canvas ref={chartContainer} id="patientChart" style={{ display: isEmpty ? 'none' : 'block' }} />
            {isEmpty && (
                <ChartEmpty
                    title="No patient data available in this date range"
                    body="Your patient has not done any activity in this date range. Change the date range to view your patient's progress."
                />
            )}
            <Panel elevation={0}>
                <PanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="graph-info" id="graph-info-header">
                    <Typography>What does this graph show?</Typography>
                </PanelSummary>
                <PanelDetails>
                    <PanelText variant="body1">
                        This graph reflects the highest end range measurement and its associated start for the specified
                        day.
                    </PanelText>
                    <PanelText variant="body1">
                        If the patient does multiple measurements in a day, you can see all their daily session data
                        under the “Activity” tab.
                    </PanelText>
                    <PanelText variant="body1">
                        Click on any label displayed underneath the graph to toggle the inclusion of that metric.
                    </PanelText>
                </PanelDetails>
            </Panel>
        </div>
    );
};
