import React, { useState } from 'react';
import { Typography, Grid } from '@mui/material';
import { TemplateOrHEP } from '@/common/types';
import { handleEnterPress } from '@/common/utils';
import { EditActions } from '@/components/Health/HEPBuilder/steps/EditActions/EditActions';
import { FrequencyEditor } from '@/components/Health/HEPBuilder/steps/FrequencyEditor/FrequencyEditor';
import { FrequencyActionsWrapper } from '@/components/Health/HEPBuilder/steps/FrequencyEditor/styles';
import { HEPError, HEPNotesLength, HEPTextField } from '@/components/Health/HEPBuilder/steps/StepInformation/styles';
import { TemplateReady } from '@/components/Health/HEPBuilder/steps/StepSummary/TemplateReady/TemplateReady';
import { LockIcon } from '@/components/common/Icons/LockIcon';
import { Button } from '@/components/common/button/button';
import { HEPSummaryWrapper } from '../../../HEPs/styles';
import { DraggableBuiltHEPBlocks } from '../../BuiltHEPBlocks/DraggableBuiltHEPBlocks';
import { NOTES_MAX_LENGTH } from '../../state/HEPBuilderContext';
import { useHEPBuilderContext } from '../../state/hooks/HEPBuilderContext';
import {
    StepSummaryContentHeader,
    StepSummaryContentWrapper,
    StepSummaryTitleWrapper,
    StepSummaryWrapper,
    StepSummaryNotes,
    StepSummaryContentHeaderActions,
    StepSummaryContentBody,
    StepSummaryCaptionTitle,
} from './styles';

interface StepSummaryProps {
    closeModal?: () => void;
}

export const StepSummary = (props: StepSummaryProps) => {
    const { closeModal } = props;
    const {
        patientRecord,
        hepDetails,
        isComplete,
        hepBuilderActions,
        hepErrors,
        isEditingTitle,
        isEditingNotes,
        handleEditingTitle,
        handleEditingNotes,
        isTemplateOrHEP,
    } = useHEPBuilderContext();
    const [hepName, setHepName] = useState(hepDetails.name ? hepDetails.name : '');

    const handleNameEditClick = () => {
        handleEditingTitle(!isEditingTitle);
    };

    const handleNotesEditClick = () => {
        handleEditingNotes(!isEditingNotes);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHepName(e.target.value);
    };

    const handleNameSubmit = () => {
        hepBuilderActions?.setName(hepName);
        handleEditingTitle(!isEditingTitle);
    };

    const handleNameEnterPress = (e: React.KeyboardEvent) => {
        handleEnterPress(e, handleNameSubmit);
    };

    const handleCancelNameEdit = () => {
        setHepName(hepDetails.name ? hepDetails.name : '');
        handleEditingTitle(!isEditingTitle);
    };

    const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        hepBuilderActions?.setNotes(e.target.value);
    };

    const handleNotesSubmit = () => {
        handleEditingNotes(!isEditingNotes);
    };

    return (
        <StepSummaryWrapper>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item sm={10} md={8} lg={8} xl={7}>
                    {isComplete ? (
                        isTemplateOrHEP === TemplateOrHEP.TEMPLATE ? (
                            <TemplateReady closeModal={closeModal} />
                        ) : null
                    ) : (
                        <>
                            <Typography variant="h4" component="h1" fontWeight={700}>
                                Program {isTemplateOrHEP === TemplateOrHEP.TEMPLATE ? 'Template' : ''} Summary{' '}
                                {patientRecord &&
                                    `for ${patientRecord.patient.firstName} ${patientRecord.patient.lastName}`}
                            </Typography>
                            <StepSummaryContentBody>
                                {isTemplateOrHEP === TemplateOrHEP.Program
                                    ? 'Check the Program you built.'
                                    : 'Review the template you built.'}
                            </StepSummaryContentBody>
                            <StepSummaryContentWrapper>
                                <StepSummaryContentHeader>
                                    {isEditingTitle ? (
                                        <>
                                            <div>
                                                <HEPTextField
                                                    required={true}
                                                    value={hepName}
                                                    onChange={handleNameChange}
                                                    placeholder="Name Your Program"
                                                    id="program-name"
                                                    label="Program Name"
                                                    variant="outlined"
                                                    error={!!hepErrors?.name}
                                                    onKeyDown={handleNameEnterPress}
                                                    autoFocus
                                                />
                                                <HEPError>{hepErrors?.name}</HEPError>
                                            </div>
                                            <StepSummaryContentHeaderActions>
                                                <Button onClick={handleCancelNameEdit} size="small" variant="text">
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={handleNameSubmit}
                                                    size="small"
                                                    startIcon={<LockIcon />}
                                                    variant="textPrimary"
                                                >
                                                    Save
                                                </Button>
                                            </StepSummaryContentHeaderActions>
                                        </>
                                    ) : (
                                        <StepSummaryTitleWrapper variant="body2">
                                            <span>{hepDetails.name}</span>
                                            <EditActions handleClick={handleNameEditClick} />
                                        </StepSummaryTitleWrapper>
                                    )}
                                </StepSummaryContentHeader>
                                <hr />
                                <HEPSummaryWrapper>
                                    {!isEditingNotes && (
                                        <StepSummaryCaptionTitle variant="caption">
                                            <span>Program Notes</span>
                                            <EditActions handleClick={handleNotesEditClick} />
                                        </StepSummaryCaptionTitle>
                                    )}
                                    <StepSummaryNotes>
                                        {isEditingNotes ? (
                                            <>
                                                <HEPTextField
                                                    multiline={true}
                                                    rows={4}
                                                    inputProps={{
                                                        maxLength: NOTES_MAX_LENGTH,
                                                    }}
                                                    value={hepDetails.notes}
                                                    onChange={handleNotesChange}
                                                    placeholder="Type something..."
                                                    id="program-name"
                                                    label="Program notes (optional)"
                                                    variant="outlined"
                                                    error={!!hepErrors?.notes}
                                                />
                                                <HEPError>
                                                    {hepErrors?.notes || `\u00a0`}{' '}
                                                    <HEPNotesLength
                                                        $isMax={
                                                            hepDetails.notes
                                                                ? hepDetails.notes.length >= NOTES_MAX_LENGTH
                                                                : false
                                                        }
                                                    >
                                                        {hepDetails.notes?.length}/{NOTES_MAX_LENGTH.toString()}
                                                    </HEPNotesLength>
                                                </HEPError>
                                                <StepSummaryContentHeaderActions>
                                                    <Button
                                                        onClick={handleNotesSubmit}
                                                        size="small"
                                                        variant="textPrimary"
                                                    >
                                                        Done
                                                    </Button>
                                                </StepSummaryContentHeaderActions>
                                            </>
                                        ) : (
                                            <>{hepDetails.notes || '-'}</>
                                        )}
                                    </StepSummaryNotes>
                                    <FrequencyActionsWrapper>
                                        <h5>Program Frequency</h5>
                                        <FrequencyEditor isInModal={true} />
                                    </FrequencyActionsWrapper>
                                    <p>
                                        Daily Frequency: {hepDetails.dailyFrequency} {`\u00a0`} Weekly Frequency:{' '}
                                        {hepDetails.weeklyFrequency}
                                    </p>
                                    <Typography>{`\u00a0`}</Typography>
                                    <StepSummaryCaptionTitle variant="caption">Exercises</StepSummaryCaptionTitle>
                                    <DraggableBuiltHEPBlocks />
                                </HEPSummaryWrapper>
                            </StepSummaryContentWrapper>
                        </>
                    )}
                </Grid>
            </Grid>
        </StepSummaryWrapper>
    );
};
