import React from 'react';
import { SvgIcon } from '@mui/material';

export const HealthShieldIcon = (props) => {
    return (
        <>
            <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 1.96924L21 4.21924V13.1765C21 18.0705 16.9494 22 12 22C7.05058 22 3 18.0705 3 13.1765V4.21924L12 1.96924ZM5 5.78079V13.1765C5 16.9241 8.11286 20 12 20C15.8872 20 19 16.9241 19 13.1765V5.78079L12 4.03079L5 5.78079Z"
                    fill="currentColor"
                />
                <path fillRule="evenodd" clipRule="evenodd" d="M9 11H15V13H9V11Z" fill="currentColor" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11 15V9H13V15H11Z" fill="currentColor" />
            </SvgIcon>
        </>
    );
};
