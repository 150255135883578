import React from 'react';
import { Tooltip } from '@mui/material';
import { RTMStatusEnum } from '@/common/types';
import { StatusChip as Chip } from '../styles';

export const StatusChip = ({ status }: { status: keyof typeof RTMStatusEnum }) => {
    return (
        <Tooltip title={RTMStatusEnum[status]}>
            <Chip $status={status} label={RTMStatusEnum[status]} />
        </Tooltip>
    );
};
