import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { HEPTemplate } from '@/common/types';
import { ExerciseTotal } from './styles';

interface TemplateSelectListProps {
    data: HEPTemplate[];
    selectedItem?: (value: string | number) => void;
}

export const TemplateSelectList = (props: TemplateSelectListProps) => {
    const { data, selectedItem } = props;
    const [selectedTemplateId, setSelectedTemplateId] = useState<string | number>();

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedTemplateId((event.target as HTMLInputElement).value);
        selectedItem && selectedItem((event.target as HTMLInputElement).value);
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Or assign a Program directly from your template list</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedTemplateId}
                label="Or assign a Program directly from your template list"
                onChange={handleChange}
            >
                {data.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.name}
                        {item.blocks && (
                            <ExerciseTotal variant="caption">
                                ({item.blocks.length} exercise{item.blocks.length > 1 && 's'})
                            </ExerciseTotal>
                        )}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
