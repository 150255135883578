import { useContext } from 'react';
import { PractitionersContext } from '../PractitionersContext';

export const usePractitionersContext = () => {
    const practitionersContext = useContext(PractitionersContext);

    if (!practitionersContext) {
        throw new Error('PractitionersContext not initialized');
    }

    return practitionersContext;
};
