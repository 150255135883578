import { mixpanel } from '@/common/mixpanel';
import { resultIsError } from '@/services/HttpService';
import { IHttpService } from '@/services/IHttpService';
import { RecordType } from './const';

export const resendInvite = async (
    httpService: IHttpService,
    recordType: RecordType,
    recordId: number,
): Promise<boolean> => {
    let success;
    switch (recordType) {
        case RecordType.PATIENT:
            success = await httpService.healthResendInvite(recordId);
            break;
        case RecordType.PRACTITIONER:
            success = await httpService.practitionerResendInvite(recordId);
            break;
    }
    if (success && !resultIsError(success)) {
        mixpanel.track(
            `User resent an invite to a ${
                recordType === RecordType.PATIENT ? 'Health Patient' : recordType
            } who was added to the portal but did not create their account yet.`,
        );
    } else {
        mixpanel.track(
            `Invite Could Not Be Sent to ${
                recordType === RecordType.PATIENT ? 'Health Patient' : recordType
            } who was added to the portal but did not create their account yet.`,
        );
    }
    return !!success;
};
