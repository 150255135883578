import React from 'react';
import { Grid } from '@mui/material';
import { ExerciseGroupedSet } from '@/common/types';
import { SetInfoTable } from '../SetInfoTable/SetInfoTable';
import { ExerciseSetWrapper, ExerciseSetTitle } from './styles';

interface Props {
    exerciseSet: ExerciseGroupedSet;
}
export const ExerciseROMSet = (props: Props) => {
    const { exerciseSet } = props;

    const setInfoTables = exerciseSet.sets.map((set, i) => {
        return (
            <SetInfoTable
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                set={set}
                i={i}
                swapLeftRight={false}
            />
        );
    });

    return (
        <ExerciseSetWrapper>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <ExerciseSetTitle>{exerciseSet.sets[0]?.exercise?.min?.label}</ExerciseSetTitle>
                </Grid>
                <Grid item xs={4}>
                    <ExerciseSetTitle>{exerciseSet.sets[0]?.exercise?.max?.label}</ExerciseSetTitle>
                </Grid>
                <Grid item xs={4}>
                    <ExerciseSetTitle>{`Errors`}</ExerciseSetTitle>
                </Grid>
            </Grid>
            <>{setInfoTables}</>
        </ExerciseSetWrapper>
    );
};
