import React from 'react';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { GaitReconcile } from './GaitReconcile';

export const GaitReconcileContainer = () => {
    return (
        <>
            <PageTitle title="Reconcile Gait Patients" />
            <GaitReconcile />
        </>
    );
};
