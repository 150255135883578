import React from 'react';
import { GaitReconcileContainer } from '@/components/Gait/Reconcile/GaitReconcileContainer';
import { Breadcrumbs } from '../../layout/Breadcrumbs/Breadrumbs';
import { RouteContentWrapper } from '../styles';

export const GaitReconcileRoute = () => {
    return (
        <>
            <Breadcrumbs />
            <RouteContentWrapper>
                <GaitReconcileContainer />
            </RouteContentWrapper>
        </>
    );
};
