import React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    IconButton,
    ListItemSecondaryAction,
    Paper,
    Typography,
    Box,
} from '@mui/material';
import { useMuiUtils } from '@/common/hooks/MuiUtils';
import { EditIcon } from '../common/Icons/EditIcon';

type Client = {
    versionId: string;
    updateRequired: boolean;
};

type ClientListProps = {
    clients: Client[];
    onEdit: (index: number) => void;
};

export const ClientList: React.FC<ClientListProps> = ({ clients, onEdit }) => {
    const { palette } = useMuiUtils();
    return (
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Paper style={{ padding: 0, margin: 0, maxWidth: '600px', width: '100%' }}>
                <Typography variant="h6" gutterBottom style={{ padding: 16 }}>
                    Client List
                </Typography>
                <List>
                    {clients.map((client, index) => (
                        <ListItem key={client.versionId} divider={index !== clients.length - 1} alignItems="center">
                            <ListItemText
                                primary={
                                    <Box style={{ display: 'flex', flexDirection: 'row', columnGap: 16 }}>
                                        <Typography variant="h6">Version ID: </Typography>
                                        <Typography variant="h6" style={{ color: palette.primary[800] }}>
                                            {client.versionId}
                                        </Typography>
                                    </Box>
                                }
                                secondary={
                                    <Typography variant="h6" style={{ color: palette.grey[700] }}>
                                        Update Required:{` `}
                                        {client.updateRequired ? 'Yes' : 'No'}
                                    </Typography>
                                }
                            />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="edit" onClick={() => onEdit(index)}>
                                    <EditIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Box>
    );
};
