import React from 'react';
import { Typography } from '@mui/material';
import { LoginForm } from './LoginForm';

export const Login = () => {
    return (
        <>
            <Typography variant={'h5'} component="h1" fontWeight={600}>
                Sign In
            </Typography>
            <LoginForm />
        </>
    );
};
