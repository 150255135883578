import React from 'react';
import { Grid } from '@mui/material';
import { GaitChart } from '@/components/Gait/GaitPatient/Charts/GaitChart';
import { GaitChartRadioGroup } from '@/components/Gait/GaitPatient/Charts/GaitRadioGroup';
import { EmptyData } from '@/components/common/EmptyData/EmptyData';
import { TabContentGrid } from '@/components/common/Tabs/styles';
import { DateRangeAtomState } from '@/recoil/atoms/dateRangeAtom';
import { TabPanel } from './TabPanel';

interface ProgressTabProps {
    currentTab: number;
    dateRange: DateRangeAtomState;
    showCharts: any;
    handleChartSelection: ({ optionName, optionId }: { optionName: string; optionId: string }) => void;
    currentChart: any;
}

export const ProgressTab = (props: ProgressTabProps) => {
    const { currentTab, dateRange, showCharts, currentChart, handleChartSelection } = props;

    return (
        <TabPanel currentTab={currentTab} index={1} ariaLabel="Progress tab">
            {showCharts ? (
                <TabContentGrid container spacing={4}>
                    <Grid item xs={12} lg={4}>
                        <GaitChartRadioGroup
                            title="GAIT MEASUREMENTS"
                            handleChange={handleChartSelection}
                            selectedOption={currentChart}
                        />
                    </Grid>
                    <Grid item xs={12} lg={showCharts ? 8 : 12}>
                        {currentChart && <GaitChart currentChart={currentChart} timeSpan={dateRange} />}
                    </Grid>
                </TabContentGrid>
            ) : (
                <EmptyData
                    title="No patient data available"
                    body="Your patient has not done any activity in this date range"
                />
            )}
        </TabPanel>
    );
};
