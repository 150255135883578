import React from 'react';
import { ScanSessionsTableContainer } from '@/components/Scan/Sessions/ScanSessionsTableContainer';
import { PaginationProvider } from '@/components/context/PaginationContext';
import { RouteContentWrapper } from '../../styles';

export const ScanSessionsRoute = () => {
    return (
        <RouteContentWrapper>
            <PaginationProvider>
                <ScanSessionsTableContainer />
            </PaginationProvider>
        </RouteContentWrapper>
    );
};
