import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { ProviderRoles } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { Location, Practitioner, ROLES } from '@/common/types';
import { isAdmin } from '@/common/utils';
import { preventParentLinkClick } from '@/common/utils';
import { getLocation } from '@/common/utils';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { SendIcon } from '@/components/common/Icons/SendIcon';
import { TrashIcon } from '@/components/common/Icons/TrashIcon';
import { MobileLabel } from '@/components/common/styles';
import { useModalContext } from '@/components/context/hooks/ModalContext';
import { OrganizationRowWrapper } from '../styles';
import { DeletePractitionerDialogContent } from './DeletePractitionerDialogContent';
import { PractitionerEditForm } from './PractitionerEditForm';
import { ResendInviteDialogContent } from './ResendInviteDialogContent';
import {
    PractitionerRowName,
    PractitionerRowEmail,
    PractitionerRowRole,
    PractitionerRowEdit,
    PractitionerRowButton,
    PractitionerRowDeleteButton,
    PractitionerRowButtonWrapper,
    LocationBadge,
} from './styles';

interface Props {
    practitioner: Practitioner;
    locations?: Location[];
}

const LocationTooltipContent = ({ locations }: { locations: (string | undefined)[] }) => {
    return (
        <>
            {locations.map((l) => {
                return <div key={l}>{l}</div>;
            })}
        </>
    );
};

const LocationTooltip = ({ locations, locationIds }: { locations: Location[]; locationIds: number[] }) => {
    const selectedLocationNames = locationIds.map((l) => {
        const location = getLocation(locations, l);
        if (location) {
            return location.name;
        }
    });

    return (
        <Tooltip title={<LocationTooltipContent locations={selectedLocationNames} />} placement="top">
            <LocationBadge badgeContent={locationIds.length} color="primary">
                <span>Multiple locations</span>
            </LocationBadge>
        </Tooltip>
    );
};

export const PractitionerRow = (props: Props) => {
    const { practitioner, locations } = props;
    const { user } = useExerUser();
    const { setDialogOpen, setDialog } = useModalContext();
    const [isEditing, setIsEditing] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState<number[]>([]);

    useEffect(() => {
        if (practitioner.locations) {
            const selectedLocationIds = practitioner.locations.map((l) => {
                return l.id;
            });
            setSelectedLocations(selectedLocationIds);
        }
    }, [practitioner]);

    const handleEditClick = (_: React.MouseEvent) => {
        setIsEditing(true);
    };

    const handleCancelEditClick = (_: React.MouseEvent) => {
        setIsEditing(false);
    };

    const handleDeleteClick = (e: React.MouseEvent) => {
        preventParentLinkClick(e);
        setDialog(
            'Delete Provider',
            <DeletePractitionerDialogContent
                practitionerId={practitioner.id}
                practitionerName={`${practitioner.firstName} ${practitioner.lastName}`}
                handleCancelClick={handleCancelDeleteClick}
            />,
        );
        setDialogOpen(true);
    };

    const handleCancelDeleteClick = (_: React.MouseEvent) => {
        setDialogOpen(false);
    };

    const handleCancelInviteClick = (_: React.MouseEvent) => {
        setDialogOpen(false);
    };

    const onSubmitPractitionerRole = (email: string, locationIds: number[], role?: ROLES) => {
        practitioner.role = role;
        practitioner.email = email;
        setSelectedLocations(locationIds);
        setIsEditing(false);
    };

    const handleResendClick = (e: React.MouseEvent) => {
        preventParentLinkClick(e);
        setDialog(
            'Resend Invite',
            <ResendInviteDialogContent practitioner={practitioner} handleCancelClick={handleCancelInviteClick} />,
        );
        setDialogOpen(true);
    };

    const hasSignedUp = (practitioner: Practitioner): boolean => {
        return !!practitioner.userId;
    };

    const isCurrentUser = (practitioner: Practitioner): boolean => {
        return user.id === practitioner.userId;
    };

    return (
        <OrganizationRowWrapper container spacing={2}>
            <PractitionerRowName item xs={12} lg={3}>
                <MobileLabel sx={{ display: { lg: 'none', xs: 'inline' } }}>Name: </MobileLabel>
                <span>
                    <strong>
                        {practitioner.firstName} {practitioner.lastName}
                    </strong>
                </span>
            </PractitionerRowName>
            {isEditing ? (
                <PractitionerEditForm
                    practitioner={practitioner}
                    handleCancelClick={handleCancelEditClick}
                    onSubmit={onSubmitPractitionerRole}
                    locations={locations}
                    selectedLocations={selectedLocations}
                    setSelectedLocations={setSelectedLocations}
                />
            ) : (
                <>
                    <PractitionerRowEmail item xs={12} lg={4}>
                        <MobileLabel sx={{ display: { lg: 'none', xs: 'inline' } }}>Email: </MobileLabel>
                        <span>{practitioner.email}</span>
                    </PractitionerRowEmail>
                    <PractitionerRowRole item xs={12} lg={1.5}>
                        <>
                            <MobileLabel sx={{ display: { lg: 'none', xs: 'inline' } }}>Role: </MobileLabel>
                            {practitioner.role
                                ? ProviderRoles[practitioner.role]
                                : practitioner.addedAsRole
                                ? ProviderRoles[practitioner.addedAsRole]
                                : null}
                        </>
                    </PractitionerRowRole>
                    <PractitionerRowName item xs={12} lg={2.5}>
                        <MobileLabel sx={{ display: { lg: 'none', xs: 'inline' } }}>Location: </MobileLabel>
                        {locations && selectedLocations.length > 1 ? (
                            <LocationTooltip locationIds={selectedLocations} locations={locations} />
                        ) : null}
                        {locations && selectedLocations.length === 1 ? (
                            <span>{getLocation(locations, selectedLocations[0])?.name}</span>
                        ) : null}
                    </PractitionerRowName>
                </>
            )}
            {!isEditing && (
                <>
                    <PractitionerRowEdit item xs={12} lg={1}>
                        <PractitionerRowButtonWrapper>
                            {isAdmin(user) && hasSignedUp(practitioner) && !isCurrentUser(practitioner) && (
                                <PractitionerRowButton onClick={handleEditClick} size="small">
                                    <EditIcon />
                                </PractitionerRowButton>
                            )}
                            {isAdmin(user) && !hasSignedUp(practitioner) && (
                                <PractitionerRowButtonWrapper>
                                    <PractitionerRowButton onClick={handleEditClick} size="small">
                                        <EditIcon />
                                    </PractitionerRowButton>
                                    <Tooltip arrow title="Resend invite" placement="top">
                                        <PractitionerRowButton onClick={handleResendClick} size="small">
                                            <SendIcon />
                                        </PractitionerRowButton>
                                    </Tooltip>
                                </PractitionerRowButtonWrapper>
                            )}
                            {isAdmin(user) && (
                                <PractitionerRowDeleteButton onClick={handleDeleteClick} size="small">
                                    <TrashIcon />
                                </PractitionerRowDeleteButton>
                            )}
                        </PractitionerRowButtonWrapper>
                    </PractitionerRowEdit>
                </>
            )}
        </OrganizationRowWrapper>
    );
};
