import React from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Grid } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { SideOfBody } from '@/common/const';
import { HEPBlock, PatientRecord, isRepsGoal, isTimeGoal } from '@/common/types';
import { toHHMMSS } from '@/common/utils';
import { useExercisesContext } from '@/components/context/hooks/ExercisesContext';
import {
    HEPBlockDetailsContainer,
    HEPBlockDetails,
    HEPGridContainer,
    HEPBlockDragWrapper,
    HEPBlockDragContentWrapper,
    DragToolTip,
} from '../../HEPs/HEPBlock/styles';
import { HEPBlockActions } from '../HEPBlockActions/HEPBlockActions';
import { ExerciseAnimationLazy as ExerciseAnimation } from '../HEPBuilderExercises/ExerciseAnimationLazy';

interface Props {
    block: HEPBlock;
    index: number;
    isDraggable?: boolean;
    patientRecord?: PatientRecord | null;
}

export const BuiltHEPBlockContent = (props: Props) => {
    const { block, isDraggable, patientRecord } = props;
    const { exercisesDict } = useExercisesContext();

    if (!exercisesDict) return null;

    return (
        <>
            <HEPGridContainer container spacing={1}>
                <HEPBlockDragWrapper item xs={1}>
                    {isDraggable && (
                        <DragToolTip disableTouchListener title="Reorder the exercise" TransitionComponent={Zoom}>
                            <DragIndicatorIcon />
                        </DragToolTip>
                    )}
                </HEPBlockDragWrapper>
                <Grid item xs={9}>
                    <HEPBlockDragContentWrapper>
                        {exercisesDict?.[block.exerciseId]?.animationUrl ? (
                            <ExerciseAnimation
                                animationUrl={exercisesDict[block.exerciseId].animationUrl!}
                                exerciseMetricId={block.exerciseId}
                                activeFrame={exercisesDict[block.exerciseId].animationThumbnailFrame}
                                style={{
                                    minWidth: 60,
                                    width: 60,
                                    padding: 0.5,
                                }}
                            />
                        ) : (
                            <img src={exercisesDict?.[block.exerciseId]?.thumbnailUrl} alt="" width="60px" />
                        )}
                        <HEPBlockDetailsContainer>
                            <p>{exercisesDict?.[block.exerciseId]?.name}</p>
                            <HEPBlockDetails>
                                {block.setGoal && (
                                    <>
                                        {isRepsGoal(block.setGoal)
                                            ? block.setGoal.reps && `Repetitions: ${block.setGoal.reps} \u00a0 \u00a0`
                                            : null}
                                        {isTimeGoal(block.setGoal)
                                            ? `Time Goal: ${toHHMMSS(block.setGoal.time)} \u00a0 \u00a0`
                                            : null}
                                    </>
                                )}
                                {block.numSets && <>Sets: {block.numSets}</>}
                            </HEPBlockDetails>
                            {block.sideOfBody ? (
                                <HEPBlockDetails>
                                    <div>
                                        Side Of Body:{' '}
                                        {`${SideOfBody[block.sideOfBody]}${
                                            patientRecord?.productData?.health?.injuredSideOfBody &&
                                            patientRecord?.productData?.health?.injuredSideOfBody === block.sideOfBody
                                                ? ' (Involved)'
                                                : ''
                                        }`}
                                    </div>
                                </HEPBlockDetails>
                            ) : null}
                        </HEPBlockDetailsContainer>
                    </HEPBlockDragContentWrapper>
                </Grid>
                <Grid item xs={2}>
                    <HEPBlockActions {...props} />
                </Grid>
            </HEPGridContainer>
        </>
    );
};
