import React, { useState } from 'react';
import { SideOfBody } from '@/common/const';
import { Exercise, ExerciseMetricOmitExercise, isROMExerciseMetric, HEPBlock } from '@/common/types';
import { ExerciseAnimationLazy as ExerciseAnimation } from '../HEPBuilderExercises/ExerciseAnimationLazy';
import { useHEPBuilderContext } from '../state/hooks/HEPBuilderContext';
import { EditBlockMetric } from './EditBlockMetric';
import { EditBlockSide } from './EditBlockSide';
import {
    EditBlockContentHeader,
    BlockPropSelectionWrapper,
    BlockPropSelectionButton,
    BlockPropSelectionButtonGroup,
    EditBlockImg,
} from './styles';

interface Props {
    exercise: Exercise;
    onSubmit: (hepBlock: HEPBlock) => void;
    onCancel: () => void;
    initialBlock?: HEPBlock;
}

export const AddBlock = (props: Props) => {
    const { exercise } = props;
    const exerciseMetricsDict = exercise.exerciseMetrics.reduce((a, v) => ({ ...a, [v.id]: v }), {});
    const { patientRecord } = useHEPBuilderContext();
    const romEM = exercise.exerciseMetrics.find((em) => {
        return isROMExerciseMetric(em);
    });
    const [exerciseMetric, setExerciseMetric] = useState<ExerciseMetricOmitExercise>(
        romEM || exercise.exerciseMetrics[0],
    );
    const determineExerciseNeedsSide = () => {
        return exercise.subjectOrientation === 'SIDE' || !exercise.sequence || exercise.sequence === 'SINGLE';
    };
    const [sideOfBody, setSideOfBody] = useState<keyof typeof SideOfBody | undefined>(
        determineExerciseNeedsSide() && patientRecord?.productData?.health?.injuredSideOfBody
            ? (patientRecord.productData.health.injuredSideOfBody as keyof typeof SideOfBody)
            : undefined,
    );

    const handleExerciseMetricClick = (id: number) => {
        setExerciseMetric(exerciseMetricsDict[id]);
    };

    return (
        <>
            <EditBlockContentHeader>
                <div>
                    {exercise.animationUrl ? (
                        <ExerciseAnimation
                            animationUrl={exercise.animationUrl!}
                            exerciseMetricId={exercise.exerciseMetrics[0].id}
                            activeFrame={exercise.animationThumbnailFrame}
                            style={{
                                minWidth: 90,
                                width: 90,
                                padding: 0.5,
                            }}
                        />
                    ) : (
                        <EditBlockImg
                            src={
                                exercise.thumbnailUrl ||
                                'https://exer-exercise-media.s3.amazonaws.com/thumbnails/placeholder.jpg'
                            }
                        />
                    )}
                </div>
                <div>
                    <h4>{exercise.name}</h4>
                </div>
            </EditBlockContentHeader>
            <BlockPropSelectionWrapper>
                Select Metric:{' '}
                <BlockPropSelectionButtonGroup variant="contained" aria-label="Select Metric">
                    {exercise.exerciseMetrics.map((em) => {
                        return (
                            <BlockPropSelectionButton
                                key={em.id}
                                variant="primary"
                                size="small"
                                $isSelected={em.id === exerciseMetric.id}
                                onClick={() => {
                                    handleExerciseMetricClick(em.id);
                                }}
                            >
                                {em.metric}
                            </BlockPropSelectionButton>
                        );
                    })}
                </BlockPropSelectionButtonGroup>
            </BlockPropSelectionWrapper>
            <EditBlockSide
                patientRecord={patientRecord}
                sideOfBody={sideOfBody}
                setSideOfBody={setSideOfBody}
                exerciseNeedsSide={determineExerciseNeedsSide()}
                showToggle={exercise?.sequence === 'BILATERAL' || exercise?.sequence === 'ALTERNATING'}
            />
            {exerciseMetric ? (
                <EditBlockMetric exerciseMetric={exerciseMetric} sideOfBody={sideOfBody} {...props} />
            ) : null}
        </>
    );
};
