import * as React from 'react';
import { GaitSession } from '@/common/types';
import { SessionExpandingRow } from './SessionExpandingRow';

interface SessionExpandingRowContainerProps {
    session: GaitSession;
    key: string;
}

export const SessionExpandingRowContainer = (props: SessionExpandingRowContainerProps) => {
    const { session, key } = props;

    return <SessionExpandingRow session={session} key={key} />;
};
