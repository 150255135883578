import React, { ChangeEvent } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { Products } from '@/common/const';
import { getStringEnumKeyByValue } from '@/common/utils';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { ServerError } from '@/components/common/Form/styles';
import { ProductSelectionWrapper, ProductsCheckboxesFieldGroup, ProductSelectionContent } from './styles';

interface Props {
    products: string[];
    defaultProducts: string[];
    handleProductSelect: (e: ChangeEvent<HTMLInputElement>) => void;
    productsError?: string | null;
    confirmProductChoices: () => void;
    handleCancel: (e) => void;
}

export const ProductSelection = (props: Props) => {
    const { handleProductSelect, productsError, confirmProductChoices, handleCancel, products, defaultProducts } =
        props;

    return (
        <ProductSelectionWrapper>
            <ProductSelectionContent>
                {defaultProducts.includes(getStringEnumKeyByValue(Products, Products.HEALTH)) &&
                defaultProducts.includes(getStringEnumKeyByValue(Products, Products.PRO)) ? (
                    <p>These products are currently assigned to this patient.</p>
                ) : (
                    <p>Select the products to which you are adding the patient.</p>
                )}
                <ProductsCheckboxesFieldGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={Products.HEALTH}
                                checked={products.includes(getStringEnumKeyByValue(Products, Products.HEALTH))}
                                disabled={defaultProducts.includes(getStringEnumKeyByValue(Products, Products.HEALTH))}
                                onChange={handleProductSelect}
                            />
                        }
                        label={`Exer ${Products.HEALTH}`}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={Products.PRO}
                                checked={products.includes(getStringEnumKeyByValue(Products, Products.PRO)!)}
                                disabled={defaultProducts.includes(getStringEnumKeyByValue(Products, Products.PRO)!)}
                                onChange={handleProductSelect}
                            />
                        }
                        label={`Exer ${Products.PRO}`}
                    />
                </ProductsCheckboxesFieldGroup>
                <ServerError>{productsError || '\u00a0 '}</ServerError>
            </ProductSelectionContent>
            <SubmitCancelButtons
                submitFunction={confirmProductChoices}
                cancelFunction={handleCancel}
                submitText="Next"
            />
        </ProductSelectionWrapper>
    );
};
