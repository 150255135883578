import React from 'react';
import { SvgIcon } from '@mui/material';

export const LockIcon = (props) => {
    return (
        <>
            <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6V11H14V6C14 4.89543 13.1046 4 12 4C10.8954 4 10 4.89543 10 6V11H8V6Z"
                    fill="currentcolor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.7587 9H14.2413C15.0463 8.99999 15.7106 8.99998 16.2518 9.04419C16.8139 9.09012 17.3306 9.18868 17.816 9.43597C18.5686 9.81947 19.1805 10.4314 19.564 11.184C19.8113 11.6694 19.9099 12.1861 19.9558 12.7482C20 13.2894 20 13.9537 20 14.7587V16.2413C20 17.0463 20 17.7106 19.9558 18.2518C19.9099 18.8139 19.8113 19.3306 19.564 19.816C19.1805 20.5686 18.5686 21.1805 17.816 21.564C17.3306 21.8113 16.8139 21.9099 16.2518 21.9558C15.7106 22 15.0463 22 14.2413 22H9.75873C8.95374 22 8.28938 22 7.74818 21.9558C7.18608 21.9099 6.66937 21.8113 6.18404 21.564C5.43139 21.1805 4.81947 20.5686 4.43597 19.816C4.18868 19.3306 4.09012 18.8139 4.04419 18.2518C3.99998 17.7106 3.99999 17.0463 4 16.2413V14.7587C3.99999 13.9537 3.99998 13.2894 4.04419 12.7482C4.09012 12.1861 4.18868 11.6694 4.43597 11.184C4.81947 10.4314 5.43139 9.81947 6.18404 9.43597C6.66937 9.18868 7.18608 9.09012 7.74817 9.04419C8.28937 8.99998 8.95373 8.99999 9.7587 9ZM7.91104 11.0376C7.47262 11.0734 7.24842 11.1383 7.09202 11.218C6.7157 11.4097 6.40973 11.7157 6.21799 12.092C6.1383 12.2484 6.07337 12.4726 6.03755 12.911C6.00078 13.3611 6 13.9434 6 14.8V16.2C6 17.0566 6.00078 17.6389 6.03755 18.089C6.07337 18.5274 6.1383 18.7516 6.21799 18.908C6.40973 19.2843 6.7157 19.5903 7.09202 19.782C7.24842 19.8617 7.47262 19.9266 7.91104 19.9624C8.36113 19.9992 8.94342 20 9.8 20H14.2C15.0566 20 15.6389 19.9992 16.089 19.9624C16.5274 19.9266 16.7516 19.8617 16.908 19.782C17.2843 19.5903 17.5903 19.2843 17.782 18.908C17.8617 18.7516 17.9266 18.5274 17.9624 18.089C17.9992 17.6389 18 17.0566 18 16.2V14.8C18 13.9434 17.9992 13.3611 17.9624 12.911C17.9266 12.4726 17.8617 12.2484 17.782 12.092C17.5903 11.7157 17.2843 11.4097 16.908 11.218C16.7516 11.1383 16.5274 11.0734 16.089 11.0376C15.6389 11.0008 15.0566 11 14.2 11H9.8C8.94342 11 8.36113 11.0008 7.91104 11.0376Z"
                    fill="currentcolor"
                />
                <path
                    d="M11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V16Z"
                    fill="currentcolor"
                />
            </SvgIcon>
        </>
    );
};
