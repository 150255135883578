import { styled } from '@mui/material';

export const PatientHEPMenuContainerWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',

    '& > h3': {
        paddingRight: theme.spacing(4),
        cursor: 'pointer',
    },
    '& > h3 > a:hover': {
        textDecoration: 'underline',
    },
}));

export const PatientHEPLabel = styled('span')(({ theme }) => ({
    color: theme.palette.grey[500],
}));
