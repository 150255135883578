import React from 'react';
import { Practitioners } from '../../Organization/Practitioners/Practitioners';
import { RouteContentWrapper } from '../styles';

export const PractitionersRoute = () => {
    return (
        <RouteContentWrapper>
            <Practitioners />
        </RouteContentWrapper>
    );
};
