import React from 'react';
import { ScanReportsContainer } from '@/components/Scan/Reports/ScanReportsContainer';
import { RouteContentWrapper } from '../styles';

export const ScanReportsRoute = () => {
    return (
        <>
            <RouteContentWrapper>
                <ScanReportsContainer />
            </RouteContentWrapper>
        </>
    );
};
