import { styled, Typography, Accordion, AccordionSummary, AccordionDetails, MenuItem } from '@mui/material';

export const TemplateAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    marginBottom: theme.spacing(7),
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: `0 ${theme.spacing(2)}`,
    '&&': {
        borderRadius: theme.shape.borderRadius,
    },
}));

export const TemplateAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    borderRadius: 0,
    padding: 0,
    fontSize: theme.typography.fontSize,
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightMedium,
    '.MuiAccordionSummary-expandIconWrapper': {
        fontSize: theme.typography.h4.fontSize,
    },
}));

export const TemplateAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    maxHeight: theme.spacing(33.75),
    overflowY: 'scroll',
    padding: 0,
}));

export const TemplateMenuItem = styled(MenuItem)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    transition: `.4s ease background-color`,
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(0)}`,
    boxShadow: '0px 2px 5px rgba(16, 5, 51, 0.04)',
    '&:last-of-type': {
        marginBottom: theme.spacing(2),
    },

    '&:hover': {
        backgroundColor: theme.palette.primary[200],
    },
}));

export const TemplateMenuItemTitle = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.fontSize,
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'center',
    paddingRight: theme.spacing(2),
}));

export const TemplateMenuItemExercises = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[900],
    justifyContent: 'flex-end',

    '.MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
        fontSize: theme.typography.fontSize,
    },
}));
