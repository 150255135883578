import React from 'react';
import { SvgIcon } from '@mui/material';

export const ArrowUpIcon = (props) => {
    return (
        <>
            <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" style={{ fill: 'none' }}>
                <path
                    d="M16 27V5"
                    stroke="currentcolor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7 14L16 5L25 14"
                    stroke="currentcolor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </SvgIcon>
        </>
    );
};
