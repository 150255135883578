import { Box, styled, Accordion } from '@mui/material';

export const StepExercisesWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
    },
}));

export const HEPBuilderExercisesSidebar = styled('div')(({ theme }) => ({
    flex: '0 0 200px',
    display: 'none',
    [theme.breakpoints.up('lg')]: {
        display: 'block',
    },
}));

export const HEPBuilderExercisesFilterWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

export const HEPBuilderExercisesContent = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        flexDirection: 'row',
    },
}));

export const HEPBuilderExercisesContentLeft = styled('div')(({ theme }) => ({
    flex: '1 1 auto',
    width: '100%',
    [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(4),
    },
}));

export const HEPBuilderExercisesContentRight = styled('div')(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('lg')]: {
        flex: '0 0 360px',
        display: 'block',
    },
}));

export const HEPBuilderExercisesMobile = styled(Accordion)(({ theme }) => ({
    display: 'block',
    [theme.breakpoints.up('lg')]: {
        flex: '0 0 300px',
        display: 'none',
    },
    '&.MuiAccordion-root:before': {
        display: 'none',
    },
}));

export const BuiltHEPSidebar = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: theme.spacing(3),
    '& h3': {
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
    },
}));

export const BuiltHEPSidebarContent = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('lg')]: {
        boxShadow: '0px 2px 5px rgba(16, 5, 51, 0.04)',
    },
    '& > h3': {
        padding: '0',
    },

    '& h5': {
        fontSize: 11,
        textTransform: 'uppercase',
        marginBottom: theme.spacing(1),
        color: theme.palette.grey[500],
    },

    '& > p': {
        margin: 0,
    },
}));

export const BuiltHEPSidebarScroll = styled('div')(({ theme }) => ({
    paddingBottom: 240,
    maxHeight: '100vh',
    overflow: 'scroll',
}));

export const HEPBuilderSearchWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

export const ActiveFiltersWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
        display: 'none',
    },
}));

export const HEPNotesWrapper = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    h5: {
        margin: '0',
        span: {
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.grey[600],
            textTransform: 'uppercase',
        },
    },
    p: {
        color: theme.palette.grey[600],
        margin: '0',
    },
}));

export const HEPNotes = styled('p')(({ theme }) => ({
    wordBreak: 'break-word',
}));

export const EditActionsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
}));
