import { selector } from 'recoil';
import { GaitSessionVersion } from '@/common/const';
import { GaitSession } from '@/common/types';
import { getStartAndEndOfDateInMillis } from '@/common/utils';
import { getGaitChartData } from '@/components/Gait/GaitPatient/Charts/utils';
import { GaitSessionsState } from '@/recoil/atoms/gaitSessionsAtom';
import { DateRangeSelector } from '@/recoil/selectors/dateRangeSelector';

export const GaitSessionsSelector = selector({
    key: 'filteredGaitSessions',
    get: ({ get }) => {
        const gaitSessions = get(GaitSessionsState);
        const { startDateMillis, endDateMillis } = get(DateRangeSelector);

        const filterSessionsByDateRange = () => {
            const sessionData: GaitSession[] = [];

            gaitSessions.map((assessment) => {
                const processedDate = getStartAndEndOfDateInMillis(assessment.session.meta.capturedDate);

                // If my session date is in range between the start and end calendar dates, add it to the sessionData
                if (processedDate.startDate >= startDateMillis && processedDate.endDate <= endDateMillis) {
                    sessionData.push(assessment);
                }
            });

            return sessionData;
        };

        const gaitSessionsFilteredByDateRange = filterSessionsByDateRange();

        const checkIfVersion1Exists = gaitSessionsFilteredByDateRange.find((session) => {
            return session.sessionSchema === GaitSessionVersion.GAIT_SA_1;
        });
        const checkIfVersion2Exists = gaitSessionsFilteredByDateRange.find((session) => {
            return session.sessionSchema === GaitSessionVersion.GAIT_SA_2;
        });

        const version1Exists = checkIfVersion1Exists !== undefined;
        const version2Exists = checkIfVersion2Exists !== undefined;

        const showCharts =
            gaitSessionsFilteredByDateRange && gaitSessionsFilteredByDateRange.length > 0 && version2Exists;

        const gaitChartsData = getGaitChartData(gaitSessionsFilteredByDateRange);

        return { gaitSessionsFilteredByDateRange, version1Exists, gaitChartsData, showCharts };
    },
});
