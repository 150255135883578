import React, { ChangeEvent } from 'react';
import { PatientRecord } from '@/common/types';
import { a11yProps } from '@/common/utils';
import { PatientFocusAction } from '@/components/Patients/Patient/PatientDetailsActions/PatientFocusAction';
import { Tabs, Tab } from '@/components/common/Tabs/styles';
import { TabLoadingText } from '../styles';

interface TabControlsProps {
    currentTab: number;
    handleTabChange: (event: ChangeEvent<HTMLButtonElement>, newValue: number) => void;
    patientRecord: PatientRecord;
    isLoadingPROMessages: boolean;
    isInModal: boolean;
}

export const TabControls = (props: TabControlsProps) => {
    const { currentTab, handleTabChange, patientRecord, isLoadingPROMessages, isInModal } = props;

    return (
        <>
            <div style={{ display: 'flex' }}>
                <Tabs value={currentTab} onChange={handleTabChange} aria-label="Patient Data" style={{ width: '100%' }}>
                    <Tab label="Activity" {...a11yProps('activity')} />
                    <Tab label="Range of motion" {...a11yProps('rom charts')} />
                    {!!patientRecord.patient.phoneNumber ? (
                        <Tab
                            label={<TabLoadingText $isLoading={isLoadingPROMessages}>Surveys</TabLoadingText>}
                            disabled={isLoadingPROMessages}
                            {...a11yProps('pro data')}
                        />
                    ) : null}
                    <Tab label="Care Management" {...a11yProps('Care Management')} />
                </Tabs>
                {!isInModal ? (
                    <div
                        style={{
                            backgroundColor: '#fff',
                            boxShadow: 'rgb(230, 232, 240) 0px -2px 0px 0px inset',
                        }}
                    >
                        <PatientFocusAction />
                    </div>
                ) : null}
            </div>
        </>
    );
};
