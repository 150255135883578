import React from 'react';
import { SvgIcon } from '@mui/material';

export const DragIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M7.1875 5.64062C7.7139 5.64062 8.14062 5.2139 8.14062 4.6875C8.14062 4.1611 7.7139 3.73438 7.1875 3.73438C6.6611 3.73438 6.23438 4.1611 6.23438 4.6875C6.23438 5.2139 6.6611 5.64062 7.1875 5.64062Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path
                d="M12.5 5.64062C13.0264 5.64062 13.4531 5.2139 13.4531 4.6875C13.4531 4.1611 13.0264 3.73438 12.5 3.73438C11.9736 3.73438 11.5469 4.1611 11.5469 4.6875C11.5469 5.2139 11.9736 5.64062 12.5 5.64062Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path
                d="M7.1875 10.9531C7.7139 10.9531 8.14062 10.5264 8.14062 10C8.14062 9.4736 7.7139 9.04688 7.1875 9.04688C6.6611 9.04688 6.23438 9.4736 6.23438 10C6.23438 10.5264 6.6611 10.9531 7.1875 10.9531Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path
                d="M12.5 10.9531C13.0264 10.9531 13.4531 10.5264 13.4531 10C13.4531 9.4736 13.0264 9.04688 12.5 9.04688C11.9736 9.04688 11.5469 9.4736 11.5469 10C11.5469 10.5264 11.9736 10.9531 12.5 10.9531Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path
                d="M7.1875 16.2656C7.7139 16.2656 8.14062 15.8389 8.14062 15.3125C8.14062 14.7861 7.7139 14.3594 7.1875 14.3594C6.6611 14.3594 6.23438 14.7861 6.23438 15.3125C6.23438 15.8389 6.6611 16.2656 7.1875 16.2656Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
            <path
                d="M12.5 16.2656C13.0264 16.2656 13.4531 15.8389 13.4531 15.3125C13.4531 14.7861 13.0264 14.3594 12.5 14.3594C11.9736 14.3594 11.5469 14.7861 11.5469 15.3125C11.5469 15.8389 11.9736 16.2656 12.5 16.2656Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.5"
            />
        </SvgIcon>
    );
};
