import React from 'react';
import { useParams } from 'react-router-dom';
import { AdminScanOrganizationModulesContainer } from '@/components/Organization/AdminOrganization/AdminOrganizationScanModulesContainer';
import { RouteContentWrapper } from '../../styles';

export const AdminScanOrganizationModulesRoute = () => {
    const { organizationId } = useParams<{ organizationId: string }>();

    return (
        <RouteContentWrapper>
            <AdminScanOrganizationModulesContainer organizationId={parseInt(organizationId!)} />
        </RouteContentWrapper>
    );
};
