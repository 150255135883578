import React, { useState, useEffect } from 'react';
import { DialogActions, TextField, MenuItem, Box, Typography } from '@mui/material';
import { Nullable, Assessment, SortableScanModule } from '@/common/types';
import { Button } from '@/components/common/button/button';
import { Dialog, DialogContent, DialogTitle } from '../Modules/styles';

export const AddAssessmentModal = ({
    open,
    onClose,
    assessments,
    onSave,
    module,
}: {
    open: boolean;
    onClose: () => void;
    assessments: Assessment[];
    onSave: (updatedModule: SortableScanModule) => void;
    module: Nullable<SortableScanModule>;
}) => {
    const [selectedAssessment, setSelectedAssessment] = useState<Nullable<Assessment>>(null);
    const [assessmentNameOverride, setAssessmentNameOverride] = useState<Nullable<string>>(null);

    useEffect(() => {
        setSelectedAssessment(null);
        setAssessmentNameOverride(null);
    }, [module]);

    const handleClose = () => {
        setSelectedAssessment(null);
        setAssessmentNameOverride(null);
        onClose();
    };

    const handleAssessmentSelect = (event) => {
        const selectedAssessment = assessments.find((a) => a.id === event.target.value);
        if (selectedAssessment) {
            setSelectedAssessment(selectedAssessment);
        }
    };

    const handleAssessmentNameOverrideChange = (event) => {
        if (event.target.value.trim().length) {
            setAssessmentNameOverride(event.target.value);
        } else {
            setAssessmentNameOverride(null);
        }
    };

    const handleSave = () => {
        if (selectedAssessment && module) {
            const updatedModule = {
                ...module,
                assessments: [
                    ...module.assessments,
                    {
                        ...selectedAssessment,
                        nameOverride: assessmentNameOverride,
                    },
                ],
            };
            onSave(updatedModule);
            handleClose();
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add Assessment</DialogTitle>
            <DialogContent>
                <Box
                    style={{
                        paddingTop: 24,
                    }}
                >
                    <TextField
                        select
                        label="Assessment"
                        fullWidth
                        value={selectedAssessment ? selectedAssessment.id : ''}
                        onChange={handleAssessmentSelect}
                    >
                        {assessments.map((assessment) => {
                            const isAssessmentAdded = module?.assessments.some(
                                (moduleAssessment) => moduleAssessment.id === assessment.id,
                            );
                            return (
                                <MenuItem key={assessment.id} value={assessment.id} disabled={isAssessmentAdded}>
                                    {assessment.id} - {assessment.name}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                    {selectedAssessment && (
                        <Box
                            style={{
                                marginTop: 16,
                            }}
                        >
                            <TextField
                                margin="dense"
                                label="Assessment Name Override"
                                type="text"
                                fullWidth
                                value={assessmentNameOverride}
                                onChange={handleAssessmentNameOverrideChange}
                            />
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    background: '#fff',
                                    marginTop: '16px',
                                    border: '1px solid lightgrey',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    padding: 8,
                                }}
                            >
                                <img
                                    src={selectedAssessment.thumbnailUrl}
                                    width={100}
                                    height={100}
                                    alt={selectedAssessment.name}
                                />
                                <Box
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography variant="h6">{selectedAssessment.name}</Typography>
                                    <Typography variant="caption">({selectedAssessment.subjectOrientation})</Typography>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="tertiary">
                    Cancel
                </Button>
                <Button onClick={handleSave} variant="primary" disabled={!selectedAssessment}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
