import * as React from 'react';
import { SVGProps } from 'react';

export const ArrowDownIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="423.16 347.6 353.68 504.82" width="16" height="16" {...props}>
        <path
            fill="currentcolor"
            d="m618.14 355.15c-5.0391-5.0352-11.086-7.5547-18.141-7.5547-7.0508 0-13.098 2.5195-18.137 7.5547-5.0352 5.0391-7.5547 11.086-7.5547 18.141v391.46l-107.31-107.31c-5.0391-5.0391-11.082-7.5586-18.137-7.5586s-13.098 2.5195-18.137 7.5586c-5.0391 5.0352-7.5586 11.082-7.5586 18.137 0 7.0508 2.5195 13.098 7.5586 18.137l151.14 151.14c5.0391 5.0391 11.086 7.5586 18.137 7.5586 7.0547 0 13.102-2.5195 18.141-7.5586l151.14-151.14c5.0391-5.0391 7.5586-11.086 7.5586-18.137 0-7.0547-2.5195-13.102-7.5586-18.137-5.0391-5.0391-11.082-7.5586-18.137-7.5586-7.0547 0-13.098 2.5195-18.137 7.5586l-107.31 107.31v-391.46c0-7.0547-2.5195-13.102-7.5547-18.141z"
        />
    </svg>
);
