import React, { useCallback, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { useExerUser } from '@/common/hooks/ExerUser';
import { GaitSessionReport } from '@/common/types';
import { SessionsReportDownloadButton } from '@/components/Health/PatientSessions/SessionsReport/styles';
import { GaitReportDownloadButtonWrapper } from './styles';

interface DownloadGaitReportProps {
    sessions: GaitSessionReport[];
    startEnd: Date;
}

export const DownloadGaitReport = (props: DownloadGaitReportProps) => {
    const { sessions, startEnd } = props;
    const [generating, setGenerating] = useState(true);
    const { user } = useExerUser();

    const hasData = (rows: any[]): boolean => {
        return rows.some((row) => row.length > 0);
    };

    const handleCsvRef = useCallback(
        async (node: HTMLAnchorElement) => {
            setGenerating(true);
            if (node && sessions) {
                const header: string[][] = [
                    ['First Name', 'Last Name', 'Gait Speed (m/s)', `Date/Time (${user.organizationTimezone})`],
                ];
                const rows = header.concat(
                    sessions.map((session) => {
                        return [
                            session.subject.firstName,
                            session.subject.lastName,
                            session.session.data[0].measures[0].value.toFixed(2),
                            `"${DateTime.fromISO(session.session.meta.capturedDate)
                                .setZone(user.organizationTimezone)
                                .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}"`,
                        ];
                    }),
                );

                if (hasData(rows)) {
                    let csvContent = '';
                    if (hasData(rows)) {
                        csvContent += rows.map((e) => e.join(',')).join('\n');
                    }

                    const blob = new Blob(['\ufeff', csvContent]);
                    const url = URL.createObjectURL(blob);
                    node.download = `${DateTime.fromJSDate(new Date(startEnd)).toFormat('MM-dd-yyyy')}.csv`;
                    node.href = url;
                }
            }
            setGenerating(false);
        },
        [sessions, startEnd],
    );

    return (
        <Grid container>
            <Grid item>
                <GaitReportDownloadButtonWrapper>
                    <SessionsReportDownloadButton ref={handleCsvRef} $isActive={true} onClick={() => null}>
                        {sessions.length > 0 && (
                            <LoadingButton loading={generating} variant="primary" disabled={generating}>
                                Download CSV
                            </LoadingButton>
                        )}
                    </SessionsReportDownloadButton>
                </GaitReportDownloadButtonWrapper>
            </Grid>
        </Grid>
    );
};
