import { styled } from '@mui/material';

export const ExerciseSetWrapper = styled('div')(({ theme }) => ({
    '& h3': {
        fontSize: '14px',
        marginBottom: theme.spacing(1),
    },
}));

export const ExerciseSetTitle = styled('span')(({ theme }) => ({
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'uppercase',
}));

export const ExerciseTitle = styled('h4')(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
}));
