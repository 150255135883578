import React from 'react';
import { SvgIcon } from '@mui/material';

export const IndustrialBuildingIcon = (props) => {
    return (
        <>
            <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    d="M7.69133 6.9375V5.0625C7.69133 4.75313 7.9337 4.5 8.22993 4.5H10.0252C10.3215 4.5 10.5638 4.75313 10.5638 5.0625V6.9375C10.5638 7.24687 10.3215 7.5 10.0252 7.5H8.22993C7.9337 7.5 7.69133 7.24687 7.69133 6.9375ZM13.975 7.5H15.7703C16.0665 7.5 16.3089 7.24687 16.3089 6.9375V5.0625C16.3089 4.75313 16.0665 4.5 15.7703 4.5H13.975C13.6787 4.5 13.4364 4.75313 13.4364 5.0625V6.9375C13.4364 7.24687 13.6787 7.5 13.975 7.5ZM8.22993 12H10.0252C10.3215 12 10.5638 11.7469 10.5638 11.4375V9.5625C10.5638 9.25313 10.3215 9 10.0252 9H8.22993C7.9337 9 7.69133 9.25313 7.69133 9.5625V11.4375C7.69133 11.7469 7.9337 12 8.22993 12ZM13.975 12H15.7703C16.0665 12 16.3089 11.7469 16.3089 11.4375V9.5625C16.3089 9.25313 16.0665 9 15.7703 9H13.975C13.6787 9 13.4364 9.25313 13.4364 9.5625V11.4375C13.4364 11.7469 13.6787 12 13.975 12ZM10.5638 15.9375V14.0625C10.5638 13.7531 10.3215 13.5 10.0252 13.5H8.22993C7.9337 13.5 7.69133 13.7531 7.69133 14.0625V15.9375C7.69133 16.2469 7.9337 16.5 8.22993 16.5H10.0252C10.3215 16.5 10.5638 16.2469 10.5638 15.9375ZM13.975 16.5H15.7703C16.0665 16.5 16.3089 16.2469 16.3089 15.9375V14.0625C16.3089 13.7531 16.0665 13.5 15.7703 13.5H13.975C13.6787 13.5 13.4364 13.7531 13.4364 14.0625V15.9375C13.4364 16.2469 13.6787 16.5 13.975 16.5ZM22.0539 22.3125V24H1.94629V22.3125C1.94629 22.0031 2.18866 21.75 2.48489 21.75H3.36011V1.125C3.36011 0.501562 3.84036 0 4.4373 0H19.5629C20.1599 0 20.6401 0.501562 20.6401 1.125V21.75H21.5153C21.8116 21.75 22.0539 22.0031 22.0539 22.3125ZM5.5145 21.7031H10.5638V18.5625C10.5638 18.2531 10.8062 18 11.1024 18H12.8978C13.194 18 13.4364 18.2531 13.4364 18.5625V21.7031H18.4857V2.29688L5.53694 2.25L5.5145 21.7031Z"
                    fill="currentColor"
                />
            </SvgIcon>
        </>
    );
};
