import { toast } from 'react-toastify';
import { AlertCircleIcon } from '@/components/common/Icons/AlertCircleIcon';
import { AlertIcon } from '@/components/common/Icons/AlertIcon';
import { CheckMarkIcon } from '@/components/common/Icons/CheckMarkIcon';
import { InfoIcon } from '@/components/common/Icons/InfoIcon';

export const toasts = {
    successToast: (message: string) =>
        toast.success(message, {
            position: 'top-right',
            autoClose: 4000,
            icon: CheckMarkIcon,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            draggable: true,
            pauseOnHover: true,
            hideProgressBar: true,
        }),
    errorToast: (message: string) =>
        toast.error(message, {
            position: 'top-right',
            autoClose: 4000,
            icon: AlertCircleIcon,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            draggable: true,
            pauseOnHover: true,
            hideProgressBar: true,
        }),
    warningToast: (message: string) =>
        toast.warning(message, {
            position: 'top-right',
            autoClose: 4000,
            icon: AlertIcon,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            draggable: true,
            pauseOnHover: true,
            hideProgressBar: true,
        }),
    infoToast: (message: string) =>
        toast.info(message, {
            position: 'top-right',
            autoClose: 4000,
            icon: InfoIcon,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            draggable: true,
            pauseOnHover: true,
            hideProgressBar: true,
        }),
};
