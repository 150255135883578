export const config =
    process.env.ENVIRONMENT === 'PRODUCTION'
        ? {
              Auth: {
                  region: 'us-east-1',
                  userPoolId: 'us-east-1_M5F4gmdk7',
                  userPoolWebClientId: '74bodf0gdn5nmpeefvt9rlkmds',
                  authenticationFlowType: 'USER_PASSWORD_AUTH',
              },
          }
        : {
              Auth: {
                  region: 'us-east-1',
                  userPoolId: 'us-east-1_M5F4gmdk7',
                  userPoolWebClientId: '57qflgn9aedpgcjusi2gi4je63',
                  authenticationFlowType: 'USER_PASSWORD_AUTH',
              },
          };
