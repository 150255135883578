import React from 'react';
import { useRecoilState } from 'recoil';
import { Products } from '@/common/const';
import { getPatientProductKeys } from '@/common/utils';
import { MultiSelectFilter } from '@/components/common/Table/Filters/MultiSelectFilter';
import { usePaginationContext } from '@/components/context/hooks/PaginationContext';
import { globalFilterState } from '@/recoil/atoms/globalSearchAtom';

export const PATIENT_PRODUCT_FILTER_ID = 'products';

export const PatientProductFilter = () => {
    const products = getPatientProductKeys();
    const [globalFilters, setGlobalFilters] = useRecoilState(globalFilterState);
    const { page, setPage } = usePaginationContext();

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (page !== 1) {
            setPage(1);
        }
        const currentActiveProducts = [...globalFilters.products];
        if (event.target.checked) {
            setGlobalFilters({
                ...globalFilters,
                products: [...currentActiveProducts, event.target.name],
            });
        } else {
            const index = currentActiveProducts.indexOf(event.target.name);
            if (index > -1) {
                currentActiveProducts.splice(index, 1);
            }
            setGlobalFilters({
                ...globalFilters,
                products: currentActiveProducts,
            });
        }
    };

    const handleClearSelection = () => {
        if (page !== 1) {
            setPage(1);
        }
        setGlobalFilters({
            ...globalFilters,
            products: [],
        });
    };

    return (
        <MultiSelectFilter
            selected={globalFilters.products.map((key) => {
                return key.toString();
            })}
            options={products.map((product) => {
                return {
                    id: product,
                    name: Products[product],
                };
            })}
            handleRadioChange={handleRadioChange}
            handleClearSelection={handleClearSelection}
            label="Products"
            prefix="products-"
        />
    );
};
