import React, { SyntheticEvent, useState } from 'react';
import { Location } from '@/common/types';
import { a11yProps } from '@/common/utils';
import { filterProvidersByLocationId } from '@/common/utils';
import { PatientTableTabs, PatientTableTab } from '@/components/PatientBilling/PatientsBillingTable/styles';
import { usePractitionersContext } from '../Practitioners/context/hooks/PractitionerContext';
import { OrganizationListWrapper } from '../styles';
import { EmptyLocations } from './EmptyLocations';
import { EmptyUserLocations } from './EmptyUserLocations';
import { LocationRow } from './LocationRow';
import { LocationsListHeader } from './LocationsListHeader';
import { LocationListTabsContainer } from './styles';

interface Props {
    locations?: Location[];
    userLocations?: Location[];
    showTabs: boolean;
}

export const LocationsList = (props: Props) => {
    const { locations, userLocations, showTabs } = props;
    const [currentTab, setCurrentTab] = useState(0);
    const { practitioners } = usePractitionersContext();

    const locationArray = currentTab === 0 ? locations : userLocations;
    const empty = currentTab === 0 ? <EmptyLocations /> : <EmptyUserLocations />;
    const locationRows =
        locationArray &&
        locationArray.map((location) => {
            const filteredPractitioners = filterProvidersByLocationId(location.id!, practitioners);
            return <LocationRow key={location.id} location={location} practitioners={filteredPractitioners} />;
        });

    const handleTabChange = (_: SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    return (
        <>
            <LocationListTabsContainer>
                {showTabs && (
                    <PatientTableTabs value={currentTab} onChange={handleTabChange}>
                        <PatientTableTab label="All Locations" {...a11yProps(0)} />
                        <PatientTableTab label="My Locations" {...a11yProps(1)} />
                    </PatientTableTabs>
                )}
            </LocationListTabsContainer>
            <OrganizationListWrapper>
                <LocationsListHeader />
                <>{locationRows ? (locationRows.length > 0 ? locationRows : empty) : null}</>
            </OrganizationListWrapper>
        </>
    );
};
