import { Grid, styled } from '@mui/material';
import { Button } from '@/components/common/button/button';

export const OrganizationWrapper = styled('div')({
    position: 'relative',
});

export const OrganizationListWrapper = styled('div')(({ theme }) => ({
    overflow: 'auto',
    backgroundColor: theme.palette.common.white,
}));

export const OrganizationRowWrapper = styled(Grid)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    marginLeft: '0',
    width: '100%',
    marginTop: '0',
    '&:last-of-type': {
        borderBottom: 'none',
    },
    '> .MuiGrid-item': {
        paddingTop: 10,
        height: 'auto',
        minHeight: 52,
    },
}));

export const OrganizationAddEntityButton = styled(Button)({});

export const OrganizationEmptyEntitiesWrapper = styled('div')(({ theme }) => ({
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    position: 'relative',

    '& h3': {
        fontWeight: 'normal',
    },
}));

export const LocationsAddLocation = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
}));
