import React from 'react';
import { PatientProductBadges } from '@/components/Patients/ProductBadges/ProductBadges';
import { PatientStatusBadge } from '../PatientStatusBadge/PatientStatusBadge';
import { PatientTableCellEllipsis } from '../styles';

export const HealthColumns = [
    {
        Header: 'Email', // Health only
        accessor: 'EMAIL',
        Cell: ({ value, row }) => <PatientTableCellEllipsis>{value}</PatientTableCellEllipsis>,
    },
    {
        Header: 'Program Program',
        accessor: 'program',
        Cell: ({ value, row }) => <div>{value}</div>,
    },
    {
        Header: 'Last Activity',
        accessor: 'LAST_ACTIVITY',
        Cell: ({ value, row }) => <div>{value}</div>,
    },
    {
        Header: 'Status',
        accessor: 'STATUS',
        Cell: ({ value, row }) => <PatientStatusBadge status={value} />,
    },
];

export const GaitColumns = [
    {
        Header: 'Location', // Gait only
        accessor: 'LOCATION',
        Cell: ({ value, row }) => <div>{value}</div>,
    },
    {
        Header: 'Last Activity',
        accessor: 'LAST_ACTIVITY',
        Cell: ({ value, row }) => <div>{value}</div>,
    },
];

export const HealthAndGaitColumns = [
    {
        Header: 'Product',
        accessor: 'product',
        width: 100,
        Cell: ({ value, row }) => {
            return <PatientProductBadges products={value.products} />;
        },
    },
    {
        Header: 'Program Program',
        accessor: 'program',
        width: 120,
        Cell: ({ value, row }) => <div>{value}</div>,
    },
    {
        Header: 'Last Activity',
        accessor: 'LAST_ACTIVITY',
        width: 100,
        Cell: ({ value, row }) => <div>{value}</div>,
    },
    {
        Header: 'Status',
        accessor: 'STATUS',
        width: 120,
        Cell: ({ value, row }) => <PatientStatusBadge status={value} />,
    },
];
