import { addDays } from 'date-fns';
import { atom } from 'recoil';

export interface DateRangeAtomState {
    startDate: Date;
    endDate: Date;
    rangeRadioOptions: {
        seven: boolean;
        thirty: boolean;
        ninety: boolean;
    };
}

export const DateRangeState = atom<DateRangeAtomState>({
    key: 'dateRangeState',
    default: {
        startDate: addDays(new Date(), -30),
        endDate: new Date(),
        rangeRadioOptions: {
            seven: false,
            thirty: true,
            ninety: false,
        },
    },
});
