import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { Login } from './Login';

export const LoginContainer = () => {
    const { user } = useExerUser();
    const navigate = useNavigate();

    useEffect(() => {
        user.isAuthenticated && navigate(`${Paths.dashboard}`, { replace: true });
    }, []);

    return <Login />;
};
