import React from 'react';
import { IconButton, SvgIcon, useTheme, Tooltip } from '@mui/material';
import { useRecoilState } from 'recoil';
import { sidebarNavState } from '@/recoil/atoms/sidebarNavAtom';

export const NavbarCollapseControls = () => {
    const [sidebarNav, setSidebarNav] = useRecoilState(sidebarNavState);
    const { isOpen } = sidebarNav;
    const theme = useTheme();

    const handleDrawerOpen = () => {
        setSidebarNav({ isOpen: !isOpen });
    };

    return (
        <Tooltip title={`${isOpen ? 'Collapse' : 'Expand'} sidebar`} placement="right">
            <IconButton
                size="medium"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                    marginRight: '14px',
                    marginBottom: '14px',
                    color: theme.palette.grey[400],
                }}
            >
                <SvgIcon viewBox="222.14 261 755.72 678" width="20" height="20" fill="rgb(147, 149, 158)">
                    <path
                        style={{
                            transition: '.6s ease transform',
                            transform: `${!isOpen ? 'translateX(90%)' : 'none'}`,
                        }}
                        fill="currentcolor"
                        d="M251.46 261.23c-16.184 0-29.316 13.129-29.316 29.316v618.9c0 16.188 13.133 29.316 29.316 29.316 16.188 0 29.316-13.129 29.316-29.316v-618.9a29.218 29.218 0 0 0-7.965-20.094c-5.344-5.672-12.93-9.223-21.352-9.223z"
                    />
                    <path
                        style={{
                            transition: '.6s ease transform',
                            transform: `${!isOpen ? 'matrix(-1,0,0,1,0,0) translate(-160%, 0)' : 'none'}`,
                        }}
                        fill="currentcolor"
                        d="M478.96 558.7h469.32c16.336 0 29.57 12.891 29.57 28.82 0 15.918-13.234 28.809-29.57 28.809H478.96l146 143.56c10.992 11.367 10.688 29.242-.738 40.238-11.375 11.008-29.676 11.145-41.254.316l-195.9-192.7c-11.426-11.234-11.426-29.328 0-40.55l195.9-192.7a29.573 29.573 0 0 1 11.656-7.407 30.448 30.448 0 0 1 9.34-1.629c7.992-.14 15.676 2.875 21.324 8.336a28.45 28.45 0 0 1 8.753 20.715 28.105 28.105 0 0 1-2.265 10.852 28.918 28.918 0 0 1-6.817 9.684z"
                    />
                </SvgIcon>
            </IconButton>
        </Tooltip>
    );
};
