import { styled } from '@mui/material';

export const ResendInviteWrapper = styled('div')(({ theme }) => ({
    '& .MuiButtonBase-root': {
        padding: '0',
        backgroundColor: 'transparent',
        color: theme.palette.grey[500],
        fontSize: theme.typography.body1.fontSize,
        marginRight: theme.spacing(0.5),
    },
}));

export const ResendInviteText = styled('span')(({}) => ({
    cursor: 'pointer',
}));

export const ResendInviteModalContent = styled('div')(({ theme }) => ({
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
}));
