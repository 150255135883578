import { styled, TextField, Grid } from '@mui/material';

export const PatientsTableWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

export const ListControlsContainer = styled(Grid)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flexWrap: 'wrap',
    alignItems: 'bottom',
    marginTop: theme.spacing(2),
    gap: theme.spacing(1),

    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
    },

    [theme.breakpoints.up('md')]: {
        gap: theme.spacing(4),
        justifyContent: 'flex-start',
        alignItems: 'center',
    },

    [theme.breakpoints.up('chromebook')]: {
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
    },
}));

export const NameTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    background: 'white',
    borderRadius: theme.shape.borderRadius,
    fontWeight: 500,

    [theme.breakpoints.up('md')]: {},

    [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(0),
    },
}));

export const NameTextFieldContainer = styled('div')(({ theme }) => ({
    color: theme.palette.grey[500],
    width: '100%',
    minWidth: 200,
    position: 'relative',

    [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(0),
    },

    [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(0),
    },

    '& .MuiSvgIcon-root': {
        top: 9,
    },
}));
