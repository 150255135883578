import React from 'react';
import { GaitEventPatientsContainer } from '@/components/Gait/Events/PatientsContainer';
import { RouteContentWrapper } from '../styles';

export const GaitEventPatientsRoute = () => {
    return (
        <>
            <RouteContentWrapper>
                <GaitEventPatientsContainer />
            </RouteContentWrapper>
        </>
    );
};
