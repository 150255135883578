import React from 'react';
import { StyleSheet, Text, View, Document, Page, Image } from '@react-pdf/renderer';
import { ExerPDFHeader } from '@/common/pdf/ExerPDFHeader';
import { AssessmentsDict, Maybe } from '@/common/types';
import { getAssessmentName } from '@/common/utils';
import { ScanReportPDFProps, ScanReportResultData } from '../types';
import { assessmentReportPDFStyles } from './styles';

interface ScanAssessmentReportPDFDocumentProps {
    assessmentEntries: [string, Map<string, ScanReportResultData>][];
    chartImageMap: Map<string, string>;
    scanReportPDFProps: ScanReportPDFProps;
    assessmentsDict: Maybe<AssessmentsDict>;
}

export const ScanAssessmentReportPDFDocument = (props: ScanAssessmentReportPDFDocumentProps) => {
    const { assessmentEntries, chartImageMap, scanReportPDFProps, assessmentsDict } = props;
    const { title, locationName, organizationLogoPrintUrl } = scanReportPDFProps;

    const pdfStyles = StyleSheet.create(assessmentReportPDFStyles);

    return (
        <Document>
            <Page size="A4" style={pdfStyles.page}>
                <ExerPDFHeader organizationLogoPrintUrl={organizationLogoPrintUrl} />
                <View style={pdfStyles.pageTitle}>
                    <Text>{title}</Text>
                </View>
                <View style={pdfStyles.pageSubTitle}>
                    <Text>
                        <Text style={{ fontWeight: 600 }}>Location: </Text>
                        {locationName}
                    </Text>
                </View>
                {assessmentEntries.map((assessmentEntry, i) => {
                    const assessmentId = assessmentEntry[0];
                    const resultMap = assessmentEntry[1];
                    return (
                        <View key={assessmentId} break={i > 0}>
                            <View style={pdfStyles.assessmentHeader}>
                                <Text>{getAssessmentName(assessmentId, assessmentsDict)}</Text>
                            </View>
                            {[...resultMap.entries()].map((resultEntry) => {
                                const resultName = resultEntry[0];
                                const imgUrl = chartImageMap.get(`${assessmentId}-${resultName}`);
                                return (
                                    <View key={`${assessmentId}-${resultName}`} wrap={false}>
                                        <Text style={pdfStyles.resultHeader}>{resultName}</Text>
                                        {imgUrl && <Image src={imgUrl} />}
                                    </View>
                                );
                            })}
                        </View>
                    );
                })}
            </Page>
        </Document>
    );
};
