import React from 'react';
import { Grid } from '@mui/material';
import { LocationsListHeaderWrapper } from './styles';

export const LocationsListHeader = () => {
    return (
        <LocationsListHeaderWrapper container spacing={2}>
            <Grid item xs={2}>
                <strong>Location Name</strong>
            </Grid>
            <Grid item xs={4}>
                <strong>Address</strong>
            </Grid>
            <Grid item xs={2}>
                <strong>Default Provider</strong>
            </Grid>
            <Grid item xs={2}>
                <strong>Timezone</strong>
            </Grid>
            <Grid item xs={4} />
        </LocationsListHeaderWrapper>
    );
};
