import { atom } from 'recoil';

export interface patientHEPDrawerHEPDict {
    drawerIsActive: boolean;
}

export const patientHEPDrawerState = atom<patientHEPDrawerHEPDict>({
    key: 'patientHEPDrawer',
    default: {
        drawerIsActive: false,
    },
});
