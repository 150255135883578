import { SideOfBody } from '@/common/const';
import { RawExerciseSetInfo, Session } from '@/common/types';
import { getStartAndEndOfDateInMillis } from '@/common/utils';
import { ROMDataDict, ROMData } from './types';

export enum AGGREGATION {
    ALLOW_MULTIPLE_PER_DAY = 'ALLOW_MULTIPLE_PER_DAY',
    SINGLE_MAX_RECORD_OF_DAY = 'SINGLE_MAX_RECORD_OF_DAY',
}

const pushToDataSet = (romData: ROMData, date: Date, set: RawExerciseSetInfo) => {
    romData.chart.labels.push(date);
    romData.chart.datasets[0].data.push(set.setInfo.minROM!);
    romData.chart.datasets[1].data.push(set.setInfo.maxROM!);
};

export const getROMChartData = (
    sessions: Session[],
    aggregation: AGGREGATION = AGGREGATION.ALLOW_MULTIPLE_PER_DAY,
): ROMDataDict => {
    const romDict: ROMDataDict = {};
    sessions.forEach((s: Session, index: number) => {
        s.session.sets.forEach((set) => {
            if (set.setInfo.maxROM !== undefined && set.setInfo.skipped !== true) {
                const id = set.setInfo.sideOfBody
                    ? `${set.exercise.id}-${set.setInfo.sideOfBody}`
                    : `${set.exercise.id}`;
                const romData = romDict[id] || {
                    exerciseId: id,
                    exerciseName: set.setInfo.sideOfBody
                        ? `${set.exercise.name} - ${SideOfBody[set.setInfo.sideOfBody]}`
                        : `${set.exercise.name}`,
                    exerciseDate: new Date(set.setInfo.completedDate),
                    chart: {
                        labels: [],
                        datasets: [
                            {
                                label: set.exercise?.min?.label,
                                data: [],
                                borderColor: '#fab531',
                                pointHoverBackgroundColor: '#fab531',
                                pointBackgroundColor: '#fff',
                                backgroundColor: '#fab531',
                                borderWidth: 1.5,
                                pointBorderWidth: 2,
                                pointRadius: 4,
                                pointHoverRadius: 6,
                            },
                            {
                                label: set.exercise?.max?.label,
                                data: [],
                                borderColor: '#3197f5',
                                pointHoverBackgroundColor: '#3197f5',
                                pointBackgroundColor: '#fff',
                                backgroundColor: '#3197f5',
                                borderWidth: 1.5,
                                pointBorderWidth: 2,
                                pointRadius: 4,
                                pointHoverRadius: 6,
                            },
                        ],
                    },
                };
                const date = new Date(set.setInfo.completedDate);
                switch (aggregation) {
                    case AGGREGATION.ALLOW_MULTIPLE_PER_DAY:
                        pushToDataSet(romData, date, set);
                        break;

                    case AGGREGATION.SINGLE_MAX_RECORD_OF_DAY:
                        // TODO Only needed because we're storing Dates as labels, redo once moved
                        let matchingDate = false;
                        romData.chart.labels.forEach((labelDate) => {
                            const ld = new Date(labelDate).setHours(0, 0, 0, 0);
                            const dd = new Date(date).setHours(0, 0, 0, 0);
                            if (ld === dd) {
                                matchingDate = true;
                            }
                        });
                        if (matchingDate) {
                            const existingRecordIndex = romData.chart.labels.findIndex((label) => {
                                return label === date;
                            });
                            const existingROM = romData.chart.datasets[1].data[existingRecordIndex];
                            if (set.setInfo.maxROM > existingROM) {
                                romData.chart.datasets[0].data.splice(existingRecordIndex, 1, set.setInfo.minROM!);
                                romData.chart.datasets[1].data.splice(existingRecordIndex, 1, set.setInfo.maxROM);
                            }
                        } else {
                            pushToDataSet(romData, date, set);
                        }
                        break;
                }
                romDict[id] = romData;
            }
        });
    });

    const romDictByDate = Object.entries(romDict)
        .sort((a, b) => b[1].exerciseDate.getTime() - a[1].exerciseDate.getTime())
        .reduce((prev, [_, rom], index) => {
            return { ...prev, [index]: rom };
        }, {});

    return romDictByDate;
};

export const filterROMDict = (
    romDictData: ROMDataDict,
    startDateMillis: number,
    endDateMillis: number,
): ROMDataDict => {
    const filteredROMs = {};
    Object.entries(romDictData).forEach(([_, rom], index) => {
        Object.values(rom.chart.labels).map((setDate) => {
            const processedDate = getStartAndEndOfDateInMillis(setDate);
            if (processedDate.startDate >= startDateMillis && processedDate.endDate <= endDateMillis) {
                filteredROMs[index] = rom;
            }
        });
    });
    return filteredROMs;
};
