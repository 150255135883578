import React, { FC, ReactNode } from 'react';
import { Maybe } from '@/common/types';
import { SliderArrowDownIcon } from '@/components/common/Icons/SliderArrowDownIcon';
import { FactorCategory } from '../types/FactorCategory.enum';
import { OutcomeFactor } from '../types/types';
import {
    OutcomeSliderWrapper,
    OutcomeSliderFactors,
    OutcomeSliderSlot,
    OutcomeSliderFactor,
    OutcomeSliderFactorDescription,
} from './styles';

interface OutcomeSliderProps {
    outcomeSliderData: {
        factors: Maybe<OutcomeFactor[]>;
        factorResultCategory: Maybe<FactorCategory>;
    };
}

export const OutcomeSlider: FC<OutcomeSliderProps> = ({ outcomeSliderData }) => {
    const { factors, factorResultCategory } = outcomeSliderData;
    const OutcomeSliderSlots: ReactNode[] = [];
    const OutcomeSliderFactorTargets: ReactNode[] = [];
    const OutcomeSliderFactorDescriptions: ReactNode[] = [];

    factors?.forEach((factor) => {
        OutcomeSliderSlots.push(
            <OutcomeSliderSlot $factorCategory={factor.category} $isActive={factorResultCategory === factor.category}>
                <SliderArrowDownIcon fontSize="inherit" />
            </OutcomeSliderSlot>,
        );
        OutcomeSliderFactorTargets.push(<OutcomeSliderFactor $factorCategory={factor.category}> </OutcomeSliderFactor>);
        OutcomeSliderFactorDescriptions.push(
            <OutcomeSliderFactorDescription $factorCount={factors.length}>
                {factor.description}
            </OutcomeSliderFactorDescription>,
        );
    });

    return (
        <OutcomeSliderWrapper>
            <OutcomeSliderFactors>{OutcomeSliderSlots}</OutcomeSliderFactors>
            <OutcomeSliderFactors>{OutcomeSliderFactorTargets}</OutcomeSliderFactors>
            <OutcomeSliderFactors>{OutcomeSliderFactorDescriptions}</OutcomeSliderFactors>
        </OutcomeSliderWrapper>
    );
};
