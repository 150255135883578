import React, { useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import InsightsIcon from '@mui/icons-material/Insights';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CardContent, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, MenuList } from '@mui/material';
import { useRecoilState } from 'recoil';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { SlideUpTransition } from '@/components/common/Transitions/SlideTransition';
import { Button } from '@/components/common/button/button';
import { PathsState, EditingPathsState } from '@/recoil/atoms/pathsAtom';
import { Phase, EndPhase } from '../Phase/Phase';
import { PathPhase } from '../Phase/types';
import {
    Dialog,
    DialogContent,
    DialogTitleContainer,
    DialogTitle,
    CardActions,
    Card,
    CardHeader,
    DialogFooter,
} from '../styles';

export const Path = ({ title, phases, id }: { title: string; phases: PathPhase[]; id: number }) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const [isEditing, setIsEditing] = useState(false);
    const [paths, setPaths] = useRecoilState(PathsState);
    const [editPaths, setEditPaths] = useRecoilState(EditingPathsState);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const openModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setIsEditing(false);
    };

    const handleEditPath = () => {
        setIsEditing(true);
        openModal();
        closeMenu();
    };

    const handleSubmit = () => {
        setPaths(editPaths);
        handleCloseModal();
    };

    const handleCancel = () => {
        handleCloseModal();
        setEditPaths(paths);
    };

    return (
        <>
            <Card variant="table" elevation={4} sx={{ width: '100%' }}>
                <CardHeader
                    title={title}
                    action={
                        <>
                            <IconButton
                                id="basic-button"
                                aria-controls={menuOpen ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={menuOpen ? 'true' : undefined}
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </>
                    }
                />
                <CardContent>
                    {phases &&
                        phases.map((phase: PathPhase, i) => {
                            const percentage_per_item = 100 / phases.length;
                            const cumulative_percentage = percentage_per_item * i;

                            if (i <= 2) {
                                return (
                                    <>
                                        <Phase
                                            key={phase.title}
                                            title={phase.title}
                                            exerciseTotal={phase.exerciseTotal}
                                            messageTotal={phase.messageTotal}
                                            phaseNumber={phase.phaseNumber}
                                            phase={phase}
                                            percent={cumulative_percentage.toFixed(2)}
                                            total={phases.length}
                                            isLast={i === 2}
                                        />
                                        {phases.length > 2 && i === phases.length - 1 ? (
                                            <div style={{ height: 28, overflow: 'hidden', marginTop: '-8px' }}>
                                                <EndPhase phaseNumber={`${phases.length + 1}`} showText={false} />
                                            </div>
                                        ) : null}
                                        {i === phases.length - 1 && i < 2 ? (
                                            <EndPhase phaseNumber={`${phases.length + 1}`} showText />
                                        ) : null}
                                    </>
                                );
                            } else return null;
                        })}
                </CardContent>
                {phases.length > 2 && (
                    <CardActions disableSpacing>
                        <Button onClick={openModal} variant="text" style={{ fontSize: 20 }}>
                            More
                        </Button>
                    </CardActions>
                )}
            </Card>
            <Menu id="basic-menu" anchorEl={anchorEl} open={menuOpen} onClose={closeMenu}>
                <MenuList>
                    <MenuItem onClick={handleEditPath}>
                        <ListItemIcon>
                            <EditRoundedIcon />
                        </ListItemIcon>
                        <ListItemText>Edit Path</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={closeMenu}>
                        <ListItemIcon>
                            <InsightsIcon />
                        </ListItemIcon>
                        <ListItemText>View Path Insights</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
            <Dialog
                open={open}
                TransitionComponent={SlideUpTransition}
                keepMounted
                onClose={handleCloseModal}
                fullWidth
            >
                <DialogTitleContainer>
                    <DialogTitle>{title}</DialogTitle>
                    <IconButton onClick={handleCloseModal}>
                        <CloseRoundedIcon />
                    </IconButton>
                </DialogTitleContainer>
                <DialogContent>
                    {phases &&
                        phases.map((phase: PathPhase, i) => {
                            const percentage_per_item = 100 / phases.length;
                            const cumulative_percentage = percentage_per_item * i;
                            return (
                                <>
                                    <Phase
                                        key={phase.title}
                                        title={phase.title}
                                        exerciseTotal={phase.exerciseTotal}
                                        messageTotal={phase.messageTotal}
                                        phaseNumber={phase.phaseNumber}
                                        phase={phase}
                                        percent={cumulative_percentage.toFixed(2)}
                                        total={phases.length}
                                        isEditing={isEditing}
                                    />
                                    {i === phases.length - 1 ? (
                                        <EndPhase phaseNumber={`${phases.length + 1}`} showText />
                                    ) : null}
                                </>
                            );
                        })}
                    {isEditing ? (
                        <DialogFooter>
                            <SubmitCancelButtons
                                submitFunction={handleSubmit}
                                cancelFunction={handleCancel}
                                submitText="Save"
                            />
                        </DialogFooter>
                    ) : null}
                </DialogContent>
            </Dialog>
        </>
    );
};
