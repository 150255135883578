import React from 'react';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { GaitReports } from './GaitReports';

export const GaitReportsContainer = () => {
    return (
        <>
            <PageTitle title="Gait Reports" />
            <GaitReports />
        </>
    );
};
