import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Radio, useTheme, useMediaQuery } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useRecoilValue } from 'recoil';
import { ControlLabel } from '@/components/common/ControlLabel/ControlLabel';
import { GaitSessionsSelector } from '@/recoil/selectors/gaitSessionsSelector';
import { RadioGroup, AccordionSummary, AccordionTitle } from './styles';

interface GaitChartRadioGroupProps {
    readonly title: string;
    readonly handleChange: (options: { optionId: string; optionName: string }) => void;
    readonly selectedOption?: string;
}

export const GaitChartRadioGroup = ({ title, handleChange, selectedOption }: GaitChartRadioGroupProps) => {
    const { gaitChartsData } = useRecoilValue(GaitSessionsSelector);
    const theme = useTheme();
    const largeBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));

    const handleOptionChange = ({ optionName, optionId }: { optionName: string; optionId: string }) => {
        handleChange({ optionId: optionId, optionName: optionName });
    };

    const AccordionItems = () => {
        return (
            <>
                {Object.values(gaitChartsData).map((chart, key) => {
                    return (
                        <ControlLabel
                            key={chart.id}
                            value={chart.id}
                            label={chart.name}
                            onChange={() => {
                                handleOptionChange({ optionName: chart.name, optionId: chart.id });
                            }}
                            control={<Radio />}
                        />
                    );
                })}
            </>
        );
    };

    return (
        <>
            {largeBreakpoint ? (
                <>
                    <AccordionTitle variant="caption">{title}</AccordionTitle>
                    <RadioGroup name={`${title} chart`} defaultValue={selectedOption} value={selectedOption}>
                        <AccordionItems />
                    </RadioGroup>
                </>
            ) : (
                <>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${title}-content`}
                            id={`${title}-header`}
                        >
                            <AccordionTitle variant="caption">{title}</AccordionTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <RadioGroup name={`${title} chart`} defaultValue={selectedOption} value={selectedOption}>
                                <AccordionItems />
                            </RadioGroup>
                        </AccordionDetails>
                    </Accordion>
                </>
            )}
        </>
    );
};
