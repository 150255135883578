import React from 'react';
import { Button } from '@mui/material';
import { useExerUser } from '@/common/hooks/ExerUser';
import { UpdateOrganizationDto } from '@/common/types';
import { isAdmin } from '@/common/utils';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { useModalContext } from '@/components/context/hooks/ModalContext';
import { OrganizationEditDialogContent } from './OrganizationEditDialogContent';

interface OrganizationEditActionProps {
    onSuccess: (res: UpdateOrganizationDto) => void;
}

export const OrganizationEditAction = (props: OrganizationEditActionProps) => {
    const { onSuccess } = props;
    const { setDialogOpen, setDialog } = useModalContext();
    const { user } = useExerUser();

    const handleEdit = (e: React.MouseEvent) => {
        e.preventDefault();
        setDialog(
            'Edit Organization Details',
            <OrganizationEditDialogContent
                handleCancel={() => {
                    setDialogOpen(false);
                }}
                onSuccess={(res: UpdateOrganizationDto) => {
                    setDialogOpen(false);
                    onSuccess(res);
                }}
            />,
        );
        setDialogOpen(true);
    };

    return user && isAdmin(user) ? (
        <Button
            variant="text"
            size="small"
            startIcon={<EditIcon style={{ fontSize: 16 }} />}
            onClick={handleEdit}
            style={{ padding: '0 4px', fontSize: 14, marginLeft: 4 }}
        >
            Edit
        </Button>
    ) : null;
};
