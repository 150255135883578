import React, { useEffect } from 'react';
import { useTable, useFilters, useGlobalFilter, useSortBy, Column } from 'react-table';
import { GaitSessionVersion } from '@/common/const';
import { GaitSessionTableRow, GaitSessionVersionSchema } from '@/common/types';
import { ColumnSortIcon, TableHeaderCell, ColumnSortedIcon, FullWidthTable } from '@/components/common/Table/styles';
import { GaitSessionTableCell, PatientGaitSessionTableWrapper, GaitTableWrapper } from '../GaitPatient/styles';

interface GaitPatientSessionTableProps {
    columns: Column[];
    data: GaitSessionTableRow[];
    version: GaitSessionVersionSchema;
    showMaxColumn: boolean;
}

export const GaitPatientSessionTable = (props: GaitPatientSessionTableProps) => {
    const { columns, data, version, showMaxColumn } = props;

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setHiddenColumns } = useTable(
        {
            columns,
            data,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
    );

    const nonSorted: string[] = [];
    const hiddenColumns =
        version === GaitSessionVersion.GAIT_SA_1 ? ['percentDifferenceOfAverage'] : showMaxColumn ? [] : ['maximum'];
    useEffect(() => {
        setHiddenColumns(hiddenColumns);
    }, [version, showMaxColumn, data]);

    return (
        <PatientGaitSessionTableWrapper>
            <GaitTableWrapper>
                <FullWidthTable {...getTableProps()} data-cy="patient-gait-session-table">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                {headerGroup.headers.map((column) => {
                                    const headerProps = !nonSorted.includes(column.id)
                                        ? { ...column.getHeaderProps(column.getSortByToggleProps()) }
                                        : { ...column.getHeaderProps() };
                                    return (
                                        <TableHeaderCell
                                            {...headerProps}
                                            key={column.id}
                                            $isSorted={column.isSorted}
                                            style={{
                                                width: column.width,
                                                maxWidth: column.width,
                                                minWidth: column.width,
                                            }}
                                        >
                                            {column.render('Header')}
                                            {!column.disableSortBy &&
                                                (column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <ColumnSortedIcon dir="DESC" />
                                                    ) : (
                                                        <ColumnSortedIcon dir="ASC" />
                                                    )
                                                ) : (
                                                    <ColumnSortIcon />
                                                ))}
                                        </TableHeaderCell>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={row.id}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <GaitSessionTableCell
                                                {...cell.getCellProps()}
                                                key={cell.column.id}
                                                style={{
                                                    width: cell.column.width,
                                                    maxWidth: cell.column.width,
                                                    minWidth: cell.column.width,
                                                }}
                                            >
                                                {cell.render('Cell')}
                                            </GaitSessionTableCell>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </FullWidthTable>
            </GaitTableWrapper>
        </PatientGaitSessionTableWrapper>
    );
};
