import { Clear, Search } from '@mui/icons-material';
import { Box, CircularProgress, styled, Tabs, Typography } from '@mui/material';
import { Modal } from '@/components/common/Modal/Modal';

export const ClearFilter = styled(Clear)({
    position: 'absolute',
    top: '9px',
    right: '20px',

    '&&&': {
        fontSize: 20,
    },
});

export const SearchFilter = styled(Search)({
    top: '8px',
    right: '20px',
    position: 'absolute',

    '&&&': {
        fontSize: 20,
    },
});

export const AddNewPatientModal = styled(Modal)({
    '& .MuiDialogContent-root': {
        paddingTop: '0px',
    },
});

export const PatientWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
}));

export const AddPatientModalContentWrapper = styled('div')({});

export const AddPatientTabs = styled(Tabs)({});

export const UploadCsvWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
}));

export const UploadCsvColumn = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '75%',

    '& button': {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[400]}`,
        width: '100%',
    },
}));

export const UploadProgressWrapper = styled('div')({
    textAlign: 'center',
});

export const UploadProgress = styled(CircularProgress)({});

export const BulkAddError = styled('div')(({ theme }) => ({
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    textAlign: 'center',
}));

export const BulkAddSuccess = styled('div')(({ theme }) => ({
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(1),
    textAlign: 'center',
}));

export const CsvTemplateContainer = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(2),
    textAlign: 'center',

    '& a ': {
        color: theme.palette.secondary.main,
    },
}));

export const EmptyPatientsWrapper = styled('div')(({ theme }) => ({
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    position: 'relative',

    '& h3': {
        fontWeight: 'normal',
    },
}));

export const NoPatientsWrapper = styled('div')(({ theme }) => ({
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    background: theme.palette.common.white,
}));

export const PatientHeaderLeft = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

export const PatientSubHeaderLeft = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

export const PatientInfoTitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    [theme.breakpoints.down('chromebook')]: {
        '.MuiTypography-root + .MuiTypography-root': {
            marginBottom: 16,
        },
    },
}));

export const PatientInvolvedTitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
}));

export const PatientInfoTitle = styled(Typography)<{ $isEmail?: boolean }>(({ theme, $isEmail }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(0.5),
    paddingRight: $isEmail ? theme.spacing(1) : 0,
    color: theme.palette.grey[700],
    textTransform: $isEmail ? 'none' : 'capitalize',
    overflowWrap: $isEmail ? 'anywhere' : 'normal',

    '> strong': {
        color: theme.palette.grey[800],
    },

    '> span': {
        whiteSpace: 'nowrap',
    },

    [theme.breakpoints.down('chromebook')]: {
        marginBottom: 8,
    },

    margin: `0 ${theme.spacing(4)} 0 ${theme.spacing(0.5)}`,
}));
