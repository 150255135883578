import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { TimeSpan } from '@/common/types';
import { groupByGaitSessionDate, isTodayString } from '@/common/utils';
import { PatientSessionDateTitle, PatientSessionSectionWrapper } from '@/components/Health/PatientSessions/styles';
import { EmptyData } from '@/components/common/EmptyData/EmptyData';
import { GaitPatientSessionsList } from './GaitPatientSessionsList';

interface Props {
    sessions: any;
    timeSpan: TimeSpan;
    inModal?: boolean;
}

export const GaitPatientSessionsListContainer = (props: Props) => {
    const { sessions, timeSpan, inModal = false } = props;
    const sessionsByDate = sessions && groupByGaitSessionDate(sessions);
    const [sessionSectionsData, setSessionSectionData] = useState<string[]>([]);

    const getGaitSessionsData = () => {
        const sessionData: string[] = [];
        sessionsByDate &&
            Object.keys(sessionsByDate).map((dateString) => {
                sessionData.push(dateString);
            });
        return sessionData;
    };

    useEffect(() => {
        const sessionsData = getGaitSessionsData();
        setSessionSectionData(sessionsData);
    }, [timeSpan, sessions]);

    return (
        <>
            {sessionSectionsData.length > 0 ? (
                <>
                    {sessionSectionsData.map((dateString, i) => {
                        const formattedDate = DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_FULL);
                        return (
                            <PatientSessionSectionWrapper
                                data-cy="patient-health-session"
                                key={dateString}
                                $inModal={inModal}
                            >
                                <PatientSessionDateTitle
                                    variant="caption"
                                    fontWeight="400"
                                    data-cy="patient-gait-session-date"
                                >
                                    {isTodayString(formattedDate)}
                                    {formattedDate}
                                </PatientSessionDateTitle>
                                <GaitPatientSessionsList sessions={sessionsByDate[dateString]} />
                            </PatientSessionSectionWrapper>
                        );
                    })}
                </>
            ) : (
                <EmptyData title="No Gait activity for this user" />
            )}
        </>
    );
};
