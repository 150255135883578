import React, { useState, useEffect } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Zoom from '@mui/material/Zoom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { mixpanel } from '@/common/mixpanel';
import { GaitSession } from '@/common/types';
import { CopyIcon } from '../Icons/CopyIcon';
import { CopyDiv, SuccessToolTip, CopyButton, CopyTextWrapper } from './style';

interface Props {
    sessionData: GaitSession;
}

export const CopyRow = (props: Props) => {
    const { sessionData } = props;
    const [showMessage, setShowMessage] = useState(false);
    const [copyText, setCopyText] = useState('');

    const returnWithDecimals = (value, unit) => {
        const decimalPlace = unit === '°' ? 1 : 2;
        return value > 0 ? value.toFixed(decimalPlace) : 0;
    };
    const generateText = () => {
        let text = `Exer Gait Screening Results`;
        Object.keys(sessionData.session.data).map((s) => {
            Object.keys(sessionData.session.data[s].measures).map((m) => {
                const excludedMeasure = /(avgTime)|(^hipFlexionAngle$)|(lapOneTime)|(lapTwoTime)/.test(
                    sessionData.session.data[s].measures[m].name,
                );
                if (excludedMeasure) return;
                text += `\n${sessionData.session.data[s].measures[m].displayName}: ${returnWithDecimals(
                    sessionData.session.data[s].measures[m].value,
                    sessionData.session.data[s].measures[m].unit,
                )}${sessionData.session.data[s].measures[m].unit}`;
            });
        });
        if (sessionData.session.meta.comment) text += `\nComment: ${sessionData.session.meta.comment}`;
        return text;
    };

    const handleTooltipClose = () => {
        setShowMessage(false);
    };

    const handleOpen = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 1500);
        mixpanel.track('Copied Gait Data');
    };

    useEffect(() => {
        setCopyText(generateText());
    }, [sessionData]);

    return (
        <CopyDiv>
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <SuccessToolTip
                    open={showMessage}
                    onClose={handleTooltipClose}
                    PopperProps={{
                        disablePortal: true,
                    }}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Copied!"
                    TransitionComponent={Zoom}
                >
                    <CopyButton size="small" onClick={handleOpen}>
                        <CopyToClipboard text={copyText}>
                            <CopyTextWrapper>
                                <CopyIcon sx={{ fill: 'none' }} />
                                Copy Data
                            </CopyTextWrapper>
                        </CopyToClipboard>
                    </CopyButton>
                </SuccessToolTip>
            </ClickAwayListener>
        </CopyDiv>
    );
};
