import React, { forwardRef } from 'react';
import { Slide, SlideProps } from '@mui/material';

export const SlideDownTransition = forwardRef<unknown, SlideProps>((props, ref) => (
    <Slide direction="down" ref={ref} {...props} />
));

export const SlideUpTransition = forwardRef<unknown, SlideProps>((props, ref) => (
    <Slide direction="up" ref={ref} {...props} />
));

SlideDownTransition.displayName = 'Slide Down Transition';
SlideUpTransition.displayName = 'Slide Up Transition';
