import { styled } from '@mui/material';

export const ResendInviteActionWrapper = styled('div')(({ theme }) => ({
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightMedium,

    'div,div:hover': {
        color: theme.palette.grey[600],
        fontWeight: theme.typography.fontWeightMedium,
        '.MuiButtonBase-root': {
            color: theme.palette.grey[500],
        },
    },
}));
