import { styled, Theme, Tooltip, IconButton } from '@mui/material';

export const AlertCellDiv = styled('div')<{ theme: Theme; $thresholdWarning?: boolean }>(
    ({ theme, $thresholdWarning }) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        position: 'relative',
        color: $thresholdWarning ? theme.palette.error[500] : 'inherit',
    }),
);

export const SuccessToolTip = styled(Tooltip)(({ theme }) => ({
    '&.MuiTooltip-popper': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary[500],
    },
}));

export const CopyButton = styled(IconButton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.grey[600],
    fontSize: 'inherit',
    '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
        fontSize: theme.typography.h5.fontSize,
    },
}));

export const CopyDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '& .MuiTooltip-tooltip': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary[500],
    },
}));

export const CopyTextWrapper = styled('span')(({ theme }) => ({
    display: 'flex',
}));
