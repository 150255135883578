import React, { useEffect } from 'react';
import { HttpProvider, UserProvider } from '@exerai/react-core';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { ErrorBoundary } from '@sentry/react';
import { Amplify, Auth } from 'aws-amplify';
import { RecoilRoot, useRecoilSnapshot } from 'recoil';
import { config } from '@/common/amplify';
import { ProfileProperites } from '@/common/types';
import { refreshUserProfile } from '@/common/utils';
import { ErrorHandler } from '@/components/common/ErrorBoundary/ErrorHandler';
import { ExercisesProvider } from '@/context/ExercisesContext';
import { ModalProvider } from '@/context/ModalContext';
import { HttpService } from '@/services/HttpService';
import { HttpService as HttpServiceMock } from '@/services/HttpServiceMock';
import { exerTheme } from '@/theme/exerTheme';
import App from './App';
import { AssessmentsProvider } from './context/AssessmentsContext';

Amplify.configure(config);

const httpServiceClass = process.env.MOCK_HTTP && process.env.MOCK_HTTP === 'true' ? HttpServiceMock : HttpService;

const DebugObserver = () => {
    const snapshot = useRecoilSnapshot();
    useEffect(() => {
        console.debug('The following atoms were modified:');
        for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
            console.debug(node.key, snapshot.getLoadable(node));
        }
    }, [snapshot]);

    return null;
};

const AppProvider = () => {
    return (
        <>
            <ModalProvider>
                <HttpProvider HttpService={httpServiceClass}>
                    <RecoilRoot>
                        <UserProvider
                            Auth={Auth}
                            refreshUserProfile={refreshUserProfile}
                            additionalProperties={ProfileProperites}
                        >
                            <ExercisesProvider>
                                <AssessmentsProvider>
                                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                                        <ThemeProvider theme={exerTheme}>
                                            {process.env.ENVIRONMENT !== 'DEVELOPMENT' ? (
                                                <ErrorBoundary fallback={ErrorHandler}>
                                                    <App />
                                                </ErrorBoundary>
                                            ) : (
                                                <>
                                                    <DebugObserver />
                                                    {/*<React.StrictMode>*/}
                                                    <App />
                                                    {/*</React.StrictMode>*/}
                                                </>
                                            )}
                                        </ThemeProvider>
                                    </LocalizationProvider>
                                </AssessmentsProvider>
                            </ExercisesProvider>
                        </UserProvider>
                    </RecoilRoot>
                </HttpProvider>
            </ModalProvider>
        </>
    );
};

export default AppProvider;
