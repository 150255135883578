import { styled, List, Grid } from '@mui/material';

export const DashboardListContainer = styled(List)(({}) => ({
    width: '100%',
    padding: 0,
}));

export const DashboardGridTitle = styled(Grid)(({ theme }) => ({
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'uppercase',
    color: theme.palette.grey[600],
}));

export const DashboardGridContainer = styled(Grid)(({}) => ({}));

export const DashboardGridHeaderContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    paddingBottom: theme.spacing(1.5),
    position: 'sticky',
    top: 0,
    zIndex: 50,
}));
