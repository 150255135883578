import { styled, Button } from '@mui/material';

export const EditActionWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: theme.spacing(1),
}));

export const EditActionButton = styled(Button)(({ theme }) => ({
    svg: {
        fontSize: theme.typography.body1.fontSize,
    },
}));
