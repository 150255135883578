import React from 'react';
import { preventParentLinkClick } from '@/common/utils';
import { AddIcon } from '@/components/common/Icons/AddIcon';
import { AssignHepActionWrapper, AssignHepActionButton } from './styles';

interface Props {
    openBuilder: () => void;
    text?: string;
}

export const AssignHepAction = (props: Props) => {
    const { openBuilder, text } = props;

    const handleClick = (e: React.MouseEvent) => {
        preventParentLinkClick(e);
        openBuilder();
    };

    return true ? (
        <AssignHepActionWrapper>
            <AssignHepActionButton variant="primary" onClick={handleClick} size="small" startIcon={<AddIcon />}>
                {`${text || 'Assign Program'}`}
            </AssignHepActionButton>
        </AssignHepActionWrapper>
    ) : null;
};
