import React from 'react';
import { SvgIcon } from '@mui/material';

export const EyeClosedIcon = (props) => {
    return (
        <>
            <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" {...props} style={{ fill: 'none' }}>
                <path
                    d="M9.76045 14.8668C9.18545 14.2928 8.83545 13.5128 8.83545 12.6378C8.83545 10.8848 10.2474 9.4718 11.9994 9.4718C12.8664 9.4718 13.6644 9.8228 14.2294 10.3968"
                    stroke="currentcolor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.1046 13.1989C14.8726 14.4889 13.8566 15.5069 12.5676 15.7409"
                    stroke="currentcolor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.65451 17.9723C5.06751 16.7263 3.72351 14.9063 2.74951 12.6373C3.73351 10.3583 5.08651 8.52829 6.68351 7.27229C8.27051 6.01629 10.1015 5.33429 11.9995 5.33429C13.9085 5.33429 15.7385 6.02629 17.3355 7.29129"
                    stroke="currentcolor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.4475 9.49078C20.1355 10.4048 20.7405 11.4598 21.2495 12.6368C19.2825 17.1938 15.8065 19.9388 11.9995 19.9388C11.1365 19.9388 10.2855 19.7988 9.46753 19.5258"
                    stroke="currentcolor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.8868 4.74957L4.11279 20.5236"
                    stroke="currentcolor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </SvgIcon>
        </>
    );
};
