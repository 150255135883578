import { styled, Toolbar } from '@mui/material';

export const HeaderWrapper = styled(Toolbar)(({ theme }) => ({
    padding: theme.spacing(2),
    background: theme.palette.grey[900],
    color: theme.palette.getContrastText(theme.palette.primary[500]),
    maxHeight: '64px',
    maxWidth: '100vw',
}));

export const HeaderLogoImg = styled('img')(() => ({
    height: '32px',
}));
