import { styled, Box, FormGroup, Typography } from '@mui/material';

export const CalenderContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),

    '& .rdrSelected': {
        borderRadius: 4,
    },

    '& > div': {
        maxWidth: 300,
    },
}));

export const ProductSelectionWrapper = styled(Box)(({ theme }) => ({
    textAlign: 'center',
}));

export const ProductSelectionContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

export const ProductsCheckboxesFieldGroup = styled(FormGroup)(({ theme }) => ({
    justifyContent: 'space-around',
    minWidth: '300px',
}));

export const InfoText = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(3),
}));
