import React from 'react';
import { CardMedia } from '@mui/material';
import { HEPBlock, isRepsGoal, isTimeGoal } from '@/common/types';
import { toHHMMSS } from '@/common/utils';
import { ExerciseAnimationLazy as ExerciseAnimation } from '@/components/Health/HEPBuilder/HEPBuilderExercises/ExerciseAnimationLazy';
import { FramePositionEnum } from '@/components/Health/HEPBuilder/HEPBuilderExercises/FramePosition.enum';
import { useExercisesContext } from '@/components/context/hooks/ExercisesContext';
import {
    PrintableHEPBlockDetails,
    HEPBlockWrapper,
    BlockAnimationContainer,
    HEPBlockImg,
    HEPBlockInfo,
    AnimationStepContainer,
    HEPBlockTitle,
    HEPBlockSubTitle,
    HEPBlockDetailsTitle,
    HEPBlockDetailsInfo,
} from './styles';

interface Props {
    blocks: HEPBlock[];
}

export const PrintableHEPBlocks = (props: Props) => {
    const { blocks } = props;
    const { exercisesDict } = useExercisesContext();

    return (
        <>
            {blocks.map((block: HEPBlock) => {
                return (
                    <HEPBlockWrapper
                        key={block.id}
                        container
                        direction="row"
                        wrap="nowrap"
                        style={{
                            maxWidth: '800px',
                            breakInside: 'avoid',
                        }}
                    >
                        <HEPBlockInfo item xs={8} sm={`auto`}>
                            <HEPBlockTitle>{exercisesDict?.[block.exerciseId]?.name}</HEPBlockTitle>
                            <PrintableHEPBlockDetails>
                                {block.setGoal && (
                                    <HEPBlockSubTitle style={{ margin: 0 }}>
                                        {isRepsGoal(block.setGoal)
                                            ? block.setGoal.reps && (
                                                  <span style={{ marginRight: 20 }}>
                                                      <b>Repetitions:</b> {block.setGoal.reps}
                                                  </span>
                                              )
                                            : null}
                                        {isTimeGoal(block.setGoal) ? (
                                            <span style={{ marginRight: 20 }}>
                                                <b>Time Goal:</b> {toHHMMSS(block.setGoal.time)}
                                            </span>
                                        ) : null}
                                    </HEPBlockSubTitle>
                                )}
                                {block.numSets && (
                                    <HEPBlockSubTitle style={{ margin: 0 }}>
                                        <b>Sets:</b> {block.numSets}
                                    </HEPBlockSubTitle>
                                )}
                            </PrintableHEPBlockDetails>
                            <div>
                                <HEPBlockDetailsTitle style={{ marginBottom: 4 }}>Position</HEPBlockDetailsTitle>
                                <HEPBlockDetailsInfo>{exercisesDict?.[block.exerciseId]?.position}</HEPBlockDetailsInfo>
                                <HEPBlockDetailsTitle>Action</HEPBlockDetailsTitle>
                                <HEPBlockDetailsInfo>{exercisesDict?.[block.exerciseId]?.action}</HEPBlockDetailsInfo>
                            </div>
                        </HEPBlockInfo>
                        <BlockAnimationContainer item xs={4}>
                            {exercisesDict?.[block.exerciseId]?.animationUrl ? (
                                <>
                                    <AnimationStepContainer>
                                        <p style={{ margin: 0 }}>Start:</p>
                                        <CardMedia
                                            alt={exercisesDict?.[block.exerciseId]?.name}
                                            component={(_) => (
                                                <ExerciseAnimation
                                                    animationUrl={exercisesDict[block.exerciseId].animationUrl!}
                                                    exerciseMetricId={block.exerciseId}
                                                    framePosition={FramePositionEnum.START}
                                                    activeFrame={
                                                        exercisesDict[block.exerciseId].animationThumbnailFrame
                                                    }
                                                    style={{
                                                        minWidth: 130,
                                                        width: 130,
                                                        padding: 0.5,
                                                    }}
                                                />
                                            )}
                                        />
                                    </AnimationStepContainer>
                                    <AnimationStepContainer>
                                        <p style={{ margin: 0 }}>Active:</p>
                                        <CardMedia
                                            alt={exercisesDict?.[block.exerciseId]?.name}
                                            component={(_) => (
                                                <ExerciseAnimation
                                                    animationUrl={exercisesDict[block.exerciseId].animationUrl!}
                                                    exerciseMetricId={block.exerciseId}
                                                    activeFrame={
                                                        exercisesDict[block.exerciseId].animationThumbnailFrame
                                                    }
                                                    style={{
                                                        minWidth: 130,
                                                        width: 130,
                                                        padding: 0.5,
                                                    }}
                                                />
                                            )}
                                        />
                                    </AnimationStepContainer>
                                </>
                            ) : (
                                <HEPBlockImg src={exercisesDict?.[block.exerciseId]?.thumbnailUrl} />
                            )}
                        </BlockAnimationContainer>
                    </HEPBlockWrapper>
                );
            })}
        </>
    );
};
