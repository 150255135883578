import { MetricCode } from '@/common/const';
import { Action, Exercise } from '@/common/types';
import {
    ASSESSMENT_FILTER,
    FAVORITE_FILTER,
    METRICS_FILTER,
    PRIMITIVE_FILTERS,
    SEARCH_FILTER,
} from '../../ExerciseFilters/ExerciseFilters';
import { ExerciseListState } from './ExerciseListContext';

export const ExerciseListActions = {
    SET_ALL: 'SET_ALL',
    FILTER: 'FILTER',
    CLEAR_FILTERS: 'CLEAR_FILTERS',
};

export interface Filter {
    type: string;
    value?: string | string[] | null;
}

export interface FilterDict {
    [key: string]: Filter | null;
}

export interface FilterPayload {
    active: FilterDict;
    favoriteExercises?: string[];
}

export interface ExerciseListAction extends Action {
    type: string;
    payload?: Exercise[] | FilterPayload | string;
}

const checkMeetsAll = (meetsAll: boolean, exercisePropValue: string[] | string, filter: Filter): boolean => {
    let meetsThis: boolean;
    if (Array.isArray(filter.value)) {
        const arrayValue = filter.value as string[];
        meetsThis = Array.isArray(exercisePropValue)
            ? exercisePropValue.some((prop) => arrayValue.includes(prop))
            : filter.value.includes(exercisePropValue);
    } else {
        meetsThis = Array.isArray(exercisePropValue)
            ? !!filter.value && exercisePropValue.includes(filter.value)
            : exercisePropValue === filter.value;
    }
    return meetsAll && meetsThis;
};

export const exerciseListReducer = (state: ExerciseListState, action: ExerciseListAction): ExerciseListState => {
    let payload: any;
    switch (action.type) {
        case ExerciseListActions.SET_ALL:
            payload = action.payload as Exercise[];
            return { ...state, exercises: payload };
        case ExerciseListActions.FILTER:
            payload = action.payload as FilterPayload;
            if (state.exercises === null) {
                return {
                    ...state,
                    filtered: null,
                };
            }
            const meetAllEM: Exercise[] = [];
            const meetAllEMIds: string[] = [];
            const meetAny = state.exercises.filter((exercise: Exercise) => {
                let meetsAll = true;
                Object.values(payload.active).forEach((filter: Filter) => {
                    if (PRIMITIVE_FILTERS.includes(filter.type)) {
                        const exercisePropValue = exercise[filter.type];
                        meetsAll = checkMeetsAll(meetsAll, exercisePropValue, filter);
                    } else if (filter.type === ASSESSMENT_FILTER) {
                        const meetsThis = !!exercise.name.includes('Assessment');
                        meetsAll = meetsAll && meetsThis;
                    } else if (filter.type === METRICS_FILTER) {
                        const arrayValue = filter.value as (keyof typeof MetricCode)[];
                        const exMetrics = exercise.exerciseMetrics.map((em) => {
                            return em.metric;
                        });
                        const meetsThis = exMetrics.some((em) => {
                            return arrayValue.includes(em);
                        });
                        meetsAll = meetsAll && meetsThis;
                        if (arrayValue.every((v) => exMetrics.includes(v))) {
                            meetAllEM.push(exercise);
                            meetAllEMIds.push(exercise.id);
                        }
                    } else if (filter.type === FAVORITE_FILTER) {
                        const meetsThis = payload.favoriteExercises?.includes(exercise?.id);
                        meetsAll = meetsAll && meetsThis;
                    } else if (filter.type === SEARCH_FILTER) {
                        const value = filter.value as string;
                        meetsAll = meetsAll && exercise.name.toLowerCase().includes(value.toLowerCase());
                    } else {
                        const exercisePropValue = exercise[filter.type];
                        meetsAll = checkMeetsAll(meetsAll, exercisePropValue, filter);
                    }
                });
                return meetsAll;
            });
            const filtered =
                meetAllEM.length > 0
                    ? meetAllEM.filter((mae) => {
                          return meetAny
                              .map((f) => {
                                  return f.id;
                              })
                              .includes(mae.id);
                      })
                    : meetAny;
            return {
                ...state,
                filtered,
            };
        case ExerciseListActions.CLEAR_FILTERS:
            return {
                ...state,
                filtered: null,
            };
        default:
            return {
                ...state,
                filtered: state.exercises,
            };
    }
};
