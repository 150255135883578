import React from 'react';
import { HEPTemplatesCardListContainer } from '@/components/Health/HEPs/HEPTemplatesCardList/HEPTemplatesCardListContainer';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { RouteContentWrapper } from '../styles';

export const HEPTemplatesRoute = () => {
    return (
        <RouteContentWrapper>
            <PageTitle title="Program Templates" />
            <HEPTemplatesCardListContainer />
        </RouteContentWrapper>
    );
};
