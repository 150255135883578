import React from 'react';
import { Close } from '@mui/icons-material';
import { getOptionEnumById } from '@/common/utils';
import { ExerciseActiveFilterWrapper } from './styles';

interface Props {
    type: string;
    value: string;
    updateFilters: (type: string, value: string | boolean, include: boolean) => void;
}

export const ExerciseActiveBooleanFilter = (props: Props) => {
    const { type, value, updateFilters } = props;
    const typeEnum = getOptionEnumById(type);

    const handleClick = (_: React.MouseEvent) => {
        updateFilters(type, value, false);
    };

    return (
        <ExerciseActiveFilterWrapper onClick={handleClick}>
            {typeEnum?.[value]}
            {`\u00a0`}
            <Close />
        </ExerciseActiveFilterWrapper>
    );
};
