import React from 'react';
import { Box, Grid } from '@mui/material';
import HipSpineIcon from '@/images/HipAssesment.png';
import STSIcon from '@/images/SitToStandRom.png';
import TimerIcon from '@/images/Timer.png';
import { AssessmentListItem, AssessmentCardText, ModuleCard, ModuleCardHeader, ModuleCardContent } from '../styles';

export const HipSpineInstabilityCard = () => {
    return (
        <>
            <ModuleCard>
                <ModuleCardHeader
                    avatar={<img src={HipSpineIcon} alt="" width={60} />}
                    title="Hip-Spine Instability"
                    titleTypographyProps={{ fontSize: 24, fontWeight: '600' }}
                    subheader="Evaluate the stability and functional capacity of the hip and spine."
                />
                <ModuleCardContent>
                    <Grid container spacing={2}>
                        <AssessmentListItem item xs={12}>
                            <Box>
                                <img src={STSIcon} width={50} />
                            </Box>
                            <AssessmentCardText variant="h5" color="text.secondary">
                                Sit to Stand ROM
                            </AssessmentCardText>
                        </AssessmentListItem>
                        <AssessmentListItem item xs={12}>
                            <Box>
                                <img src={TimerIcon} width={50} />
                            </Box>
                            <AssessmentCardText variant="h5" color="text.secondary">
                                Posture
                            </AssessmentCardText>
                        </AssessmentListItem>
                        <AssessmentListItem item xs={12}>
                            <Box>
                                <img src={TimerIcon} width={50} />
                            </Box>
                            <AssessmentCardText variant="h5" color="text.secondary">
                                Trunk Flexion
                            </AssessmentCardText>
                        </AssessmentListItem>
                    </Grid>
                </ModuleCardContent>
            </ModuleCard>
        </>
    );
};
