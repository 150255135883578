import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export const usePagination = <T>(
    itemsPerPage: number,
): {
    next: () => void;
    prev: () => void;
    jump: (p: number) => void;
    jumpToBeginning: () => void;
    currentData: T[];
    currentPage: number;
    paginatedData: T[];
    setPaginatedData: Dispatch<SetStateAction<T[]>>;
} => {
    const [currentPage, setCurrentPage] = useState(1);
    const [paginatedData, setPaginatedData] = useState<T[]>([]);
    const [currentData, setCurrentData] = useState<T[]>([]);
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;

    useEffect(() => {
        if (paginatedData) {
            setCurrentData(paginatedData.slice(begin, end));
        }
    }, [currentPage, itemsPerPage, paginatedData]);

    const next = () => {
        setCurrentPage((currentPage) => Math.min(currentPage + 1, Math.ceil(paginatedData.length / itemsPerPage)));
    };

    const prev = () => {
        setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
    };

    const jumpToBeginning = () => {
        setCurrentPage(1);
    };

    const jump = (page: number) => {
        const pageNumber = Math.max(1, page);
        setCurrentPage(() => Math.min(pageNumber, Math.ceil(paginatedData.length / itemsPerPage)));
    };

    return { next, prev, jump, currentData, currentPage, paginatedData, setPaginatedData, jumpToBeginning };
};

export default usePagination;
