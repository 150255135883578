import { styled } from '@mui/material';

export const HEPInputWrapper = styled('div')(({ theme }) => ({
    margin: theme.spacing(4),
    marginLeft: 0,
    marginRight: 0,

    '& h4': {
        margin: 0,
    },
    '& p': {
        color: theme.palette.grey[600],
    },
}));

export const HEPInputSliderWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(6),
    marginLeft: 0,
    marginRight: 0,
    width: '50%',
}));

export const FrequencyActionsWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));
