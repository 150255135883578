import { DateTime } from 'luxon';
import { PatientRecord, RTMPatientRecord } from '@/common/types';
import { getNumericalPROChartData } from '@/common/utils';
import { ExerciseData, PROSummary } from './types';

interface lineData {
    x: string;
    y: number;
}

export interface LineChartData {
    id: string;
    color: string;
    background: string;
    data: lineData[];
}

export type LineChartProps = LineChartData[];

export type LineChartTicks = string[];

export interface LineChartDataProps {
    lineChartData: LineChartProps;
    bottomTicks: LineChartTicks;
}

export interface PieChartProps {
    id: string;
    label: string;
    value: number;
    color: string;
    background: string;
}

export const convertPxToPt = (px: number): string => {
    return `${px * 0.75}pt`;
};

export const createROMLineChartData = (data: ExerciseData): LineChartDataProps => {
    const lineChartData: LineChartProps = [
        {
            id: 'Start ROM',
            color: '#fab532',
            background: '#fab532',
            data: [],
        },
        {
            id: 'End ROM',
            color: '#6f85fb',
            background: '#6f85fb',
            data: [],
        },
    ];
    const bottomTicks: LineChartTicks = [];

    Object.entries(data.aggregates).map((entry) => {
        const date = entry[0];
        const summary = entry[1];
        const dateTime = DateTime.fromFormat(date, 'yyyy-MM-dd');
        bottomTicks.push(dateTime.toFormat('yyyy-LL-dd'));
        if (summary?.minRange !== undefined) {
            lineChartData[0].data.push({
                x: dateTime.toFormat('dd LLL'),
                y: summary.minRange,
            });
        }
        if (summary?.maxRange !== undefined) {
            lineChartData[1].data.push({
                x: dateTime.toFormat('dd LLL'),
                y: summary.maxRange,
            });
        }
    });

    return { lineChartData, bottomTicks };
};

export const createProLineChartData = (data: [string, PROSummary | null][], patientId: number): LineChartDataProps => {
    const lineChartData: LineChartProps = [
        {
            id: getNumericalPROChartData(patientId).proLabel,
            color: '#f441cd',
            background: '#f441cd',
            data: [],
        },
    ];
    const bottomTicks: LineChartTicks = [];

    data.map((x) => {
        const date = x[0];
        const response = x[1];
        const yData = response?.value ? +response.value : null;
        bottomTicks.push(DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('yyyy-LL-dd'));
        if (yData) {
            lineChartData[0].data.push({
                x: DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('dd LLL'),
                y: yData,
            });
        }
    });

    return { lineChartData, bottomTicks };
};

export const dmSansFont = `/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriCZ2IHTWEBlwu8Q.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriCZOIHTWEBlw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriAWCrCBamC3YU-CnE6Q.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriAWCrCBimC3YU-Ck.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriASitCBamC3YU-CnE6Q.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriASitCBimC3YU-Ck.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}`;

export const getActivitySummaryFilename = (
    patientRecord: PatientRecord | RTMPatientRecord,
    format: 'csv' | 'pdf' = 'pdf',
) => {
    const firstName =
        'patient' in patientRecord ? patientRecord?.patient?.firstName?.charAt(0) : patientRecord?.firstName?.charAt(0);
    const lastName = 'patient' in patientRecord ? patientRecord?.patient?.lastName : patientRecord?.lastName;
    const dateString = DateTime.now().toFormat('MM-dd-yyyy');
    let name = '';
    name += firstName || 'exer_hep';
    name += lastName ? `_${lastName}` : '';
    return `${dateString}_${name}.${format}`;
};
