import React, { useEffect, useState } from 'react';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Button, Box, List, ListItem, ListItemText } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { sleep } from '@/common/utils';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { TrashIcon } from '@/components/common/Icons/TrashIcon';
import { Modal } from '@/components/common/Modal/Modal';
import { deleteTemplatesListState, deletedHEPTemplatesState, hepTemplatesState } from '@/recoil/atoms/hepTemplatesAtom';
import { resultIsError } from '@/services/HttpService';

export const DeleteHEPAction = () => {
    const [deleteTemplatesState, setDeleteTemplatesState] = useRecoilState(deleteTemplatesListState);
    const hepTemplates = useRecoilValue(hepTemplatesState);
    const [deletedHEPTemplates, setDeletedHEPTemplates] = useRecoilState(deletedHEPTemplatesState);
    const { httpService } = useHttpContext();
    const { successToast, errorToast } = toasts;
    const [templateNames, setTemplateNames] = useState<string[]>();
    const { ids, selectIsActive } = deleteTemplatesState;
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if (dialogOpen) {
            const hepTemplateNames = hepTemplates.reduce((arr: string[], template) => {
                if (ids.some((id) => template.id === id)) {
                    return [...arr, template.name];
                } else {
                    return arr;
                }
            }, []);
            setTemplateNames(hepTemplateNames);
        }
    }, [dialogOpen]);

    const handleClick = () => {
        setDeleteTemplatesState({ ...deleteTemplatesState, selectIsActive: !selectIsActive });
    };

    const handleClearClick = () => {
        setDeleteTemplatesState({ ids: [], selectIsActive: !selectIsActive });
    };

    const handleDeleteClick = () => {
        setDialogOpen(true);
    };

    const onCancel = () => {
        setDialogOpen(false);
    };

    const handleSubmit = async () => {
        const success = await httpService.deleteMultipleHEPTemplates(ids);

        const updatedTemplates = deletedHEPTemplates.reduce((arr, hepId) => {
            if (ids.some((id) => hepId === id)) {
                return arr;
            } else {
                return [...arr, hepId];
            }
        }, []);

        if (!success || resultIsError(success)) {
            errorToast('The Program templates could not be deleted.');
            return;
        } else {
            successToast(`Templates have been successfully deleted.`);

            setDeleteTemplatesState({ ids: [], selectIsActive: !selectIsActive });
            await sleep(1000);

            setDialogOpen(false);
            setDeletedHEPTemplates([...updatedTemplates]);
            await sleep(200);
        }
    };

    return (
        <>
            <Box
                style={{
                    position: selectIsActive ? 'sticky' : 'relative',
                    top: selectIsActive ? 10 : 0,
                    left: 0,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    zIndex: 100,
                    marginBottom: 8,
                }}
            >
                <Box
                    style={{ padding: 8, backgroundColor: selectIsActive ? '#f7f8fa' : 'unset', borderRadius: 4 }}
                    sx={{ boxShadow: selectIsActive ? 2 : 0 }}
                >
                    {selectIsActive ? (
                        <>
                            <Button
                                onClick={handleClearClick}
                                variant="tertiary"
                                startIcon={ids.length > 0 && <ClearRoundedIcon />}
                                size="small"
                                sx={{ mr: 2 }}
                            >
                                {ids.length > 0 ? 'Clear Selection' : 'Cancel'}
                            </Button>
                            <Button
                                onClick={handleDeleteClick}
                                variant="secondary"
                                startIcon={<TrashIcon />}
                                size="small"
                                disabled={ids.length === 0}
                            >
                                Delete {ids.length > 1 ? `${ids.length} Templates` : 'Template'}
                            </Button>
                        </>
                    ) : (
                        <Button onClick={handleClick} variant="text" startIcon={<TrashIcon />} size="small">
                            Select Multiple
                        </Button>
                    )}
                </Box>
            </Box>
            <Modal
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                fullWidth={true}
                maxWidth={'lg'}
                title="Delete Templates"
            >
                <List dense style={{ marginBottom: 16 }}>
                    {templateNames &&
                        templateNames.map((id) => {
                            return (
                                <ListItem key={id}>
                                    <ListItemText>{id}</ListItemText>
                                </ListItem>
                            );
                        })}
                </List>

                <SubmitCancelButtons
                    submitText="Delete"
                    submitFunction={handleSubmit}
                    cancelFunction={onCancel}
                    size="small"
                />
            </Modal>
        </>
    );
};
