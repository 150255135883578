import { useEffect, useState, MouseEventHandler } from 'react';

export enum ControlEnum {
    PLAYING = 'PLAYING',
    PAUSED = 'PAUSED',
    STOPPED = 'STOPPED',
}

export type ControlState = `${ControlEnum}`;

interface UseAudio {
    toggle: MouseEventHandler<HTMLButtonElement>;
    restart: MouseEventHandler<HTMLButtonElement>;
    controlState: ControlState;
}

export const useAudioController = (url): UseAudio => {
    const [audio] = useState(new Audio(url));
    const [controlState, setControlState] = useState<ControlState>(ControlEnum.STOPPED);

    const toggle = () => {
        setControlState((prev) => (prev === ControlEnum.PLAYING ? ControlEnum.PAUSED : ControlEnum.PLAYING));
    };

    const restart = () => {
        setControlState(ControlEnum.STOPPED);
    };

    const controls = (c) => {
        switch (c) {
            case ControlEnum.PAUSED:
                return audio.pause();
            case ControlEnum.PLAYING:
                return audio.play();
            case ControlEnum.STOPPED:
                return audio.load();
            default:
                return audio.load();
        }
    };

    useEffect(() => {
        controls(controlState);
    }, [controlState]);

    useEffect(() => {
        audio.addEventListener('ended', () => setControlState(ControlEnum.STOPPED));
        return () => {
            audio.load();
            audio.removeEventListener('ended', () => setControlState(ControlEnum.STOPPED));
        };
    }, []);

    return { toggle, controlState, restart };
};
