import { styled } from '@mui/material';
import { Maybe } from '@/common/types';

export const OutcomeFactorTableWrapper = styled('div')(() => ({
    maxWidth: 600,
    margin: '0 auto',
}));

export const OutcomeFactorSexTableWrapper = styled('div')(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(4),
    border: `1px solid #5B5B5B`,
    color: '#5A6978',
}));

export const OutcomeFactorTableHeader = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 'bold',
    borderBottom: `1px solid #5B5B5B`,
    color: '#cccccc',
}));

export const OutcomeFactorTableRow = styled('div')<{
    $isHeaderRow: boolean;
    $isResultRow: boolean;
    $factorColor?: Maybe<string>;
    $isLastRow: boolean;
}>(({ $isHeaderRow, $isResultRow, $factorColor, $isLastRow }) => ({
    display: 'flex',
    color: $isHeaderRow ? '#cccccc' : $isResultRow && $factorColor ? $factorColor : 'inherit',
    background: $isResultRow ? '#2A2A2A' : 'none',
    borderBottom: $isLastRow ? 'none' : `1px solid #5B5B5B`,
}));

export const OFTColumn = styled('div')<{ $isLastColumn?: boolean }>(({ theme, $isLastColumn }) => ({
    flex: 1,
    padding: theme.spacing(2),
    borderRight: $isLastColumn ? 'none' : `1px solid #5B5B5B`,
    position: 'relative',
}));
