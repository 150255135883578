import React, { ChangeEvent } from 'react';
import { DateRangeActions } from '@/components/common/DateRangeActions/DateRangeActions';
import { DateRangeWrapper } from '@/components/common/DateRangeActions/styles';
import { DateRangeAtomState } from '@/recoil/atoms/dateRangeAtom';
import { AssessmentTab } from './AssessmentTab';
import { ProgressTab } from './ProgressTab';
import { TabControls } from './TabControls';

interface PatientTabsProps {
    currentTab: any;
    handleTabChange: (event: ChangeEvent<HTMLButtonElement>, newValue: number) => void;
    handleProgressChartTabClick: any;
    handleRefreshClick: any;
    dateRange: DateRangeAtomState;
    gaitSortedSessions: any;
    gaitSessionsFilteredByDateRange: any;
    handleChartSelection: ({ optionName, optionId }: { optionName: string; optionId: string }) => void;
    showCharts: any;
    currentChart: any;
    isInModal: boolean;
}

export const PatientTabs = (props: PatientTabsProps) => {
    const {
        currentTab,
        handleTabChange,
        handleProgressChartTabClick,
        handleRefreshClick,
        dateRange,
        gaitSortedSessions,
        gaitSessionsFilteredByDateRange,
        handleChartSelection,
        showCharts,
        currentChart,
        isInModal,
    } = props;
    return (
        <>
            <TabControls
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                aria-label="Gait patient tabs"
                handleProgressChartTabClick={handleProgressChartTabClick}
                handleRefreshClick={handleRefreshClick}
                isInModal={isInModal}
            />
            <DateRangeWrapper>
                <DateRangeActions />
            </DateRangeWrapper>
            <AssessmentTab
                currentTab={currentTab}
                dateRange={dateRange}
                gaitSortedSessions={gaitSortedSessions}
                gaitSessionsFilteredByDateRange={gaitSessionsFilteredByDateRange}
            />
            <ProgressTab
                currentTab={currentTab}
                dateRange={dateRange}
                showCharts={showCharts}
                handleChartSelection={handleChartSelection}
                currentChart={currentChart}
            />
        </>
    );
};
