import { useContext } from 'react';
import { PaginationContext } from '../PaginationContext';

export const usePaginationContext = () => {
    const paginationContext = useContext(PaginationContext);

    if (!paginationContext) {
        throw new Error('PaginationContext not initialized');
    }

    return paginationContext;
};
