import React from 'react';
import { SvgIcon } from '@mui/material';

export const ComputerGraphIcon = (props) => {
    return (
        <>
            <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 5C3 3.89544 3.89544 3 5 3H19C20.1046 3 21 3.89545 21 5V15C21 16.1046 20.1046 17 19 17H5C3.89545 17 3 16.1046 3 15V5ZM19 5H5V15H19V5Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 20C2 19.4477 2.44772 19 3 19H21C21.5523 19 22 19.4477 22 20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.1602 7.01292C11.4775 7.06441 11.7507 7.26529 11.8944 7.5528L13.274 10.3118L14.2929 9.29291C14.4804 9.10537 14.7348 9.00001 15 9.00001H17C17.5523 9.00001 18 9.44773 18 10C18 10.5523 17.5523 11 17 11H15.4142L13.7071 12.7071C13.4798 12.9344 13.1571 13.0386 12.8398 12.9871C12.5225 12.9356 12.2493 12.7347 12.1056 12.4472L10.726 9.68818L9.70711 10.7071C9.51957 10.8947 9.26522 11 9 11H7C6.44772 11 6 10.5523 6 10C6 9.44773 6.44772 9.00001 7 9.00001H8.58579L10.2929 7.29291C10.5202 7.06561 10.8429 6.96144 11.1602 7.01292Z"
                    fill="currentColor"
                />
            </SvgIcon>
        </>
    );
};
