import React, { ChangeEvent } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { a11yProps } from '@/common/utils';
import { PatientFocusAction } from '@/components/Patients/Patient/PatientDetailsActions/PatientFocusAction';
import { Tabs, Tab } from '@/components/common/Tabs/styles';
import { PatientGaitSessionTableRefreshWrapper, PatientGaitSessionTableRefreshButton } from '../styles';

interface TabControlsProps {
    currentTab: number;
    handleTabChange: (event: ChangeEvent<HTMLButtonElement>, newValue: number) => void;
    handleProgressChartTabClick: () => void;
    handleRefreshClick: () => void;
    isInModal: boolean;
}

export const TabControls = (props: TabControlsProps) => {
    const { currentTab, handleTabChange, handleProgressChartTabClick, handleRefreshClick, isInModal } = props;

    return (
        <>
            <div style={{ display: 'flex' }}>
                <Tabs value={currentTab} onChange={handleTabChange} aria-label="Gait patient tabs">
                    <Tab label="Assessment" {...a11yProps(0)} />
                    {/* No Charts for Gait V1, so don't show tab, until V2 is deployed */}
                    {process.env.ENVIRONMENT !== 'PRODUCTION' && (
                        <Tab label="Progress charts" {...a11yProps(1)} onClick={handleProgressChartTabClick} />
                    )}
                    {/* END: No Charts for Gait V1, so don't show tab, until V2 is deployed */}
                    {!isInModal ? (
                        <PatientGaitSessionTableRefreshWrapper>
                            <PatientGaitSessionTableRefreshButton onClick={handleRefreshClick} size="small">
                                <RefreshIcon />
                            </PatientGaitSessionTableRefreshButton>
                        </PatientGaitSessionTableRefreshWrapper>
                    ) : null}
                </Tabs>
                {!isInModal ? (
                    <div
                        style={{
                            backgroundColor: '#fff',
                            boxShadow: 'rgb(230, 232, 240) 0px -2px 0px 0px inset',
                        }}
                    >
                        <PatientFocusAction />
                    </div>
                ) : null}
            </div>
        </>
    );
};
