import React, { useState } from 'react';
import { MenuItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { addDays, format, isPast, isToday, isFuture, isAfter, parseISO } from 'date-fns';
import { Calendar } from 'react-date-range';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { PatientRecord } from '@/common/types';
import { productsIncludesPRO, sleep } from '@/common/utils';
import { CalendarWrapper } from '@/components/common/DateRangeActions/styles';
import { Dialog, DialogContent, DialogContentWrapper } from '@/components/common/Dialog/styles';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { DischargeIcon } from '@/components/common/Icons/DischargeIcon';
import { Button } from '@/components/common/button/button';
import { resultIsError } from '@/services/HttpService';
import { CalenderContainer, InfoTextContainer } from './styles';

interface Props {
    patientRecord: PatientRecord;
    isCTA?: boolean;
    handleClose?: (e) => void;
    setEndDate?: (Date) => void;
    onSuccess?: () => void;
}

export const DischargeAction = (props: Props) => {
    const { patientRecord, isCTA = false, handleClose, setEndDate, onSuccess } = props;
    const { id, patient, products } = patientRecord;
    const { httpService } = useHttpContext();
    const [dialogOpen, setDialogOpen] = useState(false);
    const { successToast, errorToast } = toasts;
    const [date, setDate] = useState(new Date());

    const handleCloseDialog = (e) => {
        setDialogOpen(false);
        handleClose && handleClose(e);
        setDate(new Date());
    };

    const handleCalendarSelection = (item) => {
        setDate(item);
    };

    const handleClick = () => {
        setDialogOpen(true);
    };

    const handleDischarge = async (e) => {
        const result = await httpService.dischargePatient(id, format(date, 'yyyy-MM-dd'));
        if (resultIsError(result)) {
            errorToast(result.message);
        } else {
            successToast(`Successfully discharged ${patient.firstName} ${patient.lastName}`);

            handleCloseDialog(e);
            await sleep(500);
            setEndDate && setEndDate(date);

            if (isPast(date) && !isToday(date)) {
                onSuccess && onSuccess();
            }
        }
    };

    return (
        <>
            {isCTA ? (
                <Button onClick={handleClick} variant="text" size="small" startIcon={<DischargeIcon />}>
                    Discharge
                </Button>
            ) : (
                <MenuItem onClick={handleClick}>
                    <ListItemIcon>
                        <DischargeIcon />
                    </ListItemIcon>
                    <ListItemText>Discharge</ListItemText>
                </MenuItem>
            )}
            <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth={true} maxWidth={'sm'}>
                <DialogContent>
                    <DialogContentWrapper>
                        <h2>
                            Discharge {patient.firstName} {patient.lastName}
                        </h2>

                        <CalenderContainer>
                            <CalendarWrapper>
                                <Calendar
                                    date={date}
                                    onChange={handleCalendarSelection}
                                    months={1}
                                    direction="horizontal"
                                    monthDisplayFormat="MMMM yyyy"
                                    minDate={
                                        isAfter(new Date(patientRecord.plansOfCare[0].start!), addDays(new Date(), -28))
                                            ? parseISO(patientRecord.plansOfCare[0].start!)
                                            : addDays(new Date(), -28)
                                    }
                                    maxDate={addDays(new Date(), 28)}
                                />
                            </CalendarWrapper>
                        </CalenderContainer>
                        <InfoTextContainer>
                            <Typography variant="body1">
                                {`${patient.firstName}'s  discharge date will be set to `}
                                <b>
                                    {date.toLocaleDateString('en-us', {
                                        weekday: 'short',
                                        month: 'short',
                                        day: 'numeric',
                                    })}
                                </b>
                            </Typography>
                        </InfoTextContainer>
                        {(isToday(date) || isFuture(date)) && (
                            <InfoTextContainer>
                                <Typography variant="body1">
                                    The patient will remain active through the end of the date selected.
                                </Typography>
                            </InfoTextContainer>
                        )}
                        {productsIncludesPRO(products) ? (
                            <InfoTextContainer>
                                <Typography>
                                    Heads up! {patient.firstName} must reply STOP to end their PRO campaign and stop
                                    receiving SMS.
                                </Typography>
                            </InfoTextContainer>
                        ) : null}

                        <SubmitCancelButtons
                            submitFunction={handleDischarge}
                            cancelFunction={handleCloseDialog}
                            submitText="Discharge"
                            cancelText="Cancel"
                        />
                    </DialogContentWrapper>
                </DialogContent>
            </Dialog>
        </>
    );
};
