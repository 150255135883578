import { styled } from '@mui/material';

export const PatientWithNoHEPWrapper = styled('div')({
    textAlign: 'center',
});

export const TabLoadingText = styled('span')<{ $isLoading: boolean }>(({ $isLoading }) => ({
    animation: $isLoading ? 'loading 1.5s ease-in-out infinite' : 'none',
    '@keyframes loading': {
        '0%': {
            opacity: 1,
        },
        '50%': {
            opacity: 0.4,
        },
        '100%': {
            opacity: 1,
        },
    },
}));

export const EmptyPatientHr = styled('hr')(({ theme }) => ({
    border: 'none',
    borderTop: '1px solid grey',
    borderColor: theme.palette.grey[300],
    marginBottom: 40,
}));
