import {
    Dialog as MUIDialog,
    DialogContent as MUIDialogContent,
    DialogTitle as MUIDialogTitle,
    styled,
    CardActions as MUICardActions,
    Card as MUICard,
    CardHeader as MUICardHeader,
    CardContent as MUICardContent,
    Box,
    Grid,
    Typography,
} from '@mui/material';

export const Dialog = styled(MUIDialog)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
}));

export const DialogTitle = styled(MUIDialogTitle)(({ theme }) => ({
    // color: theme.palette.primary[500],
    fontSize: theme.typography.h3.fontSize,
}));

export const DialogContent = styled(MUIDialogContent)(({ theme }) => ({
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
}));

export const DialogTitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
}));

export const DialogContentWrapper = styled('div')(({ theme }) => ({
    position: 'relative',

    '& h2': {
        textAlign: 'center',
        margin: `0 0 24px`,
    },
    '& h3': {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 'normal',
        textAlign: 'center',
        margin: `0 0 24px`,
    },
}));

export const DialogFooter = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(6),
}));

export const Card = styled(MUICard)(({ theme }) => ({
    display: 'flex',
    height: '100%',
}));

export const CardHeader = styled(MUICardHeader)(({ theme }) => ({
    '.MuiCardHeader-title': {
        fontSize: '1.8rem',
        color: theme.palette.common.black,
    },
}));

export const CardActions = styled(MUICardActions)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 0,
    paddingBottom: 0,
}));

export const AssessmentListItem = styled(Grid)(({ theme }) => ({
    padding: `12px 0`,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
}));

export const ModuleCard = styled(MUICard)(({ theme }) => ({
    minWidth: 275,
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    padding: 0,
}));

export const ModuleCardHeader = styled(MUICardHeader)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    alignItems: 'flex-start',
    minHeight: 138,
    // TODO: Remove these but they are here until I get dummy Module Copy
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {
        minHeight: 140,
    },
    [theme.breakpoints.up('lg')]: {
        minHeight: 166,
    },
    [theme.breakpoints.up('xl')]: {
        minHeight: 'auto',
    },
    '.MuiCardHeader-title': {
        [theme.breakpoints.up('sm')]: {
            fontSize: 22,
            // color: 'pink',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 20,
            // color: 'yellow',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 20,
            // color: 'green',
        },
    },
}));

export const ModuleCardContent = styled(MUICardContent)(({ theme }) => ({
    paddingBottom: 0,
}));

export const AssessmentCard = styled(Box)(({ theme }) => ({
    marginBottom: 22,
    padding: `16px`,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
}));

export const AssessmentCardText = styled(Typography)(({ theme }) => ({
    marginLeft: 16,
    fontWeight: '600',
}));

export const AssessmentCardDetails = styled(Typography)(({ theme }) => ({
    marginLeft: 16,
    marginRight: 8,
    color: 'rgb(115, 117, 128)',
    fontSize: '1rem',
}));

export const AssessmentCardSubTitle = styled(Typography)(({ theme }) => ({
    marginLeft: 16,
    fontWeight: '600',
}));

export const AssessmentColumn = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}));
