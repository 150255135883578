import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    convertV1PoseLog,
    convertV2PoseLog,
    getV1PoseLogDimensions,
    getV2PoseLogDimensions,
    poseLogIsV1,
    poseLogIsV2,
    RenderKeypoints,
    useExerVision,
    PoseLogV1Schema,
    PoseLogV2Schema,
    PoseLogMeta,
} from '@exerai/vision-engine-web';
import { PauseCircle, PlayCircle } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { Maybe, Nullish } from '@/common/types';
import { camelCaseToTitle } from '@/common/utils';
import { SessionReplayCanvasWrapper } from '@/components/common/Pose/styles';
import { Result, resultIsError } from '@/services/HttpService';
import { ReplaySlider } from './ReplaySlider/ReplaySlider';
import {
    SessionReplayCurrentFrame,
    SessionReplayHeader,
    SessionReplayPhaseLabel,
    SessionReplayPlayControls,
} from './styles';

interface SessionReplayProps {
    sessionUUID: string;
    client: 'gait' | 'scan';
}

const REPLAY_SCALE = 0.66;
const DEFAULT_FPS = 30;
const OOR_NUMBER = 999999;

export const SessionReplay = (props: SessionReplayProps) => {
    const { sessionUUID, client } = props;
    const { httpService } = useHttpContext();
    const { session, outputCanvasRef, draw, setKeypoints, isPlaying, handlePlay } = useExerVision();
    const { phase, setPhase } = session;
    const [poseLog, setPoseLog] = useState<Nullish<RenderKeypoints[]>>();
    const [frame, setFrame] = useState(0);
    const [poseDimensions, setPoseDimensions] = useState<{ width: number; height: number }>({
        width: 900,
        height: 640,
    });
    const [meta, setMeta] = useState<PoseLogMeta>();
    const [mockFPS, setMockFPS] = useState<Nullish<number>>(DEFAULT_FPS);
    const isDelayedRef = useRef(false);

    useEffect(() => {
        (async () => {
            const res = await httpService.adminGetSessionPoseLogSignedUrl(client, sessionUUID);
            if (resultIsError(res)) {
                alert(res.message);
                return;
            }
            const poseRes: Result<PoseLogV1Schema | PoseLogV2Schema> = await httpService.getExternalUrl(res);
            if (resultIsError(poseRes)) {
                alert(poseRes.message);
                return;
            }
            let convertedPoseLog: RenderKeypoints[];
            if (poseLogIsV1(poseRes)) {
                const dimensions = getV1PoseLogDimensions(poseRes);
                if (dimensions) {
                    setPoseDimensions(dimensions);
                }
                convertedPoseLog = convertV1PoseLog(poseRes);
                setPoseLog(convertedPoseLog);
            } else if (poseLogIsV2(poseRes)) {
                const dimensions = getV2PoseLogDimensions(poseRes);
                if (dimensions) {
                    setPoseDimensions(dimensions);
                }
                convertedPoseLog = convertV2PoseLog(poseRes);
                setPoseLog(convertedPoseLog);
                setMeta(poseRes.meta);
            }

            handlePlay(true);
        })();
    }, []);

    const advance = useCallback((): void => {
        if (isDelayedRef.current || !isPlaying || !poseLog || poseLog.length === 0) {
            window.requestAnimationFrame(advance);
            return;
        }
        const targetFrame = frame + 1;
        if (targetFrame >= poseLog.length) {
            setFrame(0);
            handlePlay(false);
        } else {
            setFrame((prevFrame) => {
                return prevFrame + 1;
            });
        }
        setKeypoints(poseLog[targetFrame]);
        if (mockFPS) {
            isDelayedRef.current = true;
            setTimeout(() => {
                isDelayedRef.current = false;
            }, 1000 / mockFPS);
        }
    }, [frame, isPlaying, mockFPS, phase, poseLog, setKeypoints, setPhase]);

    useEffect(() => {
        window.requestAnimationFrame(advance);
    }, [advance]);

    useEffect(() => {
        draw(REPLAY_SCALE);
    }, [draw]);

    const handleSlider = (_, frame) => {
        setFrame(frame);
        if (poseLog) {
            setKeypoints(poseLog[frame]);
        }
        handlePlay(false);
    };

    const getPhasesFromLog = (meta: Maybe<PoseLogMeta>) => {
        if (!meta?.phases) {
            return undefined;
        }
        // NB: Before exposing to Provider: Should this be shown? Only shown to Exer Admin? Trim Pose Log up to first non negative index? Dependent on role?
        const phases: { value: number; label: string }[] = [
            {
                value: 0,
                label: 'Not Started',
            },
        ];

        Object.entries(meta.phases).forEach((phaseEntry) => {
            const phaseName = phaseEntry[0];
            const phaseStartFrame = phaseEntry[1].startFrame;
            if (phaseStartFrame > -1) {
                phases.push({
                    value: phaseStartFrame,
                    label: camelCaseToTitle(phaseName),
                });
            }
        });
        return phases.sort((a, b) => (b.value > a.value ? -1 : 0));
    };

    const phasesFromLog = getPhasesFromLog(meta);
    const activePhase = phasesFromLog?.reduce((high, curr) =>
        curr.value > high.value && frame >= curr.value ? curr : high,
    );

    return (
        <>
            <SessionReplayHeader>
                <SessionReplayPhaseLabel>
                    {phasesFromLog ? (
                        <>
                            Phase: <span>{activePhase?.label}</span>
                        </>
                    ) : null}
                </SessionReplayPhaseLabel>
                <SessionReplayCurrentFrame>
                    {poseLog && frame !== undefined ? `Frame: ${frame} / ${poseLog.length}` : 'Loading'}
                </SessionReplayCurrentFrame>
            </SessionReplayHeader>

            <SessionReplayCanvasWrapper width={'100%'} height={'100%'}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <canvas
                        ref={outputCanvasRef}
                        width={poseDimensions.width / (1 / REPLAY_SCALE)}
                        height={poseDimensions.height / (1 / REPLAY_SCALE)}
                    />
                </div>
            </SessionReplayCanvasWrapper>
            <SessionReplayPlayControls>
                <div>
                    <IconButton
                        aria-label="play/pause"
                        onClick={() => handlePlay()}
                        edge="end"
                        style={{ color: '#666666', fontSize: 36 }}
                    >
                        {isPlaying ? <PauseCircle fontSize="inherit" /> : <PlayCircle fontSize="inherit" />}
                    </IconButton>
                </div>
            </SessionReplayPlayControls>
            <ReplaySlider
                phases={phasesFromLog}
                frame={frame}
                frameCount={poseLog?.length}
                handleSlider={handleSlider}
            />
        </>
    );
};
