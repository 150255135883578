import { styled } from '@mui/material';

export const TermsAgreementCheckboxWrapper = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(2),
    textAlign: 'left',

    '& .MuiSvgIcon-root': {
        fill: theme.palette.primary.main,
    },
}));
