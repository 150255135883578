import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { DashboardData } from '@/common/types';
import { DashboardContainer } from '@/components/Dashboard/DashboardContainer';
import { EmptyDashboardContainer } from '@/components/Dashboard/EmptyDashboardContainer';
import { UnhandledError } from '@/components/common/Error/UnhandledError';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { resultIsError } from '@/services/HttpService';
import { RouteContentWrapper } from '../styles';
import { DashboardSubTitle, DashboardTitle } from './styles';

export const DashboardRoute = () => {
    const { user, isScanOnlyUser } = useExerUser();
    const [providerActions, setProviderActions] = useState<DashboardData>();
    const [hasFetched, setHasFetched] = useState(false);
    const [patientsExist, setPatientsExist] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isErrored, setIsErrored] = useState(false);
    const { httpService } = useHttpContext();

    const getProviderActions = async () => {
        const res = await httpService.getProviderActions();
        if (resultIsError(res)) {
            setIsErrored(true);
        } else {
            setProviderActions(res);
            setPatientsExist(res.TOTAL_NUM_PATIENTS > 0);
        }
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await getProviderActions();
            setHasFetched(true);
            setIsLoading(false);
        })();
    }, [user]);

    return (
        <RouteContentWrapper>
            <PageTitle title={`Hi ${user?.firstName}`} />
            {isScanOnlyUser() ? (
                <>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Welcome to Exer Provider Portal {user?.organizationName && `for ${user.organizationName}`}.
                    </Typography>
                    <DashboardSubTitle variant="subtitle1">Complete Scan assessments in the app.</DashboardSubTitle>
                </>
            ) : isErrored ? (
                <UnhandledError />
            ) : isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    {hasFetched ? (
                        !patientsExist ? (
                            <>
                                <Typography variant="h4" component="h1" gutterBottom>
                                    Welcome to Exer Provider Portal{' '}
                                    {user?.organizationName && `for ${user.organizationName}`}
                                </Typography>
                                <DashboardSubTitle variant="subtitle1">
                                    Choose your first step on the platform and start working with your patients.
                                </DashboardSubTitle>
                                <EmptyDashboardContainer />
                            </>
                        ) : (
                            providerActions && (
                                <>
                                    <DashboardTitle variant="body2">{`Your Patients' Activity`}</DashboardTitle>
                                    <DashboardContainer data={providerActions} />
                                </>
                            )
                        )
                    ) : null}
                </>
            )}
        </RouteContentWrapper>
    );
};
