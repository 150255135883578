import * as React from 'react';
import { SvgIcon } from '@mui/material';

export const PatientReportedOutcomesIcon = (props) => {
    return (
        <SvgIcon width="24" height="24" viewBox="240 240 720 719.97" {...props}>
            <path
                d="m284.4 954.48c13.16 5.4531 27.637 6.8828 41.609 4.1055 13.969-2.7773 26.801-9.6367 36.871-19.707l75-74.879h402.12c31.824 0 62.348-12.645 84.852-35.148 22.504-22.504 35.148-53.027 35.148-84.852v-384c0-31.824-12.645-62.348-35.148-84.852-22.504-22.504-53.027-35.148-84.852-35.148h-480c-31.824 0-62.348 12.645-84.852 35.148-22.504 22.504-35.148 53.027-35.148 84.852v528c0.003906 14.227 4.2227 28.137 12.125 39.969s19.133 21.059 32.273 26.512zm27.602-162.48v-432c0-12.73 5.0586-24.938 14.059-33.941 9.0039-9 21.211-14.059 33.941-14.059h480c12.73 0 24.938 5.0586 33.941 14.059 9 9.0039 14.059 21.211 14.059 33.941v384c0 12.73-5.0586 24.938-14.059 33.941-9.0039 9-21.211 14.059-33.941 14.059h-432l-96 96z"
                fill="currentColor"
            />
            <path d="m564 384h72v336h-72z" fill="currentColor" />
            <path d="m408 552h72v168h-72z" fill="currentColor" />
            <path d="m720 492h72v228h-72z" fill="currentColor" />
        </SvgIcon>
    );
};
