import { styled, Theme } from '@mui/material';

export const HEPSummaryWrapper = styled('div')<{ theme: Theme; $indent?: boolean }>(({ theme, $indent }) => ({
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),

    '& h5': {
        textTransform: 'uppercase',
        fontWeight: 400,
        margin: 0,
        marginBottom: theme.spacing(1),
    },

    '& p': {
        margin: 0,
        marginBottom: theme.spacing(1),
        marginLeft: $indent ? theme.spacing(2) : 0,
    },
}));
