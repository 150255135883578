import { styled } from '@mui/material';

export const SessionReplayPlayControls = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
}));

export const SessionReplayHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
}));

export const SessionReplayPhaseLabel = styled('div')(({ theme }) => ({
    fontWeight: 'bold',
    color: '#666666',

    '> span': {
        fontWeight: 'normal',
        color: theme.palette.primary.main,
    },
}));

export const SessionReplayCurrentFrame = styled('div')(({ theme }) => ({
    color: '#666666',
}));
