import React from 'react';
import { HowToReg } from '@mui/icons-material';
import { SelfReportedEnum } from '@/common/const';
import { SelfReportedTooltipWrapper } from './styles';

interface SelfReportedTooltipProps {
    selfReportedStatus: SelfReportedEnum;
}

export const SelfReportedTooltip = (props: SelfReportedTooltipProps) => {
    const { selfReportedStatus } = props;

    return selfReportedStatus === SelfReportedEnum.FULL || selfReportedStatus === SelfReportedEnum.PARTIAL ? (
        <SelfReportedTooltipWrapper
            title={`${selfReportedStatus === SelfReportedEnum.PARTIAL ? 'Partially' : ''} Self-Reported`}
        >
            <p style={{ padding: '0 0 0 8px', margin: 0, lineHeight: 0 }}>
                <HowToReg style={{ fontSize: 16, color: '#47A6FF' }} />
            </p>
        </SelfReportedTooltipWrapper>
    ) : null;
};
