import React from 'react';
import { Box, Grid } from '@mui/material';
import {
    DemoHandIcon,
    DemoHandFlexionIcon,
    DemoHandSupinationIcon,
    DemoKapandjiIcon,
} from '@/components/common/Icons/DemoHandIcon';
import { AssessmentCardText, AssessmentListItem, ModuleCard, ModuleCardHeader, ModuleCardContent } from '../styles';

export const HandWristCard = () => {
    return (
        <>
            <ModuleCard>
                <ModuleCardHeader
                    avatar={
                        <Box
                            style={{
                                width: 60,
                                height: 60,
                                background: '#66cccb',
                                border: '5px solid #d9ebfe',
                                borderRadius: 50,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#fff',
                            }}
                        >
                            <DemoHandIcon width={25} style={{ color: '#fff', fontSize: '28px' }} />
                        </Box>
                    }
                    title="Hand/Wrist Eval"
                    titleTypographyProps={{ fontSize: 24, fontWeight: '600' }}
                    subheader="Measure overall flexibility, ROM, and dexterity of hand and wrist."
                />
                <ModuleCardContent>
                    <Grid container spacing={2}>
                        <AssessmentListItem item xs={12}>
                            <Box>
                                <Box
                                    style={{
                                        width: 50,
                                        height: 50,
                                        background: '#508bf2',
                                        border: '5px solid #daebfe',
                                        borderRadius: 50,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#fff',
                                    }}
                                >
                                    <DemoHandFlexionIcon
                                        width={25}
                                        style={{
                                            color: '#fff',
                                            fontSize: '28px',
                                            stroke: '#fff',
                                            strokeWidth: '6',
                                        }}
                                    />
                                </Box>
                            </Box>
                            <AssessmentCardText variant="h5" color="text.secondary">
                                Total Active Motion
                            </AssessmentCardText>
                        </AssessmentListItem>
                        <AssessmentListItem item xs={12}>
                            <Box>
                                <Box
                                    style={{
                                        width: 50,
                                        height: 50,
                                        background: '#508bf2',
                                        border: '5px solid #daebfe',
                                        borderRadius: 50,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#fff',
                                    }}
                                >
                                    <DemoHandFlexionIcon
                                        width={25}
                                        style={{
                                            color: '#fff',
                                            fontSize: '28px',
                                            stroke: '#fff',
                                            strokeWidth: '6',
                                        }}
                                    />
                                </Box>
                            </Box>
                            <AssessmentCardText variant="h5" color="text.secondary">
                                Wrist Flexion/Extension
                            </AssessmentCardText>
                        </AssessmentListItem>
                        {/* <AssessmentListItem item xs={12}>
                            <Box>
                                <Box
                                    style={{
                                        width: 50,
                                        height: 50,
                                        background: '#508bf2',
                                        border: '5px solid #daebfe',
                                        borderRadius: 50,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#fff',
                                    }}
                                >
                                    <DemoHandFlexionIcon
                                        width={25}
                                        style={{
                                            color: '#fff',
                                            fontSize: '28px',
                                            stroke: '#fff',
                                            strokeWidth: '6',
                                        }}
                                    />
                                </Box>
                            </Box>
                            <AssessmentCardText variant="h5" color="text.secondary">
                                Wrist Extension
                            </AssessmentCardText>
                        </AssessmentListItem> */}
                        <AssessmentListItem item xs={12}>
                            <Box>
                                <Box
                                    style={{
                                        width: 50,
                                        height: 50,
                                        background: '#508bf2',
                                        border: '5px solid #daebfe',
                                        borderRadius: 50,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#fff',
                                    }}
                                >
                                    <DemoHandSupinationIcon
                                        width={25}
                                        style={{
                                            color: '#fff',
                                            fontSize: '28px',
                                            stroke: '#fff',
                                            strokeWidth: '6',
                                        }}
                                    />
                                </Box>
                            </Box>
                            <AssessmentCardText variant="h5" color="text.secondary">
                                Wrist Pronation/Supination
                            </AssessmentCardText>
                        </AssessmentListItem>
                        {/* <AssessmentListItem item xs={12}>
                            <Box>
                                <Box
                                    style={{
                                        width: 50,
                                        height: 50,
                                        background: '#508bf2',
                                        border: '5px solid #daebfe',
                                        borderRadius: 50,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#fff',
                                    }}
                                >
                                    <DemoHandSupinationIcon
                                        width={25}
                                        style={{
                                            color: '#fff',
                                            fontSize: '28px',
                                            stroke: '#fff',
                                            strokeWidth: '6',
                                        }}
                                    />
                                </Box>
                            </Box>
                            <AssessmentCardText variant="h5" color="text.secondary">
                                Wrist Supination
                            </AssessmentCardText>
                        </AssessmentListItem> */}
                        <AssessmentListItem item xs={12}>
                            <Box>
                                <Box
                                    style={{
                                        width: 50,
                                        height: 50,
                                        background: '#508bf2',
                                        border: '5px solid #daebfe',
                                        borderRadius: 50,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#fff',
                                    }}
                                >
                                    <DemoKapandjiIcon
                                        width={25}
                                        style={{
                                            color: '#fff',
                                            fontSize: '28px',
                                            stroke: '#fff',
                                            strokeWidth: '6',
                                        }}
                                    />
                                </Box>
                            </Box>
                            <AssessmentCardText variant="h5" color="text.secondary">
                                Kapandji Thumb Score
                            </AssessmentCardText>
                        </AssessmentListItem>
                    </Grid>
                </ModuleCardContent>
            </ModuleCard>
        </>
    );
};
