import React from 'react';
import { useTable } from 'react-table';
import { Products } from '@/common/const';
import { ListControlsContainer, PatientsTableWrapper } from '@/components/common/Table/PatientsTable/styles';
import {
    TableWrapper,
    TableHeader,
    TableCell,
    FullWidthTable,
    TableHeaderCell,
} from '@/components/common/Table/styles';
import { AddOrganizationContainer } from '../AddOrganization/AddOrganizationContainer';

interface Props {
    columnStructure: any[];
    data: any[];
    product?: Products;
    allowAddOrgs?: boolean;
}
export const OrganizationsTable = (props: Props) => {
    const { columnStructure, data, allowAddOrgs } = props;

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns: columnStructure,
        data,
    });

    return (
        <PatientsTableWrapper>
            {allowAddOrgs && (
                <ListControlsContainer>
                    <div>{'\u00a0'}</div>
                    <AddOrganizationContainer />
                </ListControlsContainer>
            )}
            <TableWrapper>
                <FullWidthTable {...getTableProps()}>
                    <TableHeader>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                {headerGroup.headers.map((column) => {
                                    return (
                                        <TableHeaderCell key={column.id} $isSorted={false}>
                                            {column.render('Header')}
                                        </TableHeaderCell>
                                    );
                                })}
                            </tr>
                        ))}
                    </TableHeader>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={row.original.patientRecord?.id}>
                                    {row.cells.map((cell, index) => {
                                        return (
                                            <TableCell
                                                {...cell.getCellProps()}
                                                style={{
                                                    width: cell.column.width,
                                                    maxWidth: cell.column.width,
                                                    minWidth: cell.column.width,
                                                }}
                                                key={cell.column.id}
                                            >
                                                {cell.render('Cell')}
                                            </TableCell>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </FullWidthTable>
            </TableWrapper>
        </PatientsTableWrapper>
    );
};
