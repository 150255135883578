import { styled, Grid, Theme, Tooltip } from '@mui/material';

export const HEPGridContainer = styled(Grid)({
    alignItems: 'center',
});

export const HEPBlockWrapper = styled('div')<{ theme: Theme; $snapshot?: boolean }>(({ theme, $snapshot }) => ({
    backgroundColor: $snapshot ? theme.palette.grey[50] : theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    '&:hover': {
        backgroundColor: theme.palette.grey[50],
    },
}));

export const HEPBlockDetailsContainer = styled('div')(({ theme }) => ({
    paddingLeft: theme.spacing(0.5),
}));

export const HEPBlockDetails = styled('div')(({ theme }) => ({
    fontSize: 12,
    marginTop: theme.spacing(0.5),
    color: theme.palette.grey[500],
}));

export const HEPBlockDragWrapper = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0',
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.grey[500],
}));

export const DragToolTip = styled(Tooltip)(({ theme }) => ({
    '&.MuiTooltip-popper': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary[500],
    },
}));

export const HEPBlockDragContentWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& p': {
        margin: 0,
    },
    '& img': {
        width: theme.spacing(7),
        marginRight: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
    },
}));
