import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { TemplateOrHEP } from '@/common/types';
import { HEPBuilderModal } from '@/components/Health/HEPBuilder/HepBuilderModal/HEPBuilderModal';
import { BuilderActions } from '@/components/Health/HEPBuilder/state/HEPBuilderContext';

export const AddHEPAction = () => {
    const [searchParams] = useSearchParams();
    const [dialogOpen, setDialogOpen] = useState(searchParams.get('add') === 'true' ? true : false);

    const handleClick = () => {
        setDialogOpen(true);
    };

    return (
        <>
            <Button
                onClick={handleClick}
                variant="primary"
                startIcon={<AddIcon />}
                size="small"
                data-cy="create-program-template-button"
            >
                New Template
            </Button>
            <HEPBuilderModal
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                actionType={BuilderActions.CREATE_TEMPLATE}
                isTemplateOrHEP={TemplateOrHEP.TEMPLATE}
            />
        </>
    );
};
