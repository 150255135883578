import React from 'react';
import { SvgIcon } from '@mui/material';

export const CopyIcon = (props) => {
    return (
        <>
            <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} style={{ fill: 'none' }}>
                <path
                    d="M10.5 10.5H13.5V2.50005H5.5V5.50005"
                    stroke="currentcolor"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.4999 5.50012H2.49994V13.5001H10.4999V5.50012Z"
                    stroke="currentcolor"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </SvgIcon>
        </>
    );
};
