import React, { Suspense } from 'react';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { RouteContentWrapper } from '../../styles';

const InsightsDashboardContainer = React.lazy(
    () =>
        import(
            /* webpackChunkName: "Insights" */
            /* webpackPrefetch: true */ '@/components/Insights/InsightsDashboardContainer'
        ),
);

export const AdminInsightsRoute = () => {
    return (
        <RouteContentWrapper>
            <PageTitle title="Insights" />
            <Suspense fallback={<div>Loading...</div>}>
                <InsightsDashboardContainer />
            </Suspense>
        </RouteContentWrapper>
    );
};
