import React, { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import { Select, MenuItem, SelectChangeEvent, TextField, InputLabel } from '@mui/material';
import { Maybe } from '@/common/types';
import { FormFieldContainer } from '@/components/common/Form/styles';
import { Button } from '@/components/common/button/button';
import { CriteriaCategory } from '../types/CriteriaCategory.enum';
import { Sex } from '../types/Sex.enum';
import { ParticipantDemographics } from '../types/types';
import {
    OutcomeDemographicButtonWrapper,
    OutcomeDemographicInputWrapper,
    OutcomeDemographicFormWrapper,
    OutcomeDemographicFormHeader,
} from './styles';

interface OutcomeDemographicFormProps {
    setDemographics: Dispatch<SetStateAction<Maybe<ParticipantDemographics>>>;
    demographicsRequired: CriteriaCategory[];
}

export const OutcomeDemographicForm: FC<OutcomeDemographicFormProps> = ({ setDemographics, demographicsRequired }) => {
    const [age, setAge] = useState<Maybe<number>>(demographicsRequired.includes(CriteriaCategory.AGE) ? 65 : undefined);
    const [sex, setSex] = useState<Maybe<Sex>>(
        demographicsRequired.includes(CriteriaCategory.SEX) ? Sex.MALE : undefined,
    );

    const handleSubmit = () => {
        setDemographics({
            age: age ? { value: age, type: CriteriaCategory.AGE } : undefined,
            sex: sex ? { value: sex, type: CriteriaCategory.SEX } : undefined,
        });
    };

    const handleAgeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAge(+e.target.value);
    };

    const handleSexChange = (e: SelectChangeEvent<Sex>) => {
        // TODO unknown
        setSex(e.target.value as unknown as Sex);
    };

    return (
        <OutcomeDemographicFormWrapper>
            <OutcomeDemographicFormHeader>
                Demographic data is required to assess Fall Risk
            </OutcomeDemographicFormHeader>
            {demographicsRequired.includes(CriteriaCategory.SEX) && (
                <OutcomeDemographicInputWrapper>
                    <FormFieldContainer>
                        <Select
                            labelId="sex-select-label"
                            id="sex-select"
                            value={sex}
                            label="Sex"
                            onChange={handleSexChange}
                            sx={{ width: '100%' }}
                        >
                            <InputLabel id="sex-select-label">Sex</InputLabel>
                            <MenuItem key={Sex.MALE} value={Sex.MALE}>
                                Male
                            </MenuItem>
                            <MenuItem key={Sex.FEMALE} value={Sex.FEMALE}>
                                Female
                            </MenuItem>
                        </Select>
                    </FormFieldContainer>
                </OutcomeDemographicInputWrapper>
            )}
            <br />
            {demographicsRequired.includes(CriteriaCategory.AGE) && (
                <OutcomeDemographicInputWrapper>
                    <FormFieldContainer>
                        <TextField
                            value={age?.toString()}
                            id={'age'}
                            label={'Age'}
                            variant="outlined"
                            type={'number'}
                            onChange={handleAgeChange}
                        />
                    </FormFieldContainer>
                </OutcomeDemographicInputWrapper>
            )}
            <FormFieldContainer>
                <OutcomeDemographicButtonWrapper>
                    <Button type="submit" onClick={handleSubmit}>
                        Submit
                    </Button>
                </OutcomeDemographicButtonWrapper>
            </FormFieldContainer>
        </OutcomeDemographicFormWrapper>
    );
};
