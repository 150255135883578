import React from 'react';
import { Grid } from '@mui/material';
import { PractitionersListHeaderWrapper } from './styles';

export const PractitionersListHeader = () => {
    return (
        <PractitionersListHeaderWrapper container spacing={2}>
            <Grid item xs={3} lg={3} xl={3}>
                <strong>Name</strong>
            </Grid>
            <Grid item xs={3} lg={4} xl={4}>
                <strong>Email</strong>
            </Grid>
            <Grid item xs={2} lg={1.5}>
                <strong>Role</strong>
            </Grid>
            <Grid item xs={2} lg={3}>
                <strong>Location</strong>
            </Grid>
        </PractitionersListHeaderWrapper>
    );
};
