import React, { FC } from 'react';
import { OutcomeBoxData } from '../types/types';
import {
    OutcomeResultValue,
    OutcomeBoxWrapper,
    OutcomeFactorLabel,
    OutcomeResultName,
    OutcomeResultUnit,
    OutcomeResultUnitWrapper,
} from './styles';

interface OutcomeProps {
    outcomeBoxData: OutcomeBoxData;
}

export const OutcomeBox: FC<OutcomeProps> = ({ outcomeBoxData }) => {
    const { factorResultCategory, name, value, units, label } = outcomeBoxData;

    return (
        <OutcomeBoxWrapper $factorCategory={factorResultCategory}>
            <OutcomeResultName>{name}</OutcomeResultName>
            <OutcomeResultValue>
                <div>{value}</div>
                <OutcomeResultUnitWrapper $unit={units}>
                    <OutcomeResultUnit>{units}</OutcomeResultUnit>
                </OutcomeResultUnitWrapper>
            </OutcomeResultValue>
            <OutcomeFactorLabel>{label}</OutcomeFactorLabel>
        </OutcomeBoxWrapper>
    );
};
