import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    FormControlLabel,
    TextField,
    Autocomplete,
    FormControl,
    FormLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller, Control, FieldErrors, FieldValues, Path } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Nullish } from '@/common/types';
import { Button } from '../button/button';
import {
    SaveSaveAnotherCancelButtonContainer,
    FormFieldContainer,
    UpdateButtonContainer,
    FormButtonContainer,
    FormHelperText,
} from './styles';

interface FormTextFieldProps<T extends FieldValues> {
    control: Control<T>;
    errors: FieldErrors<T>;
    name: Path<T>;
    label: string;
    required?: boolean;
    disabled?: boolean;
    multiline?: boolean;
    type?: string;
    [x: string]: any;
}

export const FormTextField = <T extends FieldValues>({
    control,
    errors,
    name,
    label,
    required,
    disabled,
    multiline,
    type,
    ...rest
}: FormTextFieldProps<T>) => (
    <FormFieldContainer>
        <Controller
            control={control}
            name={name}
            rules={{ required: required }}
            render={({ field: { onChange, value } }) => (
                <TextField
                    required={required}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    id={String(name)}
                    label={label}
                    variant="outlined"
                    error={!!errors[name]}
                    helperText={errors[name]?.message?.toString() || '\u00a0'}
                    multiline={multiline}
                    type={type}
                    {...rest}
                />
            )}
        />
    </FormFieldContainer>
);

interface FormPasswordFieldProps<T extends FieldValues> {
    control: Control<T>;
    errors: FieldErrors<T>;
    name: Path<T>;
    label: string;
    required?: boolean;
    disabled?: boolean;
}

export const FormPasswordField = <T extends FieldValues>({
    control,
    errors,
    name,
    label,
    required,
    disabled,
}: FormPasswordFieldProps<T>) => (
    <FormFieldContainer>
        <Controller
            control={control}
            name={name}
            rules={{ required: required }}
            render={({ field: { onChange, value } }) => (
                <TextField
                    type="password"
                    required={required}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    id={String(name)}
                    label={label}
                    variant="outlined"
                    error={!!errors[name]}
                    helperText={errors[name]?.message?.toString() || '\u00a0'}
                />
            )}
        />
    </FormFieldContainer>
);

interface FormDateFieldProps<T extends FieldValues> {
    control: Control<T>;
    errors: FieldErrors<T>;
    name: Path<T>;
    label: string;
    required?: boolean;
    noFuture?: boolean;
    disabled?: boolean;
    small?: boolean;
}

export const FormDateField = <T extends FieldValues>({
    control,
    errors,
    name,
    label,
    required,
    noFuture,
    disabled,
    small,
}: FormDateFieldProps<T>) => (
    <FormFieldContainer>
        <Controller
            control={control}
            name={name}
            rules={{ required: required }}
            render={({ field: { onChange, value } }) => (
                <>
                    <DatePicker
                        label={label}
                        value={value}
                        onChange={onChange}
                        disableFuture={!!noFuture}
                        disabled={disabled}
                        slotProps={small ? { textField: { size: 'small' } } : {}}
                    />
                    <FormHelperText>{errors[name]?.message?.toString() || '\u00a0'}</FormHelperText>
                </>
            )}
        />
    </FormFieldContainer>
);

interface FormPhoneNumberFieldProps<T extends FieldValues> {
    control: Control<T>;
    errors: FieldErrors<T>;
    name: Path<T>;
    label: string;
    required?: boolean;
}

export const FormPhoneNumberField = <T extends FieldValues>({
    control,
    errors,
    name,
    label,
    required,
}: FormPhoneNumberFieldProps<T>) => (
    <FormFieldContainer>
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <InputMask mask="(999)999-9999" maskChar={null} value={value} onChange={onChange}>
                    {(inputProps) => (
                        <TextField
                            id={String(name)}
                            required={required}
                            value={value}
                            label={label}
                            variant="outlined"
                            error={!!errors[name]}
                            helperText={errors[name]?.message?.toString() || '\u00a0'}
                        />
                    )}
                </InputMask>
            )}
        />
    </FormFieldContainer>
);

interface FormAutoCompleteFieldProps<T extends FieldValues> {
    control: Control<T>;
    errors: FieldErrors<T>;
    name: Path<T>;
    label: string;
    required?: boolean;
    options: any[];
    optionLabel: (option: any) => string;
    clearIcon?: Nullish<boolean>;
}

export const FormAutoCompleteField = <T extends FieldValues>({
    control,
    errors,
    name,
    label,
    required,
    options,
    optionLabel,
    clearIcon,
}: FormAutoCompleteFieldProps<T>) => {
    const optionalProps: { [key: string]: unknown } = {};
    if (clearIcon !== undefined) {
        optionalProps.clearIcon = null;
    }
    return (
        <FormFieldContainer>
            <Controller
                control={control}
                name={name}
                rules={{ required: required }}
                render={({ field: { onChange, value } }) => (
                    <Autocomplete
                        onChange={(event, item) => onChange(item || '')}
                        value={value}
                        options={options}
                        getOptionLabel={optionLabel}
                        isOptionEqualToValue={(option, value) =>
                            value === undefined || value === '' || option.id === value.id
                        }
                        {...optionalProps}
                        renderInput={(params) => (
                            <>
                                <TextField
                                    {...params}
                                    id={String(name)}
                                    label={label}
                                    required={required}
                                    margin="none"
                                    variant="outlined"
                                    error={!!errors[name]}
                                    helperText={errors[name]?.message?.toString() || `${label} required`}
                                />
                                {!errors[name]?.message ? <p> </p> : null}
                            </>
                        )}
                    />
                )}
            />
        </FormFieldContainer>
    );
};

interface FormRadioFieldProps<T extends FieldValues> {
    control: Control<T>;
    name: Path<T>;
    label: string;
    required?: boolean;
    choices: { value: string; label: string }[];
    errors: FieldErrors<T>;
}

export const FormRadioField = <T extends FieldValues>({
    control,
    name,
    label,
    required,
    choices,
    errors,
}: FormRadioFieldProps<T>) => (
    <>
        <FormFieldContainer>
            <Controller
                control={control}
                name={name}
                rules={{ required: required }}
                render={({ field }) => (
                    <FormControl component="fieldset">
                        <FormLabel component="legend">
                            {label}
                            {required && <sup>*</sup>}
                        </FormLabel>
                        <RadioGroup row aria-label={label} {...field}>
                            {choices.map((choice) => (
                                <FormControlLabel
                                    value={choice.value}
                                    control={<Radio />}
                                    label={choice.label}
                                    key={choice.label}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                )}
            />
            <FormHelperText>{errors[name]?.message?.toString() || '\u00a0'}</FormHelperText>
        </FormFieldContainer>
    </>
);

interface SaveButtonGroupProps {
    saveFunction: (event: React.MouseEvent<HTMLButtonElement>) => void;
    saveAndAddAnotherFunction: () => void;
    cancelFunction: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
}

export const SaveSaveAnotherCancelButtons = ({
    saveFunction,
    saveAndAddAnotherFunction,
    cancelFunction,
    disabled,
}: SaveButtonGroupProps) => (
    <SaveSaveAnotherCancelButtonContainer>
        <Box>
            <Button variant="secondary" type="button" onClick={cancelFunction} size="small">
                Cancel
            </Button>
        </Box>
        <Box>
            <Button
                type="button"
                onClick={saveAndAddAnotherFunction}
                variant="primary"
                size="small"
                disabled={disabled}
            >
                Save and Add Another
            </Button>
            <Button
                type="submit"
                onClick={saveFunction}
                dataCy="submit"
                variant="primary"
                size="small"
                disabled={disabled}
            >
                Save
            </Button>
        </Box>
    </SaveSaveAnotherCancelButtonContainer>
);

interface UpdateButtonGroupProps {
    updateFunction: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const UpdateButton = ({ updateFunction }: UpdateButtonGroupProps) => (
    <UpdateButtonContainer>
        <Button type="submit" onClick={updateFunction} dataCy="submit" variant="primary">
            Update
        </Button>
    </UpdateButtonContainer>
);

interface SubmitCancelButtonsProps {
    submitFunction: (event: React.MouseEvent<HTMLButtonElement>) => void;
    cancelFunction: (event: React.MouseEvent<HTMLButtonElement>) => void;
    submitText?: string;
    cancelText?: string;
    submitDisabled?: boolean;
    loading?: boolean;
    size?: 'small' | 'medium' | 'large';
}

export const SubmitCancelButtons = ({
    submitFunction,
    cancelFunction,
    submitText,
    cancelText,
    submitDisabled,
    size,
    loading,
}: SubmitCancelButtonsProps) => (
    <FormButtonContainer>
        <Box>
            <Button variant="tertiary" type="button" onClick={cancelFunction} size={size}>
                {cancelText || 'Cancel'}
            </Button>
        </Box>
        <Box>
            <LoadingButton
                onClick={submitFunction}
                data-cy="submit"
                variant="primary"
                disabled={submitDisabled}
                size={size}
                loading={loading}
            >
                {submitText || `Save`}
            </LoadingButton>
        </Box>
    </FormButtonContainer>
);

interface CloseButtonProps {
    closeFunction: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const CloseButton = ({ closeFunction }: CloseButtonProps) => (
    <FormButtonContainer>
        <Box />
        <Box>
            <Button type="submit" onClick={closeFunction} dataCy="close" variant="primary">
                Close
            </Button>
        </Box>
    </FormButtonContainer>
);
