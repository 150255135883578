import React from 'react';
import { Box, Grid } from '@mui/material';
import AssesmentIcon from '@/images/Assesment.png';
import FistIcon from '@/images/Fist.png';
import STSIcon from '@/images/SitToStandRom.png';
import TimerIcon from '@/images/Timer.png';
import { AssessmentListItem, AssessmentCardText, ModuleCard, ModuleCardHeader, ModuleCardContent } from '../styles';

export const MobilityCard = () => {
    return (
        <>
            <ModuleCard>
                <ModuleCardHeader
                    avatar={<img src={AssesmentIcon} alt="" width={60} />}
                    title="Mobility"
                    titleTypographyProps={{
                        fontSize: 24,
                        fontWeight: '600',
                        // responsiveFontSizes,
                        // sx: {
                        //     responsiveFontSizes: {
                        //         m
                        //     }
                        // },
                    }}
                    subheader="Evaluate overall mobility and balance through dynamic assessments."
                />
                <ModuleCardContent>
                    <Grid container spacing={2}>
                        <AssessmentListItem item xs={12}>
                            <Box>
                                <img src={STSIcon} width={50} />
                            </Box>
                            <AssessmentCardText variant="h5" color="text.secondary">
                                Sit to Stand (Front Facing)
                            </AssessmentCardText>
                        </AssessmentListItem>
                        <AssessmentListItem item xs={12}>
                            <Box>
                                <img src={TimerIcon} width={50} />
                            </Box>
                            <AssessmentCardText variant="h5" color="text.secondary">
                                Timed Up and Go (Front Facing)
                            </AssessmentCardText>
                        </AssessmentListItem>
                        <AssessmentListItem item xs={12}>
                            <Box>
                                <img src={FistIcon} width={50} />
                            </Box>
                            <AssessmentCardText variant="h5" color="text.secondary">
                                Functional Reach (Bilateral)
                            </AssessmentCardText>
                        </AssessmentListItem>
                        <AssessmentListItem item xs={12}>
                            <Box>
                                <img src={STSIcon} width={50} />
                            </Box>
                            <AssessmentCardText variant="h5" color="text.secondary">
                                Sit to Stand (Side Facing)
                            </AssessmentCardText>
                        </AssessmentListItem>
                        <AssessmentListItem item xs={12}>
                            <Box>
                                <img src={TimerIcon} width={50} />
                            </Box>
                            <AssessmentCardText variant="h5" color="text.secondary">
                                Timed Up & Go (Side Facing)
                            </AssessmentCardText>
                        </AssessmentListItem>
                    </Grid>
                </ModuleCardContent>
            </ModuleCard>
        </>
    );
};
