import React from 'react';
import { SliderValueLabelProps } from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import { TimeGoal } from '@/common/types';

interface SliderToolTipProps {
    label: SliderValueLabelProps;
    timeIsOverMax: TimeGoal;
    max: number;
}

export const SliderToolTip = ({ label, timeIsOverMax, max }: SliderToolTipProps) => {
    const { children, value, open } = label;

    return (
        <Tooltip
            enterTouchDelay={0}
            placement="top"
            open={timeIsOverMax.time > max ? true : open}
            title={
                timeIsOverMax.time > max ? (
                    <>
                        <span style={{ fontSize: '20px', lineHeight: 1 }}>+</span>
                    </>
                ) : (
                    `${value}s`
                )
            }
        >
            {children}
        </Tooltip>
    );
};
