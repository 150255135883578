import React from 'react';
import { Grid } from '@mui/material';
import { useMuiUtils } from '@/common/hooks/MuiUtils';
import { PatientChartRadioGroup } from '@/components/Health/PatientSessions/Charts/HealthRadioGroup';
import { PatientChart } from '@/components/Health/PatientSessions/Charts/PatientChart';
import { EmptyData } from '@/components/common/EmptyData/EmptyData';
import { TabContentGrid } from '@/components/common/Tabs/styles';
import { DateRangeAtomState } from '@/recoil/atoms/dateRangeAtom';
import { ROMDataDict } from '../../PatientSessions/Charts/types';
import { ROMAccordion } from '../ROMAccordion/ROMAccordion';
import { TabPanel } from './TabPanel';

interface ROMTabProps {
    currentTab: number;
    dateRange: DateRangeAtomState;
    hasSessions: () => boolean;
    romDictDataFiltered: ROMDataDict | undefined;
    handleChartSelection: ({ optionName, optionId }: { optionName: string; optionId: string }) => void;
    currentChart: string;
}

export const ROMTab = (props: ROMTabProps) => {
    const { currentTab, dateRange, hasSessions, romDictDataFiltered, handleChartSelection, currentChart } = props;
    const { largeBreakpoint } = useMuiUtils();

    return (
        <TabPanel currentTab={currentTab} index={1} ariaLabel="rom charts">
            {hasSessions() && romDictDataFiltered && Object.keys(romDictDataFiltered).length > 0 ? (
                <>
                    <TabContentGrid container spacing={4}>
                        <Grid item xs={12} lg={4}>
                            {largeBreakpoint ? (
                                <PatientChartRadioGroup
                                    options={romDictDataFiltered}
                                    title="ROM EXERCISE LIST"
                                    handleChange={handleChartSelection}
                                    selectedOption={currentChart}
                                />
                            ) : (
                                <ROMAccordion options={romDictDataFiltered} handleChange={handleChartSelection} />
                            )}
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <PatientChart
                                sessions={romDictDataFiltered}
                                currentChart={currentChart}
                                timeSpan={dateRange}
                            />
                        </Grid>
                    </TabContentGrid>
                </>
            ) : (
                <EmptyData
                    title="No patient data available"
                    body="Your patient’s progress will appear here when they complete a range of motion exercise. "
                />
            )}
        </TabPanel>
    );
};
