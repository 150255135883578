import React, { MouseEvent, TouchEvent } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { HealthPatientStatus } from '@/common/const';
import { PatientRecord } from '@/common/types';
import { getStringEnumKeyByValue } from '@/common/utils';
import { AddIcon } from '@/components/common/Icons/AddIcon';

interface Props {
    patientRecord: PatientRecord;
    handleShowDialog: (event: MouseEvent | TouchEvent) => void;
}

export const AssignHEPAction = (props: Props) => {
    const { patientRecord, handleShowDialog } = props;

    const shouldAssign =
        patientRecord.productData?.health?.status ===
        getStringEnumKeyByValue(HealthPatientStatus, HealthPatientStatus.NO_ASSIGNED_HEP);

    return shouldAssign ? (
        <>
            <MenuItem onClick={handleShowDialog}>
                <ListItemIcon>
                    <AddIcon />
                </ListItemIcon>
                <ListItemText>Assign Program</ListItemText>
            </MenuItem>
        </>
    ) : null;
};
