import React from 'react';
import { HealthPatientStatus } from '@/common/const';
import { PatientStatusBadgeWrapper } from './styles';

interface PatientStatusBadgeProps {
    status: string;
}

export const PatientStatusBadge = (props: PatientStatusBadgeProps) => {
    const { status } = props;

    return status ? <PatientStatusBadgeWrapper label={HealthPatientStatus[status]} size="small" /> : null;
};
