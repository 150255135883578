import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Typography, Box, styled } from '@mui/material';
import { WeightIcon } from '@/components/common/Icons/WeightIcon';
import { ArrowDownIcon } from './ArrowDown';

export const PhaseContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));

export const HEPExerciseTotalContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'self-end',
    marginLeft: theme.spacing(0.25),
    marginTop: theme.spacing(0.5),
}));

export const HEPName = styled(Typography)(({ theme }) => ({
    fontSize: '1.3rem',
}));

export const PhaseNumber = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[700],
    fontSize: '.8rem',
    textTransform: 'uppercase',
}));

export const HEPExerciseTotal = styled(Typography)(({ theme }) => ({
    textTransform: 'uppercase',
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[700],
    fontSize: theme.typography.caption.fontSize,
}));

export const DashboardTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[900],
    marginBottom: theme.spacing(4),
    fontWeight: theme.typography.fontWeightMedium,
}));

export const Dumbbell = styled(WeightIcon)(({ theme }) => ({
    color: theme.palette.grey[700],
}));

export const PhaseIconContainer = styled('div')<{ $isLast?: boolean }>(({ theme, $isLast }) => ({
    marginRight: theme.spacing(2),
    position: 'relative',
    display: 'block',

    '&:before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        bottom: '0',
        left: '50%',
        width: '1px',
        height: 'calc(100% - 52px)',
        background: theme.palette.primary[500],
    },
}));

export const TooltipProgress = styled(Box)<{ $percent: string }>(({ theme, $percent }) => ({
    background: `radial-gradient(closest-side, white 29%, transparent 80% 100%), conic-gradient(${theme.palette.primary[500]} ${$percent}%, ${theme.palette.primary[300]} 0)`,
    padding: 2,
    borderRadius: '50%',
}));

export const ActiveIcon = styled(TaskAltIcon)(({ theme }) => ({
    color: theme.palette.primary[500],
    fontSize: 24,
}));

export const InactiveIcon = styled(RadioButtonUncheckedIcon)(({ theme }) => ({
    color: theme.palette.grey[700],
    fontSize: 24,
}));

export const ArrowDown = styled(ArrowDownIcon)(({ theme }) => ({
    color: theme.palette.grey[700],
    fontSize: 24,
}));

export const PhaseBodyContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
}));

export const BodyTextContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',

    svg: {
        marginRight: theme.spacing(0.75),
        fontSize: '18px',
    },
}));

export const BodyText = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    margin: 0,
}));

export const BodyOrText = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[700],
    fontSize: 14,
    textTransform: 'uppercase',
    margin: 4,
    marginLeft: theme.spacing(3.25),
}));

export const TargetMetric = styled('span')(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
}));
