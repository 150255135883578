import { Menu, styled } from '@mui/material';
import { ControlLabel } from '@/components/common/ControlLabel/ControlLabel';
import { Modal } from '@/components/common/Modal/Modal';

export const SessionsReportModal = styled(Modal)(({ theme }) => ({
    '.MuiDialog-container': {
        height: 'auto',
    },
    '.MuiPaper-root,.MuiDialogContent-root': {
        overflowY: 'visible',
    },
}));

export const SessionsReportError = styled('div')<{ $isActive: boolean }>(({ theme, $isActive }) => ({
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
}));

export const SessionsReportFormWrapper = styled('form')(({ theme }) => ({
    padding: theme.spacing(2),
    position: 'relative',
}));

export const SessionsReportControlLabel = styled(ControlLabel)(({ theme }) => ({
    padding: 0,
    borderBottom: 'none',
    marginRight: theme.spacing(2),

    '&:hover': {
        background: 'none',
    },
}));

export const DateWrapper = styled(Menu)(({ theme }) => ({
    '.MuiPaper-root': {
        marginTop: 0,
    },
    '.MuiList-root': {
        padding: '0',
    },
}));

export const SessionReportLabel = styled('p')(({ theme }) => ({
    fontSize: 12,
    marginBottom: 4,
}));

export const SessionsReportDownloadButton = styled('a')<{ $isActive?: boolean }>(({ theme, $isActive }) => ({
    pointerEvents: $isActive ? 'auto' : 'none',
    textDecoration: 'none',
}));
