import React from 'react';
import { OrganizationsTableContainer } from '@/components/Organization/OrganizationsTable/OrganizationsTableContainer';
import { RouteContentWrapper } from '../../styles';

export const AdminOrganizationsRoute = () => {
    return (
        <RouteContentWrapper>
            <OrganizationsTableContainer />
        </RouteContentWrapper>
    );
};
