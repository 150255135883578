import React from 'react';
import { SvgIcon } from '@mui/material';

export const SliderArrowDownIcon = (props) => {
    return (
        <>
            <SvgIcon width="24" height="21" viewBox="0 0 24 21" fill="none" {...props}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21" fill="none">
                    <path
                        d="M13.7323 20C12.9625 21.3333 11.038 21.3333 10.2682 20L0.741913 3.5C-0.0278873 2.16667 0.934364 0.5 2.47396 0.5H21.5265C23.0661 0.5 24.0284 2.16667 23.2586 3.5L13.7323 20Z"
                        fill="currentColor"
                    />
                </svg>
            </SvgIcon>
        </>
    );
};
