import React, { MouseEvent, useRef, useState } from 'react';
import { Typography, Grid, IconButton, Stack, Divider, Tooltip, Slide } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { RTMItem } from '@/common/types';
import { DownloadIcon } from '@/components/common/Icons/DownloadIcon';
import { resultIsError } from '@/services/HttpService';
import { Accordion } from './Accordion';
import { RTMMonthlySummaryRow } from './RTMMonthlySummaryRow';
import { StatusChip } from './StatusChip';
import {
    AccordionName,
    AccordionDetails,
    StatusGridWrapper,
    ActionGridWrapper,
    StatusAnimationInnerWrapper,
} from './styles';

export const RTMAccordion = (props: RTMItem) => {
    const { patientRecord, status, cycles, id, pdfKey, yearMonth } = props;
    const pdfAnchorRef = useRef<HTMLAnchorElement>(null);
    const [expanded, setExpanded] = useState<string | false>(`${patientRecord.id + yearMonth}`);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [pdfDownloadURL, setPDFDownloadURL] = useState<string>();
    const { httpService } = useHttpContext();

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        if (!hasLoaded) setHasLoaded(true);
        setExpanded(isExpanded ? panel : false);
    };

    const getSetPdfUrl = async () => {
        if (pdfKey) {
            const res = await httpService.getMonthlyPatientActivityPdf(id);
            if (resultIsError(res)) {
                Sentry.captureException('Failed to get pdf url on rtm monthly summary with pdf key');
                return;
            }
            setPDFDownloadURL(res.pdfUrl);
        }
    };

    const handleDownloadMouseOver = async () => {
        await getSetPdfUrl();
    };

    const handleDownloadClick = async (e: MouseEvent<HTMLAnchorElement>) => {
        if (pdfKey) {
            e.stopPropagation();
            if (!pdfDownloadURL) {
                await getSetPdfUrl();
            }
            pdfAnchorRef.current?.click();
        } else {
            e.preventDefault();
        }
    };

    return (
        <Accordion
            expanded={expanded === `${patientRecord.id + yearMonth}`}
            onChange={handleChange(`${patientRecord.id + yearMonth}`)}
        >
            <AccordionName
                aria-controls={`${patientRecord.firstName} ${patientRecord.lastName} billing accordion`}
                id={`${patientRecord.id + yearMonth}`}
            >
                <Grid container style={{ alignItems: 'center' }}>
                    <Grid xs={7} item>
                        <Typography>
                            {patientRecord.firstName} {patientRecord.lastName}
                        </Typography>
                    </Grid>
                    <StatusGridWrapper xs={2} item>
                        {expanded !== `${patientRecord.id + yearMonth}` && (
                            <Slide
                                direction={'up'}
                                in={expanded !== `${patientRecord.id}`}
                                mountOnEnter
                                unmountOnExit
                                appear={hasLoaded}
                            >
                                <StatusAnimationInnerWrapper>
                                    <StatusChip status={status} />
                                </StatusAnimationInnerWrapper>
                            </Slide>
                        )}
                    </StatusGridWrapper>
                    <ActionGridWrapper xs={3} item>
                        {pdfKey ? (
                            <Tooltip title="Download Monthly PDF">
                                <a
                                    ref={pdfAnchorRef}
                                    href={pdfDownloadURL || undefined}
                                    onMouseOver={handleDownloadMouseOver}
                                    onClick={handleDownloadClick}
                                    rel="noreferrer"
                                >
                                    <IconButton style={{ color: '#737580', fontSize: 20 }}>
                                        <DownloadIcon />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        ) : null}
                    </ActionGridWrapper>
                </Grid>
            </AccordionName>
            <AccordionDetails>
                <Stack spacing={0} divider={<Divider flexItem />}>
                    {cycles &&
                        cycles.map((cycle) => (
                            <RTMMonthlySummaryRow key={cycle.id} patientRecord={patientRecord} cycle={cycle} />
                        ))}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};
