import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { favoriteExercisesState } from '@/recoil/atoms/favoriteExercisesAtom';
import { resultIsError } from '@/services/HttpService';

export const Initialization = () => {
    const { user } = useExerUser();
    const { httpService } = useHttpContext();
    const setFavoriteExercises = useSetRecoilState(favoriteExercisesState);

    useEffect(() => {
        if (user.isAuthenticated) {
            (async () => {
                const res = await httpService.getFavoriteExercises();
                if (resultIsError(res)) {
                } else {
                    setFavoriteExercises(res);
                }
            })();
        }
    }, [user, setFavoriteExercises, httpService]);

    return null;
};
