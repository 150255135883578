import React from 'react';
import { Typography, Grid } from '@mui/material';
import { TemplateOrHEP } from '@/common/types';
import { characterCountWithLineBreaks, CreateIntegerArray } from '@/common/utils';
import { BuilderActions } from '@/components/Health/HEPBuilder/state/HEPBuilderContext';
import { TemplatesAccordion } from '@/components/Health/HEPBuilder/steps/StepInformation/TemplatesAccordion/TemplatesAccordion';
import { ToggleButton } from '@/components/common/ToggleButton/ToggleButton';
import { NOTES_MAX_LENGTH } from '../../state/HEPBuilderContext';
import { useHEPBuilderContext } from '../../state/hooks/HEPBuilderContext';
import {
    StepInformationWrapper,
    HEPInputWrapper,
    HEPTextFields,
    HEPTextField,
    HEPError,
    HEPNotesLength,
    StepInformationBody,
    StepInfoContentWrapper,
    TemplateMenuItemDescription,
} from './styles';

export const StepInformation = () => {
    const { patientRecord, hepDetails, hepBuilderActions, hepErrors, isTemplateOrHEP, actionType } =
        useHEPBuilderContext();

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        hepBuilderActions?.setName(e.target.value);
    };

    const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const notes =
            characterCountWithLineBreaks(e.target.value) > NOTES_MAX_LENGTH
                ? e.target.value.substring(0, NOTES_MAX_LENGTH)
                : e.target.value;
        hepBuilderActions?.setNotes(notes);
    };

    const handleDailyChange = (_: React.ChangeEvent, newValue: number) => {
        hepBuilderActions?.setDailyFrequency(newValue);
    };

    const handleWeeklyChange = (_: React.ChangeEvent, newValue: number) => {
        hepBuilderActions?.setWeeklyFrequency(newValue);
    };

    return (
        <StepInformationWrapper>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item sm={10} md={7} lg={6} xl={5}>
                    <StepInfoContentWrapper>
                        <Typography variant="h3" component="h1" fontWeight={700}>
                            {BuilderActions.EDIT_HEP === actionType ? 'Edit the ' : 'Build a '}
                            Program {isTemplateOrHEP === TemplateOrHEP.Program ? '' : 'Template'}
                            {patientRecord &&
                                `for ${patientRecord?.patient?.firstName} ${patientRecord?.patient?.lastName}`}
                        </Typography>
                        <StepInformationBody>
                            {BuilderActions.EDIT_HEP === actionType ? (
                                <>Update the details of the Program by editing the following information</>
                            ) : (
                                <>
                                    Create a new {isTemplateOrHEP === TemplateOrHEP.Program ? 'Program' : 'template'} by
                                    adding the following information.
                                </>
                            )}
                        </StepInformationBody>
                        {isTemplateOrHEP === TemplateOrHEP.Program && actionType !== BuilderActions.EDIT_HEP && (
                            <>
                                <TemplatesAccordion />
                                <TemplateMenuItemDescription>
                                    Or build a program from scratch
                                </TemplateMenuItemDescription>
                            </>
                        )}
                        <HEPTextFields>
                            <HEPTextField
                                required={true}
                                value={hepDetails.name || ''}
                                onChange={handleNameChange}
                                placeholder={`Name Your ${
                                    isTemplateOrHEP === TemplateOrHEP.Program ? 'Program' : 'Template'
                                }`}
                                id="program-name"
                                label={`${isTemplateOrHEP === TemplateOrHEP.Program ? 'Program' : 'Template'} Name`}
                                variant="outlined"
                                error={!!hepErrors?.name}
                            />
                            <HEPError>{hepErrors?.name || `\u00a0`}</HEPError>

                            <HEPTextField
                                multiline={true}
                                rows={4}
                                inputProps={{
                                    maxLength: NOTES_MAX_LENGTH,
                                }}
                                value={hepDetails.notes ? hepDetails.notes : ''}
                                onChange={handleNotesChange}
                                placeholder="Type something..."
                                id="program-name"
                                label="Program notes (optional)"
                                variant="outlined"
                                error={!!hepErrors?.notes}
                            />
                            <HEPError>
                                {hepErrors?.notes || `\u00a0`}{' '}
                                <HEPNotesLength
                                    $isMax={characterCountWithLineBreaks(hepDetails.notes || '') >= NOTES_MAX_LENGTH}
                                >
                                    {characterCountWithLineBreaks(hepDetails.notes || '')}/{NOTES_MAX_LENGTH.toString()}
                                </HEPNotesLength>
                            </HEPError>
                        </HEPTextFields>
                        <HEPInputWrapper>
                            <h4>Daily Frequency</h4>
                            <p>How many times a day the patient should complete the exercise.</p>
                            <ToggleButton
                                items={CreateIntegerArray(5)}
                                ariaLabel="daily frequency toggle button group"
                                defaultValue={hepDetails.dailyFrequency}
                                onChange={handleDailyChange}
                                enforce
                            />
                        </HEPInputWrapper>
                        <HEPInputWrapper>
                            <h4>Weekly Frequency</h4>
                            <p>How many times a week the patient should complete the exercise.</p>
                            <ToggleButton
                                items={CreateIntegerArray(7)}
                                ariaLabel="weekly frequency toggle button group"
                                defaultValue={hepDetails.weeklyFrequency}
                                onChange={handleWeeklyChange}
                                enforce
                            />
                        </HEPInputWrapper>
                    </StepInfoContentWrapper>
                </Grid>
            </Grid>
        </StepInformationWrapper>
    );
};
