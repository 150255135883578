import React from 'react';
import { Skeleton } from '@mui/material';
import { SkeletonLoader } from './styles';

export const ChartSkeletonLoader = () => {
    return (
        <SkeletonLoader spacing={1}>
            <Skeleton variant="text" width={70} height={18} />
            <Skeleton variant="rectangular" width="100%" height={40} />
            <Skeleton variant="text" width={70} height={18} />
            <Skeleton variant="rectangular" width="100%" height={40} />
            <Skeleton variant="text" width={70} height={18} />
            <Skeleton variant="rectangular" width="100%" height={40} />
        </SkeletonLoader>
    );
};
