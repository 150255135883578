import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Paths } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';

export const Home = () => {
    const { user } = useExerUser();
    const [path, setPath] = useState<Paths>();

    useEffect(() => {
        if (user?.products) {
            setPath(Paths.dashboard);
        }
    }, [user]);

    return path ? <Navigate to={path} /> : null;
};
