import React, { FC } from 'react';
import { View, Path, Image } from '@react-pdf/renderer';
import Svg from '@/common/pdf/pdf';
import { convertPxToPt } from '@/components/Health/PatientSessions/SessionsPDF/SessionsPDFUtils';
import { Maybe } from '../types';

interface ExerPDFHeaderProps {
    organizationLogoPrintUrl?: Maybe<string>;
}

export const ExerPDFHeader: FC<ExerPDFHeaderProps> = ({ organizationLogoPrintUrl }) => {
    return (
        <View
            style={{
                width: '100%',
                borderBottom: '1px solid #dadce6',
                marginBottom: convertPxToPt(30),
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: convertPxToPt(17),
            }}
            fixed
        >
            <Svg width="96" height="24" viewBox="0 0 48 14">
                <Path
                    fillRule="evenodd"
                    d="M6.10651 1.97841V0.363281H4.49138L0 4.83254V6.44767H1.61513L6.10651 1.97841ZM10.5506 1.97841V0.363281H12.1658L16.635 4.83254V6.44767H15.0199L10.5506 1.97841ZM10.5057 13.4835V11.8683L14.997 7.37695H16.6122V8.99208L12.1429 13.4835H10.5057ZM17.6085 4.83254V6.44767H19.2236L23.6929 1.97841V0.363281H22.0777L17.6085 4.83254ZM17.6085 9.03624V7.42111H19.2236L23.6929 11.8904V13.5055H22.0777L17.6085 9.03624ZM38.1102 5.0083L36.9597 6.1588V12.4866L38.1102 13.6371L39.2607 12.4866V6.1588L38.1102 5.0083ZM47.0486 11.0484L47.4247 11.4024L47.5575 11.5351L48 11.9776V13.5927H46.3848L45.9423 13.1502L42.4687 9.69873L42.3802 9.61023L41.805 9.03498V7.41985H43.4422L44.3715 8.3491L47.0486 11.0484ZM47.9999 4.72068V3.39318L46.8494 2.24268L45.6989 3.39318V4.18968V4.27818V5.60568V6.95531L46.8494 8.08369L47.9999 6.95531V6.13668V5.80481V4.72068ZM38.7739 2.685V2.66288L38.1102 2.685L36.9597 1.5345L38.1102 0.383998H39.4377H41.9378H45.1238L46.2522 1.5345L45.1238 2.66288H44.2167L42.8892 2.685H41.2962H38.7739ZM0 9.05842V7.44329H1.61513L6.10651 11.9125V13.5498H4.49138L0 9.05842ZM4.72409 5.78849H3.76525L2.61475 6.93899L3.76525 8.0895H4.56175H4.65025H4.72409H5.5206H5.6091H5.97775H6.9366H7.32738H8.28623L9.4146 6.93899L8.28623 5.78849H7.4676H7.32738H7.13572H6.50875H6.17688H6.0516H5.09275H4.72409ZM31.7337 1.97841V0.363281H30.1186L25.6272 4.83254V6.44767H27.2424L31.7337 1.97841ZM25.6272 9.05842V7.44329H27.2424L31.7337 11.9125V13.5498H30.1186L25.6272 9.05842ZM30.352 5.78849H29.3931L28.2426 6.93899L29.3931 8.0895H30.1896H30.2781H30.352H31.1485H31.237H31.6056H32.5645H32.9552H33.9141L35.0425 6.93899L33.9141 5.78849H33.0955H32.9552H32.7636H32.1366H31.8047H31.6795H30.7206H30.352Z"
                    fill="#0A0A0D"
                />
            </Svg>
            {organizationLogoPrintUrl ? <Image style={{ height: 24 }} src={organizationLogoPrintUrl} /> : null}
        </View>
    );
};
