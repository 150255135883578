import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Program, Session } from '@/common/types';
import { hepState } from '@/recoil/atoms/hepAtom';
import { SessionExpandingRow } from './SessionExpandingRow';

interface SessionExpandingRowContainerProps {
    session: Session;
}

export const SessionExpandingRowContainer = (props: SessionExpandingRowContainerProps) => {
    const { session } = props;
    const [program, setHEP] = useState<Program | null>();
    const [hepDict] = useRecoilState(hepState);

    useEffect(() => {
        let fetchedHEP: Program;
        if (session.session?.hepId && hepDict) {
            fetchedHEP = hepDict[session.session?.hepId];
            setHEP(fetchedHEP);
        }
        setHEP(null);
    }, []);
    return program !== undefined ? <SessionExpandingRow session={session} program={program} /> : null;
};
