import React from 'react';
import { Typography } from '@mui/material';
import { Paths } from '@/common/const';
import { ForgotPasswordForm } from '@/components/Auth/ForgotPassword/ForgotPasswordForm';
import { AuthLink } from '../styles';

export const ForgotPassword = () => {
    return (
        <>
            <Typography variant={'h5'} component="h1" fontWeight={600}>
                Forgot Password
            </Typography>
            <ForgotPasswordForm />
            <Typography variant={'body1'} component="p" fontWeight={600}>
                <AuthLink to={Paths.userLogin}>Return to Login</AuthLink>
            </Typography>
        </>
    );
};
