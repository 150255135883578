import { styled } from '@mui/material';
import { FactorCategory } from '../types/FactorCategory.enum';
import { RISK_HIGH_COLOR, RISK_MEDIUM_COLOR, RISK_LOW_COLOR } from '../const';

export const OutcomeSliderWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(8),
}));

export const OutcomeSliderFactors = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    margin: '0 auto',
    maxWidth: 502,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    gap: 0,
}));

export const OutcomeSliderSlot = styled('div')<{ $factorCategory: FactorCategory; $isActive: boolean }>(
    ({ theme, $factorCategory, $isActive }) => ({
        background: 'none',
        padding: theme.spacing(0.5),
        flexGrow: 1,
        alignSelf: 'stretch',
        visibility: $isActive ? 'visible' : 'hidden',
        textAlign: 'center',
        fontSize: 20,

        '> svg': {
            color:
                $factorCategory === FactorCategory.HIGH
                    ? RISK_HIGH_COLOR
                    : $factorCategory === FactorCategory.MEDIUM
                    ? RISK_MEDIUM_COLOR
                    : RISK_LOW_COLOR,
        },
    }),
);

export const OutcomeSliderFactor = styled('div')<{ $factorCategory: FactorCategory }>(({ theme, $factorCategory }) => ({
    background:
        $factorCategory === FactorCategory.HIGH
            ? RISK_HIGH_COLOR
            : $factorCategory === FactorCategory.MEDIUM
            ? RISK_MEDIUM_COLOR
            : RISK_LOW_COLOR,
    padding: theme.spacing(1.5),
    flexGrow: 1,
    alignSelf: 'stretch',
}));

export const OutcomeSliderFactorDescription = styled('div')<{ $factorCount: number }>(({ theme, $factorCount }) => ({
    color: '#B0B0B0',
    padding: theme.spacing(1.5),
    width: `calc(100%/${$factorCount})`,
    flexGrow: 1,
    alignSelf: 'stretch',
    border: '1px solid #B0B0B0',
    borderTop: 'none',
    borderRightWidth: 0.5,
    borderLeftWidth: 0.5,

    '&:first-child': {
        borderLeftWidth: 1,
    },
    '&:last-child': {
        borderRightWidth: 1,
    },
}));
