import { Button as MUIButton, styled } from '@mui/material';
import { Button } from '@/components/common/button/button';

export const ScanReportTabsWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

export const ScanReportDownloadButton = styled(Button)(({ theme }) => ({
    '> a': {
        color: 'white',
        textDecoration: 'none',
    },
}));

export const ScanReportChartWrapper = styled('div')(({ theme }) => ({
    paddingBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    marginBottom: theme.spacing(4),
}));

export const ScanReportDownloadButtonWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(4),
    justifyContent: 'flex-end',
}));

export const ScanAssessmentReportFilters = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

export const ScanAssessmentReportH2 = styled('h2')(({ theme }) => ({
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

export const ScanAssessmentReportSectionWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(8),
}));

export const ScanAssessmentReportCard = styled('div')(({ theme }) => ({
    background: 'white',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,

    '> h3': {
        marginTop: 0,
    },
}));

export const ScanAssessmentReportCardResults = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,

    'div > strong': {
        color: theme.palette.text.primary,
    },
}));

export const ScanParticipantReportPDFActionButton = styled(MUIButton)(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
}));
