import React from 'react';
import { SvgIcon } from '@mui/material';

export const SendIcon = (props) => {
    return (
        <SvgIcon width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
            <path
                d="M13.8554 6.62111L8.1916 12.3227L1.56064 8.24147C0.691759 7.70657 0.867871 6.38697 1.8467 6.10287L17.5022 1.54743C18.3925 1.28978 19.2156 2.12446 18.949 3.01889L14.304 18.6582C14.013 19.6369 12.7082 19.8064 12.1809 18.9325L8.1916 12.3227"
                stroke="currentcolor"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="transparent"
            />
        </SvgIcon>
    );
};
