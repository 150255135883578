import React, { useEffect, useReducer, useState, createContext } from 'react';
import { useRecoilValue } from 'recoil';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { Exercise } from '@/common/types';
import { favoriteExercisesState } from '@/recoil/atoms/favoriteExercisesAtom';
import { resultIsError } from '@/services/HttpService';
import { ExerciseListActions, exerciseListReducer, FilterDict, FilterPayload } from './ExerciseListReducer';

export interface ExerciseListState {
    exercises: Exercise[] | null;
    filtered: Exercise[] | null;
}

interface IExerciseListContext {
    exercises: Exercise[] | null;
    filtered: Exercise[] | null;
    loading: boolean;
    setAllExercises: (_: Exercise[]) => void;
    filterExercises: (_: FilterPayload) => void;
    clearExerciseFilter: () => void;
    activeFilters: FilterDict;
    setActiveFilters: React.Dispatch<React.SetStateAction<FilterDict>>;
}

const initialState: ExerciseListState = {
    exercises: null,
    filtered: null,
};

export const ExerciseListContext = createContext<IExerciseListContext | null>(null);

interface Props {
    children: JSX.Element | JSX.Element[];
}

export const ExerciseListProvider = (props: Props) => {
    const { children } = props;
    const [exerciseListState, dispatch] = useReducer(exerciseListReducer, initialState);
    const [activeFilters, setActiveFilters] = useState({} as FilterDict);
    const [loading, setLoading] = useState(false);
    const favoriteExercises = useRecoilValue(favoriteExercisesState);
    const { httpService } = useHttpContext();

    useEffect(() => {
        (async () => {
            setLoading(true);
            const res = await httpService.getPublishedExercises();
            if (resultIsError(res)) {
                // TODO handle error
            } else {
                setAllExercises(res);
            }
            setLoading(false);
        })();
    }, []);

    const setAllExercises = (payload: Exercise[]) => {
        dispatch({ type: ExerciseListActions.SET_ALL, payload });
    };

    const filterExercises = (payload: FilterPayload) => {
        payload.favoriteExercises = favoriteExercises;
        dispatch({ type: ExerciseListActions.FILTER, payload });
    };

    const clearExerciseFilter = () => {
        dispatch({ type: ExerciseListActions.CLEAR_FILTERS });
    };

    return (
        <ExerciseListContext.Provider
            value={{
                exercises: exerciseListState.exercises,
                filtered: exerciseListState.filtered,
                loading,
                setAllExercises,
                filterExercises,
                clearExerciseFilter,
                activeFilters,
                setActiveFilters,
            }}
        >
            {children}
        </ExerciseListContext.Provider>
    );
};
