import { ButtonGroup, Button, TextField, FormLabel, IconButton } from '@mui/material';
import styled from 'styled-components';
import { HEPInputWrapper, HEPInputSliderWrapper } from '../steps/FrequencyEditor/styles';

export const EditBlockContent = styled('div')(({ theme }) => ({
    padding: `0 ${theme.spacing(3)}`,
}));

export const EditBlockCtaWrapper = styled('div')(({ theme }) => ({
    padding: `0 ${theme.spacing(3)} ${theme.spacing(4)}`,
}));

export const EditBlockContentHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: `${theme.spacing(3)}`,

    '& h4': {
        color: theme.palette.grey[900],
        fontSize: theme.typography.body1.fontSize,
        margin: 0,
        maxWidth: '260px',
        textAlign: 'center',
    },
}));

export const EditBlockImg = styled('img')(({ theme }) => ({
    maxWidth: '60px',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
}));

export const EditBlockInputWrapper = styled(HEPInputWrapper)(({ theme }) => ({
    margin: `${theme.spacing(4)} 0}`,
}));

export const EditBlockSliderWrapper = styled(HEPInputSliderWrapper)(({ theme }) => ({
    marginTop: theme.spacing(4),
}));

export const HEPRepInputSliderWrapper = styled(EditBlockSliderWrapper)({
    width: 'auto',
    marginRight: '30px',
});

export const TimeFieldWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.grey[800],
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(1.75),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxWidth: 'min-content',

    '& .MuiSvgIcon-root': {
        color: theme.palette.grey[500],
    },

    '& .MuiInputBase-root': {
        width: 80,
    },

    '& fieldset': {
        border: 'none',
    },
}));

export const TimeFieldInput = styled(TextField)(({ theme }) => ({
    border: 'none',
    '.MuiInputBase-input': {
        padding: `10px 0 10px 14px`,
    },
}));

export const FrequentlySelected = styled('p')(({ theme }) => ({
    marginTop: theme.spacing(2),

    '& a': {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));

export const BlockPropSelectionWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    fontWeight: 'bold',
}));

export const BlockPropSelectionLabel = styled(FormLabel)<{ $isActive?: boolean }>(({ theme, $isActive }) => ({
    color: $isActive ? 'inherit' : theme.palette.grey[500],
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: $isActive ? 'inherit' : 14,
}));

export const BlockPropSelectionButtonGroup = styled(ButtonGroup)(({ theme }) => ({
    marginLeft: theme.spacing(1),
}));

export const BlockPropSelectionButton = styled(Button)<{ $isSelected?: boolean }>(({ theme, $isSelected }) => ({
    background: theme.palette.primary[$isSelected ? 600 : 400],
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    border: 'none',

    '&:hover': {
        background: theme.palette.primary[$isSelected ? 500 : 200],
    },
}));

export const SideOfBodyExpandButton = styled(IconButton)<{ $isActive?: boolean }>(({ theme, $isActive }) => ({
    fontSize: 16,
    padding: 0,
    transform: $isActive ? 'none' : 'rotate(-90deg)',
    transition: 'all .25s ease-in-out',
}));
