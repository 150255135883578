import React, { useRef, useState } from 'react';
import { Slide } from '@mui/material';
import { Chart, registerables, CategoryScale } from 'chart.js';
import { ArrowUpIcon } from '@/components/common/Icons/ArrowUpIcon';
import { PROQuestion } from './PROQuestion';
import { ScrollUpButton, MessagesWrapper, ScrollButtonWrapper, MessagesList } from './styles';
import { PROAnswersByQuestionsDict } from './types';

Chart.register(...registerables, CategoryScale);

interface PROQuestionsProps {
    proAnswersByQuestionsDict: PROAnswersByQuestionsDict | undefined;
}

export const PROQuestions = (props: PROQuestionsProps) => {
    const { proAnswersByQuestionsDict } = props;
    const scrollRef = useRef<HTMLDivElement>(null);
    const messagesListRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const executeScroll = () => scrollRef.current && scrollRef.current.scrollIntoView({ behavior: 'smooth' });

    const onScroll = () => {
        if (messagesListRef.current) {
            const { scrollTop } = messagesListRef.current;
            if (scrollTop >= 200) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        }
    };

    return (
        <MessagesWrapper>
            {proAnswersByQuestionsDict && (
                <MessagesList ref={messagesListRef} onScroll={onScroll}>
                    <div ref={scrollRef} />
                    {Object.keys(proAnswersByQuestionsDict).map((m) => {
                        return <PROQuestion key={m} question={proAnswersByQuestionsDict[m]} />;
                    })}
                    <ScrollButtonWrapper>
                        <Slide direction="up" in={isVisible} container={messagesListRef.current}>
                            <ScrollUpButton onClick={executeScroll} color="primary">
                                <ArrowUpIcon />
                            </ScrollUpButton>
                        </Slide>
                    </ScrollButtonWrapper>
                </MessagesList>
            )}
        </MessagesWrapper>
    );
};
