import React, { FC } from 'react';
import { ScanSessionJSON } from '@/common/types';
import { getResultPrecision } from '../Outcomes/utils';
import { ScanSessionsTableResultsWrapper } from './styles';

interface ScanSessionTableResultsProps {
    session: ScanSessionJSON;
}

export const ScanSessionsTableResults: FC<ScanSessionTableResultsProps> = ({ session }) => {
    return (
        <ScanSessionsTableResultsWrapper>
            {session ? (
                <>
                    {session.data?.results.map((result) => {
                        const decimalPrecision = getResultPrecision(result.units);
                        return (
                            <div key={result.name}>
                                <strong>{result.name}:</strong> {result.value.toFixed(decimalPrecision)} {result.units}{' '}
                            </div>
                        );
                    })}
                </>
            ) : (
                ''
            )}
        </ScanSessionsTableResultsWrapper>
    );
};
