import React, { useState, useRef, useCallback, Dispatch, SetStateAction } from 'react';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { IconButton, Tooltip } from '@mui/material';
import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';
import { UploadInputContainer, DragNDropContainer, IconContainer, EditImageContainer } from './styles';

export const OrgLogo = ({
    orgLogoUrl,
    setNewOrgLogo,
    setShouldDeleteOrgLogo,
}: {
    orgLogoUrl: string | undefined;
    setNewOrgLogo: Dispatch<SetStateAction<File | null>>;
    setShouldDeleteOrgLogo: Dispatch<SetStateAction<boolean>>;
}) => {
    const editor = useRef<AvatarEditor>(null);
    const [image, setImage] = useState<File | null>(null);
    const [scale, setScale] = useState(1);
    const [orgLogoImg, setOrgLogoImg] = useState<string | undefined>(orgLogoUrl);

    const onDrop = useCallback(async (acceptedFiles) => {
        setShouldDeleteOrgLogo(false);
        setImage(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop: onDrop,
        accept: {
            'image/png': [],
        },
    });

    const handleClearImage = () => {
        setImage(null);
        setScale(1);
        setNewOrgLogo(null);
        setShouldDeleteOrgLogo(true);
    };

    const handleClearOrgLogoImg = () => {
        setImage(null);
        setOrgLogoImg(undefined);
        setScale(1);
        setShouldDeleteOrgLogo(true);
    };

    const handleCrop = () => {
        if (editor.current) {
            const canvas = editor.current.getImage();
            canvas.toBlob((blob) => {
                if (blob) {
                    const orgLogoImgFile = new File([blob], 'cropped-image.png', {
                        type: blob.type,
                    });
                    setNewOrgLogo(orgLogoImgFile);
                }
            }, 'image/png');
        }
    };

    return (
        <>
            {!orgLogoImg && !image ? (
                <UploadInputContainer {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                    <input {...getInputProps()} />
                    <p>Drag file here or click to upload (PNG)</p>
                </UploadInputContainer>
            ) : null}
            {orgLogoImg && (
                <EditImageContainer>
                    <img src={orgLogoImg} />
                    <IconContainer>
                        <Tooltip title="Remove image" placement="right">
                            <IconButton onClick={handleClearOrgLogoImg} size="large">
                                <DeleteForeverIcon color="error" fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </IconContainer>
                </EditImageContainer>
            )}

            {image && !orgLogoImg ? (
                <EditImageContainer>
                    <DragNDropContainer>
                        <AvatarEditor
                            ref={editor}
                            width={250}
                            height={140}
                            scale={scale}
                            image={image}
                            onMouseMove={handleCrop}
                            onImageChange={handleCrop}
                            onImageReady={handleCrop}
                        />
                    </DragNDropContainer>
                    <IconContainer>
                        <Tooltip title="Zoom in" placement="right">
                            <IconButton onClick={() => setScale((prev) => prev + 0.1)} size="medium">
                                <ZoomInIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Zoom out" placement="right">
                            <IconButton onClick={() => setScale((prev) => prev - 0.1)} size="medium">
                                <ZoomOutIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Clear image" placement="right">
                            <IconButton onClick={handleClearImage} size="medium">
                                <ClearRoundedIcon color="error" fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </IconContainer>
                </EditImageContainer>
            ) : null}
        </>
    );
};
