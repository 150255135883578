import React from 'react';
import { PathsContainer } from '@/components/Demo/Paths/Paths';
import { RouteContentWrapper } from '../styles';

export const PathsRoute = () => {
    return (
        <RouteContentWrapper>
            <PathsContainer />
        </RouteContentWrapper>
    );
};
