import React, { FC } from 'react';
import { Maybe } from '@exerai/vision-engine-web/dist/src/common/types';
import { Slider } from '@mui/material';
import { ReplaySliderWrapper } from './styles';

interface ReplaySliderProps {
    frame: number;
    frameCount: Maybe<number>;
    handleSlider: (event: Event, value: number | number[], activeThumb: number) => void;
    phases: Maybe<{ value: number; label: string }[]>;
}
export const ReplaySlider: FC<ReplaySliderProps> = ({ frame, frameCount, handleSlider, phases }) => {
    // Filter out prepended 'Not Started' phase
    const marks = phases?.filter((phase) => !!phase.value);

    return (
        <ReplaySliderWrapper>
            {frameCount ? (
                <Slider
                    value={frame}
                    defaultValue={0}
                    valueLabelDisplay="auto"
                    max={frameCount}
                    marks={marks}
                    onChange={handleSlider}
                />
            ) : null}
        </ReplaySliderWrapper>
    );
};
