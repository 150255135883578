import { styled, Typography, RadioGroup as MuiRadioGroup } from '@mui/material';

export const RadioGroup = styled(MuiRadioGroup)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
}));

export const AccordionTitle = styled(Typography)(({ theme }) => ({
    display: 'block',
    [theme.breakpoints.up('lg')]: {
        marginBottom: '12px',
    },
}));

export const PROChartWrapper = styled('div')(({ theme }) => ({
    paddingBottom: 60,
    borderBottom: `${theme.palette.grey[200]}`,
    marginBottom: 90,
}));
