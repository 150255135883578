import React from 'react';
import { HEPTemplate } from '@/common/types';
import { HEPBlocks } from '@/components/Health/HEPs/HEPTemplateDetails/HEPBlocks/HEPBlocks';
import {
    ExerciseDetailsWrapper,
    HEPTitle,
    HEPCaption,
    HEPFrequencyDetails,
    HEPDetailsNotesCard,
    HEPDetailsNotes,
    HEPExercisesCaption,
} from './styles';

interface TemplateDetailsProps {
    hepTemplate: HEPTemplate;
}

export const TemplateDetails = (props: TemplateDetailsProps) => {
    const { hepTemplate } = props;

    return (
        <ExerciseDetailsWrapper>
            <HEPCaption variant="caption">Preview</HEPCaption>
            <HEPTitle variant="body2">{hepTemplate.name}</HEPTitle>
            <HEPCaption variant="caption">Program Frequency</HEPCaption>
            <HEPFrequencyDetails variant="body1">
                Daily Frequency: {hepTemplate.dailyFrequency} {`\u00a0`} Weekly Frequency: {hepTemplate.weeklyFrequency}
            </HEPFrequencyDetails>
            <HEPDetailsNotesCard variant="outlined">
                <HEPCaption variant="caption">Program Notes</HEPCaption>
                <HEPDetailsNotes>{hepTemplate.notes || '-'}</HEPDetailsNotes>
            </HEPDetailsNotesCard>
            <HEPExercisesCaption variant="caption">Exercises</HEPExercisesCaption>
            <HEPBlocks blocks={hepTemplate.blocks} />
        </ExerciseDetailsWrapper>
    );
};
