import React, { useState } from 'react';
import { DialogContent } from '@mui/material';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { mixpanel } from '@/common/mixpanel';
import { DialogContentWrapper } from '@/components/common/Dialog/styles';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { ServerError } from '@/components/common/Form/styles';
import { resultIsError } from '@/services/HttpService';

interface Props {
    practitionerId: number;
    practitionerName: string;
    handleCancelClick: (_: React.MouseEvent) => void;
}
export const DeletePractitionerDialogContent = (props: Props) => {
    const { practitionerId, practitionerName, handleCancelClick } = props;
    const [serverError, setServerError] = useState('');
    const { httpService } = useHttpContext();

    const handleSubmit = async () => {
        const res = await httpService.deletePractitioner(practitionerId);
        if (resultIsError(res)) {
            setServerError(res.message);
            return;
        }
        if (!res) {
            setServerError('There was a problem removing that provider.');
            return;
        }
        mixpanel.track(`User deleted a provider from the portal`);
        window.location.reload();
    };

    return (
        <DialogContent>
            <DialogContentWrapper>
                <h3>Are you sure you want to remove {practitionerName} from the organization?</h3>
                <ServerError>{serverError}</ServerError>
                <SubmitCancelButtons
                    submitFunction={handleSubmit}
                    cancelFunction={handleCancelClick}
                    submitText="Delete"
                />
            </DialogContentWrapper>
        </DialogContent>
    );
};
