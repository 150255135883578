import React from 'react';
import { ScanOrganizationsTableContainer } from '@/components/Organization/OrganizationsTable/ScanOrganizationsTableContainer';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { RouteContentWrapper } from '../../styles';

export const AdminScanOrganizations = () => {
    return (
        <RouteContentWrapper>
            <PageTitle title="Scan Organizations" />
            <ScanOrganizationsTableContainer />
        </RouteContentWrapper>
    );
};
