import React, { useState } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { ClickAwayListener } from '@mui/material';
import { HEPTemplate, TemplateOrHEP } from '@/common/types';
import { HEPBuilderModal } from '@/components/Health/HEPBuilder/HepBuilderModal/HEPBuilderModal';
import { BuilderActions } from '@/components/Health/HEPBuilder/state/HEPBuilderContext';
import { DeleteTemplateAction } from '@/components/Health/HEPs/HEPTemplatesCardList/HEPTemplateCard/CardActions/DeleteTemplateAction';
import { CopyIcon } from '@/components/common/Icons/CopyIcon';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { CardIconWrapper, CardItemText } from './CardActions/styles';

interface TemplateCardActionsMenuProps {
    hepTemplete: HEPTemplate;
    handleViewHep: () => void;
}

export const TemplateCardActionsMenu = (props: TemplateCardActionsMenuProps) => {
    const { hepTemplete, handleViewHep } = props;
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const menuIsOpen = Boolean(menuAnchor);
    const [editTemplateDialogOpen, setEditTemplateDialogOpen] = useState(false);
    const [duplicateTemplateDialogOpen, setDuplicateTemplateDialogOpen] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchor(null);
    };
    const handleCloseMenu = () => {
        handleClose();
    };

    const handleEditTemplateClick = async () => {
        setMenuAnchor(null);
        setEditTemplateDialogOpen(true);
    };

    const handleDuplicateTemplateClick = async () => {
        setDuplicateTemplateDialogOpen(true);
    };

    return (
        <>
            <IconButton
                id="three-dot-button"
                aria-label="Duplicate or Delete Program Template"
                aria-controls={menuIsOpen ? 'template-actions-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={menuIsOpen ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreHoriz />
            </IconButton>

            <Menu
                onClick={(e) => e.stopPropagation()}
                id="three-dot-menu"
                anchorEl={menuAnchor}
                open={menuIsOpen}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'three-dot-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <ClickAwayListener onClickAway={handleCloseMenu}>
                    <>
                        <MenuItem onClick={handleEditTemplateClick}>
                            <CardIconWrapper>
                                <EditIcon />
                            </CardIconWrapper>
                            <CardItemText>Edit</CardItemText>
                        </MenuItem>
                        <MenuItem onClick={handleDuplicateTemplateClick}>
                            <CardIconWrapper>
                                <CopyIcon />
                            </CardIconWrapper>
                            <CardItemText>Duplicate</CardItemText>
                        </MenuItem>
                        <MenuItem onClick={handleViewHep}>
                            <CardIconWrapper>
                                <CopyIcon />
                            </CardIconWrapper>
                            <CardItemText>View</CardItemText>
                        </MenuItem>
                        <DeleteTemplateAction hepTemplate={hepTemplete} closeMenu={handleClose} />
                    </>
                </ClickAwayListener>
            </Menu>

            <HEPBuilderModal
                open={duplicateTemplateDialogOpen}
                onClose={() => {
                    setDuplicateTemplateDialogOpen(false);
                    handleClose();
                }}
                {...props}
                existingTemplateOrHEP={{ ...hepTemplete, name: `${hepTemplete.name} (DUPLICATE)` }}
                isTemplateOrHEP={TemplateOrHEP.TEMPLATE}
                actionType={BuilderActions.DUPLICATE_TEMPLATE}
            />
            <HEPBuilderModal
                open={editTemplateDialogOpen}
                onClose={() => {
                    setEditTemplateDialogOpen(false);
                    handleClose();
                }}
                {...props}
                existingTemplateOrHEP={hepTemplete}
                isTemplateOrHEP={TemplateOrHEP.TEMPLATE}
                actionType={BuilderActions.EDIT_TEMPLATE}
            />
        </>
    );
};
