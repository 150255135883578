import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useExerUser } from '@/common/hooks/ExerUser';
import { UpdateOrganizationDto } from '@/common/types';
import { PageTitleWrapper } from '@/components/common/PageTitle/styles';
import { OrganizationEditAction } from '../OrganizationEdit/OrganizationEditAction';
import {
    OrganizationTitleWrapper,
    OrganizationTitleTimezone,
    OrganizationTitleLeft,
    OrganizationTitleRight,
} from './styles';

export const OrganizationHeader = () => {
    const { user } = useExerUser();
    const [organizationName, setOrganizationName] = useState(user.organizationName);

    const handleEditSuccess = (res: UpdateOrganizationDto) => {
        setOrganizationName(res.name);
    };

    useEffect(() => {
        setOrganizationName(user.organizationName);
    }, [user.organizationName]);

    return (
        <PageTitleWrapper>
            <OrganizationTitleWrapper>
                <OrganizationTitleLeft>
                    <Typography variant="h5" component="h1" fontWeight="500">
                        {organizationName}
                    </Typography>
                    <OrganizationTitleTimezone>{user.organizationTimezone}</OrganizationTitleTimezone>
                </OrganizationTitleLeft>

                <OrganizationTitleRight>
                    <OrganizationEditAction onSuccess={handleEditSuccess} />
                </OrganizationTitleRight>
            </OrganizationTitleWrapper>
        </PageTitleWrapper>
    );
};
