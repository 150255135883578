import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { Practitioner } from '@/common/types';
import { resultIsError } from '@/services/HttpService';

interface IPractitionersContext {
    practitioners?: Practitioner[];
    refreshPractitioners: () => Promise<void>;
}
export const PractitionersContext = createContext<IPractitionersContext | null>(null);

export const PractitionersProvider = (props: { children: JSX.Element | JSX.Element[] | string }) => {
    const { children } = props;
    const { httpService } = useHttpContext();
    const [practitioners, setPractitioners] = useState<Practitioner[]>();

    const refreshPractitioners = useCallback(async () => {
        const res = await httpService.getPractitioners();
        if (resultIsError(res)) {
            return;
        }
        setPractitioners(res);
    }, [httpService]);

    useEffect(() => {
        (async () => {
            await refreshPractitioners();
        })();
    }, [refreshPractitioners]);

    const practitionersContext = {
        practitioners,
        refreshPractitioners,
    };
    return <PractitionersContext.Provider value={practitionersContext}>{children}</PractitionersContext.Provider>;
};
