import * as React from 'react';
import { SvgIcon } from '@mui/material';

export const ScanIpadIcon = (props) => {
    return (
        <SvgIcon width="24" height="24" viewBox="280.92 168.6 647.16 862.68" {...props}>
            <path
                d="m856.08 168.6h-503.16c-39.719 0-72 32.281-72 72v718.68c0 39.719 32.281 72 72 72h503.16c39.719 0 72-32.281 72-72l-0.003906-718.68c0-39.719-32.281-72-72-72zm24 790.68c0 13.199-10.801 24-24 24h-503.16c-13.199 0-24-10.801-24-24v-718.68c0-13.199 10.801-24 24-24h503.16c13.199 0 24 10.801 24 24v718.68z"
                fill="currentColor"
            />
            <path
                d="m691.68 925.2h-174.36c-6.6016 0-12 5.3984-12 12 0 6.6016 5.3984 12 12 12h174.36c6.6016 0 12-5.3984 12-12 0-6.6016-5.3984-12-12-12z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
