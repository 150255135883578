import {
    styled,
    Typography,
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
    MenuItem as MuiMenuItem,
} from '@mui/material';

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: `0 ${theme.spacing(2)}`,
    '&&': {
        borderRadius: theme.shape.borderRadius,
    },
}));

export const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    borderRadius: 0,
    padding: 0,
    fontSize: theme.typography.fontSize,
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightMedium,
    '.MuiAccordionSummary-expandIconWrapper': {
        fontSize: theme.typography.h4.fontSize,
    },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    maxHeight: theme.spacing(33.75),
    overflowY: 'scroll',
    padding: 0,
}));

export const RadioMenuItem = styled(MuiMenuItem)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    transition: `.4s ease background-color`,
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(0)}`,
    boxShadow: '0px 2px 5px rgba(16, 5, 51, 0.04)',
    '&:last-of-type': {
        marginBottom: theme.spacing(2),
    },

    '&:hover': {
        backgroundColor: theme.palette.primary[200],
    },
}));

export const MenuItemTitle = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.fontSize,
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'center',
    paddingRight: theme.spacing(2),
}));
