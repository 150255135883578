import React from 'react';
import { Grid } from '@mui/material';
import { SelfReportedEnum } from '@/common/const';
import { ExerciseSetTitle, ExerciseTitle } from '@/components/Health/PatientSessions/ExerciseSet/styles';
import { SelfReportedTooltip } from './SelfReportedTooltip';
import { SessionGridContainer } from './styles';

interface SessionGridWrapperProps {
    exerciseName: string;
    index: number;
    selfReportedStatus: SelfReportedEnum;
    children: any;
}
export const SessionGridWrapper = (props: SessionGridWrapperProps) => {
    const { exerciseName, index, selfReportedStatus, children } = props;

    return (
        <SessionGridContainer container spacing={4}>
            <Grid item xs={12} md={4}>
                <ExerciseSetTitle style={{ display: 'flex' }}>
                    Exercise {index}
                    <SelfReportedTooltip selfReportedStatus={selfReportedStatus} />
                </ExerciseSetTitle>
                <ExerciseTitle>{exerciseName}</ExerciseTitle>
            </Grid>
            <Grid item xs={12} md={8}>
                {children}
            </Grid>
        </SessionGridContainer>
    );
};
