import { styled, Box } from '@mui/material';

export const AssessmentCardContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    background: theme.palette.common.white,
    borderRadius: 8,
    width: '100%',
    padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} 0`,
    flex: 1,
    minWidth: 460,
}));

export const SortableAssessment = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: theme.palette.grey[200],
    borderRadius: '4px',
    width: '100%',
}));

export const SortableAssessmentHandle = styled(Box)(({}) => ({
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontSize: '28px',
}));
