import React from 'react';
import { PROFormsTableContainer } from '@/components/PROs/PROForms/PROFormsTableContainer';
import { RouteContentWrapper } from '../../styles';

export const AdminPROFormsRoute = () => {
    return (
        <RouteContentWrapper>
            <PROFormsTableContainer />
        </RouteContentWrapper>
    );
};
