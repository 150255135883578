import { styled, Box, Avatar, Typography, Grid } from '@mui/material';

const HEP_BUILDER_FOOTER_HEIGHT = 90;

export const HEPBuilderWrapper = styled('div')(({ theme }) => ({
    background: theme.palette.neutral.main,
    height: '10000%',
}));

export const HEPBuilderHeader = styled('header')(({ theme }) => ({
    padding: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    justifyContent: ' space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },

    '& h2': {
        fontSize: 18,
        fontWeight: 'normal',
        margin: 0,
    },
}));

export const HEPBuilderStepIndicator = styled(Avatar)(({ theme }) => ({
    background: theme.palette.primary[200],
    color: theme.palette.primary[500],
    marginRight: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    width: '20px',
    height: '20px',
    fontSize: theme.typography.caption.fontSize,
}));

export const HEPBuilderContent = styled('div')<{ $fullScreen?: boolean }>(({ theme, $fullScreen }) => ({
    padding: $fullScreen ? 0 : theme.spacing(2),
    paddingBottom: $fullScreen ? HEP_BUILDER_FOOTER_HEIGHT : HEP_BUILDER_FOOTER_HEIGHT + 30,
    paddingTop: $fullScreen ? 0 : theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
        padding: $fullScreen ? 0 : theme.spacing(3),
        paddingLeft: $fullScreen ? 0 : theme.spacing(8),
        paddingRight: $fullScreen ? 0 : theme.spacing(8),
        paddingBottom: $fullScreen ? HEP_BUILDER_FOOTER_HEIGHT : HEP_BUILDER_FOOTER_HEIGHT + 30,
    },
}));

export const HEPBuilderFooter = styled(Grid)(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    width: '100%',
    background: theme.palette.common.white,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0 -2px 4px rgba(16,24,64,.05)',
    zIndex: '50',

    '& .MuiButton-root .MuiSvgIcon-root': {
        margin: 0,
    },
    [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
}));

export const HEPBuilderNav = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
        marginTop: '0',
    },
}));

export const HEPBuilderNavItem = styled(Typography)<{ $isActive: boolean }>(({ theme, $isActive }) => ({
    textDecoration: 'none',
    position: 'relative',
    marginRight: theme.spacing(2.5),
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: $isActive ? theme.palette.primary[500] : theme.palette.grey[400],
    [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(1),
    },
}));

export const HEPBuilderHeaderRight = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexBasis: '200px',
        position: 'relative',
    },
}));

export const HEPBuilderNavItemBullet = styled('span')(({ theme }) => ({
    fontSize: 32,
    marginRight: theme.spacing(1),
    lineHeight: 0,
}));
