import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { getOptionEnumById } from '@/common/utils';
import { FilterDict } from '../ExerciseList/state/ExerciseListReducer';
import { IExerciseFilter } from './ExerciseFilters';
import { ExerciseFilterWrapper } from './styles';

interface Props {
    filter: IExerciseFilter;
    updateFilters: (type: string, value: string | string[], include: boolean) => void;
    activeFilters: FilterDict;
}

export const ExerciseFilter = (props: Props) => {
    const {
        filter: { id, name, options },
        updateFilters,
        activeFilters,
    } = props;

    const typeEnum = getOptionEnumById(id);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFilters(id, e.target.value, e.target.checked);
    };

    return (
        <ExerciseFilterWrapper>
            <h5>{name}</h5>
            <FormGroup>
                {Array.from(options).map((option: string) => {
                    const value = activeFilters[id]?.value;
                    return (
                        <FormControlLabel
                            key={option}
                            control={
                                <Checkbox
                                    value={option}
                                    checked={value?.includes(option) || false}
                                    onChange={handleChange}
                                    name={id}
                                    data-cy={`filter-${option}`}
                                />
                            }
                            label={typeEnum[option]}
                        />
                    );
                })}
            </FormGroup>
        </ExerciseFilterWrapper>
    );
};
