import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { UserProfile } from '@/common/types';
import { FormTextField } from '@/components/common/Form/Form';
import { FormFieldContainer } from '@/components/common/Form/styles';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { resultIsError } from '@/services/HttpService';
import { RouteContentWrapper } from '../../styles';

const shape = {
    uuid: yup.string().required('UUID is required'),
};

export const UserByUUID = () => {
    const schema = yup.object().shape(shape);
    const { httpService } = useHttpContext();
    const [serverError, setServerError] = useState<object | null>();
    const [userProfile, setUserProfile] = useState<UserProfile | null>();

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
    });

    const resetAll = () => {
        setUserProfile(null);
        reset();
    };

    const findByUUID = async (data) => {
        setServerError(null);
        const result = await httpService.adminGetUserByUUID(data.uuid);
        if (resultIsError(result)) {
            setServerError({ uuid: { message: 'No patient found, please check the UUID.' } });
        } else {
            setUserProfile(result);
            return result;
        }
    };

    return (
        <RouteContentWrapper>
            <PageTitle title="Find User By UUID" />
            {userProfile ? (
                <>
                    <p>
                        <strong>
                            {userProfile.firstName} {userProfile.lastName}
                        </strong>
                    </p>
                    <p>
                        <strong>Email: </strong>
                        {userProfile.email}
                    </p>
                    <p>
                        <strong>Organization: </strong>
                        {userProfile.organization?.name || ''}
                    </p>
                    <Button onClick={resetAll} variant="primary">
                        Find Another
                    </Button>
                </>
            ) : (
                <form onSubmit={handleSubmit(findByUUID)}>
                    <FormTextField
                        control={control}
                        errors={serverError || errors}
                        name="uuid"
                        label="User Cognito ID"
                    />
                    <FormFieldContainer>
                        <Button type="submit" variant="primary">
                            Find
                        </Button>
                    </FormFieldContainer>
                </form>
            )}
        </RouteContentWrapper>
    );
};
