import React, { useEffect } from 'react';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { PatientRecord } from '@/common/types';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { useModalContext } from '@/components/context/hooks/ModalContext';
import { PortalEvent } from '@/services/events/const';
import eventService from '@/services/events/eventService';
import { PatientEditDialogContent } from '../PatientActionsDialogContent/PatientEditDialogContent';

interface EditPPatientActionProps {
    patientRecord: PatientRecord;
    handleClose?: (e: React.MouseEvent) => void;
}

export const EditPatientAction = (props: EditPPatientActionProps) => {
    const { patientRecord, handleClose } = props;
    const { setDialogOpen, setDialog } = useModalContext();

    const handleEdit = (e: React.MouseEvent) => {
        e.preventDefault();
        setDialog(
            'Edit Patient Details',
            <PatientEditDialogContent
                patientRecord={patientRecord}
                handleCancel={() => {
                    setDialogOpen(false);
                }}
            />,
        );
        setDialogOpen(true);
        handleClose && handleClose(e);
    };

    useEffect(() => {
        eventService.on(PortalEvent.UPDATE_PATIENT, async () => {
            setDialogOpen(false);
        });
        return () => {
            eventService.remove(PortalEvent.UPDATE_PATIENT);
        };
    }, []);

    return (
        <MenuItem onClick={handleEdit}>
            <ListItemIcon>
                <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
        </MenuItem>
    );
};
