import React, { useState, useEffect } from 'react';
import { CARD_PAGE_SIZE } from '@/common/const';
import { Paths } from '@/common/const';
import usePagination from '@/common/hooks/Pagination';
import { DashboardPatient } from '@/common/types';
import { DashboardList } from '@/components/Dashboard/DashboardList/DashboardList';
import { DashboardDataProps } from '@/components/Dashboard/DashboardList/DashboardList';
import { StatsCard } from '@/components/Dashboard/StatsCard/StatsCard';
import { PatientStatusBadgeWrapper } from './styles';

interface RTMStatsCardProps {
    data: DashboardPatient[];
}

export const RTMStatsCard = (props: RTMStatsCardProps) => {
    const { data } = props;
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const { jump, setPaginatedData, currentData } = usePagination<DashboardDataProps>(CARD_PAGE_SIZE);

    const handlePaginationChange = (_: React.ChangeEvent<unknown>, p: number) => {
        setPage(p);
        jump(p);
    };

    const convertToRows = (patientRecords: DashboardPatient[]): DashboardDataProps[] => {
        return patientRecords.map((patientRecord) => {
            return {
                name: `${patientRecord?.firstName} ${patientRecord?.lastName}`,
                details: <PatientStatusBadgeWrapper label={patientRecord?.status || ''} size="small" />,
                id: patientRecord?.id,
            };
        });
    };

    useEffect(() => {
        setPaginatedData(convertToRows(data));
        setCount(Math.ceil(data.length / CARD_PAGE_SIZE));
    }, [data]);

    return (
        <StatsCard
            title={data.length}
            subHeader="Patients ready to bill today"
            handlePagination={handlePaginationChange}
            count={count}
            page={page}
            headerAction={{ to: Paths.billing, label: 'RTM Billing' }}
        >
            {currentData && <DashboardList data={currentData} columnTitles={{ left: 'name', right: 'Status' }} />}
        </StatsCard>
    );
};
