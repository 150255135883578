import React from 'react';
import { TabPanel as Tab } from '@/components/common/Tabs/TabPanel';

interface PatientTabProps {
    children: JSX.Element;
    currentTab: number;
    index: number;
    ariaLabel: string;
}

export const TabPanel = (props: PatientTabProps) => {
    const { currentTab, index, ariaLabel, children } = props;

    return (
        <Tab value={currentTab} index={index} ariaLabel={ariaLabel}>
            {children}
        </Tab>
    );
};
