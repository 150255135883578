import React from 'react';
import { SvgIcon } from '@mui/material';

export const PatientsIcon = (props) => {
    return (
        <>
            <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
                <path
                    d="M16.0203 10.9134C17.8413 10.9134 19.3173 9.4374 19.3173 7.6164C19.3173 5.7964 17.8413 4.3194 16.0203 4.3194"
                    stroke="currentcolor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.5364 14.4964C18.0804 14.5334 18.6204 14.6114 19.1534 14.7294C19.8924 14.8764 20.7824 15.1794 21.0984 15.8424C21.3004 16.2674 21.3004 16.7624 21.0984 17.1874C20.7834 17.8504 19.8924 18.1534 19.1534 18.3054"
                    stroke="currentcolor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.59149 15.2063C13.2815 15.2063 16.4335 15.7653 16.4335 17.9983C16.4335 20.2323 13.3015 20.8103 9.59149 20.8103C5.90149 20.8103 2.75049 20.2523 2.75049 18.0183C2.75049 15.7843 5.88149 15.2063 9.59149 15.2063Z"
                    stroke="currentcolor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.59152 12.0188C7.15752 12.0188 5.20752 10.0678 5.20752 7.63379C5.20752 5.20079 7.15752 3.24979 9.59152 3.24979C12.0255 3.24979 13.9765 5.20079 13.9765 7.63379C13.9765 10.0678 12.0255 12.0188 9.59152 12.0188Z"
                    stroke="currentcolor"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </SvgIcon>
        </>
    );
};
