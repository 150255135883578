import React from 'react';
import { Typography } from '@mui/material';
import { AlertIcon } from '@/components/common/Icons/AlertIcon';
import { UsersIcon } from '@/components/common/Icons/UsersIcon';
import { EmptyDataIcon, EmptyDataWrapper, EmptyDataCtaWrapper } from './styles';

export interface EmptyDataProps {
    readonly title: string;
    readonly body?: string;
    readonly cta?: JSX.Element;
    readonly icon?: JSX.Element;
}

export const EmptyData = ({ title, body, cta, icon }: EmptyDataProps) => {
    return (
        <EmptyDataWrapper>
            <EmptyDataIcon>{icon ? icon : <AlertIcon />}</EmptyDataIcon>
            <Typography variant="h4">{title}</Typography>
            <Typography variant="subtitle1" fontWeight={500}>
                {body}
            </Typography>
            {cta ? <EmptyDataCtaWrapper>{cta}</EmptyDataCtaWrapper> : null}
        </EmptyDataWrapper>
    );
};

export const EmptyPatientsData = ({ title, body }: EmptyDataProps) => {
    return (
        <EmptyDataWrapper>
            <EmptyDataIcon>
                <UsersIcon />
            </EmptyDataIcon>
            <Typography variant="h4">{title}</Typography>
            <Typography variant="subtitle1" fontWeight={500}>
                {body}
            </Typography>
        </EmptyDataWrapper>
    );
};
