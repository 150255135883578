import styled from 'styled-components';

export const LoaderOuterWrapper = styled.div`
    text-align: center;
    width: 100%;
`;

export const LoaderWrapper = styled.div`
    width: 100px;
    margin: 0 auto;
    animation: fadeIn 2s;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        33% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-moz-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        33% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        33% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;
