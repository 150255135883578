import React, { ReactElement, useEffect, useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { useTheme, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { GaitSessionVersion } from '@/common/const';
import { mixpanel } from '@/common/mixpanel';
import {
    GaitSession,
    GaitSessionData,
    GaitSessionDataMeasures,
    GaitSessionFooterMeasurements,
    ThresholdFunction,
    GaitSessionTableRow,
} from '@/common/types';
import { precisionRound, preventParentLinkClick, gaitSortOrder } from '@/common/utils';
import { SessionComment } from '@/components/common/SessionComment/SessionComment';
import { CopyRow } from '@/components/common/Table/CopyRow';
import { ThresholdValue } from '@/components/common/Table/ThresholdValue';
import {
    SessionTitle,
    SessionExpansionPanel,
    SessionExpansionPanelSummary,
    SessionExpansionPanelDetails,
    SessionExpansionPanelSummaryHeader,
    SessionExpansionPanelSummaryHeaderTop,
    SessionHeaderTopRight,
    SessionTime,
    GaitSessionTableContent,
    GaitTableFooterContainer,
    GaitTableFooterItem,
    GaitTableFooterLeft,
    GaitTableFooterRight,
    GaitSessionTableAssessmentLabel,
} from '../GaitPatient/styles';
import { GaitPatientSessionTable } from '../Sessions/GaitPatientSessionTable';
import { SessionSubRow } from './SessionSubRow';

interface SessionExpandingRowProps {
    session: GaitSession;
    key: string;
}

const GaitTableFooterMeasurement = ({ m }: { m: GaitSessionDataMeasures }) => {
    return (
        <GaitTableFooterItem item container xs={3}>
            <Typography variant="caption">{m.displayName}:</Typography>
            <Typography variant="body1">{` ${precisionRound(Number(m.value), 2)}${m.unit}`}</Typography>
        </GaitTableFooterItem>
    );
};

export const SessionExpandingRow = (props: SessionExpandingRowProps) => {
    const { session, key } = props;
    const theme = useTheme();
    const [rows, setRows] = useState<GaitSessionTableRow[]>();
    const [footerMeasurements, setFooterMeasurements] = useState<GaitSessionFooterMeasurements>();
    const maximumColumns = ['maxTrunkAngle', 'maxHeadFlexionAngle'];
    const hiddenRows = ['lapOneTime', 'lapTwoTime', 'avgTime', 'distance', 'hipFlexionAngleYAxis'];
    const [showMaxColumn, setShowMaxColumn] = useState(false);
    const createdTime = session.session.meta.capturedDate
        ? DateTime.fromISO(session.session.meta.capturedDate).toLocaleString(DateTime.TIME_SIMPLE)
        : null;

    const handleAccordion = (_, expanded) => {
        if (expanded) {
            mixpanel.track('Viewed Gait Session Data');
        }
    };

    const speedThresholdFunction: ThresholdFunction = (speed: number) => {
        return speed < Number(process.env.THRESHOLD_SPEED);
    };

    const hipThresholdFunction: ThresholdFunction = (angle: number) => {
        return angle < Number(process.env.THRESHOLD_HIP_FLEXION);
    };

    const FormatCell = ({ measure }: { measure: GaitSessionDataMeasures }) => {
        const isPercentage = measure.unit === '%';
        const value = isPercentage ? measure.value * 100 : measure.value;
        if (measure.name === 'avgSpeed') {
            return (
                <>
                    <ThresholdValue
                        value={precisionRound(Number(value), 2)}
                        thresholdFunction={speedThresholdFunction}
                        unit={measure.unit}
                    />
                </>
            );
        } else if (
            measure.name === 'rightFlexion' ||
            measure.name === 'leftFlexion' ||
            measure.name === 'hipFlexionAngle'
        ) {
            return (
                <>
                    <ThresholdValue
                        value={precisionRound(Number(value), 0)}
                        thresholdFunction={hipThresholdFunction}
                        unit={measure.unit}
                    />
                </>
            );
        } else {
            return (
                <div>
                    <Typography variant="body1">
                        {precisionRound(Number(value), 0)}
                        {measure.unit}
                    </Typography>
                </div>
            );
        }
    };

    const createRows = (gaitSession: GaitSessionData) => {
        const sessionRow: {
            assessmentType: ReactElement[];
            average: ReactElement[];
            percentDifferenceOfAverage: ReactElement[];
            maximum: ReactElement[];
        } = {
            assessmentType: [],
            average: [],
            percentDifferenceOfAverage: [],
            maximum: [],
        };

        if (gaitSession.measures.some((e) => e.name === 'time')) return;

        const uniqueKeys: string[] = [];
        gaitSession.measures.map((m) => {
            if (hiddenRows.includes(m.name)) {
                return;
            }

            // Remove duplicate keys if found
            if (uniqueKeys.includes(m.name)) {
                return;
            }
            uniqueKeys.push(m.name);

            const showMaximum = maximumColumns.includes(m.name);
            const dontShowAverage =
                /(flexion)|((left|right)Flexion)|([eE]xtension)|([sS]tep)|([sS]tride)|(rightDorsiflexion)|(leftDorsiflexion)|(rightPlantarFlexion)|(leftPlantarFlexion)/g.test(
                    m.name,
                );
            const orderIndex = gaitSortOrder(m.name);
            const isPercentage = m.unit === '%';

            !isPercentage &&
                sessionRow.assessmentType?.splice(
                    orderIndex,
                    0,
                    <GaitSessionTableAssessmentLabel>
                        <Typography variant="body1" color={theme.palette.grey[900]}>
                            {m.displayName}
                        </Typography>
                    </GaitSessionTableAssessmentLabel>,
                );

            !isPercentage &&
                sessionRow.average.splice(
                    orderIndex,
                    0,
                    !showMaximum ? (
                        <GaitSessionTableContent>
                            <FormatCell measure={m} />
                        </GaitSessionTableContent>
                    ) : (
                        <GaitSessionTableContent>
                            <Typography variant="body1">-</Typography>
                        </GaitSessionTableContent>
                    ),
                );

            !isPercentage &&
                !dontShowAverage &&
                sessionRow.percentDifferenceOfAverage.splice(
                    orderIndex,
                    0,
                    <GaitSessionTableContent>
                        <Typography variant="body1">-</Typography>
                    </GaitSessionTableContent>,
                );

            !isPercentage &&
                sessionRow.maximum.splice(
                    orderIndex,
                    0,
                    showMaximum ? (
                        <GaitSessionTableContent>
                            <FormatCell measure={m} />
                        </GaitSessionTableContent>
                    ) : (
                        <GaitSessionTableContent>
                            <Typography variant="body1">-</Typography>
                        </GaitSessionTableContent>
                    ),
                );
        });
        return sessionRow;
    };

    const convertToRows = (gaitSession: GaitSession): GaitSessionTableRow[] => {
        const sessionRows: GaitSessionTableRow[] = [];
        const sortedData = [...gaitSession.session.data].sort((data) => {
            return data.item === 'speed' ? -1 : 1;
        });
        sortedData.map((sessionData) => {
            const formattedRows = createRows(sessionData);
            if (formattedRows && formattedRows.percentDifferenceOfAverage !== null) {
                sessionRows.push({
                    assessmentType: <SessionSubRow row={formattedRows.assessmentType} />,
                    average: <SessionSubRow row={formattedRows.average} />,
                    percentDifferenceOfAverage: <SessionSubRow row={formattedRows.percentDifferenceOfAverage} />,
                    maximum: <SessionSubRow row={formattedRows.maximum} />,
                });
            }
        });
        return !gaitSession ? [] : sessionRows;
    };

    const columnStructure = [
        {
            Header: 'Assessment Type',
            accessor: 'assessmentType',
            disableSortBy: true,
            width: '30%',
        },
        {
            Header: 'Average',
            accessor: 'average',
            disableSortBy: true,
            width: '15%',
        },
        {
            Header: 'Maximum',
            accessor: 'maximum',
            disableSortBy: true,
            width: '15%',
        },
    ];

    const createFooter = (gaitSession: GaitSession): GaitSessionFooterMeasurements => {
        const footerColumns: GaitSessionFooterMeasurements = {
            distance: null,
            avgTime: null,
            lapOneTime: null,
            lapTwoTime: null,
        };
        Object.keys(gaitSession.session.data).map((s, i) => {
            if (gaitSession.session.data[s].item === 'speed') {
                gaitSession.session.data[s].measures.map((m) => {
                    if (m.name === 'distance') {
                        footerColumns.distance = <GaitTableFooterMeasurement m={m} />;
                    }
                    if (m.name === 'avgTime') {
                        footerColumns.avgTime = <GaitTableFooterMeasurement m={m} />;
                    }
                    if (m.name === 'lapOneTime') {
                        footerColumns.lapOneTime = <GaitTableFooterMeasurement m={m} />;
                    }
                    if (m.name === 'lapTwoTime') {
                        footerColumns.lapTwoTime = <GaitTableFooterMeasurement m={m} />;
                    }
                });
            }
        });

        return footerColumns;
    };

    useEffect(() => {
        session.session.data.some((measure) => {
            if (measure.item === 'trunk' || measure.item === 'head') {
                setShowMaxColumn(true);
            }
        });
    }, [session]);

    useEffect(() => {
        setRows(convertToRows(session));
        setFooterMeasurements(createFooter(session));
    }, [session]);

    return (
        <SessionExpansionPanel elevation={0} onChange={handleAccordion} key={key}>
            <SessionExpansionPanelSummary expandIcon={<ExpandMore />}>
                <SessionExpansionPanelSummaryHeader>
                    <SessionExpansionPanelSummaryHeaderTop>
                        <SessionTitle data-cy="session-expanding-row-title">
                            Assessment
                            {session.session.meta.assistanceOption !== 'NONE' &&
                                `: ${session.session.meta.assistanceOption}`}
                            <SessionTime>{createdTime}</SessionTime>
                        </SessionTitle>
                        <SessionHeaderTopRight onClick={preventParentLinkClick}>
                            <SessionComment gaitSessionId={session.sessionUUID} text={session.session.meta.comment} />
                            <div>
                                <CopyRow sessionData={session} />
                            </div>
                        </SessionHeaderTopRight>
                    </SessionExpansionPanelSummaryHeaderTop>
                </SessionExpansionPanelSummaryHeader>
            </SessionExpansionPanelSummary>
            <SessionExpansionPanelDetails>
                {rows && rows.length > 0 && (
                    <GaitPatientSessionTable
                        columns={columnStructure}
                        data={rows}
                        version={session.sessionSchema}
                        showMaxColumn={showMaxColumn}
                    />
                )}
                {session.sessionSchema === GaitSessionVersion.GAIT_SA_2 && footerMeasurements && (
                    <GaitTableFooterContainer container>
                        <GaitTableFooterLeft item sm={4}>
                            Other measurements:
                        </GaitTableFooterLeft>
                        <GaitTableFooterRight item container sm={8} sx={{ backgroundColor: '#F7F8FA' }}>
                            {Object.keys(footerMeasurements).map((m) => footerMeasurements[m])}
                        </GaitTableFooterRight>
                    </GaitTableFooterContainer>
                )}
            </SessionExpansionPanelDetails>
        </SessionExpansionPanel>
    );
};
