import React, { useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { FormGroup, Checkbox, MenuItem, MenuList, FormControl } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import { addDays, addYears } from 'date-fns';
import { DateTime } from 'luxon';
import { DateRangePicker } from 'react-date-range';
import { useRecoilState } from 'recoil';
import { DateOptions } from '@/common/types';
import { CalendarIcon } from '@/components/common/Icons/CalendarIcon';
import { Button } from '@/components/common/button/button';
import { DateRangeState } from '@/recoil/atoms/dateRangeAtom';
import {
    CalendarWrapper,
    MenuWrapper,
    MenuContent,
    CalendarActions,
    MenuItemText,
    FormControlLabel,
    ButtonWrapper,
    MenuItemCustomDate,
} from './styles';

export const DateRangeActions = () => {
    const [dateRange, setDateRange] = useRecoilState(DateRangeState);
    const dateRangeOptions: DateOptions = {
        seven: addDays(new Date(), -7),
        thirty: addDays(new Date(), -30),
        ninety: addDays(new Date(), -90),
    };
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [isCustomRangeActive, setIsCustomRangeActive] = useState(false);

    const [calendarDates, setCalendarDates] = useState([
        {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            key: 'selection',
            color: '#6a41f1',
            showDateDisplay: false,
        },
    ]);

    const handleCalendarSelection = (item) => {
        setCalendarDates([item.selection]);
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const optionsSelected = {
            seven: false,
            thirty: false,
            ninety: false,
            [event.target.name]: event.target.checked,
        };
        const radioOptionSelected = Object.values(optionsSelected).includes(true);

        setIsCustomRangeActive(false);
        setDateRange({
            startDate: dateRangeOptions[event.target.name],
            endDate: new Date(), // Today since we don't go into the future only back to it
            rangeRadioOptions: optionsSelected,
        });
        setCalendarDates([
            { ...calendarDates[0], startDate: dateRangeOptions[event.target.name], endDate: new Date() },
        ]);

        if (!radioOptionSelected) {
            setDateRange({
                startDate: addYears(new Date(), -2),
                endDate: new Date(),
                rangeRadioOptions: optionsSelected,
            });
            setCalendarDates([{ ...calendarDates[0], startDate: addYears(new Date(), -2), endDate: new Date() }]);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuIsOpen(true);
        setMenuAnchor(event.currentTarget);
        setShowCalendar(false);
    };

    const handleClose = () => {
        setMenuAnchor(null);
        setMenuIsOpen(false);
    };

    const handleCancel = () => {
        handleClose();
        setTimeout(() => {
            setCalendarDates([{ ...calendarDates[0], startDate: dateRange.startDate, endDate: dateRange.endDate }]);
        }, 400);
    };

    const handleSubmit = () => {
        handleClose();
        setDateRange({
            startDate: calendarDates[0].startDate,
            endDate: calendarDates[0].endDate,
            rangeRadioOptions: {
                seven: false,
                thirty: false,
                ninety: false,
            },
        });
        setIsCustomRangeActive(true);
    };

    const handleCustomSelectionClick = () => {
        setShowCalendar(true);
    };

    return (
        <React.Fragment>
            <ButtonWrapper>
                <Tooltip title="Date range selection">
                    <Button
                        variant="tertiary"
                        size="small"
                        onClick={handleClick}
                        startIcon={<CalendarIcon />}
                        data-cy="date-range-button"
                    >
                        {`${DateTime.fromJSDate(dateRange.startDate).toFormat('dd LLL yyyy')} - ${DateTime.fromJSDate(
                            dateRange.endDate,
                        ).toFormat('dd LLL yyyy')}`}
                    </Button>
                </Tooltip>
            </ButtonWrapper>
            <MenuWrapper
                anchorEl={menuAnchor}
                id="date-menu"
                open={menuIsOpen}
                onClose={handleClose}
                elevation={0}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        mt: '20px',
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuContent>
                    {!showCalendar ? (
                        <MenuList>
                            <FormControl component="fieldset" variant="standard">
                                <FormGroup>
                                    <MenuItem>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={dateRange.rangeRadioOptions.seven}
                                                    onChange={handleRadioChange}
                                                    data-cy="date-range-checkbox-seven"
                                                    name="seven"
                                                />
                                            }
                                            label={<MenuItemText>Last 7 days</MenuItemText>}
                                        />
                                    </MenuItem>
                                    <MenuItem>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={dateRange.rangeRadioOptions.thirty}
                                                    onChange={handleRadioChange}
                                                    data-cy="date-range-checkbox-thirty"
                                                    name="thirty"
                                                />
                                            }
                                            label={<MenuItemText>Last 30 days</MenuItemText>}
                                        />
                                    </MenuItem>
                                    <MenuItem>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={dateRange.rangeRadioOptions.ninety}
                                                    onChange={handleRadioChange}
                                                    data-cy="date-range-checkbox-ninety"
                                                    name="ninety"
                                                />
                                            }
                                            label={<MenuItemText>Last 90 days</MenuItemText>}
                                        />
                                    </MenuItem>
                                </FormGroup>
                            </FormControl>
                            <Divider />
                            <MenuItemCustomDate onClick={handleCustomSelectionClick} $isActive={isCustomRangeActive}>
                                <ListItemIcon>
                                    <AddRoundedIcon fontSize="small" />
                                </ListItemIcon>
                                <MenuItemText>Custom date</MenuItemText>
                            </MenuItemCustomDate>
                        </MenuList>
                    ) : (
                        <CalendarWrapper>
                            <DateRangePicker
                                onChange={handleCalendarSelection}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                ranges={calendarDates}
                                direction="horizontal"
                                monthDisplayFormat="MMMM yyyy"
                                maxDate={new Date()}
                            />
                            <CalendarActions>
                                <Button variant="tertiary" onClick={handleCancel} size="small">
                                    Cancel
                                </Button>
                                <Button onClick={handleSubmit} size="small">
                                    Submit
                                </Button>
                            </CalendarActions>
                        </CalendarWrapper>
                    )}
                </MenuContent>
            </MenuWrapper>
        </React.Fragment>
    );
};
