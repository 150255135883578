import React, { useState } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import { ClickAwayListener, IconButton, MenuList } from '@mui/material';
import { Products } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { PatientRecord } from '@/common/types';
import {
    getStringEnumKeyByValue,
    productUserCanAdd,
    productsIncludesHealth,
    productsIncludesPRO,
} from '@/common/utils';
import { EditPatientAction } from '@/components/Patients/AllPatientsTable/PatientActions/EditPatientAction/EditPatientAction';
import { AddtoProductAction } from '../PatientActions/AddToProductAction/AddToProductAction';
import { AssignHEPAction } from '../PatientActions/AssignHEPAction/AssignHEPAction';
import { DischargeAction } from '../PatientActions/DischargeAction/DischargeAction';
import { ResendInviteAction } from '../PatientActions/ResendInviteAction/ResendInviteAction';
import { PatientActionsWrapper, PatientActionsMenu } from './styles';

interface PatientActionsProps {
    patientRecord: PatientRecord;
    openHEPModal: (event: MouseEvent | TouchEvent | React.TouchEvent | React.MouseEvent) => void;
    getPatients: () => void;
}
export const PatientActions = (props: PatientActionsProps) => {
    const { patientRecord, openHEPModal, getPatients } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { user } = useExerUser();
    const open = Boolean(anchorEl);
    let toAdd: Products | null = null;

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setAnchorEl(e.currentTarget);
    };
    const handleClose = (e) => {
        e.preventDefault();
        setAnchorEl(null);
    };

    if (patientRecord.products.length === 1) {
        const key = getStringEnumKeyByValue(Products, Products.HEALTH);
        toAdd = patientRecord.products.includes(key)
            ? productUserCanAdd(user, Products.GAIT)
            : productUserCanAdd(user, Products.HEALTH);
    }

    return (
        <PatientActionsWrapper>
            <IconButton
                aria-controls={open ? 'edit-patient-actions-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                aria-label="Patient Actions Button"
                id="edit-patient-actions-button"
                onClick={handleClick}
                edge="end"
            >
                <ArrowDropDown />
            </IconButton>

            <PatientActionsMenu
                onClick={(e) => e.stopPropagation()}
                id="edit-patient-actions-menu"
                aria-labelledby="edit-patient-actions-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                        <AssignHEPAction patientRecord={patientRecord} handleShowDialog={openHEPModal} />
                        <ResendInviteAction patientRecord={patientRecord} />
                        <EditPatientAction patientRecord={patientRecord} handleClose={handleClose} />
                        {toAdd && <AddtoProductAction patientRecord={patientRecord} toAdd={toAdd} />}

                        {(productsIncludesHealth(patientRecord.products) ||
                            productsIncludesPRO(patientRecord.products)) &&
                            !patientRecord.discharged && (
                                <DischargeAction
                                    patientRecord={patientRecord}
                                    handleClose={handleClose}
                                    onSuccess={getPatients}
                                />
                            )}
                    </MenuList>
                </ClickAwayListener>
            </PatientActionsMenu>
        </PatientActionsWrapper>
    );
};
