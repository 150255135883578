import { Box, styled } from '@mui/material';

export const FormItemContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
}));

export const FormLabelContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '> svg': {
        marginRight: theme.spacing(0.5),
    },
}));
