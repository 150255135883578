import React from 'react';
import { Link } from 'react-router-dom';
import LogoBlack from '@/images/exer-black.svg';
import { AuthContainer, AuthLogoWrapper, AuthLogoSubTitle, AuthFormWrapper, AuthLogo } from './styles';

interface Props {
    children: JSX.Element | JSX.Element[];
}
export const AuthLayout = (props: Props) => {
    const { children } = props;
    return (
        <AuthContainer container spacing={4}>
            <AuthLogoWrapper item xs={12} md={6}>
                <Link to="/user/login">
                    <AuthLogo type="image/svg+xml" data={LogoBlack} width="200" />
                </Link>
                <AuthLogoSubTitle variant="h5">
                    Motion intelligence software for the modern health enterprise.
                </AuthLogoSubTitle>
            </AuthLogoWrapper>
            <AuthFormWrapper item xs={12} md={6}>
                {children}
            </AuthFormWrapper>
        </AuthContainer>
    );
};
