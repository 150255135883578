import React, { useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, ListItemText, ListItemIcon, Tooltip } from '@mui/material';
import { HEPBlock } from '@/common/types';
import { preventParentLinkClick } from '@/common/utils';
import { AddToProgramModal } from '@/components/Health/HEPBuilder/HEPBuilderExercises/AddToProgram/styles';
import { EditBlock } from '../EditBlock/EditBlock';
import { useHEPBuilderContext } from '../state/hooks/HEPBuilderContext';
import { HEPBlockDeleteIcon, HEPBlockEditIcon, TooltipDiv } from './styles';

interface Props {
    block: HEPBlock;
    index: number;
}

export const HEPBlockActions = (props: Props) => {
    const { block, index } = props;
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const open = Boolean(menuAnchor);
    const { hepBuilderActions, patientRecord } = useHEPBuilderContext();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        preventParentLinkClick(event);
        setMenuAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchor(null);
    };
    const handleDeleteClick = (_: React.MouseEvent) => {
        hepBuilderActions?.deleteBlock(index);
        handleClose();
    };

    const handleEditClick = (_: React.MouseEvent) => {
        setEditDialogOpen(true);
        handleClose();
    };

    const handleEditSubmit = (hepBlock: HEPBlock) => {
        hepBuilderActions?.editBlock({ index, hepBlock });
        setEditDialogOpen(false);
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    };

    return (
        <TooltipDiv>
            <Tooltip title="Edit Exercise Actions">
                <IconButton onClick={handleClick} size="small" data-cy="edit-exercise-actions">
                    <MoreVert />
                </IconButton>
            </Tooltip>

            <Menu
                anchorEl={menuAnchor}
                id="account-menu"
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'three-dot-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {block.setGoal && block.numSets && (
                    <MenuItem onClick={handleEditClick} data-cy="edit-exercise-action">
                        <ListItemIcon>
                            <HEPBlockEditIcon />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                    </MenuItem>
                )}
                <MenuItem onClick={handleDeleteClick}>
                    <ListItemIcon>
                        <HEPBlockDeleteIcon />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>

            <AddToProgramModal
                open={editDialogOpen}
                onClose={handleEditDialogClose}
                title="Edit Exercise Metrics"
                fullWidth={true}
                maxWidth={'lg'}
            >
                <EditBlock
                    exerciseMetricId={block.exerciseId}
                    onSubmit={handleEditSubmit}
                    onCancel={handleEditDialogClose}
                    initialBlock={block}
                    patientRecord={patientRecord}
                />
            </AddToProgramModal>
        </TooltipDiv>
    );
};
