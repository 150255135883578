import React from 'react';
import { AppBar, Box } from '@mui/material';
import X from '@/images/x.svg';
import { HeaderLogoImg, HeaderWrapper } from './styles';

export const ErrorHeader = () => {
    return (
        <AppBar elevation={0}>
            <HeaderWrapper>
                <HeaderLogoImg src={X} />
                <Box sx={{ flexGrow: 1 }} />
            </HeaderWrapper>
        </AppBar>
    );
};
