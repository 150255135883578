import React from 'react';
import { Typography, Stack, Divider, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { useRecoilValue } from 'recoil';
import { RTMItem } from '@/common/types';
import { DateFilterActions } from '@/components/PatientBilling/PatientsBillingTable/Filters/DateFilter/DateFilterActions';
import { StatusFilterActions } from '@/components/PatientBilling/PatientsBillingTable/Filters/StatusFilter/StatusFilterActions';
import { NoPatients } from '@/components/Patients/NoPatients';
import { LocationFilter } from '@/components/common/Table/Filters/LocationFilter';
import { PaginationContainer } from '@/components/common/Table/PaginationContainer';
import { ListControlsContainer, PatientsTableWrapper } from '@/components/common/Table/PatientsTable/styles';
import { TableWrapper } from '@/components/common/Table/styles';
import { RTMSelector } from '@/recoil/selectors/rtmSelector';
import { RTMAccordion } from './Accordion/RTMAccordion';
import { RTMGridContainer, ListControlsFilters, RTMGridStatusHeader, RTMGridActionHeader, DateTitle } from './styles';

interface CyclesByMonthTableProps {
    data: RTMItem[];
}

export const CyclesByMonthTable = (props: CyclesByMonthTableProps) => {
    const { data } = props;
    const { selectedRTMMonth } = useRecoilValue(RTMSelector);

    return (
        <PatientsTableWrapper>
            <ListControlsContainer>
                <DateTitle variant="h3">{DateTime.fromISO(selectedRTMMonth).toFormat('LLLL yyyy')}</DateTitle>

                <ListControlsFilters>
                    <LocationFilter />
                    <DateFilterActions dateType="month" />
                    <StatusFilterActions />
                </ListControlsFilters>
            </ListControlsContainer>
            <TableWrapper>
                {data.length > 0 ? (
                    <>
                        <Stack spacing={0} divider={<Divider flexItem style={{ padding: 0, margin: 0 }} />}>
                            <RTMGridContainer container spacing={2} style={{ marginBottom: 14 }}>
                                <Grid xs={7} item>
                                    <Typography>Name</Typography>
                                </Grid>
                                <RTMGridStatusHeader xs={2} item>
                                    <Typography>Status</Typography>
                                </RTMGridStatusHeader>
                                <RTMGridActionHeader xs={3} item>
                                    <Typography>Action</Typography>
                                </RTMGridActionHeader>
                            </RTMGridContainer>
                            {data.map((item) => (
                                <RTMAccordion key={`${item.patientRecord.id}-${item.yearMonth}`} {...item} />
                            ))}
                        </Stack>
                    </>
                ) : (
                    <NoPatients />
                )}
            </TableWrapper>
            <PaginationContainer />
        </PatientsTableWrapper>
    );
};
