import React from 'react';
import { SlideDownTransition } from '@/components/common/Transitions/SlideTransition';
import { Modal as StyledModal, ModalTitle, ModalContent } from './styles';

export const Modal = (props) => {
    const { title, children } = props;

    return (
        <StyledModal TransitionComponent={SlideDownTransition} {...props}>
            <ModalTitle>{title}</ModalTitle>
            <ModalContent>{children}</ModalContent>
        </StyledModal>
    );
};
