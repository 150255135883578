import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

interface PatientTableProp {
    currentTab: number;
}

export const patientTableState = atom<PatientTableProp>({
    key: 'patientTableState',
    default: {
        currentTab: 0,
    },
    effects: [persistAtom],
});
