import React from 'react';
import { SvgIcon } from '@mui/material';

export const FeetIcon = (props) => {
    return (
        <>
            <SvgIcon width="21" height="27" viewBox="0 0 21 27" fill="none" {...props} style={{ fill: 'none' }}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.03966 11.3795C9.03966 11.3795 9.93059 9.11008 9.44382 6.6743C8.95782 4.23735 8.5878 3.02993 7.49946 2.3054C7.00842 1.97882 4.6634 1.38035 3.77325 3.90069C2.88155 6.42141 2.30868 8.25601 4.6824 12.4698C4.68279 12.4713 6.69076 11.7984 9.03966 11.3795Z"
                    stroke="currentcolor"
                    strokeWidth="1.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.49841 13.1691L5.16675 14.079C5.16675 14.079 5.39559 16.3926 5.92619 17.7152C6.45678 19.0374 7.6739 19.2853 8.81344 18.8714C9.95454 18.4591 10.4847 17.4666 10.3327 16.3922C10.1826 15.3174 9.49841 13.1691 9.49841 13.1691Z"
                    stroke="currentcolor"
                    strokeWidth="1.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8819 18.1648C12.8819 18.1648 11.9933 15.9412 12.4778 13.5539C12.9626 11.1658 13.3315 9.98241 14.4163 9.27301C14.907 8.95302 17.2435 8.36657 18.1313 10.8361C19.0203 13.3064 19.5912 15.1041 17.226 19.233C17.2245 19.2345 15.2231 18.5755 12.8819 18.1648Z"
                    stroke="currentcolor"
                    strokeWidth="1.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.4124 20.1161L16.8239 21.026C16.8239 21.026 16.592 23.3392 16.0502 24.6615C15.5091 25.9829 14.2699 26.2308 13.1086 25.8169C11.9473 25.405 11.4063 24.4121 11.5618 23.3388C11.715 22.2637 12.4124 20.1161 12.4124 20.1161Z"
                    stroke="currentcolor"
                    strokeWidth="1.5"
                />
            </SvgIcon>
        </>
    );
};
