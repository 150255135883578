import React, { ChangeEvent } from 'react';
import { PatientRecord, Session } from '@/common/types';
import { PROChartDataDict } from '@/components/Health/PatientSessions/PROs/types';
import { DateRangeActions } from '@/components/common/DateRangeActions/DateRangeActions';
import { DateRangeWrapper } from '@/components/common/DateRangeActions/styles';
import { SelfReportedFilter } from '@/components/common/SelfReportedFilter/SelfReportedFilter';
import { DateRangeAtomState } from '@/recoil/atoms/dateRangeAtom';
import { ROMDataDict } from '../../PatientSessions/Charts/types';
import { ActivityTab } from './ActivityTab';
import { InteractionTab } from './InteractionTabTab';
import { PROTab } from './PROTab';
import { ROMTab } from './ROMTab';
import { TabControls } from './TabControls';

interface PatientTabsProps {
    currentTab: number;
    handleTabChange: (event: ChangeEvent<HTMLButtonElement>, newValue: number) => void;
    patientRecord: PatientRecord;
    isLoadingPROMessages: boolean;
    dateRange: DateRangeAtomState;
    hasFetched: boolean;
    sessions: Session[] | undefined;
    hasSessions: () => boolean;
    romDictDataFiltered: ROMDataDict | undefined;
    handleChartSelection: ({ optionName, optionId }: { optionName: string; optionId: string }) => void;
    currentChart: string;
    proChartDataDict: PROChartDataDict | undefined;
    proExistsInRange: () => boolean | undefined;
    inModal: boolean;
}

export const PatientTabs = (props: PatientTabsProps) => {
    const {
        currentTab,
        handleTabChange,
        patientRecord,
        isLoadingPROMessages,
        dateRange,
        hasFetched,
        sessions,
        hasSessions,
        romDictDataFiltered,
        handleChartSelection,
        currentChart,
        proChartDataDict,
        proExistsInRange,
        inModal,
    } = props;

    return (
        <>
            <TabControls
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                patientRecord={patientRecord}
                isLoadingPROMessages={isLoadingPROMessages}
                isInModal={inModal}
            />
            <DateRangeWrapper $inModal={inModal}>
                <DateRangeActions />
                <SelfReportedFilter />
            </DateRangeWrapper>
            <ActivityTab
                currentTab={currentTab}
                patientRecord={patientRecord}
                dateRange={dateRange}
                hasFetched={hasFetched}
                sessions={sessions}
            />
            <ROMTab
                currentTab={currentTab}
                dateRange={dateRange}
                hasSessions={hasSessions}
                romDictDataFiltered={romDictDataFiltered}
                handleChartSelection={handleChartSelection}
                currentChart={currentChart}
            />
            <PROTab
                currentTab={currentTab}
                dateRange={dateRange}
                proChartDataDict={proChartDataDict}
                proExistsInRange={proExistsInRange}
            />
            <InteractionTab currentTab={currentTab} patientRecord={patientRecord} />
        </>
    );
};
