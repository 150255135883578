import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { HEPTemplate } from '@/common/types';
import { AddHEPAction } from '@/components/Health/HEPs/AddHEPAction/AddHEPAction';
import { DeleteHEPAction } from '@/components/Health/HEPs/DeleteHEPActions/DeleteHEPAction';
import { TemplateCard } from '@/components/Health/HEPs/HEPTemplatesCardList/HEPTemplateCard/TemplateCard';
import { HEPTemplateSearch } from '@/components/Health/HEPs/HEPTemplatesCardList/HEPTemplateSeach/HEPTemplateSearch';
import { EmptyData } from '@/components/common/EmptyData/EmptyData';
import { ComputerGraphIcon } from '@/components/common/Icons/ComputerGraphIcon';
import {
    TemplateActionsWrapper,
    TemplateCardListTitle,
    ListControlsContainer,
    HEPTemplatesCardListWrapper,
} from './styles';

interface HEPTemplatesCardListProps {
    data: HEPTemplate[];
}

export const HEPTemplatesCardList = (props: HEPTemplatesCardListProps) => {
    const { data } = props;
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const [templatesExist, setTemplatesExist] = useState<boolean>();

    const filterData = (query, data) => {
        if (!query) {
            return data;
        } else {
            return data.filter((d) => d.name.toLowerCase().includes(query));
        }
    };

    useEffect(() => {
        setTemplatesExist(data && data.length > 0);
    }, [data]);

    useEffect(() => {
        setFilteredData(filterData(searchQuery, data));
    }, [searchQuery, data]);

    return (
        <HEPTemplatesCardListWrapper data-cy="program-templates-content">
            {!templatesExist ? (
                <EmptyData
                    title="No Program templates created yet"
                    body="Create your first template."
                    icon={<ComputerGraphIcon />}
                />
            ) : (
                <TemplateCardListTitle
                    sx={{ display: 'flex', justifyContent: templatesExist ? 'flex-start' : 'center' }}
                >
                    View and manage Program Templates
                </TemplateCardListTitle>
            )}
            <ListControlsContainer container spacing={3}>
                {templatesExist && (
                    <Grid item xs={12} sm={6} md={4}>
                        <HEPTemplateSearch setSearchTerm={setSearchQuery} />
                    </Grid>
                )}
                <TemplateActionsWrapper
                    item
                    xs={12}
                    sm={templatesExist ? 6 : 12}
                    md={templatesExist ? 8 : 12}
                    sx={{ display: 'flex', justifyContent: templatesExist ? 'flex-end' : 'center' }}
                >
                    <AddHEPAction />
                </TemplateActionsWrapper>
            </ListControlsContainer>

            <DeleteHEPAction />
            <Grid container spacing={3} data-cy="program-templates-card-wrapper">
                {templatesExist &&
                    filteredData.map((program: HEPTemplate) => (
                        <Grid item xs={12} sm={6} md={4} xl={3} key={program.id}>
                            <TemplateCard hepTemplete={program} />
                        </Grid>
                    ))}
            </Grid>
        </HEPTemplatesCardListWrapper>
    );
};
