import { styled } from '@mui/material';
import { Dialog as MUIDialog, DialogContent as MUIDialogContent } from '@mui/material';

export const Dialog = styled(MUIDialog)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
}));

export const DialogContent = styled(MUIDialogContent)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(2),
}));

export const DialogContentWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    marginTop: theme.spacing(2),

    '& h2': {
        textAlign: 'center',
        margin: `0 0 24px`,
    },
    '& h3': {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 'normal',
        textAlign: 'center',
        margin: `0 0 24px`,
    },
}));
