import React, { useEffect, useState } from 'react';
import { SideOfBody } from '@/common/const';
import { ExerciseMetricSummary, HEPBlock, PatientRecord } from '@/common/types';
import { useExercisesContext } from '@/components/context/hooks/ExercisesContext';
import { ExerciseAnimationLazy as ExerciseAnimation } from '../HEPBuilderExercises/ExerciseAnimationLazy';
import { EditBlockMetric } from './EditBlockMetric';
import { EditBlockSide } from './EditBlockSide';
import { EditBlockContentHeader, EditBlockImg } from './styles';

interface Props {
    exerciseMetricId: number;
    onSubmit: (hepBlock: HEPBlock) => void;
    onCancel: () => void;
    initialBlock?: HEPBlock;
    patientRecord?: PatientRecord | null;
}

export const EditBlock = (props: Props) => {
    const { exerciseMetricId, initialBlock, patientRecord } = props;
    const { exercisesDict } = useExercisesContext();
    const [exerciseMetricSummary, setExerciseMetricSummary] = useState<ExerciseMetricSummary>();

    useEffect(() => {
        if (exerciseMetricId && exercisesDict) {
            setExerciseMetricSummary(exercisesDict[exerciseMetricId]);
        }
    }, [exerciseMetricId, exercisesDict]);

    const [sideOfBody, setSideOfBody] = useState<keyof typeof SideOfBody | undefined>(initialBlock?.sideOfBody);

    return exerciseMetricSummary ? (
        <>
            <EditBlockContentHeader>
                <div>
                    {exerciseMetricSummary.animationUrl ? (
                        <ExerciseAnimation
                            animationUrl={exerciseMetricSummary.animationUrl!}
                            exerciseMetricId={exerciseMetricId}
                            activeFrame={exerciseMetricSummary.animationThumbnailFrame}
                            style={{
                                minWidth: 60,
                                width: 60,
                                padding: 0.5,
                            }}
                        />
                    ) : (
                        <EditBlockImg
                            src={
                                exerciseMetricSummary.thumbnailUrl ||
                                'https://exer-exercise-media.s3.amazonaws.com/thumbnails/placeholder.jpg'
                            }
                        />
                    )}
                </div>
                <div>
                    <h4>{exerciseMetricSummary.name}</h4>
                </div>
            </EditBlockContentHeader>
            <EditBlockSide
                patientRecord={patientRecord}
                sideOfBody={sideOfBody}
                setSideOfBody={setSideOfBody}
                exerciseNeedsSide={false}
                showToggle={false}
            />
            <EditBlockMetric exerciseMetric={exerciseMetricSummary} {...props} sideOfBody={sideOfBody} />
        </>
    ) : null;
};
