import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { styled, CardHeader, CardActions, CardActionArea, CardContent, Typography, Card } from '@mui/material';
import { CalendarIcon } from '@/components/common/Icons/CalendarIcon';
import { CheckMarkIcon } from '@/components/common/Icons/CheckMarkIcon';

export const TemplateCardWrapper = styled(Card)(({ theme }) => ({
    height: '100%',
    boxShadow: '0px 2px 5px rgba(16, 5, 51, 0.04)',
    transition: '.4s ease box-shadow',

    '&:hover': {
        boxShadow: `0 0 0 4px ${theme.palette.primary[300]}`,
    },

    '&.MuiCard-root': {
        padding: 0,
    },
}));

export const TemplateCardHeader = styled(CardHeader)(({ theme }) => ({
    padding: 0,
    width: '100%',
    '.MuiCardHeader-subheader': {
        color: theme.palette.primary[500],
        fontSize: theme.typography.body1.fontSize,
    },
    '.MuiCardHeader-avatar': {
        marginRight: theme.spacing(0.5),
    },
}));

export const TemplateCardHeaderActions = styled(CardActions)(({ theme }) => ({
    padding: theme.spacing(2),
    paddingBottom: 0,
}));

export const TemplateCardActionArea = styled(CardActionArea)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
}));

export const TemplateCardContent = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(2),
    ':last-child': {
        paddingBottom: 0,
    },
}));

export const TemplateCardTitle = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'capitalize',
    paddingRight: '45px',
}));

export const TemplateCardExerciseWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: theme.palette.grey[900],
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'uppercase',
    marginTop: theme.spacing(2),

    '.MuiSvgIcon-root': {
        fontSize: '1.25rem',
        fill: 'none',
        marginRight: theme.spacing(1),
        '& path': {
            stroke: theme.palette.grey[600],
        },
    },
}));

export const TemplateCardNotes = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.grey[600],
    marginTop: theme.spacing(2),
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
}));

export const TemplateCardCalIcon = styled(CalendarIcon)(({ theme }) => ({
    color: theme.palette.primary[500],
    fontSize: '14px',
}));

export const TemplateCardCheckmarkIcon = styled(CheckMarkIcon)(({ theme }) => ({
    color: theme.palette.error[500],
    fontSize: '22px',
}));

export const TemplateCardCheckmarkOutlineIcon = styled(CheckBoxOutlineBlankIcon)(({ theme }) => ({
    fontSize: '22px',
}));
