import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { toasts } from '@/common/toasts';
import { OrganizationAdmin, ScanModule, SortableScanModule } from '@/common/types';
import { saturateModulesWithAssessments } from '@/common/utils';
import { useAssessmentsContext } from '@/components/context/hooks/AssessmentsContext';
import { AdminModulesList } from './Modules/ModulesList';

interface AdminOrganizationScanModulesProps {
    organization: OrganizationAdmin;
    submitScanModules: (scanModules: ScanModule[]) => Promise<void>;
}

export const AdminOrganizationScanModules = (props: AdminOrganizationScanModulesProps) => {
    const { organization, submitScanModules } = props;
    const { errorToast } = toasts;
    const [modules, setModules] = useState<SortableScanModule[]>([]);
    const { assessmentsDict } = useAssessmentsContext();
    const assessments = assessmentsDict ? Object.values(assessmentsDict) : undefined;

    useEffect(() => {
        (async () => {
            if (assessmentsDict && organization?.scanModules) {
                try {
                    setModules(saturateModulesWithAssessments(organization.scanModules, assessmentsDict));
                } catch (e) {
                    errorToast('Could not parse organization scan modules.');
                    return;
                }
            }
        })();
    }, [assessmentsDict, organization]);

    return organization ? (
        <>
            <Typography variant="h4">Scan Modules</Typography>
            <Box>
                {assessments && (
                    <AdminModulesList
                        assessments={assessments}
                        scanModules={modules}
                        submitScanModules={submitScanModules}
                    />
                )}
            </Box>
        </>
    ) : null;
};
