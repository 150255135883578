import React, { FormEvent, useCallback, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { Maybe, ScanSessionAdmin, ScanSessionsPagedRequestParams } from '@/common/types';
import { FormDateField } from '@/components/common/Form/Form';
import { ServerError } from '@/components/common/Form/styles';
import { LocationFilter } from '@/components/common/Table/Filters/LocationFilter';
import { usePaginationContext } from '@/components/context/hooks/PaginationContext';
import { resultIsError } from '@/services/HttpService';
import { ListControlsContainer } from '../Sessions/styles';
import { ScanReportResults } from './ScanReportsResults';

const shape = {
    startEnd: yup.date().nullable().default(undefined),
};
const schema = yup.object().shape(shape);

export const ScanReports = () => {
    const [serverError, setServerError] = useState<string | null>();
    const [sessions, setSessions] = useState<ScanSessionAdmin[]>();
    const { filters } = usePaginationContext();
    const [loading, setLoading] = useState(false);
    const [startEnd, setStartEnd] = useState<Date>(new Date());
    const { httpService } = useHttpContext();
    const { handleSubmit, control } = useForm({
        defaultValues: {
            startEnd: DateTime.now() as unknown as Date, //TODO need to wrangle some of the times still
        },
        resolver: yupResolver(schema),
    });

    const { errors } = useFormState({
        control,
    });

    const getRequestParams = useCallback((): Maybe<ScanSessionsPagedRequestParams> => {
        const params = {} as ScanSessionsPagedRequestParams;
        params.page = 1;
        // We want all sessions for that day, no pagination
        params.limit = 999;

        filters.forEach((filter) => {
            params[filter.filterKey] = filter.filterValue;
        });
        if (!params['locationId']) {
            return undefined;
        }
        return params;
    }, [filters]);

    const onSubmit = async (data: { startEnd: Date }) => {
        setServerError(null);
        setLoading(true);
        const start = DateTime.fromJSDate(new Date(data.startEnd)).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        });
        const end = DateTime.fromJSDate(new Date(data.startEnd)).set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
        });
        setStartEnd(data.startEnd);
        const result = await httpService.getScanSessions({
            ...getRequestParams(),
            start: start.toISO(),
            end: end.toISO(),
        });
        if (resultIsError(result)) {
            setServerError(result.message);
            return;
        }
        setSessions(result.items);
        setLoading(false);
    };

    const validateAndSubmit = (e: FormEvent) => {
        e.preventDefault();
        handleSubmit(onSubmit)();
    };

    return (
        <>
            <ListControlsContainer />
            {!sessions ? (
                <form onSubmit={validateAndSubmit}>
                    <Grid
                        container
                        sx={{
                            alignItems: 'center',
                        }}
                    >
                        <Grid item alignSelf="center">
                            <LocationFilter />
                        </Grid>
                        {/* TODO Ugly hardcoded height */}
                        <Grid item height={40}>
                            <FormDateField
                                control={control}
                                errors={errors}
                                name="startEnd"
                                label={'Event Date'}
                                required={false}
                                noFuture={true}
                                small={true}
                            />
                        </Grid>
                        <Grid item alignContent={'center'}>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={
                                    !filters.find((filter) => {
                                        return filter.filterKey === 'locationId';
                                    })?.filterValue
                                }
                            >
                                Generate Report
                            </Button>
                        </Grid>
                    </Grid>
                    <ServerError>{serverError || '\u00a0'}</ServerError>
                </form>
            ) : loading ? (
                <LoadingButton loading={true} variant="primary" disabled={true}>
                    Fetching Data
                </LoadingButton>
            ) : sessions ? (
                <ScanReportResults sessions={sessions} setSessions={setSessions} startEnd={startEnd} />
            ) : (
                <ServerError>{serverError || '\u00a0'}</ServerError>
            )}
        </>
    );
};
