import React from 'react';
import { Grid } from '@mui/material';
import { useExerUser } from '@/common/hooks/ExerUser';
import { DashboardPatients } from '@/common/types';
import { AccountStatsCard } from '@/components/Dashboard/StatsCards/AccountStatsCard';
import { ActivityStatsCard } from '@/components/Dashboard/StatsCards/ActivityStatsCard';
import { HEPStatsCard } from '@/components/Dashboard/StatsCards/HEPStatsCard';
import { RTMStatsCard } from '@/components/Dashboard/StatsCards/RTMStatsCard';

interface DashboardContainerProps {
    data: DashboardPatients;
}

export const DashboardContainer = (props: DashboardContainerProps) => {
    const { data } = props;
    const { isHealthUser } = useExerUser();

    return (
        <Grid container spacing={3}>
            {isHealthUser() && (
                <Grid item sm={6} xs={12}>
                    {data?.NO_HEP && <HEPStatsCard data={data.NO_HEP} />}
                </Grid>
            )}
            <Grid item sm={6} xs={12}>
                {data.NO_RECENT_SESSION && <ActivityStatsCard data={data.NO_RECENT_SESSION} />}
            </Grid>

            {isHealthUser() && (
                <Grid item sm={6} xs={12}>
                    {data.READY_TO_BILL && <RTMStatsCard data={data.READY_TO_BILL} />}
                </Grid>
            )}
            <Grid item sm={6} xs={12}>
                {data.NO_ACCOUNT && <AccountStatsCard data={data.NO_ACCOUNT} />}
            </Grid>
        </Grid>
    );
};
