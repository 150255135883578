import React from 'react';
import { Box } from '@mui/material';
import { Program } from '@/common/types';
import { SemiStrong } from '@/components/common/styles';
import { HEPBlocks } from './HEPBlocks/HEPBlocks';
import { HEPDetailsHeader, HEPDetailsNotes, HEPDetailsTitle } from './styles';

interface Props {
    program: Program;
}

export const HEPDetails = (props: Props) => {
    const { program } = props;

    return (
        <Box>
            <Box>
                <HEPDetailsTitle variant="h4">{program.name}</HEPDetailsTitle>

                <HEPDetailsHeader variant="caption">Program Notes</HEPDetailsHeader>
                <HEPDetailsNotes>{program.notes || '-'}</HEPDetailsNotes>

                <HEPDetailsHeader variant="caption">Program Frequency</HEPDetailsHeader>
                <p>
                    <SemiStrong>
                        Daily Frequency: {program.dailyFrequency} {`\u00a0`} Weekly Frequency: {program.weeklyFrequency}
                    </SemiStrong>
                </p>
                <HEPDetailsHeader variant="caption">Exercises</HEPDetailsHeader>
                <HEPBlocks blocks={program.blocks} />
            </Box>
        </Box>
    );
};
