import { Menu, styled } from '@mui/material';

export const PatientActionsWrapper = styled('span')(({ theme }) => ({
    pointerEvents: 'auto',
    textAlign: 'right',
    display: 'block',

    '& .MuiIconButton-root': {
        color: theme.palette.grey[600],
    },
}));

export const PatientActionsMenu = styled(Menu)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
    },
}));
