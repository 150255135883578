import React, { Dispatch, SetStateAction, useState } from 'react';
import { Box } from '@mui/material';
import { useAsyncDebounce } from '@/common/react-table-utils';
import { NameTextField } from '@/components/common/Table/PatientsTable/styles';

interface AdminScanSearchProps {
    setSearchTerm: Dispatch<SetStateAction<string | null | undefined>>;
}
export const AdminScanSearch = (props: AdminScanSearchProps) => {
    const { setSearchTerm } = props;
    const [inputValue, setInputValue] = useState<string | null>();

    const onChange = useAsyncDebounce((value) => {
        setSearchTerm(value || undefined);
    }, 200);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        onChange(e.target.value);
    };

    return (
        <Box sx={{ display: 'flex', maxWidth: 600, marginBottom: '24px' }}>
            <NameTextField
                value={inputValue || ''}
                onChange={handleChange}
                id="search"
                label="Search"
                variant="outlined"
                size="small"
            />
        </Box>
    );
};
