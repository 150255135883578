import React from 'react';
import { Grid } from '@mui/material';
import { ExerciseGroupedSet } from '@/common/types';
import { SetInfoTable } from '../SetInfoTable/SetInfoTable';
import { ExerciseSetWrapper, ExerciseSetTitle } from './styles';

interface Props {
    exerciseSet: ExerciseGroupedSet;
}
export const ExerciseRepsOrTimeSet = (props: Props) => {
    const { exerciseSet } = props;

    const setInfoTables = exerciseSet.sets.map((set, i) => {
        // eslint-disable-next-line react/no-array-index-key
        return <SetInfoTable set={set} i={i} key={i} />;
    });

    return (
        <ExerciseSetWrapper>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <ExerciseSetTitle>Reps Complete</ExerciseSetTitle>
                </Grid>
                <Grid item xs={4}>
                    <ExerciseSetTitle>Total Time</ExerciseSetTitle>
                </Grid>
                <Grid item xs={4}>
                    <ExerciseSetTitle>Errors</ExerciseSetTitle>
                </Grid>
            </Grid>
            <>{setInfoTables}</>
        </ExerciseSetWrapper>
    );
};
