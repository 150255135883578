import React from 'react';
import { MenuItem } from '@mui/material';
import { HealthPatientStatus, RecordType } from '@/common/const';
import { PatientRecord } from '@/common/types';
import { getStringEnumKeyByValue } from '@/common/utils';
import { ResendInvite } from '@/components/Health/HealthPatientActions/ResendInvite/ResendInvite';
import { ResendInviteActionWrapper } from './styles';

interface Props {
    patientRecord: PatientRecord;
}
export const ResendInviteAction = (props: Props) => {
    const { patientRecord } = props;

    const shouldResend =
        patientRecord.productData?.health?.status ===
        getStringEnumKeyByValue(HealthPatientStatus, HealthPatientStatus.NO_ACCOUNT);

    return shouldResend ? (
        <MenuItem>
            <ResendInviteActionWrapper>
                <ResendInvite
                    recordId={patientRecord.id}
                    name={patientRecord.patient.firstName}
                    recordType={RecordType.PATIENT}
                />
            </ResendInviteActionWrapper>
        </MenuItem>
    ) : null;
};
