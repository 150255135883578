import React, { useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Location } from '@/common/types';
import { Modal } from '@/components/common/Modal/Modal';
import { OrganizationAddEntityButton } from '../../styles';
import { LocationForm } from '../LocationForm/LocationForm';

export const newLocation: Location = {
    name: '',
    address1: '',
    address2: '',
    city: '',
    zipCode: '',
    timezone: '',
};

export const AddLocation = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const dialogRef = useRef(null);

    const handleAddClick = (e: React.MouseEvent) => {
        e.preventDefault();
        setDialogOpen(true);
    };

    const handleCancelClick = (_: React.MouseEvent) => {
        setDialogOpen(false);
    };

    return (
        <>
            <OrganizationAddEntityButton onClick={handleAddClick} startIcon={<AddIcon />} size="small">
                Add Location
            </OrganizationAddEntityButton>
            <Modal
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                fullWidth={true}
                maxWidth={'lg'}
                title="Add Location"
            >
                <LocationForm
                    action="create"
                    location={newLocation}
                    handleCancel={handleCancelClick}
                    scrollTarget={dialogRef}
                />
            </Modal>
        </>
    );
};
