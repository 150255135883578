import React, { useState } from 'react';
import { Add, Close } from '@mui/icons-material';
import { Exercise, HEPBlock } from '@/common/types';
import { ModalCloseButton } from '@/components/common/Modal/styles';
import { Button } from '@/components/common/button/button';
import { AddBlock } from '../../EditBlock/AddBlock';
import { useHEPBuilderContext } from '../../state/hooks/HEPBuilderContext';
import { AddToProgramModal } from './styles';

interface Props {
    exercise: Exercise;
    onSubmit?: () => void;
    isInCard?: boolean;
}

export const AddToProgramAction = (props: Props) => {
    const { exercise, onSubmit, isInCard } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const { hepDetails, hepBuilderActions } = useHEPBuilderContext();

    const handleClick = (e: React.MouseEvent) => {
        setDialogOpen(true);
    };

    const handleCloseClick = (e: React.MouseEvent) => {
        setDialogOpen(false);
    };

    const handleSubmit = (hepBlock: HEPBlock) => {
        hepBuilderActions?.setBlocks(hepDetails.blocks.concat([hepBlock]));
        setDialogOpen(false);
        onSubmit && onSubmit();
    };

    const handleCancel = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <Button
                size="small"
                variant={!isInCard ? 'primary' : 'secondary'}
                onClick={handleClick}
                startIcon={!isInCard ? <Add /> : undefined}
            >
                Add to Program
            </Button>
            <AddToProgramModal
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                title="Select Exercise Metrics"
                fullWidth={true}
                maxWidth={'lg'}
                data-cy="add-to-program-modal"
            >
                {exercise && exercise.id && (
                    <AddBlock
                        exercise={exercise}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        initialBlock={undefined}
                    />
                )}
                <ModalCloseButton onClick={handleCloseClick}>
                    <Close />
                </ModalCloseButton>
            </AddToProgramModal>
        </>
    );
};
