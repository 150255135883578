import {
    AccordionSummary as MuiAccordionSummary,
    RadioGroup as MuiRadioGroup,
    styled,
    Typography,
    Paper,
} from '@mui/material';

export const ChartContainer = styled('div')(({ theme }) => ({}));

export const RadioGroup = styled(MuiRadioGroup)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    textTransform: 'capitalize',
}));

export const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({}));

export const AccordionTitle = styled(Typography)(({ theme }) => ({
    display: 'block',
    [theme.breakpoints.up('lg')]: {
        marginBottom: '12px',
    },
}));

export const LegendWrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

export const LegendGridItem = styled('div')(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[200]}`,
    span: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2),
    },
}));

export const LegendGridPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    cursor: 'pointer',
    '& ~ .MuiPaper-root': {
        borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
    div: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export const LegendGridIcon = styled('span')<{ $isDashed: boolean; $color: 'string' }>(
    ({ theme, $isDashed, $color }) => ({
        height: '1px',
        width: '32px',
        borderBottom: $isDashed ? `1px dashed ${$color}` : `1px solid ${$color}`,
    }),
);
