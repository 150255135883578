import React from 'react';
import { Products } from '@/common/const';
import { getStringEnumKeyByValue, isHealthOrPROPatient } from '@/common/utils';
import { AssignHepAction } from '@/components/Health/HealthPatientActions/AssignHEPAction/AssignHepAction';
import { PatientHEPMenuContainer } from '@/components/Patients/Patient/PatientHEP/PatientHEPMenuContainer';
import { useHEPContext } from '@/components/context/hooks/HEPContext';
import { usePatientContext } from '../context/hooks/PatientContext';
import { PatientDetailsCalloutWrapper } from '../styles';
import { PatientHEPLabel } from './styles';

export const PatientHEP = () => {
    const { patientRecord, setHEPBuilderDialogOpen, product } = usePatientContext();
    const { program } = useHEPContext();

    const handleOpenBuilder = () => {
        setHEPBuilderDialogOpen(true);
    };

    return patientRecord &&
        isHealthOrPROPatient(patientRecord) &&
        product !== getStringEnumKeyByValue(Products, Products.GAIT) ? (
        <PatientDetailsCalloutWrapper>
            <div>
                <h3>Program</h3>
                {program ? (
                    <>
                        <PatientHEPLabel>Name: </PatientHEPLabel>
                        {program.name}
                    </>
                ) : (
                    'Patient does not have a Program assigned yet.'
                )}
            </div>
            <div>{program ? <PatientHEPMenuContainer /> : <AssignHepAction openBuilder={handleOpenBuilder} />}</div>
        </PatientDetailsCalloutWrapper>
    ) : null;
};
