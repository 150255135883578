import { styled, Dialog, IconButton } from '@mui/material';

export const HEPBuilderModalWrapper = styled(Dialog)(({}) => ({
    width: '100%',
    height: '100%',

    '& > .MuiDialog-container > .MuiPaper-root': {
        width: '100%',
        height: '100%',
        maxWidth: 'none',
        maxHeight: 'none',
        margin: 0,
        borderRadius: 0,
    },
}));

export const HEPBuilderCloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: 0,
    top: '12px',
    color: theme.palette.grey[500],
    [theme.breakpoints.up('lg')]: {
        right: 0,
        top: '-10px',
    },
}));

export const HEPBuilderCloseModalBody = styled('p')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(6),
}));
