import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import * as yup from 'yup';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { mixpanel } from '@/common/mixpanel';
import { CommentIcon } from '@/components/common/Icons/CommentIcon';
import { GaitSessionsState } from '@/recoil/atoms/gaitSessionsAtom';
import { resultIsError } from '@/services/HttpService';
import { Dialog, DialogContent, DialogContentWrapper } from '../Dialog/styles';
import { CloseButton, SubmitCancelButtons } from '../Form/Form';
import {
    SessionCommentWrapper,
    CommentActions,
    EditCommentIcon,
    CommentTextField,
    DeleteCommentIcon,
    CommentErrors,
    CommentContent,
    CommentWrapper,
} from './styles';

interface Props {
    gaitSessionId: string;
    text?: string;
}

const schema = yup.object().shape({
    comment: yup.string().max(1000),
});

export const SessionComment = (props: Props) => {
    const { gaitSessionId, text } = props;
    const [gaitSortedSessions, setSortedGaitSessions] = useRecoilState(GaitSessionsState);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const { httpService } = useHttpContext();
    const { errorToast } = toasts;

    const { handleSubmit, control } = useForm({
        defaultValues: {
            comment: text,
        },
        resolver: yupResolver(schema),
    });

    const { errors } = useFormState({
        control,
    });

    const onSubmit = async (data: { comment: string }) => {
        const res = await httpService.updateGaitSessionComment(gaitSessionId, data.comment);
        if (resultIsError(res)) {
            errorToast('Could not updatecomment.');
        }
        mixpanel.track('Added Gait Comment');
        const currentSession = gaitSortedSessions.find((session) => session.sessionUUID === gaitSessionId);

        if (currentSession) {
            setSortedGaitSessions((prev) => {
                const newSessions = [...prev];
                const index = newSessions.indexOf(currentSession);
                newSessions[index] = {
                    ...currentSession,
                    session: {
                        ...currentSession.session,
                        meta: { ...currentSession.session.meta, comment: data.comment },
                    },
                };
                return newSessions;
            });
        }
    };

    const handleDelete = async () => {
        await httpService.updateGaitSessionComment(gaitSessionId, null);
        window.location.reload();
    };

    const handleCommentClick = () => {
        setDialogOpen(true);
        setIsEditing(false);
        setIsDeleting(false);
        mixpanel.track('Viewed Gait Comment');
    };

    const handleAddCommentClick = () => {
        setDialogOpen(true);
        setIsEditing(true);
    };

    const handleEditClick = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsEditing(true);
    };

    const handleDeleteClick = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsDeleting(true);
    };

    const handleCancelEdit = () => {
        if (!text) setDialogOpen(false);
        setIsEditing(false);
    };

    const handleCancelDelete = () => {
        setIsDeleting(false);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const content = (() => {
        let c: JSX.Element;
        if (isEditing) {
            c = (
                <>
                    <Controller
                        control={control}
                        name="comment"
                        render={({ field: { onChange, value } }) => (
                            <CommentTextField
                                placeholder="Add a new comment"
                                multiline
                                variant="outlined"
                                required={true}
                                value={value}
                                onChange={onChange}
                                id="comment"
                            />
                        )}
                    />
                    <CommentErrors>{(errors.comment && errors.comment.message) || '\u00a0'}</CommentErrors>
                    <SubmitCancelButtons submitFunction={handleSubmit(onSubmit)} cancelFunction={handleCancelEdit} />
                </>
            );
        } else if (isDeleting) {
            c = (
                <>
                    <h3>Are you sure you want to delete this comment?</h3>
                    <SubmitCancelButtons
                        submitFunction={handleDelete}
                        cancelFunction={handleCancelDelete}
                        submitText="Delete"
                    />
                </>
            );
        } else {
            c = (
                <>
                    <CommentWrapper>
                        <p>{text}</p>
                        <CommentActions>
                            <IconButton onClick={handleEditClick} size="small">
                                <EditCommentIcon />
                            </IconButton>
                            <IconButton onClick={handleDeleteClick} size="small" style={{ marginLeft: '8px' }}>
                                <DeleteCommentIcon />
                            </IconButton>
                        </CommentActions>
                    </CommentWrapper>
                    <CloseButton closeFunction={handleClose} />
                </>
            );
        }
        return c;
    })();

    return (
        <>
            {!text ? (
                <SessionCommentWrapper onClick={handleAddCommentClick} size="small">
                    <AddIcon /> Add comment
                </SessionCommentWrapper>
            ) : (
                <>
                    <SessionCommentWrapper onClick={handleCommentClick} size="small">
                        <CommentIcon /> View comment
                    </SessionCommentWrapper>
                </>
            )}
            <Dialog open={dialogOpen} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
                <DialogContent>
                    <DialogContentWrapper>
                        <h2>Therapist Comment</h2>
                        <CommentContent>{content}</CommentContent>
                    </DialogContentWrapper>
                </DialogContent>
            </Dialog>
        </>
    );
};
