import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { Tab, Tabs } from '@/components/common/Tabs/styles';

export const PatientTableRow = styled(Link)<{ $isDisabled?: boolean }>(({ theme, $isDisabled }) => ({
    display: 'table-row',
    color: 'inherit',
    textDecoration: 'none',
    pointerEvents: $isDisabled ? 'none' : 'auto',

    '& td': {
        verticalAlign: 'middle',
        color: theme.palette.grey[600],
        fontWeight: theme.typography.fontWeightRegular,

        '&:first-of-type': {
            color: theme.palette.grey[900],
            fontWeight: theme.typography.fontWeightMedium,
        },
    },

    '&:hover': {
        background: theme.palette.grey[100],
        cursor: 'pointer',
        '& td:first-of-type': {
            color: theme.palette.primary[500],
        },
    },
    '& td:first-of-type': {
        paddingLeft: theme.spacing(0.5),
    },
}));

export const PatientTableHeaderTop = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
    },

    '& .MuiTabs-flexContainer': {
        marginTop: 4,
    },
}));

export const PatientTableTabs = styled(Tabs)(({ theme }) => ({
    background: 'none',
    boxShadow: 'none',
    padding: 0,
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(4),

    '.MuiTabs-indicator': {
        display: 'none',
    },

    [theme.breakpoints.up('md')]: {
        marginTop: 0,
    },
}));

export const PatientTableTab = styled(Tab)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[500]}`,
    margin: 0,
    borderRadius: theme.shape.borderRadius,
    zIndex: 10,
    minHeight: 0,
    padding: theme.spacing(1.25),

    '&.Mui-selected': {
        border: `1px solid ${theme.palette.primary.main}`,
    },
    '&:first-child': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    '&:last-child': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        position: 'relative',
        left: -1,
        zIndex: 0,

        '&.Mui-selected': {
            zIndex: 20,
        },
    },
}));

export const PatientNameAndSelectionCell = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
}));

export const PatientName = styled('div')(({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium,
}));

export const PatientTableActionsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
}));

export const PatientTableCellEllipsis = styled('div')(({ theme }) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));
