import { styled, Chip, Tabs, Tab, Grid, Typography, TableCell as MUITableCell } from '@mui/material';

export const TableCell = styled(MUITableCell)(({ theme }) => ({
    padding: '4px',
}));

export const ListControlsFilters = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: theme.spacing(1),
    position: 'relative',

    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
        rowGap: 0,
        justifyContent: 'space-between',
    },

    '& > *:nth-child(1n + 2)': {
        '.MuiButton-root': {
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(2),
            },
        },
    },
    '.MuiButton-root': {
        paddingTop: '5px',
        paddingBottom: '5px',
        justifyContent: 'flex-start',
    },
}));

export const StatusChip = styled(Chip)<{ $status: string }>(({ theme, $status }) => ({
    backgroundColor:
        $status === 'READY_TO_BILL'
            ? theme.palette.success[200]
            : $status === 'IN_PROGRESS'
            ? theme.palette.warning[100]
            : $status === 'BILLED'
            ? theme.palette.info[200]
            : theme.palette.error[100],
    color:
        $status === 'READY_TO_BILL'
            ? theme.palette.success[700]
            : $status === 'IN_PROGRESS'
            ? theme.palette.warning[700]
            : $status === 'BILLED'
            ? theme.palette.info[700]
            : theme.palette.error[600],
}));

export const PatientTableTabs = styled(Tabs)(({ theme }) => ({
    background: 'none',
    boxShadow: 'none',
    padding: 0,
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(4),

    '.MuiTabs-indicator': {
        display: 'none',
    },

    [theme.breakpoints.up('md')]: {
        marginTop: 0,
    },
}));

export const PatientTableTab = styled(Tab)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[500]}`,
    margin: 0,
    borderRadius: theme.shape.borderRadius,
    zIndex: 10,
    minHeight: 0,
    padding: theme.spacing(1.25),

    '&.Mui-selected': {
        border: `1px solid ${theme.palette.primary.main}`,
    },
    '&:first-child': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    '&:last-child': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        position: 'relative',
        left: -1,
        zIndex: 0,

        '&.Mui-selected': {
            zIndex: 20,
        },
    },
}));

export const RTMGridContainer = styled(Grid)(({ theme }) => ({
    padding: `0 ${theme.spacing(2)}`,
}));

export const RTMGridStatusHeader = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
}));

export const RTMGridActionHeader = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
}));

export const DateTitle = styled(Typography)(({ theme }) => ({
    fontWeight: '300',
}));
