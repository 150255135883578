import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { MoreVert } from '@mui/icons-material';
import {
    Grid,
    List,
    ListItemText,
    Divider,
    ListItemAvatar,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Checkbox,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useForm, useFormState, Control, FieldErrors, Controller } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as yup from 'yup';
import { RtmCycleStatus } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { Interaction, InteractiveCommunicationEnum, PatientRecord } from '@/common/types';
import { sleep } from '@/common/utils';
import { EmptyData } from '@/components/common/EmptyData/EmptyData';
import { SubmitCancelButtons, FormTextField, FormDateField } from '@/components/common/Form/Form';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { TrashIcon } from '@/components/common/Icons/TrashIcon';
import { Modal } from '@/components/common/Modal/Modal';
import { Button } from '@/components/common/button/button';
import { Center } from '@/components/common/styles';
import { interactionsState } from '@/recoil/atoms/interactionsAtom';
import { InteractionsSelector } from '@/recoil/selectors/interactionsSelector';
import { resultIsError } from '@/services/HttpService';
import { TabPanel } from './TabPanel';
import {
    TimeAvatar,
    ListItem,
    ReviewContentGrid,
    CardIconWrapper,
    CardItemText,
    InteractionsGridAction,
    CheckBoxWrapper,
    InteractionType,
} from './styles';

enum ReviewFormType {
    ADD = 'ADD',
    DELETE = 'DELETE',
    EDIT = 'EDIT',
}

interface ReviewListItemProps {
    minutes: number;
    date: string;
    notes: string;
    id: number;
    patientId: number;
    interactionType: `${InteractiveCommunicationEnum}` | null;
    rtmCycleStatus: `${RtmCycleStatus}`;
}

interface NotesTabProps {
    currentTab: number;
    patientRecord: PatientRecord;
}

export interface PatientInteractionForm extends Omit<Interaction, 'interactionType'> {
    isPatientInteraction: boolean;
    // TODO add date here as either date time or ISO
}

const reviewItemShape = {
    date: yup.string().required('Date is required'),
    minutes: yup.string().required('Minutes are required'),
    notes: yup.string().required('Notes are required'),
    interactionType: yup.boolean().nullable(),
};

export const NOTES_MAX_LENGTH = 500;

export const PatientReviewForm = ({
    control,
    errors,
    rtmCycleStatus,
}: {
    control: any;
    errors: any;
    rtmCycleStatus?: `${RtmCycleStatus}`;
}) => {
    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={6}>
                    <FormDateField
                        control={control}
                        errors={errors}
                        name="date"
                        label="Date"
                        required={true}
                        noFuture={true}
                        disabled={rtmCycleStatus && rtmCycleStatus === RtmCycleStatus.BILLED}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormTextField
                        name="minutes"
                        label="Minutes"
                        control={control}
                        errors={errors}
                        required={true}
                        type="number"
                        inputProps={{ min: 0 }}
                        disabled={rtmCycleStatus && rtmCycleStatus === RtmCycleStatus.BILLED}
                    />
                </Grid>
            </Grid>
            <FormTextField
                name="notes"
                label="Notes"
                control={control}
                errors={errors}
                required={true}
                multiline={true}
                inputProps={{
                    maxLength: NOTES_MAX_LENGTH,
                }}
            />
            <CheckBoxWrapper
                control={
                    <Controller
                        control={control}
                        name="isPatientInteraction"
                        render={({ field: props }) => (
                            <Checkbox
                                {...props}
                                checked={props.value}
                                onChange={props.onChange}
                                disabled={rtmCycleStatus && rtmCycleStatus === RtmCycleStatus.BILLED}
                            />
                        )}
                    />
                }
                label="Interactive Communication"
            />
        </>
    );
};

export const ReviewItemModal = ({
    dialogOpen,
    setDialogOpen,
    isEditOrDelete,
    control,
    errors,
    submitButtonText,
    handleSubmit,
    onClose,
    rtmCycleStatus,
}: {
    dialogOpen: boolean;
    setDialogOpen: Dispatch<SetStateAction<boolean>>;
    isEditOrDelete?: `${ReviewFormType}`;
    control: Control<PatientInteractionForm, any>;
    errors: FieldErrors;
    submitButtonText: string;
    handleSubmit: () => void;
    onClose: () => void;
    rtmCycleStatus?: `${RtmCycleStatus}`;
}) => {
    const handleClose = () => {
        onClose();
        setDialogOpen(false);
    };

    return (
        <Modal
            open={dialogOpen}
            onClose={handleClose}
            title={`${isEditOrDelete
                ? isEditOrDelete === ReviewFormType.EDIT
                    ? 'Update'
                    : isEditOrDelete === ReviewFormType.DELETE
                        ? 'Delete'
                        : ReviewFormType.ADD
                : 'Add'
                } Care Note`}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                {isEditOrDelete && isEditOrDelete === ReviewFormType.EDIT ? (
                    <>
                        <PatientReviewForm control={control} errors={errors} rtmCycleStatus={rtmCycleStatus} />
                    </>
                ) : isEditOrDelete && isEditOrDelete === ReviewFormType.DELETE ? (
                    <>
                        <Center>
                            <p>Are you sure you want to delete this Care Note?</p>
                        </Center>
                    </>
                ) : (
                    <PatientReviewForm control={control} errors={errors} rtmCycleStatus={rtmCycleStatus} />
                )}

                <SubmitCancelButtons
                    submitText={submitButtonText}
                    submitFunction={handleSubmit}
                    cancelFunction={handleClose}
                />
            </div>
        </Modal>
    );
};

const ReviewListItem = (props: ReviewListItemProps) => {
    const { date, notes, minutes, id, interactionType, patientId, rtmCycleStatus } = props;
    const [patientInteractions, setPatientInteractions] = useRecoilState(interactionsState);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isEditOrDelete, setIsEditOrDelete] = useState<keyof typeof ReviewFormType>();
    const { httpService } = useHttpContext();
    const { successToast, errorToast } = toasts;
    const shape = reviewItemShape;
    const schema = yup.object().shape(shape);

    const { handleSubmit, control, reset, watch, clearErrors } = useForm<PatientInteractionForm>({
        defaultValues: {
            date: DateTime.fromISO(date) ?? DateTime.now(),
            notes: notes || '',
            minutes: minutes || 20,
            isPatientInteraction: interactionType === InteractiveCommunicationEnum.PATIENT_INT_COMM,
        },
        resolver: yupResolver(schema),
    });

    const notesField = watch('notes');

    useEffect(() => {
        if (notesField.length >= NOTES_MAX_LENGTH) {
            control.setError('notes', { message: 'Max Character Limit is 500' });
        } else {
            clearErrors();
        }
    }, [notesField]);

    const { errors } = useFormState({
        control,
    });

    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const menuIsOpen = Boolean(menuAnchor);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchor(null);
    };

    const handleEditReviewClick = async () => {
        setIsEditOrDelete(ReviewFormType.EDIT);
        setMenuAnchor(null);
        setDialogOpen(true);
    };

    const handleDeleteReviewClick = async () => {
        setIsEditOrDelete(ReviewFormType.DELETE);
        setMenuAnchor(null);
        setDialogOpen(true);
    };

    const handleUpdateSubmit = async (data: PatientInteractionForm) => {
        setMenuAnchor(null);
        const body = {
            ...data,
            date: DateTime.fromJSDate(new Date(data.date as string)).toISODate(),
            minutes: Number(data.minutes),
            interactionType: data.isPatientInteraction ? InteractiveCommunicationEnum.PATIENT_INT_COMM : null,
        };
        const res = await httpService.updatePatientInteraction(patientId, id, body);

        if (!res || resultIsError(res)) {
            errorToast('This interaction could not be updated.');
            return;
        } else {
            const filtered = patientInteractions.filter(function (interaction) {
                return interaction.id !== id;
            });
            setPatientInteractions([...filtered, res]);
            successToast(`Interaction has been updated.`);

            setDialogOpen(false);
        }
    };

    const handleDeleteSubmit = async () => {
        setMenuAnchor(null);
        const res = await httpService.deletePatientInteraction(patientId, id);

        if (!res || resultIsError(res)) {
            const filtered = patientInteractions.filter(function (interaction) {
                return interaction.id !== id;
            });
            setDialogOpen(false);
            successToast(`Interaction has been deleted.`);
            await sleep(1000);
            setPatientInteractions([...filtered]);
        }
    };

    const handleModalSubmit = () => {
        isEditOrDelete === ReviewFormType.EDIT
            ? handleSubmit(handleUpdateSubmit)()
            : handleSubmit(handleDeleteSubmit)();
    };

    return (
        <>
            <ListItem
                alignItems="flex-start"
                secondaryAction={
                    <IconButton
                        edge="end"
                        aria-label="Edit Interaction"
                        id="three-dot-button"
                        aria-controls={menuIsOpen ? 'review-actions-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={menuIsOpen ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <MoreVert />
                    </IconButton>
                }
            >
                <ListItemAvatar>
                    <Tooltip title={`${minutes} minutes`}>
                        <TimeAvatar>
                            {minutes}
                            <span>m</span>
                        </TimeAvatar>
                    </Tooltip>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <>
                            {DateTime.fromISO(date).toFormat('MMM dd yyyy')}
                            <InteractionType>
                                {interactionType === InteractiveCommunicationEnum.PATIENT_INT_COMM
                                    ? ' - Interactive Communication'
                                    : ''}
                            </InteractionType>
                        </>
                    }
                    primaryTypographyProps={{ fontWeight: 600 }}
                    secondary={<React.Fragment>{notes}</React.Fragment>}
                />
            </ListItem>
            <Menu
                onClick={(e) => e.stopPropagation()}
                id="three-dot-menu"
                anchorEl={menuAnchor}
                open={menuIsOpen}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'three-dot-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleEditReviewClick}>
                    <CardIconWrapper>
                        <EditIcon />
                    </CardIconWrapper>
                    <CardItemText>Edit</CardItemText>
                </MenuItem>
                {rtmCycleStatus !== RtmCycleStatus.BILLED && (
                    <MenuItem onClick={handleDeleteReviewClick}>
                        <CardIconWrapper>
                            <TrashIcon />
                        </CardIconWrapper>
                        <CardItemText>Delete</CardItemText>
                    </MenuItem>
                )}
            </Menu>
            <ReviewItemModal
                control={control}
                errors={errors}
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                isEditOrDelete={isEditOrDelete}
                submitButtonText={isEditOrDelete === ReviewFormType.EDIT ? 'Save' : 'Delete'}
                handleSubmit={handleModalSubmit}
                onClose={reset}
                rtmCycleStatus={rtmCycleStatus}
            />
        </>
    );
};

export const InteractionTab = (props: NotesTabProps) => {
    const { currentTab, patientRecord } = props;
    const [patientInteractions, setPatientInteractions] = useRecoilState(interactionsState);
    const { sortedPatientInteractions, interactionsExistsInRange } = useRecoilValue(InteractionsSelector);

    const [dialogOpen, setDialogOpen] = useState(false);
    const { httpService } = useHttpContext();
    const { successToast, errorToast } = toasts;
    const shape = reviewItemShape;
    const schema = yup.object().shape(shape);

    const { handleSubmit, control, reset } = useForm<PatientInteractionForm>({
        defaultValues: {
            date: DateTime.now(),
            notes: '',
            minutes: 20,
            isPatientInteraction: false,
        },
        resolver: yupResolver(schema),
    });

    const { errors } = useFormState({
        control,
    });

    const handleAddInteractionSubmit = async (data: PatientInteractionForm) => {
        const body = {
            ...data,
            date: DateTime.fromJSDate(new Date(data.date as string)).toISODate(),
            minutes: Number(data.minutes),
            interactionType: data.isPatientInteraction ? InteractiveCommunicationEnum.PATIENT_INT_COMM : null,
        };
        const res = await httpService.addPatientInteraction(patientRecord.id, body);

        if (!res || resultIsError(res)) {
            errorToast('Interaction could not be added.');
            return;
        } else {
            setPatientInteractions([...patientInteractions, res]);
            successToast(`Interaction has been successfully added.`);

            setDialogOpen(false);
        }
    };

    const handleAddSubmit = () => {
        handleSubmit(handleAddInteractionSubmit)();
        reset();
    };

    const handleAddReviewClick = () => {
        setDialogOpen(true);
    };

    return (
        <TabPanel currentTab={currentTab} index={3} ariaLabel="careManagement">
            <>
                {patientInteractions.length > 0 ? (
                    !interactionsExistsInRange() ? (
                        <ReviewContentGrid container spacing={4}>
                            <Grid item xs={12} lg={8}>
                                <EmptyData title="No Care Notes are available in this date range" />
                            </Grid>

                            <InteractionsGridAction item xs={12} lg={4}>
                                <Button variant="primary" onClick={handleAddReviewClick}>
                                    Add Care Note
                                </Button>
                            </InteractionsGridAction>
                        </ReviewContentGrid>
                    ) : (
                        <ReviewContentGrid container spacing={4}>
                            <Grid item xs={12} lg={8}>
                                <List>
                                    {sortedPatientInteractions.map((review, index) => (
                                        <React.Fragment key={review.id}>
                                            <ReviewListItem
                                                date={review.date}
                                                minutes={review.minutes}
                                                notes={review.notes}
                                                id={review.id}
                                                interactionType={review.interactionType}
                                                patientId={patientRecord.id}
                                                rtmCycleStatus={review.rtmCycleStatus}
                                            />
                                            {index !== sortedPatientInteractions.length - 1 ? (
                                                <Divider variant="inset" style={{ marginTop: 8, marginBottom: 8 }} />
                                            ) : null}
                                        </React.Fragment>
                                    ))}
                                </List>
                            </Grid>
                            <InteractionsGridAction item xs={12} lg={4}>
                                <Button variant="primary" onClick={handleAddReviewClick}>
                                    Add Care Note
                                </Button>
                            </InteractionsGridAction>
                        </ReviewContentGrid>
                    )
                ) : (
                    <ReviewContentGrid container spacing={4}>
                        <Grid item xs={12} lg={8}>
                            <EmptyData title="No Care Notes have been added yet" />
                        </Grid>

                        <InteractionsGridAction item xs={12} lg={4}>
                            <Button variant="primary" onClick={handleAddReviewClick}>
                                Add Care Note
                            </Button>
                        </InteractionsGridAction>
                    </ReviewContentGrid>
                )}
                <ReviewItemModal
                    control={control}
                    errors={errors}
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                    submitButtonText="Submit"
                    handleSubmit={handleAddSubmit}
                    onClose={reset}
                />
            </>
        </TabPanel>
    );
};
