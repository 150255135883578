import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { mixpanel } from '@/common/mixpanel';
import { HEPTemplateDetailsContainer } from '@/components/Health/HEPs/HEPTemplateDetails/HEPTemplateDetailsContainer';
import { Breadcrumbs } from '../../layout/Breadcrumbs/Breadrumbs';
import { RouteContentWrapper } from '../styles';

export const HEPTemplateRoute = () => {
    const { templateId } = useParams<{ templateId: string }>();

    useEffect(() => {
        mixpanel.track('Viewed Published Program Template');
    }, []);

    return (
        <>
            <Breadcrumbs />
            <RouteContentWrapper>
                <HEPTemplateDetailsContainer hepTemplateId={parseInt(templateId!)} />
            </RouteContentWrapper>
        </>
    );
};
