import { styled, Box } from '@mui/material';

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#eeeeee';
};

export const UploadInputContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 12,
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: `${(props) => getColor(props)}`,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary[500],
    outline: 'none',
    transition: 'border 0.24s ease-in-out',
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 24,
}));

export const DragNDropContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 24,
    marginRight: 8,
}));

export const IconContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const EditImageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(6),
}));
