import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { CreatePROTypeformFormDto, PROTypeformForm } from '@/common/types';
import { FormTextField, SubmitCancelButtons } from '@/components/common/Form/Form';
import { FormFieldContainer, ServerError } from '@/components/common/Form/styles';
import { resultIsError } from '@/services/HttpService';

interface AddPROTypeformFormFormProps {
    cancel: () => void;
    onSuccess: () => void;
}

const shape = {
    id: yup.string().required('Form ID is required'),
};

export const AddPROTypeformFormForm = (props: AddPROTypeformFormFormProps) => {
    const { cancel, onSuccess } = props;
    const { httpService } = useHttpContext();
    const [serverError, setServerError] = useState<string | null>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [webhookConfirmed, setWebhookConfirmed] = useState(false);
    const { successToast, errorToast } = toasts;

    const defaultValues = {
        id: '',
    };

    const schema = yup.object().shape(shape);

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const addForm = async (data: CreatePROTypeformFormDto): Promise<PROTypeformForm | undefined> => {
        setServerError(null);
        setIsSubmitting(true);
        const result = await httpService.adminCreatePROTypeformForm(data);
        setIsSubmitting(false);
        if (resultIsError(result)) {
            setServerError(result.message);
        } else {
            successToast(`Form Added.`);
            return result;
        }
    };

    const save = async (data: CreatePROTypeformFormDto) => {
        const result = await addForm(data);
        if (result) {
            onSuccess();
        }
    };

    return (
        <>
            <form noValidate data-cy="add-pro-typeform-form">
                <p></p>
                <FormTextField control={control} errors={errors} name="id" label="Form ID" required={true} />
                <FormFieldContainer>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={webhookConfirmed}
                                onChange={() => {
                                    setWebhookConfirmed(!webhookConfirmed);
                                }}
                                name="webhook-confirmed"
                            />
                        }
                        label={
                            'I certify the Exer webhook has been added to the \n Form > Connect > Webhooks section of Typeform.'
                        }
                    />
                </FormFieldContainer>
                <ServerError>{serverError || '\u00a0'}</ServerError>
                <SubmitCancelButtons
                    submitFunction={handleSubmit(save)}
                    cancelFunction={cancel}
                    submitDisabled={isSubmitting}
                />
            </form>
        </>
    );
};
