import React, { useState, useEffect, ReactElement, ChangeEvent } from 'react';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { Slider } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { SideOfBody } from '@/common/const';
import {
    GoalType,
    HEPBlock,
    RepsGoal,
    isRepsOrTimeGoal,
    RepsOrTimeGoal,
    SetGoal,
    isTimeGoal,
    TimeGoal,
    ExerciseMetricOmitExercise,
    ExerciseMetricSummary,
    isROMExerciseMetric,
    isTimeExerciseMetric,
} from '@/common/types';
import { CreateIntegerArray, toHHMMSS } from '@/common/utils';
import { CreateMarks } from '@/common/utils';
import { getSecondsFromHHMMSS } from '@/common/utils';
import { SliderToolTip } from '@/components/Health/HEPBuilder/EditBlock/SliderToolTip';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { ServerError } from '@/components/common/Form/styles';
import { ToggleButton } from '@/components/common/ToggleButton/ToggleButton';
import {
    EditBlockInputWrapper,
    HEPRepInputSliderWrapper,
    TimeFieldWrapper,
    TimeFieldInput,
    FrequentlySelected,
    EditBlockContent,
    EditBlockCtaWrapper,
} from './styles';

interface ExerciseBlockMetricProps {
    exerciseMetric: ExerciseMetricOmitExercise | ExerciseMetricSummary;
    onSubmit: (hepBlock: HEPBlock) => void;
    onCancel: () => void;
    initialBlock?: HEPBlock;
    sideOfBody?: keyof typeof SideOfBody;
}

export const EditBlockMetric = (props: ExerciseBlockMetricProps) => {
    const { exerciseMetric, onSubmit, onCancel, initialBlock, sideOfBody } = props;
    const isROM = isROMExerciseMetric(exerciseMetric);
    const isTime = isTimeExerciseMetric(exerciseMetric) || isROMExerciseMetric(exerciseMetric);

    const buildDefaultBlock = () => {
        const numSets = isROM || isTime || isRepsOrTimeGoal(exerciseMetric.id) ? 1 : 3;
        const setGoal: SetGoal = isROM
            ? { time: 0, goalType: GoalType.ROM }
            : isTime
            ? { time: 0, goalType: GoalType.TIME }
            : isRepsOrTimeGoal(exerciseMetric.id)
            ? { time: 60, reps: 5, goalType: GoalType.REPS_OR_TIME }
            : { reps: 10, goalType: GoalType.REPS };
        return { exerciseId: exerciseMetric.id, uuid: uuidv4(), numSets, setGoal, sideOfBody };
    };
    const [hepBlock, setHEPBlock] = useState<HEPBlock>(initialBlock || buildDefaultBlock());
    const [isRepsOrTime, setIsRepsOrTime] = useState(isRepsOrTimeGoal(exerciseMetric.id));
    const [stringTimeGoal, setStringTimeGoal] = useState(
        hepBlock.setGoal && isTimeGoal(hepBlock.setGoal)
            ? hepBlock.setGoal.time !== undefined && toHHMMSS(hepBlock.setGoal.time)
            : '0:00',
    );
    const [errorMessage, setErrorMessage] = useState<string | null>();

    const handleSubmit = () => {
        if (hepBlock.setGoal && isTimeGoal(hepBlock.setGoal) && hepBlock.setGoal.time < 5) {
            setErrorMessage('Please select at least 5 sec for the time goal.');
        } else {
            onSubmit(hepBlock);
        }
    };

    const handleRepChange = (_: Event, newValue: number) => {
        setErrorMessage(null);

        setHEPBlock({ ...hepBlock, setGoal: { reps: newValue, goalType: GoalType.REPS } });
    };

    const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrorMessage(null);
        setStringTimeGoal(e.target.value.replace(/([^0-9:])+/g, ''));
        const seconds = Math.max(0, getSecondsFromHHMMSS(e.target.value));
        setHEPBlock({ ...hepBlock, setGoal: { time: seconds, goalType: GoalType.TIME } });
    };

    const handleTimeBlur = (e) => {
        setErrorMessage(null);
        const seconds = Math.max(0, getSecondsFromHHMMSS(e.target.value));
        const timeString = toHHMMSS(seconds);
        setStringTimeGoal(timeString);
        setHEPBlock({ ...hepBlock, setGoal: { time: seconds, goalType: GoalType.TIME } });
    };

    const handlePreSelectTime = (seconds: number) => {
        const timeString = toHHMMSS(seconds);
        setStringTimeGoal(timeString);
        setHEPBlock({ ...hepBlock, setGoal: { time: seconds, goalType: GoalType.TIME } });
    };

    const handleTimeSliderChange = (e: Event, newValue: number) => {
        handlePreSelectTime(newValue);
        setErrorMessage(null);
    };

    const handleSetChange = (_: ChangeEvent<Element>, newValue: number) => {
        setErrorMessage(null);
        setHEPBlock({ ...hepBlock, numSets: newValue });
    };

    useEffect(() => {
        setIsRepsOrTime(isRepsOrTimeGoal(exerciseMetric.id));
        if (isRepsOrTimeGoal(exerciseMetric.id)) {
            // Hardcoded time: 60, reps: 5 for Sit to Stand
            setHEPBlock({
                ...hepBlock,
                numSets: 1,
                setGoal: { time: 60, reps: 5, goalType: GoalType.REPS_OR_TIME },
            });
        }
    }, [exerciseMetric.id]);

    useEffect(() => {
        setHEPBlock({
            ...hepBlock,
            sideOfBody,
        });
    }, [sideOfBody]);

    useEffect(() => {
        setHEPBlock(initialBlock || buildDefaultBlock());
    }, [exerciseMetric.id, initialBlock]);

    let setGoal: SetGoal;
    let goalBlock: ReactElement;
    if (isRepsOrTime) {
        setGoal = hepBlock.setGoal as RepsOrTimeGoal;
        goalBlock = (
            <EditBlockInputWrapper>
                <p>Goal: 5 reps in 60 seconds</p>
            </EditBlockInputWrapper>
        );
    } else {
        if (isTime) {
            setGoal = hepBlock.setGoal as TimeGoal;
            goalBlock = (
                <EditBlockInputWrapper>
                    <h4>Timer</h4>
                    <p>Time goal for each set.</p>
                    <TimeFieldWrapper>
                        <AccessTimeRoundedIcon />
                        <TimeFieldInput value={stringTimeGoal} onChange={handleTimeChange} onBlur={handleTimeBlur} />
                    </TimeFieldWrapper>
                    <HEPRepInputSliderWrapper>
                        <Slider
                            defaultValue={setGoal.time}
                            step={1}
                            marks={CreateMarks({
                                total: 60,
                                steps: 10,
                                additionalLabel: 's',
                            })}
                            valueLabelDisplay="auto"
                            min={0}
                            max={60}
                            value={setGoal.time}
                            onChange={handleTimeSliderChange}
                            slots={{
                                valueLabel: (label) =>
                                    SliderToolTip({ label, timeIsOverMax: setGoal as TimeGoal, max: 60 }),
                            }}
                            aria-label="custom thumb label"
                        />
                    </HEPRepInputSliderWrapper>
                    <FrequentlySelected>
                        Frequently Selected:{' '}
                        <a
                            onClick={() => {
                                handlePreSelectTime(30);
                            }}
                        >
                            30 sec
                        </a>
                        ,{'\u00a0'}
                        <a
                            onClick={() => {
                                handlePreSelectTime(60);
                            }}
                        >
                            1 min
                        </a>
                        ,{'\u00a0'}
                        <a
                            onClick={() => {
                                handlePreSelectTime(90);
                            }}
                        >
                            1:30 min
                        </a>
                    </FrequentlySelected>
                    {errorMessage ? <ServerError>{errorMessage}</ServerError> : null}
                </EditBlockInputWrapper>
            );
        } else {
            setGoal = hepBlock.setGoal as RepsGoal;
            goalBlock = (
                <EditBlockInputWrapper>
                    <h4>Repetitions</h4>
                    <p>The number of reps in one set.</p>
                    <HEPRepInputSliderWrapper>
                        <Slider
                            step={1}
                            marks={CreateMarks({ total: 25, steps: 5 })}
                            valueLabelDisplay="on"
                            min={1}
                            max={25}
                            value={setGoal.reps || 0}
                            onChange={handleRepChange}
                        />
                    </HEPRepInputSliderWrapper>
                </EditBlockInputWrapper>
            );
        }
    }
    return (
        <>
            <EditBlockContent>
                {goalBlock}
                {!isROM && !isRepsOrTime && (
                    <EditBlockInputWrapper>
                        <h4>Sets</h4>
                        <p>The total number of sets.</p>
                        <ToggleButton
                            items={CreateIntegerArray(5)}
                            ariaLabel="daily frequency toggle button group"
                            defaultValue={hepBlock.numSets}
                            onChange={handleSetChange}
                            enforce
                        />
                    </EditBlockInputWrapper>
                )}
            </EditBlockContent>
            <EditBlockCtaWrapper>
                <SubmitCancelButtons
                    submitText="Add"
                    submitFunction={handleSubmit}
                    cancelFunction={onCancel}
                    size="small"
                />
            </EditBlockCtaWrapper>
        </>
    );
};
