import React, { useState } from 'react';
import { actions } from '@exerai/react-core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormState } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import * as yup from 'yup';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { UpdateOrganizationDto } from '@/common/types';
import { DialogContentWrapper } from '@/components/common/Dialog/styles';
import { FormTextField, SubmitCancelButtons } from '@/components/common/Form/Form';
import { ServerError } from '@/components/common/Form/styles';
import { orgLogoAtomState } from '@/recoil/atoms/orgLogoAtom';
import { resultIsError } from '@/services/HttpService';
import { OrgLogo } from '../OrganizationLogo/OrganizationLogo';
import { SubmitCancelButtonsWrapper } from './styles';

interface Props {
    handleCancel: () => void;
    onSuccess: (res: UpdateOrganizationDto) => void;
}

const shape = {
    name: yup.string().required('Name is required'),
};

export const OrganizationEditDialogContent = (props: Props) => {
    const { handleCancel, onSuccess } = props;
    const [serverError, setServerError] = useState<string | null>();
    const { user, dispatchUser } = useExerUser();
    const { errorToast, successToast } = toasts;
    const { httpService } = useHttpContext();
    const [orgLogoState, setOrgLogoState] = useRecoilState(orgLogoAtomState);
    const [orgLogoUrl, setOrgLogoUrl] = useState<string | undefined>(orgLogoState);
    const [newOrgLogo, setNewOrgLogo] = useState<File | null>(null);
    const [shouldDeleteOrgLogo, setShouldDeleteOrgLogo] = useState(false);

    const schema = yup.object().shape(shape);

    const { handleSubmit, control } = useForm({
        defaultValues: {
            name: user.organizationName || '',
        },
        resolver: yupResolver(schema),
    });

    const { errors } = useFormState({
        control,
    });

    const handleCancelClick = () => {
        setShouldDeleteOrgLogo(false);
        setNewOrgLogo(null);
        handleCancel();
    };

    const handleOrgLogoSubmit = async (): Promise<boolean> => {
        if (!newOrgLogo && !shouldDeleteOrgLogo) return false;

        if (!shouldDeleteOrgLogo) {
            const res = await httpService.uploadOrgLogo(user.organizationId, newOrgLogo);
            if (resultIsError(res)) {
                if (res.message) {
                    errorToast(`Failed to upload logo - ${res.message}`);
                } else {
                    errorToast('Something went wrong uploading your image, please try again.');
                }
                return false;
            } else {
                setOrgLogoUrl(res.webLogoUrl);
                setShouldDeleteOrgLogo(false);
                setOrgLogoState(res.webLogoUrl);
            }
        } else {
            const res = await httpService.deleteOrgLogo(user.organizationId);
            if (resultIsError(res)) {
                errorToast('Something went wrong deleting your image, please try again.');
                return false;
            } else {
                setOrgLogoUrl(undefined);
                setShouldDeleteOrgLogo(false);
                setOrgLogoState(undefined);
            }
        }
        return true;
    };

    const onSubmit = async (data: UpdateOrganizationDto) => {
        setServerError(null);

        const success = await handleOrgLogoSubmit();
        if (!success) {
            return;
        }

        const result = await httpService.updateOrganization(user.organizationId, data);
        if (resultIsError(result)) {
            setServerError(result.message);
            errorToast('Something went wrong editing the org, please refresh and try again.');
            return;
        }
        successToast('Successfully updated organization details.');
        if (onSuccess) {
            dispatchUser({
                type: actions.UPDATE,
                payload: {
                    organizationName: result.name,
                },
            });
            onSuccess(data);
        }
    };

    const validateAndSubmit = () => {
        handleSubmit(onSubmit)();
    };

    return (
        <DialogContentWrapper>
            <form>
                <FormTextField name="name" label="Name" control={control} errors={errors} required={true} />
                <ServerError>{serverError || '\u00a0'}</ServerError>
            </form>
            <OrgLogo
                orgLogoUrl={orgLogoUrl}
                setNewOrgLogo={setNewOrgLogo}
                setShouldDeleteOrgLogo={setShouldDeleteOrgLogo}
            />

            <SubmitCancelButtonsWrapper>
                <SubmitCancelButtons submitFunction={validateAndSubmit} cancelFunction={handleCancelClick} />
            </SubmitCancelButtonsWrapper>
        </DialogContentWrapper>
    );
};
