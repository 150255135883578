import React, { useRef, useState, useEffect } from 'react';
import { Chart, registerables, CategoryScale } from 'chart.js';
import { DateTime } from 'luxon';
import { useRecoilValue } from 'recoil';
import { TimeSpan } from '@/common/types';
import { getStartAndEndOfDateInMillis } from '@/common/utils';
import { ChartEmpty } from '@/components/common/Chart/ChartEmpty';
import { PROChartTitle } from '@/components/common/Chart/styles';
import { DateRangeSelector } from '@/recoil/selectors/dateRangeSelector';
import { PROChartData, PROChartI } from '../PROs/types';
import { PROChartWrapper } from './styles';

Chart.register(...registerables, CategoryScale);

interface PROChartProps {
    proChart: PROChartData;
    timeSpan: TimeSpan;
    chartTitle: string;
}

export const PROChart = (props: PROChartProps) => {
    const { proChart, timeSpan, chartTitle } = props;
    const { chart } = proChart;
    const proChartContainer = useRef<HTMLCanvasElement>(null);
    const [chartInstance, setChartInstance] = useState<Chart<'line', string[], string> | null>(null);
    const { startDateMillis, endDateMillis } = useRecoilValue(DateRangeSelector);
    const [isEmpty, setIsEmpty] = useState(false);

    const getData = (message: PROChartI) => {
        const dateLabels: string[] = [];
        const minDataSet = { ...message.datasets[0] };
        minDataSet.data = [];

        Object.values(message.labels).map((m, i) => {
            const date = DateTime.fromJSDate(new Date(m)).toFormat('dd LLL');
            const processedDate = getStartAndEndOfDateInMillis(m);

            if (processedDate.startDate >= startDateMillis && processedDate.endDate <= endDateMillis) {
                dateLabels.push(date);
                minDataSet.data.push(message.datasets[0].data[i]);
            }
        });

        setIsEmpty(minDataSet.data.length < 1);

        return {
            minDataSet,
            dateLabels,
        } as {
            minDataSet: {
                label: string;
                data: string[] | any;
                borderColor: string;
                backgroundColor: string;
            };
            dateLabels: string[];
        };
    };

    function updateChartData() {
        if (chart && Object.keys(chart).length && chartInstance && chartInstance.data) {
            const { minDataSet, dateLabels } = getData(chart);
            chartInstance.data.datasets = [minDataSet];
            chartInstance.data.labels = dateLabels;
            chartInstance.update();
        }
    }

    useEffect(() => {
        if (proChartContainer.current) {
            const currentProChartContainer = proChartContainer.current;
            const ctx = currentProChartContainer.getContext('2d');
            if (ctx && chart) {
                if (chartInstance === null) {
                    const { minDataSet, dateLabels } = getData(chart);
                    const newChartInstance = new Chart(ctx, {
                        type: 'line',
                        data: {
                            datasets: [minDataSet],
                            labels: dateLabels,
                        },
                        options: chart.options,
                    });
                    setChartInstance(newChartInstance);
                }
            }
        }
    }, []);

    useEffect(() => {
        updateChartData();
    }, [timeSpan]);

    return (
        <PROChartWrapper>
            <PROChartTitle variant="h5">{chartTitle}</PROChartTitle>
            <canvas ref={proChartContainer} id="patientChart" style={{ display: isEmpty ? 'none' : 'block' }} />
            {isEmpty && (
                <ChartEmpty
                    title="No PRO data available"
                    body="Your patient has not submitted any responses in this date range"
                />
            )}
        </PROChartWrapper>
    );
};
