import React, { useEffect, useState } from 'react';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { Practitioner } from '@/common/types';
import { MultiSelectFilter } from '@/components/common/Table/Filters/MultiSelectFilter';
import { usePaginationContext } from '@/components/context/hooks/PaginationContext';
import { resultIsError } from '@/services/HttpService';

export const PRACTITIONER_FILTER_ID = 'primaryPractitionerId';

export const PractitionersFilter = () => {
    const { httpService } = useHttpContext();
    const { filters, handleSetFilters, page, setPage } = usePaginationContext();
    const [practitioners, setPractitioners] = useState<Practitioner[]>();
    const [activePractitioners, setActivePractitioners] = useState<number[]>([]);

    useEffect(() => {
        (async () => {
            const res = await httpService.getPractitioners();
            if (resultIsError(res)) {
                return;
            }
            setPractitioners(res);
        })();
    }, []);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (page !== 1) {
            setPage(1);
        }
        const currentActivePractitioners = [...activePractitioners];
        if (event.target.checked) {
            handleSetFilters(
                PRACTITIONER_FILTER_ID,
                JSON.stringify(currentActivePractitioners.concat([parseInt(event.target.name)])),
            );
        } else {
            const newActivePractitioners = currentActivePractitioners.filter(
                (pId) => pId !== parseInt(event.target.name),
            );
            handleSetFilters(
                PRACTITIONER_FILTER_ID,
                newActivePractitioners.length > 0 ? JSON.stringify(newActivePractitioners) : null,
            );
        }
    };

    const handleClearSelection = () => {
        if (page !== 1) {
            setPage(1);
        }
        handleSetFilters(PRACTITIONER_FILTER_ID, null);
    };

    useEffect(() => {
        if (filters.length > 0) {
            const practitionerFilter = filters.find((filter) => {
                return filter.filterKey === PRACTITIONER_FILTER_ID;
            });
            setActivePractitioners(practitionerFilter?.filterValue ? JSON.parse(practitionerFilter?.filterValue) : []);
        } else {
            setActivePractitioners([]);
        }
    }, [filters]);

    return (
        <MultiSelectFilter
            selected={activePractitioners.map((int) => {
                return int.toString();
            })}
            options={
                practitioners
                    ? practitioners.map((practitioner) => {
                          return {
                              id: String(practitioner.id),
                              name: `${practitioner.firstName} ${practitioner.lastName}`,
                          };
                      })
                    : []
            }
            handleRadioChange={handleRadioChange}
            handleClearSelection={handleClearSelection}
            label="Providers"
            prefix="providers-"
        />
    );
};
