import React from 'react';
import { ProductsContainer } from '@/components/Demo/Products/Products';
import { RouteContentWrapper } from '../styles';

export const ProductsRoute = () => {
    return (
        <RouteContentWrapper>
            <ProductsContainer />
        </RouteContentWrapper>
    );
};
