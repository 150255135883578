import React, { useRef } from 'react';
import { ExerciseListProvider } from '@/components/Health/HEPBuilder/ExerciseList/state/ExerciseListContext';
import { IconFilter } from '@/components/common/Icons/IconFilter';
import { Button } from '@/components/common/button/button';
import { ViewExerciseDrawerWrapper } from '../../../../routes/Health/ExerciseLibrary/styles';
import { ActiveExerciseChips, ExerciseFilters, ClearExerciseFilters } from '../../ExerciseFilters/ExerciseFilters';
import { ExerciseSearch } from '../../ExerciseSearch/ExerciseSearch';
import { HEPBuilderExercisesContainer } from '../../HEPBuilderExercises/HEPBuilderExercises';
import { StepExercisesHEPDetails } from './StepExercisesHEPDetails';
import {
    StepExercisesWrapper,
    HEPBuilderExercisesSidebar,
    HEPBuilderExercisesContent,
    HEPBuilderExercisesContentLeft,
    HEPBuilderSearchWrapper,
    ActiveFiltersWrapper,
    HEPBuilderExercisesFilterWrapper,
} from './styles';

export const StepExercises = () => {
    const [drawerIsOpen, setDrawerIsOpen] = React.useState<boolean>(false);

    const toggleDrawer = (open: boolean) => () => {
        setDrawerIsOpen(open);
    };
    const containerRef = useRef(null);

    return (
        <StepExercisesWrapper ref={containerRef}>
            <ExerciseListProvider>
                <HEPBuilderExercisesSidebar>
                    <HEPBuilderExercisesFilterWrapper>
                        <h3>Filters</h3>
                        <ClearExerciseFilters />
                    </HEPBuilderExercisesFilterWrapper>
                    <ExerciseFilters />
                </HEPBuilderExercisesSidebar>
                <ActiveFiltersWrapper>
                    <HEPBuilderExercisesFilterWrapper>
                        <Button onClick={toggleDrawer(true)} variant="text" startIcon={<IconFilter />}>
                            Filters
                        </Button>
                        <ClearExerciseFilters />
                    </HEPBuilderExercisesFilterWrapper>
                    <ActiveExerciseChips />
                </ActiveFiltersWrapper>
                <HEPBuilderExercisesContent>
                    <HEPBuilderExercisesContentLeft>
                        <h2>Select exercise</h2>
                        <HEPBuilderSearchWrapper>
                            <ExerciseSearch />
                        </HEPBuilderSearchWrapper>
                        <HEPBuilderExercisesContainer
                            gridSize={{ xs: 6, sm: 6, md: 3, lg: 6, chromebook: 4 }}
                            ref={containerRef}
                        />
                    </HEPBuilderExercisesContentLeft>
                </HEPBuilderExercisesContent>
            </ExerciseListProvider>
            <StepExercisesHEPDetails />
            <ViewExerciseDrawerWrapper anchor="left" open={drawerIsOpen} onClose={toggleDrawer(false)}>
                <ExerciseFilters />
            </ViewExerciseDrawerWrapper>
        </StepExercisesWrapper>
    );
};
