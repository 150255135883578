import { PROAnswerType } from '@/common/const';
import { GoalType } from '@/common/types';
import { ActivityData } from '../SessionsPDF/types';

export const activityData: ActivityData = {
    organization: {
        name: 'Exer',
    },
    provider: {
        firstName: 'Client',
        lastName: 'Gehde',
    },
    patientRecord: {
        patient: {
            firstName: 'Brad',
            lastName: "D'Antonio",
        },
        productData: {
            health: {
                injuredSideOfBody: 'LEFT',
                injuredBodyPart: 'HIP',
            },
        },
    },
    meta: {
        minDate: '2022-10-01',
        maxDate: '2022-10-07',
        dayCount: 7,
        dayCountAnyActivity: 6,
        dayCountSessions: 4,
        dayCountPROs: 5,
    },
    exercises: [
        {
            exerciseId: 111,
            exerciseName: 'Goober',
            meta: {
                dayCountSessions: 4,
            },
            goalType: GoalType.REPS,
            aggregates: {
                '2022-10-01': null,
                '2022-10-02': { totalReps: 7 },
                '2022-10-03': { totalReps: 6 },
                '2022-10-04': { totalReps: 8 },
                '2022-10-05': { totalReps: 10 },
                '2022-10-06': null,
                '2022-10-07': null,
            },
        },
    ],
    pros: [
        {
            questionId: 1,
            questionText: 'What is your pain level?',
            proAnswerType: PROAnswerType.CHOICE,
            isChartable: true,
            answers: {
                '2022-10-01': null,
                '2022-10-02': { value: '6' },
                '2022-10-03': { value: '4' },
                '2022-10-04': { value: '0' },
                '2022-10-05': { value: '3' },
                '2022-10-06': { value: '2' },
                '2022-10-07': null,
            },
        },
    ],
};
