import { styled } from '@mui/material';

export const FavoriteExerciseActionWrapper = styled('div')<{ $isFavorited: boolean }>(({ theme, $isFavorited }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 500,
    width: 30,
    height: 30,
    color: $isFavorited ? theme.palette.warning[400] : theme.palette.grey[400],
    borderRadius: '50%',

    svg: {
        transition: '0.3s ease color, 0.3s ease fill',
    },
}));
